import React, { useState, useContext } from "react";
import useRouter from "../../utils/useRouter";

// mui
import { LoadingButton } from "@mui/lab";
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import { Hidden } from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';

// components
import DialogAlert from "../dialog-alert";

//services
import authServices from "../../services/auth"

//constants
import constants from "../../constants";

// utils
import isEmpty from "../../utils/isEmpty"

// styles
import useStyles from "./index.styles"

//context
import { GlobalStates } from "../../App";

const Login = ({ isMobile, handleToast }) => {
    const classes = useStyles();
    const router = useRouter();
    const [globalStates] = useContext(GlobalStates)
    const [btnLoading, setbtnLoading] = useState(false);
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [dialogContent, setDialogContent] = useState('Please enter username/password before continuing');

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleSignInClick = async (event) => {
        event.preventDefault()
        if (isEmpty(email) || isEmpty(password)) {
            setDialogContent('Please enter username/password before continuing');
            setIsDialogOpen(true);
            return
        }
        try {
            setbtnLoading(true);
            const req = {
                "username": email,
                "password": password
            }

            const resData = await authServices.userLogin(req);
            // console.log(resData);
            // setbtnLoading(false);
            if (!resData) {
                globalStates.handleToast(true, 'Something went wrong, please try again!', 'error');
                setbtnLoading(false);
                return
            }
            if (resData.status === 500) {
                globalStates.handleToast(true, 'Internal server error', 'error');
                setbtnLoading(false);
                return
            }
            if (resData.status === 400 || resData.status === 401) {
                if (resData?.data?.errors) {
                    if (resData.data.errors[0].message === 'User not registered') {
                        globalStates.handleToast(true, 'Please enter a valid username to continue', 'error');
                        setbtnLoading(false);
                        return
                    }
                    if (resData.data.errors[0].message === 'Authentication failure') {
                        globalStates.handleToast(true, 'Incorrect password!', 'error');
                        setbtnLoading(false);
                        return
                    }
                    globalStates.handleToast(true, resData.data.errors[0].message, 'error');
                    setbtnLoading(false);
                    return
                }

            }
            if (resData.status === 200 || resData.status === 201) {
                //After success
                //Set state using redux
                const data = resData.data.data;
                // console.log("After login data = ", data);
                const session = {
                    accessToken: data.tokens.accessToken,
                    refreshToken: data.tokens.refreshToken,
                    userID: data.adminId,
                    userName: data.adminUsername,
                }
                localStorage.setItem('session', JSON.stringify(session));
                localStorage.setItem('roles', data.roles);
                if (data.admin.email.includes('superAdmin') && data.admin.name === "Super Admin") {
                    localStorage.setItem('user-type', 'superAdmin');
                    localStorage.setItem('admin', JSON.stringify(data.admin));
                    setbtnLoading(false);
                    router.history.push('/companies');
                    return
                } else if (data.admin.username === "customersupport@masteroapp.com" && data.roles.includes("ALL_USER_LIST")) {
                    localStorage.setItem('user-type', 'customerSupport');
                    localStorage.setItem('companyId', 'cs-x');
                    localStorage.setItem('admin', JSON.stringify(data.admin));
                    setbtnLoading(false);
                    router.history.push('/user-details?c_id=cs-x');
                } else {
                    let roles = data.roles;

                    if (!data.companyIds.length || !roles.length) {
                        globalStates.handleToast(true, 'Access Denied', 'error');
                        localStorage.clear();
                        setbtnLoading(false);
                        return
                    }
                    const companyId = data.companyIds[0];
                    localStorage.setItem('companyId', companyId);
                    localStorage.setItem('user-type', 'companyAdmin');
                    localStorage.setItem('company-name', data.companies[0].name);
                    localStorage.setItem('admin', JSON.stringify(data.admin));

                    if (companyId === constants.IFL_ID) {
                        if (roles.includes("CONTENT_CREATOR") && roles.includes("TMS_TRAINER")) {
                            setbtnLoading(false);
                            router.history.push(`/projects/${companyId}`);
                            return
                        } else if (!roles.includes("CONTENT_CREATOR") && roles.includes("TMS_TRAINER")) {
                            setbtnLoading(false);
                            router.history.push(`/drive/training/training-log?c_id=${companyId}`);
                            return
                        }
                        else if (roles.includes("CONTENT_CREATOR") && !roles.includes("TMS_TRAINER")) {
                            setbtnLoading(false);
                            router.history.push(`/projects/${companyId}`);
                            return
                        } else {
                            globalStates.handleToast(true, 'Access Denied', 'error');
                            setbtnLoading(false);
                        }

                    } else {
                        setbtnLoading(false);
                        if (roles.includes("PROJECTS_MANAGER")) {
                            router.history.push(`/projects/${companyId}`);
                            return
                        } else if (roles.includes("CONTENT_CREATOR")) {
                            router.history.push(`/manage-microskills/${companyId}/xx`);
                            return
                        } else if (roles.includes("ANALYTICS_MANAGER")) {
                            router.history.push(`/analytics?c_id=${companyId}&&tab=1`);
                            return
                        } else if (roles.includes("FORM_MANAGER")) {
                            router.history.push(`/add-forms?c_id=${companyId}`);
                            return
                        } else if (roles.includes("TMS_TRAINER")) {
                            router.history.push(`/drive/training/training-log?c_id=${companyId}`);
                            return
                        } else {
                            globalStates.handleToast(true, 'Access Denied', 'error');
                            localStorage.clear();
                            return
                        }

                    }
                }

            }
        } catch (e) {
            console.log(e);
        }
    }


    return (
        <div className={`${classes.loginContainer} ${classes.centerFlex}`}>
            <div className={`${classes.loginBox} ${classes.centerFlex}`}>
                <Hidden mdDown>
                    <div className={classes.side1}>
                        <div className={classes.imageContainer}>
                            <img src="/images/login-screen/new-logo-dark-theme.svg" className={classes.loginScreenMasteroLogo} alt="Master-O Logo" />
                            <h3 className={classes.loginScreenDashboardTitle}>DASHBOARD</h3>
                            <img src="/images/login-screen/mastero-drive-login-illustration.png" className={classes.loginScreenDriveLoginIntro} alt="Master-o illustration"></img>
                        </div>
                    </div>
                </Hidden>

                <div className={classes.side2}>
                    <form onSubmit={handleSignInClick}>
                        <div>
                            <label className={classes.labelClass}>Username</label>
                            <OutlinedInput
                                fullWidth
                                size="small"
                                className={classes.emailInput}
                                onChange={(e) => setEmail(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <PersonOutlineIcon sx={{ color: '#f4511e' }} />
                                    </InputAdornment>
                                }
                            />
                        </div>

                        <div className={classes.desktopPwdBox}>
                            <label className={classes.labelClass}>Password</label>
                            <OutlinedInput
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                size="small"
                                className={classes.emailInput}
                                onChange={(e) => setPassword(e.target.value)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </div>


                        <LoadingButton
                            fullWidth
                            type="Submit"
                            loading={btnLoading}
                            disableElevation
                            variant="contained"
                            color="error"
                            size="large"
                            className={classes.loadingBtn}
                        >
                            LOGIN TO YOUR ACCOUNT
                        </LoadingButton>


                        <div className={classes.checkBoxContainer}>
                            <Checkbox sx={{ padding: '0' }} />
                            <span className={classes.rememberMeText}>Remember me on this device</span>
                        </div>
                    </form>
                </div>
            </div>
            {
                isDialogOpen ? (
                    <DialogAlert
                        isOpen={isDialogOpen}
                        title={''}
                        content={dialogContent}
                        setIsDialogOpen={setIsDialogOpen}
                    />
                ) : (null)
            }
        </div>
    )
}

export default Login;