import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    contextListMainContainer: {
        height: '100%',
        width: '100%',
    },
    row1: {
        // border: '1px solid black',
        position: 'relative',
        padding: '1rem 1rem 2rem 1rem',
        height: '5.5rem',
        width: '100%',
    },
    row2: {
        touchAction: "pan-y",
        height: 'calc(100% - 5.5rem)',
        width: '100%',
        padding: '1rem',
        paddingTop: '0',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '8px',
            cursor: 'pointer'
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                background: '#f4511e',
                borderRadius: '12px'
            },
        }

    },
    expandCollapse: {
        // border: '1px solid',
        padding: '0.5rem',
        borderRadius: 4,
        position: 'absolute',
        right: '1.3rem',
        top: '2.5rem',
        cursor: 'pointer',
        color: '#f4511e',
        fontSize: '0.6rem',
        fontFamily: 'Montserrat',
        fontWeight: '700',
        letterSpacing: '0.5px'
    },
    title: {
        color: "#2B2B2B",
        fontFamily: "Montserrat",
        fontSize: "0.9rem",
        fontWeight: "800",
        padding: '0.5rem'
    },
    filterExpandLabels: {
        display: 'flex',
        padding: '0 0.5rem',
        '& div': {
            display: 'flex',
            width: '50%',
            color: '#f4511e',
            paddingTop: '1rem',
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            fontWeight: '700',
            letterSpacing: '0.5px'
        }
    },

    checkBox: {
        marginRight: '1.6rem',
        '& .MuiSvgIcon-root': {
            fontSize: 15,
            color: '#f4511e'
        },
        '& .MuiTypography-root': {
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            fontWeight: '600',
        }
    },
    listItem: {
        userSelect: "none",
        '-webkit-touch-callout': 'none'
    },
    contextBox: {
        display: 'flex',
        padding: "0.3rem 0.5rem",
        backgroundColor: "rgb(221, 224, 226, 0.5)",
        alignItems: 'center',
        height: '2.3rem',
        maxHeight: '2.3rem',
        '& i': {
            color: '#F4511E',
            fontSize: '0.8rem',
            cursor: 'pointer'
        },
        '& .fa-angle-up,.fa-angle-down': {
            marginRight: '0.7rem',
        },
        '& .fa-grip-vertical': {
            cursor: 'grab'
        },

        '& > div': {
            display: 'flex',
            color: '#707070',
            fontSize: '0.8rem',
            fontFamily: "Montserrat",
            fontWeight: "600",
            width: '88%',
            maxHeight: '1.6rem',
            marginRight: '1rem'
        }
    },
    context: {
        display: 'flex',
        alignItems: 'center',
        // height: '1.2rem',
        // minHeight: '1.2rem',
        overflowX: 'hidden',
        overflowY: 'hidden',
        width: '100%',
        // padding: '0.2rem',
        '& i': {
            marginRight: '0.6rem'
        },
        '& .fa-circle-check': {
            color: '#15B725'
        },
        '& .fa-circle-dot': {
            color: '#ffa000'
        }
    },
    deleteIcon: {
        transition: 'all 300ms ease-in',
        height: '0.8rem',
        marginRight: '1rem',
        '&:hover': {
            cursor: 'pointer',
            scale: 1.2,
            filter: 'invert(34%) sepia(70%) saturate(2053%) hue-rotate(351deg) brightness(99%) contrast(93%)'
        }
    },
    warning: {
        minWidth: 'max-content',
        display: 'flex',
        alignItems: 'center',
        border: '1.5px solid #F63B34',
        padding: '0.3rem 1rem',
        color: '#F63B34',
        fontSize: '0.7rem',
        fontFamily: 'Roboto',
        fontWeight: '600',
        borderRadius: '5px',
        '& .fa-solid': {
            marginRight: '0.4rem'
        }
    },
    btn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: "2px dashed rgb(244, 81, 30, 0.7)",
        borderRadius: '2px',
        color: "rgb(244, 81, 30)",
        fontFamily: "Montserrat",
        fontSize: "0.8rem",
        fontWeight: "600",
        width: '100%',
        padding: "10px",
        // textAlign : 'center',
        backgroundColor: "#F2F2F2",
        userSelect: "none",
        cursor: 'pointer',
        '& .fa-solid': {
            marginLeft: '0.8rem'
        },
        '&:hover': {
            backgroundColor: "rgb(221, 224, 226, 0.5)",
            // '-webkit-box-shadow': '2px 10px 34px -11px rgba(0,0,0,0.61)',
            // '-moz-box-shadow': '2px 10px 34px -11px rgba(0,0,0,0.61)',
            // boxShadow: '2px 10px 34px -11px rgba(0,0,0,0.61)'
        }
    },

    //voice-over-context styles
    customTextareaBox: {
        position: 'relative',
        border: '1.5px solid rgb(184, 184, 184, 0.5)',
        borderRadius: '3px',
        display: 'flex',
        fontSize: "0.7rem",
        fontWeight: "500",
        backgroundColor: '#fff',
        '& textarea': {
            color: 'rgb(112, 112, 112, 0.9)',
            padding: '0.5rem',
            fontSize: "0.8rem",
            width: '78%',
            border: 'none',
            overflowY: 'hidden',
            '&:focus': {
                outline: 'none',
            },
        },

        '& > span': {
            position: 'absolute',
            bottom: -14,
            right: 5,
            minWidth: 'fit-content',
            fontWeight: 600,
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            color: 'red',
        },
        '&:focus-within': {
            outline: 'thin solid #4F0060',
        },
        '&:hover': {
            // outline: 'thin solid #4F0060',
        },
    },
    error: {
        outline: '1.5px solid red',
        '&:hover': {
            outline: '1.5px solid red',
        },
    },
    disabledStyle: {
        '&:hover': {
            outline: 'none !important',
        },
    },
    textToSpeechContextWrapper: {
        padding: '0 1rem 1rem 1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
    disabledStyle: {
        '&:hover': {
            outline: 'none !important',
        },
    },
    labelMenuWrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: 33,
        '& > div': {
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            gap: '1rem',
            '& > button': {
                padding: '0.4rem',
                '& > span': {
                    fontSize: '0.7rem',
                }
            }
        },

    },
    maxCharWarning: {
        color: "#E37265",
        fontWeight: '600',
        fontSize: '0.7rem',
        fontFamily: 'Open Sans',
        paddingLeft: '0.2rem'
    }
}));

export default useStyles;
