import React from 'react'

// styles
import useStyles from './index.styles'

const TypeQuestion = (props) => {
    const { id, handleQuestionType, QuestionType, isAssessment } = props;

    const styles = useStyles();
    return (
        <div className={styles.MainConatiner}>
            <div className={styles.Heading}>Select Response Type</div>
            <div className={styles.OptionsContainer}>
                <button className={`${styles.Options} ${QuestionType === "SINGLE_CHOICE_QUESTION" ? styles.SelectedOption : null}`} value={"Single"} onClick={(e) => handleQuestionType(e, id)}>Single Selection</button>

                <button className={`${styles.Options} ${QuestionType === "MULTI_CHOICE_QUESTION" ? styles.SelectedOption : null}`} value={"Multiple"} onClick={(e) => handleQuestionType(e, id)}>Multiple Selection</button>

                {!isAssessment && (
                    <>
                        <button className={`${styles.Options} ${QuestionType === "OPEN_ENDED" ? styles.SelectedOption : null}`} value={"Open ended"} onClick={(e) => handleQuestionType(e, id)}>Open Ended</button>

                        <button className={`${styles.Options} ${QuestionType === "STAR_RATING" || QuestionType === "NUMBER_RATING" ? styles.SelectedOption : null}`} value={"Rating"} onClick={(e) => handleQuestionType(e, id)}>Rating</button>

                        <button className={`${styles.Options} ${QuestionType === "DATE" ? styles.SelectedOption : null}`} value={"Date"} onClick={(e) => handleQuestionType(e, id)}>Date</button>

                    </>
                )}


                {/* <button className={`${styles.Options} ${QuestionType === "CUSTOM" ? styles.SelectedOption : null}`} value={"Custom"} onClick={(e) => handleQuestionType(e, id)}>Custom</button> */}
            </div>
        </div>
    )
}

export default TypeQuestion