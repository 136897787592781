import fitb1 from './FITB-1.json'
import fitb2 from './FITB-2.json'
import mcq1 from './MCQ-1.json'
import mcq2 from './MCQ-2.json'
import mtrr1 from './MTRR-1.json'
import mtrr2 from './MTRR-2.json'
import sequence from './SEQUENCE-1.json'
import truefalse from './TF.json'
import copyMicroskill from './COPY-MICROSKILL.json'
import copyChallenge from './COPY-CHALLENGE.json'
// import comp from './comp.json'

export default {
    fitb1,
    fitb2,
    mcq1,
    mcq2,
    mtrr1,
    mtrr2,
    sequence,
    truefalse,
    copyMicroskill,
    copyChallenge
}
