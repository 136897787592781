import React, { useState, useRef, createRef } from 'react'
import html2canvas from "html2canvas";

//mui
import { makeStyles } from "@mui/styles";

//components
import CustomButton from '../../../buttons';
import CustomTextArea from '../../../custom-styled/custom-text-area';

//constants
import constants from '../../../../constants';

//utils
import commonUtil from '../../../../utils/commonUtil';
import { useEffect } from 'react';

const Objective = ({
  setIsObjectiveSkipped,
  creatingObjectiveAfterSkip,
  menuStatus,
  setMenuStatus,
  coverAndObjective,
  setShowFirstColumn,
  selectedCard,
  setSelectedCard,
  selectedColumns,
  setSelectedColumns,
  handleSaveCard,
  isEdit,
  editCardData,
  setIsEdit,
  setEditCardData,
  handleCancel,
  handleUpdateCard }) => {
  const backgroundColors = ['#FF3D00', '#4F0060', '#080037']
  const typeADefault = 'IN THIS LEARNING AID, YOU WILL UNDERSTAND'
  const typeBDefault = 'AT THE END OF THE LEARNING AID, \n  YOU WILL BE ABLE TO'
  const classes = useStyles();
  const [withOptions, setWithOptions] = useState(true);
  const [title, setTitle] = useState(typeADefault);
  const [body, setBody] = useState('');
  const contextValidation = constants.ILA_VALIDATIONS['objective']['context'];
  const [row1BgColor, setRow1BgColor] = useState('#080037');
  const [textAreaType, setTextAreaType] = useState('A');
  const [objectiveType, setObjectiveType] = useState('text');
  const [isNext, setIsNext] = useState(false);
  const [contextLimit, setContextLimit] = useState({ exceeded: false, limitText: `${contextValidation} CHARACTERS` });
  const [outlineAndCharacterLimit, setOutlineAndCharacterLimit] = useState(true);
  const [customAspectRatio, setCustomAspectRatio] = useState(true);//toggle aspect ratio
  let capture = useRef();
  let titleRef = useRef(null);
  let bodyRef = useRef(null);

  useEffect(() => {
    console.log('isEdit = ', isEdit);
    console.log('editCardData = ', editCardData);
    if (isEdit && editCardData !== null) {
      let card = editCardData.card;
      let title = commonUtil.getTitleFromContents(card.contents);
      console.log('title = ', title);
      setTitle(title)
      // titleRef.current.innerHTML = title;
      let body = commonUtil.getBodyFromContents(card.contents);
      console.log('body = ', body);
      setBody(body)
      // bodyRef.current.innerHTML = body;

    } else {
      titleRef.current.innerHTML = typeADefault;
      setTitle(typeADefault);
    }
  }, [])

  useEffect(() => {
    if (isNext) {
      captureScreenshot();
      setIsNext(false)
    }
  }, [isNext])

  const handleObjectiveTitleChange = (e) => {
    setTitle(e.target.value);
  }

  const handeleTitleChange = (e) => {
    if (e.currentTarget.textContent.length > 60) {
      // do nothing
      e.currentTarget.textContent = title;
    } else {
      setTitle(e.currentTarget.textContent.trim())
      // let text = e.currentTarget.textContent
      // e.currentTarget.textContent = text
    }
  }

  const handeleBodyChange = (e) => {
    let value = e.currentTarget.textContent;
    if (value > 120) {
      // do nothing
      value = body;
    } else {
      setBody(value.trim())
      let text = value
      value = text
    }

    setBody(value);
    console.log(value);
    if (value.length > contextValidation) {
      setContextLimit({ exceeded: true, limitText: `${value.length - contextValidation} CHARACTERS EXCEEDED!` });
    } else {
      setContextLimit({ exceeded: false, limitText: `${contextValidation - value.length} CHARACTERS` });
    }
    // console.log(e.currentTarget.textContent.length);
  }

  const handleObjectiveTextAreaChange = (e) => {
    let value = e.target.value;
    setBody(value);
    console.log(value);
    if (value.length > contextValidation) {
      setContextLimit({ exceeded: true, limitText: `${value.length - contextValidation} CHARACTERS EXCEEDED!` });
    } else {
      setContextLimit({ exceeded: false, limitText: `${contextValidation} CHARACTERS` });
    }
  }
  const handelNext = () => {
    setOutlineAndCharacterLimit(false)
    setIsNext(true)
    // captureScreenshot()
    return
  }

  const handleSkip = () => {
    let confirm = window.confirm('WARNING!. If you skip cover card/objective card creation, some functions may be limited, do you want to continue?')
    console.log('handleSkip confirm = ', confirm);
    if (confirm) {
      setIsObjectiveSkipped(true);
      setMenuStatus({ ...menuStatus, objectives: 'inProgress' });
      setSelectedCard({ ...selectedCard, cardType: 'uploadOrCreate' });
      // setPreview(coverAndObjective[0].imgUrl)
      setSelectedColumns({ ...selectedColumns, firstColumn: 'content' });
      setShowFirstColumn(true);
    }
    return
  }


  const captureScreenshot = async () => {
    // console.log(capture.current);
    const canvas = await html2canvas(capture.current);

    //CONVERTING CANVAS TO BASE64
    const imgData = canvas.toDataURL("image/png");
    // console.log("canvas to base64 = ", imgData);
    const myFile = commonUtil.base64toFile(imgData, 'temp')

    // console.log('created img to file = ', myFile);
    // let newCoverAndObjective = [...coverAndObjective];

    let cardData = {
      id: coverAndObjective.length,
      cardType: "OBJECTIVE",
      ratio: "4:3",
      title: title,
      body: body,
      thumbImageFile: myFile,
      thumbFileSize: myFile.size,
    }

    const type = 'OBJECTIVE'
    const withImage = false;

    handleSaveCard(type, cardData, withImage)

  }

  const handleBack = () => {
    //HERE WE HAVE TO SET CREATE OBJECTIVE AFTER SKIP TO FALSE
    return
  }

  const handelSave = () => {
    //HERE WE HAVE TO SET CREATE OBJECTIVE AFTER SKIP TO FALSE AND SHOW CARD LIST WITH OBJECTIVE AND COVER CARD SELECTED ON RIGHT SIDE

    return
  }

  const handleTypeAclick = () => {
    setTextAreaType('A');
    setTitle(typeADefault);
    titleRef.current.innerHTML = typeADefault;
  }

  const handleTypeBclick = () => {
    setTextAreaType('B');
    setTitle(typeBDefault)
    titleRef.current.innerHTML = typeBDefault;
  }


  return (
    <div className={classes.row}>
      <div className={classes.colum1}>
        <div className={classes.cardBox}>
          <div className={`${classes.card} ${customAspectRatio ? classes.aspect1 : classes.aspect2}`} ref={capture} >

            <div className={classes.row1} style={{ backgroundColor: row1BgColor }}>
              {/* OLD */}
              {/* <textarea
                style={{ backgroundColor: row1BgColor }}
                value={title}
                onChange={handleObjectiveTitleChange}
                placeholder='ENTER OBJECTIVE TITLE HERE'
                className={`${textAreaType === 'A' ? classes.typeA : classes.typeB}`}
                type='text' /> */}
              <div
                ref={titleRef}
                // className={classes.title}
                className={`${textAreaType === 'A' ? classes.typeA : classes.typeB}`} //EDITABLE DIV - TITLE CLASS 
                // style={{
                //   fontSize: fs,
                //   fontWeight: fw,
                //   textAlign: align,
                //   color: textColor,
                //   minHeight: minHeight
                // }}
                contentEditable='true'
                data-placeholder={'Enter Title Here.'}
                onInput={handeleTitleChange}>
              </div>
            </div>
            {
              withOptions ?
                <>
                  <div className={classes.toggleBgColor}>
                    {
                      backgroundColors.map((ele, i) => (
                        <div
                          key={i}
                          style={{ backgroundColor: ele }}
                          onClick={() => setRow1BgColor(ele)}>
                          {row1BgColor === ele ? <i className="fa-solid fa-check"></i> : null}
                        </div>
                      ))
                    }
                  </div>
                  <div className={classes.toggleTypeAandBBox}>
                    <div className={`${textAreaType === 'A' ? classes.active : ''}`} onClick={handleTypeAclick}>A</div>
                    <div className={`${textAreaType === 'B' ? classes.active : ''}`} onClick={handleTypeBclick}>B</div>
                  </div>
                  {/* <div className={classes.removeFocus} onClick={() => setOutlineAndCharacterLimit(false)}>
                  </div> */}
                </>
                : null
            }
            <div className={classes.row2}>
              {
                objectiveType === 'text' ?
                  //  <CustomTextArea handleChange={handleObjective} exceedText={contextLimit.limitText} laType={'objective'} textAreaFor={'context'}/>:null
                  <>
                    {/* <textarea
                      value={body}
                      onChange={handleObjectiveTextAreaChange}
                      onClick={() => setOutlineAndCharacterLimit(true)}
                      style={{
                        border: outlineAndCharacterLimit ?
                          ((contextLimit.exceeded) ? '1px solid red' : '1px solid #ccc')
                          : 'none'
                      }}
                      placeholder='ENTER OBJECTIVE HERE'
                      className={classes.objectiveTypeText}
                      type='text' /> */}
                    <div
                      ref={bodyRef}
                      // className={classes.title}
                      onClick={() => setOutlineAndCharacterLimit(true)}
                      style={{
                        border: outlineAndCharacterLimit ?
                          ((contextLimit.exceeded) ? '1px solid red' : '1px solid #ccc')
                          : 'none'
                      }}
                      className={classes.objectiveTypeText} 
                      // style={{
                      //   fontSize: fs,
                      //   fontWeight: fw,
                      //   textAlign: align,
                      //   color: textColor,
                      //   minHeight: minHeight
                      // }}
                      contentEditable='true'
                      data-placeholder={'Enter Body Here.'}
                      onInput={handeleBodyChange}>
                    </div>
                    {
                      outlineAndCharacterLimit ?
                        <h6 >{contextLimit.limitText}</h6> : null
                    }

                  </>
                  : null

              }
            </div>

          </div>
        </div>

        <div className={classes.btnBox}>
          {
            creatingObjectiveAfterSkip ?
              <>
                <CustomButton btnText={'BACK'} bgcolor={'#FFF'} textColor={'#F4511E'} fw={800} ls={1} handleClick={handleBack} />
                <CustomButton btnText={'SAVE'} bgcolor={'#F4511E'} textColor={'#FFF'} fw={800} ls={1} handleClick={handelSave} />
              </> :
              <>
                <CustomButton btnText={'SKIP'} bgcolor={'#FFF'} textColor={'#F4511E'} fw={800} ls={1} handleClick={handleSkip} />
                <CustomButton btnText={'NEXT'} bgcolor={'#F4511E'} textColor={'#FFF'} fw={800} ls={1} handleClick={handelNext} />
              </>

          }
        </div>
      </div>

      <div className={classes.colum2}>
        <div className={classes.colum3Row1}>
          <div className={classes.aspectRatioButtons}>
            {/* aspectRatioButtons */}
          </div>
        </div>
      </div>


    </div>
  )



}

export default Objective;


const useStyles = makeStyles(() => ({
  row: {
    height: '100%',
    width: '100%',
    display: 'flex'
  },
  colum1: {
    // border: '1px solid red',
    width: '90%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // position: 'relative'
  },
  colum2: {
    // border: '1px solid red',
    width: '10%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  aspect1: {
    aspectRatio: 3 / 4,
  },
  aspect2: {
    aspectRatio: 9 / 16,
  },
  cardBox: {
    // border: '1px solid',
    // position: 'relative',
    width: '100%',
    height: '88%',
    display: 'flex',
    justifyContent: 'center',

  },
  card: {
    // width: '43%',
    // border: '1px solid red',
    position: 'relative',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  row1: {
    // border: '1px solid red',
    width: '100%',
    height: '40%',
    'border-top-left-radius': '10px',
    'border-top-right-radius': '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  typeA: {
    // all: 'unset',
    // '&::placeholder': {
    //   color: '#fff5',
    // },
    // resize: 'none',
    height: '56%',
    width: '94%',
    color: '#FFF',
    fontSize: '1.8rem',
    fontWeight: 700,
    // margin: '3rem 0.8rem',
    overflow: 'hidden',
    border: 'none',
    fontFamily: 'Montserrat',
    lineHeight: 2,
    textAlign: 'center'
  },
  typeB: {
    // all: 'unset',
    // '&::placeholder': {
    //   color: '#fff5',
    // },
    // resize: 'none',
    height: 'calc(100% - 0.8rem*2)',
    width: 'calc(100% - 2rem)',
    color: '#FFF',
    fontSize: '1.8rem',
    fontWeight: 700,
    // margin: '0.8rem 1rem',
    overflow: 'hidden',
    border: 'none',
    fontFamily: 'Montserrat',
    lineHeight: 2,
    textAlign: 'center'
  },
  objectiveTypeText: {
    '&::placeholder': {
      color: '#fff5',
    },
    '&:focus': {
      outline: 'none'
    },
    // border: '1px solid #ccc',
    textAlign: 'center',
    resize: 'none',
    height: '100%',
    width: '100%',
    color: 'rgb(0,0,0, 0.9)',
    fontSize: '1.4rem',
    fontWeight: 600,
    // margin: '3rem 0.8rem',
    overflow: 'hidden',
    // border: 'none',
    fontFamily: 'Montserrat',
    lineHeight: 1.9,
  },
  toggleTypeAandBBox: {
    position: 'absolute',
    width: '1.6rem',
    height: '3.4rem',
    top: '10.2rem',
    right: '-1.8rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.3rem',
    '& > div': {
      cursor: 'pointer',
      border: '2px solid #f4511e',
      fontWeight: 700,
      textAlign: 'center',
      backgroundColor: '#fff',
      color: '#f4511e'
    }
  },
  toggleBgColor: {
    position: 'absolute',
    top: '3%',
    left: '-6rem',
    // width: '20%',
    height: '1.8rem',
    display: 'flex',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '33.3%',
      aspectRatio: 1 / 1,
      '& i': {
        color: '#fff',
        fontSize: '0.7rem'
      }
      // border: '2px solid #f4511e',
      // fontWeight: 700,
      // textAlign: 'center',
      // backgroundColor : '#fff',
      // color : '#f4511e'
    }
  },
  active: {
    backgroundColor: '#f4511e !important',
    color: '#fff !important'
  },
  row2: {
    // border: '1px solid green',
    width: '100%',
    height: '60%',
    'border-bottom-left-radius': '10px',
    'border-bottom-right-radius': '10px',
    padding: '1.5rem',
    paddingBottom: '3rem',
    position: 'relative',
    '& h6': {
      position: 'absolute',
      bottom: '2.2rem',
      right: '1.5rem',
      color: "#EB3917",
      fontFamily: "Montserrat",
      fontSize: "0.54rem",
      fontWeight: "700",
    }
  },
  removeFocus: {
    // border : '1px solid red',
    position: 'absolute',
    top: '15rem',
    right: '-28rem',
    height: '19rem',
    width: '28rem'
  },
  btnBox: {
    height: '12%',
    width: '31%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      borderRadius: '3px',
      height: '2.8rem',
      width: '32% !important',
      '&:hover': {
        borderColor: '#f4511e',
        backgroundColor: '#fff',
        color: '#f4511e'
      }
    }
  },
  colum3Row1: {
    height: '92%'
  },
  colum3Row2: {
    height: '8%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    '& .MuiButtonBase-root': {
      width: '50%',
      '&:hover': {
        borderColor: '#f4511e',
        backgroundColor: '#fff',
        color: '#f4511e'
      }
    }
  },
  aspectRatioButtons: {
    // border: '1px solid',
    height: '10%'
  },

}));