import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Chip from '@mui/material/Chip';

const myTags = ['Selling Skills', 'Objective Selling', 'Persuasion']


const ChipStack = ({ tags = myTags, selectedTag, handleClick, handleDelete }) => {
    const classes = useStyles();
    // const handleClick = () => {
    //     console.info('You clicked the Chip.');
    // };

    // const handleDelete = () => {
    //     console.info('You clicked the delete icon.');
    // };
    return (
        <div className={classes.chipStack}>
            {
                tags.map((ele, i) => (
                    <Chip
                        key={i}
                        label={ele}
                        variant={selectedTag === ele ? "filled" : "outlined"}
                        onClick={() => handleClick(ele)}
                        onDelete={handleDelete}
                    />
                ))
            }
        </div>
    )
}

export default ChipStack;

const useStyles = makeStyles(() => ({
    chipStack: {
        // border : '1px solid',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem',
        '& .MuiButtonBase-root': {
            '& .MuiChip-label': {
                color: "rgb(0,0,0, 0.9)",
                fontFamily: "Montserrat",
                fontSize: "0.7rem",
                fontWeight: 600,
            },
            '& .MuiSvgIcon-root': {
                fontSize: '1rem'
            }
        },
        '& .MuiChip-filled': {
            border: '1px solid #f4511e',
            backgroundColor: '#f4511e',
            color: '#fff'
        },
        '& .MuiChip-outlined': {
            borderColor: '#f4511e',
            color: '#000000',
            '& .MuiChip-label': {
                color: "#fff",
                fontFamily: "Montserrat",
                fontSize: "0.7rem",
                fontWeight: 600,
            },
        }
    },
}));
