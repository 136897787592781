import React from 'react';
import styled from 'styled-components';
// import ButtonBase from "@material-ui/core/ButtonBase";
// import {TimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
// import DayUtils from '@date-io/dayjs';
import ClockIcon from "./MDLIcon/icons/Clock";

//new
import ButtonBase from '@mui/material/ButtonBase';


export default function MOTimePicker(props) {
  return (
    <ComponentWrapper>
      <Label>{props.label}</Label>
      <SelectorWrapper>
        <StyledButtonBase
          onClick={props.onClick}
        >
          <StyledFlexFirst>
            <StyledClockIcon />
            <Text>{props.text}</Text>
          </StyledFlexFirst>
        </StyledButtonBase>
      </SelectorWrapper>
      <div style={{display: "none"}}>
        <h1>Time picker</h1>
        {/* <MuiPickersUtilsProvider utils={DayUtils}>
          <TimePicker
            open={props.open}
            label="Basic example"
            value={props.value}
            onChange={props.handleTimeChange}
            onClose={props.onClose}
          />
        </MuiPickersUtilsProvider> */}
      </div>
    </ComponentWrapper>
  )
}


const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  color: #707070;
  font-size: 0.75rem;
  font-family: "Montserrat";
`;

const SelectorWrapper = styled.div`
  border: 0.5px solid #dde0e2;
  
  max-width: 100px;
  height: 28px;
`;

const StyledButtonBase = styled(ButtonBase)`
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
`;

const Text = styled.span`
  font-family: "Montserrat";
  font-weight: 600;
  padding-left: 10px;
  font-size: 0.7rem;
  color: #707070;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
  display: block
`;

const StyledFlexFirst = styled.div`
  display: flex;
  align-items: center;
  padding-left: 8px;
`;

const StyledClockIcon = styled(ClockIcon)`
  .class-clock-icon__cls-1{
    fill:none;opacity:.3
  }
  
  .class-clock-icon__cls-2{
    fill:#707070
  }
  
`;