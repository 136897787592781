import * as React from 'react';
import Switch from '@mui/material/Switch';

export default function ControlledSwitches({ id = '', disabled = false, checked, handleChange }) {


  return (
    <Switch
      id={id}
      checked={checked}
      onChange={handleChange}
      disabled={disabled}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={{
        '& .Mui-checked ': {
          color: checked ? '#f4511e !important' : '',

        },
        '& .MuiSwitch-track ': {
          backgroundColor: checked ? 'rgb(244, 81, 30) !important' : ''
        }
      }}
    />
  );
}
