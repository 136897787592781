import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    main: {
        // border: '1px solid black',
        display: 'flex',
        position: 'relative',
        backgroundColor: '#ffffff',
        height: `calc(100vh - ${constants.HEADER_HEIGHT})`,
    },
    header: {
        // border: '1px solid red',
        backgroundColor: '#F9F9F9',
        height: '3.4rem',
        display: 'flex',
        width: '50%',
        alignItems: 'center',
        paddingLeft: '1rem',
        gap: '1rem',
        position: 'absolute',
        top: '-3.4rem',
        left: 0,
        zIndex: 9999,
        '& > img': {
            cursor: 'pointer',
            transition: 'width 2s',
            '&:hover': {
                width: '1.2rem',
                marginRight: '-0.11rem'
            }
        },
        '& h1': {
            color: "rgb(0,0,0,0.9)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "700",
            '& img': {
                height: '0.8rem',
                width: '0.8rem',
                marginLeft: '2rem',
                '&:hover': {
                    cursor: 'pointer',
                    filter: 'invert(42%) sepia(69%) saturate(4247%) hue-rotate(352deg) brightness(100%) contrast(92%)'
                }
            }
        },
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            '& > input': {
                border: 'none',
                backgroundColor: '#F9F9F9',
                padding: '0.2rem 0.5rem',
                color: "rgb(0,0,0,0.9)",
                fontFamily: "Montserrat",
                fontSize: "1rem",
                fontWeight: "700",
                borderBottom: '1px solid #f4511e',
                '&:focus': {
                    outline: 'none',
                    borderBottom: '2px solid #f4511e',
                }
            },
            '& .MuiButtonBase-root': {
                marginLeft: '2rem',
                height: '1.5rem',
                width: '2rem',
                borderRadius: '4px',
                backgroundColor: '#f4511e',
                color: '#fff',
                border: '2px solid #f4511e',
                fontWeight: 700,
                letterSpacing: '1px',
                '&:hover': {
                    backgroundColor: '#fff',
                    color: '#f4511e',
                }
            }
        }
    },
    column1: {
        // border: '1px solid green',
        position: 'relative',
        width: '35%',
        height: '100%',
        padding: '1rem'

    },
    inputWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        margin: '1.5rem 0',
        '& > div': {
            height: '34px',
            width: '80%',
            '& > div:first-child': {
                width: '75%'
            },
            '& > div:nth-child(2)': {
                width: '25%'
            },
            '& > input': {
                fontSize: '0.8rem',
                fontFamily: 'Montserrat',
                fontWeight: 500,
            }
        }
    },
    info: {
        width: '80%',
        marginTop: '-0.8rem',
        display: 'flex',
        justifyContent: 'space-between',
        '& > span': {
            color: '#f4511e',
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            fontWeight: 700,
        }
    },
    btnBox: {
        position: 'absolute',
        bottom: '1rem',
        width: '80%',
        display: 'flex',
        gap: '5rem',
    },
    btn: {
        border: '2px solid #f4511e',
        height: '42px',
        width: '160px',
        borderRadius: '2px',
        fontSize: '0.7rem',
        fontFamily: 'Montserrat',
        fontWeight: 800,
        textTransform: 'uppercase',
        letterSpacing: 1
    },
    btnFilled: {
        color: '#ffffff',
        backgroundColor: '#f4511e'
    },
    btnOutlined: {
        color: '#f4511e',
        backgroundColor: '#ffffff'

    },
    downloadBtn: {
        position: 'absolute',
        right: '3rem',
        top: '8rem',
    },
    column2: {
        // border: '1px solid red',
        width: '65%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '1rem'
        // justifyContent: 'center',
        // padding: '0.5rem'
    },

}));

export default useStyles;