import styled from 'styled-components';

const StyledLabel = styled.label`
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  display: block;
  padding-bottom: 15px;
`;

export const LightLabel = styled.label`
  font-size: 0.85rem;
  font-family: 'Montserrat';
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  display: block;
`;

export const NormalLabel = styled.label`
  font-size: 0.85rem;
  font-family: 'Montserrat';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  display: block;
`;
export const SemiBoldLabel = styled.label`
  font-size: 0.85rem;
  font-family: 'Montserrat';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  display: block;
`;

export const HeadingLabel = styled.label`
  font-size: 0.85rem;
  font-family: 'Montserrat';
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #484848;
  display: block;
`;
export const SmallFontLabel = styled.label`
  font-size: 0.7rem;
  font-family: 'Montserrat';
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  display: block;
`;

export default StyledLabel;
