import constants from "../../constants";

const draglistData = {
    MCQ: [
        {
            id: 0,
            skillId: "",
            status: 'inProgress',
            validations: [],
            question: '',
            sequence: 1,
            isQuestionEmpty: false,
            emptyAnswers: [
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
            ],
            unCheckedOptions: [
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                }
            ],
            answers: [
                {
                    id: 0,
                    option: "",
                    value: true,
                    sequence: 1
                },
                {
                    id: 1,
                    option: "",
                    value: false,
                    sequence: 2
                },
                {
                    id: 2,
                    option: "",
                    value: false,
                    sequence: 3
                },
                {
                    id: 3,
                    option: "",
                    value: false,
                    sequence: 4
                }
            ]
            // responsesValidation : getDefaultValidationsList(4, 'MCQ_TikiMonkey', 'response')
        }
    ],
    TF: [

        {
            id: 0,
            sequence: 1,
            skillId: "",
            status: 'inProgress',
            validations: [],
            contextId: 0,
            contextObj: { id: 0, type: 'TEXT', context: '' },
            isContextEmpty: false,
            question: '',
            isQuestionEmpty: false,
            emptyAnswers: [
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                }
            ],
            unCheckedOptions: [
                {
                    unChecked: false
                },
                {
                    unChecked: false
                }
            ],
            answers: [
                {
                    id: 0,
                    option: 'TRUE',
                    value: true,
                    sequence: 1
                },
                {
                    id: 1,
                    option: 'FALSE',
                    value: false,
                    sequence: 2
                }
            ]

        }
    ],
    MA: [

        {
            id: 0,
            skillId: "",
            sequence: 1,
            status: 'error',
            validations: [],
            // contextId: null,
            // contextObj: { id: 0, type: 'Text', context: '' },
            question: "",
            isQuestionEmpty: false,
            emptyAnswers: [
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
            ],
            unCheckedOptions: [
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
            ],
            answers: [
                {
                    id: 0,
                    option: "",
                    value: true,
                    sequence: 1
                },
                {
                    id: 1,
                    option: "",
                    value: true,
                    sequence: 2
                },
                {
                    id: 2,
                    option: "",
                    value: true,
                    sequence: 3
                },
                {
                    id: 3,
                    option: "",
                    value: true,
                    sequence: 4
                },
                {
                    id: 4,
                    option: "",
                    value: false,
                    sequence: 5
                },
                {
                    id: 5,
                    option: "",
                    value: false,
                    sequence: 6
                },
                {
                    id: 6,
                    option: "",
                    value: false,
                    sequence: 7
                },
                {
                    id: 7,
                    option: "",
                    value: false,
                    sequence: 8
                }
            ]
        }
    ],
    MTRR: [
        {
            id: 0,
            skillId: "",
            sequence: 1,
            status: 'error',
            validations: [],
            contextId: null,
            contextObj: { id: 0, type: 'TEXT', context: '' },
            isContextEmpty: false,
            questionArr: [
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                },
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                },
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                },
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                },
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                }
            ]
        }
    ],
    TF_SoundRight:[
        {
            id: 0,
            skillId: "",
            sequence: 1,
            status: 'inProgress',
            validations: [],
            audioFile: null,
            isAudioEmpty: false,
            fileName: 'No File Chosen',
            contextId: null,
            contextObj: { id: 0, type: 'AUDIO', context: '' },
            question: '',
            isQuestionEmpty: false,
            emptyAnswers: [
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                }
            ],
            unCheckedOptions: [
                {
                    unChecked: false
                },
                {
                    unChecked: false
                }
            ],
            answers: [
                {
                    id: 0,
                    option: '',
                    value: true,
                    sequence: 1
                },
                {
                    id: 1,
                    option: '',
                    value: false,
                    sequence: 2
                }
            ]

        }
    ],
    MA_RevealThePicture:[
        {
            id: 0,
            skillId: "",
            sequence: 1,
            status: 'inProgress',
            validations: [],
            fileName: 'No File Chosen',
            imageFile : null,
            isImageEmpty: false,
            question: "",
            isQuestionEmpty: false,
            emptyAnswers: [
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
                {
                    isEmpty: false
                },
            ],
            unCheckedOptions: [
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
                {
                    unChecked: false
                },
            ],
            answers: [
                {
                    id: 0,
                    option: "",
                    value: true,
                    sequence: 1
                },
                {
                    id: 1,
                    option: "",
                    value: true,
                    sequence: 2
                },
                {
                    id: 2,
                    option: "",
                    value: true,
                    sequence: 3
                },
                {
                    id: 3,
                    option: "",
                    value: true,
                    sequence: 4
                },
                {
                    id: 4,
                    option: "",
                    value: false,
                    sequence: 5
                },
                {
                    id: 5,
                    option: "",
                    value: false,
                    sequence: 6
                },
                {
                    id: 6,
                    option: "",
                    value: false,
                    sequence: 7
                },
                {
                    id: 7,
                    option: "",
                    value: false,
                    sequence: 8
                }
            ]
        }
    ],
    MTRR_LearnoCasino:[
        {
            id: 0,
            skillId: "",
            sequence: 1,
            status: 'error',
            validations: [],
            contextId: null,
            contextObj: { id: 0, type: 'TEXT', context: '' },
            isContextEmpty: false,
            questionArr: [
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                },
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                },
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                },
                {
                    id: 0,
                    contextId: null,
                    skillId: "",
                    question: "",
                    isQuestionEmpty: false,
                    emptyAnswers: [
                        {
                            isEmpty: false
                        }
                    ],
                    answers: [
                        {
                            id: 0,
                            option: "",
                            value: true
                        }
                    ]
                }
            ]
        }
    ]

}

export default draglistData;

//list data default
// [{
//     id: 0, skillId: 0, question: '', answers: [
//         {
//             id: 0,
//             option: "",
//             value: true
//         },
//         {
//             id: 1,
//             option: "",
//             value: false
//         },
//         {
//             id: 2,
//             option: "",
//             value: false
//         },
//         {
//             id: 3,
//             option: "",
//             value: false
//         }
//     ]
// }]