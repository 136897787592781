import React, { useState } from 'react'

//components
import DefaultCard from '../la-cards/defaultCard'
import UploadOrCreate from '../upload-or-create-column'
import Cover from '../la-cards/cover'
import Objective from '../la-cards/objective'
import ImageTitleBody from '../la-cards/imageTitleBody'
import ImageTitle from '../la-cards/imageTitle'
import ImageOnly from '../la-cards/imageOnly'
import TitleBody from '../la-cards/titleBody'
import Body from '../la-cards/body'
import VideoTitleBody from '../la-cards/videoTitleBody'
import Statements from '../la-cards/statements'
import Quotes from '../la-cards/quotes'
import BulletList from '../la-cards/bulletList'
import NumberedList from '../la-cards/numberedList'
import Table from '../la-cards/table'
import Graph from '../la-cards/graphs'
import LoanCalculator from '../la-cards/loanCalculator'
import LinkDocument from '../link-document'
import AttachAudio from '../attach-audio'
import Disclaimer from '../../../disclaimer'

//constants
import constants from '../../../../constants'

//styles
import useStyles from './index.styles'
import { useEffect } from 'react'

const EditContentSection = ({
  setImportingList,
  menuStatus,
  setMenuStatus,
  coverAndObjective,
  setCoverAndObjective,
  setShowImportBtn,
  cardType = 'uploadOrCreate',
  preview,
  setPreview,
  setShowFirstColumn,
  setIsObjectiveSkipped,
  creatingObjectiveAfterSkip,
  setShowCoverAndObjective,
  list,
  setList,
  selectedCard,
  setSelectedCard,
  selectedColumns,
  setSelectedColumns,
  handleSaveCard,
  handleBulkUpload,
  loadingCard,
  isEdit,
  setIsEdit,
  editCardData,
  setEditCardData,
  handleUpdateCard,
  setIsActive,
  enableAddLinkFor,
  enableAttachAudioFor,
  documents = [],
  link,
  setLink,
  laAudioData,
  setLaAudioData,
  defaultAudio,
  handleAttachAudio,
  handleRemoveAudio,
  laBgImgData,
  setLaBgImgData,
  defaultBgData,
  handleChangeLaBgImg
}) => {
  const classes = useStyles();
  const getDesignCode = (cardType) => constants.LA_DESIGN_CODES[cardType];
  const [customAspectRatio, setCustomAspectRatio] = useState(true);
  const cardsWithValidations = ['imageTitleBody', 'videoTitleBody', 'cover', 'objective', 'titleBody', 'body'];

  const [titleBody, setTitleBody] = useState({ title: "", body: "" });
  const [toolbar, setToolbar] = useState({ title: false, body: false });

  useEffect(() => {
    console.log('titleBody = ', titleBody);
  }, [titleBody])

  let titleMaxLength = 45;
  let bodyMaxLength = 240;
  if (cardsWithValidations.includes(cardType)) {
    titleMaxLength = constants.LA_VALIDATIONS[getDesignCode(cardType)]['title'] || 40;
    bodyMaxLength = constants.LA_VALIDATIONS[getDesignCode(cardType)]['body'] || 240;
  }
  const suggestedImages = constants.SUGGESTED_IMAGES;

  const handleCancel = () => {
    console.log('handleCancel clicked!');
    setSelectedCard({ ...selectedCard, cardType: 'uploadOrCreate' });
    setIsEdit(false);
    setEditCardData(null);
    setIsActive(null);
    setLink(null);
    setLaAudioData(defaultAudio);
    setLaBgImgData(defaultBgData);
  }

  // const handeleTitleChange = (e) => {
  //   if (e.currentTarget.textContent.length > titleMaxLength) {
  //     // do nothing
  //     e.currentTarget.innerHTML = titleBody.title;
  //   } else {
  //     setTitleBody({ ...titleBody, title: e.currentTarget.innerHTML.trim() });
  //   }
  // }

  // const handleBodyChange = (e) => {
  //   if (e.currentTarget.textContent.length > bodyMaxLength) {
  //     // do nothing
  //     e.currentTarget.innerHTML = titleBody.body;
  //   } else {
  //     setTitleBody({ ...titleBody, body: e.currentTarget.innerHTML.trim() });
  //     // let text = e.currentTarget.textContent
  //     // e.currentTarget.textContent = text
  //   }
  // }

  const handeleTitleChange = (innerHTML) => {
    // setTitleBody({ ...titleBody, title: innerHTML });
    setTitleBody(prev=>({ ...prev, title: innerHTML }));
  }

  const handleBodyChange = (innerHTML) => {
    // setTitleBody({ ...titleBody, body: innerHTML });
    setTitleBody(prev=>({ ...prev, body: innerHTML }));
  }

  const handleTitleClick = () => {
    if (!toolbar.title) setToolbar({ title: true, body: false });
  }

  const handleBodyClick = () => {
    if (!toolbar.body) setToolbar({ title: false, body: true });
  }

  return (
    <>
      <div className={classes.textEditorBox}></div>
      <div className={classes.main}>
        {enableAddLinkFor.includes(selectedCard?.cardType) && <LinkDocument link={link} setLink={setLink} documents={documents} />}
        {enableAttachAudioFor.includes(selectedCard?.cardType) && <AttachAudio audioFileUrl={laAudioData?.audioFileUrl} fileName={laAudioData?.fileName} mimeType={laAudioData?.mimeType} handleChange={handleAttachAudio} removeAudio={handleRemoveAudio} />}
        {cardType === 'uploadOrCreate' ?
          <UploadOrCreate
            setPreview={setPreview}
            setSelectedCard={setSelectedCard}
            setShowCoverAndObjective={setShowCoverAndObjective}
            setShowImportBtn={setShowImportBtn}
            setImportingList={setImportingList}
            list={list}
            setList={setList}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            handleBulkUpload={handleBulkUpload}
            loadingCard={loadingCard} />
          : null}

        {cardType === 'default' ?
          <DefaultCard preview={preview} />
          : null}
        {cardType === 'cover' ?
          <Cover
            suggestedImages={suggestedImages}
            handleSaveCard={handleSaveCard}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editCardData={editCardData}
            setEditCardData={setEditCardData}
            handleCancel={handleCancel}
            handleUpdateCard={handleUpdateCard}
            titleBody={titleBody}
            setTitleBody={setTitleBody}
            handeleTitleChange={handeleTitleChange}
            handleTitleClick={handleTitleClick}
            toolbar={toolbar}
            setToolbar={setToolbar}

          /> : null}

        {cardType === 'objective' ?
          <Objective
            setIsObjectiveSkipped={setIsObjectiveSkipped}
            creatingObjectiveAfterSkip={creatingObjectiveAfterSkip}
            menuStatus={menuStatus}
            setMenuStatus={setMenuStatus}
            setPreview={setPreview}
            coverAndObjective={coverAndObjective}
            setShowFirstColumn={setShowFirstColumn}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleSaveCard={handleSaveCard}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editCardData={editCardData}
            setEditCardData={setEditCardData}
            handleCancel={handleCancel}
            handleUpdateCard={handleUpdateCard}
            setToolbar={setToolbar}
          /> : null}


        {cardType === 'imageTitleBody' ?
          <ImageTitleBody
            suggestedImages={suggestedImages}
            handleSaveCard={handleSaveCard}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editCardData={editCardData}
            setEditCardData={setEditCardData}
            handleCancel={handleCancel}
            handleUpdateCard={handleUpdateCard}
            titleBody={titleBody}
            setTitleBody={setTitleBody}
            handeleTitleChange={handeleTitleChange}
            handleBodyChange={handleBodyChange}
            laBgImgData={laBgImgData}
            handleChangeLaBgImg={handleChangeLaBgImg}
            handleTitleClick={handleTitleClick}
            handleBodyClick={handleBodyClick}
            toolbar={toolbar}
            setToolbar={setToolbar}
          /> : null}

        {cardType === 'imageOnly' ?
          <ImageOnly
            suggestedImages={suggestedImages}
            handleSaveCard={handleSaveCard}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editCardData={editCardData}
            setEditCardData={setEditCardData}
            handleCancel={handleCancel}
            handleUpdateCard={handleUpdateCard}
            laBgImgData={laBgImgData}
            handleChangeLaBgImg={handleChangeLaBgImg}
          /> : null}

        {cardType === 'videoTitleBody' ?
          <VideoTitleBody
            handleSaveCard={handleSaveCard}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editCardData={editCardData}
            setEditCardData={setEditCardData}
            handleCancel={handleCancel}
            handleUpdateCard={handleUpdateCard}
            titleBody={titleBody}
            setTitleBody={setTitleBody}
            handeleTitleChange={handeleTitleChange}
            handleBodyChange={handleBodyChange}
            laBgImgData={laBgImgData}
            handleChangeLaBgImg={handleChangeLaBgImg}
            handleTitleClick={handleTitleClick}
            handleBodyClick={handleBodyClick}
            toolbar={toolbar}
            setToolbar={setToolbar}
          /> : null}

        {cardType === 'imageTitle' ?
          <ImageTitle
            list={list}
            setList={setList}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            suggestedImages={suggestedImages}
            handleSaveCard={handleSaveCard}
            laBgImgData={laBgImgData}
            handleChangeLaBgImg={handleChangeLaBgImg}
            handleTitleClick={handleTitleClick}
            toolbar={toolbar}
            setToolbar={setToolbar}
          /> : null}

        {cardType === 'titleBody' ?
          <TitleBody
            handleSaveCard={handleSaveCard}
            isEdit={isEdit}
            editCardData={editCardData}
            handleCancel={handleCancel}
            handleUpdateCard={handleUpdateCard}
            titleBody={titleBody}
            setTitleBody={setTitleBody}
            handeleTitleChange={handeleTitleChange}
            handleBodyChange={handleBodyChange}
            laBgImgData={laBgImgData}
            handleChangeLaBgImg={handleChangeLaBgImg}
            handleTitleClick={handleTitleClick}
            handleBodyClick={handleBodyClick}
            toolbar={toolbar}
            setToolbar={setToolbar}
          /> : null}

        {cardType === 'body' ?
          <Body
            handleSaveCard={handleSaveCard}
            isEdit={isEdit}
            editCardData={editCardData}
            handleCancel={handleCancel}
            handleUpdateCard={handleUpdateCard}
            titleBody={titleBody}
            setTitleBody={setTitleBody}
            handeleTitleChange={handeleTitleChange}
            handleBodyChange={handleBodyChange}
            laBgImgData={laBgImgData}
            handleChangeLaBgImg={handleChangeLaBgImg}
            handleBodyClick={handleBodyClick}
            toolbar={toolbar}
            setToolbar={setToolbar}
          /> : null}



        {cardType === 'statements' ? <Statements /> : null}
        {cardType === 'quotes' ? <Quotes /> : null}
        {cardType === 'bulletList' ? <BulletList /> : null}
        {cardType === 'numberedList' ? <NumberedList /> : null}
        {cardType === 'table' ? <Table /> : null}
        {cardType === 'graph' ? <Graph /> : null}
        {cardType === 'loanCalculator' ? <LoanCalculator /> : null}
        {cardType === 'uploadOrCreate' ? <Disclaimer p='0 0.5rem' mt='1rem' bgColor='unset' /> : null}
      </div>
    </>
  )
}

export default EditContentSection;
