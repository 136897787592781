import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    CreateCompany_Container: {
        display: "flex",
        height: "100%",
        width: "100%",
        backgroundColor: "#FFFFFF",
    },
    Left_div: {
        // border: "1px solid red",
        height: "100%",
        width: "50%",
    },
    Inside_Left_div: {
        padding: "1rem"
    },
    Title: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Montserrat",
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: "Bolder",
        characterSpacing: "0",
        lineSpacing: "155",
        padding: '0.5rem 0'
    },
    Label: {
        display: "block",
        fontFamily: "Montserrat",
        color: "#484848",
        fontSize: "12px",
        fontWeight: "Bold",
        characterSpacing: "0",
        lineSpacing: "146",
        padding: "0.6rem 0"
    },
    Input_Box: {
        fontFamily: "Open Sans",
        fontSize: "11px",
        fontWeight: "400",
        display: "block",
        width: "50%",
        padding: "0.5rem",
        margin: "0.5rem 0",
        border: "none",
        // boxShadow: "0 0 6px #DDE0E2",
        border: "1.4px solid #70707050",
        color: "#707070",
        fontSize: "0.8rem"
    },
    Save_Btn: {
        backgroundColor: "#F4511E",
        color: "#FFFFFF",
        fontFamily: "Montserrat",
        fontSize: "12px",
        fontWeight: "Bold",
        characterSpacing: "0",
        lineSpacing: "82",
        padding: "0.6rem 2.5rem",
        border: "none",
        borderRadius: "2px",
        cursor: "pointer",
        "&:hover": {
            opacity: "0.9"
        }
    },
    CheckBoxContainer: {
        height: '2rem',
        margin: "0.8rem 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E" },
        '& > .MuiFormGroup-root': {
            '& > .MuiFormControlLabel-root': {
                marginRight: "0 !important",
                marginLeft: "-0.5rem !important",
                '& > .MuiButtonBase-root': {
                    marginRight: '0.5rem'
                }
            }
        }
    },
    CheckBox: {
        // border: '1px solid black',
        '& .MuiTypography-root': {
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: 600,
        }
    },
}))

export default useStyles;