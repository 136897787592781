import React , {useState} from 'react'

//mui
import { makeStyles } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';

//components
import DragDropFiles from '../../../drag-drop-files-uploader'
import CustomButton from '../../../buttons';
import Loader from '../../../custom-styled/loader';

//constants
import constants from '../../../../constants';

const UploadOrCreate = ({
  selectedColumns,
  setSelectedColumns,
  setShowCoverAndObjective,
  setShowImportBtn,
  setPreview,
  setSelectedCard,
  handleBulkUpload,
  loadingCard,
}) => {
  const classes = useStyles();

  const handleChange = (files) => {
    //HERE WE NEED TO CHECK THE SIZE OF EACH IMAGE AND THROW ERROR IF TOO LARGE.
    handleBulkUpload(files);
  };

  const handleCreateOwn = () => {
    setShowCoverAndObjective(false)
    setShowImportBtn(true)
    setPreview(constants.LA_DEFAULT_IMAGE)
    setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' })
    setSelectedColumns({ ...selectedColumns, firstColumn: 'createOwn' })
  }
  const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "WEBP"];


  return (
    <div className={classes.uploadCreateCard}>
        {loadingCard ?
        <Loader/>
          :
          <>
            <div className={classes.dragDrop}>
              <DragDropFiles handleChange={handleChange} typesAccepted={fileTypes} />
            </div>
            <h3>OR</h3>
            <CustomButton btnText={"CREATE YOUR OWN"} fw={800} ls={1} handleClick={handleCreateOwn} />
          </>
        }
      </div>
  )
}

export default UploadOrCreate


const useStyles = makeStyles(() => ({
  mainContainer: {
    // border : '1px solid red',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center'
  },
  dragDrop: {
    height: '38%',
    width: '100%'
    // border: '1px solid',
    // '& label':{
    //   height : '100%',
    //   width : '100%',
    //   // opacity : '0'
    // }
  },
  uploadCreateCard: {
    // margin: '1rem',
    // border: '1px solid',
    backgroundColor: '#fff',
    borderRadius: '10px',
    height: '84%',
    padding: '1rem',
    aspectRatio: 0.79,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2rem',
    '& > h3': {
      color: '#f4511e',
      fontSize: '0.8rem',
      fontFamily: 'Montserrat',
    },
    '& button': {
      height: '3rem'
    }
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    '& span': {
        // border: '1px solid rgb(112, 112, 112, 0.2)',
        width: '3rem !important',
        height: '3rem !important',
        '& svg': {
            filter: 'invert(42%) sepia(76%) saturate(3287%) hue-rotate(349deg) brightness(97%) contrast(98%)'
        }
    }
},

}));