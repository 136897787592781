import React, { useState, useEffect, useContext } from 'react'

//components
import Context from '../context-input';
import Label from '../../../label';
import FileuploadInput from '../fileupload-input';
import SkillTags from '../skill-tags';
import ResponseMA from './ma-response';

//global-states
import { GlobalStates } from '../../../../../../App';


const MultipleAnswers = ({
  listitem,
  isUnChecked,
  isDisableAll,
  itemId,
  list,
  setList,
  question,
  questionValidation,
  designType,
  handleQuestionChange,
  tags,
  selectedTag,
  handleTagClick,
  responsesCharLimit }) => {
  const [globalStates] = useContext(GlobalStates);
  // const [selectedTag, setSelectedTag] = useState(null);
  const [maResponse, setMaResponse] = useState(listitem.answers)
  const [unChecked, setUnChecked] = useState(listitem.unCheckedOptions)
  const [selectedValues, setSelectedValues] = React.useState([]);
  const [imageFileName, setImageFileName] = React.useState(listitem.fileName);
  const [imageFile, setImageFile] = React.useState(listitem.imageFile);
  const [emptyAnswers, setEmptyAnswers] = useState(listitem.emptyAnswers);
  let imgWarningText = 'Max file size: 2 MB | Formats supported: JPG, PNG, SVG, GIF'
  useEffect(() => {
    let result = list.filter((ele, i) => ele.id === itemId)
    console.log('MultipleAnswers result = ', result[0]);
    console.log('MultipleAnswers designType = ', designType);
    console.log('Result selectedValues = ', selectedValues);
    setMaResponse(listitem.answers)
    // setImageFileName(listitem.fileName);
    //HERE WE ARE SETTING ALREADY SELECTED 4 OPTION ON RE-RENDER i-e, COLLAPSE
    let tempValues = [];
    result[0].answers.forEach((element, i) => {
      if (element.value === true) {
        tempValues.push(i)
      }
    });
    setSelectedValues(tempValues);
  }, [list, maResponse])

  // const handleTagClick = (e) => {
  //   const id = parseInt(e.target.id);
  //   setSelectedTag(id)
  // }


  const handleMaResponseChange = (id, value) => {
    console.log('handleMaResponseChange = ', id, " ", value);
    // let id = parseInt(e.target.id)
    // let value = e.target.value;

    let temp = [...maResponse]
    temp[id].option = value.toString();
    setMaResponse(temp);
    let tempEmpty = [...emptyAnswers]
    tempEmpty[id].isEmpty = false;
    setEmptyAnswers(tempEmpty);
  }

  const handleSelectImage = (e) => {
    console.log('handleFileChange = ', e.target.files[0]);
    if(isDisableAll) return
    let file = e.target.files[0]
    if (file.size > 2500000) {
      globalStates.handleToast(true, `File size greater than 2 MB.`, 'error');
      return
    }
    setImageFileName(e.target.files[0].name);
    let tempList = [...list];
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].id === listitem.id) {
        tempList[i].imageFile = e.target.files[0];
        tempList[i].fileName = e.target.files[0].name;
        tempList[i].isImageEmpty = false;
      }
    }
    // console.log('handleFileChange templist = ', tempList);
    // setList(tempList);
  }

  const handleMaCheckboxChange = (e) => {
    let id = parseInt(e.target.value)
    console.log('handleMaCheckboxChange = ', id);
    console.log('handleMaCheckboxChange = ', selectedValues);
    let tempUnChecked = [...unChecked];
    for(let i=0; i<tempUnChecked.length; i++){
      tempUnChecked[i].unChecked = false;
    }
    setUnChecked(tempUnChecked);
    if (selectedValues.includes(id)) {
      let index = selectedValues.indexOf(id)
      console.log('ResponseMA index = ', index);
      let temp = [...selectedValues]
      temp.splice(index, 1)
      setSelectedValues(temp);
      let tempResponse = [...maResponse]
      tempResponse[id].value = false
      setMaResponse(tempResponse)
      return
    }
    if (selectedValues.length >= 4) {
      globalStates.handleToast(true, `More than 4 correct options aren't allowed`, 'error');
      return
    }
    let temp = [...selectedValues];
    temp.push(id);
    setSelectedValues(temp);
    let tempResponse = [...maResponse];
    tempResponse[id].value = true;
    setMaResponse(tempResponse);

  };
  return (
    <>
      <Context
        defaultValue={question}
        handleContextChange={handleQuestionChange}
        isDisabled={isDisableAll}
        designType={designType}
        limitText={questionValidation.limitText}
        isExceeded={questionValidation.exceeded}
        isEmpty={listitem.isQuestionEmpty}
      />
      {
        (designType === 'MA_RevealThePicture') ?
          <div style={{ margin: '0.7rem 0rem' }}>
            <Label labelText={'Image:'} />
            <FileuploadInput
              isDisabled={isDisableAll}
              fileName={imageFileName}
              withWarning={true}
              warningText={imgWarningText}
              handleChange={handleSelectImage} />
          </div>
          : null
      }
      <SkillTags tags={tags} selectedTag={selectedTag} handleTagClick={handleTagClick} />
      <ResponseMA
        listitem={listitem}
        isUnChecked={isUnChecked}
        isDisableAll={isDisableAll}
        maResponse={maResponse}
        handleMaResponseChange={handleMaResponseChange}
        handleMaCheckboxChange={handleMaCheckboxChange}
        responsesCharLimit={responsesCharLimit}
      />
    </>
  )
}

export default MultipleAnswers;