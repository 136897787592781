import React from 'react';
import styled from 'styled-components';

function LinearProgress(props) {
  return (
    <Wrapper {...props}>
      <Filler {...props}></Filler>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 89.9px;
  height: 6px;
  border-radius: 5px;
  background-color:${props => props?.bgColr ? props?.bgColr : '#f2f2f2'} ;
`;

const Filler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.width + '%'};
  height: 6px;
  border-radius: 5px;
  background-color: #ffdd00;
`;

export default LinearProgress;