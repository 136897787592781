import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';

const UploadContainer = ({
    height = '100%',
    width = '100%',
    handleChange,
    accepted = ['JPG', 'PNG', 'JPEG', 'GIF', 'SVG', 'WEBP'],
    textColor = '#fff',
    iconColor = '#fff'
}) => {
    // const iconFilter = iconColor === '#f4511e' ? 'filter: invert(42%) sepia(34%) saturate(7347%) hue-rotate(352deg) brightness(102%) contrast(91%);' : '';
    const iconFilter = (iconColor === '#f4511e') ? 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(-36deg) brightness(99%) contrast(93%)':''
     const classes = useStyles({ height, width, textColor, iconFilter });
    return (
        <Button variant="outlined" component="label" className={classes.wrapper} onChange={handleChange}>
            <img src='/images/learning-aid-assets/add.svg' alt='' />
            <h5>UPLOAD IMAGE</h5>
            <h6>Supported types: {accepted.join(', ')} </h6>
            <input hidden accept="image/*" type="file" />
        </Button>

    )
}

export default UploadContainer;

const useStyles = makeStyles(() => ({
    wrapper: {
        // backgroundColor: 'rgb(0,0,0,0.5 )',
        borderRadius: '4px',
        height: ({ height }) => (height),
        width: ({ width }) => (width),
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#f4511e',
        '& > img': {
            height: '3rem',
            width: '3rem',
            filter: ({ iconFilter }) => (iconFilter),
            // filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)'
        },
        '& h5': {
            color: ({ textColor }) => (textColor),
            fontSize: '0.8rem',
            fontWeight: 800,
            fontFamily: 'Montserrat'
        },
        '& h6': {
            color: ({ textColor }) => (textColor),
            fontSize: '0.6rem',
            fontWeight: 800,
            fontFamily: 'Montserrat'
        },
        border: '2px dashed rgb(204, 204, 204, 0.5)',
        borderColor: 'rgb(204, 204, 204, 0.5) !important',
        '&:hover': {
            border: 'none',
        }
    },

}));