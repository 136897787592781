let formQuestionLength = 110;
let formAnswerLength = 85;

const validateQuestion = (question) => {
    if (!question.length) {
        return 'Question required!'
    } else {
        return 'Valid'
    }
    // if (question.length > formQuestionLength) {
    //     return 'Characters exceeded!'
    // } else if (!question.length) {
    //     return 'Question required!'
    // } else {
    //     return 'Valid'
    // }
}

const validateAnswer = (answer) => {
    console.log(answer);
    if (!answer.length) {
        return 'Answer required!'
    } else {
        return 'Valid';
    }
    // if (answer.length > formAnswerLength) {
    //     return 'Characters exceeded!';
    // } else if (!answer.length) {
    //     return 'Answer required!'
    // } else {
    //     return 'Valid';
    // }
}

const validateListitem = (listItem, isAssessment) => {
    console.log(listItem)
    let newStatus = 'done'
    let isQuestionEmpty = false;
    let tempValidations = []
    let isQuestionValid = validateQuestion(listItem.question);
    if (isQuestionValid !== 'Valid') {
        newStatus = 'error'
        tempValidations.push(isQuestionValid)
        console.log('Question is not valid ');
    }
    if (isQuestionValid === 'Question required!') {
        isQuestionEmpty = true
    }

    if (listItem.scorePerResponse === 0 && isAssessment) {
        newStatus = 'error'
        tempValidations.push('Score must be greater than 0');
    }
    else if (listItem.scorePerResponse === 0 && listItem.isScore) {
        newStatus = 'error'
        tempValidations.push('Score must be greater than 0');
    }
    else {
        let isScoreValid = listItem.scorePerResponse < 0;
        if (isScoreValid) {
            newStatus = 'error'
            tempValidations.push('Score must be greater than 0');
        }
    }

    if (listItem.answers.length === 0 && (listItem.type === 'SINGLE_CHOICE_QUESTION' || listItem.type === 'MULTI_CHOICE_QUESTION')) {
        newStatus = 'error'
        tempValidations.push('Answer is required')
    }

    let tempObj = listItem.answers;

    tempObj.map((temp) => {
        let isAnswerValid = validateAnswer(temp.option);
        if (isAnswerValid !== 'Valid') {
            newStatus = 'error';
            tempValidations.push(isAnswerValid);
            console.log('Answer is not valid')
        }
        if (isAnswerValid === 'Answer required!') {
            console.log('it is marked answer is empty');
            temp.isAnswerEmpty = true;
        }
    })

    let countCorrectAns = listItem.answers.filter((temp) => temp.value === true);
    if (countCorrectAns.length === 0 && (listItem.type === 'SINGLE_CHOICE_QUESTION' || listItem.type === 'MULTI_CHOICE_QUESTION')) {
        newStatus = 'error';
        tempValidations.push('No Answer is marked Correct!')
    }

    return { ...listItem, isQuestionEmpty, status: newStatus, validations: tempValidations, answers: tempObj }
}

const validateList = (list, isAssessment) => {
    let newValidatedList = [];
    list.forEach(ele => {
        let newListitem = validateListitem(ele, isAssessment);
        newValidatedList.push(newListitem)
    })

    // setFormQuestion(validatedList);
    return newValidatedList
}

const isEachListitemValid = (list) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].status === 'error') {
            console.log('element with error = ', list[i]);
            return { isValid: false, idx: i }
        }
    }
    return { isValid: true, idx: null }
}

export default {
    validateList,
    isEachListitemValid
}