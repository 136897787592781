import React from 'react'

//styles-common
import commonStyles from './index.styles';

const Table = () => {
  const styles = commonStyles();

  return (
    <div className={styles.unavailable}>
     CURRENTLY UNAVAILABLE!
    </div>
  )
}

export default Table;
