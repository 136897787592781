import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import MCQ from '../interactive-learning-aids/mcq';
import MTRR from '../interactive-learning-aids/mtrr';
import FillInTheBlankV1 from '../interactive-learning-aids/fill-in-the-blanks-v1';
import FillInTheBlankV2 from '../interactive-learning-aids/fill-in-the-blanks-v2';
import TrueFalse from '../interactive-learning-aids/true-false';
import Sequence from '../interactive-learning-aids/sequence';
import ControlledSwitches from '../../../custom-styled/switch-button';

//utils
import isEmpty from '../../../../utils/isEmpty';

//constants
import constants from '../../../../constants';

const EditInteraction = ({ list, setList, ilaCardId, selectedQuestion, selectedInteraction }) => {
  const classes = useStyles();
  const [activeQuestion, setActiveQuestion] = useState({ designType: '' });
  const [isMandatoryChecked, setIsMandatoryChecked] = useState(false);
  const [questionScore, setQuestionScore] = useState(0);
  const [isScoreChecked, setIsScoreChecked] = useState(true);
  let mtrrDesignTypes = ["ILA_MTRR_1", "ILA_MTRR_2"];
  console.log('EditInteraction selectedInteraction = ', selectedInteraction);

  // let isMandatoryChecked = isEmpty(activeQuestion) ? false : activeQuestion.mandatory;
  // let questionScore = isEmpty(activeQuestion) ? 0 : activeQuestion.score;
  // console.log('questionScore = ', questionScore);
  // let isScoreChecked = isEmpty(activeQuestion) ? true : (questionScore === 0) ? false : true;


  useEffect(() => {
    // console.log('selectedQuestion updated ');
    // // console.log('activeQuestion = ', activeQuestion);
    // console.log('edit-interaction mcq list = ', list);
    // console.log('edit-interaction ilaCardId = ', ilaCardId);
    // console.log('edit-interaction selectedQuestion index = ', selectedQuestion);
    // console.log('edit-interaction selectedInteraction  = ', selectedInteraction);

    for (let i = 0; i < list.length; i++) {
      if (list[i].id === ilaCardId) {
        if (selectedQuestion >= 0 && list[i].games.questions.length > 0) {
          let currentQuestion = list[i].games.questions[selectedQuestion];
          if (!currentQuestion) break;
          setActiveQuestion(currentQuestion);
          console.log('currentQuestion = ', currentQuestion);
          setIsMandatoryChecked(currentQuestion.mandatory);
          let score = currentQuestion.score;
          if (currentQuestion.designType === "ILA_MTRR_1" || currentQuestion.designType === "ILA_MTRR_2") score = currentQuestion.score / currentQuestion.questionArr.length;
          setQuestionScore(score);
          setIsScoreChecked((currentQuestion.score === 0) ? false : true);
          break;

        }
      }
    }
  }, [list, selectedQuestion, selectedInteraction.type])

  const handleMandatorySwitch = (e) => {
    // console.log('handleMandatorySwitch checked = ', e.target.checked);
    let prevChecked = !e.target.checked;
    // console.log('handleMandatorySwitch prevChecked = ', prevChecked);
    let temp = [...list];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === ilaCardId) {
        let mandatory = temp[i].games.questions[selectedQuestion].mandatory;
        let score = temp[i].games.questions[selectedQuestion].score;
        temp[i].games.questions[selectedQuestion].mandatory = !mandatory;
        console.log('list item after mandatory toggle = ', temp[i]);
        // setActiveQuestion(temp[i].games.questions[selectedQuestion]);
        break;
      }
    }
    setList(temp);
  };

  const handleScoreSwitch = (e) => {
    // console.log('handleScoreSwitch checked = ', e.target.checked);
    let prevChecked = !e.target.checked;
    // console.log('handleScoreSwitch prevChecked = ', prevChecked);
    let temp = [...list];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === ilaCardId) {
        if (prevChecked) {
          temp[i].games.questions[selectedQuestion].score = 0;
        } else {
          let defaultScore = 10;
          if (temp[i].games.gameType === "MTRR") defaultScore = 40
          temp[i].games.questions[selectedQuestion].score = defaultScore;
        }
        // console.log('list item after mandatory toggle = ', temp[i]);
        break;
      }
    }
    setList(temp);
  };

  const handleScoreChange = (e) => {
    let value = parseInt(e.target.value);
    // console.log('handleScoreChange value = ', value);
    let temp = [...list];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === ilaCardId) {
        if (temp[i].games.gameType === "MTRR") {
          let questionsLength = temp[i].games.questions[selectedQuestion].questionArr.length;
          if (!questionsLength) questionsLength = 4;
          temp[i].games.questions[selectedQuestion].score = value * questionsLength;
          break;
        } else {
          temp[i].games.questions[selectedQuestion].score = value;
          break;
        }

      }
    }
    setList(temp);
  }

  const handleQuestion = (value) => {
    let temp = [...list];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === ilaCardId) {
        // console.log('temp[i] = ', temp[i]);
        temp[i].games.questions[selectedQuestion].question = value;
        temp[i].games.questions[selectedQuestion].isQuestionEmpty = false;
        temp[i].games.questions[selectedQuestion].isQuestionRepeated = false;
        temp[i].games.questions[selectedQuestion].status = 'inProgress';
        break;
      }
    }
    setList(temp);
  }

  const handleResponse = (value, index) => {
    let listCopy = [...list];
    for (let i = 0; i < listCopy.length; i++) {
      if (listCopy[i].id === ilaCardId) {
        listCopy[i].games.questions[selectedQuestion].answers[index].answer = value;
        listCopy[i].games.questions[selectedQuestion].emptyAnswers[index].isEmpty = false;
        listCopy[i].games.questions[selectedQuestion].status = 'inProgress';
        // console.log('listCopy[i] = ', listCopy[i]);
        break;
      }
    }
    setList(listCopy);
  }

  const handleRadio = (id) => {
    let listCopy = [...list];
    for (let i = 0; i < listCopy.length; i++) {
      if (listCopy[i].id === ilaCardId) {
        let answers = listCopy[i].games.questions[selectedQuestion].answers;
        for (let j = 0; j < answers.length; j++) {
          listCopy[i].games.questions[selectedQuestion].answers[j].isCorrect = false;
        }
        listCopy[i].games.questions[selectedQuestion].answers[id].isCorrect = true;
        // console.log('listCopy after change radio value = ', listCopy[i]);
        break;
      }
    }
    setList(listCopy);
  }
 
  return (
    <div className={classes.main}>
      <div className={classes.column1}>
        <div className={classes.row1}>
          {/* {!isMandatoryChecked ?  */}
          <div className={`${classes.scoreBox} ${isScoreChecked ? classes.checked : ''} `} style={{ width: mtrrDesignTypes.includes(activeQuestion.designType) ? '45%' : '' }}>
            <h6>{mtrrDesignTypes.includes(activeQuestion.designType) ? "SCORE PER RESPONSE" : "SCORE"}</h6>
            <ControlledSwitches checked={isScoreChecked} handleChange={handleScoreSwitch} />
            {isScoreChecked ? <input type='number' style={{ width: mtrrDesignTypes.includes(activeQuestion.designType) ? '15%' : '' }} value={questionScore} onChange={handleScoreChange} /> : null}
          </div>
          {/* : null} */}
          <div className={`${classes.mandatoryBox} ${isMandatoryChecked ? classes.checked : ''}`}>
            <h6>MANDATORY</h6>
            <ControlledSwitches checked={isMandatoryChecked} handleChange={handleMandatorySwitch} />
          </div>
        </div>
        {
          selectedInteraction.interactionType === 'ILA_MCQ_1' ?
            <MCQ
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              handleQuestion={handleQuestion}
              handleResponse={handleResponse}
              handleRadio={handleRadio}
            /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_MCQ_2' ?
            <MCQ
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              handleQuestion={handleQuestion}
              handleResponse={handleResponse}
              handleRadio={handleRadio}
            /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_MTRR_1' ?
            <MTRR
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              ilaCardId={ilaCardId}
              selectedQuestion={selectedQuestion}
              list={list}
              setList={setList}
            /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_MTRR_2' ?
            <MTRR
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              ilaCardId={ilaCardId}
              selectedQuestion={selectedQuestion}
              list={list}
              setList={setList} /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_FITB_1' ?
            <FillInTheBlankV1
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              handleQuestion={handleQuestion}
              handleResponse={handleResponse}
              handleRadio={handleRadio}
              selectedInteraction={selectedInteraction}
            /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_FITB_2' ?
            <FillInTheBlankV2
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              handleQuestion={handleQuestion}
              handleResponse={handleResponse}
              handleRadio={handleRadio} /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_TF_1' ?
            <TrueFalse
              withLimitWarning={false}
              isDisabled={true}
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              handleQuestion={handleQuestion}
              handleResponse={handleResponse}
              handleRadio={handleRadio} /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_TF_2' ?
            <TrueFalse
              withLimitWarning={true}
              defaultValues={['', '']}
              isDisabled={false}
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              handleQuestion={handleQuestion}
              handleResponse={handleResponse}
              handleRadio={handleRadio} /> : null
        }
        {
          selectedInteraction.interactionType === 'ILA_SEQUENTIAL' ?
            <Sequence
              type={selectedInteraction.interactionType}
              activeQuestion={activeQuestion}
              handleQuestion={handleQuestion}
              handleResponse={handleResponse}
            /> : null
        }
      </div>
      <div className={classes.column2}>
        <div className={classes.imgBox}>
          <img src={selectedInteraction.previewUrl} alt='' />
        </div>
      </div>

    </div>
  )
}

export default EditInteraction



const useStyles = makeStyles(() => ({
  main: {
    // border : '1px solid red',
    height: '100%',
    width: '100%',
    display: 'flex'
  },
  column1: {
    // border: '1px solid green',
    height: '97%',
    width: '60%',
    paddingLeft: '12%',
    paddingBottom: '3%'
    // display: 'flex',
    // justifyContent: 'flex-end'
  },
  row1: {
    // border : '1px solid black',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '6%',
    width: '100%',
  },
  checked: {
    '& span': {
      '& .Mui-checked ': {
        color: '#f4511e',

      },
      '& .MuiSwitch-track ': {
        backgroundColor: 'rgb(244, 81, 30) !important'
      }
    }
  },
  scoreBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '27%',
    '& input': {
      width: '26%',
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
  },
  mandatoryBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '25%'
  },
  row2: {
    // border : '1px solid black',
    height: '94%',
    width: '100%',
  },
  column2: {
    // border: '1px solid red',
    height: '100%',
    width: '40%',
  },
  imgBox: {
    aspectRatio: 0.66,
    padding: '2rem',
    '& img': {
      height: '100%',
      width: '100%',
    }
  }
}));

