import { makeStyles } from "@mui/styles";
import { ThemeConsumer } from "styled-components";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    
    inputField: {
        border: '1px solid rgba(112, 112, 112, 0.3)',
        borderRadius: '4px',
        padding: '0.5rem',
        width: '300px',
        fontFamily: 'Open Sans',
        fontSize: '0.8rem',
        color: '#707070',
        '& :focus': {
            borderColor: '#f4511e',
        }
    },
    row: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
        flexDirection: 'row',
    },
    column: {
        position: 'relative',
    }
}));
export default useStyles;