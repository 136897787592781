import styled from "styled-components";

const MOTopBar = styled.div`
    background-color: #fcfcfc;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
`;

export default MOTopBar;