import React from 'react'

//components
import LearningAid from '../../../components/learning-aid'

const LearningAidPage = () => {
  return (
    <LearningAid/>
  )
}

export default LearningAidPage;
