import constants from "../constants";
import { authFetchFormData } from "../utils/fetch";

const uploadScorm = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/scorm`, data)
        // console.log("createMicroskill:", resData);
        return resData
    } catch (err) {
        // console.log("createMicroskill:",err.response);
        return err.response
    }
};

export default {
    uploadScorm
}