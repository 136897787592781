import React, { useEffect, useMemo, useRef, useState } from 'react'

//  mui
import { Switch } from '@mui/material';

//styles
import useStyles from './index.styles';

//components
import SelectedMsSequence from '../selected-ms-sequence';
import CustomSelect from '../../../custom-styled/custom-select';

// utils
import commonUtil from '../../../../utils/commonUtil';
import Step3 from '../step-three';
import Step2 from '../step-two';

const label = { inputProps: { 'aria-label': 'Group Launch' } };
const UNLOCKING_CONDITIONS = [
    {
        id: 1,
        name: 'COMPLETION',
    },
    {
        id: 2,
        name: 'SCORE',
    },
]

const LJStep2 = (props) => {

    const {
        journeyName,
        setJourneyName,
        selectedMicroskillsSequence,
        setSelectedMicroskillsSequence,
        setStep3,
        groupLaunchFlag,
        setGroupLaunchFlag,
        unlockCondition,
        setUnlockCondition,
        minScoreToUnlock,
        setMinScoreToUnlock,
        contentType,
        launchType,
        setLaunchType,
        scheduledDates,
        setScheduledDates,
        selectedNav,
        LAUNCHPG_NAV_TYPES,
        editingJourney
    } = props

    const styles = useStyles();
    const debouncingTimerRef = useRef(null);
    const [showMinScoreError, setShowMinScoreError] = useState(false);

    const _selectLaunchType = (selected) => {
        if (unlockCondition.includes(selected.name)) {
            return
        }
        // setLinkedContent([...linkedContent, item.id]); //for selecting multiple content
        setUnlockCondition([selected.name]);
    }


    return (
        <>
            <div className={styles.journeyName}>
                <h2 style={{ fontSize: '1.2rem', fontFamily: "Montserrat", color: "#707070", padding: '1rem 0rem' }}>
                    Name Your Journey
                </h2>
                <div className={styles.journeyNameInput}>
                    <input
                        className={styles.inputField}
                        placeholder='Enter Journey Name'
                        value={journeyName}
                        onChange={(event) => {
                            clearTimeout(debouncingTimerRef.current);
                            let value = event.target.value
                            setJourneyName(value)

                            // code can be useful on future
                            // if (value.length >= 3) {
                            //     debouncingTimerRef.current = setTimeout(() => {
                            //         setStep3(true);
                            //     }, 500);
                            // } else {
                            //     setStep3(false);
                            // }
                        }}

                        style={{
                            border: journeyName.length < 3 ? '1px solid #FF0000' : '',
                            outline: journeyName.length < 3 ? '1px solid #FF0000' : '',
                        }}
                    // withLimitWarning={false}
                    // isEmpty={false}
                    />
                </div>

                {/* switch for selection of group launch */}
                <h2 style={{ fontSize: '1.2rem', fontFamily: "Montserrat", color: "#707070", padding: '1rem 0rem 0' }}>
                    Group Launch
                    <Switch {...label}
                        defaultChecked={groupLaunchFlag}
                        onChange={() => setGroupLaunchFlag(prev => {
                            let flag = !prev;
                            if(!flag) {
                                setStep3(true)
                            }
                            return flag
                        })}
                        // onChange={() => setGroupLaunchFlag(true)}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': {
                                color: '#F4511E',
                            },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                backgroundColor: '#F4511E',
                            },
                        }}
                    />
                </h2>

                {/* // to change sequence of selected ms */}
                <SelectedMsSequence
                    selectedMsSeq={selectedMicroskillsSequence}
                    setSelectedMsSeq={setSelectedMicroskillsSequence}
                    groupLaunchFlag={groupLaunchFlag}
                />

                <> {/* unlock condition */}
                    <h2 style={{ fontSize: '1.2rem', fontFamily: "Montserrat", color: "#707070", padding: '1rem 0rem' }}>
                        Unlock Condition
                    </h2>
                    <div className={styles.row} style={{
                        paddingBottom: showMinScoreError ? '1rem' : '0'
                    }}>
                        <div className={styles.column}>
                            <CustomSelect
                                // defaultText={UNLOCKING_CONDITIONS[0].name}
                                defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Unlock Condition', selected: unlockCondition, dataObject: commonUtil.objectify(UNLOCKING_CONDITIONS, 'name') })}
                                autoClose={true}
                                withSearch={false}
                                withSelectAllOption={false}
                                listData={UNLOCKING_CONDITIONS}
                                selected={unlockCondition}
                                setSelected={setUnlockCondition}
                                handleSelect={_selectLaunchType}
                            />
                        </div>
                        <div className={styles.column}>
                            {
                                unlockCondition.includes('SCORE') && (
                                    <>
                                        <input
                                            className={styles.inputField}
                                            style={{ width: '125px' }}
                                            placeholder='Enter Score'
                                            value={minScoreToUnlock}
                                            type='number'
                                            min={0}
                                            max={100}
                                            onChange={(event) => {
                                                let value = event.target.value
                                                setMinScoreToUnlock(value);

                                                if (value >= 75 && value <= 100) {
                                                    setShowMinScoreError(false)
                                                    event.target.style.outline = '1px solid rgba(112, 112, 112, 0.3)'
                                                } else {
                                                    setShowMinScoreError(true)
                                                    event.target.style.outline = 'none'
                                                }
                                            }}
                                        /> %
                                    </>
                                )
                            }
                            {
                                showMinScoreError && (
                                    <h6 style={{
                                        color: 'red',
                                        position: 'absolute',
                                        top: '100%',
                                        left: '0',

                                    }}>Enter value in between 75% to 100%.</h6>
                                )
                            }
                        </div>
                    </div>
                </>

                {
                    groupLaunchFlag && (
                        <Step2
                            contentType={contentType}
                            launchType={launchType}
                            setLaunchType={setLaunchType}
                            setStep3={setStep3}
                            scheduledDates={scheduledDates}
                            setScheduledDates={setScheduledDates}
                            stepTitle='Schedule Launch'
                            selectedNav={selectedNav}
                            LAUNCHPG_NAV_TYPES={LAUNCHPG_NAV_TYPES}
                        />
                    )
                }

            </div>
        </>
    )
}

export default LJStep2;