import React, {useState, useEffect } from 'react';

//Image cropper component
import ReactCrop from 'react-image-crop';

//Styles
import useStyles from './index.styles'
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropComp = ({microskillName, brandIcon, src, croppedImageURL, setCroppedImageURL, crop, setCrop, setFinalImage}) =>{

  const styles = useStyles()
  // // const [imgLoaded, setImgLoaded] = useState(false)
  // const imageRef = useRef('')
  const [imageRef, setImageRef] = useState("")
  const [imageHeight, setImageHeight] = useState("0")
  const [imageWidth, setImageWidth] = useState("0")
  const [toggleDisplay, setToggleDisplay] = useState("flex")

  let fileUrl;

 
  

  const onImageLoaded = (image) => {
    console.log('onImageLoaded = ', image)
    setImageRef(image)
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    setCrop(percentCrop)
    if(percentCrop.width < 53 && percentCrop.height < 67){
      setToggleDisplay("none")
    }else{
      setToggleDisplay("flex")
    }
  };


  const makeClientCrop = async (crop) => {
    if (imageRef && crop.width && crop.height) {
      const croppedImageURL = await getCroppedImg(
        imageRef,
        crop,
        'newFile.jpeg'
      );
      setCroppedImageURL(croppedImageURL)
      // setFinalImage(croppedImageURL)
    }
  };

  function getCroppedImg(image, crop, fileName){
    // console.log(fileName);
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

   
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    setImageHeight(crop.height)
    setImageWidth(crop.width)
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          fileUrl = window.URL.createObjectURL(blob);
          resolve(fileUrl);
        },'image/jpeg',1);
    });
  }
  // useEffect(() => {
    
    // const defaultCropDetails = {unit: 'px', width: 550, height : 380 ,aspect: 9 / 6  , x : 90, y : 0}
    // const defaultCropDetails = {unit: '%', width: 550, height : 380 ,aspect: 9 / 6  , x : 90, y : 0}

    // const defaultCrop = async ()=>{
    //   const croppedImageURL = await getCroppedImg(
    //     imageRef,
    //     defaultCropDetails,
    //     'newFile.jpeg'
    //   );
    //   // console.log(croppedImageURL);
    //   setCroppedImageURL(croppedImageURL)
    // }
    // if(imgLoaded){
    //   defaultCrop()
    //   setImgLoaded(false)
    // }
    
 
    // console.log(toggleBtn)
  // }, [])

  

  return (
    <div  className={styles.ReactCropDiv}>
      {src && (
        <ReactCrop
          className={styles.ReactCropImg}
          src={src}
          crop={crop}
          onImageLoaded={onImageLoaded}
          onComplete={onCropComplete}
          onChange={onCropChange}
          renderSelectionAddon={() => {
              return(
                <div className={styles.OverCropImg}
                  style={{
                    height: imageHeight + "px",
                    width: imageWidth + "px",
                    display: toggleDisplay,
                    flexDirection : "column"
                  }}>
                    
                    <div className={styles.CropRow1}> 
                      <i className='fa fa-arrows' aria-hidden="true"></i>
                    </div>
                    <div className={styles.CropRow2}> 
                      <div className={styles.CropRow2_Column1}>
                        <h1 className={styles.MicroskillName}>{microskillName}</h1>
                        <div className={styles.ViewsLikes}>
                            <span className={styles.ViewsLikesSpan}>
                              <img className={styles.ViewsLikesIcons} src="/images/add-microskill/View_Icon.svg" alt=''/> 4504 
                            </span>
                            <span className={styles.ViewsLikesSpan}>
                              <img className={styles.ViewsLikesIcons} src="/images/add-microskill/View_Icon.svg" alt=''/> 2453 
                            </span>
                        </div>
                      </div>

                      <div className={styles.CropRow2_Column2}>
                            <img src={brandIcon} className={styles.BrandIcon} alt="Brand Icon"/>
                            <h4 className={styles.IconPreviewText}>ICON PREVIEW</h4>
                      </div>
                      
                    </div>
                </div>
              )
                 }}
                 />
            )}
      {/* {croppedImageURL && (
        <img className={styles.CroppedImg} alt="Cropped" src={croppedImageURL} />
      )} */}
    </div>
  );
}


export default ImageCropComp