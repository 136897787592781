import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => {
    const classes = useStyles();
    return (
        <div className={classes.progress}>
            <CircularProgress color="secondary" />
        </div>
    )
}

export default Loader;


const useStyles = makeStyles(() => ({
    progress: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor : '#F9F9F9',
        height: '100%',
        width: '100%',
        '& span': {
            // border: '1px solid rgb(112, 112, 112, 0.2)',
            width: '3rem !important',
            height: '3rem !important',
            '& svg': {
                filter: 'invert(42%) sepia(76%) saturate(3287%) hue-rotate(349deg) brightness(97%) contrast(98%)'
            }
        }
    },

}));