import constants from "../constants"

let ilaValidations = constants.ILA_VALIDATIONS;

const checkIsQuestionRepeated = (question, list) => {
    console.log('checkIsQuestionRepeated list = ', list);
    let isRepeated = false;
    let allQuestions = new Map();
    for (let i = 0; i < list.length; i++) {
        let ques = list[i].question.toLowerCase();
        if (allQuestions.has(ques)) {
            let count = allQuestions.get(ques);
            count = count + 1;
            allQuestions.set(ques, count);
        } else {
            allQuestions.set(ques, 1);
        }
    }
    console.log('questions map = ', allQuestions);
    let totalCount = allQuestions.get(question.toLowerCase());
    console.log('current questions totalCount = ', totalCount);
    if (totalCount > 1) isRepeated = true;
    return isRepeated;
}

const validateContext = (designType, context) => {
    if (context.length > ilaValidations[designType].context) {
        return 'Characters exceeded!'
    } else if (!context.length) {
        return 'Context required!'
    } else {
        return 'Valid'
    }
}



const validateQuestion = (designType, question, list = []) => {
    if (question.length > ilaValidations[designType].question) {
        return 'Characters exceeded!'
    } else if (!question.length) {
        return 'Question required!'
    } else if (designType === 'ILA_FITB_1' && !question.includes('[MBlank]')) {
        return 'MBLANK missing'
    }
    // else if (checkIsQuestionRepeated(question, list)) {
    //     return 'Repeated Question'
    // } 
    else {
        return 'Valid'
    }
}

const validateResponses = (designType, responses, emptyResponses) => {
    // console.log('validateResponses = ',responses);
    let responsesValidations = []
    let newEmptyAnswers = [...constants.ILA_QUESTION_JSON[designType].emptyAnswers];
    let newRepeatedAnswers = [...constants.ILA_QUESTION_JSON[designType].repeatedAnswers];
    // let allResponses = [];
    responses.forEach((element, i) => {
        if (element.answer.length > ilaValidations[designType].response) {
            responsesValidations.push('Characters exceeded!');
        } else if (!element.answer.length) {
            console.log('Responses missing!', element);
            responsesValidations.push('Responses missing!');
            newEmptyAnswers[i] = { isEmpty: true };
        } else if (element.answer.length > 0) {
            console.log('Responses not missing!', element);
            newEmptyAnswers[i] = { isEmpty: false };
        }

        //VALIDATION FOR REPEATED RESPONSES
        // if(allResponses.includes(element.answer.toLowerCase())){
        //     newRepeatedAnswers[i].isRepeated = true;
        //     responsesValidations.push('Duplicate responses');
        // }else{
        //     allResponses.push(element.answer.toLowerCase());
        //     newRepeatedAnswers[i].isRepeated = false;
        // }

    });


    console.log('newEmptyAnswers = ', newEmptyAnswers);
    console.log('newRepeatedAnswers = ', newRepeatedAnswers);
    return { responsesValidations, newEmptyAnswers, newRepeatedAnswers };
}

const validateListitem = (designType, listItem, list = []) => {
    console.log('validateListitem listItem = ', listItem);
    let mtrrDesignTypes = ["ILA_MTRR_1", "ILA_MTRR_2"];
    if (mtrrDesignTypes.includes(designType)) {
        console.log('mtrr designs ');
        let newStatus = 'done';
        let isContextEmpty = false;
        let tempValidations = [];

        let isContextValid = validateContext(designType, listItem.contextObj.context);
        console.log('validateContext = ', isContextValid);
        if (isContextValid !== 'Valid') {
            newStatus = 'error'
            tempValidations.push(isContextValid)
            console.log('MTRR Context is not valid ');
        }
        if (isContextValid === 'Context required!') {
            isContextEmpty = true
        }

        //SECOND CHECK => IS QUESTION VALID
        let element = [...listItem.questionArr];
        for (let i = 0; i < element.length; i++) {
            let isQuestionValid = validateQuestion(designType, element[i].question);
            if (isQuestionValid !== 'Valid') {
                newStatus = 'error'
                tempValidations.push(isQuestionValid)
            }
            if (isQuestionValid === 'Question required!') {
                element[i].isQuestionEmpty = true;
            }

            element[i].answers.forEach((ele, idx) => {
                if (ele.answer.length > ilaValidations[designType].response) {
                    tempValidations.push('Characters exceeded!');
                    newStatus = 'error'
                } else if (!ele.answer.length) {
                    tempValidations.push('Responses missing!');
                    element[i].emptyAnswers[idx] = { isEmpty: true };
                    newStatus = 'error'
                } else if (ele.answer.length > 0) {
                    element[i].emptyAnswers[idx] = { isEmpty: false };
                }

            });
        }

        let uniqueValidations = new Set(tempValidations);
        console.log('MTRR after element = ', element);
        let newListitem = {
            ...listItem,
            status: newStatus,
            validations: [...uniqueValidations],
            isContextEmpty: isContextEmpty,
            questionArr: element
        }
        console.log('newListitem = ', newListitem);

        return newListitem;
    }
    let allowed = ['ILA_MCQ_1', 'ILA_MCQ_2', 'ILA_TF_1', 'ILA_TF_2', 'ILA_FITB_1', 'ILA_FITB_2', 'ILA_SEQUENTIAL']
    if (allowed.includes(designType)) {
        let newStatus = 'done';
        let isQuestionEmpty = false;
        let isQuestionRepeated = false;
        let tempValidations = [];
        //FIRST CHECK => IS QUESTION VALID
        let isQuestionValid = validateQuestion(designType, listItem.question, list);
        console.log('isQuestionValid = ', designType, " ", isQuestionValid);
        if (isQuestionValid !== 'Valid') {
            newStatus = 'error';
            tempValidations.push(isQuestionValid);
            console.log('Question is not valid ');
        }
        if (isQuestionValid === 'Question required!') {
            isQuestionEmpty = true;
        }

        //VALIDATION FOR REPEATED QUESTION
        // if (isQuestionValid === 'Repeated Question') {
        //     isQuestionRepeated = true
        // }

        //SECOND CHECK => ARE RESPONSES VALID
        let validatedResponses = validateResponses(designType, listItem.answers, listItem.emptyAnswers);
        console.log('validatedResponses = ', validatedResponses);
        //IF ANY INVALIDATION IN RESPONSES
        if (validatedResponses['responsesValidations'].length) {
            newStatus = 'error'
        }
        let newValidations = tempValidations.concat(validatedResponses['responsesValidations']);
        let uniqueValidations = new Set(newValidations);
        console.log('newStatus = ', newStatus);
        console.log('uniqueValidations = ', uniqueValidations);
        let newListitem = {
            ...listItem,
            status: newStatus,
            validations: [...uniqueValidations],
            isQuestionEmpty: isQuestionEmpty,
            isQuestionRepeated: isQuestionRepeated,
            emptyAnswers: validatedResponses['newEmptyAnswers'],
            repeatedAnswers: validatedResponses['newRepeatedAnswers']
        }
        console.log('newListitem = ', newListitem);
        return newListitem;
    }

    return listItem;

}

const isEachListitemValid = (list) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].status === 'error') {
            console.log('element with error = ', list[i]);
            return { isValid: false, index: i }
        }
    }
    return { isValid: true, index: null }
}

export default { validateListitem, isEachListitemValid };
