import { authFetch } from "../utils/fetch";
import { authFetchFormData } from "../utils/fetch";

const fetchContexts = async () => {
    try {
        const resData = await authFetch.get(`/context?startIndex=0&limit=10&sort=createdOn&sortDirection=asc`);
        return resData
    } catch (err) {
        return err.response
    }
}
const createContext = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/context`, data);
        return resData
    } catch (err) {
        return err.response
    }
}

const updateContext = async (data) => {
    try {
        const resData = await authFetch.put(`/context`, data);
        return resData
    } catch (err) {
        return err.response
    }
}

const deleteContext = async (data) => {
    try {
        const resData = await authFetch.delete(`/context`, {data});
        return resData
    } catch (err) {
        return err.response
    }
}


export default { fetchContexts, createContext, updateContext, deleteContext }