import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles } from "@mui/styles";
import TopBarWrapper from '../tms-components/TopBarWrapper';
import MOTopBar from '../tms-components/MOTopBar';
import TopBarHeading from '../tms-components/TopBarHeading';
import MOSelect from '../tms-components/MOSelect';
// import TableHead from '@material-ui/core/TableHead';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
// import TableBody from '@material-ui/core/TableBody';
// import Table from '@material-ui/core/Table';
// import TableCell from '@material-ui/core/TableCell';
// import TableRow from '@material-ui/core/TableRow';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import { Dialog } from '@material-ui/core';
// import { withStyles } from '@material-ui/core';
import TrashIcon from '../tms-components/MDLIcon/icons/Trash';
import LinearProgress from '../tms-components/LinearProgress';
import EditIcon from '../tms-components/MDLIcon/icons/EditIcon';
import DuplicateIcon from '../tms-components/MDLIcon/icons/Duplicate';
import commonUtil from '../../utils/commonUtil';
import FormControl from '../tms-components/FormControl';
import Button2 from '../tms-components/Button2';
import Loader from '../tms-components/Loader';
import CustomButton from '../custom-styled/button';

//new
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ButtonBase from '@mui/material/ButtonBase';
import Dialog from '@mui/material/Dialog';

//utils
import useQuery from '../../utils/useQuery';

//services
import courseServices from '../../services/course';
import constants from '../../constants';

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const useStyles = makeStyles(() => ({
  btnBox: {
    // border : '1px solid pink',
    position: 'absolute',
    top: -56,
    right: 5,
    width: '11rem',
    '& > .MuiButtonBase-root': {
      border: '2px solid #f4511e',
      borderRadius: '3px',
      color: '#fff',
      backgroundColor: '#f4511e'
    }
  }
}));



function CourseLog(props) {
  console.log('CourseLog props = ', props);
  const classes = useStyles();
  const query = useQuery();
  const companyId = query.get('c_id');
  const roles = localStorage.getItem('roles');
  let [order, setOrder] = React.useState('desc');
  let [orderBy, setOrderBy] = React.useState('score');
  const [modules, setModules] = React.useState([]);
  console.log('CourseLog modules = ', modules);
  const [filterModule, setFilterModule] = React.useState('All');
  const [activeCourse, setActiveCourse] = React.useState(0);
  const [deletePopup, setDeletePopup] = React.useState(false);
  const [progressDialogOpen, setProgressDialogOpen] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const [errorDialogText, setErrorDialogText] = React.useState(
    'There was an unknown error, please try again?',
  );
  const [courseObject, setCourseObject] = useState({});

  useEffect(() => {

    if (props?.companyInfo?.modules) {
      setModules([
        { id: 'All', display_text: 'All' },
        ...props.companyInfo.modules.map((m) => ({
          id: m.id,
          display_text: m.name,
        })),
      ])
    }

  }, [props])

  useEffect(() => {
    fetchCourseTypes();
  }, [])

  const fetchCourseTypes = async () => {
    const res = await courseServices.fetchCourseTypes();
    console.log('fetchCourseTypes res = ', res);
    if (res.status === 200 || res.status === 201) {
      let data = res.data.data;
      let tempCourseObject = {};
      data.forEach(element => {
        tempCourseObject[element.id] = element.name;
      })
      console.log('tempCourseObject = ', tempCourseObject);
      setCourseObject(tempCourseObject);
    }
  }

  const handleCreateCourse = () => {
    if (!roles.includes("CREATE_COURSE")) {
      setErrorDialogText('ACCESS DENIED');
      setErrorDialogOpen(true)
      return
    }
    props.history.push(`/drive/courses-and-training/create-new-course?c_id=${companyId}`);
  }

  function handleSortClick(sorter) {
    if (order === 'desc') {
      setOrder('asc');
    } else setOrder('desc');

    setOrderBy(sorter);
  }

  async function deleteCourse() {
    setDeletePopup(false);
    setProgressDialogOpen(true);
    // console.log('deleteCourse = ', activeCourse);
    const id = activeCourse;
    const res = await courseServices.deleteCourse({ id });
    // console.log('deleteCourse res = ', res);
    if (res.status === 200 || res.status === 201) {
      setProgressDialogOpen(false);
      props.onDelete();
    } else {
      setErrorDialogOpen(true);
      setErrorDialogText('Internal server error');
    }

    //OLD 
    // let action_delete = props.MOClient.delete_training_course({
    //   course_id: activeCourse,
    //   token: props.jwtToken,
    // });

    // action_delete.then((result) => {
    //   setProgressDialogOpen(false);
    //   if (result.status !== 'success') {
    //     setErrorDialogOpen(true);
    //     setErrorDialogText(result.description);
    //   } else {
    //     props.onDelete();
    //   }
    // });
  }

  // const courseObject = {
  //   'clgf5d296000hqnlv0ro33trw': 'Blended',
  //   'clgkuscuk000lqn5wngi1jkoz': 'Standalone',
  //   'clgkut06c000nqn5w0svdecrp': 'Webinar',
  //   'clgkutj25000pqn5wacr0ygbv': 'Internal',
  //   'clgkuu57c000rqn5wtck1tv2s': 'In-house',
  //   'clgkuulsn000tqn5wkbi6v072': 'External',
  // };

  let rows = props.courses.map((course) => {
    console.log('course row = ', course);
    let msCount = 0;
    for (let i = 0; i < course.CourseEvents.length; i++) {
      msCount += course.CourseEvents[i]['_count']["Microskills"]
    }
    console.log('msCount = ', msCount);
    return {
      id: course.id,
      name: course.name,
      course_type: courseObject[course.courseTypeId],
      events: course.CourseEvents.length,
      microskills: msCount
    };
  });

  // let coursesObject = commonUtil.objectify(props.courses, 'id');
  // let courseEventsObject = commonUtil.objectify(props.courseEvents, 'id');
  // let modulesObject = commonUtil.objectify(props.companyInfo.modules, 'id');

  function get_filtered_records() {
    let filtered_course_ids = [];
    if (filterModule !== 'All') {
      // process moduleFilter
      for (let courseEvent of props.courseEvents) {
        if (
          courseEvent.modules !== null &&
          courseEvent.modules.indexOf(filterModule) > -1
        ) {
          filtered_course_ids.push(courseEvent.course_id);
        }
      }

      let rows_filtered = rows.filter((row) => {
        return filtered_course_ids.indexOf(row.id) > -1;
      });

      return rows_filtered.sort((a, b) => {
        return a.id > b.id ? -1 : 1;
      });
    } else {
      // let sorted = rows.sort((a, b) => {
      //   return a.id > b.id ? -1 : 1;
      // });
      // console.log('all rows of table = ', rows);

      // return rows;
      let sorted = rows.sort((a, b) => {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
      });
      console.log('sorted rows = ', sorted);
      return sorted;
    }
  }



  return (
    <div style={{ backgroundColor: '#fff', minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})` }}>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Course Log</TopBarHeading>
        </MOTopBar>
      </TopBarWrapper>

      <Dialog
        open={deletePopup}
        onClose={() => {
          setDeletePopup(false);
        }}>
        <ProgressContainer>
          <ProgressText>
            Are you sure you want to delete this Course?
          </ProgressText>
          <ProgressText>
            <FormControl>
              <Actions>
                <Button2 onClick={deleteCourse}>Yes Delete</Button2>
                <Button2
                  onClick={() => setDeletePopup(false)}
                  transparent={true}
                  style={{ marginLeft: '5px' }}>
                  Cancel
                </Button2>
              </Actions>
            </FormControl>
          </ProgressText>
        </ProgressContainer>
      </Dialog>

      <Dialog
        open={errorDialogOpen}
        onClose={() => {
          setErrorDialogOpen(false);
        }}>
        <ProgressContainer>
          <img
            src={require('../../assets/cross.png')}
            style={{ width: '50px' }}
          />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorDialogText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setErrorDialogOpen(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialogOpen}
        onClose={() => {
          setProgressDialogOpen(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />
          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      <Wrapper>
        <Label>Microskill</Label>
        <MOSelect
          options={modules}
          onSelect={(id) => {
            setFilterModule(id);
          }}
          text={((props) => {
            if (filterModule === 'All') {
              return 'All';
            } else {
              let filtered = props.companyInfo.modules.filter((m) => {
                return m.id === filterModule;
              });
              return filtered[0].name;
            }
          })(props)}
        />

        <div style={{ marginTop: 15, position: 'relative' }}>
          <div className={classes.btnBox}>
            <CustomButton
              fs="0.7rem"
              fw={700}
              ls={0}
              btnText={'Create New Course +'}
              handleClick={handleCreateCourse}
            />

          </div>
          <Table style={{ border: '1px solid rgba(0,0,0,0.1)' }}>
            <TableHead sx={{
              '& .MuiTableCell-root': {
                color: '#484848',
                fontSize: '11px',
                minWidth: '100px',
                fontWeight: 'bold',
              }
            }}>
              <CustomTableRow>
                <CustomTableCell align="center">
                  <TableSortLabel
                    hideSortIcon={true}
                    active={orderBy === 'name'}
                    direction={order}
                  // onClick={() => handleSortClick('name')}
                  >
                    Name
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <TableSortLabel hideSortIcon={true}>
                    Course Type
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <TableSortLabel
                    hideSortIcon={true}
                    active={orderBy === 'microskills'}
                    direction={order}
                  // onClick={() => handleSortClick('microskills')}
                  >
                    Microskills
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <TableSortLabel hideSortIcon={true}>
                    Training Events
                  </TableSortLabel>
                </CustomTableCell>

                <CustomTableCell align="center">
                  <StyledTabelHeaderLabel hideSortIcon={true}>
                    Control Panel
                  </StyledTabelHeaderLabel>
                </CustomTableCell>
              </CustomTableRow>
            </TableHead>
            <TableBody sx={{
              '& .MuiTableCell-root': {
                color: '#707070',
                fontSize: '12px',
                minWidth: '100px',
              }
            }}>
              {get_filtered_records().map((row) => (
                <CustomTableRow key={row.id}>
                  <CustomTableCell align="center">
                    <span>{row.name}</span>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <span>
                      {row.course_type}
                    </span>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <p
                      style={{
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                      }}>
                      {row.microskills}
                    </p>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <span>
                      {row.events}
                    </span>
                  </CustomTableCell>

                  <CustomTableCell align="center">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                      }}>
                      <CurvedButtonBase
                        onClick={() => {
                          props.history.push(`/drive/courses-and-training/courses/edit/${row.id}?c_id=${companyId}`);
                        }}>
                        <EditIcon fill="#707070" />
                      </CurvedButtonBase>
                      <CurvedButtonBase
                        onClick={() => {
                          setActiveCourse(row.id);
                          setDeletePopup(true);
                        }}>
                        <StyledTrashIcon />
                      </CurvedButtonBase>
                    </div>
                  </CustomTableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Wrapper>
    </div>
  );
}

const Digit = styled.h4`
  font-family: 'Montserrat';
  font-size: 52px;
  line-height: 1rem;
  display: block;
  font-weight: bold;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  color: ${(props) => props.color};
`;

const Wrapper = styled.div`
  padding: 30px;
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const DigitCaption = styled.h5`
  font-family: 'Montserrat';
  font-size: 11px;
  font-weight: bold;
  text-align: left;
  color: #484848;
`;

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 3px;
  padding: 5px;
`;

// const CustomTableCell = withStyles((theme) => ({
//   root: {
//     padding: '8px 10px !important',
//     display: 'flex',
//     minWidth: '100px',
//     alignItems: 'center',
//     justifyContent: 'center',
//     flexBasis: '0',
//     flexGrow: '1',
//     fontSize: '12px',
//     color: '#707070',
//   },
//   head: {
//     backgroundColor: '#fbfbfb',
//     color: '#484848',
//     fontWeight: 'bold',
//     display: 'flex',
//     minWidth: '100px',
//     alignItems: 'center',
//     flexBasis: '0',
//     flexGrow: '1',
//     fontSize: '11px',
//   },
// }))(TableCell);

// const CustomTableRow = withStyles((theme) => ({
//   root: {
//     display: 'flex',
//   },
//   head: {
//     display: 'flex',
//   },
// }))(TableRow);

const CustomTableCell = TableCell;

const CustomTableRow = TableRow;

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #707070;
  }
`;

const Label = styled.h5`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 10px;
  margin: 0;
  margin-bottom: 5px;
  color: #484848;
  text-align: left;
`;

const StyledTabelHeaderLabel = styled(TableSortLabel)`
  display: block;
  margin: auto;
`;
export default CourseLog;
