import React from 'react'
import Lottie from 'react-lottie'

const CustomLottie = ({animationData}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
  return (
    <div style={{aspectRatio:3/2}}>
      <Lottie 
          options={defaultOptions}
          height={'100%'}
          width={'100%'}
        />
    </div>
  )
}

export default CustomLottie