import React from 'react'

//components
import GameBasedAssessment from '../../../components/game-based-assessment';

const GbaPage = () => {
  return (
   <GameBasedAssessment/>
  )
}

export default GbaPage;
