import React from 'react'

//components
import Label from '../../../label';
import CustomInput from '../custom-input';

//styles
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    column1: {
        display: 'inline-block',
        width: '49%',
        marginRight: '2%'
    },
    column2: {
        display: 'inline-block',
        width: '49%',
    },
    row: {
        marginTop: '0.5rem'
    }
}));

const QuestionResponseMTRR = ({
    listitem,
    isDisableAll,
    mtrrQuestionResponse,
    questionValidations,
    handleQuestionchangeMTRR,
    responsesValidations,
    handleResponsechangeMTRR,
    maxQuestions }) => {
    const classes = useStyles();
    const data = Array(maxQuestions).fill(null).map((item, index) => (index));
   
    return (
        <>
            <div>
                <div className={classes.column1}>
                    <Label labelText={"Questions"} fs={'0.7rem'} />
                </div>
                <div className={classes.column2}>
                    <Label labelText={"Answers"} fs={'0.7rem'} />
                </div>
            </div>

            {data.map((ele, index) => (
                <div key={index} className={classes.row}>
                    <div className={classes.column1}>
                        <CustomInput
                            id={index.toString()}
                            isDisabled={isDisableAll}
                            defaultValue={mtrrQuestionResponse[index].question}
                            handleChange={handleQuestionchangeMTRR}
                            limitText={questionValidations[index].limitText}
                            isExceeded={questionValidations[index].exceeded}
                            isEmpty={listitem.questionArr[index].isQuestionEmpty}
                        />
                    </div>
                    <div className={classes.column2}>
                        <CustomInput
                            id={index.toString()}
                            isDisabled={isDisableAll}
                            defaultValue={mtrrQuestionResponse[index].answers[0].option}
                            handleChange={handleResponsechangeMTRR}

                            limitText={responsesValidations[index].limitText}
                            isExceeded={responsesValidations[index].exceeded}
                            isEmpty={listitem.questionArr[index].emptyAnswers[0].isEmpty}
                        />
                    </div>
                </div>
            ))}

        </>
    )
}

export default QuestionResponseMTRR;
