import React, { useState } from 'react';
import styled from 'styled-components';
//new
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

const StyledMetaTitle = styled.h4`
  color: #969696;
  font-size: 0.7rem;
  margin: 0;
  font-weight: 600;
  font-family: 'Montserrat';
`;

const custom_styles = makeStyles({
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    paddingLeft: 10,
    fontSize: '0.7rem',
    color: '#707070',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '200px',
    display: 'block',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  label: {
    color: '#707070',
    fontSize: '0.75rem',
    fontFamily: 'Montserrat',
  },
  selector_wrapper: {
    border: '0.5px solid #dde0e2',
    width: (props) => (props.width ? props.width : '320px'),
    maxWidth: '100%',
    height: '28px',
  },
  selector_button_base: {
    width: '100% !important',
    height: '100% !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
  },
  selector_first_flex: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  selector_second_flex: {
    display: 'flex',
    alignItems: 'center',
  },
  expand_more: {
    fontSize: '0.9rem !important',
    fill: '#f4511e !important',
    paddingRight: '8px !important',
  },
});

export default function SimpleFormSelect(props) {
  const styles = custom_styles(props);

  // console.log('SimpleFormSelect', props.options);

  let options = [{ display_text: 'No items', id: 0 }];

  let selected = [];

  if (props.hasOwnProperty('selected')) {
    selected = props.selected;
  }

  if (props.hasOwnProperty('options')) {
    options = props.options;
  }

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const [values, setValues] = React.useState({
    name: 'Cat in the Hat',
    age: '',
    multiline: 'Controlled',
    currency: 'EUR',
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleClose(id) {
    setOpen(false);
  }

  function handleSelect(id) {
    if (
      props.hasOwnProperty('onSelect') &&
      typeof id !== 'undefined' &&
      id != null
    ) {
      props.onSelect(id);
    }

    if (props.hasOwnProperty('autoClose')) {
      setOpen(false);
    }
  }

  return (
    <div className={styles.container}>
      <span className={styles.label}>{props.label}</span>
      <div className={styles.selector_wrapper}>
        <ButtonBase
          className={styles.selector_button_base}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpen(true);
          }}>
          <div className={styles.selector_first_flex}>
            <div>
              <span className={styles.text}>{props.text}</span>
            </div>
          </div>
          <div className={styles.selector_second_flex}>
            <ExpandMoreIcon className={styles.expand_more} />
          </div>
        </ButtonBase>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}>
        {options.map((option) => (
          <MenuItem key={option.id} onClick={(e) => handleSelect(option.id)}>
            <div>
              <div>
                {props.multiple === true ? (
                  <Checkbox checked={selected.indexOf(option.id) > -1} />
                ) : null}
                {option.display_text}
              </div>
              {option.hasOwnProperty('meta') ? (
                <div>
                  {option.meta.map((meta) => (
                    <StyledMetaTitle>{meta}</StyledMetaTitle>
                  ))}
                </div>
              ) : null}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
