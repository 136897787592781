import React, { useState, useEffect, useContext } from 'react'

//  components
import Content from './content';
import Learners from './Learners';
import SuccessDailog from "../success-dialog";
import FailureDialog from "../failure-dialog";

//  services
import usersServices from "../../services/user-launch";

//  styles
import useStyles from './index.styles'

//  mui
import LoadingButton from '@mui/lab/LoadingButton';
import { Button } from '@mui/material';

//  utils
import commonUtil from '../../utils/commonUtil';
import { keys, urls } from '../../configs';
import { EventSourcePolyfill } from 'event-source-polyfill';

//global-states
import { GlobalStates } from '../../App';

export const defaultValidationData = {
  "validUsersCount": 0,
  "validUsersUrl": "",
  "invalidShortUsersCount": 0,
  "invalidShortUsersUrl": "",
  "invalidLongUsersCount": 0,
  "invalidLongUsersUrl": "",
  "invalidSpecialCharUsersCount": 0,
  "invalidSpecialCharUsersUrl": "",
  "invalidSpaceUsersCount": 0,
  "invalidSpaceUsersUrl": ""
}

export const TABS = {
  reActivate: 'reActivate',
  deActivate: 'deActivate'
}

const ManageUser = () => {
  const styles = useStyles();
  const [globalStates] = useContext(GlobalStates);

  const [compainesFilter, setCompaniesFilter] = useState('');
  const [projectFilter, setProjectFilter] = useState('');
  const [file, setFile] = useState(undefined);
  const [managerFile, setManagerFile] = useState(undefined);
  const [isTestUser, setIsTestUser] = useState(false);
  const [isDemoUser, setIsDemoUser] = useState(false);

  const [listening, setListening] = useState(false);
  const [jobId, setJobId] = useState();

  const [failed, setFailed] = useState(false);
  const [failedText, setFailedText] = useState('');
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [validFile, setValidFile] = useState(false);
  const [validationData, setValidationData] = useState(defaultValidationData);

  const [activeTab, setActiveTab] = useState(TABS.reActivate);
  const [managerTabFormats, setManagerTabFormats] = useState({ user: null, manager: null });

  const userType = localStorage.getItem('user-type');


  useEffect(() => {
    if (listening) {
      console.log('EventSourcePolyfill calling!');
      const eventBaseURL = `${urls.baseUrl}${urls.apiV1}`;
      const session = JSON.parse(localStorage.getItem('session'));
      const events = new EventSourcePolyfill(`${eventBaseURL}/user/${activeTab === "reActivate" ? "reactivate" : "delete"}/${jobId}/status`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'x-api-key': keys.apiKey,
          'x-access-token': session.accessToken,
          'x-refresh-token': session.refreshToken
        }
      });

      events.onmessage = (res) => {
        const data = JSON.parse(res.data);
        if (data.status === "completed") {
          events.close()
          globalStates.handleProgressDialog(false);
          const displayText = activeTab === "reActivate" ? "Users Reactivated successfully!" : "Users Deleted successfully!";
          setSuccessText(displayText);
          setSuccess(true);
          setValidationData(defaultValidationData);
        }
      }
    }
  }, [listening]);


  useEffect(() => {
    if (file) validateUsers();
    else setValidationData(defaultValidationData);
  }, [file])


  const handleUser = async () => {
    if (file === null || file === undefined) {
      return;
    }
    let json = {
      excelFile: file,
      companyId: compainesFilter,
    }
    const payload = await commonUtil.jsonToFormData(json)
    globalStates.handleProgressDialog(true, 'Please wait...');
    const response = activeTab === 'reActivate' ? await usersServices.reActivateUser(payload) : await usersServices.deleteUser(payload);
    console.log(response);
    if (response.status === 200 || response.status === 201) {
      setJobId(response.data.data.jobId);
      setListening(true);
    }
    else {
      globalStates.handleProgressDialog(false);
      setFailedText("Something went wrong!");
      setFailed(true);
      return;
    }
  }

  const handleLaunch = async () => {
    if (file === null || file === undefined) {
      setFailed(true);
      setFailedText("Please upload the file");
      return;
    }
    await handleUser();
  }


  const handleClear = () => {
    setFile(undefined);
    setValidFile(false);
    setCompaniesFilter('');
    setValidationData(defaultValidationData);
  }


  const validateUsers = async () => {

    const json = {
      excelFile: file,
      companyId: compainesFilter
    }
    const payload = await commonUtil.jsonToFormData(json);
    globalStates.handleProgressDialog(true, 'Validating users, please wait...');
    const response = await usersServices.validateUsers(payload);
    console.log(json, payload, response);
    if (response.status === 200) {
      globalStates.handleProgressDialog(false);
      setValidFile(true);
      setValidationData(response.data.data);
    } else {
      globalStates.handleProgressDialog(false);
      globalStates.handleToast(true, 'Failed to validate users', 'error')
    }
  }


  const isBtnDisabled = () => {
    if (!validFile) return true;
    if (!file || validationData.invalidLongUsersCount || validationData.invalidShortUsersCount || validationData.invalidSpaceUsersCount || validationData.invalidSpecialCharUsersCount) return true;
    return false;

  }

  const handleFailedCloseButton = () => {
    setFailed(false);
  }

  const handleSuccessCloseButton = () => {
    setSuccess(false);
    setListening(false);
    setFile(undefined);
    setManagerFile(undefined);
    setValidFile(false);
    setValidationData(defaultValidationData)
  }


  return (
    <div className={styles.mainContainer}>
      <div className={styles.userLaunchContainer}>
        <Content
          compainesFilter={compainesFilter}
          setCompaniesFilter={setCompaniesFilter}
          projectFilter={projectFilter}
          setProjectFilter={setProjectFilter}
          isTestUser={isTestUser}
          setIsTestUser={setIsTestUser}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          file={file}
          setFile={setFile}
          managerFile={managerFile}
          setManagerFile={setManagerFile}
          setValidationData={setValidationData}
          setValidFile={setValidFile}
          managerTabFormats={managerTabFormats}
          setManagerTabFormats={setManagerTabFormats}
          isDemoUser={isDemoUser}
          setIsDemoUser={setIsDemoUser}
        />
        <Learners setFile={setFile} file={file} company={compainesFilter} validationData={validationData} activeTab={activeTab} managerTabFormats={managerTabFormats} />

        <div className={styles.buttonContainer}>
          {
            isBtnDisabled() ?
              <Button variant="contained" disabled className={styles.btn}>{activeTab === TABS.reActivate ? 'Reactivate User' : 'Deactivate User'}</Button> :
              <Button className={`${styles.launchBtn} ${styles.btn}`} onClick={handleLaunch}>{activeTab === TABS.reActivate ? 'Reactivate User' : 'Deactivate User'}</Button>
          }
          <Button className={`${styles.ClearBtn} ${styles.btn}`} onClick={handleClear}>CLEAR & DELETE</Button>
        </div>

        {failed && (
          <FailureDialog
            setErrorDialogOpen={setFailed}
            errorDialogOpen={failed}
            dialogText={failedText}
            handleClick={handleFailedCloseButton}
          />
        )}

        {success && (
          <SuccessDailog
            successDialogOpen={success}
            setSuccessDialogOpen={setSuccess}
            dialogText={successText}
            handleClick={handleSuccessCloseButton}
          />
        )}

      </div>
    </div>
  )
}

export default ManageUser