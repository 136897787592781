import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    mainContainer: {
        // border: '1px solid',
        height: 'calc(100vh - 3.4rem)',
        position: 'relative',
        display: 'flex',
        backgroundColor: '#FFF'
    },
    header: {
        // border: '1px solid red',
        backgroundColor: '#F9F9F9',
        height: '3.4rem',
        display: 'flex',
        width: '70%',
        alignItems: 'center',
        paddingLeft: '1rem',
        gap: '1rem',
        position: 'absolute',
        top: '-3.4rem',
        left: 0,
        zIndex: 9999,
        '& h1': {
            color: "rgb(0,0,0,0.9)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "700",
        },
        '& > img': {
            cursor: 'pointer',
            transition: 'width 2s',
            '&:hover': {
                width: '1.2rem',
                marginRight: '-0.11rem'
            }
        },

    },
    searchBar: {
        width: '70%',
        height: '62%',
        '& .MuiPaper-root': {
            width: '100%',
            height: '100%'
        }
    },
    column1: {
        // border: '1px solid red',
        // width: '85%', CHANGING WIDTH TO 100 WHILE HIDING COLUMN 2 IN PHASE 1 LAUNCH
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    breadcrumb: {
        borderBottom: '1px solid rgb(112, 112, 112,0.5)',
        height: '8%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        gap: '0.5rem',
        '& h6': {
            color: "rgb(0, 0, 0, 0.8)",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: "700",
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline'
            },
            '& i': {
                fontSize: '0.6rem',
                color: '#f4511e'
            }
        }
    },
    row2: {
        // border: '1px solid red',
        height: '92%'
    },
    column2: {
        borderLeft: '1px solid rgb(112, 112, 112,0.5)',
        width: '15%',
    },
    defaultScreen: {
        // border: '1px solid blue',
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1.5rem'
    },
    asideRow1: {
        // border: '1px solid blue',
        height: '8%',
        display: 'flex',
        padding: '0 1.5rem',
        alignItems: 'center',
        '& .MuiButtonBase-root': {
            borderRadius: '5px',
            "&:hover": {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e'
            }
        }
    },
    asideRow2: {
        // border: '1px solid red',
        height: '79%'
    },
    asideRow3: {
        borderTop: '1px solid rgb(112, 112, 112,0.5)',
        display: 'flex',
        gap: '0.3rem',
        flexDirection: 'column',
        padding: '0.5rem 0.8rem',
        height: '13%',
        '& .MuiButtonBase-root': {
            '-webkit-justify-content': 'start',
            justifyContent: 'start',
            border: 'none',
            textTransform: 'capitalize',
            padding: 0,
            height: '1.2rem',
            '&:hover': {
                border: 'none',
                textDecoration: 'underline'
            }
        },
        '& h6': {
            color: "rgb(112, 112, 112)",
            fontSize: "0.6rem",
            fontWeight: 600,
            fontFamily: "Montserrat",
        }
    },
    labelBox: {
        display: 'flex',
        gap: '0.5rem',
        // padding : '0rem 1rem',
    },
    mainView: {
        // border : '1px solid',
        // height: '85.9%',
        padding: '1rem',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
    },
    viewIconsBox: {
        // border : '1px solid',
        display: 'flex',
        gap: '1rem',
        position: 'absolute',
        top: '1rem',
        right: '2rem',
        '& img': {
            height: '1rem',
            width: '1rem',
            cursor: 'pointer'
        }
    },
    sortBox: {
        // border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        gap: '0.7rem',
        '& h6': {
            color: "rgb(43, 43, 43, 0.6)",
            fontFamily: "Montserrat",
            fontSize: "0.73rem",
            fontWeight: 700,
        },
        // '& select': {
        //     // border: '1px solid rgb(112,112,112,0.5)',
        //     border: '1px solid rgb(112,112,112,0.5)',
        //     color: 'rgb(0,0,0, 0.83)',
        //     fontFamily: "Montserrat",
        //     fontSize: '0.7rem',
        //     fontWeight: 700
        // }
    },
    documentsContainer: {
        // border: '1px solid',
        height: '90%',
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: '6px'
            // display: 'none'
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                background: '#f5411e',
                borderRadius: '6px'
            }
        }

    },
    detailBox: {
        borderTop: '1px solid rgb(112, 112, 112,0.5)',
        height: '14.1%',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.8rem'
    },
    detail: {
        // border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        gap: '0.7rem',
        '& h5': {
            color: "rgb(0,0,0)",
            fontFamily: "Montserrat",
            fontSize: "0.77rem",
            fontWeight: 700,
        },
        '& h6': {
            color: "rgb(112, 112, 112, 0.8)",
            fontFamily: "Montserrat",
            fontSize: "0.65rem",
            fontWeight: 500
        },
    },
    sortSelect: {
        border: '1px solid rgb(112,112,112,0.5)',
        color: 'rgb(0,0,0, 0.83)',
        fontFamily: "Montserrat",
        fontSize: '0.7rem',
        fontWeight: 700,
        padding: '0.3rem',
        borderRadius: '3px'
    }
}));


export default useStyles;