import React from 'react'

//mui
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';


//styles
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({

    radioGroup: {
        '& .MuiFormControlLabel-root': {
            margin: '0',
            marginRight: '0.5rem',
            '& .MuiTypography-root': {
                color: 'rgb(112, 112, 112, 0.8)',
                // fontFamily: "Montserrat",
                fontSize: "0.8rem",
                fontWeight: "500",
            },
            '& .MuiButtonBase-root': {
                padding: '0.4rem',
                margin: '0 0.3rem'
            }
        }
    }


}));

const RadioGroupCustom = ({
    // defaultChecked,
    options,
    handleChange,
    disabled = false,
    selected = []
}) => {
    const classes = useStyles();
    return (
        <FormControl>
            <RadioGroup
                className={classes.radioGroup}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                // defaultValue={defaultChecked}
                row
            >
                {
                    options.map((ele, i) => (
                        <FormControlLabel
                            key={i}
                            sx={{
                                '& .MuiSvgIcon-root': {
                                    fontSize: 18,
                                    color: '#f4511e'
                                },
                            }}
                            control={
                                <Radio
                                    disabled={disabled}
                                    checked={selected === ele.toUpperCase()}
                                    onChange={handleChange}
                                    value={ele.toUpperCase()}
                                    name="radio-buttons"
                                />
                            }
                            label={ele}
                        />
                    ))
                }

            </RadioGroup>
        </FormControl>
    )
}

export default RadioGroupCustom;
