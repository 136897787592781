import { authFetchFormData, authFetch } from "../utils/fetch";

const launchUsers = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/microskill/launch`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const disableUsers = async () => {

    try {
        const resData = await authFetch.post(`/microskill/launch/disable`)
        return resData
    } catch (err) {
        return err.response
    }
}

const addUsersProjects = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/user/uploads`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const validateUsers = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/user/validateUsers`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const reActivateUser = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/user/reactivate`, data)
        return resData
    } catch (error) {
        return error.response
    }
}

const deleteUser=async(data)=>{
    try {
        const resData=await authFetchFormData.post('/user/delete',data)
        return resData
    } catch (error) {
        return error.response
    }
}



export default { launchUsers, disableUsers, addUsersProjects, validateUsers, reActivateUser ,deleteUser }