import React, { useState } from "react";

// mui
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

//components
import Table from "../table";

const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '98%',
    height: '85%',
    bgcolor: 'background.paper',
    // border: '2px solid red',
    boxShadow: 24,
    // border: 'none',
    borderRadius: '5px',
    outline: 'none',

}

const modalStyle = {
    zIndex: 9999,
}

const HEADINGS = [
    { title: "Microskill Name", key: "microskillName" },
    { title: "Completed Challenges", key: "completedChallenges" },
    { title: "Completed On", key: "completedOn" },
    { title: "Completion", key: "completion" },
    // { title: "Type", key: "type" },
    { title: "Score", key: "scored" },
    { title: "Total Challenges", key: "totalChallenges" },
    { title: "Status", key: "status" },
    { title: "Created On", key: "createdOn" },
    { title: "Launched On", key: "launchedOn" },
];


const MicroskillTableModal = ({
    isOpen = false,
    setIsOpen,
    data
}) => {
    if(data!==null && data.Microskills.length){
        data.Microskills.forEach(element => {
            element.microskillName = element.Microskill.name || null;
            element.type = element.Microskill.type || null;
        });
    }
    const classes = useStyles();
    console.log('MicroskillTableModal data = ', data);
    const handleClose = () => {
        setIsOpen(false);
    }



    return (
        <Modal
            open={isOpen}
            onClose={() => {
                console.log('backdrop clicked');
                return
            }}
        // sx={modalStyle}
        >
            <Box sx={boxStyle}>
                <div className={classes.header}>
                    <Typography variant="body1" component="div"> Microskill Details of {data.username} </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#f4511e',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.body}>
                    <Table
                        // handleRowClick={handleRowClick}
                        tableHeadings={HEADINGS}
                        tableData={data.Microskills}
                        quickActions={false}
                        withPagination={false}
                    />

                </div>

            </Box>
        </Modal>
    )
}

export default MicroskillTableModal;

const useStyles = makeStyles(() => ({
    header: {
        height: '10%',
        borderBottom: '1px solid rgb(112, 112, 112, 0.5)',
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-root': {
            marginLeft: '2rem',
            color: "rgb(0,0,0)",
            fontFamily: "Montserrat",
            fontSize: "1.3rem",
            fontWeight: 700,
        },
    },
    body: {
        padding: '1rem',
        overflowY: 'auto',
        maxHeight: '85%',
         '&::-webkit-scrollbar': {
            width: '8px'
            // display: 'none'
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                background: '#f5411e',
                borderRadius: '6px'
            }
        }
    },

}));