import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 15px 50px;
  border-radius: 0px;
  background-color: transparent;
  color: #f4511e;
  border: solid 1px #f4511e;
  font-size: 13px;
  font-weight: bold;
  font-family: 'Montserrat';
  cursor: pointer;
  transition: ease box-shadow 0.4s;
  outline: 0;
  user-select: none;
  display: inline-block;
  text-transform: uppercase;

  :hover {
    box-shadow: 0 10px 17px 0 rgba(0, 0, 0, 0.13);
  }

  :hover:active {
    transform: scale(0.99);
  }
`;

var FlatClearButton = function(props) {
  let onClick = (e) => {
    if (props.hasOwnProperty('onClick')) {
      props.onClick(e);
    }
  };

  let className = '';

  if (props.hasOwnProperty('className')) {
    className = props.className;
  }

  let style = {};

  if (props.hasOwnProperty('style')) {
    style = props.style;
  }

  return (
    <StyledButton onClick={onClick} className={className} style={style}>
      {props.children}
    </StyledButton>
  );
};

export default FlatClearButton;
