import React, { useEffect, useState, useContext } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import CustomButton from '../../../buttons';
import LaListItem from '../la-list-item';
import CustomLottie from '../custom-lottie';

//constants
import constants from '../../../../constants';

//global-states
import { GlobalStates } from '../../../../App';

//services
import challengesServices from '../../../../services/challenges';

//utils
import useQuery from '../../../../utils/useQuery';

//validations
import ilaValidations from '../../../../validations/ila-validations';
import commonUtil from '../../../../utils/commonUtil';


const ManageInteractions = ({
    list,
    setList,
    ilaCardId,
    type,
    selectedQuestion,
    setSelectedQuestion,
    handleSelectQuestion,
    selectedColumns,
    setSelectedColumns,
    selectedInteraction,
    setSelectedInteraction,
    isEditInteraction,
    setIsEditInteraction,
    fetchChallengesData,
    coverAndObjective,
    selectedCard,
    setSelectedCard,
    setPreview,
    maxScore,
    setMaxScore,
    setLoadingDialog }) => {

    // console.log('ManageInteractions ilaCardId = ', ilaCardId);
    const classes = useStyles();
    const query = useQuery();
    const [globalStates] = useContext(GlobalStates);
    const [questionList, setQuestionList] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);
    const [interactions, setInteractions] = useState({ gameType: null, questions: [] });

    useEffect(() => {
        // console.log('ManageInteractions list= ', list);
        if (isDeleted) {
            // setSelectedQuestion(null);
            // console.log('ManageInteractions questionList after deleting = ', questionList);

            for (let i = 0; i < list.length; i++) {
                if (list[i].id === ilaCardId) {
                    // console.log('ManageInteractions list[i] after deleting = ', list[i]);
                    if (list[i].games.questions.length > 0) {
                        setSelectedQuestion(0)
                    } else {
                        setSelectedCard({ ...selectedCard, cardType: 'default' });
                        setPreview(list[i].thumbImage);
                        setSelectedColumns({ ...selectedColumns, secondColumn: 'editContentSection' });

                    }

                    break;
                }
            }
            setIsDeleted(false);
        }

    }, [isDeleted])


    let index = null;
    for (let i = 0; i < list.length; i++) {
        if (list[i].id === ilaCardId) {
            index = i;
            break;
        }
    }
    // console.log('ManageInteractions selected listitem = ', list[index]);

    //WILL BE CHANGED WHEN INTERACTION API WILL BE INTEGRATED
    useEffect(() => {
        // setSelectedQuestion(0)
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === ilaCardId) {
                let questions = list[i].games.questions;
                // console.log('selected card questions = ', list[i].games.questions);
                setQuestionList(list[i].games.questions);
                setInteractions(list[i].games);
                if (questions.length) {
                    let preview = constants.ILA_PREVIEWS[questions[0].designType];
                    setSelectedInteraction({ gameType: list[i].games.gameType, interactionType: questions[0].designType, previewUrl: preview });
                    setSelectedColumns({ ...selectedColumns, secondColumn: 'editInteraction' })
                }
                break;
            }
        }
    }, [ilaCardId])




    const handleAddContext = () => {
        // setSelectedColumns({ firstColumn: 'selectInteraction', secondColumn: 'editContentSection' })
    }

    const handleAddQuestion = () => {
        // console.log('handleAddQuestion list = ', list);
        //JSON FOR DEFAULT SELECTED INTERACTION
        let defaultMcqJson = JSON.stringify(constants.ILA_QUESTION_JSON['ILA_MCQ_1']);
        let newQuestion = JSON.parse(defaultMcqJson);
        // console.log("handleAddQuestion json = ", defaultMcqJson);
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === ilaCardId) {
                let temp = [...list];
                let games = temp[i].games;
                // console.log('question array  = ', games);
                if (!temp[i].games.questions.length) {
                    temp[i].games.gameType = 'MCQ';
                    temp[i].games.designType = 'ILA_MCQ_1';
                    // games.questions = [{...selectedDesignJson, designType: selectedInteraction.interactionType}]
                    temp[i].games.questions = [newQuestion]
                    // console.log('ILA-temp = ', temp);
                    setList(temp);
                    setSelectedQuestion(0);
                    // setSelectedInteraction({ gameType: '', interactionType: '', previewUrl: ''})
                    setSelectedColumns({ firstColumn: 'selectInteraction', secondColumn: 'editInteraction' })
                    return;
                } else {
                    let designType = games.questions[0].designType;
                    let selectedDesignJson = JSON.stringify(constants.ILA_QUESTION_JSON[designType]);
                    let newQuestion = JSON.parse(selectedDesignJson);
                    // console.log('sequence: temp[i].games.questions.at(-1)  = ', temp[i].games.questions.at(-1));
                    temp[i].games.questions.push({ ...newQuestion, id: temp[i].games.questions.length, sequence: temp[i].games.questions.at(-1).sequence + 1 });
                    // console.log('after game design already selected temp[i] = ', temp[i]);
                    setSelectedQuestion(temp[i].games.questions.length - 1);
                    setList(temp);
                    break;
                }

            }
        }
    }


    const handleSaveInteraction = async () => {

        if (!interactions.questions.length) {
            globalStates.handleToast(true, 'No Interaction Added', 'error');
            return
        }
        let validatedQuestions = []
        interactions.questions.forEach(element => {
            let validatedListItem = ilaValidations.validateListitem(element.designType, element, interactions.questions);
            validatedQuestions.push(validatedListItem);
        })
        let isEachListitemValid = ilaValidations.isEachListitemValid(validatedQuestions);
        
        if (!isEachListitemValid.isValid) {
            let tempList = [...list];
            tempList[index].games.questions = validatedQuestions;
            let errorIndex = isEachListitemValid.index;
            if (validatedQuestions[0].designType === 'ILA_FITB_1' && validatedQuestions[errorIndex].validations.includes('MBLANK missing')) {
                globalStates.handleToast(true, `[MBLANK] missing in Question ${errorIndex + 1}`, 'error');
            } else if (validatedQuestions[errorIndex].validations.includes('Responses missing!') || validatedQuestions[errorIndex].validations.includes('Question required!') ||  validatedQuestions[errorIndex].validations.includes('Context required!')) {
                globalStates.handleToast(true, `Please fill all the required fields`, 'error');
            } else if (validatedQuestions[errorIndex].validations.includes('Repeated Question')) {
                globalStates.handleToast(true, `Repeated Question not allowed`, 'error');
            }
            // else if (validatedQuestions[errorIndex].validations.includes('Duplicate responses')) {
            //     globalStates.handleToast(true, `Duplicate responses not allowed.`, 'error');
            // }
            else {
                globalStates.handleToast(true, `Characters exceeded!`, 'error');
            }
            setList(tempList);
            setInteractions({ ...interactions, questions: validatedQuestions })
            setQuestionList(validatedQuestions)
            setSelectedQuestion(isEachListitemValid.index)
            return
        }

        setLoadingDialog(true);
        const companyId = query.get("c_id");
        const microskillId = query.get("ms_id");
        const challengeId = query.get("challenge_id");
        const cardId = ilaCardId;
        let allQuestions = interactions.questions;
        let contexts = [];
        if (interactions.gameType === "MTRR") {
            let mtrrQuestions = [];
            interactions.questions.forEach((ele, i) => {
                let contextObjTemp = { ...ele.contextObj };
                if (typeof (contextObjTemp.id) !== 'string') contextObjTemp.id = i
                contexts.push(contextObjTemp);
                ele.questionArr.forEach(item => {
                    let questionsLength = ele.questionArr.length;
                    let tempQuestion = { ...item, mandatory: ele.mandatory, score: ele.score / questionsLength };
                    if (typeof (tempQuestion.contextId) !== 'string') tempQuestion.contextId = i
                    mtrrQuestions.push(tempQuestion);
                })
            })
            allQuestions = mtrrQuestions;

        }
        let payload = {
            companyId: companyId,
            microskillId: microskillId,
            challengeId: challengeId,
            cardId: cardId,
            interactions: {
                gameType: interactions.gameType,
                contexts: contexts,
                questions: allQuestions
            }
        }

        let res;
        // return
        if (isEditInteraction) {
            res = await challengesServices.updateInteraction(payload);
        } else {
            res = await challengesServices.addInteractions(payload);
        }
        if(res === undefined) {
            setLoadingDialog(false);
            globalStates.handleToast(true, 'Something went wrong, please try again!', 'error');
            return
        }

        if (res.status === 200 || res.status === 201) {
            let newMaxScore = 0;
            list.forEach(element => {
                console.log('element.games.questions = ', element.games.questions);
                element.games.questions.forEach(ele => {
                    if (ele.score) newMaxScore += ele.score;
                })
            })


            let updateMaxScorePayload = {
                companyId: companyId,
                microskillId: microskillId,
                challengeId: challengeId,
                type: "LA",
                gameType: "ILA",
                designType: "LA_ILA",
                maxScore: newMaxScore
            }

            let updateScoreResponse = await challengesServices.updateChallenge(updateMaxScorePayload);
            if (updateScoreResponse.status === 200 || updateScoreResponse.status === 201) {
                setLoadingDialog(false);
                globalStates.handleToast(true, 'Interaction Saved Sucessfully!', 'success');
                setIsEditInteraction(false)
                localStorage.setItem('preview', list[index].thumbImage);
                localStorage.setItem('scrollToItem', ilaCardId);
                fetchChallengesData();
            } else {
                setLoadingDialog(false);
                globalStates.handleToast(true, 'Internal Server Error', 'error');
                return
            }

        } else {
            setLoadingDialog(false);
            globalStates.handleToast(true, 'Internal Server Error', 'error');
        }


    }

    const handleDeleteMtrrQuestions = async (item, index) => {
        if (typeof (item.contextId) === 'string') {
            setLoadingDialog(true);
            for (let i = 0; i < item.questionArr.length; i++) {
                let id = item.questionArr[i].id;
                let cardId = item.questionArr[i].cardId;
                const companyId = query.get("c_id");
                const microskillId = query.get("ms_id");
                const challengeId = query.get("challenge_id");
                let payload = {
                    companyId: companyId,
                    microskillId: microskillId,
                    challengeId: challengeId,
                    cardId: cardId,
                    questionId: id
                }
                const res = await challengesServices.deleteInteraction(payload);
                if (res.status === 200 || res.status === 201) {
                    if (i === item.questionArr.length - 1) {
                        let newMaxScore = maxScore - item.score;
                        let updateMaxScorePayload = {
                            companyId: companyId,
                            microskillId: microskillId,
                            challengeId: challengeId,
                            type: "LA",
                            gameType: "ILA",
                            designType: "LA_ILA",
                            maxScore: newMaxScore
                        }

                        let updateScoreResponse = await challengesServices.updateChallenge(updateMaxScorePayload);
                        // console.log('updateScoreResponse = ', updateScoreResponse);
                        if (updateScoreResponse.status === 200 || updateScoreResponse.status === 201) {
                            setMaxScore(newMaxScore);
                            let temp = [...list];
                            for (let i = 0; i < temp.length; i++) {
                                if (temp[i].id === ilaCardId) {
                                    let result = temp[i].games.questions.filter(ele => ele.id !== item.id);
                                    temp[i].games.questions = result;
                                    setInteractions({ ...interactions, questions: result })
                                    setQuestionList(result);
                                    setList(temp);
                                    if (selectedQuestion === index && result.length > 0) {
                                        setSelectedQuestion(0)
                                    } else {
                                        setIsDeleted(true);
                                    }
                                    break
                                }
                            }
                            setLoadingDialog(false);
                            globalStates.handleToast(true, 'Successfully Deleted!', 'success');
                            return

                        } else {
                            // console.log('failed to update score');
                            setLoadingDialog(false);
                            globalStates.handleToast(true, 'Internal server error', 'error');
                            return
                        }
                    }


                } else {
                    return
                }
            }

        } else {
            let temp = [...list];
            for (let i = 0; i < temp.length; i++) {
                if (temp[i].id === ilaCardId) {
                    let result = temp[i].games.questions.filter(ele => ele.id !== item.id)
                    temp[i].games.questions = result;
                    setInteractions({ ...interactions, questions: result })
                    setQuestionList(result);
                    setList(temp);
                    if (selectedQuestion === index && result.length > 0) {
                        setSelectedQuestion(0)
                    } else {
                        setIsDeleted(true);
                    }
                    break
                }
            }

        }
    }

    const handleDeleteQuestion = async (ele, index) => {
        if (selectedQuestion !== index) {
            setSelectedQuestion(index)
            return
        }
        let confirm = window.confirm("Do you really want to delete this question?")
        if (confirm) {
            let mtrrDesigns = ["ILA_MTRR_1", "ILA_MTRR_2"]
            if (mtrrDesigns.includes(ele.designType)) {
                handleDeleteMtrrQuestions(ele, index)
                return
            }
            if (typeof (ele.id) === 'string') {
                //HERE QUESTION IS FETCHED FROM DB
                const companyId = query.get("c_id");
                const microskillId = query.get("ms_id");
                const challengeId = query.get("challenge_id");
                let payload = {
                    companyId: companyId,
                    microskillId: microskillId,
                    challengeId: challengeId,
                    cardId: ele.cardId,
                    questionId: ele.id
                }
                // console.log('payload = ', payload);
                globalStates.handleProgressDialog(true,'Please wait...');
                const res = await challengesServices.deleteInteraction(payload);
                
                if (res.status === 200 || res.status === 201) {
                    let newMaxScore = maxScore - ele.score;
                    let updateMaxScorePayload = {
                        companyId: companyId,
                        microskillId: microskillId,
                        challengeId: challengeId,
                        type: "LA",
                        gameType: "ILA",
                        designType: "LA_ILA",
                        maxScore: newMaxScore
                    }

                    let updateScoreResponse = await challengesServices.updateChallenge(updateMaxScorePayload);
                    if (updateScoreResponse.status === 200 || updateScoreResponse.status === 201) {
                        setMaxScore(newMaxScore);
                        let temp = [...list];
                        for (let i = 0; i < temp.length; i++) {
                            if (temp[i].id === ilaCardId) {
                                let result = temp[i].games.questions.filter(item => item.id !== ele.id);
                                temp[i].games.questions = result;
                                setInteractions({ ...interactions, questions: result })
                                setQuestionList(result);
                                setList(temp);
                                if (selectedQuestion === index && result.length > 0) {
                                    setSelectedQuestion(0)
                                } else {
                                    setIsDeleted(true);
                                }
                                break
                            }
                        }
                        globalStates.handleProgressDialog(false);
                        globalStates.handleToast(true, 'Successfully Deleted!', 'success');
                    } else {
                        // console.log('failed to update score');
                        globalStates.handleProgressDialog(false);
                        globalStates.handleToast(true, 'Internal server error', 'error');
                        return
                    }
                }else{
                    globalStates.handleProgressDialog(false);
                }
            } else {
                //HERE WE HAVE TO REMOVE QUESTION FROM ARRAY WHICH IS LOCALLY STORED
                // console.log('removing locally stored question');
                let temp = [...list];
                for (let i = 0; i < temp.length; i++) {
                    if (temp[i].id === ilaCardId) {
                        let result = temp[i].games.questions.filter(item => item.id !== ele.id);
                        temp[i].games.questions = result;
                        setInteractions({ ...interactions, questions: result })
                        setQuestionList(result);
                        setList(temp);
                        if (selectedQuestion === index && result.length > 0) {
                            setSelectedQuestion(0)
                        } else {
                            setIsDeleted(true);
                        }
                        break
                    }
                }
            }



        }
        return
    }

    const handleBack = () => {
        setIsEditInteraction(false);
        localStorage.setItem('scrollToItem', ilaCardId);
        setSelectedColumns({ firstColumn: 'content', secondColumn: 'editContentSection' })
    }

    const interactionStatus = (ele) => {
        const status = {
            error: "fa-solid fa-circle-exclamation",
            inProgress: "fa-regular fa-circle-dot",
            done: "fa-solid fa-circle-check"
        }

        return status[ele.status];
    }

    const isAddQuestionVisible = () => {
        // if (interactions.gameType === "MTRR" && interactions.questions.length > 0) return false;
        return true;
    }

    return (
        <div className={classes.main}>
            {/* manageInteraction */}
            <div className={classes.row1}>
                <LaListItem
                    list={list}
                    item={list[index]}
                    type={selectedInteraction.interactionType}
                    deleteCopy={false}
                    coverAndObjective={coverAndObjective}
                />
                <div className={classes.listContainer}>
                    {
                        questionList.length ?
                            questionList.map((ele, i) => (
                                <div key={i}
                                    // style={{ backgroundColor: selectedQuestion === i ? '#FCFCFC' : 'rgb(149, 152, 154, 0)' }} 
                                    className={`${classes.listItem}  ${selectedQuestion === i ? classes.active : ''}`}
                                >
                                    <i className={interactionStatus(ele)}></i>
                                    <div className={classes.column} onClick={() => handleSelectQuestion(ele, i)}>
                                        <CustomLottie animationData={constants.LA_QUESTION_ANIMATION[selectedInteraction.interactionType]} />
                                        {/* <img src={'/images/learning-aid-assets/image1.jpg'} alt='' /> */}
                                        <div className={classes.details} >
                                            <h4>Question {i + 1}</h4>
                                            <h5>{ele.mandatory ? 'Mandatory' : 'Not Mandatory'}</h5>
                                            <h6>{interactions.gameType === "MTRR" ? "Total Score" : "Score"} : {ele.score}</h6>
                                        </div>

                                    </div>
                                    <div className={classes.buttonsBox}>
                                        <div className={classes.duplicateDelete}>
                                            {/* <img src='/images/icons/duplicate.svg' alt='' /> */}
                                            <img src='/images/icons/delete-photo.svg' alt='' onClick={() => handleDeleteQuestion(ele, i)} />
                                        </div>
                                    </div>
                                </div>
                            ))
                            : null
                    }
                    <div className={classes.addQuestion}>
                        {/* <CustomButton handleClick={handleAddContext} fw={700} fs={'0.59rem'} ls={1} btnText={'Add Context +'} /> */}
                        {isAddQuestionVisible() ? <CustomButton handleClick={handleAddQuestion} fw={700} fs={'0.59rem'} ls={1} btnText={'Add Question +'} /> : null}
                    </div>

                </div>
            </div>
            <div className={classes.btnBox}>
                <CustomButton handleClick={handleSaveInteraction} fw={800} fs={'0.59rem'} ls={1} btnText={'SAVE INTERACTION'} variant={'contained'} textColor={'#fff'} bgcolor={'#f4511e'} />
                <CustomButton handleClick={handleBack} fw={800} fs={'0.59rem'} ls={1} btnText={'BACK'} />
            </div>
        </div>
    )
}

export default ManageInteractions


const useStyles = makeStyles(() => ({
    main: {
        height: 'calc(100% - 3.8rem)',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'rgb(149, 152, 154, 0)',
    },
    row1: {
        // border : '1px solid',
        height: 'calc(100% - 4.5rem)',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.6rem',
        '& .listItemLa': {
            height: '20%'
        }
    },
    btnBox: {
        // border : '1px solid',
        padding: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        // padding: '1rem 1.5rem',
        height: '4.5rem',
        // backgroundColor: 'rgb(149, 152, 154, 0)',
        '& .MuiButton-contained:hover': {
            backgroundColor: 'rgb(244, 81, 30, 0.9)',
            borderColor: 'rgb(244, 81, 30, 0.9)',
            color: '#fff',
        }
    },
    listContainer: {
        // border : '1px solid',
        height: '82%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '2px',

        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px'
        },
    },
    listItem: {
        // borderLeft : '1px solid rgb(112, 112, 112, 0.2)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        padding: '0.7rem 0.6rem',
        borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
        height: '20%',
        minHeight: '20%',
        width: '85%',
        '&:hover': {
            backgroundColor: '#FCFCFC',
            '& > div:nth-child(2)': {
                // border : '1px solid red'
                '& > div:first-child': {
                    border: '1px solid #ccc'
                }
            }
        },
        '& > .fa-circle-exclamation': {
            color: '#EB3917'
        },
        '& > .fa-circle-check': {
            color: 'green'
        },
        '& > .fa-circle-dot': {
            color: '#f3b401'
        },
    },
    active: {
        backgroundColor: '#FCFCFC',
        '& > div:nth-child(2)': {
            // border : '1px solid red'
            '& > div:first-child': {
                border: '1px solid #ccc'
            }
        }

    },
    column: {
        // border: '1px solid',
        width: '84%',
        display: 'flex',
        gap: '0.7rem',
        cursor: 'pointer',
        // marginLeft: '7%',
        '& > div:first-child': {
            height: '3.8rem !important',
            width: '14rem !important'
        }
        // '& > img': {
        //     border: '1px solid rgb(112, 112, 112, 0.1) ',/* Gray border */
        //     borderRadius: '4px ', /* Rounded border */
        //     // padding: '5px', /* Some padding */
        //     height: '4.5rem',
        //     width: '4.5rem', /* Set a small width */
        // }
    },
    // lottie: {
    //     '& > div':{
    //         height: '3.8rem !important',
    //         width: '14rem !important'
    //     }
    // },
    details: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.5rem',
        width: '100%',
        '& h4': {
            fontSize: '0.7rem',
            fontFamily: 'Montserrat',
            color: '#484848'
        },
        '& h5': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.64rem',
            fontStyle: 'italic',
            color: 'rgb(72, 72, 72, 0.5)',
            fontWeight: '600',
            '& i': {
                fontSize: '0.7rem',
                marginLeft: '0.5rem',
                // paddingTop : '0.5rem',
                color: '#f4511e'
            },
        },
        '& h6': {
            fontSize: '0.6rem',
            color: 'rgb(72, 72, 72, 0.7)'
        },
    },
    buttonsBox: {
        // border: '1px solid red',
        width: '9%',
        cursor: 'pointer'


    },
    duplicateDelete: {
        height: '63%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1rem',
        '& img': {
            height: '0.8rem',
            width: '0.8rem',
            '&:hover': {
                filter: 'invert(44%) sepia(28%) saturate(6975%) hue-rotate(349deg) brightness(97%) contrast(97%)'
            }
        },

    },
    addQuestion: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
        gap: '3rem',
        '& .MuiButtonBase-root': {
            width: '28%',
            height: '2rem',
            padding: 0,
            backgroundColor: 'rgb(149, 152, 154, 0)',
            borderRadius: '2px',
            fontSize: '0.5rem',
            fontWeight: 800,
            letterSpacing: 0
        }
    }
}));
