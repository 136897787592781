import React, { useContext } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, selectClasses } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

//constants
import constants from '../../../../constants';

//utils
import useQuery from '../../../../utils/useQuery';
import useRouter from '../../../../utils/useRouter';

//services
import challengesServices from '../../../../services/challenges';

//global-states
import { GlobalStates } from '../../../../App';
import isEmpty from '../../../../utils/isEmpty';


const About = ({
    aboutTitle,
    aboutDescription,
    setSelectedMenuItem,
    menuItemStatus,
    setMenuItemStatus,
    selectedGBATypeData,
    selectedGame,
    challengeName,
    createChallengeGBA }) => {
    const classes = useStyles();
    const router = useRouter();




    return (
        <div className={classes.mainContainer}>
            <h1 className={classes.title}>
                <ErrorOutlineIcon className={classes.errorIcon} />{aboutTitle}
            </h1>
            <p className={classes.description}>
                {aboutDescription}
            </p>
            <div>
                <Button onClick={() => createChallengeGBA(selectedGBATypeData, selectedGame)} className={classes.btn} variant="contained" color='warning' endIcon={<AddIcon />}>Add Your First Question </Button>
            </div>
        </div>
    )
}

export default About;



const useStyles = makeStyles(() => ({
    mainContainer: {
        // margin: '10rem 2rem',
        padding: "0 4rem",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: "center"

    },
    title: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Montserrat",
        color: "#4F0060",
        fontSize: "0.8rem",
        fontWeight: "Bold",
        characterSpacing: "0",
        // lineSpacing : "155"
        // padding : '0.5rem 0'
    },
    errorIcon: {
        color: "#F4511E",
        fontSize: "1rem",
        marginRight: "0.2rem"
    },
    description: {
        fontFamily: "Open Sans",
        color: "#707070",
        fontSize: "0.8rem",
        fontWeight: "400",
        characterSpacing: "0",
        lineHeight: "2.6",
        marginTop: '0.3rem',
        marginBottom: '3rem'
    },
    btn: {
        borderRadius: '25px',
        backgroundColor: '#f4511e',
        padding: '0.6rem 1.5rem',
        color: "rgb(255, 255, 255)",
        fontFamily: "Montserrat",
        fontSize: "0.5rem",
        fontWeight: "700"
    }
}));