import React from 'react';

function ClockIcon(props) {
  return (
    <svg width={12} height={12} viewBox="0 0 12 12" {...props}>
      <g
        id="class-clock-icon__mastero_icon_alarm"
        transform="translate(-261 -27)">
        <path
          id="class-clock-icon__Rectangle_1694"
          d="M0 0h12v12H0z"
          data-name="Rectangle 1694"
          transform="translate(261 27)"
          fill="none"
          opacity={0.3}
        />
        <g
          id="class-clock-icon__alarm-clock"
          transform="translate(262.201 27.601)">
          <g id="class-clock-icon__Group_2452" data-name="Group 2452">
            <path
              id="class-clock-icon__Path_5325"
              d="M2.185 3.064A4.2 4.2 0 014.71 1.282a.259.259 0 00.158-.389 1.9 1.9 0 10-3.1 2.189.259.259 0 00.42-.019z"
              className="class-clock-icon__cls-2"
              data-name="Path 5325"
              transform="translate(-1.354)"
            />
            <path
              id="class-clock-icon__Path_5326"
              d="M30.149 0a1.9 1.9 0 00-1.607.886.259.259 0 00.159.39 4.2 4.2 0 012.536 1.768.259.259 0 00.421.015A1.9 1.9 0 0030.149 0z"
              className="class-clock-icon__cls-2"
              data-name="Path 5326"
              transform="translate(-22.448)"
            />
            <path
              id="class-clock-icon__Path_5327"
              d="M12.539 11.526a4.193 4.193 0 10-6.971 3.132l-.353.5a.476.476 0 10.776.551l.353-.5a4.185 4.185 0 004 0l.353.5a.476.476 0 00.776-.551l-.353-.5a4.179 4.179 0 001.419-3.132zm-4.192 3.02a3.02 3.02 0 113.02-3.02 3.023 3.023 0 01-3.02 3.02z"
              className="class-clock-icon__cls-2"
              data-name="Path 5327"
              transform="translate(-3.53 -5.698)"
            />
            <path
              id="class-clock-icon__Path_5328"
              d="M22.676 19.416l-1.152-.716a.55.55 0 00-.2-.333l.053-1.446a.335.335 0 00-.669-.025l-.053 1.447a.556.556 0 00.564.955l1.105.683a.335.335 0 10.351-.57z"
              className="class-clock-icon__cls-2"
              data-name="Path 5328"
              transform="translate(-16.167 -12.881)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ClockIcon;
