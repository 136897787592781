import React from "react";

//components
import Login from "../../components/login";



const LoginPage = () => {
    return (
        <div>
            <Login />
        </div>
    )

}

export default LoginPage;