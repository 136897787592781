import React, { useEffect, useRef } from "react";
// import HeatMap from "react-heatmap-grid";

//mui
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';


//components
import Loader from "../../../custom-styled/loader";

//utils
import isEmpty from "../../../../utils/isEmpty";

//styles
import useStyles from "./index.styles";
import { useState } from "react";

// const defaultHeadings = new Array(12).fill(0).map((_, i) => `${i + 1}`);

const defaultYLabels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

// const defaultData = new Array(defaultYLabels.length).fill(0).map(() =>
//     new Array(defaultHeadings.length).fill(0).map(() => 0)
// );

const defaultHeadings = [
    { title: "Username", key: "Username" },
    { title: "Plan", key: "Plan" },
    { title: "Execute", key: "Execute" },
    { title: "Sustain", key: "Sustain" },
    { title: "Context", key: "Context" },
    { title: "test", key: "test" },
    { title: "tes", key: "tes" },
];

const defaultData = [
    {
        "Username": "Jdivya",
        "Plan": 0,
        "Execute": 0,
        "Sustain": 0,
        "Context": 0,
        "test": "85.02",
        "tes": "68.46"
    },
    {
        "Username": "Zuhaib",
        "Plan": 5,
        "Execute": 3,
        "Sustain": 68.33,
        "Context": 90,
        "test": "35.02",
        "tes": "79"
    },
    {
        "Username": "Akshay",
        "Plan": 76,
        "Execute": 32,
        "Sustain": 48,
        "Context": 80,
        "test": "58",
        "tes": "90"
    },
]

const defaultLegend = [//default is set as retention
    { name: "Highest", hexCode: "#43A047", value: "81-100", startSeverity: 81, endSeverity: 100 },//81-100, color: dark-green
    { name: "High", hexCode: "#00CC2E", value: "61-80", startSeverity: 61, endSeverity: 80 },//61-80, color: light-green
    { name: "Medium", hexCode: "#FFB300", value: "41-60", startSeverity: 41, endSeverity: 60 },//41-60 , color: yellow
    { name: "Low", hexCode: "#D93731", value: "0-40", startSeverity: 0, endSeverity: 40 } //0-40, color: red
]



const Heatmap = ({
    loader = false,
    loaderText = "GENERATING REPORT ",
    showGenerate,
    handleGenerate,
    progress = 0,
    headings = defaultHeadings,
    // yLabels = defaultYLabels,
    tableData = defaultData,
    legend = defaultLegend,
    isRetention = true,
}) => {
    // console.log('headings = ', headings);
    // // console.log('yLabels = ', yLabels);
    // console.log('tableData = ', tableData);
    // console.log('isRetention = ', isRetention);
    // console.log('legend = ', legend);
    const styles = useStyles();
    //   const router = useRouter();
    const loadingDivRef = useRef(null);
    const [toggle, setToggle] = useState(false);
    // const [sortBy, setSortBy] = useState(headings[0].key);
    // const [sortedData, setSortedData] = useState([]);
    // console.log('sortBy = ', sortBy);


    // console.log('MasteroTable tableHeadings = ', tableHeadings);

    useEffect(() => {
        if (loader || showGenerate) {
            scrollToStart()
        }
    }, [loader, showGenerate])

    useEffect(() => {
        if (!showGenerate && !loader) {
            setToggle(true);
            setTimeout(() => {
                setToggle(false);
            }, 1000)
        }
    }, [isRetention])

    // useEffect(() => {
    //     setSortBy(headings[0].key);
    //     console.log('while headings are changed headings[0].key = ', headings[0].key);
    // }, [headings])

    // useEffect(() => {
    //     console.log('sortBy = ', sortBy);
    //     let tempData = [...tableData];
    //     tempData.sort((a, b) => {
    //         console.log('a = ', a);
    //         console.log('b = ', b);
    //         console.log('a[sortBy] = ', a[sortBy]);
    //         if (!isEmpty(a[sortBy]) && !isEmpty(b[sortBy])) {
    //             if (a[sortBy].toString().toLowerCase() < b[sortBy].toString().toLowerCase()) {
    //                 return -1
    //             } 
    //             else if (a[sortBy].toString().toLowerCase() > b[sortBy].toString().toLowerCase()) {
    //                 return 1
    //             } 
    //             else return 0
    //         }else{
    //             console.log('inside else block [sortBy] = ', a[sortBy]);
    //             return 1
    //         }

    //     })
    //     console.log('tableData = ', tableData);
    //     console.log('tempData = ', tempData);
    //     setSortedData(tempData);
    // }, [sortBy, tableData])



    const scrollToStart = () => {
        if (loadingDivRef.current) {
            loadingDivRef.current.scrollLeft = 0;
        }
    };

    const getValue = (element, data, tableIndex, cellIndex) => {
        // console.log('tableIndex', tableIndex);
        // console.log('cellIndex', cellIndex);
        // console.log('data', data);
        // console.log('element[data.key] ', element[data.key]);

        if (cellIndex === 0 && tableIndex === tableData.length - 1) {
            // console.log('last table row data.key ', data.key);
            return "Overall";
        }
        if (isEmpty(element[data.key])) return "-";
        if (cellIndex === 0 || data.isDemographic) return element[data.key];
        let value;
        if (!isRetention) {
            value = Math.round(100 - parseFloat(element[data.key]));
            console.log('value = ', value);
            console.log('value isNan = ', isNaN(value));
            if (isNaN(value)) return 'NA';
            return `${value}%`;
        }
        value = Math.round(parseFloat(element[data.key]));
        console.log('value = ', value);
        console.log('value isNan = ', isNaN(value));
        if (isNaN(value)) return 'NA';
        return `${value}%`;
    }

    const getCellStyles = (element, data, tableIndex, cellIndex) => {
        let defaultStyles = {
            backgroundColor: "#ffffff",
            color: 'rgb(0,0,0,0.9)'
        }
        if (cellIndex === 0 && tableIndex === tableData.length - 1) {
            return { ...defaultStyles, fontWeight: 800 };
        }
        if (cellIndex === 0 || data.isDemographic) return defaultStyles;
        if (isEmpty(element[data.key])) return defaultStyles;
        let { severity, hexCode } = getSeverity(element, data);
        defaultStyles = { ...defaultStyles, backgroundColor: hexCode, color: '#ffffff' };
        //STYLES FOR N/A
        let value = Math.round(parseFloat(element[data.key]));
        if (!isRetention) value = Math.round(100 - parseFloat(element[data.key]));
        if (isNaN(value)) {
            console.log('isNaN(value) = ', isNaN(value));
            console.log('element[data.key] = ', element[data.key]);
            defaultStyles = { backgroundColor: '#ffffff', color: 'rgb(0,0,0,0.9)' };
        }
        // console.log('defaultStyles = ', defaultStyles);
        return defaultStyles;
    }

    const getSeverity = (element, data) => {
        let severity;
        let hexCode;
        let value = Math.round(parseFloat(element[data.key]));
        if (isRetention === false) value = `${Math.round(100 - parseFloat(element[data.key]))}`;;
        for (let i = 0; i < legend.length; i++) {
            let ele = legend[i];
            if (parseInt(value) >= ele.startSeverity && parseInt(value) <= ele.endSeverity) {
                severity = ele.name;
                hexCode = ele.hexCode;
            }
        }
        return { severity, hexCode };
    }
    return (
        <>
            <div className={`${styles.wrapper} ${loader || showGenerate || !tableData.length ? styles.disableScroll : ""}`} ref={loadingDivRef}>
                <table className={styles.Table}>
                    {!showGenerate && <thead style={{
                        backgroundColor: '#fff',//while freezing table header
                        position: 'sticky',//while freezing table header
                        top: 0 //while freezing table header
                    }}>
                        <tr className={styles.Table_Heading}>
                            {headings.map((element, i) => (
                                <th key={i}>{element.title}</th>
                            ))}
                        </tr>
                    </thead>}
                    {!loader && !showGenerate && <tbody>
                        {tableData.map((element, key) => (
                            <tr className={styles.Table_Data} key={key}>

                                {
                                    headings.map((data, i) => {
                                        return (
                                            <td key={i}>
                                                <h1 style={getCellStyles(element, data, key, i)} className={`${toggle && i !== 0 && !data.isDemographic ? styles.scaleDown : ''}`}>{getValue(element, data, key, i)}</h1>
                                            </td>
                                        )
                                    }
                                    )
                                }
                            </tr>
                        ))}
                    </tbody>}
                </table>
                {showGenerate ?
                    <div className={styles.emptyTable}>
                        <ButtonBase onClick={handleGenerate}>GENERATE REPORT</ButtonBase>
                    </div> : null}
                {
                    (loader && !showGenerate) ?
                        <div className={styles.loaderBox}>
                            {/* <Loader /> */}
                            <LinearProgressWithLabel value={progress} />
                            <h1>
                                {loaderText}
                                {`(${Math.round(progress)}%)`}
                            </h1>
                        </div> : null
                }
                {
                    (!showGenerate && !loader && !tableData.length && <div className={styles.emptyTable} style={{ minHeight: '366px' }}>
                        No data found.
                    </div>)
                }
            </div>
            {true ? <div className={styles.legendBox} style={{ borderTop: loader ? '1px solid rgb(112, 112, 112, 0.4)' : 'none' }}>
                {
                    legend.map((item, i) => (
                        <div key={i} className={styles.legend}>
                            <div style={{ backgroundColor: item.hexCode, }}>{item.value}</div>
                            <h4>{item.name}</h4>
                        </div>
                    ))
                }
            </div> : null}
        </>
    );
}

export default Heatmap;

function LinearProgressWithLabel(props) {
    const styles = useStyles();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
                width: '42rem',
                height: '12px',
                mr: 1,
                '& > .MuiLinearProgress-root': {
                    height: '10px',
                    borderRadius: '4px'
                }
            }}>
                <LinearProgress className={styles.linearProgress} variant="determinate" color='warning' {...props} />
            </Box>
            {/* <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" className={styles.typography}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box> */}
        </Box>
    );
}