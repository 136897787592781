import React from 'react'

//components
import Analytics from '../../components/analytics'

const AnalyticsPage = () => {
  return (
    <Analytics/>
  )
}

export default AnalyticsPage
