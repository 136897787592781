import React, { useState, useEffect, useContext, useRef } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import MuiCheckbox from "@mui/material/Checkbox";
import dayjs from 'dayjs';

//components
import Title from '../../../title'
import Table from '../table';
import Label from '../../../custom-styled/label';
import EmailReports from '../email-report';
import MultipleFilter from '../multiple-filter';
import ControlledSwitches from '../../../custom-styled/switch-button';
import DatePicker from '../date-picker';

//utils
import useQuery from '../../../../utils/useQuery';
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';

//services
import reportsServices from '../../../../services/reports';

//global-states
import { GlobalStates } from '../../../../App';

let defaultPagination = {
    limit: 1,
    pageCount: 0,
    startIndex: -1,
    totalCount: 0
}

let staticDemographicFilters = [
    {
        id: "username", name: 'Username', subMenu: [], selected: []
    }
]


const defaultHeadings = [
    { title: "Learner Name", key: "Learner Name" },
    { title: "Email ID", key: "Email" },
    { title: "Username", key: "Username" },
    { title: "First Name", key: "First Name" },
    { title: "Last Name", key: "Last Name" },
    { title: "Mobile", key: "Mobile" },
    { title: "Designation", key: "Designation" },
    { title: "Zone", key: "Zone" },
    { title: "Supervisor", key: "Supervisor" },
    { title: "Business Group", key: "Business Group" },
    // { title: "Password", key: "Password" },
    { title: "LoginStatus", key: "LoginStatus" },
    { title: "First Login", key: "firstLogin", isDate: true },
    { title: "Latest Login", key: "latestLogin", isDate: true },
];




const LoginReport = ({
    setBackdrop,
    mapping,
    demographicFilter,
    setDemographicFilter,
    _applyDemographicFilter,
    _selectAllSubmenuDF,
    _handleSubmenuItemDF,
    _resetDemographicFilter,
    handleDemographicSearch,
    _resetDateRange }) => {
    const classes = useStyles();
    const query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates);
    const [headings, setHeadings] = useState(defaultHeadings);
    const [tableData, setTableData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [tableLoader, setTableLoader] = useState(false);
    const [progress, setProgress] = useState(0);
    const [intervalCleared, setIntervalCleared] = useState(false);
    const [pagination, setPagination] = useState(defaultPagination);
    const [payload, setPayload] = useState({ companyId: companyId, flag: "filter" });
    const today = dayjs();
    const [dateRange, setDateRange] = useState([null, null]);
    const [limit, setLimit] = useState(50);
    const [emailId, setEmailId] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    let stringifiedDemographicFilter = JSON.stringify(staticDemographicFilters);
    let parsedDemographicFilter = JSON.parse(stringifiedDemographicFilter);
    // const [mapping, setMapping] = useState([]);
    let mappingObject = commonUtil.objectify(mapping, "name");
    // const [demographicFilter, setDemographicFilter] = useState(parsedDemographicFilter);
    const [showGenerate, setShowGenerate] = useState(true);
    const [detailedReport, setDetailedReport] = useState(true);
    const [allData, setAllData] = useState([]);
    const [selectedShortcut, setSelectedShortcut] = useState(null);
    let myBookName = detailedReport ? "Login Details" : "Login Summary";
    let minDate = dayjs(new Date('2022-01-01'));
    const intervalRef = useRef();

    useEffect(() => {
        if (tableData.length) {
            updateTableData(allData);
        }
    }, [detailedReport])

    useEffect(() => {
        if (!showGenerate) {
            fetchLoginReports(payload, { startIndex, limit });
        }
    }, [showGenerate, startIndex])

    useEffect(() => {
        // fetchLoginReports(payload, { startIndex, limit });
        setShowGenerate(true);
        setStartIndex(0);
        // setPagination(defaultPagination);
        setProgress(0);
    }, [payload, limit])

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [])


    useEffect(() => {
        // console.log('progress = ', progress);
        if (intervalCleared) {
            setProgress(0);
            setTableLoader(false);
            setIntervalCleared(false);
            // globalStates.handleToast(true, 'Login reports generated successfully! ', 'success');
        }

    }, [intervalCleared])



    const updateProgress = () => {
        intervalRef.current = setInterval(() => {
            // console.log('setInterval calling', intervalRef.current);
            setProgress((prevProgress) => {
                console.log('prevProgress in setInterval = ', prevProgress);
                let random = prevProgress + Math.floor(Math.random() * 20);
                if (prevProgress >= 100 || random >= 100) {
                    clearInterval(intervalRef.current);
                    setIntervalCleared(true);
                    return 100
                } else {
                    return random
                }
            });
        }, 300);

    };

    const handleChangeLimit = (event) => {
        setStartIndex(0)
        setPageNo(1);
        setLimit(event.target.value);
    };

    const handleBackClick = () => {
        if (showGenerate) return
        if (startIndex >= limit) {
            setStartIndex(startIndex - limit)
            setPageNo(pageNo - 1);
        }
    }

    const handleForwardClick = () => {
        if (showGenerate) return
        if (startIndex + limit < totalCount) {
            setStartIndex(startIndex + limit);
            setPageNo(pageNo + 1);
        }
    }
    const updateTableData = (allBookData) => {
        let [resData] = allBookData.data.filter(ele => ele.bookName === myBookName);
        let paginationOptions = allBookData.paginationOptions;
        const headings = commonUtil.getHeadings(resData.data, 'dateOnly');
        // console.log('Login headings = ', headings);
        if (headings.length) setHeadings(headings);
        setTableData(resData.data);
        setPagination(paginationOptions);
        setPageCount(paginationOptions.pageCount);
        setTotalCount(paginationOptions.totalCount);
    }

    const fetchLoginReports = async (payload, options = {}) => {
        setTableLoader(true);
        // return
        const response = await reportsServices.fetchLoginReports(payload, options);
        // console.log('fetchLoginReports response = ', response);
        if (response && response.status === 200) {
            let allBookData = response.data.data;
            // console.log('allBookData= ', allBookData);
            setAllData(allBookData);
            updateTableData(allBookData);
            updateProgress();
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            return
        }
    }


    const handleDateChange = (newValue) => {
        if (newValue[1] !== null) {
            let newPayload = {
                ...payload,
                companyId: companyId,
                flag: 'filter',
                fromDate: dayjs(newValue[0]).format('YYYY-MM-DD HH:mm:ss.SSS'),
                toDate: dayjs(newValue[1]).format('YYYY-MM-DD HH:mm:ss.SSS')
            }
            console.log('newPayload = ', newPayload);

            setPayload(newPayload);
        }

        setDateRange(newValue);

    }

    const getMappingDetails = (value) => {
        return mappingObject[value]
    }

    const handleDownloadLoginReport = async () => {
        console.log('handleDownloadLoginReport');
        // if (!tableData.length) {
        //     globalStates.handleToast(true, "No Data Found.", "warning");
        //     return
        // }
        setBackdrop(true);
        let downloadAsExcelPayload = {
            ...payload,
            companyId: companyId,
            flag: "excel"
        };
        if ("demographics" in downloadAsExcelPayload) {
            let selectedFilters = [...downloadAsExcelPayload["demographics"]];
            console.log('demographics are selected and selectedFilters = ', selectedFilters);
            let selectedOrders = [];
            for (let i = 0; i < selectedFilters.length; i++) {
                const mappingObj = getMappingDetails(selectedFilters[i].excelColumn);
                console.log('mappingObj = ', mappingObj);
                selectedOrders.push(mappingObj.order);
            }
            let sortedMapping = [...mapping];
            sortedMapping.sort((a, b) => {
                if (a.order < b.order) return -1
                else return 1
            })
            selectedOrders.sort((a, b) => b - a);
            let current = selectedOrders[0];
            console.log('current = ', current);
            if (!isEmpty(current)) {
                for (let j = 0; j < sortedMapping.length; j++) {
                    let isFilter = sortedMapping[j].isFilter;
                    let order = sortedMapping[j].order;
                    if (isFilter === 1 && order > current && sortedMapping[j].table_column !== "passwordHash") {
                        if (!selectedOrders.includes(order)) {
                            let missingFilterObj = {
                                columnName: sortedMapping[j].table_column,
                                excelColumn: sortedMapping[j].excel_column,
                                isFilter: sortedMapping[j].isFilter,
                                order: sortedMapping[j].order,
                                values: [],
                            }
                            selectedFilters.push(missingFilterObj);
                            break;
                        }
                    }
                }
                downloadAsExcelPayload["demographics"] = selectedFilters;
            }
        }

        console.log('downloadAsExcel payload = ', downloadAsExcelPayload);
        const response = await reportsServices.fetchLoginReports(downloadAsExcelPayload, { startIndex: 0 });
        console.log('downloadAsExcel response = ', response);
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location;
            console.log('downloadAsExcel fileUrl = ', fileUrl);
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Login reports'; // Set the desired filename for the downloaded file
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }

    const handleEmailLoginReport = async () => {
        console.log('email id = ', emailId);
        console.log('is valid emailId = ', commonUtil.isValidEmail(emailId));
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        setSendingEmail(true);
        let emailReportPayload = {
            ...payload,
            companyId: companyId,
            flag: "email",
            emailId: emailId
        };
        console.log('emailReportPayload = ', emailReportPayload);
        const response = await reportsServices.fetchLoginReports(emailReportPayload, { startIndex: 0 });
        console.log('emailReport response = ', response);
        if (response.status === 200) {
            let message = `Login Report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
            setSendingEmail(false);
            setEmailDialog(false);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setSendingEmail(false);
        }

    }


    const handleApplyDemographicFilter = () => {
        const { newDemographicFilterData, newPayload } = _applyDemographicFilter(demographicFilter, payload);
        setPayload(newPayload);
        setDemographicFilter(newDemographicFilterData);
    }

    const handleResetDemographicFilter = () => {
        let newPayload = {
            ...payload,
            companyId: companyId,
            flag: "filter"
        };
        if (newPayload.hasOwnProperty('demographics')) {
            delete newPayload['demographics'];
        }
        console.log('handleResetDemographicFilter newPayload = ', newPayload);
        setPayload(newPayload);
        _resetDemographicFilter();
    }

    const handleResetDateRange = () => {
        let { newPayload } = _resetDateRange(payload);
        setPayload(newPayload);
        setDateRange([null, null]);
    }


    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <Title titleText={"Login Report"} />
                <div className={classes.actionIcons}>
                    <EmailReports
                        open={emailDialog}
                        setOpen={setEmailDialog}
                        loading={sendingEmail}
                        handleChange={(e) => setEmailId(e.target.value)}
                        handleClick={handleEmailLoginReport}
                    />
                    <img src='/images/analytics/download-icon.svg' alt='' title='Download Login Report' onClick={handleDownloadLoginReport} />
                </div>
            </div>
            <div className={classes.tableBox}>
                <div className={classes.filtersContainer}>
                    <span className={classes.switchWrapper}>
                        Detailed Report
                        <ControlledSwitches checked={detailedReport} handleChange={() => setDetailedReport(!detailedReport)} />
                    </span>
                    <MultipleFilter
                        label={"Demographic Filters"}
                        title={"Select Demographics"}
                        filterList={demographicFilter}
                        setFilterList={setDemographicFilter}
                        handleSaveFilter={handleApplyDemographicFilter}
                        handleResetFilter={handleResetDemographicFilter}
                        handleSearch={handleDemographicSearch}
                        customhandleClick={_handleSubmenuItemDF}
                        customSelectAll={_selectAllSubmenuDF}
                    />
                    <div className={classes.dateRangeWrapper}>
                        {/* <Label labelText={"Date Range"} /> */}
                        <DatePicker
                            value={dateRange}
                            maxDate={today}
                            minDate={minDate}
                            handleDateChange={handleDateChange}
                            handleReset={handleResetDateRange}
                            selectedShortcut={selectedShortcut}
                            setSelectedShortcut={setSelectedShortcut}
                        />
                    </div>
                </div>
                <Table
                    loader={tableLoader}
                    showGenerate={showGenerate}
                    handleGenerate={() => setShowGenerate(false)}
                    progress={progress}
                    tableHeadings={headings}
                    tableData={tableData}
                    handleForwardClick={handleForwardClick}
                    handleBackClick={handleBackClick}
                    pageCount={pagination.pageCount}
                    pageNo={pageNo}
                    pagination={pagination}
                    rows={limit}
                    handleChangeNumberOfRows={handleChangeLimit}
                />
            </div>

        </div>
    )
}

export default LoginReport;

const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};



const useStyles = makeStyles(() => ({
    main: {
        // border: '1px solid',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#f9f9f9',
        padding: '0.2rem 1rem',
        margin: '1.5rem 0',
        height: '3rem'
    },
    actionIcons: {
        position: 'absolute',
        right: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        '& img': {
            height: '1.9rem',
            transition: '.4s',
            '&:hover': {
                height: '2rem',
                cursor: 'pointer'
            }
        },

    },
    tableBox: {
        // border: '1px solid blue',
        margin: '1rem',
        marginBottom: '2rem',
        boxShadow: "0px 0px 8px -4px rgba(0,0,0,0.5)",
        borderRadius: '6px'
    },
    filtersContainer: {
        // border: '1px solid red',
        position: 'relative',
        height: '3.8rem',
        gap: '1.3rem',
        display: 'flex',
        padding: '0 1rem',
        alignItems: "center"
    },
    switchWrapper: {
        // border: '1px solid green',
        fontSize: '0.8rem',
        color: "rgb(0, 0, 0, 0.9)",
        fontFamily: "Montserrat",
        fontWeight: 700,
    },
    demographicsFilterWrapper: {
        // border: '1px solid',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14rem',
        '& > h1': {
            border: '1px solid rgb(112,112,112, 0.3)',
            borderRadius: '3px',
            fontSize: '0.8rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 600,
            padding: '0.4rem 0.5rem',
            display: 'flex',
            alignItems: 'center',
            '& > i': {
                marginLeft: '0.8rem',
                fontSize: '1rem',
                color: '#f4511e'
            },
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
            }
        }
    },
    selectBoxWrapper: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        backgroundColor: '#fff',
        borderRadius: '4px',
    },
    dateRangeWrapper: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        gap: '1rem',
        right: '1rem',
        height: '100%',
        "& > span": {
            marginRight: '-0.9rem'
        }
    },
    datePickerBox: {
        border: '1px solid red',
        '& > div': {
            overflow: 'hidden !important',
            paddingTop: '0.4rem !important',
            height: '2.5rem !important',
            '& .MuiInputBase-root': {
                height: '2rem !important',
                width: '8rem !important',
                color: '#707070'

            },
            '& .MuiFormLabel-root': {
                fontFamily: 'Montserrat',
                fontSize: '0.9rem',
                fontWeight: 600
            }
        }
    },
}));

