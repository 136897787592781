
// import jwt_decode from "jwt-decode";

// // redux
// import { fetchStart, fetchSuccess, fetchFail } from "../common/common.actions";
// import { setAnalyticsToken } from "./auth.actions";

// // utils
// import axiosInstance from "../../utils/axios";

// // constants
// import constants from "../../constants";
// import isEmpty from "../../utils/isEmpty";

// export const getToken = async (dispatch, req) => {
//     try {
//         dispatch(fetchStart());
//         const resp = await axiosInstance.post(`${constants.DRIVE_API_QA_URL}/mastero/UserAuthencryptionfirstscreenv1`, req);

//         dispatch(fetchSuccess());
//         if (!isEmpty(resp.data.token)) {
//             dispatch(setAnalyticsToken(resp.data.token));
//         }
//         return resp;
//     } catch (err) {
//         console.log("getToken:", err)
//         dispatch(fetchFail(err.response));
//     }
// }

// export const login = async (dispatch, req) => {
//     try {
//         dispatch(fetchStart());
//         const user = await axiosInstance.post(`${constants.DRIVE_API_QA_URL}/get-token`, req);
//         //Localstorage.setItem('token', user.data.token)
//         dispatch(fetchSuccess());
//         dispatch(setAnalyticsToken(user.data.token));
//         var decoded = jwt_decode(user.data.token);
//         console.log("decode:", decoded);
//         return user;
//     } catch (err) {
//         console.log("err:", err.response)
//         dispatch(fetchFail(err.response));
//     }
// }

export const logout = () => {
    window.localStorage.clear();
    window.location.href = "/login";
};