import React from 'react'

//  components
import CustomInput from '../custom-input'

//  mui
import Radio from "@mui/material/Radio"
import Checkbox from "@mui/material/Checkbox"
import { FormControlLabel } from '@mui/material'
import Button from '@mui/material/Button';
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from '@mui/icons-material/Delete';

//  styles
import useStyles from './index.styles'

const ResponseQuestions = ({
    questionIdx,
    answers,
    typeQuestion,
    handleInputAnswer,
    validation,
    handleCorrectIncorrectOption,
    handleAddAnswer,
    setShowDeleteDialog,
    setDeleteParameters
}) => {

    const styles = useStyles();

    const handleDeleteAnswer = (e, answerIdx) => {
        setShowDeleteDialog(true);
        setDeleteParameters({ questionIdx: questionIdx, answerIdx: answerIdx, type: 'Answer' })
    }

    return (
        <>
            <div key={questionIdx} className={styles.Heading}>Responses:</div>
            <div className={styles.AnswersContainer}>
                {answers.map((element, i) => (
                    <div key={i} className={styles.Answers}>
                        <div className={styles.AnswerInput}>
                            {element.type === "FIXED" && (
                                <CustomInput
                                    id={i.toString()}
                                    defaultValue={element.option}
                                    handleChange={(e) => handleInputAnswer(e, questionIdx, i)}
                                    // limitText={validation[i].limitText}
                                    // isExceeded={validation[i].exceeded}
                                    isEmpty={element.isAnswerEmpty}
                                    isDisabled={false}
                                    isDisabledTextLimitContainer={true}
                                />
                            )}
                        </div>
                        <FormControlLabel
                            control={
                                typeQuestion === "SINGLE_CHOICE_QUESTION" ?
                                    <Radio
                                        checked={element.value}
                                        style={{ color: "#F4511E" }}
                                        onClick={(e) => handleCorrectIncorrectOption(questionIdx, i)}
                                    /> : <Checkbox
                                        checked={element.value}
                                        style={{ color: "#F4511E" }}
                                        onClick={(e) => handleCorrectIncorrectOption(questionIdx, i)}
                                    />
                            }
                            label={element.value === true ? "Correct" : "Incorrect"} />

                        {/* <DeleteIcon key={i} onClick={(e) => handleDeleteAnswer(e, i)} className={styles.deleteAnswerIcon} /> */}
                    </div>
                ))}

                <div className={styles.AddButtonsContainer}>
                    <Button
                        className={styles.AddButtons}
                        startIcon={<AddIcon style={{ display: "flex", alignItems: "center", justifyContent: "center" }} />}
                        onClick={() => handleAddAnswer(questionIdx)}>
                        Add Option
                    </Button>
                </div>
            </div>
        </>
    )
}

export default ResponseQuestions