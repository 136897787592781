import React, { useState } from 'react';

//components
import lotties from '../../lotties';
import CustomLottie from '../learning-aid/components/custom-lottie';

//styles
import useStyles from './index.styles'
import constants from '../../constants';

const MicroskillCard = ({
  key,
  data,
  handleEditMS,
  handleDeleteMS,
  handleCopyMS,
  handleViewGroups,
  copyMsId = null
}) => {
  const styles = useStyles();
  const [show, setShow] = useState(false)//false
  const handleMouseEnter = () => {
    // console.log("mouse in");
    if (copyMsId === data.id) return;
    setShow(true)
  }
  const handleMouseLeave = () => {
    // console.log("mouse out");
    setShow(false)//false
  }
  // const shadows = ['rgb(104 0 0 / 75%)', 'rgb(0 36 104 / 75%)', 'rgb(104 0 98 / 69%)', 'rgb(51 104 0 / 69%)']
  // const getRandomShadow = () => {
  //   let randomIndex = Math.floor(Math.random() * shadows.length);
  //   return shadows[randomIndex]
  // }

  return (
    <>
      <div
        key={key}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={styles.card}
        style={{
          // '-webkit-box-shadow': '0px 2px 6px 0px rgba(0,0,0,0.2)',
          // boxShadow: '0px 2px 6px 0px rgba(0,0,0,0.2)',
        }}>
        <img
          src={data?.coverImage || data?.OrgCoverImage || constants.MS_DEFAULT_COVER_IMAGE}
          alt=''
          style={{
            filter: (copyMsId === data.id) ? 'blur(7px)' : ''
          }}
        />

        {
          (copyMsId === data.id) ?
            <div className={styles.lottieBox}>
              <CustomLottie animationData={lotties.copyMicroskill} />
              <h5>Copied Successfully!</h5>
            </div> : null
        }
        <div className={styles.cardRow1} style={{
          filter: (copyMsId === data.id) ? 'blur(7px)' : ''
        }}>
          {show ?
            <span onClick={() => handleViewGroups(data)}>
              View groups
            </span> : null
          }
          {show ?
            <span className={styles.btnBox}>
              <button className={styles.editBtn} onClick={() => handleCopyMS(data)}>
                <img src="/images/icons/duplicate.svg" alt="Copy" title="Duplicate" />
              </button>
              <button className={styles.editBtn} onClick={() => handleDeleteMS(data)}>
                <img src="/images/icons/delete-photo.svg" alt="Delete" title="Delete" />
              </button>
            </span> : null}

        </div>

        <div className={styles.cardRow2} onClick={() => handleEditMS(data)} style={{
          filter: (copyMsId === data.id) ? 'blur(7px)' : ''
        }}>
          <h1>{data.name}</h1>
          <div className={styles.viewsLikes}>
            <span>
              <img src="/images/add-microskill/eye-solid.svg" alt='' />
              {data.views}
            </span>
            <span>
              <img src="/images/add-microskill/heart-solid.svg" alt='' />
              {data.likes}
            </span>
          </div>
          <img
            // src={brandIconPath}
            src={data.brandImage || constants.MS_DEFAULT_BRAND_ICON}
            className={styles.brandIcon}
            alt="Brand Icon"
          />
        </div>

      </div>
    </>
  )
  //OLD
  // return (
  //   <>
  //     {/* style={{background : `url(${finalImage})`}} */}
  //     <div onMouseEnter={handleMouseEnter}
  //       onMouseLeave={handleMouseLeave}

  //       className={styles.defaultCard}
  //       style={{
  //         background: `url(${data.coverImage})`,
  //         // boxShadow: `inset 0 -160px 45px -46px ${data.shadowColor}`,//for random box shadows
  //         boxShadow: `inset 0 -160px 45px -46px rgba(0, 0, 0, 0.9)`,
  //         filter: (copyMsId === data.id) ? 'blur(7px)' : ''
  //       }}
  //     >
  //       {/* { 
  //       (copyMsId === data.id) ?
  //         <div className={styles.lottieBox}>
  //           <CustomLottie animationData={lotties.copyMicroskill} />
  //         </div> : null
  //         } */}
  //       <div className={styles.cardRow1}>
  //         {show ?
  //           <span onClick={() => handleViewGroups(data)}>
  //             View groups
  //           </span> : null
  //         }
  //         {show ?
  //           <span className={styles.btnBox}>
  //             <button className={styles.editBtn} onClick={() => handleCopyMS(data)}>
  //               <img src="/images/icons/duplicate.svg" alt="Copy" className={styles.editIcon} title="Duplicate" />
  //             </button>
  //             <button className={styles.editBtn} onClick={() => handleDeleteMS(data)}>
  //               <img src="/images/icons/delete-photo.svg" alt="Delete" className={styles.editIcon} title="Delete" />
  //             </button>
  //           </span> : null}

  //       </div>
  //       <div className={styles.cardRow2} onClick={() => handleEditMS(data)}>
  //         <div className={styles.cardColumn1}>
  //           <h1 className={styles.cardHeading}>{data.name}</h1>
  //           <div className={styles.cardViewsLikes}>
  //             <span className={styles.cardViewsLikesSpan}>
  //               <img className={styles.cardViewsLikesIcons} src="/images/add-microskill/eye-solid.svg" />
  //               {data.views}
  //             </span>
  //             <span className={styles.cardViewsLikesSpan}>
  //               <img className={styles.cardViewsLikesIcons} src="/images/add-microskill/heart-solid.svg" />
  //               {data.likes}
  //             </span>
  //           </div>
  //         </div>
  //         <div className={styles.cardColumn2}>
  //           <img
  //             // src={brandIconPath}
  //             src={data.brandImage}
  //             className={styles.brandIcon}
  //             alt="Brand Icon"
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // )
}

export default MicroskillCard;
