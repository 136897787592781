import React, { useEffect } from 'react'

//components
import CustomButton from '../../../buttons'
import Label from '../../../game-based-assessment/components/label';
import CustomLottie from '../custom-lottie';

//constants
import constants from '../../../../constants'

//styles
import useStyles from './index.styles';


const InteractionTypes = ({ list, setList, ilaCardId, selectedInteraction, setSelectedInteraction, selectedColumns, setSelectedColumns, setSelectedQuestion }) => {
    const classes = useStyles();

    useEffect(() => {
        console.log('InteractionTypes list = ', list); 
        setSelectedQuestion(0);
        setSelectedInteraction({ gameType: 'MCQ', interactionType: 'ILA_MCQ_1', previewUrl: '/images/learning-aid-assets/screens/mcq-v1.png' })
    }, [])

    const handleSelectInteraction = (cardData, rowIndex, columnIndex) => {
        console.log('handleSelectInteraction cardData = ', cardData);
        let selectedDesignJson = JSON.stringify(constants.ILA_QUESTION_JSON[cardData.interactionType]);
        console.log('handleSelectInteraction selectedDesignJson = ',selectedDesignJson);
        let newQuestion = JSON.parse(selectedDesignJson);
        console.log('handleSelectInteraction newQuestion = ',newQuestion);
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === ilaCardId) {
                let temp = [...list];
                console.log('questions  = ', temp[i].games.questions);
                temp[i].games.gameType = cardData.gameType;
                temp[i].games.designType = cardData.interactionType;
                // temp[i].games.questions = [{...newQuestion, designType: cardData.interactionType}];
                temp[i].games.questions = [newQuestion];
                console.log('ILA-temp = ', temp[i]);
                setList(temp);
                break;
            }
        }
        // rowIndex: rowIndex, columnIndex: columnIndex, 
        setSelectedQuestion(0);
        setSelectedInteraction({ gameType: cardData.gameType, interactionType: cardData.interactionType, previewUrl: cardData.previewUrl })
    }

    const handleSave = () => {
        // let selectedDesignJson = constants.ILA_QUESTION_JSON[selectedInteraction.interactionType];
        // console.log('handleSave selectedDesignJson = ', selectedDesignJson);
        // for (let i = 0; i < list.length; i++) {
        //     if (list[i].id === ilaCardId) {
        //         let temp = [...list];
        //         console.log('questions  = ', temp[i].games.questions);
        //         temp[i].games.gameType = selectedInteraction.gameType;
        //         temp[i].games.designType = selectedInteraction.interactionType;
        //         // temp[i].games.questions = [{...selectedDesignJson, designType: selectedInteraction.interactionType}];
        //         temp[i].games.questions = [selectedDesignJson];
        //         console.log('ILA-temp = ', temp);
        //         setList(temp);
        //         break;
        //     }
        // }
        setSelectedColumns({ ...selectedColumns, firstColumn: 'manageInteraction' })
    }

    const handleBack = () => {
        console.log('ilaCardId = ', ilaCardId);
        console.log('handleBack list = ', list);
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === ilaCardId) {
                let temp = [...list];
                console.log('design length = ', temp[i].games.questions.length);
                temp[i].games.gameType = "";
                temp[i].games.designType = ""
                temp[i].games.questions = []
                setList(temp)
                break;
            }
        }
        setSelectedInteraction({ gameType: '', interactionType: '', previewUrl: ''});
        setSelectedColumns({ firstColumn: 'manageInteraction', secondColumn: 'editContentSection' });
    }
    return (
        <>
            <div className={classes.interactionsMainContainer}>
                {/* interactiontypes */}
                {
                    constants.LA_INTERACTIONS.map((rowData, rowIndex) => (

                        <div key={rowIndex}>
                            <Label labelText={rowData.title} fs={'0.6rem'} />
                            <div className={classes.cardsBox} >
                                {
                                    rowData.data.map((cardData, columnIndex) => (
                                        <div className={classes.lottieBox} key={columnIndex} onClick={() => handleSelectInteraction(cardData, rowIndex, columnIndex)}>
                                            {selectedInteraction.interactionType === cardData.interactionType ? <i className="fa-solid fa-circle-check"></i> : null}
                                            <CustomLottie animationData={cardData.animation} />
                                        </div>
                                    ))
                                }

                            </div>

                        </div>
                    ))
                }

            </div>
            <div className={classes.btnBox}>
                <CustomButton handleClick={handleSave} fs={'0.59rem'} ls={1} btnText={'SAVE'} variant={'contained'} textColor={'#fff'} bgcolor={'#f4511e'} />
                <CustomButton handleClick={handleBack} fs={'0.59rem'} ls={1} btnText={'BACK'} />
            </div>
        </>
    )
}

export default InteractionTypes;
