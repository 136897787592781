import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    cardComponent: {
        // margin: '1rem 2rem',
        padding: '2rem',
        marginLeft: '0',
        marginBottom: '0'
    },
    defaultCard: {
        position: 'relative',
        display: "flex",
        flexDirection: "column",
        // height : "100%",
        width: "100%",
        aspectRatio: '16/9',
        borderRadius: "1rem",
        border: '1px solid rgb(0,0,0,0.1)',
        // boxShadow : 'rgb(104 0 0 / 75%) 0px -160px 45px -46px inset',//red box shadow - old
        boxShadow: 'rgba(0, 0, 0, 0.75) 0px -160px 45px -46px inset',
        //old
        // backgroundRepeat : "no-repeat !important",
        // backgroundSize : "100% 100% !important",
        // backgroundColor : "rgba(0,0,0,0.2) !important",
        // backgroundBlendMode : "multiply !important",
        //kk
        backgroundSize: 'cover',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',

    },
    cardRow1: {
        height: '65%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: "flex-end"
    },
    editBtn: {
        margin: "2rem",
        padding: '0.4rem',
        background: "none",
        backgroundColor: "rgba(255,255,255,0.3)",
        borderRadius: "50%",
        cursor: 'pointer',
        border: "none",
        "& :hover": {
            filter: "none"
        }

    },
    editIcon: {
        height: '1rem',
        width: '1.4rem',
        // filter: 'invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%)'
        filter: 'invert(100%) sepia(100%) saturate(590%) hue-rotate(180deg) brightness(100%) contrast(100%)'
    },
    cardRow2: {
        height: '35%',
        display: "flex",
    },
    cardColumn1: {
        // border: "1px dotted white",
        display: "inline-block",
        width: "80%",
        height: "100%",
        containerType: 'inline-size'
    },
    cardHeading: {
        color: "#FFFFFF",
        fontFamily: "Montserrat",
        // fontSize : "1.4rem",
        fontSize: "4.5cqi",
        fontWeight: "600",
        letterSpacing: "0.1rem",
        marginLeft: "10cqi",
        marginTop: "3cqi",
        overflow: "hidden",
    },
    cardViewsLikes: {
        // fontFamily: "Open Sans",
        // fontWeight: "600",
        // letterSpacing : "0.5px",
        // color : "#FFFFFF",
        // fontSize : "0.7rem",
        marginLeft: "10cqi",
        marginTop: "4cqi",
        display: "flex",
        containerType: 'inline-size'
    },
    cardViewsLikesSpan: {
        display: 'flex',
        alignItems: 'center',
        marginRight: "6cqi",
        fontSize: '2.2cqi',
        fontFamily: "Montserrat",
        fontWeight: "600",
        letterSpacing: "0.5px",
        color: "#FFFFFF",
    },
    cardViewsLikesIcons: {
        marginRight: "0.5rem",
        height: "0.8rem",
        width: "0.8rem",
        filter: 'invert(100%) sepia(100%) saturate(590%) hue-rotate(180deg) brightness(100%) contrast(100%)'

    },
    cardColumn2: {
        // border: "1px dotted white",
        display: "flex",
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        height: "100%",
        width: "18%",

    },
    brandIcon: {
        // border: "1px dotted white",
        // border: "dotted 2px #fff",
        position: 'absolute',
        bottom: '4.3rem',
        right: '3rem',
        height: '2.8rem',
        width: 'auto',
        maxHeight: '2.8rem',
        maxWidth: '7rem',
        borderRadius: "1px",

    },
    iconPreviewText: {
        fontFamily: "Open Sans",
        fontWeight: "Bold",
        color: "#FFFFFF",
        fontSize: "0.5rem",
        marginTop: "0.9rem",
        marginLeft: "0.3rem"
    },
    previewTxt: {
        display: "inline-block",
        fontFamily: "Montserrat",
        fontWeight: "800",
        color: "#0D034B",
        fontSize: "0.5rem",
        marginTop: "1rem",
        // marginLeft : "2.2rem"
    },
    note: {
        fontFamily: "Open Sans",
        fontWeight: "700",
        fontStyle: "italic",
        color: "#707070",
        fontSize: "0.7rem",
        marginTop: "0.5rem",
        // marginLeft : "2.2rem"
    },
    noteText: {
        color: "#484848",
    },
}))


export default useStyles