import React, { useState } from 'react'

//  mui
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MuiCheckbox from "@mui/material/Checkbox";
import CableIcon from '@mui/icons-material/Cable';

//components
import SearchBox from '../../../search-box';
import CustomSelect from '../../../custom-styled/custom-select';
import ControlledSwitches from '../../../custom-styled/switch-button';

//styles
import useStyles from './index.styles';
import Label from '../../../custom-styled/label';
import commonUtil from '../../../../utils/commonUtil';

const LaunchPageNav = ({
    selectedNav,
    setSelectedNav,
    LAUNCHPG_NAV_TYPES
}) => {
    const styles = useStyles();

    return (
        <div className={styles.navigation}>
            <ul className={styles.navList}>
                <li className={`${styles.navListItem} centerFlex navListItem1`}>
                    <Button variant='text' className={`${styles.navBtn} ${selectedNav === 'INDIVIDUAL_LAUNCH' ? styles.navBtnSelected : ''}`} onClick={() => {
                        setSelectedNav(LAUNCHPG_NAV_TYPES[0])
                    }}
                    >
                        {/* <CableIcon className={styles.navBtnIcon} sx={{
                            color: selectedNav === LAUNCHPG_NAV_TYPES[0] ? '#f4511e' : '#707070'
                        }} /> */}
                        <img src="/images/icons/socialadmajor.svg" alt="socialadmajor" className={styles.navBtnIcon} style={{
                            filter: selectedNav === LAUNCHPG_NAV_TYPES[0] ? commonUtil.filterBlackColorToOrange : ''
                        }} />
                        individual launch</Button>
                </li>
                <li className={`${styles.navListItem} centerFlex navListItem2`}>
                    <Button variant='text' className={`${styles.navBtn} ${selectedNav === LAUNCHPG_NAV_TYPES[1] ? styles.navBtnSelected : ''}`} onClick={() => {
                        setSelectedNav(LAUNCHPG_NAV_TYPES[1])
                    }}
                    >
                        <CableIcon className={styles.navBtnIcon} sx={{
                            color: selectedNav === LAUNCHPG_NAV_TYPES[1] ? '#f4511e' : '#707070'
                        }} />
                        learning journeys</Button>
                </li>
            </ul>
        </div>
    )
}

export default LaunchPageNav;