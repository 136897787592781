import React, { useState, useEffect } from 'react';
import { Route, Router } from 'react-router';

import jwtDecode from 'jwt-decode';
// import MOClient from '../../MOClient';

//components
import CourseTab from '../../components/course-tab';
import AudienceTab from '../../components/audience-tab'
import LoadingContainer from '../../components/loading-container';
import Loader from '../../components/tms-components/Loader';

//utils
import useQuery from '../../utils/useQuery';

//services
import microskillServices from '../../services/microskill';

const LS_TOKEN_KEY = 'mo-drive-token';
const FRESHCHAT_TOKEN = '3d0017c5-0a60-4693-bdae-3def0407cf94';

function DriveScreen(props) {
  const query = useQuery();
  const companyId = query.get('c_id');
  let [loading, set_loading] = useState(false);
  let [sso_loading, set_sso_loading] = useState(false);
  let [activeProject, setActiveProject] = useState(0);
  let [activeProjectText, setActiveProjectText] = useState(0);
  //to be removed
  let [showIncentiveSection, setShowIncentiveSection] = useState(false);
  let [showRewardSection, setShowRewardSection] = useState(false);
  let [showLearnerJourney, setShowLearnerJourney] = useState(false);
  let [showTeamReport, setShowTeamReport] = useState(false);
  let [showChallengeType, setShowChallengeType] = useState(false);
  let [showLevelFilters, setShowLevelFilterType] = useState(false);
  let [showProjectFilter, setShowProjectFilter] = useState(false);
  let [activeProjectDescription, setActiveProjectDescription] = useState(
    'Sales Training',
  );

  let [logged_in, set_login_status] = useState(true);
  let [sso_logged_in, set_sso_logged_in] = useState(false);
  let [showProjectSelector, setShowProjectSelector] = useState(false);
  let [user_info, set_user_info] = useState({
    first_name: '',
    last_name: '',
    email: '',
    permissions: '',
    preferences: [],
    created_on: '',
  });

  const tempModules = [{ id: 'xyz', name: 'Understanding customer needs' }]

  let [company_info, set_company_info] = useState({
    demographics: [],
    company_logo_url: '',
    modules: []
  });

  let [category_feature, set_category_feature] = useState(true);
  let [classification_feature, set_classfication_feature] = useState(true);
  let [timestamp_feature, set_timestamp_feature] = useState(true);
  let [driveUsers, setDriveUsers] = useState([]);

  let [jwt_token, set_jwt_token] = useState(localStorage.getItem(LS_TOKEN_KEY));
  // console.log('DriveScreen jwt_token = ', jwt_token);
  const userID = query.get('userID');
  const userEmail = query.get('userEmail');
  const modID = query.get('modID');
  const prID = query.get('prID');
  const pass = query.get('pass');
  const compID = query.get('compID');
  const challengeSeq = query.get('challengeSeq');
  const isEdit = query.get('isEdit'); //false for new and true for edit draft
  const challengeId = query.get('challengeID'); //selfcoaching_detail_id


useEffect(()=>{
  fetchMicroskills(companyId);
},[])

const fetchMicroskills = async (companyId) => {
  console.log('fetchMicroskills companyId = ', companyId);
  if (!companyId) {
    // router.history.push('/companies');
    return
  }
  const res = await microskillServices.fetchMicroskills(companyId)
  console.log('fetchMicroskills response = ', res);
  if (res.status === 200 || res.status === 201) {
    let activeMicroskills = res.data.data.filter(element => element.active === true);
    console.log('fetchMicroskills activeMicroskills = ', activeMicroskills);
    set_company_info({...company_info, modules:activeMicroskills })
    return
  }
  if (res.status === 400 || res.status === 401) {
    return
  }
}

  function logout_handler(data) {
    return
    // reset_login();
    props.history.push({
      pathname: '/',
      state: {},
    });
    document.location.reload();
  }

  function initFreshchat(widget) {
    // window.fcc = widget;
    // setFreshchatProperties();
  }

  function setFreshchatProperties() {
    if (typeof window.user === 'undefined') return;
    if (window.fcc != null) {
      window.fcc.user.setProperties({
        email: window.user.email,
        first_name: window.user.first_name,
        last_name: window.user.last_name,
      });
      window.fcc.user.setFirstName(window.user.first_name);
      window.fcc.user.setEmail(window.user.email);
    }
  }

  function reset_login() {
    set_jwt_token(null);
    set_login_status(false);
    set_user_info({
      first_name: '',
      last_name: '',
      email: '',
      permissions: '',
      created_on: '',
    });
    set_company_info({});

    window.localStorage.removeItem(LS_TOKEN_KEY);
    // window.localStorage.removeItem(PASSED_ROLEPLAY_DATA);
    set_loading(false);
  }

  //   token tracker effect
  //   useEffect(() => {
  //     //event listener to get event from other .net code
  //     setTimeout(() => {
  //       let token = window.localStorage.getItem(LS_TOKEN_KEY);
  //       if (token != null) {
  //         // token exists so verify for its expiry
  //         try {
  //           var decoded = jwtDecode(token);
  //           let epoch = Math.floor(new Date().getTime() / 1000);

  //           if (decoded.exp < epoch) {
  //             reset_login();
  //           } else {
  //             set_login_status(true);
  //             set_jwt_token(token);
  //           }
  //         } catch (ex) {
  //         //   reset_login();
  //         }
  //       } else {
  //         // reset_login();
  //       }
  //     }, 5000);
  //   });

  //   const handlemessage = (event) => {
  //     if (
  //       !event.origin.includes('http://localhost:8080') ||
  //       typeof event.data != 'string'
  //     )
  //       return;

  //     set_jwt_token(null);
  //     set_user_info({
  //       first_name: '',
  //       last_name: '',
  //       email: '',
  //       permissions: '',
  //       created_on: '',
  //     });
  //     set_company_info({});
  //     window.localStorage.removeItem(LS_TOKEN_KEY);
  //     // window.localStorage.removeItem(PASSED_ROLEPLAY_DATA);
  //     set_loading(false);

  //     try {
  //       let challangeData = JSON.parse(event.data);
  //       if (
  //         challangeData.userEmail &&
  //         challangeData.userEmail.length > 0 &&
  //         challangeData.password &&
  //         challangeData.password.length
  //       ) {
  //         const email = challangeData.userEmail;
  //         const password = challangeData.password;
  //         const fetch_result = MOClient.get_token({
  //           email,
  //           password,
  //         });
  //         set_loading(true);
  //         fetch_result.then((result) => {
  //           if (result.status !== 200) {
  //             const result_json = result.json();
  //             result_json.then((result_json) => {
  //               //do nothing for now
  //               set_loading(false);
  //               return;
  //             });
  //           } else {
  //             const async_result_json = result.json();
  //             async_result_json.then((result_json) => {
  //               const jwtToken = result_json.token;
  //               set_loading(false);
  //               //set data to localstorage
  //               const passed_roleplayData = { ...challangeData, password: '' };
  //               saveChallengeData(passed_roleplayData);
  //               window.removeEventListener('message', handlemessage);
  //               set_sso_logged_in(true);
  //               on_login_success({
  //                 token: jwtToken,
  //                 status: 'success',
  //               });
  //               return;
  //             });
  //           }
  //         });
  //       } else {
  //         set_loading(false);
  //         return;
  //       }
  //     } catch (err) {
  //       console.log('err:', err);
  //       set_loading(false);
  //       return;
  //     }
  //   };

  //   const handleCMSRoute = () => {
  //     if (!userEmail && !pass) return;

  //     set_jwt_token(null);
  //     set_user_info({
  //       first_name: '',
  //       last_name: '',
  //       email: '',
  //       permissions: '',
  //       created_on: '',
  //     });
  //     set_company_info({});
  //     window.localStorage.removeItem(LS_TOKEN_KEY);
  //     window.localStorage.removeItem(PASSED_ROLEPLAY_DATA);
  //     set_loading(false);
  //     try {
  //       let challangeData = {
  //         userID: userID,
  //         userEmail: userEmail,
  //         moduleID: modID,
  //         projID: prID,
  //         password: pass,
  //         companyID: compID,
  //         Challenge_Seq: challengeSeq,
  //         isEdit: isEdit,
  //         challengeId: challengeId,
  //       };
  //       if (
  //         challangeData.userEmail &&
  //         challangeData.userEmail.length > 0 &&
  //         challangeData.password &&
  //         challangeData.password.length
  //       ) {
  //         const email = challangeData.userEmail;
  //         const password = challangeData.password;
  //         const fetch_result = MOClient.get_token({
  //           email,
  //           password,
  //         });
  //         set_loading(true);
  //         fetch_result.then((result) => {
  //           if (result.status !== 200) {
  //             const result_json = result.json();
  //             result_json.then((result_json) => {
  //               //do nothing for now
  //               set_loading(false);
  //               return;
  //             });
  //           } else {
  //             const async_result_json = result.json();
  //             async_result_json.then((result_json) => {
  //               const jwtToken = result_json.token;
  //               set_loading(false);
  //               //set data to localstorage
  //               const passed_roleplayData = { ...challangeData, password: '' };
  //               window.localStorage.setItem(
  //                 PASSED_ROLEPLAY_DATA,
  //                 JSON.stringify(passed_roleplayData),
  //               );
  //               window.removeEventListener('message', handlemessage);
  //               set_sso_logged_in(true);
  //               on_login_success({
  //                 token: jwtToken,
  //                 status: 'success',
  //               });
  //               return;
  //             });
  //           }
  //         });
  //       } else {
  //         set_loading(false);
  //         return;
  //       }
  //     } catch (err) {
  //       console.log('err:', err);
  //       set_loading(false);
  //       return;
  //     }
  //   };

  const getChallengeData = () => {
    return null
    try {
      //   const roleplayChallangeData = JSON.parse(
      //     window.localStorage.getItem(PASSED_ROLEPLAY_DATA),
      //   );
      //   return roleplayChallangeData;
    } catch (error) {
      throw Error('no data found');
    }
  };

  const saveChallengeData = (data) => {
    // window.localStorage.setItem(PASSED_ROLEPLAY_DATA, JSON.stringify(data));
  };

  //   useEffect(() => {
  //     if (!sso_logged_in) {
  //       //window.addEventListener('message', handlemessage);
  //     //   handleCMSRoute();
  //     }
  //     // check for url params and SSO.
  //     var url = new URL(window.location.href);

  //     var sso = url.searchParams.get('sso');

  //     if (sso !== null && sso == 1) {
  //       // sso is present, get the JWT token
  //       set_sso_loading(true);
  //       let client_id = url.searchParams.get('client_id');
  //       url.searchParams.delete('client_id');
  //       let params = url.searchParams.toString();

  //       let fetch_jwt_token = MOClient.get_token_from_sso(client_id, params);

  //       fetch_jwt_token.then((res) => {
  //         window.localStorage.setItem(LS_TOKEN_KEY, res.token);
  //         window.location.href = window.location.href.split('?')[0];
  //         set_sso_loading(false);
  //       });
  //     }
  //     // return () => {
  //     //   window.removeEventListener('message', handlemessage);
  //     // };
  //   }, []);

  useEffect(() => {
    // if (company_info)
    //   localStorage.setItem('company-info', JSON.stringify(company_info));
  }, [company_info]);

  useEffect(() => {
    // if (user_info.permissions.company_id)
    //   localStorage.setItem(
    //     'company-id',
    //     JSON.stringify(user_info.permissions.company_id),
    //   );
  }, [user_info]);

  useEffect(() => {
    // if (jwt_token) localStorage.setItem('auth-token', jwt_token);
  }, [jwt_token]);

  // function dispatch_user_info(token) {
  //   set_loading(true);
  //   let async_user_info = MOClient.fetch_user_info(token);
  //   let async_company_info = MOClient.fetch_company_info(token);
  //   let async_feature_access = MOClient.get_feature_access(token);

  //   Promise.all([
  //     async_user_info,
  //     async_company_info,
  //     async_feature_access,
  //   ]).then(([info, company_info, feature_access]) => {
  //     console.log('dispatch_user_info info = ', info);
  //     console.log('dispatch_user_info company_info = ', company_info);
  //     console.log('dispatch_user_info feature_access = ', feature_access);
  //     feature_access.incentive_status === 0
  //       ? setShowIncentiveSection(false)
  //       : setShowIncentiveSection(true);
  //     feature_access.rewards_recognition === 0
  //       ? setShowRewardSection(false)
  //       : setShowRewardSection(true);
  //     feature_access.dashboard_module_categories === 0
  //       ? set_category_feature(false)
  //       : set_category_feature(true);
  //     feature_access.dashboard_module_classifications === 0
  //       ? set_classfication_feature(false)
  //       : set_classfication_feature(true);
  //     feature_access.timestamp_access === 0
  //       ? set_timestamp_feature(false)
  //       : set_timestamp_feature(true);
  //     feature_access.learner_challenge_journey === 0
  //       ? setShowLearnerJourney(false)
  //       : setShowLearnerJourney(true);
  //     feature_access.team_challenge_report === 0
  //       ? setShowTeamReport(false)
  //       : setShowTeamReport(true);
  //     feature_access.challenge_type_filter === 0
  //       ? setShowChallengeType(false)
  //       : setShowChallengeType(true);
  //     feature_access.level_filters === 0
  //       ? setShowLevelFilterType(false)
  //       : setShowLevelFilterType(true);
  //     feature_access.project_filter === 0
  //       ? setShowProjectFilter(false)
  //       : setShowProjectFilter(true);
  //     set_loading(false);
  //     set_user_info(info);

  //     window.user = info;

  //     setFreshchatProperties();
  //     // set_company_info(company_info);
  //     // let data = getChallengeData();
  //     // if (company_info.hasOwnProperty('projects')) {
  //       //set project name in local storage
  //       // const project = company_info.projects.find((elem) => elem.id == prID);
  //       // if (project && data) {
  //       //   const projectName = project.name;
  //       //   data['projectName'] = projectName;
  //       // }
  //       // setActiveProject(company_info.projects[0]['id']);
  //       // setActiveProjectText(company_info.projects[0]['name']);
  //       // if (company_info.projects[0]['description'] !== null)
  //       //   setActiveProjectDescription(company_info.projects[0]['description']);
  //     // }
      
  //     // if (company_info.hasOwnProperty('modules')) {
  //     //   const module = company_info.modules.find((elem) => elem.id == modID);
  //     //   if (module && data) {
  //     //     const moduleName = module.name;
  //     //     data['moduleName'] = moduleName;
  //     //   }
  //     // }
  //     // if (data) {
  //     //   saveChallengeData(data);
  //     // }
  //     let fetch_drive_users = MOClient.get_drive_users({
  //       token,
  //       company_id: info.permissions.company_id,
  //     });

  //     fetch_drive_users.then((outcome) => {
  //       if (outcome.status === 'success') {
  //         setDriveUsers(outcome.data);
  //       }
  //     });
  //   });
  // }

  function projectChangeHandler(projectId) {
    setActiveProject(projectId);
  }

  // effect that checks if the user_info is loaded and loads it
  useEffect(() => {
    let token = window.localStorage.getItem(LS_TOKEN_KEY);
    if (token != null && logged_in === true) {
      // dispatch_user_info(token);
    }
  }, [logged_in]);

  if (loading === true) {
    return (
      <LoadingContainer />
    );
  }

  let permissions = [];

  if (
    user_info.permissions &&
    user_info.permissions.hasOwnProperty('features')
  ) {
    for (let permission of user_info.permissions.features) {
      permissions.push(permission.key);
    }
  }

  let topLevelProps = {
    // {...routeProps}
    logoutHandler: logout_handler,
    userInfo: {
      permission_features: permissions,
      ...user_info,
    },
    loading: loading,
    jwtToken: jwt_token,
    companyInfo: company_info,
    activeProject: activeProject,
    activeProjectText: activeProjectText,
    activeProjectDescription: activeProjectDescription,
    projectChangeHandler: projectChangeHandler,
    onProjectSelector: () => {
      setShowProjectSelector(true);
    },
    showIncentiveSection: showIncentiveSection,
    showRewardSection: showRewardSection,
    showLearnerJourney: showLearnerJourney,
    showTeamReport: showTeamReport,
    showChallengeType: showChallengeType,
    showLevelFilters: showLevelFilters,
    showProjectFilter: showProjectFilter,
    // MOClient: MOClient,
    categoryFeature: category_feature,
    classificationFeature: classification_feature,
    timestampFeature: timestamp_feature,
    driveUsers: driveUsers,
  }

  function toggleMenu() {
    return
    // if (menuOpen === true) {
    //   setMenuOpen(false);
    // } else setMenuOpen(true);
  }

  return (
    <div>
      <Route
        exact
        path="/drive/courses-and-training/*"
        render={(routeProps) => (
          <CourseTab {...routeProps} {...topLevelProps} />
        )}
      />

      <Route
        exact
        path="/drive/audience/*"
        render={(routeProps) => (
          <AudienceTab
            {...routeProps}
            {...topLevelProps}
            toggleMenu={toggleMenu}
          />
        )}
      />

      <Route
        exact
        path="/drive/training/*"
        render={(routeProps) => (
          <CourseTab {...routeProps} {...topLevelProps} />
        )}
      />

    </div>
  );
}

export default DriveScreen;