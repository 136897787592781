import { makeStyles } from "@mui/styles";
// import palette from "../../theme/palette";
// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loginContainer: {
        // backgroundColor: palette.bgThemeColor, "url(/static/media/mastero-dashboard-bg-login.4c1be565.png)"
        backgroundImage: `url("/images/login-screen/mastero-dashboard-bg-login.png")`,
        backgroundSize: "cover",
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: '100vh',
        width: '100vw',
        overflow: 'hidden',
    },
    loginBox: {
        height: '70%',
        width: '88%',
        flexWrap: 'wrap',
        borderRadius: '5px',
        boxShadow: '0 22px 16px 0 rgb(0 0 0 / 23%)',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            width: '60%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },
    side1: {
        display: "block",
        backgroundColor: "#4f0060",
        flexBasis: "43%",
        height: '100%',
        // flex: '1 1 43%',
        // backgroundImage: palette.linearGradientPrimary,
        // display: 'grid',
        // gridAutoRows: '53% 47%',
         
        // padding: '2rem 1.8rem'
    },
    imageContainer: {
        display: 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        alignItems: 'center'
        // "& :nth-child(1)": {
        //     width: '53%',
        //     height: '100%',
        //     objectFit: 'contain'
        // },
    },
    loginContentBox: {
        color: '#ffffff'
    },
    welcomeText: {
        fontWeight: 'bold',
        fontSize: '1.5rem',
        fontFamily: 'Montserrat',
    },
    divider: {
        width: '15%',
        height: '.4rem',
        backgroundColor: '#f4511e'
    },
    logoText: {
        fontFamily: 'Montserrat',
        fontSize: '3.1rem',
        marginTop: '0px',
        fontWeight: 'bold',
        marginBottom: '12px',
    },
    mainTextDesc: {
        fontSize: '0.8rem',
    },
    side2: {
        flex: '1 1 45%',
        backgroundColor: '#fff',
        height: '100%',
        padding: '2.5rem 3.5rem',
    },
    signInMainText: {
        // fontFamily: 'Montserrat',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        // color: COLORS.textLight,
        marginBottom: '1rem'
    },
    signInDetails: {
        fontSize: '.65rem',
        // color: COLORS.textLight,
        lineHeight: '1.3rem'
    },
    labelClass: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
        fontSize: '0.9rem',
        margin: '1rem 0',
        display: 'block',
    },
    loadingBtn:{
        borderRadius: "4px",
        backgroundColor: "#f4511e",
        width: "100%",
        display: "flex",
        fontSize: "0.9rem",
        color: "#fff",
        fontWeight: "700",
        fontFamily: "Montserrat",
        border: "none",
        cursor: "pointer",
        padding: "0.8rem",
        margin : "3rem 0 2rem 0",
        cursor : "pointer",
        '& > span > span':{
            height:'1.3rem !important',
            width:'1.3rem !important'
        }
    },
    link : {
        textDecoration : "none"
    },
    muiBtn: {
        width: '100%',
        fontSize: '0.9rem !important',
        fontWeight: 'bold !important',
        fontFamily: 'Montserrat !important',
        margin: '1rem 0 !important'
    },
    checkBox: {
        // color: COLORS.primaryOrange
    },
    checkBoxContainer: {
        margin: '2rem 0',
        display: 'flex',
        // gap: '1rem',
        alignItems: 'center',
        '& .MuiButtonBase-root' :{
            '& .MuiSvgIcon-root':{
                fontSize: '1.2rem'
            }
        }
    },
    rememberMeText: {
        fontSize: "1rem",
        fontFamily: '"Open Sans","-apple-system","BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue","Arial","sans-serif","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        fontWeight: "400",
        lineHeight: "1.5",
        marginLeft : "0.5rem",
        // fontSize: '0.75rem',
        // color: COLORS.textLight,
        // padding: '1rem'
    },
    iconBox: {
        width: '22%',
    },
    iconTextBox: {
        color: '#ffffff',
        display: 'grid',
        alignContent: 'center',
        justifyItems: 'center'
    },
    loginScreenMasteroLogo:{
        display: "block",
        margin: 'auto',
        width: '53%',
        // minWidth: '210px',
        marginTop: '2.2rem',
        
    },
    loginScreenDashboardTitle: {
        fontSize : "1.4rem",
        fontFamily: "Montserrat",
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: '1.22rem',
        letterSpacing: '8.3px',
        color: '#fff',
        width: '100%',
        textAlign: 'center',
        marginBottom: '0',
        marginTop: '1.2rem'
    },
    loginScreenDriveLoginIntro : {
        display: "block",
        width: "60%",
        margin: "auto"
    },
    logoMaintext: {
        fontWeight: 'bold',
        fontFamily: 'Montserrat',
    },
    logoSubText: {
        fontSize: '0.45rem',
        fontFamily: 'Montserrat',
    },
    mobileLoginContainer: {
        backgroundImage: 'linear-gradient(rgb(79, 0, 96), rgb(8, 0, 55))',
        height: '100vh',
        width: '100vw',
    },
    mobileLoginBox: {
        backgroundImage: 'url(/images/login-screen/login_illustration_bg.svg)',
        height: '100vh',
        width: '100vw',
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'contain',
        display: 'grid',
        gridTemplateRows: '38% 62%',
        // alignItems: 'center',
    },
    mobileLogoContainer: {
        width: '90%',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            width: '100%'
        }
    },
    mobileLoginForm: {
        width: '90%',
        margin: 'auto',
        color: '#FFF',
        height: '100%',
        display: 'grid',
        gridAutoRows: '45% 55%'
    },
    loginFormBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    mobileLoginInputField: {
        fontFamily: "Montserrat",
        // height: "52px",
        borderRadius: "1px",
        fontSize: "14px",
        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.4)",
        background: '#4F0060',
        // color: 'rgba(255, 255, 255, 0.6)',
        color: "white",
        fontWeight: "500",
        '& .MuiOutlinedInput-input': {
            color: "#FFF !important",
            boxShadow: `0 0 0 100px ${'#4F0060'} inset !important`,
            borderRadius: '0 !important'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: "transparent !important",
        }
    },
    textBoxMobile: {
        padding: '1.2rem 0',
        display: 'grid',
        gridTemplateRows: '25% 25% 25% 25%',
        alignItems: 'center',
        textAlign: 'center'
    },
    text: {
        fontSize: '0.7rem',
        cursor: 'pointer'
    },
    // checkboxContainer: {
        
    // },
    rememberText: {
        fontSize: '0.8rem'
    },
    emailInput: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ea4e1d',
            padding: '1.2rem 0',
            marginBottom : "1rem"
        }
    },
    textBoxDesktop: {
        fontSize: '0.8rem',
        padding: '2.5rem 1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#f4511e',
    },
    desktopPwdBox: {
        margin: '0.8rem 0'
    }
}))

export default useStyles

// FOR LOADING BTN
// border-radius: 10px;
// box-shadow: 0 2px 2px 0 rgb(0 0 0 / 33%);
// background-color: #f4511e;
// width: 100%;
// display: block;
// font-size: .9rem;
// color: #fff;
// font-weight: 700;
// font-family: Montserrat;
// border: none;
// cursor: pointer;