import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

//styles-common
import commonStyles from './index.styles';

const NumberedList = () => {
  const classes = useStyles();
  const styles = commonStyles();
  return (
    <div className={styles.unavailable}>
      CURRENTLY UNAVAILABLE!
    </div>
  )
}

export default NumberedList;


const useStyles = makeStyles(() => ({
  card: {
    // aspectRatio : 9/16, //TOGGLE ASPECT RATIO

    aspectRatio: 3 / 4,
    backgroundColor: '#fff',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
    '& > img': {
      height: '100%',
      width: '100%'
    }
  }

}));
