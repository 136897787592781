import React from 'react'

//mui
import { FormControl, FormControlLabel, RadioGroup, Typography, Radio } from '@mui/material'
import { makeStyles } from "@mui/styles";

//components
import ViaCsvFile from '../via-csv-file';
import ViaDemographic from '../via-demographic';

import { LEARNER_OPTIONS } from '../../index';

const Step3 = ({
    learnerOption,
    setLearnerOption,
    fileUpload,
    setFileUpload,
    setShowDwModal,
    selectedUsers = [],
    setSelectedUsers,
    userData,
    setUserData,
    selectedMicroskills = [],
    setHeadings,
    setLoadingUserData,
    withDemograhicwiseOption = false,
    validationData
}) => {
    const classes = useStyles()

    const handleChange = (e, value) => {
        setSelectedUsers([]);
        setFileUpload(null);
        setLearnerOption(value);
    }

    const icons = {
        error: "fa-solid fa-circle-exclamation",
        inProgress: "fa-regular fa-circle-dot",
        valid: "fa-solid fa-circle-check"
    }

    const validations = [
        // {
        //     name: 'valid-users',
        //     title: validationData?.validUsersCount > 1 ? 'User IDs are valid' : 'User ID is valid',
        //     count: validationData?.validUsersCount,
        //     url: validationData?.validUsersUrl,
        //     icon: icons.valid
        // },
        {
            name: 'min-character-error',
            title: `Invalid user IDs`,
            count: validationData?.invalidShortUsersCount,
            url: validationData?.invalidShortUsersUrl,
            icon: icons.error
        },
        {
            name: 'relaunch',
            title: 'Re-Launch',
            count: validationData?.relaunchUsersCount,
            url: validationData?.relaunchUsersUrl,
            icon: icons.valid
        },
        {
            name: 'new-launch',
            title: 'New Launch',
            count: validationData?.newLaunchUsersCount,
            url: validationData?.newLaunchUsersUrl,
            icon: icons.valid
        }
    ]

    const handleDownloadValidationFile = (ele) => {
        if (ele.url) {
            const link = document.createElement('a');
            link.href = ele.url;
            link.download = 'Validation file'; // Set the desired filename for the downloaded file
            link.click();
        }
    }


    return (
        <div style={{
            marginBottom: '5rem'
        }}>
            <h2 style={{
                fontSize: '1.2rem',
                fontFamily: "Montserrat",
                color: "#707070",
                padding: '1rem 0rem',
                paddingTop: '1.5rem',
                borderTop: "1px solid rgb(112, 112, 112, 0.3)",
                marginTop: '1.5rem'
            }}>Step 3: Learners</h2>
            <FormControl>
                <RadioGroup onChange={handleChange} row sx={{ gap: '1rem' }}>
                    <FormControlLabel sx={{ margin: '0px !important' }} value={LEARNER_OPTIONS.CSV} control={<Radio checked={learnerOption === LEARNER_OPTIONS.CSV} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>Via CSV File</Typography>} />
                    {withDemograhicwiseOption ? <FormControlLabel sx={{ margin: '0px !important' }} value={LEARNER_OPTIONS.DEMOGRAPHIC} control={<Radio checked={learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>Demographic Wise</Typography>} /> : null}
                </RadioGroup>
            </FormControl>
            {learnerOption === LEARNER_OPTIONS.CSV && <ViaCsvFile
                fileUpload={fileUpload}
                setFileUpload={setFileUpload}
            />}
            {withDemograhicwiseOption && learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC && <ViaDemographic
                setShowDwModal={setShowDwModal}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                userData={userData}
                setUserData={setUserData}
                selectedMicroskills={selectedMicroskills}
                setHeadings={setHeadings}
                setLoadingUserData={setLoadingUserData}
            />}

            <div className={classes.validationWrapper}>
                {
                    validations.map((ele, i) => {
                        if (ele.count) return <div key={i} onClick={() => handleDownloadValidationFile(ele)}><i className={ele.icon}></i><span>{ele.count}</span><p>{ele.title}</p> </div>
                    })
                }
            </div>


        </div>
    )
}

export default Step3;


const useStyles = makeStyles(() => ({
   
    validationWrapper: {
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.3rem',
        '& > div': {
            display: 'flex',
            fontSize: '0.8rem',
            gap: '0.5rem',
            '& > p': {
                fontFamily: 'Montserrat',
                fontWeight: 500,
                color: '#484848',
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                },
            },

            '& > span': {
                fontWeight: 700,
                fontFamily: 'Montserrat',
                color: '#000'
            },
            '& > .fa-circle-exclamation': {
                color: '#EB3917'
            },
            '& > .fa-circle-check': {
                color: 'green'
            },
            '& > .fa-circle-dot': {
                color: '#f3b401'
            },
        }
    }
}))

