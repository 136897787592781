import React, { useState, useEffect } from "react";

//components
import Checkbox from "./checkbox";

//styles
import useStyles from "./index.styles";


let defaultMessage = 'No Data Found'

const SearchBox = ({
  withSearch = true,
  withSelectAllOption = true,
  customSelectAll = false,
  _selectAll,
  listData = [],
  selected = [],
  setSelected,
  emptyListMessage = defaultMessage,
  handleSelect,
  withCustomFunction = true,
  withLocalSearch = true,
  handleSearch,
  autoClose = false,
  setOpen,
  name = "" }) => {
  const styles = useStyles();
  // const [selected, setSelected] = useState([]);
  const [list, setList] = useState(listData);
  const [message, setMessage] = useState(emptyListMessage);



  const handleLocalSearch = (e) => {
    let value = e.target.value;
    console.log(value);
    if (!withLocalSearch) {
      handleSearch(e);
      return
    }
    if (value === '') {
      setList(listData);
      setMessage(defaultMessage)
      return
    }
    let result = listData.filter(li => {
      if (li.name.toLowerCase().includes(value.toLowerCase())) return li
      if (li.name.toLowerCase() === value.toLowerCase()) return li
    });
    setList(result);
    if (!result.length) {
      setMessage('0 results')
    } else {
      setMessage(defaultMessage)
    }
  }

  const handleSelectAll = e => {
    if (customSelectAll) {
      _selectAll(list);
      return
    }
    let isSelectAll = list.length === selected.length;
    if (isSelectAll) {
      setSelected([]);
    } else {
      let all = list.map(ele => ele.id)
      setSelected(all);
    }

  };

  const handleClick = (item) => {
    // console.log('handleClick item = ', item);
    if (withCustomFunction) {
      handleSelect(item)
      if (autoClose) setOpen(false);
      return;
    }
    if (selected.includes(item.id)) {
      let filtered = selected.filter(ele => ele !== item.id);
      setSelected(filtered);
    } else {
      setSelected([...selected, item.id])
    }
    if (autoClose) setOpen(false);
  };

  const getIcon = (ele) => {
    if (ele.type === "FOLDER") return (<i className="fa-regular fa-folder"></i>)
    return (<i className="fa-regular fa-file"></i>)
  }


  return (
    <div className={styles.searchBox} style={{ minHeight: withSearch ? '5rem' : '2.6rem' }}>
      {
        withSearch ? <div className={styles.searchInputBox}>
          <input placeholder="Search" type="text" onChange={handleLocalSearch} autoFocus/>
          <i className="fa-solid fa-magnifying-glass"></i>
        </div> : null
      }
      <div className={styles.searchList} style={{ height: withSearch ? '88%' : '100%' }}>

        {
          list.length ?
            (<>
              {
                withSelectAllOption ?
                  <div className={styles.selectAllLi} onClick={handleSelectAll}>
                    <Checkbox
                      className={styles.checkBox}
                      type="checkbox"
                      name="selectAll"
                      id="selectAll"
                      isChecked={listData.length && list.length === selected.length}
                    />
                    <span>Select All</span>
                  </div> : null
              }
              {list.map((ele, index) => (
                <div key={index} className={styles.listItem} onClick={() => handleClick(ele)} title={ele.name} style={{
                  backgroundColor: selected.includes(ele.id) ? '#f9f9f9' : '#fff'
                }}>
                  <Checkbox
                    id={ele.id}
                    type="checkbox"
                    name={ele.name}
                    // handleClick={handleClick}
                    isChecked={selected.includes(ele.id)}
                  />
                  <span style={{ marginRight: name === "documents" ? '1.5rem' : '' }}>{ele.name}</span>
                  {name === "documents" ? <span className={styles.listItemIcon} > {getIcon(ele)}</span> : null}
                </div>
              ))}
            </>)
            : <div style={{
              textAlign: 'center',
              color: 'rgb(0,0,0,0.7)',
              padding: '0.5rem 0'
            }}>
              {message}
            </div>
        }
      </div>
    </div>
  );
}

export default SearchBox 