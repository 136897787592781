import { makeStyles } from "@mui/styles";

import constants from "../../constants";

const useStyles = makeStyles((theme) => ({
    centerFlex: {
      
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    container: {
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        height : '100%',
        backgroundColor : '#fff',
        '& > div:first-child' :{
            minHeight: `calc((100vh - ${constants.HEADER_HEIGHT})/2.5)`,
            '& > div:first-child':{
                '& > form':{
                    '& > button':{
                        marginTop: '2rem'
                    }
                }
            }
        },
        // '& > div:nth-child(2)':{
        //     border : '1px solid red',
        //     minHeight: `60% !important`,
        //     // minHeight: `calc((100vh - ${constants.HEADER_HEIGHT})/1.68)`
        // }

    },
    row2:{
        // border : '1px solid red',
        height: '50%'
    }
}))

export default useStyles