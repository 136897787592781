import React, { useEffect, useRef, useState } from 'react'

// mui
import useStyles from './index.styles';
import CircularProgress from '@mui/material/CircularProgress';

// utils
import isEmpty from '../../../../utils/isEmpty';
import { Button } from '@mui/material';

const LaunchHistoryTable = (props) => {
    const {
        selectedNav,
        tableData,
        tableHeadings,
        actionBtnVisible = false,
        isLoadingData,
        handleJourneyEditClick,
        handleJourneyDltClick,
        handleJourneyCopyClick,
        handleJourneyAddUsersClick,
        rowsCount,
        handleRowsCountChange
    } = props

    const styles = useStyles();

    const [tableDataState, setTableDataState] = useState([]);
    const isAscendingSortRef = useRef(true);

    useEffect(() => {
        setTableDataState(tableData);
    }, [tableData])

    const handleSortingClick = (findingKey) => {
        let sortedData = tableDataState.sort((a, b) => {
            if (typeof a[findingKey] === 'string') {
                if (isAscendingSortRef.current) {
                    return a[findingKey].localeCompare(b[findingKey]);
                } else {
                    return b[findingKey].localeCompare(a[findingKey]);
                }
            } else {
                if (isAscendingSortRef.current) {
                    return a[findingKey] - b[findingKey];
                } else {
                    return b[findingKey] - a[findingKey];
                }
            }
        });
        isAscendingSortRef.current = !isAscendingSortRef.current;
        setTableDataState([...sortedData]);
    }

    /**
     * 1. if existing learner journey is edited it will necessary to relaunch to the users
     * 2. we won't allow to edit users list in the existing learner journey
     * 3. if you need to edit the users list, you need to create a new learner journey or you can copy the exsting learning journey that will be new journey with blank users list
     * 4.
     */
    return (
        <div className={`${styles.tableRoot} scrollbar`}>
            {
                isLoadingData && <div className={`${styles.loader} ${styles.centerFlex}`}><CircularProgress color="inherit" /></div>
            }
            {
                !isLoadingData && (
                    !isEmpty(tableDataState) ? (
                        <React.Fragment>
                            <table className={`${styles.table}`}>
                                <thead className={styles.tableHead} >
                                    <tr className={styles.tableRow} >
                                        {/* <td className={`${styles.tableCell} ${styles.tableHeadCell}`} style={{ textAlign: 'left' }}>S.No</td> */}
                                        {tableHeadings.map(({ heading, key }, index) => {
                                            return (
                                                <td className={`${styles.tableCell} ${styles.tableHeadCell}`} key={index} cellSpacing='1rem'>{heading}
                                                    <Button variant='text' size='small' sx={{ color: 'rgb(0, 0, 0, 0.8)', minWidth: '1rem' }} onClick={() => handleSortingClick(key)}>
                                                        <i className="fa-solid fa-sort"></i>
                                                    </Button>
                                                </td>
                                            )
                                        })}
                                        {
                                            actionBtnVisible && (
                                                <td className={`${styles.tableCell} ${styles.tableHeadCell}`}>Actions</td>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody className={styles.tableBody}>
                                    {tableDataState.map((data, index) => {
                                        return (
                                            <tr key={index} className={styles.tableRow}>
                                                {/* <td className={styles.tableCell} style={{ textAlign: 'left' }}>{index + 1}</td> */}
                                                {
                                                    tableHeadings.map(({ key }, index) => {
                                                        return (
                                                            <td
                                                                key={index}
                                                                className={styles.tableCell}
                                                                style={{
                                                                    textAlign: index === 0 ? 'left' : 'center'
                                                                }}
                                                            >{isEmpty(data[key]) ? "-" : data[key]}</td>
                                                        )
                                                    })
                                                }
                                                {
                                                    actionBtnVisible && (
                                                        <>
                                                            <td className={styles.tableCell}>
                                                                <Button
                                                                    variant="text"
                                                                    size="small"
                                                                    sx={{
                                                                        color: 'rgb(0, 0, 0, 0.9)',
                                                                        minWidth: '0rem'

                                                                    }}
                                                                    onClick={() => handleJourneyEditClick(data)}
                                                                >
                                                                    <i className='fa fa-pen' style={{ cursor: 'pointer' }}></i>
                                                                </Button>
                                                                <Button
                                                                    variant="text"
                                                                    size="small"
                                                                    sx={{
                                                                        color: 'rgb(0, 0, 0, 0.9)',
                                                                        minWidth: '0rem'

                                                                    }}
                                                                    onClick={() => handleJourneyCopyClick(data)}
                                                                >
                                                                    <i className="fa fa-clipboard" style={{ cursor: 'pointer' }}></i>
                                                                </Button>

                                                                <Button variant="text"
                                                                    size="small"
                                                                    sx={{
                                                                        color: 'rgb(0, 0, 0, 0.9)',
                                                                        minWidth: '0rem',
                                                                        marginLeft: '0.5rem',
                                                                    }}
                                                                    onClick={() => handleJourneyAddUsersClick(data)}>
                                                                    <i className="fa-solid fa-user-plus"></i>
                                                                </Button>
                                                                <Button
                                                                    variant="text"
                                                                    size="small"
                                                                    sx={{
                                                                        color: 'rgb(0, 0, 0, 0.9)',
                                                                        minWidth: '0rem',
                                                                        marginLeft: '0.5rem',
                                                                    }}
                                                                    onClick={() => handleJourneyDltClick(data)}
                                                                >
                                                                    <i className='fa fa-trash' style={{ cursor: 'pointer' }}></i>
                                                                </Button>
                                                            </td>
                                                        </>
                                                    )
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            {/* <div className={styles.tableFooter}>
                                <div>Select Rows Count</div>
                                <div>
                                    <input
                                        className={styles.inputField}
                                        placeholder='Rows Count per page'
                                        value={rowsCount}
                                        onChange={handleRowsCountChange}
                                    />
                                </div>
                            </div> */}
                        </React.Fragment>
                    ) : (
                        <div className={styles.noData}>No Records found</div>
                    )
                )

            }
            {/* {JSON.stringify(tableData)}, {JSON.stringify(tableHeadings)} */}
        </div>
    )
}

export default LaunchHistoryTable