import React from 'react'

//components
import CopyMicroskill from '../../components/copy-microskill'

const CopyMicroskillPage = () => {
  return (
    <CopyMicroskill/>
  )
}

export default CopyMicroskillPage
