import React, { useState, useEffect } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import { Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';

//components
import Label from '../label';
// import CustomInput from '../question-answer-component/components/custom-input';
import CustomInput from '../../../custom-styled/custom-input';
import RadioGroup from '../radio-group';

//constants
import constants from '../../../../constants';

//utils
import useQuery from '../../../../utils/useQuery';

//styles
import useStyles from './index.styles';
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';
const useCheckboxStyles = makeStyles({
  root: {
    color: '#F4511E',
    '&.Mui-checked': {
      color: '#F4511E',
    },
  },
});
const Settings = ({
  handleSaveChallenge,
  isSavingChallenge = false,
  handleGoback,
  settings,
  setSettings,
  list,
  selectedGame,
  isEditSetting,
  setIsEditSetting,
}) => {
  const classes = useStyles();
  const query = useQuery();
  const checkboxClasses = useCheckboxStyles();
  let isEdit = query.get('edit');
  const [inputError, setInputError] = useState({ exceedText: '', isExceeded: false, })
  const handleChangeQuestionSequence = (e) => {
    setSettings({ ...settings, questionSequence: e.target.value })
  }
  const handleChangeInput = (e) => {
    if (parseInt(e.target.value) < 1) {
      setInputError({ exceedText: 'Error', isExceeded: true })
    } else {
      setSettings({ ...settings, activateAnswerAttempt: e.target.value })
      setInputError({ exceedText: '', isExceeded: false })
    }
  }


  const getMaxLives = () => {
    let questionsCount = list.length;
    if (selectedGame.type === 'MTRR') questionsCount = list.length * 5;
    if (selectedGame.type === 'MA') questionsCount = list.length * 8;
    const { maxLife, maxQuestion } = commonUtil.getQuestionAndLifeForGba({ designType: selectedGame.designType });
    const life = commonUtil.getMaxLifeForGba({
      designType: selectedGame.designType,
      maxQuestion,
      questionsInGba: questionsCount,
      maxLife
    })
    return life || 0;
  }

  const getTotalTime = () => {
    if (selectedGame.type === 'MCQ') return `${list.length * 18} Seconds`;
    if (selectedGame.type === 'MTRR') {
      let value = 180;
      if (list.length < 3) value = 60 * list.length;
      return `${value} Seconds`;
    }
    //No time for MA and TF games
    return `N/A`;
  }

  const getDefaultValue = (element) => {
    if (element.titleText === 'Maximum Lives:') return getMaxLives();
    if (element.titleText === 'Total Time:') return getTotalTime();
    return element.defaultValue
  }

  const handleCheckbox = () => {
    setIsEditSetting(prev => {
      if (prev) {
        setSettings({ ...settings, activateAnswerAttempt: null })
      }
      return !prev
    })
  }
  useEffect(() => {
    if (!isEmpty(settings.activateAnswerAttempt)) {
      setIsEditSetting(true)
    }
  }, [settings.activateAnswerAttempt])
  return (
    <>

      <div className={classes.mainContainer}>
        {
          constants.GBA_SETTINGS.map((element, index) => (
            <div className={classes.options} key={index}>
              <Label labelText={element.titleText} fs={'0.8rem'} />
              {element.key === 6 && <Checkbox classes={{ root: checkboxClasses.root }} checked={isEditSetting} onClick={handleCheckbox} />}
              <div className={classes.inputBox}>
                {/* <CustomInput isDisabled={true} defaultValue={element.titleText === 'Maximum Lives:' ? getMaxLives() : element.defaultValue} /> : */}
                {
                  (element.type === 'input') ?
                    <>
                      {
                        element.key === 6 ?
                          (isEditSetting) && <CustomInput type={'number'} exceedText={inputError.exceedText} isExceeded={inputError.isExceeded} value={!settings.activateAnswerAttempt ? '' : settings.activateAnswerAttempt} handleChange={handleChangeInput} />
                          :
                          <CustomInput isDisabled={!element.isEdit ? true : false} value={getDefaultValue(element)} />
                      }
                    </>
                    :
                    <>
                      <RadioGroup defaultChecked={element.options[0]} options={element.options} />
                    </>
                }
              </div>
            </div>
          ))
        }
        <div className={classes.options}>
          <Label labelText={"Question Sequence:"} fs={'0.8rem'} />
          <div className={classes.inputBox}>
            <RadioGroup
              options={['Random', 'Sequence']}
              selected={settings.questionSequence}
              handleChange={handleChangeQuestionSequence}
            />
          </div>
        </div>
        {/* <div className={classes.row1}>
         
        </div> */}

        {/* <div className={classes.row2}>
        
      </div> */}
      </div>
      <div className={classes.btnBox}>
        {
          isSavingChallenge ?
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Saving...
            </LoadingButton> :
            <Button title={isEdit ? "Update" : "Save"} onClick={handleSaveChallenge} className={`${classes.btn} ${classes.saveBtn}`}>
              {isEdit ? "UPDATE" : "SAVE"}
            </Button>

        }


        <Button title='Edit' onClick={handleGoback} className={`${classes.btn} ${classes.clearBtn}`}>
          EDIT
        </Button>
      </div>
    </>
  )
}

export default Settings;
