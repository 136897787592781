import React, { useState, useEffect } from 'react'

//components
import Context from '../context-input';
import SkillTags from '../skill-tags';
import QuestionResponseMTRR from './mtrr-question-response';

let answers = [
    {
        id: 0,
        contextId: null,
        question: "",
        answers: [
            {
                id: 0,
                option: "",
                value: true
            }
        ]
    },
    {
        id: 0,
        contextId: null,
        question: "",
        answers: [
            {
                id: 0,
                option: "",
                value: true
            }
        ]
    },
    {
        id: 0,
        contextId: null,
        question: "",
        answers: [
            {
                id: 0,
                option: "",
                value: true
            }
        ]
    },
    {
        id: 0,
        contextId: null,
        question: "",
        answers: [
            {
                id: 0,
                option: "",
                value: true
            }
        ]
    },
    {
        id: 0,
        contextId: null,
        question: "",
        answers: [
            {
                id: 0,
                option: "",
                value: true
            }
        ]
    }
]

const MatchTheRightResponse = ({
    listitem,
    isDisableAll,
    designType,
    itemId,
    tags,
    selectedTag,
    handleTagClick,
    list,
    context,
    contextValidation,
    handleContextChange,
    questionCharlimit = 40,
    responseCharlimit = 20 }) => {
    // const [selectedTag, setSelectedTag] = useState(null);
    const [mtrrQuestionResponse, setMtrrQuestionResponse] = useState(listitem.questionArr)
    let maxQuestions = 5
    if (designType === 'MTRR_LearnoCasino') maxQuestions = 4
    const question = Array(maxQuestions).fill(null).map((item, index) => ({ exceeded: false, limitText: `${questionCharlimit} Characters` }));
    const response = Array(maxQuestions).fill(null).map((item, index) => ({ exceeded: false, limitText: `${responseCharlimit} Characters` }));

    const [questionValidations, setQuestionValidations] = React.useState(question);
    const [responsesValidations, setResponsesValidations] = React.useState(response);

    // const handleTagClick = (e) => {
    //     const id = parseInt(e.target.id);
    //     setSelectedTag(id)
    // }

    useEffect(() => {
        let tempQuestionValidation = [...questionValidations];
        mtrrQuestionResponse.forEach((element, id) => {
            let value = element.question;
            if (value.length > questionCharlimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
                tempQuestionValidation[id] = { exceeded: true, limitText: `${value.length - questionCharlimit} Characters Exceeded` };
                // setResponsesValidations(tempQuestionValidation);
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
                if (listitem.questionArr[id].isQuestionEmpty) {
                    tempQuestionValidation[id] = { exceeded: false, limitText: `*Required` };
                } else {
                    tempQuestionValidation[id] = { exceeded: false, limitText: `${questionCharlimit - value.length} Characters` };
                }
                // setResponsesValidations(tempQuestionValidation);
            }
        });
        console.log('McqResponse temp = ', tempQuestionValidation);
        setQuestionValidations(tempQuestionValidation);

        let tempResponsesValidation = [...responsesValidations];
        mtrrQuestionResponse.forEach((element, id) => {
            let value = element.answers[0].option;
            if (value.length > responseCharlimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
                tempResponsesValidation[id] = { exceeded: true, limitText: `${value.length - responseCharlimit} Characters Exceeded` };
                // setResponsesValidations(tempResponsesValidation);
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
                if (listitem.questionArr[id].emptyAnswers[0].isEmpty) {
                    tempResponsesValidation[id] = { exceeded: false, limitText: `*Required` };
                } else {
                    tempResponsesValidation[id] = { exceeded: false, limitText: `${responseCharlimit - value.length} Characters` };
                }
                // setResponsesValidations(tempResponsesValidation);
            }
        });
        console.log('McqResponse temp = ', tempResponsesValidation);
        setResponsesValidations(tempResponsesValidation);
    }, [])

    const handleQuestionchangeMTRR = (e) => {
        let id = parseInt(e.target.id)
        let value = e.target.value
        console.log('Question = ', id, " ", value);

        let temp = [...mtrrQuestionResponse];
        temp[id].question = value.toString();
        temp[id].isQuestionEmpty = false;

        setMtrrQuestionResponse(temp);


        let tempQuesValidations = [...questionValidations];
        if (value.length > questionCharlimit) {
            //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
            tempQuesValidations[id] = { exceeded: true, limitText: `${value.length - questionCharlimit} Characters Exceeded` };
            setQuestionValidations(tempQuesValidations);
        } else {
            //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
            tempQuesValidations[id] = { exceeded: false, limitText: `${questionCharlimit - value.length} Characters` };
            setQuestionValidations(tempQuesValidations);
        }
    }

    const handleResponsechangeMTRR = (e) => {
        let id = parseInt(e.target.id)
        let value = e.target.value

        let temp = [...mtrrQuestionResponse];
        temp[id].answers[0].option = value.toString();
        temp[id].emptyAnswers[0].isEmpty = false;
        setMtrrQuestionResponse(temp);

        let tempResponseValidations = [...responsesValidations];
        if (value.length > responseCharlimit) {
            //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
            tempResponseValidations[id] = { exceeded: true, limitText: `${value.length - responseCharlimit} Characters Exceeded` };
            setResponsesValidations(tempResponseValidations);
        } else {
            //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
            tempResponseValidations[id] = { exceeded: false, limitText: `${responseCharlimit - value.length} Characters` };
            setResponsesValidations(tempResponseValidations);
        }
    }

    return (
        <>
            <Context
                defaultValue={context}
                isDisabled={isDisableAll}
                designType={designType}
                handleContextChange={handleContextChange}
                limitText={contextValidation.limitText}
                isExceeded={contextValidation.exceeded}
                isEmpty={listitem.isContextEmpty}
            />
            <SkillTags tags={tags} selectedTag={selectedTag} handleTagClick={handleTagClick} />
            <QuestionResponseMTRR
                listitem={listitem}
                isDisableAll={isDisableAll}
                mtrrQuestionResponse={mtrrQuestionResponse}
                questionValidations={questionValidations}
                handleQuestionchangeMTRR={handleQuestionchangeMTRR}
                responsesValidations={responsesValidations}
                handleResponsechangeMTRR={handleResponsechangeMTRR}
                maxQuestions={maxQuestions}
            />
        </>
    )
}

export default MatchTheRightResponse;
