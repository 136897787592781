import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Chip from '@mui/material/Chip';
import { Divider, Box } from '@mui/material';
import dayjs from 'dayjs';

import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

const shortcutsItems = [
    {
        label: 'Last 7 Days',
        getValue: () => {
            const today = dayjs();
            return [today.subtract(7, 'day'), today];
        },
    },
    {
        label: 'This Week',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('week'), today];
        },
    },
    {
        label: 'Last Week',
        getValue: () => {
            const today = dayjs();
            const prevWeek = today.subtract(7, 'day');
            return [prevWeek.startOf('week'), prevWeek.endOf('week')];
        },
    },

    {
        label: 'Current Month',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('month'), today];
        },
    },
    {
        label: 'Current year',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('year'), today];
        },
    },
    {
        label: 'Last 1 year',
        getValue: () => {
            const today = dayjs();
            return [today.startOf('year').subtract(365, 'day'), today.startOf('year').subtract(1, 'day')];
        },
    },
    // {
    //   label: 'Next Month',
    //   getValue: () => {
    //     const today = dayjs();
    //     const startOfNextMonth = today.endOf('month').add(1, 'day');
    //     return [startOfNextMonth, startOfNextMonth.endOf('month')];
    //   },
    // },
    // { label: 'Reset', getValue: () => [null, null] },
];

function CustomRangeShortcuts(props) {
    const { items, onChange, isValid, handleReset, selectedShortcut, setSelectedShortcut } = props;
    // console.log('CustomRangeShortcuts props = ', props);
    if (items == null || items.length === 0) {
        return null;
    }

    const resolvedItems = items.map((item) => {
        const newValue = item.getValue({ isValid });

        return {
            label: item.label,
            onClick: () => {
                if (item.label === 'Reset') {
                    handleReset();
                    setSelectedShortcut(null);
                } else setSelectedShortcut(item.label);
                onChange(newValue);
            },
            disabled: !isValid(newValue),
        };
    });

    const getChipStyles = (label) => {
        let styles = {
            backgroundColor: selectedShortcut === label ? '#f4511e' : 'rgba(0, 0, 0, 0.08)',
            color: selectedShortcut === label ? '#fff' : 'rgba(0, 0, 0, 0.87)'
        }
        if (label === 'Reset') {
            styles = { ...styles, backgroundColor: 'rgb(211, 47, 47)', color: '#fff' }
            return styles
        }

        return styles

    }

    return (
        <Box
            sx={{
                gridRow: 1,
                gridColumn: 2,
            }}
        >
            <List
                dense
                sx={(theme) => ({
                    display: 'flex',
                    px: theme.spacing(4),
                    '& .MuiListItem-root': {
                        pt: 0,
                        pl: 0,
                        pr: theme.spacing(1),
                    },
                })}
            >
                {resolvedItems.map((item) => {
                    return (
                        <ListItem key={item.label} sx={{
                            '& > .MuiChip-root': getChipStyles(item.label)
                        }}>
                            <Chip {...item} />
                        </ListItem>
                    );
                })}
            </List>
            <Divider />
        </Box>
    );
}


const DatePicker = ({
    disabled = false,
    selectedShortcut = null,
    setSelectedShortcut = () => { },
    value,
    maxDate,
    minDate,
    handleDateChange,
    handleReset = () => { }
}) => {
    const classes = useStyles();
    return (
        <div className={classes.datePickerBox}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['SingleInputDateRangeField']}>
                    <DateRangePicker
                        disabled={disabled}
                        // localeText={{ start: 'From', end: 'to' }}
                        value={value}
                        // defaultValue={value}
                        format='DD/MM/YYYY'
                        maxDate={maxDate}
                        minDate={minDate}
                        onChange={(newValue) => {
                            if(newValue[1]){
                                let tillEod = dayjs(newValue[1]).set('h', 23).set('m',59).set('s',59).set('ms',999).set('ss',59)
                                newValue[1] = tillEod;
                            }
                          
                            handleDateChange(newValue)
                        }}
                        slots={{
                            shortcuts: (props) => (<CustomRangeShortcuts {...props} handleReset={handleReset} selectedShortcut={selectedShortcut} setSelectedShortcut={setSelectedShortcut} />),
                            field: SingleInputDateRangeField,
                            // actionBar: CustomActionBar,
                        }}
                        slotProps={{
                            shortcuts: {
                                items: shortcutsItems,
                            },
                            toolbar: {
                                hidden: true,
                            },
                            actionBar: {
                                actions: ['clear'],

                                onClick: () => {
                                    handleReset();
                                    setSelectedShortcut(null);
                                },
                                sx: {
                                    '& .MuiButtonBase-root': {
                                        color: '#f4511e',
                                        textDecoration: 'underline',
                                        fontWeight: 800,
                                        fontSize: '0.8rem',
                                        ':hover': {
                                            textDecoration: 'underline',

                                        }
                                    }
                                }
                            },
                            day: {
                                sx: {

                                    ['&[data-mui-date="true"] .Mui-selected']: {
                                        // Reset the background color of the selected date
                                        backgroundColor: 'blue',
                                    },
                                    '& .Mui-selected': {
                                        color: '#fff',
                                        fontWeight: 800,
                                        backgroundColor: '#f4511e !important',
                                        borderColor: '#f4511e !important',
                                    },
                                },
                            },
                            layout: {
                                sx: {
                                    '& .MuiPickersLayout-contentWrapper': {
                                        alignItems: 'center'
                                    },
                                    '& .MuiDateRangeCalendar-root': {
                                        '& > div:first-of-type': {
                                            display: 'none !important',
                                            zIndex: '-100000 !important'
                                        }

                                    },
                                }
                            }
                        }}
                    //   calendars={2}
                    />
                    <button>OK</button>
                </DemoContainer>
            </LocalizationProvider>
        </div>
    )
}

export default DatePicker


const useStyles = makeStyles(() => ({
    datePickerBox: {
        // border: '1px solid red',
        '& > div': {
            overflow: 'hidden !important',
            paddingTop: '0.4rem !important',
            height: '2.5rem !important',
            '& .MuiTextField-root': {
                minWidth: 0
            },
            '& .MuiInputBase-root': {
                height: '2rem !important',
                width: '16rem !important',
                color: '#707070',
                '& > input': {
                    fontFamily: 'Montserrat',
                    fontSize: '0.8rem',
                    fontWeight: 600,
                    textAlign: 'center'
                }

            },
            '& .MuiFormLabel-root': {
                fontFamily: 'Montserrat',
                fontSize: '0.9rem',
                fontWeight: 600,
                top: -9,
                color: 'rgb(128 119 119 / 60%)'
            },
            '& .Mui-focused': {
                color: '#707070',
                '& > fieldset': {
                    borderColor: '#f4511e !important'
                },
            },
        }
    },
}));