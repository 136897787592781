import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    MasteroTable_Container: {
        width: "100%",
        height: "100%",
        backgroundColor: "#fff",
        color: "black",
        padding: "1rem"
    },
    tableWrapper: {
        overflow: 'auto',
        maxHeight: '60vh',
        border: '1px solid #70707050',
        // '&::-webkit-scrollbar': {
        //     height: '6px',
        //     width: '6px',
        // },
        // '&::-webkit-scrollbar-thumb': {
        //     background: '#f5411e',
        //     borderRadius: '6px',
        // }
    },
    Title: {
        display: "flex",
        alignItems: "center",
        fontFamily: 'Montserrat',
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: "Bolder",
        characterSpacing: "0",
        lineSpacing: "155"
    },
    Help_Icon: {
        color: "#F4511E",
        fontSize: "1rem",
        marginLeft: "0.5rem"
    },
    Table: {
        // marginTop: "1.5rem",
        // border: "1.4px solid #70707050",
        width: "100%",
        borderSpacing: "0px",
        borderRadius: "2px"
    },
    Table_Heading: {
        backgroundColor: "#FBFBFB",
        color: "#484848",
        fontSize: "0.74rem",
        lineHeight: "4",
        position: 'sticky',
        top: 0,
        '& th': {
            fontFamily: 'Montserrat',
            fontWeight: 800,
            minWidth: 'fit-content',
            textWrap: 'nowrap',
            padding: '0 5rem',
        }
    },
    Table_Data: {
        boxShadow: " 0px 0px 1px #707070",
        borderRadius: "2px",
        fontFamily: 'Open Sans',
        color: "#707070",
        fontSize: "0.7rem",
        fontWeight: 400,
        lineHeight: "5",
        textAlign: "center",
        '&  td': {
            cursor: 'pointer',
            fontFamily: 'Montserrat',
            fontWeight: 500,
        }
    },
    SemiBold_Font: {
        fontWeight: "600",
    },
    iconBox: {
        '& img:hover': {
            height: '1rem',
            margin: '0 0.4rem',
            filter: 'invert(34%) sepia(70%) saturate(2053%) hue-rotate(351deg) brightness(99%) contrast(93%)'
        }
    },
    Icons: {
        margin: "0 0.5rem",
        cursor: "pointer",
        height: '0.8rem',
    },
    Tooltip: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px" /* Use half of the width (120/2 = 60), to center the tooltip */
    },
    Tooltiptext: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px" /* Use half of the width (120/2 = 60), to center the tooltip */
    },
    leftIcon: {
        cursor: "pointer",
        "&:hover": {
            background: "#d5d5d5",
            borderRadius: "2rem"
        }
    },
    paginationBox: {
        // border: '1px solid',
        color: "#F4511E",
        padding: "1rem 0rem",
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "center",
        '& > h5': {
            // border: '1px solid black',
            color: "rgb(0,0,0,0.8)",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: 600,
            marginRight: '1rem'
        }

    },
    disableScroll: {
        overflow: 'hidden !important'
    },
    loaderBox: {
        minHeight: '20vh',
        padding: '1rem',
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > div': {
            backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
    },
    emptyTable: {
        minHeight: '20vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "rgb(112, 112, 112)",
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 500,
        borderBottom: "1px solid rgb(112, 112, 112, 0.4)",
    },
}))

export default useStyles