import React from 'react'

//components
import Scorm from '../../../components/scorm';

const ScormPage = () => {
    return (
        <Scorm />
    )
}

export default ScormPage;
