import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

//components
import LinearWithValueLabel from '../../../game-based-assessment/components/lenear-progress-bar';

//utils
import commonUtil from '../../../../utils/commonUtil';


const InProgressListItem = ({cardNumber, fileSize, progress }) => {
    // console.log('InProgressListItem progress = ', progress);
    const classes = useStyles();
    return (
        <div className={classes.inProgressLi}>
            <div className={classes.imgProgress}>
                <CircularProgress color="secondary" />
            </div>
            <div className={classes.cardDetails}>
                <h4>Importing...</h4>
                <LinearWithValueLabel progress={progress}/>
                <h6>{commonUtil.formatBytes(fileSize)}</h6>
            </div>
        </div>
    )
}

export default InProgressListItem


const useStyles = makeStyles(() => ({
    inProgressLi: {
        // border: '1px solid red',
        width : '100%',
        height: '6rem',
        borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        padding: '0.7rem 0.6rem',

    },
    imgProgress: {
        marginLeft: '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid rgb(112, 112, 112, 0.2)',
        borderRadius : '5px',
        height: '4rem',
        width: '4rem',
        '& span': {
            // border: '1px solid rgb(112, 112, 112, 0.2)',
            width: '1.5rem !important',
            height: '1.5rem !important',
            '& svg': {
                filter: 'invert(42%) sepia(76%) saturate(3287%) hue-rotate(349deg) brightness(97%) contrast(98%)'
            }
        }
    },
    cardDetails : {
        // border: '1px solid rgb(112, 112, 112, 0.2)',
        height: '4rem',
        width : '70%',
        display : 'flex',
        flexDirection : 'column',
        justifyContent: 'center',
        gap: '0.4rem',
        '& h4': {
            fontSize: '0.7rem',
            fontFamily: 'Montserrat',
            color: '#484848'
        },
        '& h6': {
            fontSize: '0.6rem',
            color: 'rgb(72, 72, 72, 0.7)'
        },
    }
}));

