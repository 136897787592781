import styled from 'styled-components';

const BlockSelector = styled.button`
  width: 135.2px;
  height: 36.8px;
  transition: background-color 0.5s ease, color 0.5s ease, border-color 0.5s ease;
  background-color: ${props=> props.active ? '#f4511e' : 'transparent'};
  color: ${props=> props.active ? 'white' : '#707070'};
  border-radius: 2px;
  border: ${props=> props.active ? 'solid 1px #f4511e' : 'solid 1px #707070'};
  font-family: "Montserrat";
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.8px;
  text-align: center;
  text-transform: uppercase;
  margin-right: 30px;
  cursor: pointer;
  
  :active {
    transform: scale(0.99);
  }
`;

export default BlockSelector;