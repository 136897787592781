import { makeStyles } from "@mui/styles";
import { ThemeConsumer } from "styled-components";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centerStart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    sequencingWrapper: {
        margin: '1rem 0 0',
        position: 'relative',
    },
    sequencingCard: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        borderRadius: '0.5rem',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
    },
    draggbleItem: {
        cursor: 'grab',
    },
    optionCard: {
        gap: '0.5rem',
        cursor: 'grab',
        border: '0.5px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '0.25rem',
        padding: '0.2rem',
        // maxWidth: '600px',
        flexGrow: 1,
        flexBasis: 100
    },
    msBtn: {
        fontSize: '0.9rem',
        fontFamily: "Montserrat",
        fontWeight: 500,
        color: "#707070",
    },

    scheduledWrapper: {
        display: 'flex',
        gap: '0.5rem',
        '& > i': {
            color: '#f4511e',
            fontSize: '1rem',
            scale: '1.2',
            transition: 'scale 0.5s',
            '&:hover': {
                scale: '1.4'
            }
        },
    },
    schedule: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'end',
        paddingBottom: '0.2rem',
        gap: '1.5rem',
        '& > i': {
            color: '#f4511e',
            fontSize: '1rem',
            scale: '1.2',
            transition: 'scale 0.5s',
            '&:hover': {
                scale: '1.4',
                cursor: 'pointer'
            }
        },
    },
    addScheduleBtn: {
        color: "#F4511E",
        border: "1px solid #F4511E",
        backgroundColor: "#ffffff",
        borderRadius: "2rem",
        fontFamily: 'Montserrat',
        fontSize: '0.7rem',
        fontWeight: 700,
        width: '155px',
        height: '36px',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'uppercase',
        '&:hover': {
            color: "#F4511E",
            border: "1px solid #F4511E",
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
    scheduleListItem: {
        // border: '1px solid',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        '& > div': {
            display: 'flex',
            gap: '1rem',
            '& > h4': {
                fontSize: '0.8rem',
                fontWeight: 500,
                fontFamily: 'Montserrat',
            }
        }
    },
    scheduleLilabel: {
        // border: '1px solid',
        display: 'flex',
        gap: '1rem',
        '& > img': {
            scale: '1.1',
            transition: 'scale 0.5s',
            "&:hover": {
                scale: '1.2',
                cursor: 'pointer',
                filter: 'invert(42%) sepia(30%) saturate(7472%) hue-rotate(352deg) brightness(100%) contrast(92%)'
            }
        }

    },

}));
export default useStyles;