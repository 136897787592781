import React, { useEffect } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import AddCard from './components/add-card';
import GridCard from './components/grid-card';
import { useState } from 'react';

const GridView = ({
    documents,
    handleDocumentClick,
    createFolder = true,
    createFile = true,
    handleAddFolder,
    handleAddFile,
    handleDelete,
    _editClick }) => {
    const classes = useStyles();
    const [mouseOverOptions, setMouseOverOptions] = useState(false);
    const [showOptions, setShowOptions] = useState(null);

    useEffect(() => {
        console.log('GridView documents = ', documents);
    }, [])

    const handleDocument = (data) => {
        if (mouseOverOptions) return
        console.log('Document clicked');
        setShowOptions(null);
        handleDocumentClick(data);
    }
    const handleOptions = (index) => {
        console.log('Options clicked index = ', index);
        if (showOptions === index) {
            setShowOptions(null);
            return
        }
        setShowOptions(index);
    }

    const getDetail = (ele) => {
        if (ele.type === 'FOLDER') {
            return `${ele.totalFiles} Items`
        } else if (ele.type === 'FILE') {
            if (parseInt(ele.size) > 0) return `${ele.size} MB`
            return `${ele.size * 1024} KB`
        } else return ``
    }

    return (
        <div className={classes.main}>
            {createFolder ?
                <AddCard
                    icon='/images/documents/add-folder.svg'
                    text='Add Folder'
                    handleClick={handleAddFolder}
                /> : null}
            {createFile ?
                <AddCard
                    icon='/images/documents/add-file.svg'
                    text='Add File'
                    handleClick={handleAddFile}
                /> : null}

            {
                documents.map((ele, i) => (
                    <GridCard
                        key={i}
                        id={i}
                        icon={ele.thumbImage}
                        name={ele.name}
                        detail={getDetail(ele)}
                        handleDocument={() => handleDocument(ele)}
                        handleOptions={handleOptions}
                        setMouseOverOptions={setMouseOverOptions}
                        showOptions={showOptions === i}
                        handleDelete={() => handleDelete(ele)}
                        _editClick={() => _editClick(ele)}
                    />
                ))
            }

            {/* <GridCard
                icon='/images/documents/root-icons/FILE.png'
                name='Digital Marketing.svg'
                detail='89.6KB'
                handleDocument={handleDocument}
                handleOptions={handleOptions}
                setMouseOverOptions={setMouseOverOptions}
                // showOptions={showOptions}
            />
            <GridCard
                handleDocument={handleDocument}
                handleOptions={handleOptions}
                setMouseOverOptions={setMouseOverOptions} />
            <GridCard
                icon='/images/documents/root-icons/IMAGE.PNG'
                name='Slide 23.png'
                detail='74KB'
                handleDocument={handleDocument}
                handleOptions={handleOptions}
                setMouseOverOptions={setMouseOverOptions}
            /> */}
        </div>
    )
}

export default GridView;



const useStyles = makeStyles(() => ({
    main: {
        // border: '1px solid red',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1.5rem',
        paddingBottom: '0.2rem'
    }
}));