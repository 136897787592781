import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

//utils
import isEmpty from '../../../../utils/isEmpty';

//constants
import constants from '../../../../constants';

//components
import Title from '../../../title';
import CustomTextArea from '../../../custom-styled/custom-text-area';
import CustomInput from '../../../custom-styled/custom-input'
import ControlledSwitches from '../../../custom-styled/switch-button';
import CustomButton from '../../../custom-styled/button';

const Sequence = ({
  type,
  activeQuestion = { designType: '' },
  handleQuestion,
  handleResponse,
}) => {
  const classes = useStyles();
  const questionValidation = constants.ILA_VALIDATIONS[type]['question'];
  const responseValidation = constants.ILA_VALIDATIONS[type]['response'];
  const [questionLimit, setQuestionLimit] = useState({ exceeded: false, limitText: `${questionValidation} CHARACTERS` })
  const data = new Array(5).fill({ exceeded: false, limitText: `${responseValidation} CHARACTERS` })
  const [responseLimit, setResponseLimit] = useState(data);
  const [showAddResponse, setShowAddResponse] = useState(false);
  if (activeQuestion.designType !== 'ILA_SEQUENTIAL') {
    activeQuestion = {}
  }

  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let questionTempLimit = {};
      let value = activeQuestion.question;
      if (value.length > questionValidation) {
        //HERE LENGTH OF INPUT IS GREATER THAN questionCharlimit CHARACTERS.
        questionTempLimit = { exceeded: true, limitText: `${value.length - questionValidation} CHARACTERS EXCEEDED!` };
        setQuestionLimit(questionTempLimit);
      } else {
        //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
        if (activeQuestion.isQuestionEmpty) {
          questionTempLimit = { exceeded: false, limitText: `*Required` };
        } else {
          questionTempLimit = { exceeded: false, limitText: `${questionValidation - value.length} CHARACTERS` };
        }
        setQuestionLimit(questionTempLimit);
      }
    }
  }, [activeQuestion])

  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let temp = [...responseLimit];
      activeQuestion.answers.forEach((element, id) => {
        let value = element.answer;
        if (value.length > responseValidation) {
          //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
          temp[id] = { exceeded: true, limitText: `${value.length - responseValidation} CHARACTERS EXCEEDED!` };
          // setResponsesValidations(temp);
        } else {
          //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
          if (activeQuestion.emptyAnswers[id].isEmpty) {
            temp[id] = { exceeded: false, limitText: `*Required` };
          } else {
            temp[id] = { exceeded: false, limitText: `${responseValidation - value.length} CHARACTERS` };
          }
        }
      });
      console.log('McqResponse temp = ', temp);
      setResponseLimit(temp);
    }


  }, [activeQuestion])


  const handleQuestionChange = (e) => {
    let value = e.target.value;
    handleQuestion(value);
    console.log(value.length);
    if (value.length > questionValidation) {
      setQuestionLimit({ exceeded: true, limitText: `${value.length - questionValidation} CHARACTERS EXCEEDED!` })
    } else {
      setQuestionLimit({ exceeded: false, limitText: `${questionValidation - value.length} CHARACTERS` })
    }
  }

  const handleResponseChange = (e, index) => {
    console.log('handleResponseChange index = ', index);
    let value = e.target.value;
    console.log(value.length);
    handleResponse(value, index);
    let temp = [...responseLimit];
    if (value.length > responseValidation) {
      temp[index] = { exceeded: true, limitText: `${value.length - responseValidation} CHARACTERS EXCEEDED!` }
      setResponseLimit(temp)
    } else {
      temp[index] = { exceeded: false, limitText: `${responseValidation - value.length} CHARACTERS` }
      setResponseLimit(temp)
    }
  }

  const handleAddResponse = () => {

    let temp = [...responseLimit]
    temp.push({ exceeded: false, limitText: `${responseValidation} CHARACTERS` })
    setResponseLimit(temp)
    if (temp.length >= 5) {
      setShowAddResponse(false);
    }
  }

  return (
    <div className={classes.main}>
      <div className={classes.row2}>
        <div className={classes.card}>
          {/* <Title titleText={'Sequence'} /> */}
          <div className={classes.contextBox}>
            <CustomTextArea
              value={isEmpty(activeQuestion) ? '' : activeQuestion.question}
              handleChange={handleQuestionChange}
              exceedText={questionLimit.limitText}
              isExceeded={questionLimit.exceeded}
              isEmpty={isEmpty(activeQuestion) ? false : activeQuestion.isQuestionEmpty}
            />
          </div>
          <div className={classes.responsesBox}>
            {
              responseLimit.map((ele, i) => (
                <div className={classes.response} key={i}>
                  <div className={classes.column1}>
                    {i + 1}
                  </div>
                  <div className={classes.column2}>
                    <CustomInput
                      value={isEmpty(activeQuestion) ? '' : activeQuestion.answers[i].answer}
                      handleChange={(e) => handleResponseChange(e, i)}
                      exceedText={responseLimit[i].limitText}
                      isExceeded={responseLimit[i].exceeded}
                      isEmpty={isEmpty(activeQuestion) ? false : activeQuestion.emptyAnswers[i].isEmpty}
                    />

                  </div>
                </div>
              ))
            }
          </div>
          {
            showAddResponse ?
              <div className={classes.addResponse}>
                <CustomButton btnText={'Add New Response +'} handleClick={handleAddResponse} />
              </div> : null
          }
        </div>
      </div>
    </div>
  )
}

export default Sequence;

const useStyles = makeStyles(() => ({
  main: {
    // backgroundColor : 'red',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  row1: {
    // border : '1px solid black',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '6%',
    width: '100%',
  },
  checked: {
    '& span': {
      '& .Mui-checked ': {
        color: '#f4511e',

      },
      '& .MuiSwitch-track ': {
        backgroundColor: 'rgb(244, 81, 30) !important'
      }
    }
  },
  scoreBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '27%',
    '& input': {
      width: '26%',
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
  },
  mandatoryBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '25%'
  },
  row2: {
    // border : '1px solid black',
    height: '94%',
    width: '100%',
  },
  card: {
    aspectRatio: 0.87,
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '2rem',
    paddingBottom: '1rem',
    '& > h2': {
      marginTop: '1rem',
      fontSize: '1.6rem',
      color: 'rgb(0,0,0, 0.8)'
    }
  },
  contextBox: {
    // border : '1px solid black',
    width: '100%',
    height: '33%',
    marginTop: '1.5rem',
  },
  responsesBox: {
    // border: '1px solid',
    height: '51%',
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  response: {
    display: 'flex',
    height: '2.5rem',
    columnGap: '3%'
  },
  column1: {
    border: '1px solid #ccc',
    color: 'rgb(112, 112, 112, 0.7)',
    width: '10%',
    aspectRatio: 1 / 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  column2: {
    // border : '1px solid',
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center'
  },
  addResponse: {
    display: 'flex',
    justifyContent: 'center',
    '& .MuiButtonBase-root': {
      width: '30%',
      height: '2rem',
      padding: 0,
      fontSize: '0.5rem',
      fontWeight: 700,
      borderRadius: '4px'
    }
  }
}));