import axios from 'axios';
import { keys, urls } from '../configs';
import { store } from '../redux/store';
import { logout } from "../redux/auth/auth.api";

const baseConfig = {
    baseURL: `${urls.baseUrl}${urls.apiV1}`,
    mode: 'no-cors',
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'x-api-key': keys.apiKey
    }
}

export const fetch = axios.create(baseConfig);

const authInstance = axios.create(baseConfig);

authInstance.interceptors.request.use((config) => {
    const session = JSON.parse(localStorage.getItem('session'));
    config.headers['x-access-token'] = session.accessToken;
    config.headers['x-refresh-token'] = session.refreshToken;
    return config;
});

authInstance.interceptors.response.use(
    response => {
        return response
    },

    function (error) {

        console.log("error:", error.response);
        if (error.response.status === 401 || error.response.status === 403) {
            // return error.response;
            // console.log('Logout initiated');
            store.dispatch(logout);
        }
        return Promise.reject(error)
    }
)


export const authFetch = authInstance;

const multipartFormDataConfig = {
    baseURL: `${urls.baseUrl}${urls.apiV1}`,
    mode: 'no-cors',
    headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'x-api-key': keys.apiKey
    }
}

const authInstanceFormData = axios.create(multipartFormDataConfig);

authInstanceFormData.interceptors.request.use((config) => {
    const session = JSON.parse(localStorage.getItem('session'));
    config.headers['x-access-token'] = session.accessToken;
    config.headers['x-refresh-token'] = session.refreshToken;
    return config;
});

export const authFetchFormData = authInstanceFormData;


//kk
// import axios from "axios";
// import { openToast } from "../redux/common/common.actions";
// import constants from "../constants";
// import isEmpty from "./isEmpty";
// import { store } from '../redux/store';
// import { logout } from "../redux/auth/auth.api";

// const baseConfig = {
//     baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
//     // mode: 'no-cors',
//     // withCredentials: false,
//     // credentials: 'same-origin',
// }
// const axiosInstance = axios.create(baseConfig);
// const accessToken = localStorage.getItem("accessToken");
// const refreshToken = localStorage.getItem("refreshToken");
// const userId = localStorage.getItem("userId");
// const userName = localStorage.getItem("userName");
// // const refreshToken = store?.getState()?.auth?.tokens?.refreshToken;
// // const accessToken = store?.getState()?.auth?.tokens?.accessToken;
// // console.log("accessToken:", accessToken)
// // console.log("persist:", JSON.parse(localStorage.getItem('persist:root')));

// const PUBLIC_ENDPOINT = [
//     `${process.env.REACT_APP_API_BASE_URL}/access/logout`
// ];

// const MULTIPART_FORM_DATA = [
//     '/spotlight-attempt'
// ]

// axiosInstance.interceptors.request.use(
//     (config) => {
//         if (!PUBLIC_ENDPOINT.includes(config.url) && !isEmpty(accessToken)) {
//             // config.headers["Authorization"] = accessToken && `Bearer ${accessToken}`;
//         }
//         config.headers["x-api-key"] = process.env.REACT_APP_X_API_KEY;
//         config.headers["x-access-token"] = localStorage.getItem('accessToken');
//         config.headers["x-refresh-token"] = localStorage.getItem('refreshToken');

//         // config.headers["x-user-id"] = userId;
//         // config.headers["x-user-name"] = userName;
//         config.headers["access-control-allow-origin"] = "*";
//         config.headers["Content-Type"] = "application/json";
//         if (MULTIPART_FORM_DATA.includes(config.url)) {
//             // console.log("config.url:", config.url)
//             // config.headers["Content-Type"] = "multipart/form-data";
//             // config.headers["accept"] = "multipart/form-data";
//         } else {
//             // console.log("else")
//             // config.headers["Content-Type"] = "application/json";
//         }
//         return config
//     },
//     error => {
//         Promise.reject(error)
//     }
// )


