import { makeStyles } from "@mui/styles";
// import palette from "../../theme/palette";

import constants from "../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    container: {
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        height: '100%',
        backgroundColor: '#fff',
    },
    row1: {
        // border : '1px solid green',
        height: '50%'
    },
    row2: {
        // border : '1px solid red',
        position: 'relative',
        height: '50%',
        '& > form': {
            position: 'absolute',
            left: '28%',
            top: '1rem',
            width: '44rem',
            boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)'
        }
    },
}))

export default useStyles