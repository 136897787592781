import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    inputBox: {
        fontFamily: "Open Sans",
        fontSize: "0.8rem",
        fontWeight: "400",
        display: "block",
        width: "30%",
        padding: "0.5rem",
        margin: "1.2rem 0",
        border: "none",
        border: '1px solid rgb(112, 112, 112, 0.3)',
        color: "#707070",
        borderRadius: '4px'
    },

    anchor: {
        '&:hover': {
            textDecoration: 'underline !important',
            cursor: 'pointer'
        }
    },

    btn: {
        width: '190px',
        borderRadius: '2px',
        height: '42px',
        padding: '0 4px',
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        cursor: "pointer",
    },

    row: {
        // border: '1px solid',
        // height: '2rem',
        margin: '1rem 0',
        // marginBottom: '3.5rem',
        display: 'flex',
        flexDirection: 'column',
        // alignItems: 'center',
        gap: '1rem',
        '& > div': {
            height: '2.5rem',
            width: '50rem'
        }
    },
    linkContentBtnOld: {
        position: 'relative',
        '& > button': {
            position: 'absolute',
            zIndex: 100,
            top: 0,
            opacity: 0,
        },
        '& > span': {
            border: '1px solid red',
            position: 'absolute',
            top: 0,
            width: 200
        },
    },
    linkContentBtn: {
        position: 'relative',
        color: "#F4511E",
        border: "1px solid #F4511E",
        backgroundColor: "#ffffff",
        borderRadius: "2rem",
        fontFamily: 'Montserrat',
        fontSize: '0.7rem',
        fontWeight: 700,
        width: '155px',
        height: '36px',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'uppercase',
        '& > button': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '155px',

        },
        '& > svg': {
            marginLeft: '0.6rem'

        },
        '&:hover': {
            color: "#F4511E",
            border: "1px solid #F4511E",
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
    titleWrapper: {
        marginBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.6rem',
        '& > div': {
            height: '2.5rem',
            width: '50rem',
            '& > input': {
                color: 'rgb(112, 112, 112, 0.9) !important',
                fontSize: '0.8rem !important',
                fontFamily: 'Montserrat',
                fontWeight: 500,
            }
        }
    },
    messageWrapper: {
        marginBottom: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.6rem',
        '& > textarea': {
            height: '4rem',
            width: '50rem',
            resize: 'none',
            color: 'rgb(112, 112, 112, 0.9)',
            fontSize: '0.8rem',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            border: 'none',
            padding: '0.5rem',
            outline: '1.5px solid rgb(184, 184, 184, 0.5)',
            borderRadius: '3px',
            '&:focus-within': {
                outline: '2px solid #4F0060',
            },
            '&:hover': {
                outline: '2px solid #4F0060',
            },
        }
    },
    selectWrapper: {
        position: 'relative',
        height: '2rem',
        maxHeight: '2rem',

    },
    select: {
        border: '1px solid rgb(112, 112, 112, 0.4)',
        borderRadius: '4px',
        padding: '0.4rem 1rem',
        width: '20rem',
        height: '100%',
        overflow: 'hidden',
        color: "rgb(112, 112, 112, 0.9)",
        fontFamily: "Montserrat",
        fontSize: "0.9rem",
        fontWeight: 600,
        position: 'relative',
        cursor: 'pointer',
        transition: '0.5s',
        '& > i': {
            position: 'absolute',
            right: '0.7rem',
            top: '0.5rem',
            color: '#f4511e'
        },
        '&:hover': {
            borderColor: '#f4511e',
            color: '#f4511e',
            fontSize: '0.91rem',
            padding: '0.390rem 0.9rem',
        },
    },
    active: {
        borderColor: '#f4511e',
        color: '#f4511e',
        fontSize: '0.95rem',
        padding: '0.388rem 0.8rem',
    },
    selectBox: {
        width: '20rem',
        backgroundColor: '#fff',
        position: 'relative',
        top: '0.5rem',
        zIndex: 1
    },
    btnBox: {
        display: 'flex',
        gap: '2rem',
        marginTop: '6rem',
        '& .MuiButtonBase-root': {
            width: '158px',
            height: '42px',
            padding: '0 4px',
            borderRadius: '3px',

        },
        '& .MuiButton-contained:hover': {
            borderColor: '#f4511e',
            backgroundColor: '#f4511e',
            color: '#fff',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'

        },
        '& .MuiButton-outlined:hover': {
            borderColor: '#f4511e',
            backgroundColor: '#fff',
            color: '#f4511e',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'

        },
        '& .MuiLoadingButton-root': {
            fontFamily: 'Montserrat',
            fontSize: '0.6rem',
            letterSpacing: 1,
            fontWeight: 800,
            borderWidth: '2px',
        }
    }
}));
export default useStyles;