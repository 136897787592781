import React, { useEffect, useState } from 'react'

//mui
import MuiCheckbox from "@mui/material/Checkbox";
import { Button, ButtonBase } from '@mui/material';

//components
import Label from '../../custom-styled/label';
import AutoCompleteSearchApi from '../../autocomplete-search-api';
import DragDropFiles from "../../drag-drop-files-uploader/index"
import UploadedFiles from '../uploadedFiles';

//services
import companiesServices from "../../../services/companies";
import projectServices from "../../../services/projects";
import managerServices from '../../../services/manager';

//utils
import isEmpty from '../../../utils/isEmpty';

//styles
import useStyles from './index.styles';
import useClasses from '../index.styles';

//constants
import { TABS } from '../index';

//validations
import { defaultValidationData, defaultValidationDataManager } from '../index';
import commonUtil from '../../../utils/commonUtil';


const Content = (props) => {
    const { compainesFilter, setCompaniesFilter, setProjectFilter, isTestUser, setIsTestUser, activeTab, setActiveTab, file, setFile, setValidationData, managerFile, setManagerFile, setValidFile, managerTabFormats, setManagerTabFormats, isDemoUser, setIsDemoUser } = props;
    let roles = localStorage.getItem('roles') || [];
    const userType = localStorage.getItem('user-type');
    const styles = useStyles();
    const classes = useClasses();

    const [company, setCompany] = useState([]);
    const [project, setProject] = useState([]);

    const [compaines, setCompaines] = useState([]);
    const [projects, setProjects] = useState([]);

    const [searchCompany, setSearchCompany] = useState('')
    const [searchProject, setSearchProject] = useState('')

    const [loadingCompany, setLoadingCompany] = useState(false);
    const [loadingProject, setLoadingProject] = useState(false);


    useEffect(() => {
        if (userType === "companyAdmin") setCompaniesFilter(localStorage.getItem('companyId'));
    }, [])

    useEffect(() => {
        if (userType === "superAdmin") {
            if (searchCompany !== '') fetchCompaniesSearch(searchCompany);
            else fetchCompaines();
        }

    }, [searchCompany])

    useEffect(() => {
        fetchProjects();
    }, [searchProject, compainesFilter])

    useEffect(() => {
        if (activeTab === TABS.MANAGERS) {
            fetchManagerTabFormats()
        }
    }, [activeTab, compainesFilter])

    const fetchCompaines = async () => {
        setLoadingCompany(true);
        const response = await companiesServices.fetchCompanies();
        if (response.status === 200 || response.status === 201) {
            let compainesActive = response.data.data.filter(element => element.active === true);
            setCompaines(compainesActive);
            setLoadingCompany(false);
        }
    }

    const fetchProjects = async () => {
        if (isEmpty(compainesFilter)) return
        setLoadingProject(true)
        const response = await projectServices.fetchProjects(compainesFilter, searchProject);
        if (response.status === 200 || response.status === 201) {
            let projectActive = response.data.data.filter(element => element.active === true);
            setProjects(projectActive);
            setLoadingProject(false)
        }
    }

    const fetchCompaniesSearch = async (search) => {
        setLoadingCompany(true);
        const respData = await companiesServices.fetchCompaniesSearch(search);
        if (respData.status === 200 || respData.status === 201) {
            setCompaines(respData.data.data);
            setLoadingCompany(false);
        }
    }

    const changeOptionBaseValueCompany = (value) => {
        if (value === '') {
            setCompany([]);
            setSearchCompany(value);
        }
        else {
            setLoadingCompany(true);
            setCompaines([]);
            setSearchCompany(value);
        }
    }

    const handleChangeCompany = (e, companyOption) => {
        if (companyOption !== null) {
            setCompany([companyOption]);
            setCompaniesFilter(companyOption.id)
        }
        else {
            setProjects([])
        }
    }

    const changeOptionBaseValueProject = (value) => {
        if (value === '') {
            setProject([])
            setSearchProject(value)
        }
        else {
            setLoadingProject(true);
            setProjects([]);
            setSearchProject(value)
        }
    }

    const handleChangeProject = (e, projectOption) => {
        if (projectOption !== null) {
            setProject([projectOption]);
            setProjectFilter(projectOption.id)
        }
    }

    const handleSelectTab = (e) => {
        const tab = e.target.name;
        setActiveTab(tab);
        if (file) setFile(undefined);
        if (managerFile) setManagerFile(undefined);
        setValidFile(false);
        setValidationData(defaultValidationData);
        // if (tab === TABS.MANAGERS) setValidationData(defaultValidationDataManager);

    }

    const fetchManagerTabFormats = async () => {
        const companyId = compainesFilter;
        const response = await managerServices.getLastUploadedManagerMapping(companyId);
        if (response.status === 200) {
            setManagerTabFormats(response.data?.data);
        }
    }

    const handleManagerFileUpload = (file) => {
        setManagerFile(file);
    }


    return (
        <div className={styles.ContentContainer}>
            {userType === "superAdmin" && <Label labelText={'Company:'} fs='0.8rem' />}
            {userType === "superAdmin" && (
                <div style={{ width: "25%", marginTop: '1rem' }}>
                    <AutoCompleteSearchApi
                        selectedOption={company}
                        options={compaines}
                        loading={loadingCompany}
                        multiple={false}
                        changeOptionBaseOnValue={changeOptionBaseValueCompany}
                        handleChange={handleChangeCompany}
                        noOptionsText={'No Company Found'}
                        placeholder={'Search Company'}
                    />
                </div>
            )}

            <div className={styles.buttonWrapper} >
                <Button className={`${styles.btn} ${styles.btnUser} ${activeTab === TABS.USERS && styles.active}`} onClick={handleSelectTab} name={TABS.USERS} variant='contained'>Users</Button>
                <Button className={`${styles.btn} ${styles.btnManager} ${activeTab === TABS.MANAGERS && styles.active}`} onClick={handleSelectTab} name={TABS.MANAGERS} variant='contained'>Managers</Button>
            </div>

            <h1 className={styles.ContentHeading}>{activeTab === TABS.USERS ? 'Step 1: Project' : 'Step 1: Upload Manager Details'}  </h1>

            {activeTab === TABS.MANAGERS && <div style={{ marginBottom: '1rem' }}>
                <a style={{
                    textDecoration: "underline",
                    color: "#F4511E",
                    fontWeight: 700,
                    fontSize: "1rem",
                    fontFamily: 'Montserrat'
                }}
                    download
                    className={classes.anchor}
                    href={managerTabFormats?.manager}>Download File Format</a>
            </div>}

            {activeTab === TABS.MANAGERS && <div style={{ height: "15rem", display: 'flex', gap: '2rem' }}>
                {
                    !managerFile ?
                        (<DragDropFiles
                            title={'Drag & Drop your file here to import'}
                            handleChange={handleManagerFileUpload}
                            typesAccepted={["XLS", "XLSX"]}
                            multiple={false}
                            width='35rem'
                        />)
                        :
                        (<UploadedFiles fileName={managerFile.name} setFileUpload={setManagerFile} width='35rem' />)
                }
                {/* <div className={classes.validationWrapper}>
                    {
                        validations.map((ele, i) => {
                            if (ele.count) return <div key={i} onClick={() => handleDownloadValidationFile(ele)}><i className={ele.icon}></i><span>{ele.count}</span><p>{ele.title}</p> </div>
                        })
                    }
                </div> */}
            </div>
            }

            {activeTab === TABS.USERS && userType === "superAdmin" && compainesFilter !== '' && (
                <div style={{ width: "25%", marginTop: '1rem' }}>
                    <AutoCompleteSearchApi
                        selectedOption={project}
                        options={projects}
                        loading={loadingProject}
                        multiple={false}
                        changeOptionBaseOnValue={changeOptionBaseValueProject}
                        handleChange={handleChangeProject}
                        noOptionsText={'No Project Found'}
                        placeholder={'Search Project'}
                    />
                </div>
            )}


            {activeTab === TABS.USERS && userType === "companyAdmin" && (
                <div style={{ width: "25%" }}>
                    <AutoCompleteSearchApi
                        selectedOption={project}
                        options={projects}
                        loading={loadingProject}
                        multiple={false}
                        changeOptionBaseOnValue={changeOptionBaseValueProject}
                        handleChange={handleChangeProject}
                        noOptionsText={'No Project Found'}
                        placeholder={'Search Project'}
                    />
                </div>
            )}



            {activeTab === TABS.USERS && roles.includes('CREATE_TEST_USERS') && <div style={{ margin: "1rem 0" }}>
                <Checkbox
                    id="test-users"
                    type="checkbox"
                    handleClick={() => setIsTestUser(!isTestUser)}
                    isChecked={isTestUser}
                />
                <Label labelText={"Test users"} htmlFor='test-users' fs='0.8rem' />
            </div>}

            {
                activeTab === TABS.USERS && (
                    <>
                        <Checkbox
                            id="demo-users"
                            type="checkbox"
                            handleClick={() => setIsDemoUser(!isDemoUser)}
                            isChecked={isDemoUser}
                        />
                        <Label labelText={"Demo users"} htmlFor='demo-users' fs='0.8rem' />
                    </>
                )
            }


        </div>
    )
}

export default Content


const Checkbox = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiCheckbox
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};