import React from 'react'

//components
import QrBasedChallenge from '../../../components/qr-based-challenge';

const QrBasedChallengePage = () => {
  return (
   <QrBasedChallenge/>
  )
}

export default QrBasedChallengePage;
