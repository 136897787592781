import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams } from "react-router-dom";



import MasteroTable from "../../components/mastero-table";
import LoadingContainer from "../../components/loading-container";
import SuccessDialog from '../../components/success-dialog';
import FailureDialog from '../../components/failure-dialog';
import CreateNewProject from "../../components/create-new-company";

//services
import projectsServices from "../../services/projects";

// utils
import useRouter from "../../utils/useRouter";

// styles
import useStyles from "./index.styles";

//global-states
import { GlobalStates } from "../../App";
import SearchBar from "../../components/custom-styled/search-bar";

let descriptionTitle = "How to use projects:"
let descriptionText = "This tool gives you the ability to make different projects for your micro learning needs. Every project can house unlimited learner IDs, unless the sum of all learners across projects does not cross the specified limit for your company. You have full control on creation, deletion and updation of projects and learners within them."
let defaultPagination = {
  limit: 0,
  pageCount: 0,
  startIndex: 0,
  totalCount: 0
}

const ProjectsPage = ({ isMobile }) => {
  // console.log("isMobile:", isMobile);
  const classes = useStyles();
  const router = useRouter();
  const { companyId } = useParams();
  const [globalStates] = useContext(GlobalStates);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [isRepository, setIsRepository] = useState(false);
  const [inputValues, setInputValues] = useState({
    name: "",
    validity: "",
  });
  const [editProject, setEditProject] = useState({ edit: false, key: null });
  const [dialogContent, setDialogContent] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pagination, setPagination] = useState(defaultPagination);
  const [limit, setLimit] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearch = useRef();

  useEffect(() => {
    fetchProjects({ startIndex });
  }, [startIndex])

  const fetchProjects = async ({ startIndex = 0, search = '' }) => {
    // setLoading(true)
    setTableLoader(true)
    let res = await projectsServices.fetchProjectsPagination(companyId, startIndex, search);
    if (res?.status === 200 || res?.status === 201) {
      let activeProjects = res.data.data.filter(element => element.active === true);
      setPagination(res.data.pagination);
      setPageCount(res.data.pagination.pageCount);
      setTotalCount(res.data.pagination.totalCount);
      console.log('fetchProjects active projects =', activeProjects);
      setTableData(activeProjects);
      // setLoading(false);
      setTableLoader(false);
    }
  }

  const handleSubmit = async (data) => {
    globalStates.handleProgressDialog(true, 'Please wait...');
    let payload = {
      companyId: companyId,
      name: data.name,
      description: "Creating Productive Team",
      usersLimit: 1000,
      microskillsLimit: 50,
      repository: isRepository,
      validity: data.validity
    }
    if (editProject.edit) {
      let updatePayload = {
        id: tableData[editProject.key].id,
        name: data.name,
        validity: data.validity,
        description: "Temp description"
      }
      let res = await projectsServices.updateProject(updatePayload);
      globalStates.handleProgressDialog(false);
      if (res.status === 200 || res.status === 201) {
        setDialogContent("Project updated successfully!");
        setSuccessDialogOpen(true);
        setInputValues({ name: "", validity: "" })
        setEditProject({ edit: false, key: null });
        fetchProjects({ startIndex, search: searchValue })
        return
      } else {
        globalStates.handleToast(true, 'Internal server error', 'error')
      }

    } else {
      let res = await projectsServices.createProject(payload);
      globalStates.handleProgressDialog(false);
      if (res.status === 400) {
        if (res.data.errors) {
          setDialogContent(res.data.errors[0].message);
          setErrorDialogOpen(true);
          // alert(res.data.errors[0].message)
        }
        return
      }
      if (res.status === 200 || res.status === 201) {
        setDialogContent("Project created successfully!");
        setSuccessDialogOpen(true);
        setInputValues({ name: "", validity: "" });
        setIsRepository(false)
        fetchProjects({ startIndex });
      }
      return
    }
  }

  const handleDelete = async (key) => {
    let confirm = window.confirm("Do you really want to delete this project?")
    if (confirm) {
      globalStates.handleProgressDialog(true, 'Please wait...');
      const payload = { id: tableData[key].id }
      try {
        let res = await projectsServices.deleteProject(payload);
        if (res.status === 200 || res.status === 201) {
          globalStates.handleProgressDialog(false);
          setDialogContent("Project deleted successfully!");
          setSuccessDialogOpen(true);
          fetchProjects({ startIndex });
          if (searchValue.length) setSearchValue('');
        }
        return
      } catch (e) {
        console.log(e);
      }
    } else {
      return;
    }
  };

  const handleEdit = (element, key) => {
    setInputValues({
      name: element.name,
      validity: element.expiresOn.split('T')[0],
    });
    setEditProject({ edit: true, key: key });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleDuplicate = (element, key) => {
    return
  };

  const handleIsRepository = (e) => {
    let isChecked = e.target.checked;
    setIsRepository(isChecked);
  }

  const handleCreateRepository = (element) => {
    console.log('handleCreateRepository element = ', element);
    router.history.push(`/documents/${element.companyId}`)
  }

  const handleShowCourses = (element) => {
    console.log('handleShowCourses element = ', element);
    router.history.push(`/drive/courses-and-training/course-log?c_id=${companyId}`)
  }
  const handleManageLearners = (element) => {
    console.log('handleManageLearners element = ', element);
    router.history.push(`/drive/audience/manage-learners?c_id=${companyId}`)
  }
  const handleTraining = (element) => {
    console.log('handleTraining element = ', element);
    router.history.push(`/drive/training/training-log?c_id=${companyId}`)
  }

  const handleBackClick = () => {
    if (startIndex >= limit) {
      setStartIndex(startIndex - limit)
      setPageNo(pageNo - 1);
    }
  }

  const handleForwardClick = () => {
    if (startIndex + limit < totalCount) {
      setStartIndex(startIndex + limit);
      setPageNo(pageNo + 1);
    }
  }

  const TITLE = "Create New Project";
  const PLACEHOLDER = "Project name"
  const LABELS = {
    companyName: "Project Name:",
    validity: "Project Validity:",
  };
  const SAVE_BTN_TEXT = editProject.edit ? "Save Changes" : "Save Project";

  const TABLE_TITLE = "All Projects"
  let HEADINGS;
  const userType = localStorage.getItem('user-type');
  if (userType === 'superAdmin') {
    HEADINGS = [
      { title: "Project Name", key: "name" },
      // { title: "Microskills", key: "activeMicroskills" },
      { title: "Content Repository", key: "repository" },
      { title: "Learners", key: "activeUsers" },
      { title: "Batches", key: "batches" },
      { title: "Courses", key: "courses" },
      { title: "Training", key: "training" },
      { title: "Validity", key: "expiresOn" },
      { title: "Last Updated", key: "updatedOn" },
      { title: "Quick Actions", key: "quickActions" }
    ];
  } else {
    HEADINGS = [
      { title: "Project Name", key: "name" },
      { title: "Learners", key: "activeUsers" },
      { title: "Validity", key: "expiresOn" },
      { title: "Last Updated", key: "updatedOn" },
      { title: "Quick Actions", key: "quickActions" }
    ];
  }

  const handleSearchProject = (e) => {
    e.preventDefault();
    clearTimeout(debounceSearch.current);
    let value = e.target.value;
    setSearchValue(value);
    let delay = 1000;
    if (!value.length) delay = 0;
    debounceSearch.current = setTimeout(() => {
      fetchProjects({ startIndex: 0, search: value });
      clearTimeout(debounceSearch.current);
    }, delay)
  }

  const handleClearSearch = () => {
    setSearchValue('');
    if (!startIndex) fetchProjects({ startIndex: 0 });
    else setStartIndex(0);
  }


  if (loading) {
    return (
      <LoadingContainer />
    )
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.row1}>
          <CreateNewProject
            title={TITLE}
            labels={LABELS}
            placeholder={PLACEHOLDER}
            btnTxt={SAVE_BTN_TEXT}
            handleSubmit={handleSubmit}
            inputValues={inputValues}
            setInputValues={setInputValues}
            handleRepository={handleIsRepository}
            descriptionTitle={descriptionTitle}
            descriptionText={descriptionText}
          />
        </div>

        <div className={classes.row2}>
          <SearchBar
            handleChange={handleSearchProject}
            value={searchValue}
            handleClear={handleClearSearch}
            placeholder="Search project"
          />
          <MasteroTable
            tableTitle={TABLE_TITLE}
            tableHeadings={HEADINGS}
            tableData={tableData}
            quickActions={true}
            deleteKey={true}
            copyKey={false}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            handleDuplicate={handleDuplicate}
            handleCreateRepository={handleCreateRepository}
            handleShowCourses={handleShowCourses}
            handleManageLearners={handleManageLearners}
            handleTraining={handleTraining}
            handleForwardClick={handleForwardClick}
            handleBackClick={handleBackClick}
            pageCount={pageCount}
            pageNo={pageNo}
            pagination={pagination}
            loader={tableLoader}
          />
        </div>

        {
          successDialogOpen &&
          <SuccessDialog
            successDialogOpen={successDialogOpen}
            setSuccessDialogOpen={setSuccessDialogOpen}
            dialogText={dialogContent}
            handleClick={() => setSuccessDialogOpen(false)}
            buttonText='Okay'
          />
        }
        {
          errorDialogOpen &&
          <FailureDialog
            errorDialogOpen={errorDialogOpen}
            setErrorDialogOpen={setErrorDialogOpen}
            dialogText={dialogContent}
            handleClick={() => setErrorDialogOpen(false)}
          />
        }
      </div>
    );
  }


};

export default ProjectsPage;
