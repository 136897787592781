import React from 'react';
import styled from 'styled-components';
// import Grid from '@material-ui/core/Grid';
// import { Dialog } from '@material-ui/core';
import TextInput from '../../components/tms-components/TextInput';
import SimpleFormSelect from '../../components/tms-components/SimpleFormSelect';
import FlatPrimaryButton from '../../components/tms-components/FlatPrimaryButton';
import FormControl from '../../components/tms-components/FormControl';
import Label from '../../components/tms-components/Label';
import Loader from '../../components/tms-components/Loader';
import Button2 from '../../components/tms-components/Button2';

//new
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';

//utils
import useQuery from '../../utils/useQuery';
import commonUtil from '../../utils/commonUtil';

//services
import audienceServices from '../../services/audience';

const TickImage = require('../../assets/tick.png');
const CrossImg = require('../../assets/cross.png');


export default function SingleLearnerUpload({ batches, jwtToken }) {
  const query = useQuery();
  const companyId = query.get('c_id');
  const [errorText, setErrorText] = React.useState(
    'There was an error processing your request, please try again or contact us using the Chat Button',
  );
  const [progressDialog, setProgressDialog] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [failureDialog, setFailureDialog] = React.useState(false);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [employeeId, setEmployeeId] = React.useState('');
  const [channel, setChannel] = React.useState('');
  const [batchId, setBatchId] = React.useState(0);
  const [userId, setUserId] = React.useState(0);
  const [users, setUsers] = React.useState([]);
  const [contactNumber, setContactNumber] = React.useState('');
  const [designation, setDesignation] = React.useState('');
  const [bankName, setBankName] = React.useState('');
  const [contactNumberErrorText, setContactNumberErrorText] = React.useState(
    '',
  );

  React.useEffect(() => {
    // fetchCompanyUsers(companyId);
  }, []);

  const fetchCompanyUsers = async (companyId) => {
    if (!companyId) return
    const res = await audienceServices.fetchAllLearners(companyId);
    console.log('fetchCompanyUsers res = ', res);
    if (res.status === 200 || res.status === 201) {
      setUsers(res.data.data);
    }
  }

  let selectedUserName = '';

  const userOptions = users.map((user) => {
    return {
      id: user.username,
      display_text: user.username,
    };
  });

  if (userId !== 0) {
    const theUser = users.filter((user) => user.username === userId);
    if (theUser.length) {
      if (theUser[0].username.length) {
        selectedUserName = theUser[0].username;
      }
    }
  }

  const batchOptions = batches.map((batch) => {
    let batch_name = batch.code;
    if (batch.name !== null) {
      batch_name += ` ${batch.name}`;
    }
    return {
      id: batch.id,
      display_text: batch_name,
    };
  });



  let selectedBatchName = '';

  if (batchId !== 0) {
    const the_batch = batches.filter((b) => b.id === batchId);
    if (the_batch.length > 0) {
      let batch_name = the_batch[0].code;
      if (the_batch[0].name !== null) {
        batch_name += ` ${the_batch[0].name}`;
      }
      selectedBatchName = batch_name;
    }
  } else {
    selectedBatchName = '';
  }

  function runValidation() {
    // check the form
    // if (userId === 0) {
    //   setErrorText('Please select the learner');
    //   setFailureDialog(true);
    //   return false;
    // }


    if (firstName === '') {
      setErrorText('Please enter the First Name');
      setFailureDialog(true);
      return false;
    }

    if (lastName === '') {
      setErrorText('Please enter the Last Name');
      setFailureDialog(true);
      return false;
    }

    if (employeeId === '') {
      setErrorText('Please enter the Employee ID/Username');
      setFailureDialog(true);
      return false;
    }



    if (contactNumber.length > 0 && contactNumber.length !== 10) {
      setContactNumberErrorText('Contact number should be 10 digits!');
      return false;
    }

    if (batchId === 0) {
      setErrorText('Please select the Batch');
      setFailureDialog(true);
      return false;
    }

    return true;
  }

  function updateContactNumber(text) {
    if (!Number.isNaN(Number(text)) && text.length < 11) {
      setContactNumber(text);
    }
  }

  React.useEffect(() => {
    if (contactNumber.length === 10) {
      setContactNumberErrorText('');
    }
  }, [contactNumber]);

  const handleUploadLearner = async () => {
    const valid = runValidation();
    if (!valid) return;
    // return

    const payload = {
      companyId: companyId,
      batchId: batchId,
      username: employeeId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobile: contactNumber,
      employeeId: employeeId
    }
    console.log('handleUploadLearner = ', payload);
    // return
    setProgressDialog(true);
    const res = await audienceServices.addLearnerToBatch(payload);
    console.log('handleUploadLearner res = ', res);
    if (res.status === 200 || res.status === 201) {
      setSuccessDialog(true);
      setProgressDialog(false);
    } else {
      setErrorText("Internal Server Error");
      setFailureDialog(true);
      setProgressDialog(false);
    }

    // const o = {
    //   token: jwtToken,
    //   batch_id: batchId,
    //   data: {
    //     designation,
    //     channel,
    //     first_name: firstName,
    //     last_name: lastName,
    //     participant_code: employeeId,
    //     email_id: email,
    //     employee_id: employeeId,
    //     contact_number: contactNumber,
    //     bank_name: bankName,
    //   },
    // };

    // const action = MOClient.add_learners_to_batch(o);

    // setProgressDialog(true);

    // action.then((outcome) => {
    //   setProgressDialog(false);
    //   if (outcome.status === 'success') {
    //     setSuccessDialog(true);
    //   } else {
    //     setErrorText(outcome.description);
    //     setFailureDialog(true);
    //   }
    // });
  }

  const handleClear = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setEmployeeId("");
    setChannel("");
    setBatchId(0);
    setContactNumber("");
    setDesignation("");
    setBankName("");

  }

  return (
    <div style={{ textAlign: 'left', paddingLeft: 30, paddingTop: 20 }}>
      <Grid container>
        <Grid item xs={12} lg={5}>
          <FormControl>
            <Label>
              First Name
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <TextInput
              value={firstName}
              onChangeText={(t) => {
                setFirstName(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>
              Last Name
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <TextInput
              value={lastName}
              onChangeText={(t) => {
                setLastName(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>
              Email ID
              <span style={{ color: 'red' }}></span>
            </Label>
            <TextInput
              value={email}
              onChangeText={(t) => {
                setEmail(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>
              Employee ID / Username
              <span style={{ color: 'red' }}>*</span>
            </Label>
            <TextInput
              value={employeeId}
              onChangeText={(t) => {
                setEmployeeId(t);
              }}
            />
          </FormControl>

          {/* <FormControl>
            <Label>
              Learner <span style={{ color: 'red' }}>*</span>
            </Label>
            <SimpleFormSelect
              autoClose
              options={userOptions}
              text={userId===0?'Select Learner':selectedUserName}
              onSelect={(id) => {
                setUserId(id);
              }}
            />
          </FormControl> */}

          <FormControl>
            <Label>
              Batch <span style={{ color: 'red' }}>*</span>
            </Label>
            <SimpleFormSelect
              autoClose
              options={batchOptions}
              text={batchId === 0 ? 'Select Batch' : selectedBatchName}
              onSelect={(id) => {
                setBatchId(id);
              }}
            />
          </FormControl>

          <FormControl style={{ marginTop: '30px' }}>
            <FlatPrimaryButton
              onClick={handleUploadLearner}>
              Upload Learner
            </FlatPrimaryButton>
          </FormControl>
        </Grid>

        <Grid item lg={5}>
          <FormControl>
            <Label>
              Channel
              <span style={{ color: 'red' }}></span>
            </Label>
            <TextInput
              value={channel}
              onChangeText={(t) => {
                setChannel(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>Designation</Label>
            <TextInput
              value={designation}
              onChangeText={(t) => {
                setDesignation(t);
              }}
            />
          </FormControl>

          <FormControl>
            <Label>Contact Number</Label>
            <TextInput
              value={contactNumber}
              onChangeText={(t) => {
                updateContactNumber(t);
              }}
              errorText={contactNumberErrorText}
            />
          </FormControl>

          <FormControl>
            <Label>Bank Name</Label>
            <TextInput
              value={bankName}
              onChangeText={(t) => {
                setBankName(t);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {/* Progress Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={progressDialog}
        onClose={() => {
          setProgressDialog(false);
        }}>
        <ProgressContainer>
          <Loader fill="#f4511e" />

          <ProgressText>Please wait...</ProgressText>
        </ProgressContainer>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        open={successDialog}
        onClose={() => {
          setSuccessDialog(false);
        }}>
        <ProgressContainer>
          <img alt="Success" src={TickImage} style={{ width: '50px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>Learner Uploaded Successfully!</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setSuccessDialog(false);
                handleClear();
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>

      {/* Failure Dialog */}
      <Dialog
        open={failureDialog}
        onClose={() => {
          setFailureDialog(false);
        }}>
        <ProgressContainer>
          <img alt="Failure" src={CrossImg} style={{ width: '50px' }} />
          <div style={{ margin: '12px 0' }}>
            <ProgressText>{errorText}</ProgressText>
          </div>
          <div style={{ margin: '12px 0' }}>
            <Button2
              onClick={() => {
                setFailureDialog(false);
              }}>
              Close
            </Button2>
          </div>
        </ProgressContainer>
      </Dialog>
    </div>
  );
}

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
