import React, { useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
import LoadingButton from '@mui/lab/LoadingButton';

const EmailReports = ({ open, setOpen,handleClick, handleChange, loading = false }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClose = () => {
        setOpen(false);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        handleClick()
    }

    return (
        <>
            <ButtonBase
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpen(true);
                }}>
                <img src='/images/analytics/email.svg' alt='' title='Email' />

            </ButtonBase>
            {open && <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                className={classes.menu}
                open={open}
                onClose={handleClose}>
                <form className={classes.form}>
                    <input required type='email' placeholder='name@example.com' title='name@example.com' onChange={handleChange} />
                    {loading ? <LoadingButton loading variant="outlined">Submit</LoadingButton> :
                        <ButtonBase onClick={handleSubmit} type='submit' className={classes.btn}>Send Report</ButtonBase>
                    }
                </form>
            </Menu>}
        </>
    )
}

export default EmailReports


const useStyles = makeStyles(() => ({
    menu: {
        '& > .MuiPaper-root': {
            // top: "17% !important",
            left: "76% !important",
            width: "300px",
            height: "46px",

            '& > .MuiList-root': {
                display: "flex",
                padding: "6px",
                height: "46px"
            }
        }
    },
    form: {
        display: "flex",
        gap: "0.5rem",
        height: "100%",
        width: "100%",
        '& > input': {
            width: "70%",
            border: "1px solid rgb(112,112,112, 0.4)",
            padding: "0 0.5rem",
            color: "rgb(112, 112, 112)",
        },

    },
    btn: {
        width: "28%",
        backgroundColor: "#f4511e",
        color: "#ffffff",
        fontFamily: "Montserrat",
        fontSize: "10px",
        fontWeight: 700,
        borderRadius: "4px"
    }
}));