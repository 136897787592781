import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    mainContainer: {
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        padding: '1rem 1.5rem',
        backgroundColor: '#ffffff'
    },
    row: {
        // border: '1px solid',
        height: '2rem',
        margin: '1rem 0',
        marginBottom: '3.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '5rem'
    },
    selectWrapper:{
        position: 'relative',
        height: '2rem',
        maxHeight: '2rem',
       
    },
    select: {
        border: '1px solid rgb(112, 112, 112, 0.4)',
        borderRadius: '4px',
        padding: '0.4rem 1rem',
        width: '20rem',
        height: '100%',
        overflow: 'hidden',
        color: "rgb(112, 112, 112, 0.9)",
        fontFamily: "Montserrat",
        fontSize: "0.9rem",
        fontWeight: 600,
        position: 'relative',
        cursor: 'pointer',
        transition: '0.5s',
        '& > i': {
            position: 'absolute',
            right: '0.7rem',
            top: '0.5rem',
            color: '#f4511e'
        },
        '&:hover':{
            borderColor: '#f4511e',
            color: '#f4511e',
            fontSize: '0.91rem',
            padding: '0.390rem 0.9rem',
        },
    },
    active:{
        borderColor: '#f4511e',
        color: '#f4511e',
        fontSize: '0.95rem',
        padding: '0.388rem 0.8rem',
    },
    selectBox:{
        backgroundColor: '#fff',
        position: 'relative',
        top: '0.5rem',
        zIndex: 1
    },
    btnBox:{
        display: 'flex',
        gap: '2rem',
        marginTop: '6rem',
        '& .MuiButtonBase-root':{
            width: '12rem',
            height: '2.8rem',
            borderRadius: '3px',
         
        },
        '& .MuiButton-contained:hover':{
            borderColor: '#f4511e',
            backgroundColor: '#f4511e',
            color: '#fff',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
        
        },
        '& .MuiButton-outlined:hover':{
            borderColor: '#f4511e',
            backgroundColor: '#fff',
            color: '#f4511e',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
        
        },
        '& .MuiLoadingButton-root':{
            fontFamily: 'Montserrat',
            fontSize: '0.6rem',
            letterSpacing: 1,
            fontWeight: 800,
            borderWidth: '2px',
        }
    }
}));


export default useStyles;