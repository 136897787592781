import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import ButtonBase from '@mui/material/ButtonBase';

const useStyles = makeStyles(() => ({
    questionTag: {
        margin: '0.6rem 0rem',
        // borderBottom: '1px solid rgb(184, 184, 184, 0.4)'
    },
    label: {
        padding: '1rem 0rem',
        color: "rgb(0, 0, 0, 0.9)",
        fontFamily: "Montserrat",
        fontSize: "0.7rem",
        fontWeight: "700",
    },
    btnBox: {
        // marginTop: '0.5rem',
        "& button": {
            margin: '0.8rem',
            marginLeft: '0',
            padding: '0.5rem 1.3rem',
            backgroundColor: '#FFFFFF',
            border: '1px solid #f4511e',
            borderRadius: '2rem',
            color: "rgb(68, 68, 68, 0.9)",
            fontFamily: "Montserrat",
            fontSize: "0.6rem",
            fontWeight: 700,
            cursor: 'pointer',
            transition: '.5s',
            '&:hover': {
                // backgroundColor: '#f4511e',
                // color: "#FFFFFF",
                boxShadow: "3px 2px 9px 0px rgba(0,0,0,0.2)",
                "-moz-box-shadow": "3px 2px 9px 0px rgba(0,0,0,0.2)",
                "-webkit-box-shadow": "3px 2px 9px 0px rgba(0,0,0,0.2)",
            }
        },
        '& > h1': {
            fontSize: '0.8rem',
            margin: '1rem 0',
            // color: '#ffa00099',//yelllow
            color: '#0D034B',
            fontFamily: "Montserrat",
            padding: '0.4rem 1rem',
            margin: '0.5rem 0',
            textAlign: 'center',
            // backgroundColor: '#fff',
            letterSpacing: 1

        }
    },
    active: {
        backgroundColor: '#f4511e !important',
        color: "#FFFFFF !important",
    }

}));

const SkillTags = ({ tags, selectedTag, handleTagClick }) => {
    const classes = useStyles();
    return (
        <div className={classes.questionTag}>
            <label className={classes.label}>Select Skill Tag:</label>
            <div className={classes.btnBox}>

                {
                    tags.length ?
                        (

                            tags.map((element, index) => (
                                <ButtonBase
                                    id={index.toString()}
                                    className={`${selectedTag === element.id ? classes.active : null}`}
                                    key={index}
                                    onClick={() => handleTagClick(element)}
                                    value={element.id}>
                                    {element.skill}
                                </ButtonBase>

                            ))

                        ) : <h1>No skill tags are present in this Microskill</h1>
                }


            </div>
        </div>
    )
}

export default SkillTags
