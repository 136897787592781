import React from 'react'
import { useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';

//components
import SearchBox from '../../search-box';
import CustomButton from '../button';
import Label from '../label';

let defaultMessage = 'No Data Available.'

const CustomSelect = ({
    defaultText = "",
    hideBaseBtn = false,
    autoClose = false,
    withSearch = true,
    withSelectAllOption = true,
    listData = [],
    selected = [],
    setSelected = () => { },
    handleSelect,
    customSelectAll = false,
    _selectAll,
    emptyListMessage = defaultMessage,
    withCustomSelect = true,
    withLocalSearch = true,
    handleSearch,
    withEndIcon = true,
    startIcon = true,
    name = "",
    width = '300px',
    handleClear = null,
    withClear = false
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const clear = () => {
        if (handleClear) {
            handleClear();
            return
        }
        setSelected([]);
    }

    return (
        <>
            <ButtonBase
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                className={`${classes.baseBtn}  ${open ? classes.active : ''}`}
                sx={{ width }}
                onClick={handleClick}>
                <span>{defaultText}</span>
                {withEndIcon ? <i className={`fa-solid ${open ? 'fa-angle-up' : 'fa-angle-down'}`}></i> : null}
                {/* <div
                    className={`${classes.select} ${open ? classes.active : ''}`}
                    onClick={() => handleSetActive('company')}
                >

                    {
                        selectedCompany.length ?
                            companiesObject[selectedCompany[0]]?.name
                            : 'Select Company'
                    }
                </div> */}

            </ButtonBase >
            {open && <Menu
                key={listData.length}
                id='basic-menu'
                anchorEl={anchorEl}
                className={classes.menu}
                sx={{
                    top: hideBaseBtn ? -36 : 3
                }}
                open={open}
                onClose={handleClose}
            >
                <SearchBox
                    autoClose={autoClose}
                    setOpen={setOpen}
                    withSelectAllOption={withSelectAllOption}
                    withSearch={withSearch}
                    listData={listData}
                    selected={selected}
                    setSelected={setSelected}
                    handleSelect={handleSelect}
                    withCustomFunction={withCustomSelect}
                    emptyListMessage={emptyListMessage}
                    withLocalSearch={withLocalSearch}
                    handleSearch={handleSearch}
                    customSelectAll={customSelectAll}
                    _selectAll={_selectAll}
                    name={name}
                />
                {
                    withClear ?
                        <div className={classes.clearBtnWrapper}>
                            <Label labelText={`${selected.length ? 'Selected: '+ selected.length +'/': 'Total:'} ${listData.length}`} fs='0.9rem' />
                            <CustomButton btnText={'Clear Selection'} handleClick={clear} fw={800} />
                        </div> : null
                }

            </Menu>
            }
        </>
    )
}

export default CustomSelect;

const useStyles = makeStyles(() => ({
    baseBtn: {
        display: 'flex',
        justifyContent: 'start',
        position: 'relative',
        height: '33px',
        maxHeight: '33px',
        border: '1px solid rgb(112, 112, 112, 0.4)',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: '0.5s',
        padding: '0.4rem 1rem',
        '& > i': {
            position: 'absolute',
            right: '0.7rem',
            fontSize: '1rem',
            color: '#f4511e'
        },
        '& > span': {
            color: "rgb(112, 112, 112, 0.9)",
            fontFamily: "Montserrat",
            fontSize: "0.9rem",
            fontWeight: 600,
        },
        '&:hover': {
            borderColor: '#f4511e',
            '& > span': {
                color: '#f4511e',
                // fontSize: '0.81rem',

            }
        },
    },
    menu: {
        // top: 3,
        '& > .MuiPaper-root': {
            width: "300px",
            minWidth: "fit-content",
            overflow: "hidden",
            '& > .MuiList-root': {
                padding: 0,
                '& > div:first-child': {
                    border: 'none'
                }
            }
        },

    },

    active: {
        borderColor: '#f4511e',
        '& > span': {
            color: '#f4511e',
            // fontSize: '0.81rem',

        },
    },
    clearBtnWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '1rem',
        gap: '1rem',
        '& > label': {
            width: 'fit-content'
        },
        '& > button': {
            width: 150
        }
    }
}));
