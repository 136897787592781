import React from "react";

//mui
import MuiCheckbox from "@mui/material/Checkbox";

const Checkbox = ({id, type, name, handleClick, isChecked }) => {
  return (
    <MuiCheckbox
      id={id}
      name={name}
      type={type}
      onChange={handleClick}
      checked={isChecked}
      sx={{
        "& .MuiSvgIcon-root": {fontSize : "1rem", color: "#F4511E", padding : "0", margin: "0" },
      }}
    />
  );
};

export default Checkbox;