import React from "react";

//components
import UserLaunch from "../../components/zw-user-launch";

const UserLaunchPage = () => {
  return (
    <UserLaunch/>
  )
}

export default UserLaunchPage