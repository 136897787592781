import React from 'react';
import dayjs from 'dayjs';
// import styled from 'styled-components';
// import {makeStyles} from "@material-ui/styles";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
// import DayUtils from '@date-io/dayjs';
// import CalendarIcon from "./MDLIcon/icons/Calendar";

//new
import styled from 'styled-components';
import { makeStyles } from "@mui/styles";
import { ButtonBase } from '@mui/material';
import CalendarIcon from "./MDLIcon/icons/Calendar";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';



const custom_styles = makeStyles({
  text: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    paddingLeft: 5,
    fontSize: "0.7rem",
    color: "#707070",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "170px",
    display: "block"
  },
  container: {
    display: 'flex',
    flexDirection: "column"
  },
  textField: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  label: {
    color: "#707070",
    fontSize: "0.75rem",
    fontFamily: "Montserrat",
  },
  selector_wrapper:{
    border: "0.5px solid #dde0e2",
    width: "200px",
    maxWidth: "100%",
    height: "28px"
  },
  selector_button_base: {
    width: "100% !important",
    height: "100% !important",
    display: "flex !important",
    justifyContent: "space-between !important",
    alignItems: "center !important"
  },
  selector_first_flex: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "4px"
  },
  selector_second_flex: {
    display: "flex",
    alignItems: "center"
  },
  expand_more: {
    fontSize: "0.9rem !important",
    fill: "#f4511e !important",
    paddingRight: "8px !important"
  },
  errorText: {
    color: "#EB3917",
    fontFamily: "Montserrat",
    fontSize: "0.55rem",
    fontWeight: "700",
    paddingTop: "0.5rem"
}
});

const StyledCalendarIcon = styled(CalendarIcon)`
  .cls-1{
    fill:#fff;opacity:0
  }
  
  .cls-2{
    fill:#707070;
    fill-rule:evenodd
  }
  
`;

export default function MODateTimePicker(props) {
  // const {label,onClick,text,open,value,  minDate = dayjs(new Date()),handleDateChange,onClose, errorText = ''}=props;

  let {label,onClick,text,open,value, minDate , maxDate,handleDateChange,onClose, errorText = '', isLearningJourney = false}=props;
  if(!minDate)minDate = dayjs(new Date( "1970-01-01"))
  if(!maxDate)maxDate = dayjs(new Date( "2100-01-01"))
  let styles = custom_styles(props);

  return (
    <div className={styles.container} >
      <span className={styles.label}>{label}</span>
      <div className={styles.selector_wrapper} style={{width : isLearningJourney ? '160px' : ''}}>
        <ButtonBase  className={styles.selector_button_base} onClick={onClick}>
          <div className={styles.selector_first_flex}>
            <StyledCalendarIcon />
            <span className={styles.text}>{text}</span>
          </div>
        </ButtonBase>
        {errorText.length?<h6 className={styles.errorText}>{errorText}</h6>:null}
      </div>
      <div style={{display: "none"}}>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDateTimePicker 
          open={open}
          label="Date"
          value={value}
          // disablePast={true}
          // ampm={false}
          minDate={minDate}
          minDateTime={minDate}
          onChange={handleDateChange}
          onClose={onClose}
          maxDate={maxDate}
          />
      
    </LocalizationProvider>
      </div>

    </div>
  )

}




















// import React from 'react';
// import styled from 'styled-components';
// import {makeStyles} from "@material-ui/styles";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
// import DayUtils from '@date-io/dayjs';
// import CalendarIcon from "./MDLIcon/icons/Calendar";

// const custom_styles = makeStyles({
//   text: {
//     fontFamily: "Montserrat",
//     fontWeight: 600,
//     paddingLeft: 5,
//     fontSize: "0.7rem",
//     color: "#707070",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     whiteSpace: "nowrap",
//     maxWidth: "170px",
//     display: "block"
//   },
//   container: {
//     display: 'flex',
//     flexDirection: "column"
//   },
//   textField: {
//     marginLeft: "10px",
//     marginRight: "10px",
//   },
//   dense: {
//     marginTop: 16,
//   },
//   menu: {
//     width: 200,
//   },
//   label: {
//     color: "#707070",
//     fontSize: "0.75rem",
//     fontFamily: "Montserrat",
//   },
//   selector_wrapper:{
//     border: "0.5px solid #dde0e2",
//     width: "200px",
//     maxWidth: "100%",
//     height: "28px"
//   },
//   selector_button_base: {
//     width: "100% !important",
//     height: "100% !important",
//     display: "flex !important",
//     justifyContent: "space-between !important",
//     alignItems: "center !important"
//   },
//   selector_first_flex: {
//     display: "flex",
//     alignItems: "center",
//     paddingLeft: "4px"
//   },
//   selector_second_flex: {
//     display: "flex",
//     alignItems: "center"
//   },
//   expand_more: {
//     fontSize: "0.9rem !important",
//     fill: "#f4511e !important",
//     paddingRight: "8px !important"
//   }
// });

// const StyledCalendarIcon = styled(CalendarIcon)`
//   .cls-1{
//     fill:#fff;opacity:0
//   }
  
//   .cls-2{
//     fill:#707070;
//     fill-rule:evenodd
//   }
  
// `;

// export default function MODateTimePicker(props) {
//   let styles = custom_styles(props);

//   let min_date = new Date();


//   if(props.hasOwnProperty('minDate')) {
//     min_date = props.minDate;
//   }

//   return (
//     <div className={styles.container} >
//       <span className={styles.label}>{props.label}</span>
//       <div className={styles.selector_wrapper}>
//         <ButtonBase  className={styles.selector_button_base} onClick={props.onClick}>
//           <div className={styles.selector_first_flex}>
//             <StyledCalendarIcon />
//             <span className={styles.text}>{props.text}</span>
//           </div>
//         </ButtonBase>
//       </div>
//       <div style={{display: "none"}}>
//         <MuiPickersUtilsProvider utils={DayUtils}>

//         <DateTimePicker
//           open={props.open}
//           label="Basic example"
//           value={props.value}
//           minDate={min_date}
//           onChange={props.handleDateChange}
//           animateYearScrolling
//           onClose={props.onClose}
//         />
//         </MuiPickersUtilsProvider>
//       </div>

//     </div>
//   )

// }




















// import React from 'react';
// import styled from 'styled-components';
// import {makeStyles} from "@material-ui/styles";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
// import DayUtils from '@date-io/dayjs';
// import CalendarIcon from "./MDLIcon/icons/Calendar";

// const custom_styles = makeStyles({
//   text: {
//     fontFamily: "Montserrat",
//     fontWeight: 600,
//     paddingLeft: 5,
//     fontSize: "0.7rem",
//     color: "#707070",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     whiteSpace: "nowrap",
//     maxWidth: "170px",
//     display: "block"
//   },
//   container: {
//     display: 'flex',
//     flexDirection: "column"
//   },
//   textField: {
//     marginLeft: "10px",
//     marginRight: "10px",
//   },
//   dense: {
//     marginTop: 16,
//   },
//   menu: {
//     width: 200,
//   },
//   label: {
//     color: "#707070",
//     fontSize: "0.75rem",
//     fontFamily: "Montserrat",
//   },
//   selector_wrapper:{
//     border: "0.5px solid #dde0e2",
//     width: "200px",
//     maxWidth: "100%",
//     height: "28px"
//   },
//   selector_button_base: {
//     width: "100% !important",
//     height: "100% !important",
//     display: "flex !important",
//     justifyContent: "space-between !important",
//     alignItems: "center !important"
//   },
//   selector_first_flex: {
//     display: "flex",
//     alignItems: "center",
//     paddingLeft: "4px"
//   },
//   selector_second_flex: {
//     display: "flex",
//     alignItems: "center"
//   },
//   expand_more: {
//     fontSize: "0.9rem !important",
//     fill: "#f4511e !important",
//     paddingRight: "8px !important"
//   }
// });

// const StyledCalendarIcon = styled(CalendarIcon)`
//   .cls-1{
//     fill:#fff;opacity:0
//   }
  
//   .cls-2{
//     fill:#707070;
//     fill-rule:evenodd
//   }
  
// `;

// export default function MODateTimePicker(props) {
//   let styles = custom_styles(props);

//   let min_date = new Date();


//   if(props.hasOwnProperty('minDate')) {
//     min_date = props.minDate;
//   }

//   return (
//     <div className={styles.container} >
//       <span className={styles.label}>{props.label}</span>
//       <div className={styles.selector_wrapper}>
//         <ButtonBase  className={styles.selector_button_base} onClick={props.onClick}>
//           <div className={styles.selector_first_flex}>
//             <StyledCalendarIcon />
//             <span className={styles.text}>{props.text}</span>
//           </div>
//         </ButtonBase>
//       </div>
//       <div style={{display: "none"}}>
//         <MuiPickersUtilsProvider utils={DayUtils}>

//         <DateTimePicker
//           open={props.open}
//           label="Basic example"
//           value={props.value}
//           minDate={min_date}
//           onChange={props.handleDateChange}
//           animateYearScrolling
//           onClose={props.onClose}
//         />
//         </MuiPickersUtilsProvider>
//       </div>

//     </div>
//   )

// }




















// import React from 'react';
// import styled from 'styled-components';
// import {makeStyles} from "@material-ui/styles";
// import ButtonBase from "@material-ui/core/ButtonBase";
// import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
// import DayUtils from '@date-io/dayjs';
// import CalendarIcon from "./MDLIcon/icons/Calendar";

// const custom_styles = makeStyles({
//   text: {
//     fontFamily: "Montserrat",
//     fontWeight: 600,
//     paddingLeft: 5,
//     fontSize: "0.7rem",
//     color: "#707070",
//     overflow: "hidden",
//     textOverflow: "ellipsis",
//     whiteSpace: "nowrap",
//     maxWidth: "170px",
//     display: "block"
//   },
//   container: {
//     display: 'flex',
//     flexDirection: "column"
//   },
//   textField: {
//     marginLeft: "10px",
//     marginRight: "10px",
//   },
//   dense: {
//     marginTop: 16,
//   },
//   menu: {
//     width: 200,
//   },
//   label: {
//     color: "#707070",
//     fontSize: "0.75rem",
//     fontFamily: "Montserrat",
//   },
//   selector_wrapper:{
//     border: "0.5px solid #dde0e2",
//     width: "200px",
//     maxWidth: "100%",
//     height: "28px"
//   },
//   selector_button_base: {
//     width: "100% !important",
//     height: "100% !important",
//     display: "flex !important",
//     justifyContent: "space-between !important",
//     alignItems: "center !important"
//   },
//   selector_first_flex: {
//     display: "flex",
//     alignItems: "center",
//     paddingLeft: "4px"
//   },
//   selector_second_flex: {
//     display: "flex",
//     alignItems: "center"
//   },
//   expand_more: {
//     fontSize: "0.9rem !important",
//     fill: "#f4511e !important",
//     paddingRight: "8px !important"
//   }
// });

// const StyledCalendarIcon = styled(CalendarIcon)`
//   .cls-1{
//     fill:#fff;opacity:0
//   }
  
//   .cls-2{
//     fill:#707070;
//     fill-rule:evenodd
//   }
  
// `;

// export default function MODateTimePicker(props) {
//   let styles = custom_styles(props);

//   let min_date = new Date();


//   if(props.hasOwnProperty('minDate')) {
//     min_date = props.minDate;
//   }

//   return (
//     <div className={styles.container} >
//       <span className={styles.label}>{props.label}</span>
//       <div className={styles.selector_wrapper}>
//         <ButtonBase  className={styles.selector_button_base} onClick={props.onClick}>
//           <div className={styles.selector_first_flex}>
//             <StyledCalendarIcon />
//             <span className={styles.text}>{props.text}</span>
//           </div>
//         </ButtonBase>
//       </div>
//       <div style={{display: "none"}}>
//         <MuiPickersUtilsProvider utils={DayUtils}>

//         <DateTimePicker
//           open={props.open}
//           label="Basic example"
//           value={props.value}
//           minDate={min_date}
//           onChange={props.handleDateChange}
//           animateYearScrolling
//           onClose={props.onClose}
//         />
//         </MuiPickersUtilsProvider>
//       </div>

//     </div>
//   )

// }