import React from 'react'

//components
import Loader from '../custom-styled/loader'
// import "./index.styles.css"

// //custom-animation-loader
// const LoadingContainer = () => {
//   return (
//     <div className='loader' >
//      <div className='loader-bar'></div>
//      <div className='loader-bar'></div>
//      <div className='loader-bar'></div>
//      <div className='loader-bar'></div>
//      <div className='loader-bar'></div>
//      <div className='loader-bar'></div>
//      <div className='loader-bar'></div>
//      <div className='loader-bar'></div>
//     </div>
//   )
// }

const LoadingContainer = () => {
  let container = {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'red !important',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 99999999
  }
  return (
    <div style={container} >
      <Loader />
    </div>
  )
}

export default LoadingContainer
