import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    interactionsMainContainer: {
        padding: '0rem 2.5rem',
        paddingTop: '1rem',
        height: 'calc(100% - (4.5rem + 3.8rem))',
        overflowY: 'scroll',
        overflowX: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.4rem',
        backgroundColor : 'rgb(149, 152, 154, 0)',
        '&::-webkit-scrollbar': {
            width: '2px',

        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px'
        }
    },
    btnBox: {
        display: 'flex',
        gap: '1rem',
        padding: '1rem 1.5rem',
        height : '4.5rem',
        backgroundColor : 'rgb(149, 152, 154, 0)',
        '& .MuiButton-contained:hover': {
            backgroundColor: 'rgb(244, 81, 30, 0.9)',
            borderColor: 'rgb(244, 81, 30, 0.9)',
            color: '#fff'
        }
    },
    cardsBox: {
        display: 'flex',
        gap: '1.7rem',
        margin: '0.5rem 0rem'
    },
    lottieBox: {
        width: '45%',
        cursor: 'pointer',
        position: 'relative',
        '& .fa-solid': {
            fontSize : '0.8rem',
            color : '#15B725',
            position: 'absolute',
            top : '0.2rem',
            right : '0.2rem',
            zIndex: 1
        }
    },
}));

export default useStyles;
