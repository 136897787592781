import { makeStyles } from "@mui/styles";




const useStyles = makeStyles(() => ({
    wrapper: {
        overflow: 'auto',
        border: '1px solid #70707050',
        maxHeight: '30rem',
        minHeight: '30rem',
        minWidth: '82rem',
        maxWidth: '100%',
        '&::-webkit-scrollbar': {
            height: '7px',
            width: '7px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5411e',
            borderRadius: '6px',
        }
    },
    disableScroll: {
        overflow: 'hidden !important'
    },
    loaderBox: {
        minHeight: 'calc(2.4rem*10)',
        padding: '1rem',
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > div': {
            // backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
        '& > h1': {
            fontFamily: 'Montserrat',
            color: "#f4511e",
            fontSize: "1rem",
            fontWeight: 700,
        }
    },
    Title: {
        // display: "flex",
        // alignItems: "center",
        fontFamily: 'Montserrat',
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: "Bolder",
        lineSpacing: "155"
    },
    Help_Icon: {
        color: "#F4511E",
        fontSize: "1rem",
        marginLeft: "0.5rem"
    },
    Table: {
        backgroundColor: "#fff",
        width: "100%",
        borderSpacing: "0px",
        borderRadius: "2px",
    },
    Table_Heading: {
        color: "#1D1D1D",
        fontSize: "0.8rem",
        height: '2.8rem',
        textAlign: 'start',
        '& > th': {
            padding: '0 0.5rem',
            backgroundColor: "#f9f9f9",
            borderBottom: '1px solid #70707050',
            borderRight: '1px solid #70707050',
            fontFamily: 'Montserrat',
            fontWeight: 800,
            minWidth: '150px',
            textWrap: 'nowrap',
            textTransform: 'capitalize',
            textAlign: 'center',
        }
    },
    Table_Data: {
        borderRadius: "2px",
        color: "#707070",
        fontSize: "0.8rem",
        height: '2.8rem',
        textAlign: 'start',
        '& > td': {
            fontFamily: 'Montserrat',
            fontWeight: 500,
            border: '1px solid #cccccc30',
            textWrap: 'nowrap',
            textAlign: 'center'
        },
        '&:nth-child(even)': {
            backgroundColor: '#f9f9f9'
        }
    },
    SemiBold_Font: {
        fontWeight: "600",
    },
    iconBox: {
        '& > img:hover': {
            height: '1rem',
            margin: '0 0.4rem',
            filter: 'invert(34%) sepia(70%) saturate(2053%) hue-rotate(351deg) brightness(99%) contrast(93%)'
        }
    },
    Icons: {
        margin: "0 0.5rem",
        cursor: "pointer",
        height: '0.8rem',
    },
    Tooltip: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px"
    },
    Tooltiptext: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px"
    },
    leftIcon: {
        cursor: "pointer",
        "&:hover": {
            background: "#d5d5d5",
            borderRadius: "2rem"
        }
    },
    paginationBox: {
        color: "#F4511E",
        padding: "0.6rem",
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "center",
        '& > h5': {
            color: "rgb(0,0,0,0.8)",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: 600,
            marginRight: '1rem'
        },

    },
    emptyTable: {
        minHeight: '420px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "rgb(112, 112, 112)",
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 500,
        '& > .MuiButtonBase-root': {
            height: '40px',
            width: '170px',
            border: '1px solid #f4511e',
            backgroundColor: '#f4511e',
            color: '#fff',
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: 700,
            letterSpacing: 1
        }
    },
    selectRowsBox: {
        color: "rgb(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontSize: "0.8rem",
        fontWeight: 600,
        marginRight: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.7rem',
        '& .MuiFormControl-root': {
            minWidth: '1rem',
            margin: 0,
            '& .MuiInputBase-root': {
                lineHeight: 0,
                padding: 0,
                '& > svg': {
                    fontSize: '1.5rem',
                    color: '#f4511e',
                    right: '0.5rem'
                },
                '& > fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0)'
                },
                '& .MuiSelect-select': {
                    lineHeight: 0,
                    padding: '0.3rem',
                    display: 'flex',
                    width: '3rem',
                    alignItems: 'center',
                    color: '#f4511e',
                    fontFamily: "Montserrat",
                    fontSize: "0.8rem",
                    fontWeight: 600
                }
            }
        }
    },
    btnCell: {
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
        // height: '2.8rem',
        '& > span': {
            // display: 'flex',
            // justifyContent: 'center',
            minWidth: '100px',
            padding: '0.5rem 0.7rem',
            borderRadius: '4px',
            fontSize: '0.7rem',
            fontFamily: 'Montserrat',
            fontWeight: 600
        }
    },
    successBtnCell: {
        '& > span': {
            color: '#43A047',
            backgroundColor: '#ECF5EC',
        }
    },
    errorBtnCell: {
        '& > span': {
            color: '#FF0000',
            backgroundColor: '#FFE5E5',
        }
    },
    inProgressBtnCell: {
        '& > span': {
            color: '#FFB300',
            backgroundColor: '#FFF7E5',
        }
    },
    linearProgress: {
        backgroundColor: '#F2F2F2',
        '& span': {
            backgroundColor: '#f4511e'
        },
        '& .MuiLinearProgress-bar1': {
            backgroundColor: '#f4511e'
        }
    },
    typography: {
        color: '#f4511e',
        fontSize: '1.2rem',
        fontFamily: 'Montserrat',
        fontWeight: 600
    }
}))
export default useStyles