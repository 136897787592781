import React from "react";

// mui
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";

//utils
import useQuery from "../../utils/useQuery";

//constants
import constants from "../../constants";



const SelectChallenge = (props) => {
    const {
        isOpen = false,
        handleClose
    } = props;

    const classes = useStyles();
    const userType = localStorage.getItem("user-type");
    let roles = localStorage.getItem('roles') || [];
    const query = useQuery();
    let companyId = query.get("c_id");

    const _hasChallengeAccess = (element) => {
        if (userType === "superAdmin") {
            return true
        } else {
            switch (element.challengeName) {
                case "LA":
                    return !constants.RESTRICT_LA_FOR.includes(companyId)
                case "GBA":
                    return !constants.RESTRICT_GBA_FOR.includes(companyId)
                case "SL":
                    return roles.includes("SPOTLIGHT_CREATION");
                case "SCORM":
                    return roles.includes("CREATE_SCORM");
                case "QR_CODE":
                    return false;
                default:
                    return false
            }

        }
    }

    const getEnabledChallengeCount = () => {
        let count = 0;
        constants.CHALLENGE_DATA.forEach(ele => {
            if (_hasChallengeAccess(ele)) count++
        })
        return count;
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '60rem',
        minWidth: '30rem',
        width: getEnabledChallengeCount() > 3 ? '100%' : 'auto',
        maxHeight: '29rem',
        height: "auto",
        // height: "100%",
        bgcolor: 'background.paper',
        border: 'none !important',
        outline: 'none !important',
        boxShadow: 24,
        border: 'none',
        overflow: 'hidden',
        // p: 3,
        borderRadius: '5px',
        '& .MuiTypography-root': {
            fontFamily: 'montserrat'
        }
    }


    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            sx={{
                '& > .MuiBackdrop-root ': {
                    backgroundColor: 'rgb(255 255 255 / 70%)',
                    backdropFilter: 'blur(4px)'
                }
            }}
        >
            <Box sx={style}>
                <div className={classes.titleBox}>
                    <img onClick={handleClose} src='/images/gba-assets/left-arrow.svg' alt="back-btn" />
                    <h1 className={classes.title}>SELECT A CHALLENGE TYPE</h1>
                </div>
                <div className={classes.row2}>
                    {
                        constants.CHALLENGE_DATA.map((ele, index) => {
                            if (_hasChallengeAccess(ele)) {
                                return (
                                    <div key={index} onClick={() => { handleClose(index, ele) }} className={classes.challengeBox}>
                                        <img src={ele.imgUrl} alt='icon' />
                                        <div className={classes.challengeNameDetail}>
                                            <h1>{ele.displayName}</h1>
                                            <p>
                                                {ele.detail}
                                            </p>
                                        </div>
                                    </div>
                                )
                            } else return null
                        })
                    }
                </div>
            </Box>
        </Modal>
    )
}

export default SelectChallenge;

const useStyles = makeStyles((theme) => ({


    titleBox: {
        // border: '1px solid black',
        width: '100%',
        height: '4rem',
        display: 'flex',
        alignItems: 'center',
        gap: '2rem',
        padding: '0 2rem',
        '& > img': {
            cursor: 'pointer',
            height: '1.5rem',
            width: '1.5rem',
            transition: '.5s',
            '&:hover': {
                scale: '1.2'
            }
        },
    },
    title: {
        color: '#484848',
        fontSize: '1.2rem',
        fontWeight: 'Bold',
        fontFamily: 'Montserrat'
    },
    row2: {
        // border: '1px solid black',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        minHeight: 'calc(100% - 4rem)',
        maxHeight: '30rem',
        padding: '1rem',
        flexDirection: 'column',
        flexWrap: 'wrap',
        "&::-webkit-scrollbar": {
            width: '8px'
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#fff",
        },
        "&::-webkit-scrollbar-thumb": {
            // boxShadow: 'inset 0 0 6px rgba(112, 112, 112, 0.5)',
            borderRadius: "4px",
            backgroundColor: '#f4511e'
        }
    },
    challengeBox: {
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'center',
        // alignItems: 'center',
        // border: '1px solid black',
        gap: '1rem',
        height: '100%',
        maxHeight: '8rem',
        maxWidth: '28rem',
        width: '100%',
        cursor: 'pointer',
        '& > img': {
            margin: '0.5rem',
            padding: '0.5rem',
            width: '6rem',
            aspectRatio: 1,
            border: '1.5px solid rgb(244, 81, 30, 0.6)',
            borderRadius: '6px'
            // height: '65%'
        },
        '&:hover': {
            // backgroundColor: 'rgb(204, 204, 204, 0.2)',
            borderRadius: '4px',
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
            '& > img': {
                backgroundColor: '#fff'
            }
        }
    },
    challengeNameDetail: {
        width: '100%',
        maxWidth: 'calc(100% - 8rem)',
        display: 'flex',
        gap: '0.5rem',
        flexDirection: 'column',
        paddingTop: '0.8rem',
        '& h1': {
            // margin: '0.5rem 0',
            fontSize: '0.9rem',
            fontWeight: 'Bold',
            fontFamily: 'Montserrat'
        },
        '& p': {
            color: '#707070',
            fontSize: '0.7rem',
            fontWeight: '500',
            fontFamily: 'Montserrat',
            lineHeight: '1.3rem'
        }
    }
}));