import React from 'react'

//mui
import Button from '@mui/material/Button';

//styles
import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
    // mainCustomContext : {
    //     borderBottom : '1px solid rgb(184, 184, 184, 0.4)',
    //     paddingBottom: '1.5rem'
    // },
    row1: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '0.3rem',
        '& label': {
            width: '15%'
        },

    },
    radioGroup: {
        '& .MuiFormControlLabel-root': {
            margin: '0 0.8rem',
            '& .MuiTypography-root': {
                color: 'rgb(112, 112, 112, 0.8)',
                fontFamily: "Montserrat",
                fontSize: "0.69rem",
                fontWeight: "600",
            },
            '& .MuiButtonBase-root': {
                padding: '0.4rem',
                margin: '0 0.3rem'
            }
        }
    },
    main: {
        margin: '0.5rem 0rem',
        width: '100%',
        border: '1.5px solid rgb(184, 184, 184, 0.5)',
        borderRadius: '2px',
        display: 'flex',
        backgroundColor: '#FFFFFF',
        // padding : '1rem'
    },
    column1: {
        width: '89%',
        display: 'flex',
        alignItems: 'center',
        margin: '0.7rem 0.6rem',
        backgroundColor: '#FFFFFF',
        color: 'rgb(112, 112, 112, 0.9)',
        fontFamily: "Montserrat",
        fontSize: "0.8rem",
        fontWeight: "500",
    },
    column2: {
        backgroundColor: '#FFFFFF',
        width: '15%',
        // padding : '0.4rem',

        '& .MuiButtonBase-root': {
            // lineheight: '1.8rem',
            boxShadow: 'none',
            color: '#F4511E',
            backgroundColor: '#f1f1f1',
            width: '100%',
            height: '100%',
            fontWeight: '500',
            fontSize: '0.5rem',
            fontFamily: 'Open Sans',
            padding: 0,
            borderRadius: 0,
            border: 'inherit',
            '& img': {
                marginRight: '0.5rem'
            },
            '&:hover': {
                backgroundColor: '#DDE0E2',
            }
        }
    },
    warning: {
        paddingLeft: '0.2rem',
        color: "#E37265",
        fontWeight: '600',
        fontSize: '0.7rem',
        fontFamily: 'Open Sans',
    }
}));

let imgWarningText = 'Max file size: 2 MB | Formats supported: JPG, PNG, SVG, GIF'

const FileuploadInput = ({
    multiple = false,
    isDisabled = false,
    fileName = 'No file chosen',
    accept = 'image/*',
    withWarning = false,
    warningText = imgWarningText,
    handleChange }) => { //for image : accept='image/*', audio : accept='audio/*'
    const classes = useStyles();
    return (
        <>
            <div className={classes.main} style={{ backgroundColor: isDisabled ? 'rgba(239, 239, 239, 0.3)' : '' }}>
                <div className={classes.column1} style={{ backgroundColor: isDisabled ? 'rgba(239, 239, 239, 0.3)' : '' }}>
                    {fileName}
                </div>
                <div className={classes.column2}>
                    <Button variant="contained" component="label" >
                        <img src='/images/add-microskill/folder.svg' />
                        <span style={{ fontSize: '0.6rem', fontWeight: 700 }}>BROWSE</span>
                        <input hidden accept={accept} type="file" onChange={handleChange} multiple={multiple} />
                    </Button>
                </div>
            </div>
            {
                withWarning ?
                    <div className={classes.warning}>
                        {warningText}
                    </div> : null
            }
        </>
    )
}

export default FileuploadInput;
