import React, { useEffect, useState, useRef, useContext } from 'react'
import html2canvas from "html2canvas";

//components
import CustomButton from '../../../custom-styled/button';
import EditableTitle from '../rtf-title';
import EditableBody from '../rtf-body';
import SuggestedOrUpload from '../suggested-or-upload';

//utils
import commonUtil from '../../../../utils/commonUtil';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';

const TitleBody = ({
    customAspectRatio = true,
    handleSaveCard,
    isEdit,
    editCardData,
    handleCancel,
    handleUpdateCard,
    titleBody,
    setTitleBody,
    handeleTitleChange,
    handleBodyChange,
    handleTitleClick,
    handleBodyClick,
    toolbar,
    setToolbar,
    laBgImgData,
    handleChangeLaBgImg
}) => {
    const styles = commonStyles();
    const [globalStates] = useContext(GlobalStates)
    let capture = useRef();
    let titleRef = useRef(null);
    let bodyRef = useRef(null);

    useEffect(() => {
        setToolbar({ title: false, body: false })
        if (isEdit && editCardData !== null) {
            let card = editCardData.card;
            let title = commonUtil.getTitleFromContents(card.contents);
            let body = commonUtil.getBodyFromContents(card.contents);
            // titleRef.current.value = title;
            // bodyRef.current.value = body;
            setTitleBody({ title: title, body: body });
        }else {
            setTitleBody({ title: '', body: '' })
          }
    }, [editCardData])



    const captureScreenshot = async () => {
        if (!titleBody.title.length) {
            globalStates.handleToast(true, 'Please enter the Title', 'error')
            console.log('Title is Empty');
            return
        }
        if (!titleBody.body.length) {
            globalStates.handleToast(true, 'Please enter the Body text', 'error')
            console.log('Body is Empty');
            return
        }


        if (laBgImgData?.bgImg) {
            var backgroundColorDiv = document.createElement("div");
            // Set the background color on the additional div
            backgroundColorDiv.style.backgroundColor = 'rgba(0,0,0,0.5)';
            backgroundColorDiv.style.position = 'absolute';
            backgroundColorDiv.style.top = '0';
            backgroundColorDiv.style.left = '0';
            backgroundColorDiv.style.width = '100%';
            backgroundColorDiv.style.height = '100%';
            backgroundColorDiv.style.zIndex = -1;

            // Append the additional div to the container
            capture.current.appendChild(backgroundColorDiv);
        }

        // setCustomAspectRatio(!customAspectRatio)
        const canvas = await html2canvas(capture.current);
        if (laBgImgData?.bgImg) capture.current.removeChild(backgroundColorDiv);

        //CONVERTING CANVAS TO BASE64
        const imgData = canvas.toDataURL("image/png");
        // console.log("canvas to base64 = ", imgData);
        const myFile = commonUtil.base64toFile(imgData)

        let cardData = {
            id: 0,
            cardType: "CREATED",
            ratio: "4:3",
            title: titleBody.title,
            body: titleBody.body,
            thumbImageFile: myFile,
            thumbFileSize: myFile.size,
        }

        const type = 'TB'


        if (isEdit) {
            cardData.cardId = editCardData.id;
            handleUpdateCard(type, cardData)
            return
        }

        handleSaveCard(type, cardData);
        return
    }

    let textColor = laBgImgData?.bgImg ? '#fff' : 'rgb(0, 0, 0, 0.5)';
    let placeholderColor = laBgImgData?.bgImg ? 'rgb(255, 255, 255, 0.7)' : 'rgb(0, 0, 0, 0.2)';

    return (
        <>
            <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}
                style={{
                    backgroundImage: `url(${laBgImgData.bgImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundBlendMode: laBgImgData?.bgImg && 'multiply',
                    backgroundColor: laBgImgData?.bgImg && 'rgb(0,0,0,0.5)',
                }}
            >
                <EditableTitle
                    ref={titleRef}
                    minHeight={'3rem'}
                    maxHeight={'6rem'}
                    handeleTitleChange={handeleTitleChange}
                    value={titleBody.title}
                    handleTitleClick={handleTitleClick}
                    showTitleToolbar={toolbar.title}
                        textColor={textColor}
                        placeholderColor={placeholderColor}
                />
                <EditableBody
                    ref={bodyRef}
                    minHeight={'28rem'}
                    maxHeight={'28rem'}
                    handleBodyChange={handleBodyChange}
                    value={titleBody.body}
                    handleBodyClick={handleBodyClick}
                    showBodyToolbar={toolbar.body}
                    textColor={textColor}
                    placeholderColor={placeholderColor}
                />
                {true ?
                    <div className={styles.suggestionBox}>
                        <SuggestedOrUpload
                            title={'Background Image:'}
                            handleChange={handleChangeLaBgImg}
                        />
                    </div> : null}
            </div>

            {true && <div className={styles.btnBox}>
                <CustomButton
                    btnText={'SAVE'}
                    bgcolor={'#F4511E'}
                    textColor={'#FFF'}
                    fw={800}
                    ls={1}
                    handleClick={captureScreenshot.bind(this)}
                // handleClick={() => setCustomAspectRatio(!customAspectRatio)}
                />
                {isEdit && <CustomButton
                    btnText={'CANCEL'}
                    bgcolor={'#F4511E'}
                    textColor={'#FFF'}
                    fw={800}
                    ls={1}
                    handleClick={handleCancel}
                />}
            </div>}
        </>
    )
}

export default TitleBody;
