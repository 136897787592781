import React from 'react'

import { makeStyles } from "@mui/styles";


import constants from '../../../constants';


const CustomTextArea = ({
    handleChange , 
    value='', 
    exceedText, 
    isExceeded=false,
    isEmpty = false,
    fs='1rem', 
    warningTextFs="0.54rem", 
    p='0.8rem',
    pb='0.8rem'}) => {
    const classes = useStyles();
    return (
        <div className={`${classes.box}  ${isExceeded || isEmpty ? classes.error : ''}`} style={{padding: p, paddingBottom: pb}}>
            <textarea value={value} style={{fontSize: fs,}} className={classes.customTextarea} onChange={handleChange} />
            <h6 style={{fontSize: warningTextFs,}}>{exceedText} </h6>
        </div>
    )
}

export default CustomTextArea

// borderColor : isExceeded?  '#EB3917' : '#ccc', 
const useStyles = makeStyles(() => ({
    box: {
        outline: '1px solid #ccc',
        borderRadius: '4px',
        height: '100%',
        width: '100%',
        // padding: '0.8rem',
        position : 'relative',
        '& textarea':{
            height: '95%',
        },
        '& h6': {
            position: 'absolute',
            bottom: '0.3rem',
            right: '0.4rem',
            color: "#EB3917",
            fontFamily: "Montserrat",
            // fontSize: "0.54rem",
            fontWeight: "700",
        },
        '&:focus-within': {
            outline: '2px solid #4F0060',
        },
        '&:hover': {
            outline: '2px solid #4F0060',
        },
        // '&:focus' :{
        //     outline : '1px solid #f4511e'
        // }
    },
    error:{
        outline: '2px solid #EB3917 !important',
        '&:hover': {
            outline: '2px solid #EB3917 !important',
        },
    },
    customTextarea: {
        resize: 'none',
        height: '100%',
        width: '100%',
        color: "rgb(112, 112, 112)",
        fontFamily: "Montserrat",
        // fontSize: "1rem",
        fontWeight: 500,
        border : 'none',
        overflow: 'hidden',
        // outline : 'none',
        '&:focus': {
            outline: 'none'
        }
    }
}));