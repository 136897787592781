import React from 'react'

//mui
import AddIcon from '@mui/icons-material/Add';

//components
import CustomSelect from '../../../custom-styled/custom-select'

//utils
import commonUtil from '../../../../utils/commonUtil'

//styles
import useStyles from '../../index.styles';

const Step4 = ({
    linkedContent,
    setLinkedContent,
    setStep2,
    setStep3,
    setLaunchType,
    microskills,
    microskillsObject,
    selectedMicroskills,
    setSelectedMicroskills,
    _selectMicroskill,
    forms,
    formsObject,
    selectedForms,
    setSelectedForms,
    _selectForm
}) => {
    const classes = useStyles();


    const chooseLinkedContent = (item) => {
        console.log('chooseLinkedContent item = ', item);
        if (linkedContent.includes(item.id)) {
            setLinkedContent([]);
            setSelectedMicroskills([]);
            setSelectedForms([]);
            return
        }
        // setLinkedContent([...linkedContent, item.id]); //for selecting multiple content
        setLinkedContent([item.id]);
        // setAddContent(false);
        // setStep2(false);
        // setStep3(false);
        // setLaunchType([]);
        setSelectedMicroskills([]);
        setSelectedForms([]);
        // setSelectedGroups([]);
    }

    return (
        <div style={{ minHeight: '250px', marginBottom: linkedContent.length ? '1rem' : '' }}>
            <h2 style={{
                fontSize: '1.2rem',
                fontFamily: "Montserrat",
                color: "#707070",
                padding: '1rem 0rem',
                paddingTop: '1.5rem',
                borderTop: "1px solid rgb(112, 112, 112, 0.3)",
            }}>Step 4: Link Content</h2>
            {linkedContent.length ? <div className={classes.row} style={{ marginBottom: linkedContent.length ? '2rem' : '' }}>
                {
                    linkedContent.includes('Microskills') ?
                        <CustomSelect
                            defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Microskills', selected: selectedMicroskills, dataObject: microskillsObject })}
                            autoClose={true}
                            withSearch={true}
                            withSelectAllOption={false}
                            listData={microskills}
                            selected={selectedMicroskills}
                            handleSelect={_selectMicroskill}
                        /> : null
                }
                {
                    linkedContent.includes('Forms') ?
                        <CustomSelect
                            defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Form', selected: selectedForms, dataObject: formsObject })}
                            autoClose={true}
                            withSearch={true}
                            withSelectAllOption={false}
                            listData={forms}
                            selected={selectedForms}
                            handleSelect={_selectForm}
                        /> : null
                }
            </div> : null}
            <div className={classes.linkContentBtn}>
                <AddIcon /> Link Content
                <CustomSelect
                    hideBaseBtn={true}
                    defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Link Content', selected: [], })}
                    autoClose={true}
                    withSearch={false}
                    withSelectAllOption={false}
                    listData={[{ id: 'Microskills', name: 'Microskills' }, { id: 'Forms', name: 'Forms' }]}
                    selected={linkedContent}
                    handleSelect={chooseLinkedContent}
                />
            </div>
        </div>
    )
}

export default Step4
