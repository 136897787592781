import React, { useEffect, useState } from 'react'

//mui
import MuiRadio from "@mui/material/Radio";
import { Button, ButtonBase } from '@mui/material';

//components
import Label from '../../custom-styled/label';
import AutoCompleteSearchApi from '../../autocomplete-search-api';
import DragDropFiles from "../../drag-drop-files-uploader/index"
import UploadedFiles from '../uploadedFiles';

//services
import companiesServices from "../../../services/companies";
import projectServices from "../../../services/projects";
import managerServices from '../../../services/manager';

//utils
import isEmpty from '../../../utils/isEmpty';

//styles
import useStyles from './index.styles';
import useClasses from '../index.styles';

//constants
import { TABS } from '../index';

//validations
import { defaultValidationData, defaultValidationDataManager } from '../index';
import commonUtil from '../../../utils/commonUtil';
import { fas } from '@fortawesome/free-solid-svg-icons';


const Content = (props) => {
    const { compainesFilter, setCompaniesFilter, activeTab, setActiveTab } = props;
    let roles = localStorage.getItem('roles') || [];
    const userType = localStorage.getItem('user-type');
    const styles = useStyles();
    const classes = useClasses();
    const [userFlag, setUserFlag] = useState({
        reActivate: false,
        deActivate: false
    });

    const [activationFlag, setActivationFlag] = useState(true)
    const [deActivationFlag, setDeactivationFlag] = useState(false)

    setCompaniesFilter(localStorage.getItem('companyId'));


    const handleDeactivateClick = () => {
        setDeactivationFlag(!deActivationFlag)
        setActivationFlag(false)
        setActiveTab(TABS.deActivate)
        console.log("Deactive");
    }

    const handleReactivateClick = () => {
        setActivationFlag(!activationFlag)
        setDeactivationFlag(false)
        setActiveTab(TABS.reActivate)
        console.log("Reactive");
    };

    return (
        <div className={styles.ContentContainer} style={{ padding: "2rem 1.2rem", border: "0 0 1px 0 #707070" }}>
            <h1 style={{
                fontSize: '1.2rem',
                color: "#707070",
                fontFamily: "Montserrat",
                fontWeight: 700,
            }}>
                Step 1: Action
            </h1>
            <div className={styles.RadioContainer}>

                <div >
                    <Radio id="reactivate" isChecked={activationFlag} type="radio" handleClick={handleReactivateClick} />
                    <Label labelText={"Reactivate User"} htmlFor='reactivate' fs='1rem' />

                </div>

                <div >
                    <Radio id="deactivate" isChecked={deActivationFlag} type="radio" handleClick={handleDeactivateClick} />
                    <Label labelText={"Deactivate User"} htmlFor='deactivate' fs='1rem' />

                </div>

            </div>

        </div>
    )
}

export default Content


const Radio = ({ id, type, name, handleClick, isChecked }) => {
    return (
        <MuiRadio
            id={id}
            name={name}
            type={type}
            onChange={handleClick}
            checked={isChecked}
            sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.4rem", color: "#F4511E", padding: "0", margin: "0" },
            }}
        />
    );
};