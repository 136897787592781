import React, { useEffect, useContext, useState } from 'react'

//mui
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

//components
import Title from '../title'
import SearchBox from '../search-box';
import CustomButton from '../custom-styled/button';
import SuccessDialog from '../success-dialog';
import FailureDialog from '../failure-dialog';
import LoadingContainer from '../loading-container';

//services
import companiesServices from '../../services/companies';
import groupServices from '../../services/group';
import microskillServices from '../../services/microskill';

//utils
import commonUtil from '../../utils/commonUtil';
import useRouter from '../../utils/useRouter';

//styles
import useStyles from './index.styles';

//global-states
import { GlobalStates } from '../../App';


const CopyMicroskill = () => {

    const classes = useStyles();
    const router = useRouter();
    const [globalStates, setGlobalStates] = useContext(GlobalStates);
    const [active, setActive] = useState(null);
    const [dialogContent, setDialogContent] = useState('');
    const [successDialog, setSuccessDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [oldCompanyGroups, setOldCompanyGroups] = useState([]);
    const [microskills, setMicroskills] = useState([]);
    const microskillsObject = commonUtil.objectify(microskills, 'id');
    const companiesObject = commonUtil.objectify(companies, 'id');
    const oldCompanyGroupsObject = commonUtil.objectify(oldCompanyGroups, 'id');
    const [oldCompanyId, setOldCompanyId] = useState([]);
    const [oldCompanyGroupId, setOldCompanyGroupId] = useState([]);
    const [selectedMicroskills, setSelectedMicroskills] = useState([]);
    const [newCompanyId, setNewCompanyId] = useState([]);
    const [newCompanyGroups, setNewCompanyGroups] = useState([]);
    const newCompanyGroupsObject = commonUtil.objectify(newCompanyGroups, 'id');
    const [newCompanyGroupId, setNewCompanyGroupId] = useState([]);

    useEffect(() => {
        const userType = localStorage.getItem('user-type');
        if (userType === 'superAdmin') {
            setGlobalStates({ ...globalStates, appBarTitle: 'Microskills Copy' });
            fetchCompanies();
        } else {
            router.history.goBack();
        }

        return () => {
            setGlobalStates({ ...globalStates, appBarTitle: 'Master-O Studio' });
        }
    }, [])

    useEffect(() => {
        if (oldCompanyId.length) {
            fetchCompanyGroups(oldCompanyId[0], 'old');
            fetchMicroskills(oldCompanyId[0])
        }

    }, [oldCompanyId])

    useEffect(() => {
        if (newCompanyId.length) {
            fetchCompanyGroups(newCompanyId[0], 'new');
        }

    }, [newCompanyId])

    const fetchCompanies = async () => {
        const response = await companiesServices.fetchCompanies();
        console.log('fetchCompanies response = ', response);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            })
            setCompanies(data);
            setIsLoading(false);
            // router.history.replace(`/manage-microskills/${data[0].id}/xx`);
        }
    }

    const fetchCompanyGroups = async (companyId, groupsFor) => {
        const response = await groupServices.fetchGroups(companyId);
        console.log('fetchCompanyGroups response = ', response);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            const activeGroups = data.filter(ele => ele.active === true);
            if (groupsFor === 'old') {
                setOldCompanyGroups(activeGroups);
                return
            }
            if (groupsFor === 'new') {
                setNewCompanyGroups(activeGroups);
                return
            }
        }
    }

    const fetchMicroskills = async (companyId) => {
        const response = await microskillServices.fetchMicroskills(companyId);
        console.log('fetchMicroskills response = ', response);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            const activeMicroskills = data.filter(ele => ele.active === true);
            const msWithGroups = data.filter(ele => {
                if (ele.GroupMicroskills.length) {
                    return ele
                }
            });
            console.log('with group ms = ', msWithGroups);
            setMicroskills(activeMicroskills);
        }
    }

    const handleSetActive = (name) => {
        console.log('handleSetActive name = ', name);
        if (name === active) {
            setActive(null);
            return
        }
        setActive(name);
    }



    const _selectOldCompany = (item) => {
        console.log('_selectOldCompany item = ', item);
        setOldCompanyId([item.id]);
        setActive(null);
        setOldCompanyGroups([]);
        setMicroskills([]);
        setOldCompanyGroupId([]);
        setSelectedMicroskills([]);
        return

    }

    const _selectOldGroup = (item) => {
        setOldCompanyGroupId([item.id])
        setActive(null);
    }

    const _selectNewGroup = (item) => {
        setNewCompanyGroupId([item.id])
        setActive(null);
    }

    const _selectNewCompany = (item) => {
        console.log('_selectNewCompany item = ', item);
        setNewCompanyId([item.id]);
        setNewCompanyGroups([])
        setNewCompanyGroupId([]);
        setActive(null);
        return

    }

    const runValidation = () => {
        let message = 'Something went wrong!';
        if (!selectedMicroskills.length) {
            message = 'Please select the microskills you want to copy.';
            setDialogContent(message);
            setErrorDialog(true);
            return false;
        }
        if (!newCompanyId.length) {
            message = 'Please select the company you want to copy microskill to.';
            if (selectedMicroskills.length > 1) message = 'Please select the company you want to copy microskills to.';
            setDialogContent(message);
            setErrorDialog(true);
            return false;
        }
        return true;
    }

    const handleCopy = async () => {
        let isValid = runValidation();
        if (!isValid) return
        setIsSaving(true);

        for (let i = 0; i < selectedMicroskills.length; i++) {
            let payload = {
                oldMicroskillId: selectedMicroskills[i],
                newCompanyId: newCompanyId[0],
                flag: 1
            }
            let response = await microskillServices.copyMicroskill(payload);
            console.log('handleCopy response = ', response);
            console.log('handleCopy selectedMicroskills = ', selectedMicroskills);
            console.log('handleCopy microskillsObject = ', microskillsObject);
            if (response.status === 200 || response.status === 201) {
                if (i === selectedMicroskills.length - 1) {

                    let message = 'Microskill Copied Successfully!';
                    if (selectedMicroskills.length > 1) message = 'Microskills Copied Successfully!';
                    setDialogContent(message);
                    setSuccessDialog(true);
                    handleClear();
                    setIsSaving(false);


                }
            } else {
                globalStates.handleToast(true, 'Internal Server Error!', 'error');
                setIsSaving(false);
                break;
            }

        }
        return
    }

    const handleClear = () => {
        setActive(null);
        setOldCompanyGroups([]);
        setMicroskills([]);
        setOldCompanyId([]);
        setOldCompanyGroupId([]);
        setSelectedMicroskills([]);
        setNewCompanyId([]);
        setNewCompanyGroups([])
        setNewCompanyGroupId([]);
    }

    if (isLoading) {
        return (
            <LoadingContainer />
        )
    } else {
        return (
            <div className={classes.mainContainer}>
                <Title titleText={' Copy from:'} fs='1.4rem' fw={800} />
                <div className={classes.row}>
                    <div className={classes.selectWrapper}>
                        <div
                            className={`${classes.select} ${active === 'old-company' ? classes.active : ''}`}
                            onClick={() => handleSetActive('old-company')}
                        >
                            {
                                oldCompanyId.length ?
                                    companiesObject[oldCompanyId[0]]?.name
                                    : 'Select Company'
                            }
                            <i className={`fa-solid ${active === 'old-company' ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                        </div>
                        {
                            active === 'old-company' &&
                            <div className={classes.selectBox}>
                                <SearchBox
                                    withSelectAllOption={false}
                                    listData={companies}
                                    selected={oldCompanyId}
                                    setSelected={setOldCompanyId}
                                    handleSelect={_selectOldCompany} />
                            </div>
                        }
                    </div>
                    <div className={classes.selectWrapper}>
                        <div className={`${classes.select} ${active === 'old-groups' ? classes.active : ''}`}
                            onClick={() => handleSetActive('old-groups')}
                        >
                            {
                                oldCompanyGroupId.length ?
                                    oldCompanyGroupsObject[oldCompanyGroupId[0]]?.name
                                    : 'Select Groups'
                            }
                            <i className={`fa-solid ${active === 'old-groups' ? 'fa-angle-up' : 'fa-angle-down'}`}></i></div>
                        {
                            active === 'old-groups' &&
                            <div className={classes.selectBox}>
                                <SearchBox
                                    withSelectAllOption={false}
                                    listData={oldCompanyGroups}
                                    selected={oldCompanyGroupId}
                                    setSelected={setOldCompanyGroupId}
                                    handleSelect={_selectOldGroup}
                                />
                            </div>
                        }
                    </div>
                    <div className={classes.selectWrapper}>
                        <div className={`${classes.select} ${active === 'old-microskills' ? classes.active : ''}`}
                            onClick={() => handleSetActive('old-microskills')}>

                            {
                                selectedMicroskills.length ?
                                    ((selectedMicroskills.length > 1) ?
                                        `${microskillsObject[selectedMicroskills[0]]?.name} & ${selectedMicroskills.length - 1} more.`
                                        : microskillsObject[selectedMicroskills[0]]?.name)
                                    : 'Select Microskill'
                            }

                            <i className={`fa-solid ${active === 'old-microskills' ? 'fa-angle-up' : 'fa-angle-down'}`}></i></div>
                        {
                            active === 'old-microskills' &&
                            <div className={classes.selectBox}>
                                <SearchBox
                                    withSelectAllOption={true}
                                    listData={microskills}
                                    selected={selectedMicroskills}
                                    setSelected={setSelectedMicroskills}
                                    withCustomFunction={false}
                                />
                            </div>
                        }
                    </div>
                </div>
                <Title titleText={' Copy to:'} fs='1.4rem' fw={800} />
                <div className={classes.row}>
                    <div className={classes.selectWrapper}>
                        <div className={`${classes.select} ${active === 'new-company' ? classes.active : ''}`}
                            onClick={() => handleSetActive('new-company')}
                        >
                            {
                                newCompanyId.length ?
                                    companiesObject[newCompanyId[0]]?.name
                                    : 'Select Company'
                            } <i className={`fa-solid ${active === 'new-company' ? 'fa-angle-up' : 'fa-angle-down'}`}></i></div>
                        {
                            active === 'new-company' &&
                            <div className={classes.selectBox}>
                                <SearchBox
                                    withSelectAllOption={false}
                                    listData={companies}
                                    selected={newCompanyId}
                                    setSelected={setNewCompanyId}
                                    handleSelect={_selectNewCompany}
                                />
                            </div>
                        }
                    </div>
                    <div className={classes.selectWrapper}>
                        <div
                            className={`${classes.select} ${active === 'new-groups' ? classes.active : ''}`}
                            onClick={() => handleSetActive('new-groups')}
                        >
                            {
                                newCompanyGroupId.length ?
                                    newCompanyGroupsObject[newCompanyGroupId[0]]?.name
                                    : 'Select Groups'
                            }
                            <i className={`fa-solid ${active === 'new-groups' ? 'fa-angle-up' : 'fa-angle-down'}`}></i></div>
                        {
                            active === 'new-groups' &&
                            <div className={classes.selectBox}>
                                <SearchBox
                                    withSelectAllOption={false}
                                    listData={newCompanyGroups}
                                    selected={newCompanyGroupId}
                                    setSelected={setNewCompanyGroupId}
                                    handleSelect={_selectNewGroup} />
                            </div>
                        }
                    </div>
                </div>

                <div className={classes.btnBox}>
                    {isSaving ?
                        <LoadingButton
                            loading
                            loadingPosition="start"
                            startIcon={<SaveIcon />}
                            variant="outlined"
                        >
                            Copying...
                        </LoadingButton>
                        : <CustomButton
                            handleClick={handleCopy}
                            variant='contained'
                            textColor='#fff'
                            bgcolor='#f4511e'
                            fs="0.6rem"
                            fw={800}
                            ls={1}
                            btnText='COPY'
                        />}
                    <CustomButton
                        handleClick={handleClear}
                        variant='outlined'
                        textColor='#f4511e'
                        borderColor='#f4511e'
                        bgcolor='#fff'
                        fs="0.6rem"
                        fw={800}
                        ls={1}
                        btnText='CLEAR'
                    />
                </div>
                {
                    successDialog &&
                    <SuccessDialog
                        successDialogOpen={successDialog}
                        setSuccessDialogOpen={setSuccessDialog}
                        dialogText={dialogContent}
                        handleClick={() => setSuccessDialog(false)}
                        buttonText='Okay'
                    />
                }
                {
                    errorDialog &&
                    <FailureDialog
                        errorDialogOpen={errorDialog}
                        setErrorDialogOpen={setErrorDialog}
                        dialogText={dialogContent}
                        handleClick={() => setErrorDialog(false)}
                    />
                }
            </div>
        )
    }



}

export default CopyMicroskill
