import React, {useContext} from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import InProgressListItem from '../in-progress-list-item';

//global-states
import { GlobalStates } from '../../../../App';

const ImportingList = ({ importingList, progress, setProgress, imported, setImported, setLoadingCard, setPreview, setSelectedCard, selectedColumns, setSelectedColumns }) => {
    const classes = useStyles();
    const  [globalStates, setGlobalStates] = useContext(GlobalStates);
    React.useEffect(() => {
        if (imported) {
            console.log('ImportingList = ', importingList);
            const timer1 = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 100 : (prevProgress + Math.floor(Math.random() * 10)) > 100 ? 100 : prevProgress + Math.floor(Math.random() * 10)));
            }, 100);

              const timer2 = setTimeout(() => {
                setPreview(importingList[0].thumbImage);
                setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' });
                setSelectedColumns({ ...selectedColumns, firstColumn: 'content' });
                console.log("time out");
            }, 4000)
            return () => {
                setProgress(0);
                clearInterval(timer1);
                clearTimeout(timer2);
                globalStates.handleToast(true, 'Successfully Imported', 'success');
                setImported(false);
                setLoadingCard(false);
                setPreview(importingList[0].thumbImage);
                setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' });
            };

            // const timer = setTimeout(() => {
            //     setPreview(importingList[0].thumbImage)
            //     setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' })
            //     setSelectedColumns({ ...selectedColumns, firstColumn: 'content' })
            //     console.log("time out");
            // }, 4000)
            // return () => {
            //     clearTimeout(timer)
        };

    }, [imported]);

    return (
        <div className={classes.mainContainer}>
            {importingList.map((ele, i) => (
                // ele.cardType==='Cover Card'?null:
                <InProgressListItem key={i} progress={progress} cardNumber={i + 1} fileSize={ele.thumbFileSize} />
            ))}
        </div>
    )
}

export default ImportingList





const useStyles = makeStyles(() => ({
    mainContainer: {
        height: 'calc(100% - 3.8rem)',
        backgroundColor: '#fff',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '8px',

        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px'
        }
    }
}));


