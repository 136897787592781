import React from 'react'

//styles
import useStyles from '../edit-content-section/index.styles';

const DefaultCard = ({ preview }) => {
    const classes = useStyles();
    return (
        <div className={classes.defaultCard}>
            <img src={preview} alt='' />
        </div>
    )
}

export default DefaultCard
