import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import CustomButton from '../../../../../custom-styled/button';


const GridCard = ({
    id,
    icon = '/images/documents/root-icons/folder.svg',
    name = 'Digital Marketing',
    detail = '53 files',
    withOptions = true,
    withButton = false,
    btnText = 'Restore',
    handleDocument,
    handleOptions,
    setMouseOverOptions,
    showOptions,
    handleDelete,
    _editClick }) => {
    const classes = useStyles();
    return (
        <div className={classes.gridCard} onClick={handleDocument}>
            {
                withOptions ?
                    <span
                        onMouseEnter={() => setMouseOverOptions(true)}
                        onMouseLeave={() => setMouseOverOptions(false)}
                        className={classes.optionsBtn}
                        onClick={() => handleOptions(id)}
                    >
                        <i className="fa-solid fa-ellipsis"></i>
                        {
                            showOptions ?
                                <div className={classes.optionsBox}>
                                    <div className={classes.option} onClick={_editClick}>
                                        <img src='/images/documents/edit-icon.svg'/><span>Edit Name</span>
                                    </div>
                                    {/* <div className={classes.option}>
                                        <img src='/images/documents/duplicate.svg' /><span>Copy</span>
                                    </div> */}
                                    <div className={classes.option} onClick={handleDelete}>
                                        <img src='/images/documents/delete-icon.svg' /><span>Delete</span>
                                    </div>
                                </div> : null
                        }
                    </span> :
                    null
            }
            <img src={icon} alt='' />
            <h5>{name}</h5>
            {
                withButton ?
                    <div className={classes.btnBox}>
                        <CustomButton
                            textColor='#ECD900'
                            borderColor='#FCF8DE'
                            bgcolor='#FCF8DE'
                            fs="0.6rem"
                            fw={700}
                            ls={0}
                            btnText={btnText}
                        />
                    </div> :
                    <h6>{detail}</h6>
            }
        </div>
    )
}

export default GridCard;


const useStyles = makeStyles(() => ({
    gridCard: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid rgb(112, 112, 112,0.5)',
        borderRadius: '5px',
        width: '15%',
        aspectRatio: 2 / 1.5,
        containerType: 'inline-size',
        cursor: 'pointer',
        // zIndex : 0,
        '& img': {
            height: '40cqi',
            width: '40cqi',
            objectFit: 'scale-down'
        },
        '& h5': {
            marginTop: '5cqi',
            color: "rgb(112, 112, 112,0.8)",
            fontFamily: "Montserrat",
            fontSize: "5cqi",
            fontWeight: 800,
            maxHeight: '7cqi',
            maxWidth: '74cqi',
            overflow: 'hidden',
            textAlign: 'center',
            // animation: '$slide 5s linear infinite'
            // padding: '0 9cqi'
        },
        
        '& h6': {
            marginTop: '5cqi',
            color: "rgb(112, 112, 112,0.5)",
            fontFamily: "Montserrat",
            fontSize: "4.5cqi",
            fontWeight: 700,
        },
        '&:hover': {
            border: '2px solid #f4511e',
        }

    },
    '@keyframes slide': {
        from : {
            transform: 'translateX(0)' /* Start position */
        },
        to: {
            transform: 'translateX(-100%)' /* End position */
        }
    },
    mouseOver: {
        zIndex: 9999999,
    },
    optionsBtn: {
        cursor: 'pointer',
        position: 'absolute',
        top: '5cqi',
        right: '5cqi',
        '& > i': {
            color: '#f4511e',
            // border : '1px solid',
            borderRadius: '50%',
            padding: '0.5cqi 1cqi',
            '&:hover': {
                backgroundColor: 'rgb(112, 112, 112,0.1)'
            }
        },
    },
    optionsBox: {
        // border: '1px solid',
        boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.2)',
        borderRadius: '5px',
        position: 'absolute',
        top: '12cqi',
        right: '-16cqi',
        height: '32cqi',
        width: '45cqi',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.1rem'
    },
    option: {
        display: 'flex',
        gap: '0.5rem',
        padding: '0 4cqi',
        alignItems: 'center',
        color: "rgb(112, 112, 112)",
        fontFamily: "Montserrat",
        fontSize: '5cqi',
        fontWeight: 700,
        height: '50%',
        userSelect: 'none',
        cursor: 'pointer',
        '& img': {
            height: '5cqi',
            width: '5cqi'
        },
        '&:hover': {
            backgroundColor: '#00000010'
        }
    },
    btnBox: {
        height: '13cqi',
        width: '36cqi',
        marginTop: '3cqi',
        // border: '1px solid',
        '& .MuiButtonBase-root': {
            borderRadius: '5px',
            height: '100%',
            width: '100%',
            textTransform: 'capitalize'
        }
    }
}));
