import React from 'react'
import { makeStyles } from "@mui/styles";
import { FileUploader } from "react-drag-drop-files";


const fileTypes = ["JPG", "PNG", "GIF", "JPEG"];

const DragDropFiles = ({
  title = 'Drag & Drop Cards Here To Import',
  handleChange,
  typesAccepted = fileTypes,
  withExternalLink = false,
  handleExternalLink,
  multiple = true,
  width = '100%',
}) => {
  const classes = useStyles();
  const [isDragover, setIsDragover] = React.useState(false);
  const [isExternalLink, setIsExternalLink] = React.useState(false);
  // const handleChange = (file) => {
  //   console.log('DragDropFiles = ', file);
  //   setFile(file);
  // };

  const handleonDragOverCapture = () => {
    // console.log('onDragOverCapture')
    if (isDragover) return
    setIsDragover(true)
  };
  const handleonDragLeaveCapture = () => {
    // console.log('onDragLeaveCapture')
    setIsDragover(false)
  };

  const handleOnDrop = () => {
    console.log('dropped');
    setIsDragover(false)
  }

  return (
    <div className={classes.main} style={{ background: isDragover ? 'rgb(239, 239, 239, 0.5)' : '#fff', width, }} onDragOverCapture={handleonDragOverCapture} onDragLeaveCapture={handleonDragLeaveCapture} onDropCapture={handleOnDrop}>
      <FileUploader handleChange={handleChange} name="file" types={typesAccepted} multiple={multiple} />
      <div className={classes.customDragDrop} style={{ borderColor: isDragover ? '#F4511E' : 'rgb(112, 112, 112, 0.3)' }}>
        <img src='/images/learning-aid-assets/upload.png' />
        {withExternalLink ?
          <input
            placeholder='Enter Your Video URL (Youtube/Vimeo)'
            className={`${classes.externalLinkInput} ${isExternalLink ? classes.captureExternalLinkInput : ''}`}
            onMouseEnter={() => setIsExternalLink(true)}
            onMouseLeave={() => setIsExternalLink(false)}
            onChange={handleExternalLink}
            type='text' /> :
          <h3>{title}</h3>}
        <h6 >or</h6>
        <h4>Browse files</h4>
        <h5>Supported types: {typesAccepted.join(", ")}</h5>
      </div>
    </div>
  );
}

export default DragDropFiles;



//  className={classes.mainContainer}

const useStyles = makeStyles(() => ({
  main: {
    height: '100%',
    // width: '100%',
    position: 'relative',
    minHeight: "10rem",
    '& label': {
      height: '100%',
      // position : 'relative',
      zIndex: '1',
      maxWidth: '100%',
      opacity: '0'
    },
    '&:hover': {
      backgroundColor: 'rgb(112, 112, 112, 0.01)'
    }
  },
  customDragDrop: {
    cursor: 'pointer',
    position: 'absolute',
    top: '0',
    // zIndex : '0',
    border: '2px dashed rgb(112, 112, 112, 0.3)',
    borderRadius: '7px',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // padding : '1rem 0.1rem',
    // paddingTop : '10%',
    '& img': {
      height: '1.3rem',
      width: '2rem',
    },
    '& h3,h6,h4,h5': {
      fontFamily: 'Montserrat',
      paddingTop: '0.7rem'
    },
    '& h3': {
      fontSize: '0.6rem',
      color: 'rgb(112, 112, 112, 0.9)'
    },
    '& h6': {
      fontSize: '0.57rem',
      color: 'rgb(112, 112, 112, 0.7)'
    },
    '& h4': {
      fontSize: '0.6rem',
      color: '#f4511e',
      textDecoration: 'underline'
    },
    '& h5': {
      fontSize: '0.6rem',
      color: 'rgb(112, 112, 112, 0.9)'
    },


  },
  externalLinkInput: {
    border: '1.4px solid #ccc',
    borderRadius: '4px',
    position: 'relative',
    zIndex: 99999,
    color: "rgb(112, 112, 112, 0.6)",
    // fontFamily: "Montserrat",
    fontSize: "0.7rem",
    fontWeight: "500",
    padding: '0.4rem',
    marginTop: '0.9rem',
    width: '82%'
  },
  captureExternalLinkInput: {
    border: '1px solid #f4511e',

  }
}));

// export default useStyles;
