import { makeStyles } from "@mui/styles";




const useStyles = makeStyles(() => ({
    wrapper: {
        overflowX: 'scroll',
        maxHeight: '55vh', //while freezing table header
        // minHeight: '55vh', //while freezing table header
        // maxHeight: '420px', //while freezing table header
        // minHeight: '420px', //while freezing table header
        // height: '57vh',
        '&::-webkit-scrollbar': {
            height: '6px',
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f5411e',
            borderRadius: '6px',
        }
    },
    disableScroll: {
        overflow: 'hidden !important'
    },
    loaderBox: {
        minHeight: 'calc(2.4rem*10)',
        padding: '1rem',
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > div': {
            backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
        '& > h1': {
            // marginTop: '2rem',
            fontFamily: 'Montserrat',
            color: "#f4511e",
            fontSize: "1.5rem",
            fontWeight: 700,
        }
    },
    Title: {
        // display: "flex",
        // alignItems: "center",
        fontFamily: 'Montserrat',
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: "Bolder",
        lineSpacing: "155"
    },
    Help_Icon: {
        color: "#F4511E",
        fontSize: "1rem",
        marginLeft: "0.5rem"
    },
    Table: {
        backgroundColor: "#fff",
        width: "100%",
        borderSpacing: "0px",
        borderRadius: "2px",
    },
    Table_Heading: {
        // border: "1px solid rgb(112, 112, 112, 0.4)",
        color: "#484848",
        fontSize: "0.8rem",
        height: '3rem',
        textAlign: 'center',//table text alignment - this was center
        '& > th': {
            backgroundColor: "rgb(200, 200, 200, 0.5)",
            fontFamily: 'Montserrat',
            fontWeight: 800,
            // minWidth: '12rem',
            minWidth: '200px',
            textWrap: 'nowrap',
            textTransform: 'capitalize',
            textAlign: 'center',//table text alignment
            // paddingLeft: '2rem'//for center table text alignment
        }
    },
    Table_Data: {
        borderRadius: "2px",
        height: '50px',
        textAlign: 'center',//table text alignment - this was center
        '& > td': {
            textWrap: 'nowrap',
            // paddingLeft: '2rem',//for center table text alignment
            backgroundColor: '#fff',
            '& > h1': {
                transition: 'all 1.5s ease;',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                fontSize: "14px",
                // backgroundColor: 'green',
                color: "#ffffff",
                margin: '0px 2px',
                padding: '15px',
                borderRadius: "3px",
            }

        },
        // '&:nth-child(even)': {
        //     backgroundColor: '#f9f9f9'
        // }
    },
    SemiBold_Font: {
        fontWeight: "600",
    },
    iconBox: {
        '& > img:hover': {
            height: '1rem',
            margin: '0 0.4rem',
            filter: 'invert(34%) sepia(70%) saturate(2053%) hue-rotate(351deg) brightness(99%) contrast(93%)'
        }
    },
    Icons: {
        margin: "0 0.5rem",
        cursor: "pointer",
        height: '0.8rem',
    },
    Tooltip: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px" /* Use half of the width (120/2 = 60), to center the tooltip */
    },
    Tooltiptext: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px" /* Use half of the width (120/2 = 60), to center the tooltip */
    },
    leftIcon: {
        cursor: "pointer",
        "&:hover": {
            background: "#d5d5d5",
            borderRadius: "2rem"
        }
    },
    legendBox: {
        height: '50px',
        padding: "0.6rem 2rem",
        display: 'flex',
        gap: '1.4rem',
        justifyContent: "flex-end",
        alignItems: "center",
    },
    scaleDown: {
        scale: .9,
        // rotate: 'x 90deg'
        color: 'rgb(255, 255, 255, 0) !important'
    },
    legend: {
        display: 'flex',
        gap: '0.3rem',
        '& > div': {
            borderRadius: '3px',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize: "10px",
            color: "#ffffff",
            padding: '5px 6px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            transition: '1.5s'
        },
        '& > h4': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontFamily: 'Montserrat',
            fontWeight: 700,
            fontSize: "12px",
            color: "rgb(0,0,0,0.7) !important",

        },
    },
    emptyTable: {
        minHeight: '420px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "rgb(112, 112, 112)",
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 500,
        borderBottom: "1px solid rgb(112, 112, 112, 0.4)",
        '& > .MuiButtonBase-root': {
            height: '40px',
            width: '170px',
            border: '1px solid #f4511e',
            backgroundColor: '#f4511e',
            color: '#fff',
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: 700,
            letterSpacing: 1
        }
    },
    selectRowsBox: {
        color: "rgb(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontSize: "0.8rem",
        fontWeight: 600,
        marginRight: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.7rem',
        '& .MuiFormControl-root': {
            minWidth: '1rem',
            margin: 0,
            '& .MuiInputBase-root': {
                lineHeight: 0,
                padding: 0,
                '& > svg': {
                    fontSize: '1.5rem',
                    color: '#f4511e',
                    right: '0.5rem'
                },
                '& > fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0)'
                },
                '& .MuiSelect-select': {
                    lineHeight: 0,
                    padding: '0.3rem',
                    display: 'flex',
                    width: '3rem',
                    alignItems: 'center',
                    color: '#f4511e',
                    fontFamily: "Montserrat",
                    fontSize: "0.8rem",
                    fontWeight: 600
                }
            }
        }
    },
    btnCell: {
        paddingLeft: '2.4rem',
        '& > span': {
            padding: '0.4rem 0.7rem',
            borderRadius: '4px',
            fontWeight: 700,
            fontSize: '0.7rem',
            fontFamily: 'Montserrat',
            color: 'rgb(0, 128, 0)',
            backgroundColor: 'rgb(0, 128, 0, 0.5)',
        }
    },
    successBtnCell: {
        '& > span': {
            // color: 'rgb(0, 128, 0)',
            // backgroundColor: 'rgb(0, 128, 0, 0.4)',
            color: '#43A047',
            backgroundColor: '#ECF5EC',
        }
    },
    errorBtnCell: {
        '& > span': {
            color: '#FF0000',
            backgroundColor: '#FFE5E5',
        }
    },
    inProgressBtnCell: {
        '& > span': {
            color: '#FFB300',
            backgroundColor: '#FFF7E5',
        }
    },
    linearProgress: {
        backgroundColor: '#F2F2F2',
        '& span': {
            backgroundColor: '#f4511e'
        },
        '& .MuiLinearProgress-bar1': {
            backgroundColor: '#f4511e'
        }
    },
    typography: {
        color: '#f4511e',
        fontSize: '1.2rem',
        fontFamily: 'Montserrat',
        fontWeight: 600
    }
}))
export default useStyles;