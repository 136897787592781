import React from 'react'
import { makeStyles } from "@mui/styles";
import { Box } from '@mui/system';
import { LinearProgress } from '@mui/material';


function LinearProgressWithLabel(props) {
    const styles = useStyles();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{

                mr: 1,
                '& > .MuiLinearProgress-root': {
                    width: props.width ? props.width : '42rem',
                    height: props.height ? props.height : '5px',
                    borderRadius: '4px'
                }
            }}>
                <LinearProgress className={styles.linearProgress} variant="determinate" color='warning' {...props} />
            </Box>
            {/* <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" className={styles.typography}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box> */}
        </Box >
    );
}

export default LinearProgressWithLabel

const useStyles = makeStyles(() => ({

    linearProgress: {
        backgroundColor: '#F2F2F2',
        '& span': {
            backgroundColor: '#f4511e'
        },
        '& .MuiLinearProgress-bar1': {
            backgroundColor: '#f4511e'
        }
    },
}));