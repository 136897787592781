import * as React from 'react';
import { useEffect, useContext } from 'react';

//mui
import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

// utils
import useRouter from "../../utils/useRouter";
import useQuery from '../../utils/useQuery';
import isEmpty from '../../utils/isEmpty';

// constants
import constants from '../../constants';

// styles
import useStyles from './index.styles';


//global-states
import { GlobalStates } from '../../App';


import { useParams } from "react-router-dom";



const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const SideMenu = ({
    sideMenu,
    setSideMenu,
}) => {
    const [globalStates] = useContext(GlobalStates);
    // console.log('Side menu globalStates = ', globalStates);
    const [collapseOpen, setCollapseOpen] = React.useState(null);
    const [activeClass, setActiveClass] = React.useState(null)
    const theme = useTheme();
    const classes = useStyles();
    const router = useRouter();
    const userType = localStorage.getItem('user-type');
    let roles = localStorage.getItem('roles') || [];
    let { companyId } = useParams();
    const query = useQuery();
    if (isEmpty(companyId)) companyId = query.get("c_id");

    useEffect(() => {
        if (!sideMenu.isOpen) {
            setCollapseOpen(null)
            setActiveClass(null)
        }
    }, [sideMenu.isOpen])

    const handleDrawerClose = () => {
        setSideMenu({ isOpen: false, defaultWidth: "65px" })
    };

    const handleCollapseItemClick = (clickedIndex, url) => {
        if (clickedIndex === 6) {
            //LOGOUT BUTTON CLICKED
            setCollapseOpen(null);
            handleDrawerClose();
            localStorage.clear();
            router.history.push('/login');
            return
        }
        let userType = localStorage.getItem('user-type');
        let companyId = localStorage.getItem('companyId');

        //ROUTES FOR OPEN SIDE MENU
        if (sideMenu.isOpen) {
            if (clickedIndex === 0) {
                //HOME ICON CLICKED
                setCollapseOpen(null);
                console.log('home icon clicked by', userType);
                if (userType === 'superAdmin') {
                    handleDrawerClose();
                    router.history.push('/companies');
                    return
                } else {
                    //ROUTING TO PROJECTS PAGE ON CLICK OF HOME ICON FROM MENU
                    setSideMenu({ isOpen: false, defaultWidth: "65px" });
                    router.history.push(`/projects/${companyId}`);
                }
            } else {
                //EXPANDING SIDEMENU WITH COLLAPSE MENU OF CLICKED INDEX
                if (clickedIndex === collapseOpen) {
                    //CLOSING ALREADY OPEN MENU ITEM
                    setCollapseOpen(null);
                    setActiveClass(null);
                    return
                }
                setCollapseOpen(clickedIndex);
                // setSideMenu({ isOpen: true, defaultWidth: "215px" });
            }
        }

        //ROUTING FOR CLOSED SIDE-MENU
        if (!sideMenu.isOpen) {
            // console.log(`CLOSED side menu ${clickedIndex} item clicked with url ${url}`);
            if (clickedIndex === 0) {
                //HOME ICON CLICKED
                if (userType === 'superAdmin') {
                    handleDrawerClose();
                    router.history.push('/companies');
                    return
                } else {
                    //ROUTING TO PROJECTS PAGE ON CLICK OF HOME ICON FROM MENU FOR ALL ADMINS OTHER THAN SUPER-ADMIN
                    router.history.push(`/projects/${companyId}`);
                }
                return
            } else {
                setCollapseOpen(clickedIndex);
            }
            setSideMenu({ isOpen: true, defaultWidth: "215px" });
        }


        // if (collapseOpen === clickedIndex) {
        //     return
        // } 
        // else {
        //     setCollapseOpen(clickedIndex);
        // }
    };

    const getOtherReportsActiveTab = () => {
        let tab = constants.OTHER_REPORTS_TABS_OBJECT.MS_ATTEMPT_LEVEL;
        const otherReportRoles = constants.OTHER_REPORTS_ROLES;
        for (let i = 0; i < otherReportRoles.length; i++) {
            if (roles.includes(otherReportRoles[i])) {
                tab = constants.OTHER_REPORTS_TABS_OBJECT[otherReportRoles[i]];
                break;
            }
        }
        return tab
    }

    const collapseItemClick = (item) => {
        console.log('collapseItemClick item = ', item);
        let url = `${item.url}`;
        if (userType === 'superAdmin') {
            let defaultCompId = constants.SUPER_ADMIN_COMPANY_ID
            url = `${item.url}?c_id=${defaultCompId}`;
            if (item.title === 'Microskills') url = `${item.url}/${null}/xx`;
            if (item.title === 'Content Launch') url = `${item.url}?c_id=${null}`;

            const comingSoonFor = ['Library', 'Groups', 'Course', 'Training', 'Batch', 'Login and Completion', 'Feedback', 'Skill Gap', 'Tough Question Analysis    ']
            if (comingSoonFor.includes(item.title)) url = `/coming-soon`;

        } else {
            //collapse-menu routing for company admins
            const companyId = localStorage.getItem('companyId');
            url = `${item.url}?c_id=${companyId}`;
            if (item.title === 'Microskills') url = `${item.url}/${companyId}/xx`;
            if (item.title === 'Library') url = `${item.url}/${companyId}`;
            if (item.url === '/analytics') {
                if (item.title === 'Other Reports') {
                    const tab = getOtherReportsActiveTab();
                    url = `${item.url}?c_id=${companyId}&&tab=${tab}`;
                } else {
                    url = `${item.url}?c_id=${companyId}&&tab=${item.tabValue}`;
                }
            }

        }
        handleDrawerClose();
        setCollapseOpen(null);
        router.history.push(url);
        return
    }

    const handleSetActiveClass = (index) => {
        if (index === activeClass) {
            setActiveClass(null);
            return
        }
        setActiveClass(index)
    }

    const iflCollapseItemAccess = (item) => {
        if (item.title === "Course") return roles.includes("CREATE_COURSE");
        if (item.title === "Training") return roles.includes("CREATE_TRAINING");
        if (item.title === "Batch") return roles.includes("MANAGE_BATCH_LEARNERS");
        if (item.restricted) return _isRoleAssigned(item.allowedRoles);
        return true
    }

    const _isRoleAssigned = (allowedRoles = []) => {
        let allowed = false;
        for (let i = 0; i < allowedRoles.length; i++) {
            if (!roles.includes(allowedRoles[i])) {
                allowed = false;
                break;
            } else {
                allowed = true;
            }

        }
        return allowed
    }

    const _hasCollapseItemAccess = (item) => {
        // console.log('_hasCollapseItemAccess item = ', item);
        const companyId = localStorage.getItem('companyId');
        const userType = localStorage.getItem('user-type');
        // if (userType === 'superAdmin') return true;
        if (item.hasAccess.includes(userType)) {
            if (companyId === constants.IFL_ID) return iflCollapseItemAccess(item);
            if (item.restricted) return _isRoleAssigned(item.allowedRoles);
            return true;
        }
        return false;
    }

    const _hasMenuItemAccess = (element) => {
        let allowedForCustomerSupport = ['Logout', 'User Management'];
        if (userType === 'superAdmin') return true
        if (userType === 'customerSupport') return allowedForCustomerSupport.includes(element.title);
        if (companyId === constants.IFL_ID) {
            if (element.title === "Logout") return true
            if (roles.includes("CONTENT_CREATOR") && roles.includes("TMS_TRAINER")) {
                if (element.title === "Engagement") return roles.includes("FORM_MANAGER");
                return true
            }
            if (!roles.includes("CONTENT_CREATOR") && roles.includes("TMS_TRAINER")) {
                if (element.title === 'Training') {
                    return true
                } else if (element.title === 'Analytics') {
                    return roles.includes("ANALYTICS_MANAGER")
                } else if (element.title === "Engagement") {
                    return roles.includes("FORM_MANAGER");
                } else {
                    return false
                }
            }
            if (roles.includes("CONTENT_CREATOR") && !roles.includes("TMS_TRAINER")) {
                if (element.title === 'Training') {
                    return false
                } else if (element.title === 'Analytics') {
                    return roles.includes("ANALYTICS_MANAGER")
                } else if (element.title === "Engagement") {
                    return roles.includes("FORM_MANAGER");
                } else {
                    return true
                }
            }
            // 
            return false
        } else {
            if (element.title === 'Training') return constants.HAS_TMS_ACCESS.includes(companyId) || _isRoleAssigned(element.allowedRoles);
            if (element.restricted) return _isRoleAssigned(element.allowedRoles);
            return true;
        }
    }

    return (
        <>
            <DrawerHeader sx={{ justifyContent: 'start' }}>
                {!sideMenu.isOpen ? null :
                    <IconButton className={classes.iconBtn} color="inherit" onClick={handleDrawerClose}>
                        <CloseIcon className={classes.closeIcon} />
                        {/* {theme.direction === 'rtl' ? <ChevronRightIcon className={classes.closeIcon} /> : <CloseIcon className={classes.closeIcon} />} */}
                    </IconButton>
                }
            </DrawerHeader>
            <List className={classes.list}>
                {constants.DRAWER_MENU.map((element, index) => {
                    if (_hasMenuItemAccess(element)) {
                        return (
                            <ListItem
                                onClick={() => handleSetActiveClass(index)}
                                className={`${sideMenu.isOpen ? classes.listItemDefault : ''}  ${sideMenu.isOpen ? (activeClass == index && classes.active) : ''}`}
                                // className={`${listStyle()}  ${sideMenu.isOpen ? (activeClass == index && classes.active) : null}`} 
                                key={index}
                                disablePadding
                                sx={{ display: 'block' }}
                            >
                                <ListItemButton
                                    onClick={() => handleCollapseItemClick(index, element.url)}
                                    className={sideMenu.isOpen ? classes.listBtn : classes.listBtnDefault}
                                    // className={`${btnStyle()}`}
                                    sx={{
                                        // backgroundColor : collapseOpen.length ?'rgba(0, 0, 0, 0.4)' : 'transparent' ,
                                        fontWeight: 'bold',
                                        borderRadius: "20px",
                                        justifyContent: sideMenu.isOpen ? 'initial' : 'center',
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: sideMenu.isOpen ? 1 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <img
                                            className={classes.menuIcon}
                                            src={element.icon}
                                            alt={element.value}
                                            title={element.title}
                                        />
                                    </ListItemIcon>
                                    {
                                        sideMenu.isOpen ?
                                            <ListItemText
                                                className={classes.listText}
                                                primary={element.title}
                                                sx={{ opacity: sideMenu.isOpen ? 1 : 0 }}
                                            />
                                            : null
                                    }
                                </ListItemButton>
                                {
                                    element.collapseMenu.length ?
                                        <Collapse className={classes.collapseListBox} in={(collapseOpen === index) && sideMenu.isOpen} timeout="auto" unmountOnExit>
                                            <List className={classes.collapseList} component="div" disablePadding sx={{
                                                ml: sideMenu.isOpen ? 7 : 'auto',
                                                mt: sideMenu.isOpen ? 0 : 'auto',
                                            }}>
                                                {element.collapseMenu.map((item, key) => {
                                                    if (_hasCollapseItemAccess(item)) {
                                                        return (
                                                            <ListItemButton onClick={() => collapseItemClick(item)} className={classes.collapseListBtn} key={key}>
                                                                <ListItemText primary={item.title} />
                                                            </ListItemButton>
                                                        )
                                                    }

                                                })}

                                            </List>
                                        </Collapse> : null
                                }
                            </ListItem>
                        )

                    } else return null

                })}
            </List>

        </>
    );
}
export default SideMenu;