import React, { useState, useEffect, useContext } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import Title from '../../../title'
import MultipleFilter from '../multiple-filter';
import ControlledSwitches from '../../../custom-styled/switch-button';
import EmailReports from '../email-report';
import Heatmap from '../heatmap';

//utils
import useQuery from '../../../../utils/useQuery';
import commonUtil from '../../../../utils/commonUtil';

//services
import reportsServices from '../../../../services/reports';

//global-states
import { GlobalStates } from '../../../../App';

import commonStyles from '../../index.styles';
import { useRef } from 'react';


const retentionLegend = [
    { title: "retentionLegend", name: "Low", hexCode: "#D93731", value: "0-60", startSeverity: 0, endSeverity: 60 }, //0-40, color: red
    { title: "retentionLegend", name: "Medium", hexCode: "#FFB300", value: "61-80", startSeverity: 61, endSeverity: 80 },//41-60 , color: yellow
    { title: "retentionLegend", name: "High", hexCode: "#00CC2E", value: "81-90", startSeverity: 81, endSeverity: 90 },//61-80, color: light-green
    { title: "retentionLegend", name: "Highest", hexCode: "#43A047", value: "91-100", startSeverity: 91, endSeverity: 100 },//81-100, color: dark-green
]

const skillGapLegend = [
    { title: "skillGapLegend", name: "Low", hexCode: "#43A047", value: "0-20", startSeverity: 0, endSeverity: 20 }, //0-20, color: dark-green
    { title: "skillGapLegend", name: "Medium", hexCode: "#00CC2E", value: "21-40", startSeverity: 21, endSeverity: 40 },//21-40, color: light-green
    { title: "skillGapLegend", name: "High", hexCode: "#FFB300", value: "41-60", startSeverity: 41, endSeverity: 60 },//41-60, color: yellow
    { title: "skillGapLegend", name: "Highest", hexCode: "#D93731", value: "61-100", startSeverity: 61, endSeverity: 100 },//61-100, color: red
]

const defaultHeadings = [
    { title: "Username", key: "Username" },
    { title: "Plan", key: "Plan" },
    { title: "Execute", key: "Execute" },
    { title: "Sustain", key: "Sustain" },
    { title: "Context", key: "Context" },
    { title: "test", key: "test" },
    { title: "tes", key: "tes" },
];

const SkillGapReport = ({
    setBackdrop,
    mapping,
    demographicFilter,
    setDemographicFilter,
    _applyDemographicFilter,
    _selectAllSubmenuDF,
    _handleSubmenuItemDF,
    _resetDemographicFilter,
    handleDemographicSearch,
    contentFilter,
    setContentFilter,
    _selectAllSubmenuCF,
    _handleSubmenuItemCF,
    _applyContentFilter,
    _resetContentFilter,
    _handleContentSearch }) => {

    const classes = useStyles();
    const styles = commonStyles();
    const query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates);
    const [payload, setPayload] = useState({ companyId, flag: "filter" });
    const [isRetention, setIsRetention] = useState(true);
    const [headings, setHeadings] = useState(defaultHeadings);
    const [tableData, setTableData] = useState([]);
    // const [pageCount, setPageCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    // const [totalCount, setTotalCount] = useState(0);
    // const [pageNo, setPageNo] = useState(1);
    const [tableLoader, setTableLoader] = useState(false);
    const [progress, setProgress] = useState(0);
    const [intervalCleared, setIntervalCleared] = useState(false);
    const [showGenerate, setShowGenerate] = useState(true);
    const [emailId, setEmailId] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const intervalRef = useRef();

    useEffect(() => {
        if (!showGenerate) {
            fetchSkillGapReports(payload);
        }
    }, [showGenerate, startIndex])

    useEffect(() => {
        setShowGenerate(true);
        setStartIndex(0);
        setProgress(0);
    }, [payload])

    useEffect(() => {
        if (intervalCleared) {
            setProgress(0);
            setTableLoader(false);
            setIntervalCleared(false);
        }

    }, [intervalCleared])

    //RT-CF
    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        }
    }, [])

    const updateProgress = () => {
        intervalRef.current = setInterval(() => {
            setProgress((prevProgress) => {
                let random = prevProgress + Math.floor(Math.random() * 20);
                if (prevProgress >= 100 || random >= 100) {
                    clearInterval(intervalRef.current);
                    // clearInterval(timer);
                    setIntervalCleared(true);
                    return 100
                } else {
                    return random
                }
            });
        }, 300);

    };

    const fetchSkillGapReports = async (payload, options = {}) => {
        setTableLoader(true);
        const response = await reportsServices.fetchSkillGapReports(payload, options);
        if (response && response.status === 200) {
            let objectifiedData = commonUtil.objectify(response.data.data.data, "bookName");
            let resData = objectifiedData["SkillGapSummary"];

            let tempHeadings = [];
            for (let i = 0; i < resData.header.length; i++) {
                let tempObj = { title: resData.header[i], key: resData.header[i] }
                if (_checkIsDemographic(resData.header[i])) tempObj.isDemographic = true;
                tempHeadings.push(tempObj);
            }
            setHeadings(tempHeadings);
            setTableData(resData.data);
            updateProgress();
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            return
        }
    }

    const _checkIsDemographic = (key) => {
        let isDemographic = false;
        for (let i = 0; i < mapping.length; i++) {
            if (mapping[i].excel_column.toLowerCase() === key.toLowerCase()) {
                isDemographic = true;
                break
            }
        }
        return isDemographic
    }

    const handleDownloadSkillGapReport = async () => {
        setBackdrop(true);
        let downloadAsExcelPayload = {
            ...payload,
            companyId: companyId,
            flag: "excel",
        };


        const response = await reportsServices.fetchSkillGapReports(downloadAsExcelPayload, { startIndex: 0 });
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location || response.data.data.file;
            console.log('downloadAsExcel fileUrl = ', fileUrl);
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Completion reports';
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }

    //RT-CF
    const handleApplyContentFilter = (filterList) => {
        const { newPayload } = _applyContentFilter(payload, contentFilter);
        setPayload(newPayload);
    }

    //RT-CF
    const handleResetContentFilter = () => {
        let newPayload = {
            ...payload,
            companyId: companyId,
            flag: "filter"
        };
        if (newPayload.hasOwnProperty('microskillIds')) {
            delete newPayload['microskillIds'];
        }
        if (newPayload.hasOwnProperty('languages')) {
            delete newPayload['languages'];
        }
        setPayload(newPayload);
        _resetContentFilter();
    }

    const handleApplyDemographicFilter = () => {
        const { newDemographicFilterData, newPayload } = _applyDemographicFilter(demographicFilter, payload);
        setPayload(newPayload);
        setDemographicFilter(newDemographicFilterData);
    }

    const handleResetDemographicFilter = () => {
        let newPayload = {
            ...payload,
            companyId: companyId,
            flag: "filter"
        };
        if (newPayload.hasOwnProperty('demographics')) {
            delete newPayload['demographics'];
        }
        setPayload(newPayload);
        _resetDemographicFilter();
    }

    const handleEmailSkillGapReport = async () => {
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        setSendingEmail(true);
        let emailReportPayload = {
            ...payload,
            companyId: companyId,
            flag: "email",
            emailId: emailId
        };
        let options = {
            startIndex: 0,
        }
        const response = await reportsServices.fetchSkillGapReports(emailReportPayload, options);
        if (response.status === 200) {
            let message = `Skillgap report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
            setSendingEmail(false);
            setEmailDialog(false);
        } else {
            globalStates.handleToast(true, "Something went wrong. Please try again!", "error");
            setSendingEmail(false);
        }

    }

    return (
        <div className={classes.main}>
            <div className={`${classes.header}`} >
                <Title titleText={isRetention ? "Retention Heatmap" : "Skill Gap Analysis"} />
                <ControlledSwitches checked={isRetention} handleChange={() => setIsRetention(!isRetention)} />
                <div className={classes.actionIcons}>
                    <EmailReports
                        open={emailDialog}
                        setOpen={setEmailDialog}
                        loading={sendingEmail}
                        handleChange={(e) => setEmailId(e.target.value)}
                        handleClick={handleEmailSkillGapReport}
                    />
                    <img src='/images/analytics/download-icon.svg' alt='' title='Download Skill Gap Report' onClick={handleDownloadSkillGapReport} />
                </div>
            </div>
            <div className={classes.tableBox}>
                <div className={classes.filtersContainer}>
                    <MultipleFilter
                        label={"Demographic Filters"}
                        title={"Select Demographics"}
                        filterList={demographicFilter}
                        setFilterList={setDemographicFilter}
                        handleSaveFilter={handleApplyDemographicFilter}
                        handleResetFilter={handleResetDemographicFilter}
                        handleSearch={handleDemographicSearch}
                        customhandleClick={_handleSubmenuItemDF}
                        customSelectAll={_selectAllSubmenuDF}
                    />
                    <MultipleFilter
                        label={"Content Filters"}
                        title={"Select Content"}
                        filterList={contentFilter}
                        setFilterList={setContentFilter}
                        // customhandleClick={handleSelectContent}
                        handleSaveFilter={handleApplyContentFilter}
                        handleResetFilter={handleResetContentFilter}
                        handleSearch={_handleContentSearch}
                        customSelectAll={_selectAllSubmenuCF}
                        customhandleClick={_handleSubmenuItemCF}
                    />
                </div>
                <Heatmap
                    legend={isRetention ? retentionLegend : skillGapLegend}
                    headings={headings}
                    loader={tableLoader}
                    tableData={tableData}
                    isRetention={isRetention}
                    showGenerate={showGenerate}
                    handleGenerate={() => setShowGenerate(false)}
                    progress={progress}
                />
            </div>

        </div>
    )

}

export default SkillGapReport;


const useStyles = makeStyles(() => ({
    main: {
        // border: '1px solid',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#f9f9f9',
        padding: '0.2rem 1rem',
        margin: '1.5rem 0',
        height: '3rem'
    },
    actionIcons: {
        position: 'absolute',
        right: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        '& img': {
            height: '1.9rem',
            transition: '.4s',
            '&:hover': {
                height: '2rem',
                cursor: 'pointer'
            }
        },

    },
    tableBox: {
        margin: '1rem',
        marginBottom: '2rem',
        boxShadow: "0px 0px 8px -4px rgba(0,0,0,0.5)",
        borderRadius: '6px'
    },
    filtersContainer: {
        // border: '1px solid red',
        position: 'relative',
        height: '3.8rem',
        gap: '1.3rem',
        display: 'flex',
        padding: '0 1rem',
        alignItems: "center"
    },
    demographicsFilterWrapper: {
        // border: '1px solid',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14rem',
        '& > h1': {
            border: '1px solid rgb(112,112,112, 0.3)',
            borderRadius: '3px',
            fontSize: '0.8rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 600,
            padding: '0.4rem 1rem',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
            }
        }
    },
    contentFilterWrapper: {
        // border: '1px solid',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14rem',
        '& > h1': {
            border: '1px solid rgb(112,112,112, 0.3)',
            borderRadius: '3px',
            fontSize: '0.8rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 600,
            padding: '0.4rem 1rem',
            display: 'flex',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
            }
        }
    },
    filterIcon: {
        marginLeft: '0.8rem',
    },
    selectBoxWrapper: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        backgroundColor: '#fff',
        borderRadius: '4px',
    },
    dateRangeWrapper: {
        // border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        gap: '1rem',
        right: '1rem',
        height: '100%',
        "& > span": {
            marginRight: '-0.9rem'
        }
    },
    datePickerBox: {
        // border: '1px solid red',
        '& > div': {
            overflow: 'hidden !important',
            paddingTop: '0.4rem !important',
            height: '2.5rem !important',
            '& .MuiInputBase-root': {
                height: '2rem !important',
                width: '8rem !important',
                color: '#707070'

            },
            '& .MuiFormLabel-root': {
                fontFamily: 'Montserrat',
                fontSize: '0.9rem',
                fontWeight: 600
            }
        }
    },
}));

