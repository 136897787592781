import React, { useState, useEffect, useContext, useRef } from 'react'

//mui
import dayjs from 'dayjs';

//components
import Title from '../../../title'
import Label from '../../../custom-styled/label';
import MultipleFilter from '../multiple-filter';
import EmailReports from '../email-report';
import DatePicker from '../date-picker';

//utils
import useQuery from '../../../../utils/useQuery';
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';

//services
import reportsServices from '../../../../services/reports';

//global-states
import { GlobalStates } from '../../../../App';

import useStyles from '../../index.styles';

const MicroskillFeedbackReport = ({
    setBackdrop,
    mapping,
    demographicFilter,
    setDemographicFilter,
    _applyDemographicFilter,
    _selectAllSubmenuDF,
    _handleSubmenuItemDF,
    _resetDemographicFilter,
    handleDemographicSearch,
    contentFilter,
    setContentFilter,
    _selectAllSubmenuCF,
    _handleSubmenuItemCF,
    _applyContentFilter,
    _resetContentFilter,
    _handleContentSearch,
    _resetDateRange,
    active = false,
    tabIdx,
    handleSelectTab }) => {
    const commonStyles = useStyles({ active });
    const query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates);
    const today = dayjs();
    const [payload, setPayload] = useState({ companyId, flag: "none" });
    const [dateRange, setDateRange] = useState([null, null]);
    let mappingObject = commonUtil.objectify(mapping, "name");
    const [emailId, setEmailId] = useState(null);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedShortcut, setSelectedShortcut] = useState(null);
    let minDate = dayjs(new Date('2022-01-01'));


    const handleDateChange = (newValue) => {
        if (newValue[1] !== null) {
            let newPayload = {
                ...payload,
                companyId: companyId,
                flag: 'filter',
                fromDate: dayjs(newValue[0]).format('YYYY-MM-DD HH:mm:ss.SSS'),
                toDate: dayjs(newValue[1]).format('YYYY-MM-DD HH:mm:ss.SSS')
            }
            console.log('newPayload = ', newPayload);

            setPayload(newPayload);
        }

        setDateRange(newValue);

    }

    const handleResetDateRange = () => {
        let { newPayload } = _resetDateRange(payload);
        setPayload(newPayload);
        setDateRange([null, null]);
    }

    const getMappingDetails = (value) => mappingObject[value];

    const handleDownloadMicroskillFeedbackReport = async () => {
        if (!active) return
        setBackdrop(true);
        let downloadAsExcelPayload = {
            ...payload,
            companyId: companyId,
            flag: "excel",
        };
        if ("languages" in downloadAsExcelPayload && !downloadAsExcelPayload.languages.length) delete downloadAsExcelPayload.languages;
        if ("demographics" in downloadAsExcelPayload) {
            let selectedFilters = [...downloadAsExcelPayload["demographics"]];
            // console.log('demographics are selected and selectedFilters = ', selectedFilters);
            let selectedOrders = [];
            for (let i = 0; i < selectedFilters.length; i++) {
                const mappingObj = getMappingDetails(selectedFilters[i].excelColumn);
                // console.log('mappingObj = ', mappingObj);
                selectedOrders.push(mappingObj.order);
            }
            let sortedMapping = [...mapping];
            sortedMapping.sort((a, b) => {
                if (a.order < b.order) return -1
                else return 1
            })
            selectedOrders.sort((a, b) => b - a);
            let current = selectedOrders[0];
            // console.log('current = ', current);
            if (!isEmpty(current)) {
                for (let j = 0; j < sortedMapping.length; j++) {
                    let isFilter = sortedMapping[j].isFilter;
                    let order = sortedMapping[j].order;
                    if (isFilter === 1 && order > current && sortedMapping[j].table_column !== "passwordHash") {
                        if (!selectedOrders.includes(order)) {
                            let missingFilterObj = {
                                columnName: sortedMapping[j].table_column,
                                excelColumn: sortedMapping[j].excel_column,
                                isFilter: sortedMapping[j].isFilter,
                                order: sortedMapping[j].order,
                                values: [],
                            }
                            selectedFilters.push(missingFilterObj);
                            break;
                        }
                    }
                }
                downloadAsExcelPayload["demographics"] = selectedFilters;
            }
        }

        const response = await reportsServices.fetchMicroskillFeedbackReport(downloadAsExcelPayload, { startIndex: 0 });
        if (response.status === 200) {
            let fileUrl = response?.data?.data?.file?.Location;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.click();
                setBackdrop(false);
            }

        } else if (response.status === 400 && response?.data?.errors[0]?.message === "microskillIds is required") {
            globalStates.handleToast(true, "Please select the content filter ", "error");
            setBackdrop(false);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }

    const handleApplyContentFilter = (filterList) => {
        const { newPayload } = _applyContentFilter(payload, contentFilter);
        setPayload(newPayload);
    }

    const handleResetContentFilter = () => {
        let newPayload = {
            ...payload,
            companyId: companyId,
            // flag: "filter"
        };
        if (newPayload.hasOwnProperty('microskillIds')) {
            delete newPayload['microskillIds'];
        }
        if (newPayload.hasOwnProperty('languages')) {
            delete newPayload['languages'];
        }
        console.log('handleResetContentFilter newPayload = ', newPayload);
        setPayload(newPayload);
        _resetContentFilter();
    }

    const handleApplyDemographicFilter = () => {
        const { newDemographicFilterData, newPayload } = _applyDemographicFilter(demographicFilter, payload);
        setPayload(newPayload);
        setDemographicFilter(newDemographicFilterData);
    }

    const handleResetDemographicFilter = () => {
        let newPayload = {
            ...payload,
            companyId: companyId,
            flag: "filter"
        };
        if (newPayload.hasOwnProperty('demographics')) {
            delete newPayload['demographics'];
        }
        setPayload(newPayload);
        _resetDemographicFilter();
    }

    const handleEmailAttemptLevelReport = async () => {
        // console.log('email id = ', emailId);
        // console.log('is valid emailId = ', commonUtil.isValidEmail(emailId));
        if (!emailId) {
            globalStates.handleToast(true, "Please enter an email ID", "error");
            return
        }
        if (!commonUtil.isValidEmail(emailId)) {
            globalStates.handleToast(true, "Invalid email ID", "error");
            return
        }
        setSendingEmail(true);
        let emailReportPayload = {
            ...payload,
            companyId: companyId,
            flag: "email",
            emailId: emailId
        };
        // console.log('emailReportPayload = ', emailReportPayload);
        const response = await reportsServices.fetchAttemptLevelReport(emailReportPayload, { startIndex: 0 });
        // console.log('emailReport response = ', response);
        if (response.status === 200) {
            let message = `Attempt Level Report sent to ${emailId} successfully!`
            globalStates.handleToast(true, message, "success");
            setEmailId(null);
            setSendingEmail(false);
            setEmailDialog(false);
        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setSendingEmail(false);
        }

    }

    return (
        <div  id={`#${tabIdx}-mis-reports`} className={`${commonStyles.card} ${!active && commonStyles.inActiveCard}`} onClick={(e) => handleSelectTab(e, tabIdx)}>
            <Title titleText={"Microskill Feedback Report"} padding='0rem' />
            <div className={commonStyles.filtersContainer}>
                <MultipleFilter
                    label={"Demographic Filters"}
                    title={"Select Demographics"}
                    filterList={demographicFilter}
                    setFilterList={setDemographicFilter}
                    handleSaveFilter={handleApplyDemographicFilter}
                    handleResetFilter={handleResetDemographicFilter}
                    customSelectAll={_selectAllSubmenuDF}
                    customhandleClick={_handleSubmenuItemDF}
                    handleSearch={handleDemographicSearch}
                    disabled={!active}
                />
                <MultipleFilter
                    label={"Content Filters"}
                    title={"Select Content"}
                    filterList={contentFilter}
                    setFilterList={setContentFilter}
                    // customhandleClick={handleSelectContent}
                    handleSaveFilter={handleApplyContentFilter}
                    handleResetFilter={handleResetContentFilter}
                    handleSearch={_handleContentSearch}
                    isFilterOpen={isFilterOpen}
                    setIsFilterOpen={setIsFilterOpen}
                    customSelectAll={_selectAllSubmenuCF}
                    customhandleClick={_handleSubmenuItemCF}
                    disabled={!active}
                />

            </div>

            <div className={commonStyles.dateRangeWrapper}>
                <Label labelText={'Date Range:'} />
                <DatePicker
                    value={dateRange}
                    maxDate={today}
                    minDate={minDate}
                    handleDateChange={handleDateChange}
                    handleReset={handleResetDateRange}
                    selectedShortcut={selectedShortcut}
                    setSelectedShortcut={setSelectedShortcut}
                    disabled={!active}
                />
            </div>

            <div className={commonStyles.actionIcons}>
                {/* <EmailReports
                        open={emailDialog}
                        setOpen={setEmailDialog}
                        loading={sendingEmail}
                        handleChange={(e) => setEmailId(e.target.value)}
                        handleClick={handleEmailMicroskillFeedbackReport} /> */}
                <img src='/images/analytics/download-icon.svg' alt='' title='Download Microskill Feedback Report' onClick={handleDownloadMicroskillFeedbackReport} />
            </div>


        </div>
    )

}

export default MicroskillFeedbackReport;