import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loaderContainer: {
        height: 'calc(100vh - 4rem)',
    },

    MainContainer: {
        // border: "1px solid black",
        height: "100%",
        width: "100%",
        userSelect: 'none'
    },
    Row1: {
        border: "1px solid rgb(221, 224, 226, 0.5)",
        // boxShadow: "0 0 1px #707070",
        display: "flex",
        minHeight: "30rem",
        width: "100%",
        backgroundColor: "#FFFFFF"
    },
    Row2: {
        border: "1px solid rgb(221, 224, 226, 0.5)",
        display: "flex",
        minHeight: "20rem",
        width: "100%",
        backgroundColor: "#FFFFFF"
    },
    Row3: {
        border: "1px solid rgb(221, 224, 226, 0.5)",
        borderBottom: 'none',
        padding: "1rem",
        // display : "flex",
        // height: "35rem",
        width: "100%",
        backgroundColor: "#FFFFFF",

    },
    Row4: {
        padding: "1rem",
        height: "8rem",
        width: "100%",
        backgroundColor: "#FFFFFF",

    },
    Row_LeftDiv: {
        // border: "1px solid black",
        height: "100%",
        width: "50%"
    },
    Inside_Left_div: {
        padding: "1rem"
    },
    Title: {
        fontFamily: "Montserrat",
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: "800",
        characterSpacing: "0",
        lineSpacing: "155",
        padding: '0.5rem 0',
        paddingBottom: "0.9rem"
    },
    LabelInputBox: {
        marginTop: "1rem"
    },
    Label: {
        display: "inline-block",
        fontFamily: "Montserrat",
        color: "#484848",
        fontSize: "12px",
        fontWeight: "Bold",
        characterSpacing: "0",
        lineSpacing: "146",
        padding: "0.8rem 0",
        "& > i": {
            color: "#f4511e",
            fontSize: "10px",
            fontWeight: 700,
            animation: '$highlight 2s infinite ease'
        },
    },
    '@keyframes highlight': {
        '0%': { color: '#f4511e' },
        '50%': { color: 'white' },
        '100%': { color: '#f4511e' },
    },
    Input_Box: {
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: "400",
        display: "block",
        width: "50%",
        padding: "0.5rem 0.5rem",
        margin: "0.5rem 0",
        border: "1.4px solid #70707050",
        // boxShadow: "0 0 6px #DDE0E2",
        color: "#707070"

    },
    Row_RightDiv: {
        // border: "1px solid black",
        height: "100%",
        width: "50%"
    },
    Inside_RightDiv: {
        padding: "1rem",
        paddingLeft: "0"
    },
    Img_div: {
        // border: "1px solid black",
        // boxSizing : "border-box",
        height: "50vh",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "38rem 23rem",
        opacity: "0.8",
        display: "flex"
    },
    Img_div_Heading: {
        color: "#FFFFFF",
        fontFamily: "Montserrat",
        fontSize: "21px",
        fontWeight: "Bold",
        // lineHeight : "22",
        marginLeft: "5rem",
        marginTop: "14.7rem",
        overflow: "hidden",
        // display: "inline-block"
    },
    Img_div_Column1: {
        // border: "1px dotted white",
        display: "inline-block",
        width: "70%",
        height: "100%",
    },
    Img_div_ViewsLikes: {
        fontFamily: "Open Sans",
        fontWeight: "Bold",
        color: "#FFFFFF",
        fontSize: "0.6rem",
        marginLeft: "5rem",
        marginTop: "2.5rem"
    },
    Img_div_ViewsLikes_span: {
        marginRight: "1.1rem"
    },
    Img_div_ViewsLikes_icons: {
        marginRight: "0.3rem"
    },
    Brand_Icon: {
        // border: "1px dotted white",
        border: "dotted 2px #fff",
        borderRadius: "6px",
        marginLeft: "0.3rem",
        marginTop: "14.9rem",
        height: "3.5rem",
        width: "3.5rem"

    },
    Img_div_Column2: {
        // border: "1px dotted white",
        display: "inline-block",
        height: "100%",
        width: "30%"
    },
    Icon_preview_text: {
        fontFamily: "Open Sans",
        fontWeight: "Bold",
        color: "#FFFFFF",
        fontSize: "0.5rem",
        marginTop: "0.9rem",
        marginLeft: "0.3rem"
    },


    Preview_txt: {
        display: "inline-block",
        fontFamily: "Montserrat",
        fontWeight: "800",
        color: "#0D034B",
        fontSize: "0.5rem",
        marginTop: "0.5rem"
    },
    Icons: {
        float: "right",
        marginTop: "0.2rem",
        marginRight: "0.2rem",
    },
    Done_Cancel_Icons: {
        height: "1.3rem",
        width: "1.3rem",
        marginLeft: "0.6rem",
        filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(90%) contrast(290%)',
        "&:hover": {
            cursor: "pointer",
            filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(60%) contrast(290%)',
        }
    },
    Note: {
        fontFamily: "Open Sans",
        fontWeight: "700",
        fontStyle: "italic",
        color: "#707070",
        fontSize: "0.7rem",
        marginTop: "0.5rem"
    },
    Note_text: {
        color: "#484848",
    },
    Input_Column1: {
        display: "inline-block",
        width: "80%"

    },
    Custom_File_Input: {
        display: "flex",
        alignItems: 'center',
        boxSizing: "border-box",
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontWeight: "400",
        width: "50%",
        minHeight: "2rem",
        height: "2rem",
        margin: "0.5rem 0",
        border: "1.4px solid #70707050",
        // boxShadow: "0 0 6px #DDE0E2",
        color: "#707070"
    },

    Btn: {
        display: "flex",
        flexWrap: "wrap",
        alignContent: "center",
        width: "24%",
        height: '100%',
        padding: "0.6rem",
        backgroundColor: "#F9F9F9",
        textAlign: "center",
        fontFamily: "Open Sans",
        fontSize: "0.5rem",
        fontWeight: "700",
        color: "#F4511E",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#EBEBF2",
        }
    },
    Image: {
        display: "none"
    },
    FilePath: {
        color: "#707070",
        overflow: "hidden",
        fontSize: "0.7rem",
        minWidth: "76%",
        width: "76%",
        fontFamily: "Open Sans",
        fontWeight: "400",
        paddingLeft: '3%'
    },
    Folder_Icon: {
        marginRight: "0.5rem"
    },
    Row2_Left_Div: {
        padding: "1rem",
        // border: "1px solid red",
        height: "100%",
        width: "50%",
    },

    Row2_Right_Div: {
        // height: "100%",
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        // marginTop: "3rem"

    },
    Inside_Right_div: {
        // padding: "0 2rem",
        paddingRight: "3.5rem",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap"

    },
    Error_Icon: {
        color: "#F4511E",
        fontSize: "1rem",
        marginRight: "0.2rem"
    },
    Right_div_title: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Montserrat",
        color: "#4F0060",
        fontSize: "0.9rem",
        fontWeight: "Bold",
        characterSpacing: "0",
        // lineSpacing : "155"
        // padding : '0.5rem 0'
    },
    Right_div_textbox: {
        marginTop: "0.4rem",
        fontFamily: "Open Sans",
        color: "#707070",
        fontSize: "0.9rem",
        fontWeight: "400",
        characterSpacing: "0",
        lineHeight: "2.5",
        opacity: "1"
    },
    btnBox: {
        '& .MuiButtonBase-root': {
            marginRight: '3rem',
            padding: "0.63rem 1.5rem",
            fontFamily: "Montserrat",
            fontWeight: "bold",
            fontSize: "10px",
            letterSpacing: "75",
            borderRadius: "0.1rem",
        }
    },
    saveBtn: {
        border: "1px solid #F4511E",
        backgroundColor: "#F4511E",
        padding: "0.8rem 3rem",
        marginRight: "3rem",
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "10px",
        color: "#FFFFFF",
        letterSpacing: "75",
        borderRadius: "0.1rem",
        transform: "uppercase",
        cursor: "pointer",
        "&:hover": {
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)'
        }
    },
    ClearBtn: {
        border: "1px solid #F4511E",
        backgroundColor: "#FFFFFF",
        padding: "0.8rem 2rem",
        fontFamily: "Montserrat",
        fontWeight: "bold",
        fontSize: "10px",
        color: "#F4511E",
        letterSpacing: "75",
        borderRadius: "0.1rem",
        transform: "uppercase",
        cursor: "pointer",
        "&:hover": {
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)'
        }
    },
    addChallengeCardContainer: {
        display: 'flex',
        gap: '3rem',
        margin: '2rem 0.5rem'
    },
    certificateCheckbox: {
        margin: '0.5rem 0 0.9rem 0',
        display: 'flex',
        gap: '2rem',
        '& .Mui-checked': {
            color: '#f4511e'
        }
    },
    sequenceCtaBtnBox: {
        position: 'absolute',
        right: 0,
        top: 0,
        display: 'flex',
        containerType: 'inline-size',
        height: '2rem',
        width: '16rem',
        gap: '1rem',
        '& .MuiButtonBase-root': {
            maxHeight: '100%',
            width: '50%',
            padding: 0,
            borderRadius: '4px',
            fontSize: '3cqi'

        },
        '& .MuiButton-filled:hover': {
            borderColor: '#f4511e',
            backgroundColor: '#f4511e',

        }
    }

}))


export default useStyles