import React, { useEffect, useMemo, useRef, useState } from 'react'
import DraggableList from 'react-draggable-list'
import dayjs from 'dayjs';

// mui
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// components
import MODateTimePicker from '../../../tms-components/MODateTimePicker';

// styles
import useStyles from './index.styles';

// Utils
import isEmpty from '../../../../utils/isEmpty';

const isKeyExist = (obj, key) => {
    if (isEmpty(obj)) return false;
    let keyExists = Object.keys(obj).includes(key);
    if (keyExists) {
        return !isEmpty(obj[key])
    }
    return keyExists
}

const SelectedMsSequence = ({
    selectedMsSeq,
    setSelectedMsSeq,
    groupLaunchFlag
}) => {

    const dragBoxRef = useRef();
    const nodeRef = useRef();
    const styles = useStyles();

    let currentDate = dayjs(new Date());

    const [startDateError, setStartDateError] = useState('');
    const [endDateError, setEndDateError] = useState([]);
    const [startDateDialog, setStartDateDialog] = useState([]);
    const [endDateDialog, setEndDateDialog] = useState([]);


    
    // const [failed, setFailed] = useState(false);
    // const [failedText, setFailedText] = useState('');
    // const handleFailClick = () => {
    //     setFailed(false);
    //     setFailedText('');
    // }

    useEffect(() => {
        if (!groupLaunchFlag) {
            selectedMsSeq.map((item, index, arr) => {
                setEndDateError(prev => ([...prev, '']))
                
                setStartDateDialog(prev => {
                    let temp = [...prev];
                    if (prev.length <= index) {
                        temp.push(false);
                    }
                    if (prev.length > arr.length) {
                        temp.splice(arr.length, prev.length - arr.length)
                    }
                    return temp;
                })
                setEndDateDialog(prev => {
                    let temp = [...prev];
                    if (prev.length <= index) {
                        temp.push(false);
                    }
                    if (prev.length > arr.length) {
                        temp.splice(arr.length, prev.length - arr.length)
                    }
                    return temp;
                })
            })
        }
    }, [selectedMsSeq])

    useEffect(() => {
        // to initialise the launch date and expiry date for the entire list
        if (!groupLaunchFlag) {
            setSelectedMsSeq(prev => prev.map(item => ({ ...item, launchesOn: !isEmpty(item?.launchesOn) ? dayjs(item.launchesOn).format('YYYY-MM-DD HH:mm:ss.SSS') : dayjs(currentDate).format('YYYY-MM-DD HH:mm:ss.SSS'), expiresOn: !isEmpty(item?.expiresOn) ? dayjs(item?.expiresOn).format('YYYY-MM-DD HH:mm:ss.SSS') : null })))
        }
    }, [groupLaunchFlag])

    const handleStartDateChange = (newDate, item) => {
        selectedMsSeq.find(ms => ms.id === item.id).launchesOn = newDate
        selectedMsSeq.find(ms => ms.id === item.id).expiresOn = null
        setStartDateError('')
        setEndDateError('');
    }
    const handleEndDateChange = (newDate, item) => {
        let launchDateOfItem = dayjs(selectedMsSeq.find(ms => ms.id === item.id).launchesOn)
        
        if (dayjs(newDate).isBefore(launchDateOfItem)) {
            setEndDateError(prev => {
                let temp = [...prev];
                temp[item.sequence - 1] = 'Expiry date must be after the launch date'
                return temp;
            })
            return
        }
        selectedMsSeq.find(ms => ms.id === item.id).expiresOn = newDate
        setEndDateError(prev => {
            let temp = [...prev];
            temp[item.sequence - 1] = ''
            return temp;
        });
    }

    // const handleSaveDateChanges = () => {
    //     console.log('startDate > endDate = ', startDate > endDate);
    //     if (endDate !== null && startDate >= endDate) {
    //         setEndDateError('Expiry date must be after the launch date')
    //         return
    //     }
    //     let tempScheduledDates = [...scheduledDates];
    //     tempScheduledDates.push({
    //         order: tempScheduledDates.length + 1,
    //         launchesOn: dayjs(startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
    //         expiresOn: endDate ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss.SSS') : null
    //     });
    //     handleResetDates()
    // }

    const DraggableItem = useMemo(() => {

        return ({ item, itemSelected, dragHandleProps }) => {
            // return ({ item, itemSelected, dragHandleProps }) => {
            const { onMouseDown, onTouchStart } = dragHandleProps;

            return (
                <div
                    className={styles.draggbleItem}
                    ref={nodeRef}
                    style={{
                        paddingBottom : !isEmpty(endDateError[item.sequence - 1]) ? '1.2rem' : '0'
                    }}
                >
                    <div
                        className="draggable-item"
                        draggable={false}
                        onTouchStart={(event) => {
                            event.preventDefault();
                            onTouchStart(event);
                        }}
                        onMouseDown={(event) => {
                            onMouseDown(event);
                        }}
                        onTouchEnd={(event) => { }}
                        onMouseUp={(event) => { }}
                    >
                        {/* {item} */}
                        <div
                            className={`${styles.optionCard}`}
                            style={groupLaunchFlag ? {
                                maxWidth: '600px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start'
                            } : {
                                display: 'grid',
                                gridTemplateColumns: '40% 60%',
                            }}
                        >
                            <div className={`${styles.centerStart}`}>
                                {/* <img width="100%" height={isMobile ? "70%" : "50%"} src="/images/icons/drag-icon.svg" alt="drag" /> */}
                                <DragIndicatorIcon sx={{
                                    color: '#F4511E'
                                }} />
                                <h6 className={styles.msBtn}>{item.name}</h6>
                            </div>
                            {
                                !groupLaunchFlag && (
                                    <div className={`${styles.scheduledWrapper} ${styles.centerFlex}`}>
                                        {/* <> */}
                                        <div className={styles.schedule}>
                                            <MODateTimePicker
                                                onClick={() => {
                                                    setStartDateDialog(prev => {
                                                        let temp = [...prev];
                                                        temp[item.sequence - 1] = true;
                                                        return temp;
                                                    });
                                                }}
                                                open={startDateDialog[item.sequence - 1]}
                                                value={isKeyExist(selectedMsSeq.find(ms => ms.id === item.id), 'launchesOn') ? dayjs(selectedMsSeq.find(ms => ms.id === item.id).launchesOn) : currentDate}
                                                minDate={currentDate}
                                                errorText={startDateError}
                                                onClose={() => setStartDateDialog(prev => {
                                                    let temp = [...prev];
                                                    temp[item.sequence - 1] = false;
                                                    return temp;
                                                })}
                                                // text={startDate.format('DD-MM-YYYY HH:mm [Hrs]')}
                                                text={isKeyExist(selectedMsSeq.find(ms => ms.id === item.id), 'launchesOn') ? dayjs(selectedMsSeq.find(ms => ms.id === item.id).launchesOn).format('DD-MM-YYYY HH:mm [Hrs]') : currentDate.format('DD-MM-YYYY HH:mm [Hrs]')}
                                                handleDateChange={(newDate) => handleStartDateChange(newDate, item)}
                                                isLearningJourney={true}
                                            />
                                        </div>
                                        <div className={styles.schedule}>
                                            <MODateTimePicker
                                                onClick={() => {
                                                    setEndDateDialog(prev => {
                                                        let temp = [...prev];
                                                        temp[item.sequence - 1] = true;
                                                        return temp;
                                                    });
                                                }}
                                                open={endDateDialog[item.sequence - 1]}
                                                value={isKeyExist(selectedMsSeq.find(ms => ms.id === item.id), 'expiresOn') ? dayjs(selectedMsSeq.find(ms => ms.id === item.id).expiresOn) : null}
                                                minDate={isKeyExist(selectedMsSeq.find(ms => ms.id === item.id), 'launchesOn') ? dayjs(selectedMsSeq.find(ms => ms.id === item.id).launchesOn) : currentDate}
                                                errorText={endDateError[item.sequence - 1]}
                                                onClose={() => setEndDateDialog(prev => {
                                                    let temp = [...prev];
                                                    temp[item.sequence - 1] = false;
                                                    return temp;
                                                })}
                                                text={isKeyExist(selectedMsSeq.find(ms => ms.id === item.id), 'expiresOn') ? dayjs(selectedMsSeq.find(ms => ms.id === item.id).expiresOn).format('DD-MM-YYYY HH:mm [Hrs]') : 'DD-MM-YYYY'}
                                                // text={endDate ? endDate.format('DD-MM-YYYY HH:mm [Hrs]') : 'DD-MM-YYYY'}
                                                handleDateChange={(newDate) => handleEndDateChange(newDate, item)}
                                                isLearningJourney={true}
                                            />
                                        </div>
                                    </div>
                                    // </>
                                )
                            }
                        </div>
                    </div>
                </div>
            )
        }
    }, [groupLaunchFlag, startDateDialog, endDateDialog]);

    const _onListChange = (newList) => {
        let newSeqList = newList.map((elem, index) => {
            elem["sequence"] = index + 1
            return elem
        })
        setSelectedMsSeq(newSeqList);
    };

    return (
        <div className={styles.sequencingWrapper}>

            <div className={styles.sequencingCard} style={{ touchAction: "pan-y" }} ref={dragBoxRef}>
                <h2 style={{ fontSize: '1.2rem', fontFamily: "Montserrat", color: "#707070" }}>
                    Set Sequence
                </h2>
                {/* <DraggableList className={styles.draggableList} itemKey="id" template={SelectedMicroskill} list={selectedMsSeq} onMoveEnd={(list) => setSelectedMsSeq(list)} /> */}
                <DraggableList
                    itemKey="id"
                    template={DraggableItem}
                    list={selectedMsSeq.sort((a, b) => a.sequence - b.sequence)}
                    onMoveEnd={(newList) => _onListChange(newList)}
                    container={() => dragBoxRef.current}
                    constrainDrag={true}
                />
            </div>
        </div>
    )
}

export default SelectedMsSequence;