import React from "react";

//Styles
import useStyles from './index.styles'


const DefaultCropCard = ({finalImage, microskillTitle, brandIcon, setToggleEditBtn, likes = 0, views=0}) => {
  // console.log('DefaultCropCard finalImage = ', finalImage);
    const styles = useStyles()
    const handleEditToggle = () =>{
      return
        //CROP DISABLED
        setToggleEditBtn(false)
    }
  return (
    <div className={styles.cardComponent}>
      <div className={styles.defaultCard} style={{backgroundImage : `url('${finalImage}')`}}>
        <div className={styles.cardRow1}>
            {/* <button className={styles.editBtn}>
                <img onClick={handleEditToggle} src="/images/add-microskill/pen-to-square-solid.svg" alt="Edit" className={styles.editIcon}/>
            </button> */}
            
        </div>
        <div className={styles.cardRow2}>
        <div className={styles.cardColumn1}>
          <h1 className={styles.cardHeading}>{microskillTitle}</h1>
          <div className={styles.cardViewsLikes}>
            <span className={styles.cardViewsLikesSpan}>
              <img className={styles.cardViewsLikesIcons} src="/images/add-microskill/eye-solid.svg"/>
              {views}
            </span>
            <span className={styles.cardViewsLikesSpan}>
              <img className={styles.cardViewsLikesIcons} src="/images/add-microskill/heart-solid.svg"/>
               {likes}
            </span>
          </div>
        </div>
        <div className={styles.cardColumn2}>
          <img
            src={brandIcon}
            className={styles.brandIcon}
            alt="Brand Icon"
          />
          {/* <h4 className={styles.iconPreviewText}>ICON PREVIEW</h4> */}
        </div>
        </div>
      </div>
        <h4 className={styles.previewTxt}>
          PREVIEW:
        </h4>
        <h2 className={styles.note}>
          <span className={styles.noteText}>NOTE :</span> Default images will
          be used if you don't upload any assets of your own
        </h2>
    </div>
  );
};

export default DefaultCropCard;
