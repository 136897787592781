import React, { useEffect } from 'react'

//mui
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

//components
import Label from '../../../label';
import CustomInput from '../custom-input';

//styles
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    main: {
        display: 'flex',
        paddingTop: '0.4rem'
    },
    column1: {
        width: '87%',

    },
    column2: {
        width: '13%',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    checkBox: {
        marginRight: '0',
        '& .MuiSvgIcon-root': {
            fontSize: 15,
            color: '#f4511e'
        },
        '& .MuiTypography-root': {
            fontSize: '0.6rem',
            fontFamily: 'Montserrat',
            fontWeight: '600',
        }
    },
    leftUnchecked: {
        '& .MuiSvgIcon-root': {
            color: 'red'
        },
        '& .MuiTypography-root': {
            fontFamily: 'Montserrat',
            fontWeight: 700,
            color: 'red'
        }
    }


}));

const ResponseMA = ({
    listitem,
    isUnChecked,
    isDisableAll,
    maResponse,
    responsesCharLimit = 10,
    handleMaResponseChange,
    handleMaCheckboxChange }) => {
    const classes = useStyles();
    console.log('ResponseMA = ', maResponse);
    const data = Array(8).fill(null).map((item, index) => ({ exceeded: false, limitText: `${responsesCharLimit} Characters` }));
    const [responsesValidations, setResponsesValidations] = React.useState(data);

    useEffect(() => {
        let temp = [...responsesValidations];
        maResponse.forEach((element, id) => {
            let value = element.option;
            // if (value.length > responsesCharLimit) {
            //     //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
            //     temp[id] = { exceeded: true, limitText: `${value.length - responsesCharLimit} Characters Exceeded` };

            // }
            if (value.length > responsesCharLimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
                temp[id] = { exceeded: true, limitText: `${value.length - responsesCharLimit} Characters Exceeded` };
                // setResponsesValidations(temp);
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
                if (listitem.emptyAnswers[id].isEmpty) {
                    temp[id] = { exceeded: false, limitText: `*Required` };
                } else {
                    temp[id] = { exceeded: false, limitText: `${responsesCharLimit - value.length} Characters` };
                }
                // setResponsesValidations(temp);
            }
        });
        console.log('McqResponse temp = ', temp);
        setResponsesValidations(temp);
    }, [maResponse])

    const handleInputChange = (e) => {
        let id = parseInt(e.target.id);
        let value = e.target.value;

        handleMaResponseChange(id, value)

        //SET WARNING IF LIMIT EXCEEDS
        let temp = [...responsesValidations];
        if (value.length > responsesCharLimit) {
            //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
            temp[id] = { exceeded: true, limitText: `${value.length - responsesCharLimit} Characters Exceeded` };
            setResponsesValidations(temp);
        } else {
            //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
            temp[id] = { exceeded: false, limitText: `${responsesCharLimit - value.length} Characters` };
            setResponsesValidations(temp);
        }
    }

    

    return (
        <>
            <Label labelText={'Responses:'} fs={'0.7rem'} />
            {data.map((ele, index) => (
                <div key={index} className={classes.main}>
                    <div className={classes.column1}>
                        <CustomInput
                            handleChange={handleInputChange}
                            id={index.toString()}
                            isDisabled={isDisableAll}
                            defaultValue={maResponse[index].option.toString()}
                            limitText={responsesValidations[index].limitText}
                            isExceeded={responsesValidations[index].exceeded}
                            isEmpty={listitem.emptyAnswers[index].isEmpty}
                        />
                    </div>
                    <div className={classes.column2}>
                        <FormGroup sx={{ p: '0 1rem' }} row>
                            <FormControlLabel className={`${classes.checkBox} ${isUnChecked(index) ? classes.leftUnchecked : ""}`}
                                control={
                                    <Checkbox
                                        disabled={isDisableAll}
                                        checked={maResponse[index].value}
                                        onChange={handleMaCheckboxChange}
                                        value={index.toString()}
                                        name="checkbox"
                                    />}
                                label={maResponse[index].value ? "Correct" : "Incorrect"} />
                        </FormGroup>
                        {/* <FormControl>
                            <RadioGroup
                                className={classes.radioGroup}


                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                            >
                                <FormControlLabel value="Incorrect"
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                            color: '#f4511e'
                                        },
                                    }}
                                    control={<Radio
                                        checked={selectedValues.includes(index)}
                                        onChange={handleChange}
                                        value={index.toString()}
                                        name="radio-buttons"
                                    // inputProps={{ 'aria-label': 'A' }}
                                    />} label={selectedValues.includes(index)? "Correct" : "Incorrect"} />
                            </RadioGroup>
                        </FormControl> */}
                    </div>
                </div>
            ))}
        </>
    )
}

export default ResponseMA;
