import { makeStyles } from "@mui/styles";
import { ThemeConsumer } from "styled-components";

const useStyles = makeStyles(() => ({
    containerWrapper: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    container: {
        // border: '1px solid',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        '& > h4': {
            fontSize: '0.8rem',
            fontWeight: 500,
            fontFamily: 'Montserrat',
        }
    },
    navigation: {
        padding: '0% 5% 2%',
    },
    navList: {
        listStyle: 'none',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '1rem',
        backgroundColor: '#fff',

    },
    navListItem: {
        width: '100%'
    },
    navBtn: {
        fontSize: '0.8rem !important',
        fontWeight: `600 !important`,
        cursor: 'pointer !important',
        color: '#707070 !important',
        borderRadius: `0 !important`,
        textTransform: 'capitalize',
        fontFamily: 'Montserrat',
        '&:hover': {
            color: '#f4511e !important',
        }
    },
    navBtnIcon: {
        // color: '#707070',
        fontSize: '1.7rem !important',
        paddingRight: `0.5rem !important`
    },
    navBtnSelected: {
        color: '#f4511e !important',
        borderBottom: '2px solid #f4511e',
    },
    labelBtnWrapper: {
        display: 'flex',
        position: 'relative',
        height: '2.5rem',
        '& > button': {
            position: 'absolute',
            right: 0,
            top: 0,
            width: 200,
            borderRadius: 4,
            '&:hover': {
                borderColor: '#f4511e',
                backgroundColor: '#f4511e',
                color: '#fff'
            }
        }
    }
}));
export default useStyles;