import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    main: {
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        height: '100%',
        backgroundColor: '#fff'
    },
    tabs: {
        borderBottom: '2px solid rgb(112, 112, 112,0.2)',
        minHeight: '3rem !important',
        height: '3rem',
        '& > div': {
            overflowX: 'hidden',
            '& > .MuiTabs-flexContainer': {
                // gap: '0.5rem',
                '& > .MuiButtonBase-root': {
                    minWidth: '16%',
                    height: '3rem',
                    minHeight: '3rem !important',
                    lineHeight: 0,
                    color: "#707070",
                    fontFamily: "Montserrat",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    display: 'flex',
                    gap: '0.7rem',
                    flexDirection: 'row',
                    padding: '0 1rem',
                    textTransform: 'none',
                    '& > img': {
                        margin: 0,
                        filter: 'invert(38%) sepia(91%) saturate(12%) hue-rotate(315deg) brightness(94%) contrast(93%)'
                    }
                },
                '& > .Mui-selected': {
                    color: "#f4511e",
                    fontWeight: 800,
                    '& > img': {
                        filter: 'invert(39%) sepia(45%) saturate(1512%) hue-rotate(340deg) brightness(102%) contrast(100%) !important'
                    }
                }
            }
        }
    },
    tabIndicator: {
        backgroundColor: '#f4511e',
        height: '3px'
    },
    loaderBox: {
        minHeight: 'calc(100vh - 6.4rem)',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        alignItems: 'center',
        justifyContent: 'center',
        '& > div': {
            backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
        '& > h1': {
            // marginTop: '2rem',
            fontFamily: 'Montserrat',
            color: "#f4511e",
            fontSize: "1.5rem",
            fontWeight: 700,
        }
    },

    //other-reports-common
    card: {
        boxShadow: '0px 2px 12px 0px rgba(0,0,0,0.2)',
        height: '13rem',
        position: 'relative',
        width: '100%',
        minWidth: '26rem',
        maxWidth: '26rem',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        aspectRatio: 4 / 2,
        borderRadius: 4,

    },
    inActiveCard: {
        opacity: 0.4,
        cursor: 'pointer'
    },
    actionIcons: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: '0.5rem',
        '& > img': {
            height: '1.9rem',
            transition: 'scale .4s',
            '&:hover': {
                scale: ({ active }) => (active && 1.1),
                cursor: 'pointer'
            }
        },

    },
    filtersContainer: {
        position: 'relative',
        display: 'flex',
        alignItems: "center",
        gap: '1.3rem',
    },
    dateRangeWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '3.2rem'
    },
    bidirectionalToggleWrapper: {
        // border: '1px solid green',
        fontSize: '0.8rem',
        color: "rgb(0, 0, 0, 0.9)",
        fontFamily: "Montserrat",
        fontWeight: 700,
        position: 'absolute',
        left: 0,
        '& .MuiSwitch-track ': {
            backgroundColor: '#f4511e !important'
        },
        '& .MuiSwitch-switchBase ': {
            color: '#f4511e !important'
        }
    },
}));


export default useStyles;