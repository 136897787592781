import { makeStyles } from "@mui/styles";
import { ThemeConsumer } from "styled-components";
import constants from "../../constants";

export const TITLE_HEIGHT = '1.5rem';

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    inputBox: {
        fontFamily: "Open Sans",
        fontSize: "0.8rem",
        fontWeight: "400",
        display: "block",
        width: "30%",
        padding: "0.5rem",
        margin: "1.2rem 0",
        border: "none",
        border: '1px solid rgb(112, 112, 112, 0.3)',
        color: "#707070",
        borderRadius: '4px'
    },

    anchor: {
        '&:hover': {
            textDecoration: 'underline !important',
            cursor: 'pointer'
        }
    },
    addContentBtn: {
        position: 'relative',
        color: "#F4511E",
        border: "1px solid #F4511E",
        backgroundColor: "#ffffff",
        borderRadius: "2rem",
        fontFamily: 'Montserrat',
        fontSize: '0.7rem',
        fontWeight: 700,
        width: '155px',
        height: '34px',
        // marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'uppercase',
        '& > button': {
            position: 'absolute',
            top: 0,
            left: 0,
            opacity: 0,
            width: '155px',

        },
        '& > svg': {
            marginLeft: '0.6rem'

        },
        '&:hover': {
            color: "#F4511E",
            border: "1px solid #F4511E",
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },

    btn: {
        width: '190px',
        borderRadius: '2px',
        height: '42px',
        padding: '0 4px',
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        cursor: "pointer",
    },

    row: {
        // border: '1px solid',
        height: '2rem',
        margin: '1rem 0',
        marginBottom: '3.5rem',
        display: 'flex',
        alignItems: 'center',
        gap: '5rem'
    },
    customRow: {
        gap: '1rem',
        alignItems: 'flex-start',
        flexDirection: 'column',
        height: 'auto',
    },
    contentSelectionBox: {
        flexDirection: 'column',
        gap: `1rem !important`,
        justifyContent: 'flex-start !important',
        alignItems: 'flex-end !important',
        margin: '0',
        height: 'auto',
    },
    selectWrapper: {
        position: 'relative',
        height: '2rem',
        maxHeight: '2rem',

    },
    select: {
        border: '1px solid rgb(112, 112, 112, 0.4)',
        borderRadius: '4px',
        padding: '0.4rem 1rem',
        width: '20rem',
        height: '100%',
        overflow: 'hidden',
        color: "rgb(112, 112, 112, 0.9)",
        fontFamily: "Montserrat",
        fontSize: "0.9rem",
        fontWeight: 600,
        position: 'relative',
        cursor: 'pointer',
        transition: '0.5s',
        '& > i': {
            position: 'absolute',
            right: '0.7rem',
            top: '0.5rem',
            color: '#f4511e'
        },
        '&:hover': {
            borderColor: '#f4511e',
            color: '#f4511e',
            fontSize: '0.91rem',
            padding: '0.390rem 0.9rem',
        },
    },
    active: {
        borderColor: '#f4511e',
        color: '#f4511e',
        fontSize: '0.95rem',
        padding: '0.388rem 0.8rem',
    },
    selectBox: {
        minWidth: '20rem',
        maxWidth: '45rem',
        backgroundColor: '#fff',
        position: 'relative',
        top: '0.5rem',
        zIndex: 1
    },
    btnBox: {
        display: 'flex',
        gap: '2rem',
        marginTop: '6rem',
        '& .MuiButtonBase-root': {
            width: '12rem',
            height: '2.8rem',
            borderRadius: '3px',

        },
        '& .MuiButton-contained:hover': {
            borderColor: '#f4511e',
            backgroundColor: '#f4511e',
            color: '#fff',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'

        },
        '& .MuiButton-outlined:hover': {
            borderColor: '#f4511e',
            backgroundColor: '#fff',
            color: '#f4511e',
            boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'

        },
        '& .MuiLoadingButton-root': {
            fontFamily: 'Montserrat',
            fontSize: '0.6rem',
            letterSpacing: 1,
            fontWeight: 800,
            borderWidth: '2px',
        }
    },
    scheduledWrapper: {
        display: 'flex',
        gap: '3rem',
        '& > i': {
            color: '#f4511e',
            fontSize: '1rem',
            scale: '1.2',
            transition: 'scale 0.5s',
            '&:hover': {
                scale: '1.4'
            }
        },
    },
    schedule: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem'
    },
    iconWrapper: {
        display: 'flex',
        alignItems: 'end',
        paddingBottom: '0.2rem',
        gap: '1.5rem',
        '& > i': {
            color: '#f4511e',
            fontSize: '1rem',
            scale: '1.2',
            transition: 'scale 0.5s',
            '&:hover': {
                scale: '1.4',
                cursor: 'pointer'
            }
        },
    },
    addScheduleBtn: {
        color: "#F4511E",
        border: "1px solid #F4511E",
        backgroundColor: "#ffffff",
        borderRadius: "2rem",
        fontFamily: 'Montserrat',
        fontSize: '0.7rem',
        fontWeight: 700,
        width: '155px',
        height: '36px',
        marginTop: '1rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        textTransform: 'uppercase',
        '&:hover': {
            color: "#F4511E",
            border: "1px solid #F4511E",
            backgroundColor: "#ffffff",
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
    scheduleListItem: {
        // border: '1px solid',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        '& > div': {
            display: 'flex',
            gap: '1rem',
            '& > h4': {
                fontSize: '0.8rem',
                fontWeight: 500,
                fontFamily: 'Montserrat',
            }
        }
    },
    scheduleLilabel: {
        // border: '1px solid',
        display: 'flex',
        gap: '1rem',
        '& > img': {
            scale: '1.1',
            transition: 'scale 0.5s',
            "&:hover": {
                scale: '1.2',
                cursor: 'pointer',
                filter: 'invert(42%) sepia(30%) saturate(7472%) hue-rotate(352deg) brightness(100%) contrast(92%)'
            }
        }

    },

    pageWrapper: {
        // padding: "1rem",
        background: "#FFF",
        height: '100%',
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        position: 'relative'
    },
    pageTitle: {
        padding: '0 1rem 0',
        backgroundColor: '#f9f9f9',
        fontSize: '1.2cqi',
        height: TITLE_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    containerWrapper: {
        width: '100%',
        // uncomment below line when table is added
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
    },
    container: {
        // border: '1px solid',
        maxHeight: `calc(100dvh - ${constants.HEADER_HEIGHT} - ${TITLE_HEIGHT})`,
        overflowY: 'auto',
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        '& > h4': {
            fontSize: '0.8rem',
            fontWeight: 500,
            fontFamily: 'Montserrat',
        }
    },
    navigation: {
        padding: '0% 5% 2%',
    },
    navList: {
        listStyle: 'none',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '1rem',
        backgroundColor: '#fff',

    },
    navListItem: {
        width: '100%'
    },
    navBtn: {
        fontSize: '0.8rem !important',
        fontWeight: `600 !important`,
        cursor: 'pointer !important',
        color: '#707070 !important',
        borderRadius: `0 !important`,
        textTransform: 'capitalize',
        fontFamily: 'Montserrat',
        '&:hover': {
            color: '#f4511e !important',
        }
    },
    navBtnIcon: {
        // color: '#707070',
        fontSize: '1.7rem !important',
        paddingRight: `0.5rem !important`
    },
    navBtnSelected: {
        color: '#f4511e !important',
        borderBottom: '2px solid #f4511e',
    },
    labelBtnWrapper: {
        display: 'flex',
        position: 'relative',
        height: '2.5rem',
        '& > button': {
            position: 'absolute',
            right: 0,
            top: 0,
            width: 200,
            borderRadius: 4,
            '&:hover': {
                borderColor: '#f4511e',
                backgroundColor: '#f4511e',
                color: '#fff'
            }
        }
    }
}));
export default useStyles;