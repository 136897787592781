import React, { useState, useEffect, useContext, useRef } from 'react'

//mui
import Checkbox from '@mui/material/Checkbox';

//utils
import commonUtil from '../../utils/commonUtil';
import useQuery from '../../utils/useQuery';
import useRouter from '../../utils/useRouter';
import isEmpty from '../../utils/isEmpty';

//services
import challengesServices from '../../services/challenges';
import documentsServices from '../../services/documents';

//components
import StepperMenu from '../stepper-menu';
import CardTypeColumn from './components/card-type-column';
import EditContentSection from './components/edit-content-section'
import Content from './components/content';
import ImportingList from './components/importing-list';
// import UploadOrCreate from './components/upload-or-create-column';
import InteractionTypes from './components/interaction-types';
import EditInteraction from './components/edit-interaction';
import ManageInteractions from './components/manage-interactions';
import LoadingContainer from '../loading-container';
import CustomButton from '../custom-styled/button';
import LoadingDialog from '../progress-dialog';

//constants
import constants from '../../constants';

//context
import { GlobalStates } from '../../App';

//styles
import useStyles from './index.styles';
import Label from '../custom-styled/label';

const enableAddLinkFor = ["imageOnly", "imageTitleBody", "videoTitleBody", "titleBody", "body"];
const enableAttachAudioFor = ["cover", "imageOnly", "imageTitleBody", "titleBody", "body"];
const defaultAudio = { audioFile: null, audioFileUrl: null, fileName: null, mimeType: null };
const defaultBgData = { bgImgFile: null, bgImg: null, prevBgImageUrl: null };

const LearningAid = () => {
    const router = useRouter();
    let query = useQuery();
    const companyId = query.get("c_id");
    const projectId = query.get('p_id');
    const microskillId = query.get('ms_id');
    const challengeId = query.get('challenge_id');
    const editChallenge = query.get('edit');
    const [globalStates, setGlobalStates] = useContext(GlobalStates);
    const [isLoading, setIsLoading] = useState(false);
    const [menuStatus, setMenuStatus] = useState({ covercard: 'inProgress', objectives: 'inProgress', content: 'inProgress' })
    const [selectedColumns, setSelectedColumns] = useState({ firstColumn: 'content', secondColumn: 'editContentSection' })
    const [selectedCard, setSelectedCard] = useState({ rowIndex: null, columnIndex: null, cardType: '' })
    const [showFirstColumn, setShowFirstColumn] = useState(false);
    const [showImportBtn, setShowImportBtn] = useState(false);
    const [coverAndObjective, setCoverAndObjective] = useState([]);
    const [list, setList] = useState([]);
    const [showCoverAndObjective, setShowCoverAndObjective] = useState(true);
    const [isObjectiveSkipped, setIsObjectiveSkipped] = useState(false);
    const [creatingObjectiveAfterSkip, setCreatingObjectiveAfterSkip] = useState(false);
    const [selectedInteraction, setSelectedInteraction] = useState({ gameType: '', interactionType: '', previewUrl: '' });
    const [preview, setPreview] = useState('/images/learning-aid-assets/la-default.png');
    const [ilaCardId, setIlaCardId] = useState(null);
    const [isActive, setIsActive] = useState(null);
    const [selectedQuestion, setSelectedQuestion] = useState(0);
    const [isSavingChallenge, setIsSavingChallenge] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [importingList, setImportingList] = useState([]);
    const [progress, setProgress] = useState(0);
    const [imported, setImported] = useState(false);
    const [isEditName, setEditName] = useState(false);
    const [challengeName, setChallengeName] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [editCardData, setEditCardData] = useState(null);
    const [isEditInteraction, setIsEditInteraction] = useState(false);
    const [maxScore, setMaxScore] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [link, setLink] = useState(null);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [laAudioData, setLaAudioData] = useState(defaultAudio);
    const [laBgImgData, setLaBgImgData] = useState(defaultBgData);
    const [minTimeSpent, setMinTimeSpent] = useState(0);
    const [setting, setSetting] = useState({ minTimeSpent: null });
    const [showSaveMinTime, setShowSaveMinTime] = useState(false);
    const [withMinTimeSpent, setWithMinTimeSpent] = useState(false);
    const classes = useStyles({ minTimeSpent });
    const firstRenderRef = useRef(true);

    // console.log('challenge maxScore = ', maxScore);
    const menuData = [
        'Cover Card',
        // '',
        // 'Objectives',
        '',
        'Content'
    ];

    useEffect(() => {
        if ((coverAndObjective.length + list.length) > 25) globalStates.handleToast(true, 'WARNING: Content may not be effective if it contains more than 25 cards', 'warning')
    }, [list])

    useEffect(() => {
        setGlobalStates({ ...globalStates, showMsName: true });
        return () => {
            setGlobalStates({ ...globalStates, showMsName: false });
        }
    }, [])



    useEffect(() => {
        setChallengeName(query.get("challengeName"));
        fetchChallengesData();
    }, [])

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return
        }
        if (!imported) {
            fetchChallengesData();
        }
    }, [imported])

    useEffect(() => {
        fetchDocuments(companyId)
    }, [])


    const fetchChallengesData = async () => {
        setIsLoading(true);
        const microskillId = query.get("ms_id");
        const challengeId = query.get("challenge_id");
        let res = await challengesServices.getChallengeData(microskillId, challengeId);
        // console.log('fetchChallengesData res = ', res);
        if (res.status === 200 || res.status === 201) {
            let data = res.data.data;
            if (data.setting) {
                const oldMinTimeSpent = data?.setting?.minTimeSpent || null;
                setSetting({ minTimeSpent: oldMinTimeSpent });
                setMinTimeSpent(oldMinTimeSpent);
                if (oldMinTimeSpent) setWithMinTimeSpent(true)
            }
            setMaxScore(data.maxScore);
            setIsEdit(false);
            if (!data.Cards.length) {
                // console.log('No cards present');
                setSelectedCard({ ...selectedCard, cardType: 'cover' });
                setIsLoading(false);
                return
            } else {
                // setSelectedCard({ ...selectedCard, cardType: 'objective' });//changing while fixing objective card
                setSelectedCard({ ...selectedCard, cardType: 'uploadOrCreate' });//original
                setSelectedColumns({ firstColumn: 'content', secondColumn: 'editContentSection' });
            }
            let coverStatus = 'done';
            let objectiveStatus = 'done';
            let coverCard = data.Cards.filter(ele => ele.cardType === 'COVER');
            // console.log('Filteriing cover card = ', coverCard);
            if (coverCard.length) {
                setCoverAndObjective([coverCard[0]]);
            } else {
                coverStatus = 'inProgress'
            }
            let objectiveCard = data.Cards.filter(ele => ele.cardType === 'OBJECTIVE');
            // console.log('Filteriing objective card = ', objectiveCard);
            if (objectiveCard.length) {
                //HERE WE HAVE OBJECTIVE CARD
                if (coverCard.length) {
                    //here we have both cover and objective card
                    setCoverAndObjective([coverCard[0], objectiveCard[0]]);
                } else {
                    //here we have only objective card
                    setCoverAndObjective([objectiveCard[0]]);
                }
                setIsObjectiveSkipped(false);
            } else {
                //here we don't have objective card
                setIsObjectiveSkipped(true);
                objectiveStatus = 'inProgress'
            }

            let otherCards = data.Cards.filter(ele => ele.cardType === 'IMPORTED' || ele.cardType === 'CREATED');
            // console.log('Filteriing other cards = ', otherCards);
            if (otherCards.length) {
                let manipulated = [];
                otherCards.forEach(element => {
                    let newCard = { ...element };
                    let tempQuestions = [];
                    if (element.gameType === 'MTRR') {
                        // console.log('card element = ', element);
                        let contextsMap = new Map();
                        element.Questions.forEach(item => {
                            if (item.contextId !== null) {
                                contextsMap.set(item.contextId, item.Context)
                            }
                        })
                        // console.log('contextsMap = ', contextsMap);
                        let questionsMap = new Map();
                        element.Questions.forEach(item => {
                            if (questionsMap.has(item.contextId)) {
                                //do nothing
                                let result = questionsMap.get(item.contextId);
                                result = [...result, item];
                                questionsMap.set(item.contextId, result);
                            } else {
                                questionsMap.set(item.contextId, [item]);
                            }
                        })
                        // console.log('questionsMap = ', questionsMap);
                        let i = 0;
                        for (let [key, element] of questionsMap) {
                            // console.log(`loop questionsMap key: ${key}, value: `, element);
                            let designType = element[0].designType;
                            let stringifiedJson = JSON.stringify(constants.ILA_QUESTION_JSON[designType]);
                            let parsedJson = JSON.parse(stringifiedJson);
                            parsedJson.id = i;
                            parsedJson.contextObj = contextsMap.get(key);
                            parsedJson.contextId = key;
                            parsedJson.validations = [];
                            parsedJson.status = "done";
                            let tempQuestionArr = [];
                            element.forEach(item => {
                                item.isQuestionEmpty = false;
                                item.emptyAnswers = [{ isEmpty: false }];
                                tempQuestionArr.push(item);
                            })
                            if (tempQuestionArr.length) {
                                parsedJson.mandatory = tempQuestionArr[0].mandatory;
                                let score = 0;
                                tempQuestionArr.forEach(ques => {
                                    if (ques.score) score += ques.score
                                })
                                parsedJson.score = score;
                            }
                            tempQuestions.push({ ...parsedJson, questionArr: tempQuestionArr });
                            i++;
                            // console.log('parsed json = ', { ...parsedJson, questionArr: tempQuestionArr });
                        }
                    } else {
                        element.Questions.forEach(ele => {
                            // console.log('card element = ', element);
                            // console.log('Questions ele = ', ele);
                            let tempObj = { ...ele };
                            tempObj.isQuestionEmpty = false;
                            tempObj.status = 'done';
                            tempObj.validations = [];
                            let tempEmptyAnswers = constants.ILA_QUESTION_JSON[ele.designType].emptyAnswers;
                            tempObj.emptyAnswers = tempEmptyAnswers;
                            // console.log('tempObj = ', tempObj);
                            if (tempObj?.answers.length) {
                                tempObj.answers.sort((a, b) => {
                                    if (a?.sequence && b?.sequence) {
                                        return a?.sequence - b?.sequence
                                    }
                                });
                            }
                            tempQuestions.push(tempObj);
                        })
                        tempQuestions.sort((a, b) => a.sequence - b.sequence);
                    }

                    newCard.games = {
                        gameType: element.gameType,
                        questions: tempQuestions
                    };
                    // // console.log('newCard.games = ', newCard.games);
                    if (newCard.card.cardType === "VTB" && newCard.thumbImage === null) {
                        newCard.thumbImage = constants.VTB_DEFAULT_THUMBNAIL;
                        // newCard.thumbImage = "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/clf15f5o1000tqn043k2g2umg/LAMediaFiles-1687084182299.png";
                    }
                    manipulated.push(newCard);
                });
                manipulated.sort((a, b) => a.sequence - b.sequence)
                // console.log('manipulated list = ', manipulated);
                setList(manipulated);
                let preview = localStorage.getItem('preview');
                if (preview) {
                    setPreview(preview);
                    setSelectedCard({ ...selectedCard, cardType: 'default' })
                    localStorage.removeItem('preview');
                } else {
                    setSelectedCard({ ...selectedCard, cardType: 'uploadOrCreate' });
                }
                setSelectedColumns({ firstColumn: 'content', secondColumn: 'editContentSection' });
            }

            setMenuStatus({ ...menuStatus, covercard: coverStatus, objectives: objectiveStatus });
            // setShowFirstColumn(false);//changing while fixing objective card
            setShowFirstColumn(true);//original
            setIsLoading(false);

            return
        }
        if (res.status === 404) {
            return
        }
    }

    const fetchDocuments = async (companyId) => {
        const response = await documentsServices.fetchDocuments(companyId);
        // console.log('fetchDocuments response = ', response);
        if (response.status === 200 || response.status === 201) {
            let data = [];
            response.data.data.forEach(async (ele) => {
                if (ele.type === "FOLDER") {
                    const res = await documentsServices.fetchDocuments(companyId, ele.id);
                    // console.log('get folder items res = ', res);
                    if (res.status === 200 || res.status === 201) {
                        let tempSubMenu = [];
                        res.data.data.forEach((item) => {
                            if (item.type !== "FOLDER") tempSubMenu.push(item);
                        })
                        let temp = { ...ele, subMenu: tempSubMenu }
                        data.push(temp);
                    }
                } else {
                    data.push(ele);
                }
            })

            setDocuments(data);
        }
    }

    const handleSelectCard = (cardData, rowIndex, columnIndex) => {
        if (cardData.cardType === 'cover') return
        if (cardData.cardType === 'objective') {
            setCreatingObjectiveAfterSkip(true)
            setShowFirstColumn(false)
            // setSelectedCard({ rowIndex: rowIndex, columnIndex: columnIndex, cardType: cardData.cardType })
        }
        setLaBgImgData(defaultBgData);
        setLaAudioData(defaultAudio);
        setSelectedCard({ rowIndex: rowIndex, columnIndex: columnIndex, cardType: cardData.cardType })
    }

    const handleSelectQuestion = (ele, i) => {
        let gameType = constants.ILA_GAMETYPES[ele.designType];
        let preview = constants.ILA_PREVIEWS[ele.designType];
        // console.log('handleSelectQuestion = ', i, ' ', ele);
        setSelectedQuestion(i);
        setSelectedInteraction({ gameType: gameType, interactionType: ele.designType, previewUrl: preview });
        setSelectedColumns({ ...selectedColumns, secondColumn: 'editInteraction' });
    }

    const handleSaveChallenge = async () => {
        globalStates.handleProgressDialog(true, "Please wait...");
        let cards = [];
        for (let i = 0; i < list.length; i++) {
            cards.push({
                id: list[i].id,
                sequence: i + coverAndObjective.length + 1
            })
        }
        let sequencePayload = {
            companyId: companyId,
            microskillId: microskillId,
            challengeId: challengeId,
            cards: cards
        }
        // console.log('sequencePayload = ', sequencePayload);
        const response = await challengesServices.updateCardSequence(sequencePayload);
        // console.log('updateCardSequence response = ', response);
        if (response.status === 200 || response.status === 201) {
            localStorage.setItem('sequenceUpdated', true);
            let payload = {
                challengeId: challengeId,
                status: "INPROGRESS",
                setting: { minTimeSpent: minTimeSpent ? minTimeSpent : null }
            }
            if (list.length > 0) payload.status = "COMPLETED"

            // console.log('handleSaveChallenge payload = ', payload);
            const res = await challengesServices.updateChallenge(payload);
            // console.log('handleSaveChallenge res = ', res);
            if (res.status === 200 || res.status === 201) {
                if (editChallenge) {
                    localStorage.setItem('isChallengeUpdated', true)
                } else {
                    localStorage.setItem('isChallengeCreated', true);
                }
                // setIsSavingChallenge(false);
                globalStates.handleProgressDialog(false);
                router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`)
            }
        } else {
            globalStates.handleToast(true, 'Internal server error', 'error');
        }
        return
    }

    const handleSaveCard = async (type, cardData, withImage = false, isSuggested = false) => {
        if (type !== 'IMPORTED') globalStates.handleProgressDialog(true, 'Please wait...');
        let newSequence = list.length + coverAndObjective.length + 1;
        let payload = {
            companyId: companyId,
            microskillId: microskillId,
            challengeId: challengeId,
            cards: [
                {
                    cardType: cardData.cardType,
                    thumbImage: "",
                    size: cardData.thumbFileSize,
                    sequence: newSequence,
                    card: {
                        cardType: type,
                        ratio: "4:3",
                        contents: [],
                        link
                    },

                }
            ]

        }
        let thumbNail = null;

        //UPLOADING CARD THUMBNAIL - EXCLUDING IMPORTED IMAGE CARDS
        let ignoreThumbImgFor = ['IMPORTED', 'VTB']
        if (!ignoreThumbImgFor.includes(type)) {
            let thumbnailFormData = await commonUtil.getUploadMediaPayload(companyId, microskillId, challengeId, 'LAThumbnail', cardData.thumbImageFile);
            let res = await challengesServices.uploadThumbnail(thumbnailFormData);
            // console.log('upload thumnail response = ', res);
            if (res.status === 200 || res.status === 201) {
                thumbNail = res?.data?.data?.mediaResources[0]?.location;
                // payload.cards[0].thumbImage = thumbImg;
            } else {
                // console.log('THUMB IMAGE UPLOAD FAILED');
                return
            }
        }

        // console.log('handleSaveCard payload after thumbimg upload = ', payload);

        //INSERTING IMAGE URL IN PAYLOAD IF PRESENT.
        if (withImage) { //CHECKING IF CARD HAS IMAGE PRESENT IN IT 
            if (!isSuggested) {//CHECKING IF IMAGE IS SELECTED FROM SUGGESTED IMAGES - IF NOT THEN UPLOADING THAT IMAGE FILE
                let imageFormData = await commonUtil.getUploadMediaPayload(companyId, microskillId, challengeId, 'LAMediaFiles', cardData.imageFile);
                let res = await challengesServices.uploadMediaResources(imageFormData);
                // console.log('upload image response  = ', res);
                if (res.status === 200 || res.status === 201) {
                    payload.cards[0].card.contents.push({
                        name: 'IMAGE',
                        content: res.data.data.mediaResources[0].location,
                        type: "URL",
                        sequence: 1
                    });
                } else {
                    // console.log('IMAGE UPLOAD FAILED');
                    return
                }
            } else {//HERE THE IMAGE PRESENT INSIDE CARD IS SELECTED FROM SUGGESTED - DIRECTLY INSERTING SUGGESTED IMAGE URL INTO CONTENTS
                payload.cards[0].card.contents.push({
                    name: 'IMAGE',
                    content: cardData.suggestedImgUrl,
                    type: "URL",
                });
            }
        }

        if (laBgImgData?.bgImgFile) {
            let bgImageFormData = await commonUtil.getUploadMediaPayload(companyId, microskillId, challengeId, 'LAMediaFiles', laBgImgData.bgImgFile);
            let res = await challengesServices.uploadMediaResources(bgImageFormData);
            if (res.status === 200 || res.status === 201) {
                payload.cards[0].card.backgroundImageURL = res.data.data.mediaResources[0].location;
            } else {
                // console.log('BG IMAGE UPLOAD FAILED');
                return
            }
        }

        if (laAudioData?.audioFile) {
            let audioFormData = await commonUtil.jsonToFormData({ companyId, microskillId, challengeId, LAMediaFiles: laAudioData?.audioFile })
            let res = await challengesServices.uploadMediaResources(audioFormData);
            if (res.status === 200 || res.status === 201) {
                payload.cards[0].card.audioURL = res.data.data.mediaResources[0].location;
                payload.cards[0].card.audioMimeType = laAudioData.mimeType;
                payload.cards[0].card.audioFileName = laAudioData.fileName;
            } else {
                // console.log('AUDIO UPLOAD FAILED');
                return
            }
        }

        if (type === 'VTB') {
            //INSERTING DATA IN CONTENTS IN CASE OF YOUTUBE URL
            if (cardData.isYoutube) {
                payload.cards[0].card.contents.push({
                    name: 'YOUTUBE',
                    content: cardData.youtubeUrl,
                    type: "URL",
                    sequence: 1
                });
            } else {  //UPLOADING VIDEO FIRST THEN INSERTING DATA IN CONTENTS
                let videoFormData = await commonUtil.jsonToFormData({ companyId, microskillId, challengeId, LAMediaFiles: cardData.videoFile })
                let res = await challengesServices.uploadMediaResources(videoFormData);
                // console.log('upload video response  = ', res);
                if (res.status === 200 || res.status === 201) {
                    payload.cards[0].card.contents.push({
                        name: 'VIDEO',
                        content: res.data.data.mediaResources[0].location,
                        type: "URL",
                        sequence: 1
                    });
                } else {
                    // console.log('VIDEO UPLOAD FAILED');
                    return
                }
            }
        }

        //INSERTING CONTENTS IN PAYLOAD WRT EACH CARD TYPE.
        if (thumbNail) payload.cards[0].thumbImage = thumbNail;
        if (type === 'COVER') {
            // console.log('its COVER');
            //ADDING LOGO URL AND CARD DETAILS FOR COVER
            payload.cards[0].card.contents[0].sequence = 2
            payload.cards[0].card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#4F0060",
                sequence: 1
            });
            payload.cards[0].card.contents.push({
                name: 'DETAILS',
                content: {
                    cardLength: 0,
                    duration: 0,
                    score: 0
                },
                type: "JSON",
                color: "#FFFF00",
                sequence: 3
            });
        }
        if (type === 'OBJECTIVE') {
            // console.log('its OBJECTIVE');
            payload.cards[0].card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#4F0060",
                sequence: 1
            });
            payload.cards[0].card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 2
            });
        }
        if (type === 'IMPORTED') {
            // console.log('its IMPORTED');
            payload.cards[0].thumbImage = cardData.location;
            payload.cards[0].sequence = cardData.sequence;
            payload.cards[0].card.contents.push({
                name: 'IMAGE',
                content: cardData.location,
                type: "URL",
                sequence: 1
            });
        }
        if (type === 'ITB') {
            // console.log('its image title body');
            //ADDING TITLE AND BODY - IMAGE ALREADY INSERTED INSIDE CONTENTS
            payload.cards[0].card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#707070",
                sequence: 2

            });
            payload.cards[0].card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 3
            });
        }
        if (type === 'VTB') {
            payload.cards[0].thumbImage = cardData.thumbImage;
            payload.cards[0].card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#707070",
                sequence: 2

            });
            payload.cards[0].card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 3
            });
        }
        if (type === 'TB') {
            // console.log('its title body');
            //ADDING TITLE AND BODY INSIDE CONTENTS
            payload.cards[0].card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#707070",
                sequence: 1

            });
            payload.cards[0].card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 2
            });
        }
        if (type === 'B') {
            // console.log('its body only card');
            //ADDING BODY INSIDE CONTENTS
            payload.cards[0].card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                sequence: 1
            });
        }
        // console.log('handleSaveCard payload at end = ', payload);

        //CREATING ACTUAL CARD
        let response = await challengesServices.addLearningCards(payload);
        // console.log('handleSaveCard response = ', response);
        globalStates.handleProgressDialog(false);
        if (response && response.status === 200 || response.status === 201) {
            setLink(null);
            setLaAudioData(defaultAudio);
            setLaBgImgData(defaultBgData);
            if (type === "COVER") {
                fetchChallengesData();
                globalStates.handleToast(true, 'Cover card created successfully!', 'success');
                // setCoverAndObjective([payload.cards[0]]);
                // setMenuStatus({ ...menuStatus, covercard: 'done' });
                // setSelectedCard({ ...selectedCard, cardType: 'uploadOrCreate' });
                // setSelectedColumns({ ...selectedColumns, firstColumn: 'content' });
                // setShowFirstColumn(true);
                return
            }
            if (type === "OBJECTIVE") {
                setCoverAndObjective([...coverAndObjective, payload.cards[0]]);
                setIsObjectiveSkipped(false);
                setMenuStatus({ ...menuStatus, objectives: 'done' });
                setSelectedCard({ ...selectedCard, cardType: 'uploadOrCreate' });
                setSelectedColumns({ ...selectedColumns, firstColumn: 'content' });
                setShowFirstColumn(true);
                return
            }
            if (type === "IMPORTED") {
                localStorage.setItem('scrollToBottom', true);
                return response;
            }
            if (type === "ITB" || type === "I" || type === "TB" || type === "B") {
                localStorage.setItem('preview', payload.cards[0].thumbImage);
                localStorage.setItem('scrollToBottom', true);
                globalStates.handleToast(true, 'Card created successfully!', 'success');
                fetchChallengesData();
                return
            }
            if (type === "VTB") {
                // localStorage.setItem('preview', payload.cards[0].thumbImage);
                localStorage.setItem('scrollToBottom', true);
                globalStates.handleToast(true, 'Card created successfully!', 'success');
                fetchChallengesData();
                return
            }

        } else {
            globalStates.handleToast(true, 'Something went wrong, please try again!', 'error');
            return
        }

    }

    const handleUpdateCard = async (type, cardData, withImage = false, isSuggested = false) => {
        globalStates.handleProgressDialog(true, 'Please wait...');
        let payload = {
            companyId: companyId,
            microskillId: microskillId,
            challengeId: challengeId,
            cardId: cardData.cardId,
            cardData: {
                cardType: type === "COVER" ? "COVER" : "CREATED",
                card: {
                    cardType: type,
                    ratio: "4:3",
                    contents: [],
                    link: link,
                    backgroundImageURL: laBgImgData?.prevBgImageUrl || null,
                    audioURL: laAudioData?.audioFileUrl || null,
                    audioMimeType: laAudioData?.mimeType || null,
                    audioFileName: laAudioData?.fileName || null
                },
                thumbImage: "",
                // sequence: 1
            }
        }

        let thumbNail = null;

        let ignoreThumbImgFor = ['IMPORTED', 'VTB']
        if (!ignoreThumbImgFor.includes(type)) {
            let thumbnailFormData = await commonUtil.getUploadMediaPayload(companyId, microskillId, challengeId, 'LAThumbnail', cardData.thumbImageFile);
            // console.log('upload thumnail response = ', thumbnailFormData);
            let res = await challengesServices.uploadThumbnail(thumbnailFormData);
            if (res.status === 200 || res.status === 201) {
                thumbNail = res?.data?.data?.mediaResources[0]?.location;
                // payload.cards[0].thumbImage = thumbImg;
            } else return
        }

        // console.log('handleUpdateCard payload after thumbimg upload = ', payload);

        //INSERTING IMAGE URL IN PAYLOAD IF PRESENT.
        if (withImage) {
            //HERE CARD HAS IMAGE PRESENT IN IT 
            if (!isSuggested) {
                //HERE THE IMAGE IS NOT SELECTED FROM SUGGESTED IMAGES
                if (!cardData.imageFile) {
                    //HERE THE IMAGE PRESENT IN CARD IS NOT BEEN CHANGED SO WE HAVE TO PUSH EXISTING URL TO CONTENTS
                    payload.cardData.card.contents.push({
                        name: 'IMAGE',
                        content: cardData.prevImageUrl,
                        type: "URL",
                        sequence: 1
                    });
                    if (type === 'IMPORTED') {
                        localStorage.setItem('preview', cardData.prevImageUrl);
                        thumbNail = cardData.prevImageUrl;
                    }
                } else {
                    let imageFormData = await commonUtil.getUploadMediaPayload(companyId, microskillId, challengeId, 'LAMediaFiles', cardData.imageFile);
                    let res = await challengesServices.uploadMediaResources(imageFormData);
                    if (res.status === 200 || res.status === 201) {
                        payload.cardData.card.contents.push({
                            name: 'IMAGE',
                            content: res.data.data.mediaResources[0].location,
                            type: "URL",
                            sequence: 1
                        });
                        if (type === 'IMPORTED') thumbNail = res.data.data.mediaResources[0].location;
                    } else {
                        // console.log('IMAGE UPLOAD FAILED');
                        return
                    }
                }

            } else {
                //HERE THE IMAGE PRESENT INSIDE CARD IS SELECTED FROM SUGGESTED - DIRECTLY INSERTING SUGGESTED IMAGE URL INTO CONTENTS
                payload.cardData.card.contents.push({
                    name: 'IMAGE',
                    content: cardData.suggestedImgUrl,
                    type: "URL",
                });
            }
        }

        if (laBgImgData?.bgImgFile) {
            // console.log('background img changed');
            let bgImageFormData = await commonUtil.getUploadMediaPayload(companyId, microskillId, challengeId, 'LAMediaFiles', laBgImgData.bgImgFile);
            let res = await challengesServices.uploadMediaResources(bgImageFormData);
            console.log('upload image response bg = ', bgImageFormData);
            if (res.status === 200 || res.status === 201) {
                payload.cardData.card.backgroundImageURL = res.data.data.mediaResources[0].location;
            } else {
                // console.log('BG IMAGE UPLOAD FAILED');
                return
            }
        }

        if (laAudioData?.audioFile) {
            let audioFormData = await commonUtil.jsonToFormData({ companyId, microskillId, challengeId, LAMediaFiles: laAudioData?.audioFile });
            let res = await challengesServices.uploadMediaResources(audioFormData);
            if (res.status === 200 || res.status === 201) {
                payload.cardData.card.audioURL = res.data.data.mediaResources[0].location;
                payload.cardData.card.audioMimeType = laAudioData.mimeType;
                payload.cardData.card.audioFileName = laAudioData.fileName;
            } else {
                // console.log('AUDIO UPLOAD FAILED');
                return
            }
        }

        //INSERTING CONTENTS IN PAYLOAD WRT EACH CARD TYPE.
        if (thumbNail) payload.cardData.thumbImage = thumbNail;

        if (type === 'IMPORTED') {
            payload.cardData.cardType = 'IMPORTED';
            payload.cardData.sequence = cardData.sequence;
        }

        if (type === 'ITB') {
            // console.log('its image title body');
            //ADDING TITLE AND BODY - IMAGE ALREADY INSERTED INSIDE CONTENTS
            payload.cardData.card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#707070",
                sequence: 2

            });
            payload.cardData.card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 3
            });
        }

        if (type === 'VTB') {
            //INSERTING DATA IN CONTENTS IN CASE OF YOUTUBE URL
            if (cardData.isYoutube) {
                payload.cardData.card.contents.push({
                    name: 'YOUTUBE',
                    content: cardData.youtubeUrl,
                    type: "URL",
                    sequence: 1
                });
            } else {  //UPLOADING VIDEO FIRST THEN INSERTING DATA IN CONTENTS
                if (typeof (cardData.videoFile) === 'string') {
                    //HERE VIDEO IS NOT CHANGED
                    payload.cardData.card.contents.push({
                        name: 'VIDEO',
                        content: cardData.videoFile,
                        type: "URL",
                        sequence: 1
                    });

                } else {
                    let videoFormData = await commonUtil.jsonToFormData({ companyId, microskillId, challengeId, LAMediaFiles: cardData.videoFile });
                    let res = await challengesServices.uploadMediaResources(videoFormData);
                    // console.log('upload video response  = ', res);
                    if (res.status === 200 || res.status === 201) {
                        payload.cardData.card.contents.push({
                            name: 'VIDEO',
                            content: res.data.data.mediaResources[0].location,
                            type: "URL",
                            sequence: 1
                        });
                    } else {
                        // console.log('VIDEO UPLOAD FAILED');
                        return
                    }
                }

            }

            payload.cardData.thumbImage = cardData.thumbImage;
            payload.cardData.card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#707070",
                sequence: 2

            });
            payload.cardData.card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 3
            });

        }

        if (type === 'TB') {
            // console.log('its title body');
            //ADDING TITLE AND BODY INSIDE CONTENTS
            payload.cardData.card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#707070",
                sequence: 1

            });
            payload.cardData.card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 2
            });
        }

        if (type === 'B') {
            // console.log('its body only card');
            //ADDING BODY INSIDE CONTENTS
            payload.cardData.card.contents.push({
                name: 'BODY',
                content: cardData.body,
                type: "TEXT",
                color: "#4F0060",
                sequence: 1
            });
        }

        if (type === 'COVER') {
            payload.cardData.card.contents.push({
                name: 'TITLE',
                content: cardData.title,
                type: "TEXT",
                color: "#707070",
                sequence: 2

            });
        }

        // console.log('handleUpdateCard payload at END = ', payload);
        // return
        let response = await challengesServices.updateLearningCard(payload);
        console.log('handleUpdateCard response = ', response);
        if (response.status === 200 || response.status === 201) {
            globalStates.handleProgressDialog(false);
            // setSelectedCard({...selectedCard, cardType: "default"});
            setLink(null);
            setLaAudioData(defaultAudio);
            setLaBgImgData(defaultBgData);
            if (type === 'VTB') {
                localStorage.setItem('isVtbSaved', true);
            } else {
                localStorage.setItem('preview', thumbNail);
            }
            if (type !== "COVER") localStorage.setItem('scrollToItem', cardData.cardId);
            globalStates.handleToast(true, 'Card updated successfully!', 'success');
            fetchChallengesData();
        }
    }

    const handleDeleteCard = async (e, item) => {
        e.stopPropagation();
        // console.log('handleDeleteCard item = ', item);
        // return
        let confirm = window.confirm("Do you really want to delete this card?")
        if (confirm) {
            let payload = {
                companyId: companyId,
                microskillId: microskillId,
                challengeId: challengeId,
                cardId: item.id
            }
            // console.log('handleDeleteCard payload = ', payload);
            let res = await challengesServices.deleteLearningCard(payload);
            // console.log('handleDeleteCard response = ', res);
            if (res.status === 200 || res.status === 201) {
                // setIsLoading(false);
                // fetchChallengesData();
                if (item.cardType === 'COVER' || item.cardType === 'OBJECTIVE') {
                    let filtered = coverAndObjective.filter(ele => ele.id !== item.id);
                    // console.log('filtered c&o = ', filtered);
                    setCoverAndObjective(filtered);
                } else {
                    let filtered = list.filter(ele => ele.id !== item.id);
                    // console.log('filtered = ', filtered);
                    setList(filtered);
                }
                globalStates.handleToast(true, "Deleted Successfully!", "success");
            } else {
                globalStates.handleToast(true, "Internal Server Error.", "error");
                return
            }
        } else {
            return
        }



    }

    const handleBulkUpload = async (files) => {
        setLoadingCard(true);
        let tempImporting = [];
        for (let i = 0; i < files.length; i++) {
            let blob = URL.createObjectURL(files[i]);
            tempImporting.push({
                thumbFileSize: files[i].size,
                thumbImage: blob,
            })
        }
        setImportingList(tempImporting);
        setSelectedColumns({ ...selectedColumns, firstColumn: 'importingList' });
        let mediaLocations = [];
        let newSequence = list.length + coverAndObjective.length + 1;
        for (let i = 0; i < files.length; i++) {
            let payload = await commonUtil.getUploadMediaPayload(companyId, microskillId, challengeId, 'LAMediaFiles', files[i]);
            // console.log('handleBulkUpload payload ', i, ' = ', payload);
            const res = await challengesServices.uploadMediaResources(payload);
            if (res.status === 200 || res.status === 201) {
                let mediaResources = res.data.data.mediaResources;
                mediaLocations.push({
                    cardType: 'IMPORTED',
                    thumbFileSize: mediaResources[0].size,
                    originalname: mediaResources[0].originalname,
                    location: mediaResources[0].location,
                    sequence: newSequence + i
                })
            }
        }


        if (mediaLocations.length) {
            for (let i = 0; i < mediaLocations.length; i++) {
                let response = await handleSaveCard('IMPORTED', mediaLocations[i]);
                // console.log('response for imported no. ', i, ' = ', response);
                if (response.status === 200 || response.status === 201) {
                    localStorage.setItem('preview', mediaLocations[0].location);
                }
            }
            setImported(true);
        }

    }

    const handleMenuClick = () => {
        return
    }

    const handleUpdateName = async () => {
        if (isEmpty(challengeName)) {
            globalStates.handleToast(true, 'Please enter the challenge name', 'error')
            return
        }
        let oldName = query.get("challengeName");
        let newName = challengeName;
        if (oldName === newName) {
            //here name is not changed, so no need to call api
            setEditName(false);
        } else {
            // console.log('challenge_id = ', challengeId);
            if (challengeId) {
                let payload = {
                    challengeId: challengeId,
                    name: challengeName
                }
                const res = await challengesServices.updateChallenge(payload);
                // console.log('update name response = ', res);
                if (res.status === 200 || res.status === 201) {
                    router.history.replace(`/microskill-studio/learning-aid?edit=true&&challengeName=${challengeName}&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}&&challenge_id=${challengeId}`);
                    setEditName(false);
                } else {
                    globalStates.handleToast(true, 'Internal server error', 'error')
                }
            }
        }
    }

    const handleCancelNameChange = () => {
        let oldName = query.get("challengeName");
        setChallengeName(oldName);
        setEditName(false);
    }

    const handleBack = () => {
        router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=${projectId}&&ms_id=${microskillId}`)
    }

    const handleAttachAudio = (e) => {
        let file = e.target.files[0];
        if (file) {
            let fileUrl = URL.createObjectURL(file);
            let audioDataCopy = {
                ...laAudioData,
                audioFile: file,
                fileName: file.name,
                audioFileUrl: fileUrl,
                mimeType: file.type
            };
            setLaAudioData(audioDataCopy)
        }
    }

    const handleRemoveAudio = () => {
        let audioDataCopy = {
            ...laAudioData,
            audioFile: null,
            fileName: null,
            audioFileUrl: null,
            mimeType: null
        };
        setLaAudioData(audioDataCopy);
    }

    const handleChangeLaBgImg = (event) => {
        if (event.target.files && event.target.files[0]) {
            let file = event.target.files[0];
            let fileUrl = URL.createObjectURL(file);
            let bgImgDataCopy = {
                ...laBgImgData,
                bgImgFile: file,
                bgImg: fileUrl
            };
            setLaBgImgData(bgImgDataCopy);
        }
    }

    const handleTimeSpentChange = (e) => {
        const value = e.target.value;
        if (!value.length) {
            setMinTimeSpent(null);
            // setShowSaveMinTime(false);
            return
        }

        if (parseInt(value) < 0 || parseInt(value) > constants.LA_MINTIMESPENT_LIMIT) return
        setMinTimeSpent(parseInt(value));

    }

    const handleWithMinTimeSpent = () => {
        setWithMinTimeSpent(!withMinTimeSpent);
        setMinTimeSpent(null);
    }


    if (isLoading) {
        return (
            <LoadingContainer />
        )
    } else {
        return (
            <div className={classes.main}>
                <div className={classes.header}>
                    {selectedColumns.firstColumn === 'content' ? <img onClick={handleBack} src='/images/gba-assets/left-arrow.svg' alt='left-arrow' /> : null}
                    {isEditName ?
                        <div className={classes.editName}>
                            <input type='text' value={challengeName} onChange={(e) => setChallengeName(e.target.value)} />
                            <CustomButton
                                btnText={'Save'}
                                handleClick={handleUpdateName} />
                            <CustomButton
                                btnText={'Cancel'}
                                handleClick={handleCancelNameChange} />
                        </div>
                        : <h1>
                            {challengeName}
                            <img src='/images/icons/pencil-edit-button.svg' alt='' onClick={() => setEditName(true)} />
                        </h1>}

                    <div className={classes.minTimeSpent}>
                        <span style={{ marginRight: '1rem' }}>
                            <Label labelText={"Minimum Time Spent"} fs='0.8rem' htmlFor={'min-time-spent'} />
                            <Checkbox
                                id='min-time-spent'
                                checked={withMinTimeSpent}
                                onChange={handleWithMinTimeSpent}
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 18, color: '#f4511e' } }}
                            />
                        </span>

                        {withMinTimeSpent ?
                            <>
                                <input
                                    type='number'
                                    min={0}
                                    value={minTimeSpent || minTimeSpent === 0 ? minTimeSpent : ''}
                                    onChange={handleTimeSpentChange}
                                // width={minTimeSpent.toString().length > 1 ? `calc(${minTimeSpent.toString().length} * 15px)` : '30px'}
                                />
                                <Label labelText={"Seconds"} fs='0.71rem' fw={600} color='rgba(112, 112, 112,0.9)' />
                            </> : null
                        }
                    </div>

                </div>
                {showFirstColumn ? <div className={`${classes.column1} ${isEdit ? classes.disableEvents : ''}`}>
                    <StepperMenu handleMenuClick={handleMenuClick} menuData={menuData} menuStatus={menuStatus} selectedColumns={selectedColumns} />
                    {
                        selectedColumns.firstColumn === 'createOwn' ?
                            <CardTypeColumn
                                list={list}
                                setList={setList}
                                setImportingList={setImportingList}
                                showImportBtn={showImportBtn}
                                showCoverAndObjective={showCoverAndObjective}
                                coverAndObjective={coverAndObjective}
                                isObjectiveSkipped={isObjectiveSkipped}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                                handleSelectCard={handleSelectCard}
                                selectedColumns={selectedColumns}
                                setSelectedColumns={setSelectedColumns}
                                setPreview={setPreview}
                                handleBulkUpload={handleBulkUpload}
                                defaultBgData={defaultBgData}
                                setLaBgImgData={setLaBgImgData}
                                defaultAudio={defaultAudio}
                                setLaAudioData={setLaAudioData}
                            /> : null
                    }
                    {
                        selectedColumns.firstColumn === 'content' ?
                            <Content
                                setShowCoverAndObjective={setShowCoverAndObjective}
                                setShowImportBtn={setShowImportBtn}
                                // coverCard={coverCard}
                                coverAndObjective={coverAndObjective}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                                selectedColumns={selectedColumns}
                                setSelectedColumns={setSelectedColumns}
                                setSelectedInteraction={setSelectedInteraction}
                                setPreview={setPreview}
                                isActive={isActive}
                                setIsActive={setIsActive}
                                list={list}
                                setList={setList}
                                handleSaveChallenge={handleSaveChallenge}
                                isSavingChallenge={isSavingChallenge}
                                setIlaCardId={setIlaCardId}
                                setSelectedQuestion={setSelectedQuestion}
                                handleDeleteCard={handleDeleteCard}
                                setIsEdit={setIsEdit}
                                setEditCardData={setEditCardData}
                                setShowFirstColumn={setShowFirstColumn}
                                setIsEditInteraction={setIsEditInteraction}
                                setLink={setLink}
                                laAudioData={laAudioData}
                                setLaAudioData={setLaAudioData}
                                laBgImgData={laBgImgData}
                                setLaBgImgData={setLaBgImgData}
                            /> : null
                    }
                    {
                        selectedColumns.firstColumn === 'importingList' ?
                            <ImportingList
                                progress={progress}
                                setProgress={setProgress}
                                setPreview={setPreview}
                                setSelectedCard={setSelectedCard}
                                imported={imported}
                                setImported={setImported}
                                importingList={importingList}
                                selectedColumns={selectedColumns}
                                setSelectedColumns={setSelectedColumns}
                                setLoadingCard={setLoadingCard} /> : null
                    }
                    {
                        selectedColumns.firstColumn === 'selectInteraction' ?
                            <InteractionTypes
                                list={list}
                                setList={setList}
                                ilaCardId={ilaCardId}
                                selectedInteraction={selectedInteraction}
                                setSelectedInteraction={setSelectedInteraction}
                                selectedColumns={selectedColumns}
                                setSelectedColumns={setSelectedColumns}
                                setSelectedQuestion={setSelectedQuestion} /> : null
                    }
                    {
                        selectedColumns.firstColumn === 'manageInteraction' ?
                            <ManageInteractions
                                list={list}
                                setList={setList}
                                ilaCardId={ilaCardId}
                                type={selectedInteraction.interactionType}
                                selectedQuestion={selectedQuestion}
                                setSelectedQuestion={setSelectedQuestion}
                                handleSelectQuestion={handleSelectQuestion}
                                selectedColumns={selectedColumns}
                                setSelectedColumns={setSelectedColumns}
                                selectedInteraction={selectedInteraction}
                                setSelectedInteraction={setSelectedInteraction}
                                isEditInteraction={isEditInteraction}
                                setIsEditInteraction={setIsEditInteraction}
                                fetchChallengesData={fetchChallengesData}
                                coverAndObjective={coverAndObjective}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                                setPreview={setPreview}
                                maxScore={maxScore}
                                setMaxScore={setMaxScore}
                                setLoadingDialog={setLoadingDialog} /> : null
                    }
                </div> : null}
                <div style={{ width: showFirstColumn ? '72%' : '100%' }} className={classes.column2}>
                    {
                        selectedColumns.secondColumn === 'editContentSection' ?
                            <EditContentSection
                                list={list}
                                setList={setList}
                                preview={preview}
                                setPreview={setPreview}
                                menuStatus={menuStatus}
                                setMenuStatus={setMenuStatus}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                                selectedColumns={selectedColumns}
                                setSelectedColumns={setSelectedColumns}
                                setShowImportBtn={setShowImportBtn}
                                cardType={selectedCard.cardType}
                                setImportingList={setImportingList}
                                coverAndObjective={coverAndObjective}
                                setCoverAndObjective={setCoverAndObjective}
                                setShowFirstColumn={setShowFirstColumn}
                                setIsObjectiveSkipped={setIsObjectiveSkipped}
                                setShowCoverAndObjective={setShowCoverAndObjective}
                                creatingObjectiveAfterSkip={creatingObjectiveAfterSkip}
                                handleSaveCard={handleSaveCard}
                                handleBulkUpload={handleBulkUpload}
                                loadingCard={loadingCard}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                                editCardData={editCardData}
                                setEditCardData={setEditCardData}
                                handleUpdateCard={handleUpdateCard}
                                setIsActive={setIsActive}
                                enableAddLinkFor={enableAddLinkFor}
                                enableAttachAudioFor={enableAttachAudioFor}
                                documents={documents}
                                link={link}
                                setLink={setLink}
                                laAudioData={laAudioData}
                                setLaAudioData={setLaAudioData}
                                defaultAudio={defaultAudio}
                                handleAttachAudio={handleAttachAudio}
                                handleRemoveAudio={handleRemoveAudio}
                                laBgImgData={laBgImgData}
                                setLaBgImgData={setLaBgImgData}
                                defaultBgData={defaultBgData}
                                handleChangeLaBgImg={handleChangeLaBgImg}
                            /> : null
                    }
                    {
                        selectedColumns.secondColumn === 'editInteraction' ?
                            <EditInteraction
                                list={list}
                                setList={setList}
                                ilaCardId={ilaCardId}
                                selectedQuestion={selectedQuestion}
                                selectedInteraction={selectedInteraction}
                                selectedCard={selectedCard}
                                setSelectedCard={setSelectedCard}
                                selectedColumns={selectedColumns}
                                setSelectedColumns={setSelectedColumns}
                            /> : null
                    }
                </div>
                <LoadingDialog
                    progressDialogOpen={loadingDialog}
                    setProgressDialogOpen={setLoadingDialog}
                    dialogText='Please wait...'
                />
            </div>
        )
    }


}

export default LearningAid;
