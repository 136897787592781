import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    label: {
        // padding: '1rem 0rem',
        color: "rgb(0, 0, 0, 0.9)",
        fontFamily: "Montserrat",
        // fontSize: "0.8rem",
        // fontWeight: "700",
    },
}));

const Label = ({labelText, fs='0.7rem', fw=700, color="rgb(0, 0, 0, 0.9)", htmlFor = ''}) => {
    const classes = useStyles();
    const customStyles = {
      fontSize: fs,
      fontWeight: fw,
      color: color,
      cursor: htmlFor.length && 'pointer'
    }
  return (
    <label htmlFor={htmlFor} className={classes.label} style={customStyles}>{labelText}</label>
  )
}

export default Label;


