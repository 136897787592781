import * as React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    linearProgress : {
        backgroundColor: '#F2F2F2',
        '& span':{
            backgroundColor: '#f3c50e'
        },
        '& .MuiLinearProgress-bar1':{
            backgroundColor: '#f3c50e'
        }
    },
    typography : {
        color : '#f3c50e',
        fontSize : '0.7rem'
    }
}));


function LinearProgressWithLabel(props) {
    const classes = useStyles();
    // const [progress, setProgress] = React.useState(0);

    // React.useEffect(() => {
    //     const timer = setInterval(() => {
    //         setProgress((prevProgress) => (prevProgress >= 100 ? 100 : (prevProgress + Math.floor(Math.random() * 10))>100?100:prevProgress + Math.floor(Math.random() * 10)));
    //         // console.log('setInterval still running');
    //     }, 100);
    //     // setTimeout(()=>{
    //     //     clearInterval(timer);
    //     // }, 10000)
    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '90%', mr: 1 }}>
                <LinearProgress className={classes.linearProgress} variant="determinate" color='warning' {...props}/>
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2"className={classes.typography}>
                    {`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    // value: PropTypes.number.isRequired,
    
};

const LinearWithValueLabel = ({progress}) => {
    // console.log('LinearWithValueLabel progress = ', progress);
    return (
        <Box sx={{ width: '100%' }}>
            <LinearProgressWithLabel value={progress} />
        </Box>
    );
}
export default LinearWithValueLabel;