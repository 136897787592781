import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    ContentContainer: {
        position: 'relative',
        padding: '1rem',
        borderBottom: "1px solid rgb(112, 112, 112, 0.3)",
        paddingBottom: '3rem'

    },
    ContentHeading: {
        fontSize: "1.2rem",
        color: "#707070",
        padding: '1rem 0rem',
        fontFamily: "Montserrat",
        fontWeight: 700,

    },
    CompainesContainer: {
        width: "50%",
        margin: "1rem 0rem",
    },
    ProjectsContainer: {
        width: "30%",
        margin: "1.5rem 0rem"
    },
    buttonWrapper: {
        padding: "1.5rem 0 1rem 0",
        // display: 'flex',
    },
    btn: {
        width: '190px',
        borderRadius: '2px',
        height: '42px',
        padding: '0 4px',
        fontFamily: "Montserrat",
        fontWeight: 700,
        fontSize: "0.8rem",
        letterSpacing: 1,
        cursor: "pointer",
        boxShadow: "none",
        color: '#00000090',
        textTransform: 'math-auto',
        backgroundColor: '#cccccc52',
        "&:hover": {
            backgroundColor: '#cccccc52',
            boxShadow: "none"
        }
    },
    active: {
        color: '#fff',
        backgroundColor: '#f4511e',
        "&:hover": {
            backgroundColor: '#f4511e',
            boxShadow: "none"
        }
    },
    btnUser: {
        borderRadius: 8,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
    },
    btnManager: {
        borderRadius: 8,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
    },
    lastUploadedFormatBtn: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: '#f4511e',
        fontSize: '1rem',
        fontWeight: 600,
        fontFamily: 'Montserrat',
        padding: '0.8rem',
        borderRadius: '4px',
        textDecoration: 'underline'
    }
}));

export default useStyles;