import React, { useCallback, useState } from 'react';
import { KeywordInput } from './style';
import HorizontalRuler from './components/HorizontalRuler';
import Dialog from '@mui/material/Dialog';
import AddKeywordEvent from './AddKeywordEvent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import FormControl from './components/FormControl';
import Button2 from './components/Button2';
import ReactTooltip from 'react-tooltip';



const KeywordCards = (props) => {
  const classes = custom_styles();
  const { skills, setSkills, transcript, skill, handleMouseOver, handleMouseLeave } = props;
  const [cardSkill, setCardSkill] = useState({ ...skill });
  const initialTitle = cardSkill.head;
  const [skillTitle, setSkillTitle] = useState(initialTitle);
  const [editKeyword, setEditKeyword] = useState(null);
  const [editKeywordDialogOpen, setEditKeywordDialogOpen] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteKeywordItem, setDeleteKeywordItem] = useState();
  const [limit, setLimit] = useState(cardSkill.limit);


  const addKeywordAction = () => {
    setEditKeywordDialogOpen(true);
  }

  const updateSkills = (newSkill) => {
    let keybox = newSkill.keywords.map((item) => item.name);
    newSkill.keywordInput = keybox;
    const newArray = skills.map((obj) =>
      obj.id == newSkill.id ? newSkill : obj,
    );
    setSkills(newArray);
  };

  const updateLimit = (newLimit) => {
    setLimit(newLimit);
    const updatedSkill = { ...cardSkill, limit: newLimit };
    setCardSkill(updatedSkill);
    updateSkills(updatedSkill);
  };

  const editKeywordAction = (item) => {
    setEditKeyword(item);
    setEditKeywordDialogOpen(true);
  };
  const deleteKeyword = (item) => {
    setDeleteKeywordItem(item);
    setDeletePopup(true);
  }

  const deleteKeywordEvent = () => {
    setDeletePopup(false);
    const updatedKeywords = cardSkill.keywords.filter(
      (obj) => obj.id != deleteKeywordItem.id,
    );
    const updatedSkill = { ...cardSkill, keywords: updatedKeywords };
    setCardSkill(updatedSkill);
    updateSkills(updatedSkill);
    setDeleteKeywordItem();
  };

  const handleOnDragEnd = (result) => {
    if (result.source && result.destination) {
      const items = Array.from(cardSkill.keywords);
      const [reorderedItems] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItems);
      const updatedSkill = { ...cardSkill, keywords: items };
      setCardSkill(updatedSkill);
      updateSkills(updatedSkill);
    }
  };

  const checkIfKeywordIsInTranscript = (keyword) => {
    return transcript?.toLowerCase().includes(keyword?.toLowerCase());
  }

  const KeywordItems = cardSkill.keywords.map((keyword, index) => (
    <Draggable key={keyword.id} draggableId={keyword.id} index={index}>
      {(provided) => (
        <li
          key={keyword.id}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={classes.keywordItemStyle}>
          <div
            title={!checkIfKeywordIsInTranscript(keyword.name) ? 'Keyword not found in transcript' : keyword.name}
            style={{ borderColor: !checkIfKeywordIsInTranscript(keyword.name) && '#EB3917' }}
            className={classes.keywordContainer}
            data-tip
            data-for={keyword.id}>
            {!checkIfKeywordIsInTranscript(keyword.name) && <i className="fa-solid fa-circle-exclamation"></i>}
            <button
              onMouseEnter={() => handleMouseOver(keyword.name)}
              className={classes.editKeywordStyle}
              onClick={() => { editKeywordAction(keyword); }}>
              {keyword.name}
            </button>
            <FontAwesomeIcon
              icon={faClose}
              className={classes.deleteKeywordStyle}
              onClick={() => deleteKeyword(keyword)}
            />
          </div>
          {keyword.similarKeywords.length > 0 && (
            <ReactTooltip
              id={keyword.id}
              place="bottom"
              type="light"
              effect="solid"
              textColor="#707070"
              className={classes.TipStyle}
              multiline={true}>
              <ul className={classes.similarKeywordulStyle}>
                {renderSimilarKeywords(keyword.similarKeywords)}
              </ul>
            </ReactTooltip>
          )}
        </li>
      )}
    </Draggable>
  ));

  const renderSimilarKeywords = (similarKeywords) => {
    return similarKeywords.map((keyword, index) => (
      <li key={keyword.id} className={classes.keywordItemStyle}>
        <div className={classes.similarKeywordContainer}>
          <label
            style={{
              textAlign: 'left',
              color: '#707070',
              font: 'normal normal normal 10px Open Sans',
              marginRight: 3,
              marginLeft: 3,
            }}>
            {keyword.name}
          </label>
        </div>
      </li>
    ));
  };

  const AddKeywordView = ({ keyword }) => {
    console.log('keyword = ', keyword);
    return (
      <div id="edit-keyword-event-dialog">
        <Dialog
          fullWidth={true}
          open={editKeywordDialogOpen}
          onClose={() => {
            setEditKeywordDialogOpen(false);
          }}>
          <AddKeywordEvent
            keyword={keyword}
            onClose={() => {
              setEditKeyword(null);
              setEditKeywordDialogOpen(false);
            }}
            onAddEvent={(item) => {
              setEditKeyword(null);
              const found = cardSkill.keywords.find((obj) => {
                return obj.id == item.id;
              });
              let updatedKeywords = [];
              if (found) {
                updatedKeywords = cardSkill.keywords.map((keyword) =>
                  keyword.id == item.id ? item : keyword,
                );
              } else {
                updatedKeywords = [...cardSkill.keywords, item];
              }
              const skillObj = {
                ...cardSkill,
                keywords: updatedKeywords,
              };
              setCardSkill(skillObj);
              updateSkills(skillObj);
              setEditKeywordDialogOpen(false);
            }}
          />
        </Dialog>
      </div>
    );
  };
  // card render

  return (
    <li key={skill.id} id={skill.id} className={classes.liStyle}>
      <div className={classes.container}>
        <KeywordInput
          type={'text'}
          placeholder={'Add Skill Tag Here'}
          value={skillTitle}
          onChange={(e) => {
            const value = e.target.value;
            setSkillTitle(value);
          }}
          onBlur={(e) => {
            const value = e.target.value;
            const skillObj = {
              ...cardSkill,
              head: value,
            };
            setCardSkill(skillObj);
            updateSkills(skillObj);
          }}></KeywordInput>
        <HorizontalRuler />
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="keywordList">
            {(provided) => (
              <ul
                onMouseLeave={handleMouseLeave}
                className={classes.ulStyle}
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {KeywordItems}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        {true && (
          <button
            className={classes.addKeywordStyle}
            onClick={addKeywordAction}>
            Add Keyword +
          </button>
        )}
        {/* <div className={classes.scoreContainer}>
          <div className={classes.scoreInnerContainer}>
            <Label
              style={{ paddingBottom: 0, marginLeft: 5, marginRight: 5 }}
              className={classes.scoreTitle}>
              Score:
            </Label>
            <NumericInput
              width={'50px'}
              isSimpleStepper={true}
              border={'none'}
              style={custom_styles.scoreTextInput}
              min={0}
              value={limit}
              onChangeText={(t) => updateLimit(t)}
            />
          </div>
        </div> */}
      </div>
      {editKeywordDialogOpen && <AddKeywordView keyword={editKeyword} />}
      {deletePopup && (
        <Dialog open={deletePopup} onClose={() => setDeletePopup(false)}>
          <ProgressContainer>
            <ProgressText>
              Are you sure you want to delete this Keyword?
            </ProgressText>
            <ProgressText>
              <FormControl>
                <Actions>
                  <Button2 onClick={deleteKeywordEvent}>Yes Delete</Button2>
                  <Button2
                    onClick={() => setDeletePopup(false)}
                    transparent={true}
                    style={{ marginLeft: '5px' }}>
                    Cancel
                  </Button2>
                </Actions>
              </FormControl>
            </ProgressText>
          </ProgressContainer>
        </Dialog>
      )}
    </li>
  );
};

export default KeywordCards;

const custom_styles = makeStyles({
  liStyle: {
    width: '44%',
    height: '95%', //'80%',
    maxHeight: '327px',
    maxWidth: '225px',
    // border: '0.5px solid #707070',
    backgroundColor: '#F5F5F5',
    borderRadius: '5px',
    marginRight: '13px',
    marginTop: '13px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 6.5px 6.5px 6.5px',
    height: '100%',
  },
  ulStyle: {
    listStyle: 'none',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    gap: 5,
    // flexDirection: 'column',
    padding: '0',
    maxHeight: 'calc(100% - (44px + 30px))',
    minHeight: 'calc(100% - (44px + 30px))',
    overflow: 'auto',
  },

  addKeywordStyle: {
    width: '131px',
    height: '30px',
    // background: '#F4511E',
    cursor: 'pointer',
    background: 'transparent',
    color: '#F4511E',
    border: 'none',
    borderRadius: '5px',
    font: 'normal normal bold 13px/17px Open Sans',
    marginTop: '6.5px',
  },
  addKeywordDiffStyle: {
    width: '20px',
    height: '30px',
    background: 'transparent',
    color: '#F4511E',
    border: 'none',
    font: 'normal normal bold 20px Open Sans',
    alignSelf: 'center',
  },
  scoreContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: 'auto',
    marginBottom: '10px',
  },

  scoreInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    background: '#fff',
    border: '0.5px solid #484848',
    borderRadius: 3,
    paddingRight: 5,
  },
  scoreTitle: {
    paddingBottom: '0px',
    alignSelf: 'center',
    marginRight: '18px',
  },
  scoreTextInput: { maxWidth: '63px', width: '63px', height: '30px' },
  keywordItemStyle: {
    width: 'auto',
    maxWidth: '99%',
    height: '30px',
    border: 'none',
    marginTop: '3px',
    marginBottom: '3px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  similarKeywordulStyle: {
    listStyle: 'none',
    justifyContent: 'flex-start',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    columnGap: 5,
    flexDirection: 'column',
    padding: '0',
    maxHeight: '99%',
    overflow: 'auto',
  },
  similarKeywordContainer: {
    height: '25px',
    background: 'transparent',
    border: '0.25px solid #484848',
    opacity: 0.5,
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '90%',
    maxWidth: 136,
    minWidth: 136,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'wrap',
  },

  keywordContainer: {
    // width: '131px',
    height: '30px',
    background: 'transparent',
    border: '0.5px solid #F4511E',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    '& > .fa-circle-exclamation': {
      color: '#EB3917',
      marginLeft: '0.5rem'
    },
  },
  editKeywordStyle: {
    cursor: 'pointer',
    background: 'transparent',
    color: '#707070',
    font: 'normal normal bold 13px/17px Open Sans',
    border: 'none',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: '0.5rem'
    // width: '80%',
  },
  deleteKeywordStyle: {
    color: '#F4511E',
    fontSize: '0.8rem',
    marginRight: '10px',
    cursor: 'pointer',
  },

  angleStyle: {
    color: '#F4511E',
    fontSize: '0.7rem',
    marginRight: '10px',
  },
  TipStyle: {
    font: 'normal normal normal 12px Montserrat',
    background: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
  },
});

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;
