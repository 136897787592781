import React from 'react';
import ReactQuill from 'react-quill';
import '../../../node_modules/react-quill/dist/quill.snow.css';

const Editor = (props) => {
  const { readOnly = false } = props;
  const { showModules = true } = props;
  const { preserveWhitespace = true } = props;
  return (
    <ReactQuill
      ref={props.quillRef}
      theme={props.theme}
      onChange={props.handleChange}
      onBlur={props.onBlur}
      value={props.value}
      modules={showModules == true ? Editor.modules : {}}
      formats={showModules == true ? Editor.formats : {}}
      //   bounds={'.app'}
      style={props.style}
      className={props.className}
      placeholder={props.placeholder}
      readOnly={readOnly}
      preserveWhitespace={preserveWhitespace}
    />
  );
};

export default Editor;

Editor.modules = {
  toolbar: [
    [{ font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline'],
    [{ indent: '-1' }, { indent: '+1' }, { indent: '0' }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = ['font', 'size', 'bold', 'italic', 'underline', 'indent'];
