import React, { useEffect, useRef } from "react";

//mui
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import LoadingButton from '@mui/lab/LoadingButton';

//components
import Loader from "../../../custom-styled/loader";


//styles
import useStyles from "./index.styles";
import isEmpty from "../../../../utils/isEmpty";
import commonUtil from "../../../../utils/commonUtil";
import CustomButton from "../../../custom-styled/button";
import CustomInput from "../../../custom-styled/custom-input";

//context
// import { GlobalStates } from "../../App";

const Table = ({
    loader = false,
    showGenerate,
    handleGenerate,
    progress = 0,
    loaderText = "GENERATING REPORT ",
    withRowsSelection = true,
    tableHeadings,
    tableData,
    handleBackClick,
    handleForwardClick,
    pageNo = 1,
    pageCount = 1,
    rows = 10,
    handleChangeNumberOfRows,
    pagination = {
        limit: 0,
        pageCount: 0,
        startIndex: -1,
        totalCount: 0
    },
    largeReport = false,
    handleSendEmail,
    emailId,
    setEmailId,
    sendingEmail

}) => {
    const styles = useStyles();
    //   const router = useRouter();
    const loadingDivRef = useRef(null);


    // console.log('MasteroTable tableHeadings = ', tableHeadings);

    useEffect(() => {
        if (loader || showGenerate) {
            scrollToStart()
        }
    }, [showGenerate, loader])



    const scrollToStart = () => {
        if (loadingDivRef.current) {
            loadingDivRef.current.scrollLeft = 0;
        }
    };

    const getPaginationText = () => {
        return `${pagination.startIndex + 1} - ${(pagination.startIndex + 1 * pagination.limit) > pagination.totalCount ? pagination.totalCount : pagination.startIndex + pagination.limit} of ${pagination.totalCount}`
        // return `${pagination.startIndex + 1} - ${pageNo === pageCount ? pagination.totalCount : pagination.startIndex + pagination.limit} of ${pagination.totalCount}`

    }


    const getValue = (element, data) => {
        if (isEmpty(element[data.key])) return "-";
        let withPercentageSymbol = ["PrecisionPercentage", "Accuracy %", "Percentage"];
        if (withPercentageSymbol.includes(data.key)) {
            return `${element[data.key]}%`;

        }
        if (data.isBtn) {
            if (data.key === "Completion Status" && element[data.key] === "No") return `In Progress`;
            return `${element[data.key]}`;
        }
        if (data.isDate) {
            if (data.dateStampType === "dateOnly") {
                let separator = "-";
                let formattedDate = commonUtil.getFormattedDate(element[data.key], separator);
                return formattedDate;
                // return new Date(element[data.key]).toLocaleDateString();
            }
            return new Date(element[data.key]).toLocaleString();
        }
        if (typeof (element[data.key]) === 'number' && commonUtil.isFloat(element[data.key])) {
            return element[data.key].toFixed(1)
        }
        return element[data.key];
    }
    const getBtnClass = (element, data) => {
        if (data.key === "Login Status") {
            if (element[data.key] === "Yes" || element[data.key] === "Logged In") return styles.successBtnCell;
            if (element[data.key] === "No" || element[data.key] === "Not Logged In") return styles.errorBtnCell;
        }
        if (data.key === "Completion Status") {
            if (element[data.key] === "Completed") return styles.successBtnCell;
            if (element[data.key] === "Not Started") return styles.errorBtnCell;
            return styles.inProgressBtnCell;
        }
        if (data.key === "Percentage") {
            let value = parseInt(element[data.key]);
            if (value >= 80 && value <= 100) return styles.successBtnCell;
            if (value >= 51 && value <= 79) return styles.errorBtnCell;
            return styles.inProgressBtnCell;
        }
        return ""
    }
    return (
        <>
            <div className={`${styles.wrapper} ${showGenerate || loader || !tableData.length ? styles.disableScroll : ""}`} ref={loadingDivRef}>
                <table className={styles.Table}>
                    {!showGenerate && <thead style={{
                        backgroundColor: '#fff',//while freezing table header
                        position: 'sticky',//while freezing table header
                        top: 0 //while freezing table header
                    }}>
                        <tr className={styles.Table_Heading}>
                            {tableHeadings.map((element, i) => (
                                <th key={i}>{element.title}</th>
                            ))}
                        </tr>
                    </thead>}
                    {!loader && !showGenerate && <tbody>
                        {tableData.map((element, key) => (
                            <tr className={styles.Table_Data} key={key}>

                                {
                                    tableHeadings.map((data, i) => {
                                        if (data.isBtn) {
                                            return (<td key={i} className={`${styles.btnCell} ${getBtnClass(element, data)}`}>
                                                <span>{getValue(element, data)}</span>
                                            </td>)
                                        } else {
                                            return (<td key={i}>{getValue(element, data)}</td>)
                                        }
                                    }
                                    )
                                }
                            </tr>
                        ))}
                    </tbody>}
                </table>

                {showGenerate ?
                    <div className={`${styles.emptyTable} ${largeReport && styles.largeReportError}`}>
                        {largeReport ? <LargeReportError emailId={emailId} setEmailId={setEmailId} handleSendEmail={handleSendEmail} loading={sendingEmail}/> : <ButtonBase onClick={handleGenerate}>GENERATE REPORT</ButtonBase>}
                    </div> : null}
                {
                    (loader && !showGenerate) ?
                        <div className={styles.loaderBox}>
                            {/* <Loader /> */}
                            <LinearProgressWithLabel value={progress} />
                            <h1>
                                {loaderText}
                                {`(${Math.round(progress)}%)`}
                            </h1>
                        </div> : null
                }
                {
                    (!showGenerate && !loader && !tableData.length && <div className={styles.emptyTable} style={{ minHeight: '366px' }}>
                        No data found.
                    </div>)
                }

            </div>
            <div className={styles.paginationBox} style={{ borderTop: loader ? '1px solid rgb(112, 112, 112, 0.4)' : 'none' }}>
                {withRowsSelection ?
                    <span className={styles.selectRowsBox}>
                        Rows per page
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                            <Select
                                value={rows}
                                onChange={handleChangeNumberOfRows}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={25}>25</MenuItem>
                                <MenuItem value={50}>50</MenuItem>
                                <MenuItem value={100}>100</MenuItem>
                            </Select>
                        </FormControl>
                    </span> : null}
                <h5>
                    {getPaginationText()}
                </h5>
                <ChevronLeftIcon className={styles.leftIcon} onClick={handleBackClick} />
                {/* <div style={{ padding: '0rem 0.5rem' }}>
                    {pageNo}/{pageCount}
                </div> */}
                <ChevronRightIcon className={styles.leftIcon} onClick={handleForwardClick} />
            </div>
        </>
    );
};

export default Table;


function LinearProgressWithLabel(props) {
    const styles = useStyles();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
                width: '42rem',
                height: '12px',
                mr: 1,
                '& > .MuiLinearProgress-root': {
                    height: '10px',
                    borderRadius: '4px'
                }
            }}>
                <LinearProgress className={styles.linearProgress} variant="determinate" color='warning' {...props} />
            </Box>
            {/* <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" className={styles.typography}>
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box> */}
        </Box>
    );
}

const LargeReportError = ({ emailId, setEmailId, handleSendEmail, loading }) => {
    return (
        <>
            <h1>This report will take time to generate</h1>
            <h1>Please enter your email ID to get the report on email</h1>
            <h1>Or select filters to reduce the report size</h1>
            <div>
                <CustomInput placeholder="john@example.com" value={emailId} handleChange={(e) => setEmailId(e.target.value)} />
                {
                    loading ? <LoadingButton loading variant="outlined">Submit</LoadingButton> :
                        <CustomButton btnText={'Get Report'} width={200} fw={700} variant="contained" textColor="#FFFFFF" bgcolor="#F4511e" borderColor="#F4511e" handleClick={handleSendEmail} />
                }
            </div>
        </>
    )
}