import { fetch, authFetch } from "../utils/fetch";

const userLogin = async (payload) => {

    try {
        const resData = await fetch.post(`/access/sign-in`, payload)
        //console.log("userLogin:", resData);
        return resData
    } catch (err) {
        //console.log(err.response); // response.data.errors
        return err.response
    }
}
const userLogout = async () => {
    try {
        const resData = await authFetch.delete(`/access/logout`)
        console.log("userLogout:", resData);
        return resData
    }
    catch (err) {
        console.log("userLogout:", err);
    }
}

export default { userLogin, userLogout }