import React, { useState, useRef } from 'react';
//old
// import { makeStyles } from '@material-ui/styles';
// import MenuItem from '@material-ui/core/MenuItem';
// import Menu from '@material-ui/core/Menu';
// import ButtonBase from '@material-ui/core/ButtonBase';
// import FilterIcon from './MDLIcon/icons/Filter';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Checkbox from '@material-ui/core/Checkbox';

//new
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ButtonBase from '@mui/material/ButtonBase';
import FilterIcon from './MDLIcon/icons/Filter';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

const custom_styles = makeStyles({
  text: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    paddingLeft: 10,
    fontSize: '0.7rem',
    color: '#707070',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '100px',
    display: 'block',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginLeft: '10px',
    marginRight: '10px',
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
  label: {
    color: '#707070',
    fontSize: '0.75rem',
    fontFamily: 'Montserrat',
  },
  selector_wrapper: {
    marginTop: '5px',
    border: '1px solid #707070',
    width: '155px',
    height: '35px',
    borderRadius: '2px',
  },
  selector_button_base: {
    width: '100% !important',
    height: '100% !important',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
  },
  selector_first_flex: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
  },
  selector_second_flex: {
    display: 'flex',
    alignItems: 'center',
  },
  expand_more: {
    fontSize: '0.9rem !important',
    fill: '#f4511e !important',
    paddingRight: '8px !important',
  },
});

export default function MOSelect(props) {
  let styles = custom_styles(props);

  let options = [{ display_text: 'No items', id: 0 }];
  let selected = [];
  let disabled = false;
  let style = {};

  if (props.hasOwnProperty('selected')) {
    selected = props.selected;
  }

  if (props.hasOwnProperty('options')) {
    options = props.options;
  }

  if (props.hasOwnProperty('disabled')) {
    disabled = props.disabled;
  }

  if (props.hasOwnProperty('style')) {
    style = props.style;
  }

  let [open, setOpen] = useState(false);
  let [anchorEl, setAnchorEl] = useState(null);

  const [values, setValues] = React.useState({
    name: 'Cat in the Hat',
    age: '',
    multiline: 'Controlled',
    currency: 'EUR',
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  function handleClose(id) {
    setOpen(false);
    if (
      props.hasOwnProperty('onSelect') &&
      typeof id !== 'undefined' &&
      id != null
    ) {
      props.onSelect(id);
    }
  }

  let filter_icon = <FilterIcon />;

  if (props.hasOwnProperty('hideFilterIcon')) {
    filter_icon = null;
  }

  return (
    <div className={styles.container}>
      <span className={styles.label}>{props.label}</span>
      <div className={styles.selector_wrapper}>
        <ButtonBase
          disabled={disabled}
          style={style}
          className={styles.selector_button_base}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpen(true);
          }}>
          <div className={styles.selector_first_flex}>
            {filter_icon}
            <div>
              <span className={styles.text}>{props.text}</span>
            </div>
          </div>
          <div className={styles.selector_second_flex}>
            <ExpandMoreIcon className={styles.expand_more} />
          </div>
        </ButtonBase>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setOpen(false)}>
        {options.map((option) => (
          <MenuItem key={option.id} onClick={(e) => handleClose(option.id)}>
            {props.multiple === true ? (
              <Checkbox checked={selected.indexOf(option.id) > -1} />
            ) : null}
            {option.display_text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
