import { makeStyles } from "@mui/styles"

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '1rem',
        fontFamily: "Montserrat",
    },
    pageTitle: {
        color: "#3f3e3e",
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    sectionTitle: {
        color: "#4b4b4b",
        fontSize: '1.2rem',
        fontWeight: 'bold',
        padding: '0.5rem 0rem',
    },
    listTitle: {
        paddingBottom: '0'
    },
    list: {
        paddingLeft: '1.5rem',
        paddingBottom: '1rem'
    }
}))

export default useStyles