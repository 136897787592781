import { authFetchFormData } from "../utils/fetch";
import { authFetch } from "../utils/fetch";

const fetchMicroskills = async (companyId, microskillId = null, limit = 10000) => {
    //NOTE = FETCH CORRESPONDING TO PROJECT ID CHANGED TO COMPANY ID
    let url = `/microskill?limit=${limit}&sort=createdOn&sortDirection=asc&companyIds=${companyId}`
    if (microskillId) {
        url = `${url}&ids=${microskillId}`
    }
    try {
        const resData = await authFetch.get(`${url}`)
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }

}

const copyMicroskill = async (payload) => {
    let url = `/microskill/copy-microskill`
    try {
        const resData = await authFetch.post(`${url}`, payload)
        return resData
    } catch (err) {
        return err.response
    }

}

const createMicroskill = async (payload) => {
    // console.log(data);
    try {
        const resData = await authFetchFormData.post(`/microskill`, payload)
        // console.log("createMicroskill:", resData);
        return resData
    } catch (err) {
        // console.log("createMicroskill:",err.response);
        return err.response
    }
}

const updateMicroskill = async (payload) => {
    try {
        const resData = await authFetch.put(`/microskill`, payload)
        // console.log("updateMicroskill:", resData);
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}
const deleteMicroskill = async (data) => {
    try {
        const resData = await authFetch.delete(`/microskill`, { data })
        // console.log("deleteMicroskill:", resData);
        return resData
    } catch (err) {
        // console.log('deleteMicroskill err:', err.response);
        return err.response
    }
}

const fetchMicroskillSkills = async (microskillId = null) => {
    //NOTE = FETCH CORRESPONDING TO PROJECT ID CHANGED TO COMPANY ID
    let url = `/microskill/get-skills/${microskillId}`
    try {
        const resData = await authFetch.get(`${url}`)
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }

}

const uploadUserMicroskill = async (data) => {
    try {
        const resData = await authFetchFormData.post('/microskill/launch', data);
        return resData;
    }
    catch (err) {
        return err.response;
    }
}

const disableMicroskill = async (data) => {
    try {
        const resData = await authFetchFormData.post('/microskill/disable', data);
        return resData;
    }
    catch (err) {
        return err.response;
    }
}

const fetchMicroskillGroups = async (microskillId = null) => {
    let url = `/microskill/get-groups/${microskillId}`
    try {
        const resData = await authFetch.get(`${url}`)
        return resData
    } catch (err) {
        return err.response
    }

}

const addMsToGroup = async (payload) => {
    let url = `/microskill/add-group`
    try {
        const resData = await authFetch.post(`${url}`, payload)
        return resData
    } catch (err) {
        return err.response
    }

}

const removeMsFromGroup = async (payload) => {
    let url = `/microskill/remove-group`
    try {
        const resData = await authFetch.post(`${url}`, payload)
        return resData
    } catch (err) {
        return err.response
    }

}

const getRandomImageUrl = () => {
    let imgUrls = ['/images/test-images/test-img-1.png', '/images/test-images/test-img-2.png', '/images/test-images/test-img-3.png', '/images/test-images/test-img-1.png', '/images/test-images/test-img-3.png', '/images/test-images/test-img-2.png', '/images/test-images/test-img-1.png', '/images/test-images/test-img-3.png', '/images/test-images/test-img-2.png', '/images/test-images/test-img-1.png', '/images/test-images/test-img-3.png', '/images/test-images/test-img-2.png']
    let shadows = ['rgb(104 0 0 / 75%)', 'rgb(0 36 104 / 75%)', 'rgb(104 0 98 / 69%)', 'rgb(51 104 0 / 69%)', 'rgb(104 0 0 / 75%)', 'rgb(0 36 104 / 75%)', 'rgb(104 0 98 / 69%)', 'rgb(51 104 0 / 69%)', 'rgb(104 0 0 / 75%)', 'rgb(0 36 104 / 75%)', 'rgb(104 0 98 / 69%)', 'rgb(51 104 0 / 69%)']
    let randomIndex = Math.floor(Math.random() * imgUrls.length);
    let randomViews = Math.floor(Math.random() * 20000);
    let randomLikes = Math.floor(Math.random() * 5000);
    // console.log('randomIndex = ' ,randomIndex);
    // console.log('randomViews = ' ,randomViews);
    // console.log('randomLikes = ' ,randomLikes);
    return { url: imgUrls[randomIndex], shadow: shadows[randomIndex], randomViews, randomLikes }
}

const fetchMicroskillsSearch = async (companyId = null, search = null, groupIds = null, limit = 3000, categoryIds = null) => {
    let url = `/microskill?limit=${limit}&sort=createdOn&sortDirection=desc`;
    if (companyId !== null) url += `&companyIds=${companyId}`;
    if (search !== null) url += `&search=${search}`;
    if (groupIds !== null) url += `&groupIds=${groupIds}`;
    if (categoryIds !== null) url += `&categoryIds=${categoryIds}`;

    try {
        const resData = await authFetch.get(`${url}`)
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }

}

const launchLearnerJourney = async (data) => {
    try {
        const resData = await authFetchFormData.post('/learner-journey/launch', data);
        return resData;
    }
    catch (err) {
        return err.response;

    }
}

const getMicroskillAndDemographicWiseUsers = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/demographic-user-details`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const launchDemographicWise = async (data) => {
    try {
        const resData = await authFetch.post(`/microskill/demographic-microskill-launch`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const getLaunchedLearnerJourneyList = async (data) => {
    try {
        const resData = await authFetch.post('/learner-journey/get-learner-journey-list', data);
        return resData;
    }
    catch (err) {
        return err.response;

    }
}

const getLearnerJourneyDetails = async (data) => {
    try {
        const resData = await authFetch.post('/learner-journey/get-learner-journey-details', data);
        return resData;
    }
    catch (err) {
        return err.response;

    }
}

const updateLearnerJourney = async (data) => {
    try {
        const resData = await authFetch.put('/learner-journey', data);
        return resData;
    }
    catch (err) {
        return err.response;

    }
}

const deleteLearnerJourney = async (data) => {
    try {
        const resData = await authFetch.delete('/learner-journey', { data });
        return resData;
    }
    catch (err) {
        return err.response;

    }
}

const getImmidiateLaunchList = async (data) => {
    try {
        const resData = await authFetch.post('/microskill/get-individual-launch-list', data);
        return resData;
    }
    catch (err) {
        return err.response;

    }
}

const addUsersInJourney = async (data) => {
    try {
        const resData = await authFetchFormData.post('/learner-journey/add-learner-learning-journey ', data);
        return resData;
    } catch (err) {
        return err.response;
    }
}


const fetchLeastUsedGbasList = async (data) => {
    console.log("data", data);
    try {
        const resData = await authFetch.post('/microskill/least-used-gba', data);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const validateUsers = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/microskill/validateUsers`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

export default {
    fetchMicroskills,
    copyMicroskill,
    createMicroskill,
    updateMicroskill,
    deleteMicroskill,
    fetchMicroskillSkills,
    getRandomImageUrl,
    uploadUserMicroskill,
    disableMicroskill,
    fetchMicroskillsSearch,
    fetchMicroskillGroups,
    addMsToGroup,
    removeMsFromGroup,
    launchLearnerJourney,
    getMicroskillAndDemographicWiseUsers,
    launchDemographicWise,
    getLaunchedLearnerJourneyList,
    getImmidiateLaunchList,
    getLearnerJourneyDetails,
    updateLearnerJourney,
    deleteLearnerJourney,
    addUsersInJourney,
    fetchLeastUsedGbasList,
    validateUsers
}