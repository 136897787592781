import React, { useState, useRef } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import { ButtonBase } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import commonUtil from '../../../../utils/commonUtil';

const AttachAudio = ({
    audioFileUrl = null,
    fileName = ' No file chosen',
    mimeType = '',
    handleChange,
    removeAudio
}) => {
    const classes = useStyles();
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    // const [audioFile, setAudioFile] = useState(null);
    // const [audioFileUrl, setAudioFileUrl] = useState(null);
    // const [fileName, setFileName] = useState('');
    // const [mimeType, setMimeType] = useState(null);


    // const handleChange = (e) => {
    //     let file = e.target.files[0];
    //     if (file) {
    //         console.log(file.name.length);
    //         setFileName(file.name)
    //         setAudioFile(file);
    //         let fileUrl = URL.createObjectURL(file);
    //         setAudioFileUrl(fileUrl);
    //         setIsPlaying(false);
    //         setMimeType(file.type)
    //     }
    // }

    const handleTogglePlay = () => {
        if (audioRef.current) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
        }
    };

   
      const handleDelete = () => {
        setIsPlaying(false);
        removeAudio()
    }

    return (
        <div className={classes.wrapper}>
            {audioFileUrl ? <audio ref={audioRef}>
                <source src={audioFileUrl} type={mimeType} />
            </audio> : null}

            {
                audioFileUrl ?
                    <ButtonBase className={classes.audio}>
                        {isPlaying ?
                            <PauseIcon sx={{ fontSize: '1rem', marginRight: '0.3rem', }} onClick={handleTogglePlay} /> :
                            <PlayArrowIcon sx={{ fontSize: '1rem', marginRight: '0.3rem', }} onClick={handleTogglePlay} />}


                        {commonUtil.truncateString(fileName, 24)}
                        <DeleteIcon sx={{ fontSize: '1rem', marginLeft: '0.3rem', }} onClick={handleDelete} />
                    </ButtonBase> :
                    <Button variant="outlined" component="label" className={`${classes.btn} ${classes.attachBtn}`} onChange={handleChange}>
                        <AttachFileIcon sx={{ fontSize: '0.9rem', marginRight: '0.3rem', transform: 'rotate(30deg)' }} />
                        Upload Audio
                        <input hidden accept="audio/*" type="file" />
                    </Button>
            }



        </div>
    )
}

export default AttachAudio

const useStyles = makeStyles(() => ({
    wrapper: {
        // border: '1px solid',
        position: 'absolute',
        top: '-3rem',
        right: '1rem'
    },
    audio: {
        border: '1px solid #f4511e',
        backgroundColor: '#fff',
        color: '#f4511e',
        textAlign: 'center',
        fontSize: '0.7rem',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        borderRadius: 5,
        padding: '0.4rem 0.5rem',

    },
    btn: {
        border: '1px solid #f4511e',
        backgroundColor: '#f4511e',
        color: '#fff',
        textAlign: 'center',
        fontSize: '0.6rem',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#f4511e',
            borderColor: '#f4511e',
            color: '#fff',
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'

        }
    }

}));