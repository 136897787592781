import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    mainContainer: {
        backgroundColor: '#ffffff',
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
    },
    anchor: {
        '&:hover': {
            textDecoration: 'underline !important',
            cursor: 'pointer'
        }
    },
    userLaunchContainer: {
        backgroundColor: '#ffffff',
        height: '100%',
        width: "100%",
        // margin : "3rem"
    },
    buttonContainer: {
        padding: "1rem",
        width: "40%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between"
    },
    btn: {
        width: '190px',
        borderRadius: '2px',
        height: '42px',
        padding: '0 4px',
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        cursor: "pointer",
        "&:hover": {
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
    launchBtn: {
        border: "1px solid #F4511E",
        backgroundColor: "#F4511E",
        color: "#FFFFFF",
        "&:hover": {
            border: "1px solid #F4511E",
            backgroundColor: "#F4511E",
            color: "#FFFFFF",
        }
    },
    ClearBtn: {
        border: "1px solid #F4511E",
        backgroundColor: "#FFFFFF",
        color: "#F4511E",
    },
    validationWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.3rem',
        '& > div': {
            display: 'flex',
            fontSize: '0.8rem',
            gap: '0.5rem',
            '& > p': {
                fontFamily: 'Montserrat',
                fontWeight: 500,
                color: '#484848',
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer'
                },
            },

            '& > span': {
                fontWeight: 700,
                fontFamily: 'Montserrat',
                color: '#000'
            },
            '& > .fa-circle-exclamation': {
                color: '#EB3917'
            },
            '& > .fa-circle-check': {
                color: 'green'
            },
            '& > .fa-circle-dot': {
                color: '#f3b401'
            },
        }
    }
}))

export default useStyles