import React, { useState } from 'react';
//old
// import IconButton from '@material-ui/core/IconButton';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

//new
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const ITEM_HEIGHT = 48;

function KababMenu(props) {
  let options = [{ display_text: 'No items', id: 0 }];

  if (props.hasOwnProperty('options')) {
    options = props.options;
  }

  let dispatchOnClick = function(id) {
    return;
  };

  if (props.hasOwnProperty('onClick')) {
    dispatchOnClick = props.onClick;
  }

  let [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);

  let handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let handleClose = (id) => {
    if (id != null) {
      dispatchOnClick(id);
    }

    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns={open ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={(e) => handleClick(e)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200,
          },
        }}
        anchorOrigin={{ horizontal: 'right' }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} onClick={(e) => handleClose(option.id)}>
            {option.display_text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default KababMenu;
