import React from 'react'

import Button from '@mui/material/Button';

import { makeStyles } from "@mui/styles";

const CustomButton = ({
    variant = 'outlined',
    textColor = '#f4511e',
    borderColor = '#f4511e',
    bgcolor = '#fff',
    fs = "0.6rem",
    fw = 800,
    ls = 0,
    btnText,
    handleClick
}) => {
    const classes = useStyles();
    return (
        <Button
            variant={variant}
            className={classes.btn}
            onClick={handleClick}
            sx={{
                color: textColor,
                border: `2px solid ${borderColor}`,
                bgcolor: bgcolor,
                fontSize: fs,
                fontWeight: fw,
                letterSpacing : ls
            }}>
            {btnText}
        </Button>
    )
}

export default CustomButton;



const useStyles = makeStyles(() => ({
    btn: {
        width: '100%',
        height: '2.5rem',
        fontFamily: "Montserrat",
        // fontSize: "0.6rem",
        // letterSpacing: "75",
        cursor: "pointer",
        boxShadow: 'none',
        borderRadius: 0,
        '&:hover': {
            borderColor: 'inherit',
            borderWidth : '2px',
        }
    }
}));
