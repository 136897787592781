import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    MasteroTable_Container: {
        // boxShadow: '0px 6px 10px -5px rgba(0,0,0,0.2)',
        // '-webkit-box-shadow': '0px 6px 10px -5px rgba(0,0,0,0.2)',
        // '-moz-box-shadow': '0px 6px 10px -5px rgba(0,0,0,0.2)',
        border: "2px solid rgb(112,112,112,0.1)",
        borderRadius: '4px',
        width: "100%",
        height: "100%",
        color: "black",
        // overflowX: 'scroll',
        // '&::-webkit-scrollbar': {
        //     width: '6px'
        //     // display: 'none'
        // },
        // '&:hover': {
        //     '&::-webkit-scrollbar-thumb': {
        //         background: '#f5411e',
        //         borderRadius: '6px'
        //     }
        // }
    },
    Title: {
        display: "flex",
        alignItems: "center",
        fontFamily: 'Montserrat',
        color: "#2B2B2B",
        fontSize: "14px",
        fontWeight: "Bolder",
        characterSpacing: "0",
        lineSpacing: "155"
    },
    Help_Icon: {
        color: "#F4511E",
        fontSize: "1rem",
        marginLeft: "0.5rem"
    },
    Table: {
        backgroundColor: "#fff",
        width: "100%",
        borderSpacing: "0px",
        // border: "1.4px solid rgb(112,112,112,0.5)",
        borderRadius: "2px"
    },
    Table_Heading: {
        backgroundColor: "#FBFBFB",
        color: "#484848",
        fontSize: "0.74rem",
        lineHeight: "4",
        '& th': {
            fontFamily: 'Montserrat',
            fontWeight: 800,
        }
    },
    Table_Data: {
        boxShadow: " 0px 0px 1px rgb(112,112,112,0.7)",
        borderRadius: "2px",
        fontFamily: 'Open Sans',
        color: "#707070",
        fontSize: "0.7rem",
        fontWeight: 400,
        characterSpacing: "0",
        lineHeight: "5",
        textAlign: "center",
        '&  td': {
            // cursor: 'pointer',
            fontFamily: 'Montserrat',
            fontWeight: 500,
        }
    },
    SemiBold_Font: {
        fontWeight: "600",
    },
    iconBox: {
        '& img:hover': {
            height: '1rem',
            margin: '0 0.4rem',
            filter: 'invert(34%) sepia(70%) saturate(2053%) hue-rotate(351deg) brightness(99%) contrast(93%)'
        }
    },
    Icons: {
        margin: "0 0.5rem",
        cursor: "pointer",
        height: '0.8rem',
    },
    Tooltip: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px" /* Use half of the width (120/2 = 60), to center the tooltip */
    },
    Tooltiptext: {
        width: "120px",
        top: "100%",
        left: "50%",
        marginLeft: "-60px" /* Use half of the width (120/2 = 60), to center the tooltip */
    },
    leftIcon: {
        cursor: "pointer",
        "&:hover": {
            background: "#d5d5d5",
            borderRadius: "2rem"
        }
    },
    paginationBox: {
        // border: '1px solid',
        color: "#F4511E",
        padding: "1rem 0rem",
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: "center",
        '& > h5': {
            // border: '1px solid black',
            color: "rgb(0,0,0,0.8)",
            fontFamily: "Montserrat",
            fontSize: "0.8rem",
            fontWeight: 600,
            marginRight: '1rem'
        }

    },
    emptyBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '20rem',
        color: "rgb(0,0,0,0.8)",
        fontFamily: "Montserrat",
        fontSize: "1rem",
        fontWeight: 600,
    },
    btn: {
        borderRadius: '4px',
        fontWeight: 700,
        fontSize: '0.7rem',
        padding: '0.4rem 0.7rem',
    },
    successBtn: {
        color: '#43A047',
        backgroundColor: '#ECF5EC',
    },
    errorBtn: {
        color: '#FF0000',
        backgroundColor: '#FFE5E5',
    },
    loaderBox: {
        minHeight: '20rem',
        padding: '1rem',
        display: 'flex',
        gap: '2rem',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        '& > div': {
            backgroundColor: '#fff',
            '& > span': {
                height: '2rem !important',
                width: '2rem !important',
            }
        },
    },
}))

export default useStyles