import { fetch, authFetch } from "../utils/fetch";

const createForm = async (payload) => {
    try {
        const respData = await authFetch.post("/forms", payload);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const fetchForm = async (companyIds, startIndex) => {
    try {
        const respData = await authFetch.get(`/forms?startIndex=${startIndex}&limit=10&sort=createdOn&sortDirection=desc&companyIds=${companyIds}`);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const fetchSpecificForm = async (payload) => {
    try {
        const respData = await authFetch.get(`/forms/form?formId=${payload.formId}`);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const fetchFormSearch = async (companyId = null, search = null) => {
    let url = `/forms?startIndex=0&limit=300&sort=createdOn&sortDirection=desc`
    if (companyId !== null) {
        url += `&companyIds=${companyId}`
    }
    if (search !== null) {
        url += `&search=${search}`;
    }
    try {
        const respData = await authFetch.get(`${url}`);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const fetchAllForm = async (companyIds) => {
    try {
        const respData = await authFetch.get(`/forms?limit=300&sort=createdOn&sortDirection=asc&companyIds=${companyIds}`);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const launchForm = async (payload) => {
    try {
        const respData = await authFetch.post("/forms/launch", payload);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const addQuestionForms = async (payload = {}) => {
    try {
        const respData = await authFetch.post(`/forms/add-questions`, payload);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const updateForm = async (payload = {}) => {
    try {
        const respData = await authFetch.put('/forms', payload);
        return respData;
    }
    catch (err) {
        return err.response;
    }
}

const deleteQuestion = async (payload = {}) => {
    try {
        const respData = await authFetch.put('/forms/delete-question', payload);
        return respData;
    }
    catch (err) {
        return err.response;
    }
}

const copyForm = async (payload = {}) => {
    try {
        const respData = await authFetch.post('/forms/copy-form', payload);
        return respData;
    }
    catch (err) {
        return err.response;
    }
}

const deleteForm = async (data) => {
    try {
        const respData = await authFetch.delete('/forms', { data });
        return respData;
    }
    catch (err) {
        return err.response;
    }
}

export default { createForm, fetchForm, fetchAllForm, launchForm, addQuestionForms, fetchFormSearch, fetchSpecificForm, updateForm, deleteQuestion, copyForm, deleteForm };