import { commonActionTypes } from "./common.types";

const INITIAL_STATE = {
    currentUser: null,
    isLoading: false,
    error: null
}
const commonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case commonActionTypes.FETCH_START:
            return {
                ...state,
                isLoading: true
            }
        case commonActionTypes.FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false
            }
        case commonActionTypes.FETCH_FAIL:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            }

        default:
            return state;
    }
}

export default commonReducer