import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import styles, { FlexRowDiv } from './style';
import FormControl from './components/FormControl';
import { HeadingLabel } from './components/Label';

import FlatPrimaryButton from './components/FlatPrimaryButton';
import { v4 as uuid } from 'uuid';
import KeywordCards from './KeywordCards';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import cssStyles from './microskill.module.css';

import LinearWithValueLabel from '../game-based-assessment/components/lenear-progress-bar';
import Label from '../game-based-assessment/components/label';
import Disclaimer from '../disclaimer';
import Papa from 'papaparse';
import constants from '../../constants';
import { GlobalStates } from '../../App';


const Page3_keywords = (props) => {
  const { setCurrentPage, pages } = props;
  const { microSkillState, setMicroSkillState } = props;
  const [hoveredKeyword, setHoveredKeyword] = useState([])
  const [scrollToId, setScrolltoId] = useState(null)
  const [skills, setSkills] = useState(!microSkillState.RequestKeyBox.length ? [createEmptySkill()] : microSkillState.RequestKeyBox);
  const classes = styles();

  const scrollToItem = (id, block = 'center', blockOffset = null) => {
    let item = document.getElementById(`${id}`);
    if (item) {
      let scrollOptions = { behavior: 'smooth', block: block };
      if (blockOffset) scrollOptions.blockOffset = blockOffset;
      item.scrollIntoView(scrollOptions);
    }
    return
  }


  useEffect(() => {
    if (scrollToId) {
      scrollToItem(scrollToId)
      setScrolltoId(null)
    }
  }, [skills])

  useEffect(() => {
    if (hoveredKeyword.length) {
      scrollToItem('highlighted')
    }
  }, [hoveredKeyword])


  function createEmptySkill() {
    const emptySkill = {
      id: uuid(),
      head: '',
      keywords: [],
      keywordInput: [],
      limit: 50,
    };
    return emptySkill;
  }
  const nextAction = () => {
    // console.log('microSkillState page 3 = ', microSkillState);
    setCurrentPage(pages[3]);
  };


  const DisabledTranscriptContainer = () => {
    const highlightText = (text) => {
      let highlightedText = text;

      hoveredKeyword.forEach(keyword => {
        const regex = new RegExp(`(${keyword})`, 'gi');
        highlightedText = highlightedText.replace(regex, `<span id="highlighted" style="background-color: yellow;">$1</span>`);
      });

      return highlightedText;
    };

    return (
      <div className={classes.disabledTranscriptBox} key={skills.length}>
        <Label labelText={"Transcript:"} fs='0.8rem' />
        {/* <div>{microSkillState.Sample_transcript ?? ''} </div> */}
        <div
          dangerouslySetInnerHTML={{
            __html: highlightText(microSkillState.Sample_transcript ?? ''),
          }}
        />
      </div>
    );
  };

  const handleMouseOver = useCallback((keyword) => {
    setHoveredKeyword([keyword])
  }, [])

  const handleMouseLeave = useCallback(() => {
    setHoveredKeyword([])
  }, [])



  const addMoreAction = () => {
    const newEmptySkill = createEmptySkill();
    setScrolltoId(newEmptySkill.id)
    setSkills([...skills, newEmptySkill]);
  };

  const updateSkills = (value) => {
    const newBox = value.filter((item) => item.keywords.length > 0);
    setMicroSkillState({ ...microSkillState, RequestKeyBox: newBox });
    setSkills(value);
  };

  return (
    <>
      {/* <div className={classes.heading_container}>
        <HeadingLabel>Create Roleplay Challenge Below</HeadingLabel>
      </div> */}
      <FlexRowDiv className={classes.keywordContainer}>
        <div className={classes.leftPage3Container}>
          <div className={classes.leftTopContainer} style={{ height: '100%' }}>
            <div>
              <HeadingLabel>Add Skill Tags with Keywords</HeadingLabel>
            </div>
            <div style={{ display: 'flex', height: '93%', gap: '1rem' }}>
              <div style={{ minWidth: 300, maxWidth: 300 }}>
                <CsvKeywordUpload
                  setSkills={setSkills}
                  microSkillState={microSkillState}
                  setMicroSkillState={setMicroSkillState}
                />
              </div>
              <div style={{ width: 'calc(100% - 300px)' }}>

                <ul
                  className={cssStyles.keyword}
                  style={{
                    listStyle: 'none',
                    display: 'flex',
                    flexDirection: 'row',
                    padding: 0,
                    flexWrap: 'wrap',
                    overflow: 'auto',
                    height: '100%',
                    width: '100%',
                  }}>
                  <div className={classes.scrollFeed}>
                    {
                      skills.map((skill, i) => (
                        <React.Fragment key={skill?.id}>
                          <KeywordCards
                            skill={skill}
                            setSkills={(value) => updateSkills(value)}
                            skills={skills}
                            transcript={microSkillState.Sample_transcript ?? ''}
                            handleMouseOver={handleMouseOver}
                            handleMouseLeave={handleMouseLeave}
                          />
                        </React.Fragment>

                      ))
                    }
                    <li
                      onClick={addMoreAction}
                      className={classes.addSkillCard}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        marginRight: '13px',
                        width: '44%',
                        height: '95%',
                        maxWidth: 225,
                        marginTop: 13,
                        maxHeight: 327,
                        alignItems: 'center',
                        cursor: 'pointer'
                      }}>
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        // className={classes.competeStepStyle}
                        style={{
                          fontSize: '24px',
                          color: '#F4511E',
                          cursor: 'pointer',
                        }}
                      />
                      <label
                        style={{
                          width: '115px',
                          height: '18px',
                          background: 'transparent',
                          color: '#F4511E',
                          font: 'normal normal bold 13px Open Sans',
                          marginTop: '16px',
                          textAlign: 'center'
                        }}>
                        Add Skill Tag
                      </label>
                    </li>
                  </div>
                </ul>
              </div>
            </div>

          </div>
        </div>
        <div className={classes.rightpage3Container}>
          {DisabledTranscriptContainer()}
        </div>
      </FlexRowDiv>
      <Disclaimer p='0 2.5rem' lh={1.2} />
      <FormControl className={classes.action_btn_container}>
        <FlatPrimaryButton
          style={{
            minWidth: '130px',
            width: '150px',
            padding: '15px 30px',
          }}
          onClick={nextAction}>
          Next
        </FlatPrimaryButton>
      </FormControl>
    </>
  );
};

export default Page3_keywords;

const defaultValidationData = {
  noSkillTagCount: 0,
  noKeyWithSkillTagCount: 0,
  noSkillAndKeyCount: 0
}


const CsvKeywordUpload = ({ setSkills, microSkillState, setMicroSkillState }) => {
  const classes = styles();
  const [globalStates] = useContext(GlobalStates)
  const csvUploadInputRef = useRef()
  const timer = useRef()
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [validationData, setValidationData] = useState(defaultValidationData);
  const [intervalCleared, setIntervalCleared] = useState(false);

  useEffect(() => {
    if (intervalCleared) {
      updateListData();
      globalStates.handleToast(true, 'CSV uploaded successfully!', 'success');
      setProgress(100);
      setIntervalCleared(false);
    }
  }, [intervalCleared])

  const isCsvFormatValid = (fields = []) => {
    let isValid = false;
    const validHeaders = ["Skill tag", "Keyword", "Is a Product"];

    if (fields.length !== validHeaders.length) return isValid;
    for (let i = 0; i < validHeaders.length; i++) {
      if (!fields.includes(validHeaders[i])) {
        isValid = false;
        break;
      } else {
        isValid = true;
      }
    }
    return isValid;
  }

  const updateProgress = () => {
    timer.current = setInterval(() => {
      setProgress((prevProgress) => {
        let random = prevProgress + Math.floor(Math.random() * 20);
        if (prevProgress >= 100 || random >= 100) {
          clearInterval(timer.current);
          setIntervalCleared(true);
          return 100
        } else {
          return random
        }
      });
    }, 100);
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return
    setProgress(0);
    setFileName(file.name);

    Papa.parse(file, {
      header: true, // Assumes the first row contains column headers
      dynamicTyping: false, // Automatically parse numbers and booleans
      complete: (result) => {
        // The parsed data is available in result.data
        let fields = result.meta.fields || [];
        if (isCsvFormatValid(fields)) {
          setLoadingCsv(true);
          setCsvData(result.data);
          updateProgress();
        } else {
          globalStates.handleToast(true, 'Incorrect CSV uploaded, please retry!', 'error');
          return
        }

      },
      error: (error) => {
        console.error('CSV Parsing Error:', error.message);
        globalStates.handleToast(true, 'Failed to upload csv', 'error');
      },
    });
    csvUploadInputRef.current.value = null
  };

  const updateListData = () => {
    let noSkillTagCount = 0;
    let noKeyWithSkillTagCount = 0;
    let noSkillAndKeyCount = 0;

    const skillMap = new Map();
    csvData.forEach(ele => {
      if (ele && ele['Skill tag'] && ele['Keyword']) {
        const skill = ele['Skill tag'];
        const keyword = {
          keyword: ele['Keyword'],
          isProduct: ele['Is a Product'] === 'Y' ? true : false
        }

        if (skillMap.has(skill)) {
          const prev = skillMap.get(skill);
          skillMap.set(skill, [...prev, keyword]);
        } else {
          skillMap.set(skill, [keyword]);
        }
      }

      if (ele && ele.hasOwnProperty('Skill tag') && ele.hasOwnProperty('Keyword') && ele.hasOwnProperty('Is a Product')) {
        if (ele['Keyword'] && !ele['Skill tag']) noSkillTagCount++;
        if (ele['Skill tag'] && !ele['Keyword']) noKeyWithSkillTagCount++;
        if (ele['Is a Product'] && !ele['Skill tag'] && !ele['Keyword']) noSkillAndKeyCount++;
      }
    })

    setValidationData({ noSkillTagCount, noKeyWithSkillTagCount, noSkillAndKeyCount })

    const newKeyBox = [];

    for (let [skill] of skillMap) {
      const keywords = skillMap.get(skill);
      const newSkill = {
        id: uuid(),
        head: skill,
        keywords: keywords.map(ele => ({
          id: uuid(),
          matchType: 'exact',
          name: ele.keyword,
          similarKeywords: [],
          product: ele.isProduct
        })),
        keywordInput: keywords.map(ele => ele.keyword),
        limit: 50,
      };

      newKeyBox.push(newSkill);
    }
    console.log('newKeyBox = ', newKeyBox);

    setSkills(newKeyBox);
    setMicroSkillState({ ...microSkillState, RequestKeyBox: newKeyBox });

  }


  const icons = {
    error: "fa-solid fa-circle-exclamation",
    inProgress: "fa-regular fa-circle-dot",
    valid: "fa-solid fa-circle-check"
  }

  const validations = [
    {
      name: 'no-skill-tag',
      title: 'No skill tag associated with keyword',
      count: validationData.noSkillTagCount,
      icon: icons.error
    },
    {
      name: 'no-keyword-with-skill-tag',
      title: 'No keyword associated with skill tag',
      count: validationData.noKeyWithSkillTagCount,
      icon: icons.error
    },
    {
      name: 'invalid-keyword',
      title: 'Invalid keyword',
      count: validationData.noSkillAndKeyCount,
      icon: icons.error
    }
  ]

  const showValidations = () => {
    return validations.filter(validation => validation.count > 0).length;
  }


  return (
    <div className={classes.row1}>
      <div className={`${classes.labelBox}`}>
        <Label labelText={"CSV Upload:"} fs='0.8rem' />
      </div>
      {loadingCsv ? <div className={`${classes.uploadProgressBox}`}>
        <i className="fa-solid fa-file-csv"></i>
        <div>
          <h6>{fileName}</h6>
          <LinearWithValueLabel progress={progress} />
        </div>
      </div> : null}
      <div className={`${classes.csvUploadBoxContainer} ${classes.flexCenter}`}>
        <div className={classes.csvUploadBox}>
          <label className={classes.uploadIcon}>
            <i className="fa-solid fa-cloud-arrow-up"></i>
            <input ref={csvUploadInputRef} style={{ display: 'none' }} type='file' accept='.csv' onChange={handleFileUpload} />
          </label>
          <h4>Upload CSV file here</h4>
          <h5>or</h5>
          <a
            href={constants.SPOTLIGHT_KEYWORD_CSV_FORMAT}
            target="_blank"
            download
          >
            Download CSV format</a>
        </div>
      </div>
      {
        showValidations() ?
          <div className={classes.validationWrapper}>
            {
              validations.map((ele, i) => {
                if (ele.count) return <div key={i}><i className={ele.icon}></i><span>{ele.count}</span><p>{ele.title}</p> </div>
              })
            }
          </div> : null

      }

    </div>
  )
}