import React from "react";

function PauseIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={props.className}>
      <path d="M162.642 148.337h56.034v215.317h-56.034V148.338zM293.356 148.337h56.002v215.317h-56.002V148.338z" />
    </svg>
  );
}

export default PauseIcon;
