import React from 'react'

//components
import Groups from '../../components/groups'

const GroupsPage = () => {
  return (
    <div>
      <Groups/>
    </div>
  )
}

export default GroupsPage
