import styled from 'styled-components';

const FlexItem = styled.div`
  padding-left: ${props => props.gap ? props.gap : 12}px;
  padding-right: ${props => props.gap ? props.gap : 12}px;
  @media (min-width: 1024px) {
    flex-basis: 0;
  }
`;

export default FlexItem;