import React, { useState } from 'react';

// components
import CustomInput from '../custom-input';

// mui-icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// mui
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';

// styles
import useStyles from './index.styles';
import { useEffect } from 'react';

const RatingResponses = (props) => {
    const { questionIdx, formQuestion, setFormQuestion } = props;

    const styles = useStyles();

    const [upperLimit, setUpperLimit] = useState(5);
    const [lowerLimit, setLowerLimit] = useState(1);
    const [upperLimitLabel, setUpperLimitLabel] = useState('');
    const [lowerLimitLabel, setLowerLimitLabel] = useState('');

    const handleChangeUpperLimitText = (e) => {
        setUpperLimitLabel(e.target.value);
    }

    const handleChangeLowerLimitText = (e) => {
        setLowerLimitLabel(e.target.value);
    }


    const handleArrowUpClick = (text) => {
        if (text === 'UPPER_LIMIT' && upperLimit < 10) {
            setUpperLimit(upperLimit + 1);
        }
        else if (text === 'LOWER_LIMIT' && lowerLimit < 10 && lowerLimit < upperLimit - 1) {
            setLowerLimit(lowerLimit + 1);
        }
    }

    const handleArrowDownClick = (text) => {
        if (text === 'UPPER_LIMIT' && upperLimit > 2) {
            if (lowerLimit + 1 === upperLimit) {
                setLowerLimit(lowerLimit - 1);
            }
            setUpperLimit(upperLimit - 1);
        }
        else if (text === 'LOWER_LIMIT' && lowerLimit >= 2) {
            setLowerLimit(lowerLimit - 1);
        }
    }

    const handleRatingMethod = (e, value) => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].type = value;
        setFormQuestion(tempObj);
    }

    useEffect(() => {
        let tempObj = [...formQuestion];
        tempObj[questionIdx].options = {
            maxLimit: upperLimit,
            minLimit: lowerLimit,
            maxLabel: upperLimitLabel,
            minLabel: lowerLimitLabel
        };
        setFormQuestion(tempObj);
    }, [upperLimitLabel, lowerLimitLabel, upperLimit, lowerLimit])

    return (
        <div style={{ margin: '1rem 0' }}>
            {formQuestion[questionIdx].type === 'NUMBER_RATING' && (
                <>
                    <div className={styles.Heading}>Responses:</div>
                    <div className={`${styles.centerFlex} ${styles.labelContainer}`}>
                        <div className={styles.AnswerLabel}>
                            <CustomInput
                                handleChange={(e) => handleChangeUpperLimitText(e)}
                                defaultValue={upperLimitLabel}
                                placeholder='Upper Limit Label'
                                maxLength={10}
                            />
                        </div>

                        <div className={`${styles.centerFlex} ${styles.limitSetContainer}`}>
                            <div className={`${styles.limitValue}`}>{upperLimit}</div>
                            <div className={`${styles.centerFlex} ${styles.textContainer}`}>
                                <div className={`${styles.centerFlex} ${styles.arrowRating}`}>
                                    <KeyboardArrowUpIcon className={styles.arrow} onClick={() => handleArrowUpClick('UPPER_LIMIT')} />
                                    <KeyboardArrowDownIcon className={styles.arrow} onClick={() => handleArrowDownClick('UPPER_LIMIT')} />
                                </div>
                                <div>Upper Limit</div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.centerFlex} ${styles.labelContainer}`}>
                        <div className={styles.AnswerLabel}>
                            <CustomInput
                                handleChange={(e) => handleChangeLowerLimitText(e)}
                                defaultValue={lowerLimitLabel}
                                placeholder='Lower Limit Label'
                                maxLength={10}
                            />
                        </div>

                        <div className={`${styles.centerFlex} ${styles.limitSetContainer}`}>
                            <div className={`${styles.limitValue}`}>{lowerLimit}</div>
                            <div className={`${styles.centerFlex} ${styles.textContainer}`}>
                                <div className={`${styles.centerFlex} ${styles.arrowRating}`}>
                                    <KeyboardArrowUpIcon className={styles.arrow} onClick={() => handleArrowUpClick('LOWER_LIMIT')} />
                                    <KeyboardArrowDownIcon className={styles.arrow} onClick={() => handleArrowDownClick('LOWER_LIMIT')} />
                                </div>
                                <div>Lower Limit</div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className={`${styles.centerFlex} ${styles.ratingMethodContainer}`}>
                <div className={styles.Heading}>Rating Method:</div>
                <FormControl>
                    <RadioGroup defaultValue='STAR_RATING' className={`${styles.centerFlex} ${styles.ratingMethod}`} onChange={(e, value) => handleRatingMethod(e, value)}>
                        <FormControlLabel value='STAR_RATING' label={'Star Rating'} control={<Radio className={styles.arrow} />} />
                        <FormControlLabel value='NUMBER_RATING' label={'Number Rating'} control={<Radio className={styles.arrow} />} />
                    </RadioGroup>
                </FormControl>
            </div>

        </div>
    )
}

export default RatingResponses;