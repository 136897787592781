import React from 'react'

//mui
import HelpIcon from "@mui/icons-material/Help";

//styles
import { makeStyles } from "@mui/styles";




const Title = ({ titleText, infoIcon = false, fs = '14px', fw = 900, padding = '0.5rem 0'}) => {
    const useStyles = makeStyles(() => ({
        title: {
            display: "flex",
            alignItems: "center",
            fontFamily: "Montserrat",
            color: "#2B2B2B",
            // fontSize : "14px",
            // fontWeight: 900,
            // padding: '0.5rem 0'
        },
        helpIcon: {
            color: "#F4511E",
            fontSize: "1rem",
            marginLeft: "0.7rem"
        },
    }))
    const styles = useStyles();
    const titleStyle = {
        fontSize: fs,
        fontWeight: fw,
        padding: padding
    }
    return (
        <h2 className={styles.title} style={titleStyle}>
            {titleText} {infoIcon ? <HelpIcon className={styles.helpIcon} /> : null}
        </h2>
    )
}

export default Title;
