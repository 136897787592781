import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { useParams } from "react-router-dom";

// mui
import Box from '@mui/material/Box';

// components
import SideDrawerContainer from "../side-drawer-container";
import AppBar from "../app-bar";

// utils
import useRouter from "../../utils/useRouter";
import isEmpty from "../../utils/isEmpty";
import useQuery from "../../utils/useQuery";

// constants
import constants from "../../constants";

const Layout = (props) => {
    const router = useRouter();
    let { companyId } = useParams();
    const query = useQuery();
    if (!companyId) companyId = query.get('c_id');
    const {
        Component,
        withHeader = false,
        withDrawer = false,
        isProtected = false,
        isMobile,
        sideMenu,
        setSideMenu,
    } = props;
    // const analyticsToken = useSelector(state => state.auth.analyticsToken);
    const session = JSON.parse(localStorage.getItem('session'));
    // console.log('layout session = ', session);
    const validToken = () => {
        const currentTimestamp = new Date().getTime() / 1000;
        let decoded = jwt_decode(session.accessToken);
        if (decoded.exp < currentTimestamp) return false;
        else return true;
    }

    const handleReload = (seconds) => {
        setTimeout(() => {
            window.location.reload();
        }, parseInt(`${seconds}000`))
    }

    useEffect(() => {
        if (isProtected) {
            // check token and its validity
            if (isEmpty(session)) {
                router.history.push("/login");
                handleReload(1);
                return
            }
            if (!validToken()) {
                router.history.push("/login");
                localStorage.clear();
                handleReload(1);
                return
            }
            let userType = localStorage.getItem('user-type');
            if (userType !== 'superAdmin') {
                let userCompanyId = localStorage.getItem('companyId');
                if (companyId !== null && companyId !== undefined) {
                   if (userCompanyId !== companyId) {
                        router.history.push("/login");
                        localStorage.clear();
                        handleReload(1);
                    }
                } 
            }
        }
    }, [])


    const getDrawerStyle = () => {
        let styles = {
            transition: sideMenu.isOpen ? '1s' : '0.3s cubic-bezier(0.09, 0.58, 0.93, 0.94)',
            marginLeft: withDrawer ? sideMenu.defaultWidth : '0rem',
            marginTop: withHeader ? constants.HEADER_HEIGHT : '0rem',
        }
        if (withDrawer) {
            styles.marginLeft = { sm: `0rem`, md: `${sideMenu.defaultWidth}` }
        }
        return styles
    }

    const handleDrawerOpen = () => {
        setSideMenu({ isOpen: true, defaultWidth: "215px" })
    };

    return (
        <>
            {withDrawer ?
                (<SideDrawerContainer
                    sideMenu={sideMenu}
                    setSideMenu={setSideMenu}
                />)
                : (null)}
            {withHeader ?
                (
                    <AppBar
                        handleDrawerOpen={handleDrawerOpen}
                        open={sideMenu.isOpen}
                        drawerWidth={sideMenu.defaultWidth}
                    />
                )
                : (null)}
            <Box sx={getDrawerStyle}>
                <Component isMobile={isMobile} />
            </Box>
        </>
    )
}
export default Layout;

