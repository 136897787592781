import React, { useState, useContext, useEffect } from 'react'
import { useParams } from "react-router-dom";

//components
import LoadingContainer from '../loading-container';
import SearchBar from './components/search-bar';
import CreationCard from './components/creation-card';
import CustomButton from '../custom-styled/button';
import Label from '../custom-styled/label'
import DocumentsModal from './components/modal';
import GridView from './components/grid-view';

//services
import documentsServices from '../../services/documents';
import groupServices from '../../services/group';

//utils
import useQuery from '../../utils/useQuery';
import isEmpty from '../../utils/isEmpty';
import useRouter from '../../utils/useRouter';
import useLocalStorage from '../../utils/useLocalStorage';

//global-states
import { GlobalStates } from '../../App';

//styles
import useStyles from './index.styles';
import commonUtil from '../../utils/commonUtil';
import constants from '../../constants';

const data1 = {
    name: "",
    type: "",
    parentId: "",
    createdOn: "",
    views: ""
}

const Documents = () => {
    const classes = useStyles();
    const router = useRouter();
    const { companyId } = useParams();
    const query = useQuery();
    const parentId = query.get("parentId");
    let userType = localStorage.getItem('user-type');
    const [loading, setLoading] = useState(false);
    const [isToast, setIsToast] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState('');
    const [toastSeverity, setToastSeverity] = React.useState('');
    const [globalStates, setGlobalStates] = useContext(GlobalStates);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [allData, setAllData] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [selectedFile, setSelectedFile] = useState(data1);
    const [showDocumentDetail, setShowDocumentDetail] = useState(false);
    const [type, setType] = useState('FOLDER');
    const [folderName, setFolderName] = useState('');
    const [isError, setIsError] = useState(false);
    const [files, setFiles] = useState(null);
    const [fileName, setFileName] = useState('No file chosen');
    const [isSaving, setIsSaving] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [sortType, setSortType] = useState('name');
    const [status, setStatus] = useState('ACTIVE');
    const [editData, setEditData] = useState({ edit: false, data: null });
    const [groups, setGroups] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useLocalStorage('breadcrumb', [{
        title: 'My Documents',
        url: `/documents/${companyId}`
    }])
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [repositoryGroups, setRepositoryGroups] = useState([]);

    useEffect(() => {
        fetchCompanyGroups(companyId);
        return () => {
            localStorage.removeItem('breadcrumb');
        }
    }, [])

    useEffect(() => {
        fetchDocuments(companyId, parentId);
    }, [parentId])

    const thumbImages = {
        FOLDER: "/images/documents/thumb-icons/folder.svg",
        FILE: "/images/documents/thumb-icons/file.png"
    }

    const fetchCompanyGroups = async (companyId, options = { startIndex: 0, limit: 1000 }) => {
        const response = await groupServices.fetchGroups(companyId, options);
        // console.log('fetchCompanyGroups response = ', response);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            const activeGroups = data.filter(ele => ele.active === true);
            setGroups(activeGroups);
        }
    }

    const fetchDocuments = async (companyId, parentId = null) => {
        setLoading(true);
        console.log('parentId = ', parentId);
        const res = await documentsServices.fetchDocuments(companyId, parentId);
        console.log('fetchDocuments with parent id response = ', res);
        if (res.status === 200 || res.status === 201) {
            let active = res.data.data.filter(element => element.active === true);
            let temp = [];
            active.forEach(element => {
                element.thumbImage = thumbImages[element.type];
                temp.push(element);
            });
            temp.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            })
            setDocuments(temp);
            setAllData(temp);
            setSortType('name');
            let newUrl = `/documents/${companyId}`;
            if (parentId) newUrl += `?parentId=${parentId}`
            router.history.replace(newUrl)
            setLoading(false);
        }

    }

    const handleBreadcrumbClick = (ele, index) => {
        let temp = [...breadcrumbData];
        if (temp.length - 1 === index) return
        temp.splice(index + 1);
        setBreadcrumbData(temp);
        setShowDocumentDetail(false);
        router.history.push(ele.url);
    }

    const handleAddFolder = () => {
        setType('FOLDER');
        setFolderName('');
        setIsError(false);
        setIsToast(false);
        setIsModalOpen(true);
    }

    const _editClick = (item) => {
        setType('EDIT');
        setFolderName(item.name);
        if ("status" in item) setStatus(item.status);
        setIsModalOpen(true);
        setEditData({ edit: true, data: item });
    }

    const handleEditDocument = async () => {
        if (isEmpty(folderName)) {
            setIsError(true);
            handleToast(true, 'Please enter the name', 'error');
            return
        }
        setIsSaving(true);

        let json = {
            companyId: editData.data.companyId,
            id: editData.data.id,
            name: folderName,
            status: status,
            description: 'zok'
        }
        let payload = await commonUtil.jsonToFormData(json);
        const response = await documentsServices.updateDocument(payload);
        if (response.status === 200 || response.status === 201) {
            await handleRepositoryGroups(editData.data.id, editData.edit)
            fetchDocuments(companyId, parentId);
            globalStates.handleToast(true, 'Updated successfully!', 'success');
            setIsSaving(false);
            setEditData({ edit: false, data: null });
            setIsModalOpen(false);
            setRepositoryGroups([]);
            setSelectedGroups([]);
        } else {
            setIsSaving(false);
            setIsToast(true);
            setToastMessage('Internal server error');
            setToastSeverity('error');
        }
    }

    const handleAddFile = () => {
        setType('FILE');
        setFileName('No file chosen');
        setFiles(null);
        setIsError(false);
        setIsToast(false);
        setIsModalOpen(true);
    }

    const handleFolderName = (e) => {
        let value = e.target.value;
        setIsError(false);
        setFolderName(value);
    }

    const validateFileLength = (files = []) => {
        let validFiles = [];
        let inValidFiles = [];
        for (let i = 0; i < files.length; i++) {
            if ((files[i].size / 1024 / 1024) > constants.REPOSITORY_MAX_FILE_SIZE_MB) {
                inValidFiles.push(files[i]);
            } else validFiles.push(files[i]);
        }

        return { validFiles, inValidFiles }
    }

    const validateFileFormats = (files = [], supportedFormats = []) => {
        let validFormatFiles = [];
        let inValidFormatFiles = [];
        for (let i = 0; i < files.length; i++) {
            let fileName = files[i].name;
            let fileExtension = fileName.split('.').pop().toLowerCase();
            // Check if the file extension is in the supported formats array
            if (supportedFormats.includes(`.${fileExtension}`)) {
                validFormatFiles.push(files[i]);
            } else {
                inValidFormatFiles.push(files[i]);

            }
        }

        return { validFormatFiles, inValidFormatFiles }
    }

    const handleFileChange = (e) => {
        if (!e.target.files.length) return
        let { validFormatFiles, inValidFormatFiles } = validateFileFormats(e.target.files, constants.LIBRARY_SUPPORTED_EXTENSIONS);
        if (inValidFormatFiles.length) {
            let firstFilename = commonUtil.truncateString(inValidFormatFiles[0].name, 18)
            let message = `${firstFilename} - Invalid file format`;
            if (inValidFormatFiles.length > 1) message = `${firstFilename} & ${inValidFormatFiles.length - 1} more - Invalid file format`;
            handleToast(true, message, 'error');
        }
        let { validFiles, inValidFiles } = validateFileLength(validFormatFiles);
        if (inValidFiles.length) {
            let firstFilename = commonUtil.truncateString(inValidFiles[0].name, 18)
            let message = `${firstFilename} - File size cannot be greater than 10MB`;
            if (inValidFiles.length > 1) message = `${firstFilename} & ${inValidFiles.length - 1} more - File size cannot be greater than ${constants.REPOSITORY_MAX_FILE_SIZE_MB}MB`;
            handleToast(true, message, 'error');
        }
        if (!validFiles.length) return
        if (validFiles.length > 1) {
            setFileName(`${validFiles.length} Files Selected`);
        } else {
            setFileName(validFiles[0].name);

        }
        setFiles(validFiles);
    }

    const handleDocumentClick = (data) => {
        console.log('handleDocumentClick = ', data);
        setSelectedFile(data);
        setShowDocumentDetail(true);
        if (data.type === 'FOLDER') {
            let temp = [...breadcrumbData];
            temp.push({});
            let newUrl = `/documents/${companyId}?parentId=${data.id}`;
            let newTitle = {
                title: data.name,
                url: newUrl
            }
            temp.push(newTitle);
            setBreadcrumbData(temp);
            router.history.replace(newUrl)
        }
        if (data.type === 'FILE') {
            window.open(data.path, '_blank');
        }
        return
    }

    const handleToast = (open, message, severity) => {
        setIsToast(open);
        setToastMessage(message);
        setToastSeverity(severity);
    }

    const validateFiles = (files) => {
        let names = [];
        let exists = false;
        let name = '';
        for (let i = 0; i < documents.length; i++) {
            names.push(documents[i].name)
        }

        for (let i = 0; i < files.length; i++) {
            if (names.includes(files[i].name)) {
                name = files[i].name;
                exists = true;
                break
            }
        }
        return { exists, name }
    }


    const handleSave = async () => {
        if (editData.edit) {
            handleEditDocument();
            return
        }
        if (type === 'FOLDER') {
            if (isEmpty(folderName)) {
                setIsError(true);
                handleToast(true, 'Please enter the folder name', 'error');
                return
            }
            setIsSaving(true);
            let newFolder = {
                companyId: companyId,
                name: folderName,
                type: "FOLDER",
                description: "Description",
                status: status
            }
            if (parentId) newFolder.parentId = parentId;
            let payload = await commonUtil.jsonToFormData(newFolder);
            let res = await documentsServices.createFolder(payload);
            if (res.status === 200 || res.status === 201) {
                let id = res.data.data.id || null;
                await handleRepositoryGroups(id)
                fetchDocuments(companyId, parentId);
                globalStates.handleToast(true, 'Folder created successfully!', 'success');
                setFolderName('')
                setIsModalOpen(false);
                setIsSaving(false);
                setSelectedGroups([]);
            }
            if (res.status === 400 || res.status === 401) {
                if (res.data.errors) {
                    handleToast(true, res.data.errors[0].message, 'error')
                }
                setIsSaving(false);
            }
            if (res.status === 500) {
                handleToast(true, 'Internal server error', 'error')
                setIsSaving(false);
            }

            return
        } else {
            console.log('handle save files = ', files);
            if (!files || !files.length) {
                handleToast(true, 'Please select the file', 'error');
                return
            }
            const validated = validateFiles(files);
            if (validated.exists) {
                handleToast(true, `${validated.name} - Already Exists`, 'error');
                return
            }
            setIsSaving(true);
            for (let i = 0; i < files.length; i++) {
                let newFile = {
                    companyId: companyId,
                    name: files[i].name,
                    type: "FILE",
                    filePath: files[i],
                    description: "Description",
                    status: status
                };
                if (parentId) newFile.parentId = parentId;
                const compressImages = false;
                let payload = await commonUtil.jsonToFormData(newFile, compressImages);
                let res = await documentsServices.createFile(payload);
                if (res.status === 200 || res.status === 201) {
                    let id = res.data.data.id || null;
                    await handleRepositoryGroups(id)
                    if (i === files.length - 1) {
                        fetchDocuments(companyId, parentId);
                        let message = `${files.length} File uploaded successfully!`;
                        if (files.length > 1) message = `${files.length} Files uploaded successfully!`;
                        setFiles(null);
                        setIsModalOpen(false);
                        setIsSaving(false);
                        setSelectedGroups([]);
                        globalStates.handleToast(true, message, 'success');
                        return
                    }

                }
                if (res.status === 400) {
                    setIsSaving(false);
                    if (res.data.errors) {
                        handleToast(true, `${files[i].name} - ${res.data.errors[0].message}`, 'error');
                        return
                    }
                }
                if (res.status === 500) {
                    handleToast(true, 'Internal server error', 'error');
                    setIsSaving(false);
                    return
                }
            }


            return
        }
    }

    const handleDelete = async (ele) => {
        let confirm = window.confirm("Do you really want to delete this document?")
        if (confirm) {
            let id = ele.id;
            if (ele.type === 'FOLDER') {
                let res = await documentsServices.deleteFolder({ id });
                if (res.status === 200 || res.status === 201) {
                    globalStates.handleToast(true, 'Deleted successfully!', 'success');
                    fetchDocuments(companyId, parentId);
                }
            } else {
                let res = await documentsServices.deleteFile({ id });
                if (res.status === 200 || res.status === 201) {
                    globalStates.handleToast(true, 'Deleted successfully!', 'success');
                    fetchDocuments(companyId, parentId);
                }
            }

        } else {
            return
        }
    }

    const handleSearch = (e) => {
        let searchVal = e.target.value;
        setIsSearching(true);
        if (searchVal === "") {
            setIsSearching(false);
            setDocuments(allData);
            return;
        }
        const filterBySearch = documents.filter((item) => {
            if (item.name.toLowerCase().includes(searchVal.toLowerCase())) {
                return item;
            }
        })
        setDocuments(filterBySearch);

    }

    const handleBack = () => {
        router.history.push(`/projects/${companyId}`)
    }

    const handleSort = (e) => {
        setSortType(e.target.value);
        let temp = [...documents];
        if (e.target.value === 'name') {
            temp.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            })
        }
        if (e.target.value === 'date') {
            temp.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.createdOn) - new Date(a.createdOn);
            });
        }
        setDocuments(temp);

    }

    const handleRepositoryGroups = async (repositoryId, isEdit = false) => {
        if (!repositoryId) return
        let groupIds = [];
        selectedGroups.forEach(ele => {
            if (!repositoryGroups.includes(ele)) groupIds.push({ id: ele, name: groups.find(item => item.id === ele)?.name, active: true })
        });
        if (isEdit) {
            let unLinkedGroups = [];
            repositoryGroups.forEach(ele => {
                if (!selectedGroups.includes(ele)) unLinkedGroups.push({ id: ele, name: groups.find(item => item.id === ele)?.name, active: false });
            })
            groupIds = [...groupIds, ...unLinkedGroups]
        }

        for (let i = 0; i < groupIds.length; i++) {
            let payload = {
                companyId: companyId,
                repositoryId: repositoryId,
                groupId: groupIds[i].id
            }
            if (groupIds[i].active) await documentsServices.addRepositoryToGroup(payload);
            else await documentsServices.removeRepositoryFromGroup(payload);
        }
    }


    if (loading) {
        return (
            <LoadingContainer />
        )
    } else {
        return (
            <div className={classes.mainContainer}>
                <div className={classes.header}>
                    {userType === 'superAdmin' ?
                        <img
                            onClick={handleBack}
                            src='/images/gba-assets/left-arrow.svg'
                            alt='left-arrow'
                        /> : null
                    }
                    <h1>
                        Documents & Assets Manager
                    </h1>
                    <div className={classes.searchBar} >
                        <SearchBar handleChange={handleSearch} />
                    </div>
                </div>
                <div className={classes.column1}>
                    <div className={classes.breadcrumb}>
                        {
                            breadcrumbData.map((ele, i) => (
                                i % 2 == 0 ?
                                    <h6 key={i} onClick={() => handleBreadcrumbClick(ele, i)}>{ele.title}</h6>
                                    : <h6 key={i}><i className="fa-sharp fa-solid fa-chevron-right"></i></h6>
                            ))
                        }
                        {/* <h6>My Document</h6>
                        <h6>Master O</h6>
                        <h6>File 23</h6> */}
                    </div>
                    <div className={classes.row2}>
                        {
                            documents.length ?
                                <>
                                    <div style={{ height: showDocumentDetail ? '85.9%' : '100%' }} className={classes.mainView}>
                                        <Label labelText={'All Files'} fs='0.8rem' />
                                        <div className={classes.sortBox}>
                                            <h6>Sort By:</h6>
                                            <select
                                                className={classes.sortSelect}
                                                onChange={handleSort}
                                                defaultValue={sortType}>
                                                <option value='name'>Name</option>
                                                <option value='date'>Date</option>
                                            </select>
                                        </div>
                                        <div className={classes.viewIconsBox}>
                                            {/* <img src='/images/documents/grid-view-icon.svg'/> */}
                                            {/* <img src='/images/documents/grid-view-icon.svg' /> */}
                                        </div>
                                        <div className={classes.documentsContainer}>
                                            <GridView
                                                documents={documents}
                                                handleDocumentClick={handleDocumentClick}
                                                handleAddFolder={handleAddFolder}
                                                handleAddFile={handleAddFile}
                                                handleDelete={handleDelete}
                                                _editClick={_editClick}
                                            />
                                        </div>

                                    </div>
                                    {showDocumentDetail ?
                                        <div className={classes.detailBox}>
                                            <Label labelText='Selected File:' fs='0.7rem' />
                                            <div className={classes.detail}>
                                                <h5>{selectedFile.name}</h5>
                                                <h6>Created on {new Date(selectedFile.createdOn).toLocaleString()}</h6>
                                                <h6>|</h6>
                                                <h6>Type: {selectedFile.type}</h6>
                                                <h6>|</h6>
                                                <h6>Opened by: {selectedFile.views} Learners</h6>
                                            </div>
                                        </div> : null}
                                </> :
                                <div className={classes.defaultScreen}>
                                    <CreationCard handleClick={handleAddFolder} text='Add Folder' icon='/images/documents/add-folder.svg' />
                                    <CreationCard handleClick={handleAddFile} text='Add File' icon='/images/documents/add-file.svg' />
                                </div>
                        }
                    </div>

                </div>
                {null && <aside className={classes.column2}>
                    <div className={classes.asideRow1}>
                        {/* <CustomButton
                            variant='contained'
                            textColor='#fff'
                            borderColor='#f4511e'
                            bgcolor='#f4511e'
                            fs="0.6rem"
                            fw={800}
                            ls={0}
                            btnText='+ Add New'
                        /> */}
                    </div>
                    <div className={classes.asideRow2}>
                        {/* <h6>Recent</h6>
                        <h6>Most Used</h6>
                        <h6>Deleted</h6> */}
                    </div>
                    <div className={classes.asideRow3}>
                        <div className={classes.labelBox}>
                            <img src='/images/documents/database.svg' alt='' />
                            <Label labelText="Storage:" fs='0.7rem' />
                        </div>
                        <progress id="file" value="2.8" max="10"> 32% </progress>
                        <h6>2.8 GB of 10 GB used</h6>
                        <CustomButton
                            variant='text'
                            textColor='#1E184C'
                            borderColor='#fff'
                            // bgcolor = '#fff'
                            fs="0.6rem"
                            fw={700}
                            ls={0}
                            btnText='Request More Storage'
                        />
                    </div>
                </aside>}
                {
                    isModalOpen ?
                        <DocumentsModal
                            type={type}
                            isModalOpen={isModalOpen}
                            title='Add New Folder'
                            setIsModalOpen={setIsModalOpen}
                            isError={isError}
                            folderName={folderName}
                            handleFolderName={handleFolderName}
                            fileName={fileName}
                            handleFileChange={handleFileChange}
                            handleSave={handleSave}
                            isSaving={isSaving}
                            isToast={isToast}
                            setIsToast={setIsToast}
                            toastMessage={toastMessage}
                            toastSeverity={toastSeverity}
                            editData={editData}
                            setEditData={setEditData}
                            status={status}
                            setStatus={setStatus}
                            groups={groups}
                            selectedGroups={selectedGroups}
                            setSelectedGroups={setSelectedGroups}
                            setRepositoryGroups={setRepositoryGroups}
                        /> : null
                }
            </div>
        )
    }


}

export default Documents;
