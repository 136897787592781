import React, { useState } from 'react'
import dayjs from 'dayjs';

//mui
// import { Add } from '@material-ui/icons';
import AddIcon from '@mui/icons-material/Add';
import { ButtonBase } from '@mui/material';
import { Add } from '@mui/icons-material';

//components
import CustomSelect from '../../../custom-styled/custom-select';
import MODateTimePicker from '../../../tms-components/MODateTimePicker'

//utils
import commonUtil from '../../../../utils/commonUtil';

//styles
import useStyles from '../../index.styles';
import Label from '../../../custom-styled/label';

import { CONTENT_TYPES } from '../../index';
import { LAUNCH_TYPES } from '../../index';

const Step2 = ({
    contentType,
    launchType,
    setLaunchType,
    setStep3,
    scheduledDates,
    setScheduledDates,
    stepTitle = 'Step 2: Launch Schedule',
    selectedNav,
    LAUNCHPG_NAV_TYPES

}) => {
    const classes = useStyles();
    let currentDate = dayjs(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(currentDate);
    const [startDateError, setStartDateError] = useState('');
    const [endDate, setEndDate] = useState(null);
    const [endDateError, setEndDateError] = useState('');
    const [startDateDialog, setStartDateDialog] = useState(false);
    const [endDateDialog, setEndDateDialog] = useState(false);

    let launchTypeData = Object.keys(LAUNCH_TYPES).map(item => ({ id: item, name: item }));
    // [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }, { id: 'DISABLE', name: 'DISABLE' }, { id: 'SCHEDULED', name: 'SCHEDULED' }];
    if (contentType.includes(CONTENT_TYPES.FORMS)) launchTypeData = [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }];
    if (contentType.includes(CONTENT_TYPES.DOCUMENTS)) launchTypeData = [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }, { id: 'DISABLE', name: 'DISABLE' }];
    if (selectedNav === LAUNCHPG_NAV_TYPES[1]) launchTypeData = [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }, { id: 'SCHEDULED', name: 'SCHEDULED' }];
    const launchTypeObject = commonUtil.objectify(launchTypeData, 'id');

    const _selectLaunchType = (item) => {
        // console.log('_selectLaunchType item = ', item);
        if (launchType.includes(item.id)) {
            return
        }
        // setContentType([...contentType, item.id]); //for selecting multiple content
        setLaunchType([item.id]);
        handleResetDates();
        if (item.id === 'SCHEDULED') {
            setScheduledDates([]);
            setShowDatePicker(true);
            setStep3(false);
            return;
        }
        setStep3(true);
    }

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate);
        setEndDate(null);
        setEndDateError('');
    }
    const handleEndDateChange = (newDate) => {
        setEndDate(newDate);
        setEndDateError('');
    }

    const handleResetDates = () => {
        setStartDate(currentDate);
        setEndDate(null);
        setEndDateError('');
    }

    const handleSaveDateChanges = () => {
        console.log('startDate > endDate = ', startDate > endDate);
        if (endDate !== null && startDate >= endDate) {
            setEndDateError('Expiry date must be after the launch date')
            return
        }
        let tempScheduledDates = [...scheduledDates];
        tempScheduledDates.push({
            order: tempScheduledDates.length + 1,
            launchesOn: dayjs(startDate).format('YYYY-MM-DD HH:mm:ss.SSS'),
            expiresOn: endDate ? dayjs(endDate).format('YYYY-MM-DD HH:mm:ss.SSS') : null
        });
        setScheduledDates(tempScheduledDates)
        handleResetDates()
        setShowDatePicker(false);
        setStep3(true);
    }
    const handleCancelDateChanges = () => {
        setShowDatePicker(false);
        handleResetDates()
    }
    const handleAddSchedule = () => {
        setShowDatePicker(true);
    }
    const handleDeleteSchedule = (item, idx) => {
        let tempScheduledDates = scheduledDates.filter((element, i) => i !== idx);
        setScheduledDates(tempScheduledDates);
        if (!tempScheduledDates.length) setStep3(false);
    }

    return (
        <div>
            <h2 style={{
                fontSize: '1.2rem',
                fontFamily: "Montserrat",
                color: "#707070",
                padding: '1rem 0rem',
                paddingTop: '1.5rem',
                borderTop: "1px solid rgb(112, 112, 112, 0.3)",
                marginTop: '1.5rem'
            }}>{stepTitle}</h2>

            <div className={classes.row} style={{ flexDirection: 'column', alignItems: 'flex-start', height: 'initial', gap: '1rem' }}>
                <CustomSelect
                    defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Launch Type', selected: launchType, dataObject: launchTypeObject })}
                    autoClose={true}
                    withSearch={false}
                    withSelectAllOption={false}
                    listData={launchTypeData}
                    selected={launchType}
                    handleSelect={_selectLaunchType}
                />
                {launchType.includes("SCHEDULED") ?
                    <>
                        {scheduledDates.map((item, i) => (
                            <div className={classes.scheduleListItem} key={i}>
                                <h5 className={classes.scheduleLilabel} >
                                    <Label labelText={`Schedule ${i + 1}:`} fw={800} />
                                    <img src='/images/icons/delete-photo.svg' alt='' title='Delete Schedule' onClick={(e) => handleDeleteSchedule(item, i)} />
                                </h5>
                                <div>
                                    <h4>Launch: {dayjs(item.launchesOn).format('DD-MM-YYYY HH:mm [Hrs]')}</h4>
                                    {item.expiresOn !== null ? <h4>Expiry: {dayjs(item.expiresOn).format('DD-MM-YYYY HH:mm [Hrs]')}</h4> : null}
                                </div>
                            </div>
                        ))}

                        {showDatePicker ?
                            <div className={classes.scheduledWrapper}>
                                <div className={classes.schedule}>
                                    <Label labelText={"Launch"} fw={800} />
                                    <MODateTimePicker
                                        onClick={() => {
                                            setStartDateDialog(true);
                                        }}
                                        open={startDateDialog}
                                        value={startDate}
                                        minDate={currentDate}
                                        errorText={startDateError}
                                        onClose={() => setStartDateDialog(false)}
                                        text={startDate.format('DD-MM-YYYY HH:mm [Hrs]')}
                                        handleDateChange={handleStartDateChange}
                                    />
                                </div>
                                <div className={classes.schedule}>
                                    <Label labelText={"Expiry"} fw={800} />
                                    <MODateTimePicker
                                        onClick={() => {
                                            setEndDateDialog(true);
                                        }}
                                        open={endDateDialog}
                                        value={endDate ? endDate : currentDate}
                                        minDate={startDate}
                                        errorText={endDateError}
                                        onClose={() => setEndDateDialog(false)}
                                        text={endDate ? endDate.format('DD-MM-YYYY HH:mm [Hrs]') : 'DD-MM-YYYY'}
                                        handleDateChange={handleEndDateChange}
                                    />
                                </div>
                                <div className={classes.iconWrapper}>
                                    <i onClick={handleSaveDateChanges} className="fa-solid fa-check" title='Save'></i>
                                    <i onClick={handleCancelDateChanges} className="fa-solid fa-xmark" title='Cancel'></i>
                                </div>
                            </div> :
                            <ButtonBase className={classes.addScheduleBtn} onClick={handleAddSchedule}><AddIcon />Add Schedule</ButtonBase>
                        }
                    </>
                    : null}

            </div>
        </div>
    )
}

export default Step2;
