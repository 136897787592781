import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import isEmpty from '../../../utils/isEmpty';

const func = (e) => {
    if (e?.key === 'Enter') e.preventDefault();
}

export default function SearchBar({ id = '', value = '', handleChange = func, placeholder = "Search", handleClear = func, handleKeyPress = func }) {
    return (
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
        >
            <IconButton sx={{ p: '5px', color: '#f4511e' }} aria-label="menu">
                <SearchIcon />
            </IconButton>
            <InputBase
                id={id}
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleChange}
                value={value}
                onKeyDown={handleKeyPress}
            />
            {!isEmpty(value) ?
                <IconButton sx={{ p: '5px', ':hover': { color: '#f4511e' } }} aria-label="menu" onClick={handleClear}>
                    <CloseIcon />
                </IconButton> : null
            }
        </Paper>
    );
}
