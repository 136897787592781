import React, {useEffect} from 'react'

//mui
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

//components
import Label from '../../../label';
import CustomInput from '../custom-input';

//styles
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    main: {
        display: 'flex',
        paddingTop: '0.4rem'
    },
    column1: {
        width: '87%',

    },
    column2: {
        width: '13%',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    radioGroup: {
        '& .MuiFormControlLabel-root': {
            margin: '0',

            '& .MuiTypography-root': {
                color: 'rgb(112, 112, 112, 0.8)',
                // fontFamily: "Montserrat",
                fontSize: "0.66rem",
                fontWeight: "600",
            },
            '& .MuiButtonBase-root': {
                padding: '0.4rem',
                margin: '0 0.3rem'
            }
        }
    },
    leftUnchecked: {
        '& .MuiSvgIcon-root': {
            color: 'red !important'
        },
        '& .MuiTypography-root': {
            fontWeight: '700 !important',
            color: 'red !important'
        }
    }


}));

const ResponseTF = ({
    designType,
    isDisableAll,
    isUnChecked,
    listitem,
    tfResponse,
    handleResponseChange,
    handleRadioChangeTF,
    responsesCharLimit }) => {
    console.log('tfResponse = ', tfResponse);
    console.log('TF responsesCharLimit = ', responsesCharLimit);
    const classes = useStyles();
    // const data = Array(2).fill(null).map((item, index) => (index));
    const data = ["True", "False"];
    const validationData = Array(2).fill(null).map((item, index) => ({ exceeded: false, limitText: `${responsesCharLimit} Characters` }));
    const [responsesValidations, setResponsesValidations] = React.useState(validationData);
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        let temp = [...responsesValidations];
        tfResponse.forEach((element, id) => {
            let value = element.option;
            if (value.length > responsesCharLimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
                temp[id] = { exceeded: true, limitText: `${value.length - responsesCharLimit} Characters Exceeded` };
                // setResponsesValidations(temp);
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
                if(listitem.emptyAnswers[id].isEmpty){
                    temp[id] = { exceeded: false, limitText: `*Required` };
                }else{
                    temp[id] = { exceeded: false, limitText: `${responsesCharLimit - value.length} Characters` };
                }
                // setResponsesValidations(temp);
            }
        });
        console.log('McqResponse temp = ', temp);
        setResponsesValidations(temp);
    }, [tfResponse])

    const handleChange = (event) => {
        // let value = parseInt(event.target.value)
        // setValue(value);
        handleRadioChangeTF(event)
        return
    };

    const handleInputChange = (e, index) => {
        handleResponseChange(e, index);
        let value = e.target.value;
        //SET WARNING IF LIMIT EXCEEDS
        let temp = [...responsesValidations];
        if (value.length > responsesCharLimit) {
            //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
            temp[index] = { exceeded: true, limitText: `${value.length - responsesCharLimit} Characters Exceeded` };
            setResponsesValidations(temp);
        } else {
            //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
            temp[index] = { exceeded: false, limitText: `${responsesCharLimit - value.length} Characters` };
            setResponsesValidations(temp);
        }
    }

    return (
        <>
            <Label labelText={'Responses:'} fs={'0.7rem'} />
            {data.map((ele, index) => (
                <div key={index} className={classes.main}>
                    <div className={classes.column1}>
                        <CustomInput
                            defaultValue={tfResponse[index].option}
                            isDisabled={designType === "TF_SoundRight" ? isDisableAll : true}
                            handleChange={(e) => handleInputChange(e, index)}
                            limitText={designType === "TF_SoundRight" ? responsesValidations[index].limitText:""}
                            isExceeded={responsesValidations[index].exceeded}
                            isEmpty={listitem.emptyAnswers[index].isEmpty}
                        />
                    </div>
                    <div className={classes.column2}>
                        <FormControl>
                            <RadioGroup
                                className={classes.radioGroup}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                            >
                                <FormControlLabel value="Incorrect"
                                 className={`${isUnChecked(index) ? classes.leftUnchecked : ""}`}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                            color: '#f4511e'
                                        },
                                    }}
                                    control={<Radio
                                        checked={tfResponse[index].value}
                                        onChange={handleChange}
                                        value={index.toString()}
                                        name="radio-buttons"
                                    // inputProps={{ 'aria-label': 'A' }}
                                    />} label={tfResponse[index].value ? "Correct" : "Incorrect"} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ResponseTF;
