import React, {
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
// import './component.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';

const NumericInput = forwardRef((props, ref) => {
  let inputRef = useRef(null);

  useImperativeHandle(ref, () => ({
    focus() {
      inputRef.current.focus();
    },
  }));

  let type = 'number';

  if (props.hasOwnProperty('type')) {
    type = props.type;
  }

  let value = '';

  if (props.hasOwnProperty('value')) {
    value = props.value;
  }
  let textAlign = 'left';
  if (props.hasOwnProperty('textAlign')) {
    textAlign = props.textAlign;
  }
  let min = Number.MIN_VALUE;
  if (props.hasOwnProperty('min')) {
    min = props.min;
  }
  let max = Number.MAX_VALUE;
  if (props.hasOwnProperty('max')) {
    max = props.max;
  }

  let disabled = false;

  if (props.hasOwnProperty('disabled')) {
    disabled = props.disabled;
  }
  if (props.hasOwnProperty('disabled')) {
    disabled = props.disabled;
  }
  let backgroundColor = 'white';
  if (props.hasOwnProperty('backgroundColor')) {
    backgroundColor = props.backgroundColor;
  }
  let placeholder = '';

  if (props.hasOwnProperty('placeholder')) {
    placeholder = props.placeholder;
  }

  let stepperbg = '#eaeaea';
  if (props.hasOwnProperty('stepperbg')) {
    stepperbg = props.stepperbg;
  }

  let unit = '';
  if (props.hasOwnProperty('unit')) {
    unit = props.unit;
  }

  let isSimpleStepper = false;
  if (props.hasOwnProperty('isSimpleStepper')) {
    isSimpleStepper = props.isSimpleStepper;
  }

  function handleInput() {
    if (inputRef.hasOwnProperty('current') && inputRef.current !== null) {
      if (props.hasOwnProperty('onChangeText')) {
        props.onChangeText(inputRef.current.value);
      }
    }
  }

  const stepUpAction = () => {
    const currentValue = parseInt(
      inputRef.current.value.length === 0 ? 0 : inputRef.current.value,
    );
    if (currentValue < max) {
      inputRef.current.value = currentValue + 1;
    } else {
      inputRef.current.value = currentValue;
    }
    handleInput();
  };

  const stepDownAction = () => {
    const currentValue = parseInt(
      inputRef.current.value.length === 0 ? 0 : inputRef.current.value,
    );
    if (currentValue > min) {
      inputRef.current.value = currentValue - 1;
    } else {
      inputRef.current.value = currentValue;
    }
    handleInput();
  };

  const StepperContainer = styled.div`
    width: ${(props) => (props.unit ? 80 : 30)};
    height: 100%;
    background: ${stepperbg};
    display: flex;
    align-items: center;
  `;

  const NoUnitSimpleStepper = () => {
    return (
      <StepperBox>
        {/* <StepperUp
          isSimpleStepper={props.isSimpleStepper}
          onClick={stepUpAction}
        />
        <StepperDown
          isSimpleStepper={props.isSimpleStepper}
          onClick={stepDownAction}
        /> */}
        <FontAwesomeIcon
          icon={faAngleUp}
          // className={classes.competeStepStyle}
          style={{
            fontSize: 12,
            color: '#F4511E',
          }}
          onClick={stepUpAction}
        />
        <FontAwesomeIcon
          icon={faAngleDown}
          // className={classes.competeStepStyle}
          style={{
            fontSize: 12,
            color: '#F4511E',
          }}
          onClick={stepDownAction}
        />
      </StepperBox>
    );
  };

  const Stepper = () => {
    return (
      <StepperContainer>
        <StepperBox>
          {/* <StepperUp onClick={stepUpAction} />
          <StepperDown onClick={stepDownAction} /> */}

          <FontAwesomeIcon
            icon={faAngleUp}
            // className={classes.competeStepStyle}
            style={{
              fontSize: '12px',

              color: '#F4511E',
              marginTop: 2,
              marginLeft: 5,
              marginRight: 5,
            }}
            onClick={stepUpAction}
          />
          <FontAwesomeIcon
            icon={faAngleDown}
            // className={classes.competeStepStyle}
            style={{
              fontSize: '12px',
              color: '#F4511E',

              marginBottom: 2,
              marginLeft: 5,
              marginRight: 5,
            }}
            onClick={stepDownAction}
          />
        </StepperBox>
        {props.unit && <UnitText>{props.unit}</UnitText>}
      </StepperContainer>
    );
  };

  return (
    <div>
      <Box
        width={props.width}
        height={props.height}
        style={props.style}
        border={props.border}
        maxWidth={props.maxWidth}
        minWidth={props.minWidth}
        backgroundColor={backgroundColor}>
        <StyledInput
          ref={inputRef}
          type={type}
          onChange={handleInput}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          min={min}
          max={max}
          textAlign={textAlign}
        />
        {props.isSimpleStepper === true ? NoUnitSimpleStepper() : Stepper()}
      </Box>
    </div>
  );
});

export const StepperBox = styled.div`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  background: 'transparent';
  justify-content: center;
  flexdirection: 'column';
  width: 30px;
  cursor: pointer;
  ${'' /* min-width: 30px; */}
`;
export const StepperButton = styled.span`
  display: inline-flex;
  width: 100%;
  height: 50%;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const StepperUp = styled(StepperButton)`
  &:after {
    content: '';
    width: 0;
    height: 0;
    ${'' /* border-bottom: ${(props) =>
      props.isSimpleStepper ? '5px solid #484848' : '5px solid #f4511e'};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; */}
    background: 'faAngleUp';
    position: absolute;
  }
`;

export const StepperDown = styled(StepperButton)`
  &:after {
    content: '';
    width: 0;
    height: 0;
    ${'' /* border-top: ${(props) =>
      props.isSimpleStepper ? '5px solid #484848' : '5px solid #f4511e'};
    border-left: 5px solid transparent;
    border-right: 5px solid transparent; */}
    position: absolute;
  }
`;

const Box = styled.div`
  width: ${(props) => (props.width ? props.width : '320px')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : '100%')};
  height: 27.3px;
  border: ${(props) => (props.border ? props.border : 'solid 0.5px #dde0e2')};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#ffffff'};
  display: flex;
  align-items: center;
`;

const StyledInput = styled.input`
  border: none;
  width: 100%;
  background-color: transparent;
  display: block;
  height: 100%;
  margin: 0;
  padding-left: 10px;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'left')};
  color: #707070;

  &:focus {
    border: none;
    outline: 0 !important;
  }

  %:active {
    border: none;
  }
`;

export const UnitText = styled.p`
  font-family: 'Open Sans';
  font-size: 10px;
  padding-right: 10px;
  font-weight: 600;
  color: #707070;
`;

export default NumericInput;
