import React, { Fragment } from 'react'

//  mui
import { Autocomplete, TextField, CircularProgress, Checkbox, Radio } from '@mui/material'

const AutoCompleteSearchApi = ({
    selectedOption,
    options,
    loading,
    changeOptionBaseOnValue,
    handleChange,
    multiple,
    noOptionsText = '',
    placeholder = ''
}) => {
    // console.log('selectedOption = ', selectedOption);
    return (
        <Autocomplete
            sx={{
                '& .MuiInputBase-root': {
                    padding: "0rem 1rem",
                    color: '#707070',
                    fontSize: '0.9rem',
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                },
            }}
            options={options}
            loading={loading}
            disableClearable={multiple}
            multiple={multiple}
            limitTags={2}
            noOptionsText={noOptionsText}
            getOptionLabel={(option) => { return option.name }}
            onChange={(e, option) => handleChange(e, option)}
            onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                    changeOptionBaseOnValue(value);
                }
            }}
            renderOption={(props, option, idx) => {
                return (
                    <li {...props} style={{ padding: "1rem 0.5rem", display: "flex", alignContent: "center", justifyContent: "space-between" }} key={idx}>
                        <div>
                            {multiple === true ? (
                                <Checkbox style={{ color: "#F4511E" }} checked={selectedOption.length > 0 && selectedOption.find((item) => item.id === option.id)} />
                            ) : (
                                <Radio style={{ color: "#F4511E" }} checked={selectedOption.length > 0 && selectedOption[0].id === option.id} />
                            )}
                            {option.name}
                        </div>
                    </li>
                )
            }}
            renderInput={(params, option) => {
                return (
                    <TextField {...params}
                        placeholder={placeholder}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </Fragment>
                            ),
                        }}
                    />
                )
            }}
        />
    )
}

export default AutoCompleteSearchApi