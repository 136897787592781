import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

//utils
import commonUtil from '../../../../utils/commonUtil';
import { useEffect } from 'react';

const LaListItem = ({ setPreview,
    list = [],
    item,
    type = null,
    isManageInteraction = true,
    deleteCopy = true,
    selectedCard,
    setSelectedCard,
    isActive,
    setIsActive, 
    coverAndObjective }) => {
    const classes = useStyles();
    useEffect(() => {
        console.log('LaListItem list = ', list);
        console.log('list item = ', item);
        console.log('LaListItem type = ', type);

    }, [item])

    const types = {
        COVER: 'COVER',
        OBJECTIVE: 'OBJECTIVE',
        ILA_MCQ_1: 'Multiple Choice Questions',
        ILA_MCQ_2: 'Multiple Choice Questions',
        ILA_TF_1: 'True False',
        ILA_TF_2: 'True False',
        ILA_MTRR_1: 'Match The Right Response',
        ILA_MTRR_2: 'Match The Right Response',
        ILA_FITB_1: 'Fill in the blanks',
        ILA_FITB_2: 'Fill in the blanks',
        ILA_SEQUENTIAL: 'Sequence',
    }

    const getType = (value) => {
        if (value === null) return
        if (!value.length) return 'No Interaction Added'
        console.log('getType = ', value.length);
        return types[value]
    }
    const getCardNumber = () => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
                return i + 1
            }
        }
    }
    const handleClick = (item) => {
        console.log('handleclick item = ', item);
        setIsActive(item.id)
        setSelectedCard({ ...selectedCard, cardType: 'default' });
        setPreview(item.thumbImage);
    }


    return (
        <div className={`${classes.listItemLa}`} style={{ backgroundColor: (isActive === item.id) ? "rgb(204, 204, 204, 0.3)" : "#fff" }}>
            <div className={classes.column}>
                <img src={item.thumbImage} alt='' onClick={() => handleClick(item)} />
                <div className={classes.cardDetails} >
                    {
                        isManageInteraction ?
                            <h4>Card {getCardNumber() +  coverAndObjective.length}/Interaction</h4> :
                            <h4>{getType(type)}</h4>
                    }
                    <h5>{getType(type)}
                        {/* {isManageInteraction?<i className="fa-solid fa-angle-down"></i>:null} */}
                    </h5>
                    <h6>{commonUtil.formatBytes(item.thumbFileSize ?  item.thumbFileSize : item.size )}</h6>
                </div>
                {deleteCopy ?
                    <div className={classes.buttonsBox}>
                        <div className={classes.duplicateDelete}>
                            <img src='/images/icons/pencil-edit-button.svg' alt='' />
                            <img src='/images/icons/delete-photo.svg' alt='' />
                        </div>
                    </div> : null}
            </div>
        </div>
    )
}

export default LaListItem;


const useStyles = makeStyles(() => ({
    listItemLa: {
        // backgroundColor : '#fff',
        marginBottom: '-10px',
        borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        padding: '0.7rem 0.6rem',
        backgroundColor: 'rgb(149, 152, 154, 0.1)'
        // '& > i': {
        //     width: '3%',
        //     cursor: 'pointer',
        //     fontSize: '0.9rem',
        //     color: '#f4511e'
        // },

    },
    column: {
        width: '93%',
        display: 'flex',
        gap: '0.7rem',
        cursor: 'pointer',
        marginLeft: '6.2%',
        '& > img': {
            border: '1px solid rgb(112, 112, 112, 0.1) ',/* Gray border */
            borderRadius: '4px ', /* Rounded border */
            // padding: '5px', /* Some padding */
            height: '4.5rem',
            width: '4.5rem', /* Set a small width */
        }
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.7rem',
        width: '100%',
        '& h4': {
            fontSize: '0.7rem',
            fontFamily: 'Montserrat',
            color: '#484848'
        },
        '& h5': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.64rem',
            fontStyle: 'italic',
            color: 'rgb(72, 72, 72, 0.5)',
            fontWeight: '600',
            '& i': {
                fontSize: '0.7rem',
                marginLeft: '0.5rem',
                // paddingTop : '0.5rem',
                color: '#f4511e'
            },
        },
        '& h6': {
            fontSize: '0.6rem',
            color: 'rgb(72, 72, 72, 0.7)'
        },
    },
    buttonsBox: {
        width: '33%',


    },
    duplicateDelete: {
        height: '63%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1rem',
        '& img': {
            height: '0.8rem',
            width: '0.8rem',
            '&:hover': {
                filter: 'invert(44%) sepia(28%) saturate(6975%) hue-rotate(349deg) brightness(97%) contrast(97%)'
            }
        },

    },
}));
