import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        columnGap: '1rem'
    },
    tagsContainer: {
        justifyContent: 'flex-start',
        columnGap: '0.5rem',
        rowGap: '0.5rem',
        marginBottom: '1rem',
        flexWrap: 'wrap'
    },
    selectedTagsContainer: {
        rowGap: '0.5rem',
        columnGap: '0.5rem',
        marginBottom: '1rem',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    selectedTag: {
        background: '#F4511E',
        color: '#FFF',
        borderRadius: '2rem',
        justifyContent: 'space-between !important',
        padding: '0.5rem 1rem'
    },
    defaultColor: {
        color: '#F4511E'
    },
    optionContainer: {
        overflow: 'auto',
        maxHeight: '30vh',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        padding: '1rem',
        "&::-webkit-scrollbar": {
            // 'scrollbar-width': 'thin'
            width: "0px",
        },
        '&:hover': {
            "&::-webkit-scrollbar": {
                // 'scrollbar-width': 'thin'
                width: "8px",
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "#fff",
            },
            "&::-webkit-scrollbar-thumb": {
                // boxShadow: 'inset 0 0 6px rgba(112, 112, 112, 0.5)',
                borderRadius: "4px",
                backgroundColor: '#f4511e'
            }
        }
    },
    options: {
        justifyContent: 'flex-start !important',
        padding: '0.5rem 0',
        cursor: 'pointer'
    }
}))

export default useStyles







// @import url("https://fonts.googleapis.com/css?family=Overpass");

// * {
//   box-sizing: border-box;
//   font-family: "Overpass", sans-serif;
// }

// body {
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-family: "Overpass", sans-serif;
// }

// .tags-input {
//   display: flex;
//   align-items: flex-start;
//   flex-wrap: wrap;
//   min-height: 48px;
//   width: 480px;
//   padding: 0 8px;
//   border: 1px solid rgb(214, 216, 218);
//   border-radius: 6px;
//   &:focus-within {
//     border: 1px solid #0052cc;
//   }
//   input {
//     flex: 1;
//     border: none;
//     height: 46px;
//     font-size: 14px;
//     padding: 4px 0 0 0;
//     &:focus {
//       outline: transparent;
//     }
//   }
// }

// #tags {
//   display: flex;
//   flex-wrap: wrap;
//   padding: 0;
//   margin: 8px 0 0 0;
// }

// .tag {
//   width: auto;
//   height: 32px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #fff;
//   padding: 0 8px;
//   font-size: 14px;
//   list-style: none;
//   border-radius: 6px;
//   margin: 0 8px 8px 0;
//   background: #0052cc;
//   .tag-title {
//     margin-top: 3px;
//   }
//   .tag-close-icon {
//     display: block;
//     width: 16px;
//     height: 16px;
//     line-height: 16px;
//     text-align: center;
//     font-size: 14px;
//     margin-left: 8px;
//     color: #0052cc;
//     border-radius: 50%;
//     background: #fff;
//     cursor: pointer;
//   }
// }

// @media screen and (max-width: 567px) {
//   .tags-input {
//     width: calc(100vw - 32px);
//   }
// }