import React, { useState, useEffect, useContext } from 'react';
import styles, { FlexRowDiv } from './style';
import ReactTooltip from 'react-tooltip';
// import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  NormalLabel,
  SemiBoldLabel,
  LightLabel,
  SmallFontLabel,
  HeadingLabel,
} from './components/Label';
import HorizontalRuler from './components/HorizontalRuler';
import stylecss from './microskill.module.css';
import { Page1_challange } from './Page1_challange';
import Page3_keywords from './Page3_keywords';
import Page4_CompletionCriteria from './Page4_CompletionCriteria';
// import SettingsIcon from '@material-ui/icons/Settings';
// import { EventNote, DescriptionOutlined } from '@material-ui/icons';
import { faAngleRight, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Page2_challange } from './Page2_challange';
// import MOClient from '../../MOClient';
import moment from 'moment';
import Loader from '../Loader';
import { PASSED_ROLEPLAY_DATA } from './msConstants';

//utils
import useQuery from '../../utils/useQuery';
import useRouter from '../../utils/useRouter';

//services
import challengesServices from '../../services/challenges';
import commonUtil from '../../utils/commonUtil';
import LoadingContainer from '../loading-container';

//global-states
import { GlobalStates } from '../../App';

function Spotlight(props) {
  const classes = styles();
  const query = useQuery();
  const router = useRouter();
  let isEdit = query.get('edit');
  let companyId = query.get('c_id');
  let microskillId = query.get('ms_id');
  let challengeId = query.get('challenge_id');
  const [savingInProgress, setsavingInProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fromEdit, setFromEdit] = useState(false);
  const [globalStates, setGlobalStates] = useContext(GlobalStates);

  const [projectName, setProjectName] = useState('');
  const [moduleName, setModuleName] = useState('');
  const StepProgressStatus = {
    INCOMPLETE: 'incomplete',
    INPROGRESS: 'inprogress',
    COMPLETED: 'completed',
  };

  const pages = [
    {
      id: 1,
      label: 1,
      name: 'challenge',
      status: StepProgressStatus.INPROGRESS,
      title: 'Create Roleplay Challenge',
      toolTipTitle: 'Scenario Set Up',
    },
    {
      id: 2,
      label: 2,
      name: 'challenge',
      status: StepProgressStatus.INCOMPLETE,
      title: 'Create Roleplay Challenge',
      toolTipTitle: 'Scenario Details',
    },
    {
      id: 3,
      label: 3,
      name: 'keyword',
      status: StepProgressStatus.INCOMPLETE,
      title: 'Create Roleplay Challenge',
      toolTipTitle: 'Skill Tags & Keywords',
    },
    {
      id: 4,
      label: 4,
      name: 'criteria',
      status: StepProgressStatus.INCOMPLETE,
      title: 'Create Roleplay Challenge',
      toolTipTitle: 'Criteria',
    },
  ];

  const [microSkillState, setMicroSkillState] = useState({
    Self_Id: null,
    Challenge_Seq: 2,
    Chlng_Id: null,
    Challenge_Type: 'sc',
    Level_Id: 1,
    Mod_Id: '123',
    Proj_Id: '123',
    Company_id: '123',
    Game_Type: 0,
    Skill_tag: null,
    //page 1
    Challenge_Name: '',
    Scenar: '',
    Video_name: null,
    uploaded_file: null,
    Input_key: 'video', //for now  static  to video
    Sample_transcript: '',
    Formatted_transcript: '',
    Media_Path: null,
    extracted: null,
    isTranscript: false,
    //page 2
    languages: 'en-US',
    Time_limit: 120,
    rating_method: '0',
    ratingMethod: 'AI',
    learner_Assist: true,
    learner_Practice: true,
    attempts: false,
    no_Attempts: 1,
    //page 3
    match_keyword_for_transcript: true,
    RequestKeyBox: [],
    //page 4
    Completion: [
      {
        name: 'Duration',
        rate: '120',
        check: '1',
        weightage: 1,
        weightageValue: 16.67,
      },
      {
        name: 'SpeechRate',
        rate: '140',
        check: '1',
        weightage: 1,
        weightageValue: 16.67,
      },
      {
        name: 'KeyMatch',
        rate: '50',
        check: '1',
        weightage: 1,
        weightageValue: 16.67,
      },
      {
        name: 'TranscriptMatch',
        rate: '50',
        check: '1',
        weightage: 1,
        weightageValue: 16.67,
      },
      {
        name: 'Tone',
        rate: '75',
        check: '1',
        weightage: 1,
        weightageValue: 16.67,
      },
      {
        name: 'Clarity',
        rate: '75',
        check: '1',
        weightage: 1,
        weightageValue: 16.67,
      },
    ],
  });

  const [skillSteps, updateSkillSteps] = useState(pages);
  const [currentPage, setCurrentPage] = useState(null);
  const [currentPageTitle, setCurrentPageTitle] = useState(null);

  useEffect(() => {
    setGlobalStates({ ...globalStates, showMsName: true });
    return () => {
      setGlobalStates({ ...globalStates, showMsName: false });
    }
  }, [])

  useEffect(() => {

    let challengeName = query.get('challengeName');
    console.log('challengeName = ', challengeName);
    if (isEdit) {
      // setsavingInProgress(true);
      setFromEdit(isEdit);
      setModuleName(challengeName);
      fetchChallengeData(microskillId, challengeId)
      // setMicroSkillState({
      //   ...microSkillState,
      //   Mod_Id: microskillId,
      //   Company_id: companyId,
      // });
      // setsavingInProgress(false);
    } else {
      setMicroSkillState({
        ...microSkillState,
        Challenge_Name: challengeName
      });
      setFromEdit(false);
      setLoading(false);

    }
    setCurrentPage(skillSteps[0]);
    setCurrentPageTitle(skillSteps[0].title);
    

  }, []);

  const fetchChallengeData = async (microskillId, challengeId) => {
    let res = await challengesServices.getChallengeData(microskillId, challengeId);
    console.log('fetchChallengeData response = ', res);
    if (res.status === 200) {
      let challengeData = res.data.data;
      console.log('fetchChallengeData challengeData = ', challengeData);
      if (challengeData.SpotLight === null) {
        setMicroSkillState({ ...microSkillState, Challenge_Name: challengeData.name });
        router.history.replace(`/microskill-studio/spotlight?microskillName=${query.get('microskillName')}&&challengeName=${challengeData.name}&&c_id=${companyId}&&p_id=xx&&ms_id=${microskillId}&&challenge_id=${challengeId}`);
        setLoading(false);
        return
      }
      let newLocalState = challenegDataToLocal(challengeData.SpotLight, challengeData.name, challengeData.sequence);
      console.log('newLocalState = ', newLocalState);
      setMicroSkillState(newLocalState);
      setLoading(false);
    }
  }

  const challenegDataToLocal = (responseData, name, sequence) => {
    // scenario: microSkillState.Scenar,
    // timeLimit: microSkillState.Time_limit,
    // mediaPath: microSkillState.Media_Path,
    // extracted : microSkillState.extracted,
    // keywords: microSkillState.RequestKeyBox, // pending
    // criteria: microSkillState.Completion,
    // transcript: microSkillState.Sample_transcript,
    // formatted: microSkillState.Formatted_transcript,
    // ratingMethod: microSkillState.ratingMethod,
    // assistance: microSkillState.learner_Assist,
    // practice: microSkillState.learner_Practice,
    // maxAttempts: microSkillState.no_Attempts,
    // matchKeywords: microSkillState.match_keyword_for_transcript,
    // language: microSkillState.languages
    let fileName = '';
    if (responseData.mediaPath !== null) {
      fileName = commonUtil.remoteUrlToFilename(responseData.mediaPath);
    }
    let keywords = [];
    responseData.keywords.forEach(element => {
      let inputs = [];
      element.keywords.forEach(item => {
        inputs.push(item.name)
      })
      let obj = {
        id: element.id,
        head: element.head,
        keywords: element.keywords,
        keywordInput: inputs,
        limit: 50,
      };

      keywords.push(obj);
    })
    let methods = ['AI', 'MANUAL', 'HYBIRD']
    const data = {
      Self_Id: null,
      Challenge_Seq: sequence,
      Chlng_Id: null,
      Challenge_Type: 'sc',
      Level_Id: 1,
      Mod_Id: microskillId,
      Proj_Id: '123',
      Company_id: companyId,
      Game_Type: 0,
      Skill_tag: null,
      context: responseData.Context,
      //page 1
      Challenge_Name: name,
      Scenar: responseData.scenario,
      Video_name: fileName,
      uploaded_file: null,
      Input_key: 'video', //for now  static  to video
      Sample_transcript: responseData.transcript,
      Formatted_transcript: responseData.formatted,
      Media_Path: responseData.mediaPath,
      extracted: responseData.extracted,
      //page 2
      languages: responseData.language,
      Time_limit: responseData.timeLimit,
      rating_method: methods.indexOf(responseData.ratingMethod),
      ratingMethod: responseData.ratingMethod,
      learner_Assist: responseData.assistance,
      learner_Practice: responseData.practice,
      attempts: responseData.maxAttempts > 1,
      no_Attempts: responseData.maxAttempts,
      //page 3
      match_keyword_for_transcript: responseData.matchKeywords,
      RequestKeyBox: keywords,
      //page 4
      Completion: responseData.criteria,
      isUploading: null,
      isTranscript: responseData.isTranscript
    }
    return data;
  }




  //OLD USE EFFECT
  // const data = getChallengeData();
  //     if (data) {
  //       let {
  //         moduleID,
  //         projID,
  //         companyID,
  //         Challenge_Seq,
  //         isEdit = false,
  //         challengeId = null,
  //         projectName = '',
  //         moduleName = '',
  //       } = data;
  //       setProjectName(projectName);
  //       setModuleName(moduleName);
  //       setFromEdit(isEdit);
  //       if (isEdit && challengeId) {
  //         setsavingInProgress(true);
  //         //COMMMENTED WHILE REMOVING MOClient
  //         // const fetch_result = MOClient.getCreatorCoachingDetails(challengeId);
  //         // fetch_result.then((result) => {
  //         //   if (result.status !== 200) {
  //         //     const result_json = result.json();
  //         //     result_json.then((result_json) => {
  //         //       //do nothing for now
  //         //       console.log(result_json);
  //         //     });
  //         //   } else {
  //         //     const async_result_json = result.json();
  //         //     async_result_json.then((result_json) => {
  //         //       setMicroSkillState((prevState) => ({
  //         //         ...prevState,
  //         //         ...result_json.resource,
  //         //       }));
  //         //       setCurrentPage((prevState) => skillSteps[0]);
  //         //       setCurrentPageTitle((prevState) => skillSteps[0].title);
  //         //     });
  //         //   }
  //         // });
  //       } else {
  //         setMicroSkillState({
  //           ...microSkillState,
  //           Mod_Id: moduleID,
  //           Proj_Id: projID,
  //           Company_id: companyID,
  //           Challenge_Seq: Challenge_Seq,
  //         });
  //         setCurrentPage(skillSteps[0]);
  //         setCurrentPageTitle(skillSteps[0].title);
  //       }
  //     } else {
  //       setFromEdit(false);
  //       setCurrentPage(skillSteps[0]);
  //       setCurrentPageTitle(skillSteps[0].title);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setsavingInProgress(false);
  //   }

  const getChallengeData = () => {
    try {
      const roleplayChallangeData = JSON.parse(
        window.localStorage.getItem(PASSED_ROLEPLAY_DATA),
      );
      console.log('roleplayChallangeData from localstorage = ', roleplayChallangeData);
      return roleplayChallangeData;
    } catch (error) {
      throw Error('no data found');
    }
  };

  const updateToNextPage = (page) => {
    console.log('update to  next page called');
    const prevPage = { ...currentPage, status: StepProgressStatus.COMPLETED };
    const updatedPage = { ...page, status: StepProgressStatus.INPROGRESS };
    const newSteps = skillSteps.map((step) =>
      step.id == prevPage.id
        ? prevPage
        : step.id == updatedPage.id
          ? updatedPage
          : step,
    );
    updateSkillSteps(newSteps);
    setCurrentPage(updatedPage);
    setCurrentPageTitle(updatedPage.title);
  };

  const addChallengeAction = () => {
    try {
      setsavingInProgress(true);
      if (microSkillState.Chlng_Id === null) {
        let data = {
          Challenge_Name: microSkillState.Challenge_Name,
          Challenge_Seq: microSkillState.Challenge_Seq,
          Challenge_Type: microSkillState.Challenge_Type,
          Game_Type: microSkillState.Game_Type,
          Level_Id: microSkillState.Level_Id,
          Media_Path: microSkillState.Media_Path,
          Mod_Id: microSkillState.Mod_Id,
          Proj_Id: microSkillState.Proj_Id,
          Company_id: microSkillState.Company_id,
        };
        //COMMMENTED WHILE REMOVING MOClient
        // const fetch_result = MOClient.addChallenge(data);
        // fetch_result.then((result) => {
        //   if (result.status !== 200) {
        //     setsavingInProgress(false);
        //     const result_json = result.json();
        //     result_json.then((result_json) => {
        //       //do nothing for now
        //       console.log(result_json);
        //     });
        //   } else {
        //     const async_result_json = result.json();
        //     async_result_json.then((result_json) => {
        //       const newChalangeId = result_json.d;
        //       setMicroSkillState((prevState) => ({
        //         ...prevState,
        //         Chlng_Id: newChalangeId,
        //       }));
        //       saveDraftChallengeAction(newChalangeId);
        //     });
        //   }
        // });
      } else {
        const challengeId = microSkillState.Chlng_Id;
        saveDraftChallengeAction(challengeId);
      }
    } catch (error) {
      setsavingInProgress(false);
      console.error(error);
    }
  };

  const saveDraftChallengeAction = async (Chlng_Id) => {
    try {
      const draftData = {
        Chlng_Id: Chlng_Id,
        Challenge_Name: microSkillState.Challenge_Name,
        Self_Id: microSkillState.Self_Id,
        Completion: microSkillState.Completion,
        Input_key: microSkillState.Input_key,
        Level_Id: microSkillState.Level_Id,
        Mod_Id: microSkillState.Mod_Id,
        Proj_Id: microSkillState.Proj_Id,
        RequestKeyBox: microSkillState.RequestKeyBox,
        Scenar: microSkillState.Scenar,
        Skill_tag: microSkillState.Skill_tag,
        Time_limit: microSkillState.Time_limit,
        Video_name: microSkillState.Video_name,
        Sample_transcript: microSkillState.Sample_transcript,
        Formatted_transcript: microSkillState.Formatted_transcript,
        languages: microSkillState.languages,
        rating_method: microSkillState.rating_method,
        learner_Assist: microSkillState.learner_Assist,
        learner_Practice: microSkillState.learner_Practice,
        attempts: microSkillState.attempts,
        no_Attempts: microSkillState.no_Attempts,
        match_keyword_for_transcript:
          microSkillState.match_keyword_for_transcript,
      };
      //COMMMENTED WHILE REMOVING MOClient
      // const fetch_result = MOClient.saveSelfCriteria(draftData);
      // fetch_result.then((result) => {
      //   setsavingInProgress(false);
      //   if (result.status !== 200) {
      //     const result_json = result.json();
      //     result_json.then((result_json) => {
      //       //do nothing for now
      //       console.log(result_json);
      //     });
      //   } else {
      //     const async_result_json = result.json();
      //     async_result_json.then((result_json) => {
      //       setMicroSkillState((prevSate) => ({
      //         ...prevSate,
      //         Self_Id: result_json.d,
      //       }));
      //     });
      //   }
      // });
    } catch (error) {
      setsavingInProgress(false);
      console.error(error);
    }
  };

  const updateToPrevPage = (page) => {
    console.log('update to  prev page called');
    console.log(page);
    setCurrentPage(page);
    setCurrentPageTitle(page.title);
  };

  const stepProgressBar = (steps) => {
    const items = steps.map((page, index) => (
      <div className={classes.stepItemsStyle}>
        <div
          className={classes.stepStyle}
          style={{
            borderColor:
              page.status == StepProgressStatus.INCOMPLETE
                ? '#FFFFFF'
                : '#F4511E',
            backgroundColor: '#fff',
          }}
          // ref={setTriggerRef}
          data-tip={page.toolTipTitle}>
          {StepProgressStatus.COMPLETED == page.status ? (
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={classes.competeStepStyle}
              onClick={() => updateToPrevPage(page)}
            />
          ) : (
            <HeadingLabel
              style={{
                textAlign: 'center',
                color:
                  StepProgressStatus.INCOMPLETE == page.status
                    ? '#707070'
                    : '#F4511E',
              }}>
              {page.label}
            </HeadingLabel>
          )}
        </div>

        {index < steps.length - 1 ? (
          <div className={classes.progressPathContainer}>
            {StepProgressStatus.COMPLETED == page.status && (
              <span
                style={{
                  width: '100%',
                  background: '#F4511E',
                }}></span>
            )}
          </div>
        ) : (
          <></>
        )}
        <ReactTooltip
          place="bottom"
          type="light"
          effect="solid"
          textColor="#707070"
          className={classes.toolTipStyle}
        />
      </div>
    ));
    return <ul className={classes.stepTableStyle}>{items}</ul>;
  };

  if (loading) {
    return (
      <LoadingContainer />
    )
  } else {
    return (
      <div
        id="MicroskillStudioApp"
        style={{ backgroundColor: '#FFF', height: 'calc(100vh - 3.4rem)' }}>
        {/* <div className={`${classes.top_bar} ${classes.top_bar_wrapper}`}>
          <h2 className={classes.top_bar_heading}>Master-O Studio</h2>
        </div> */}
        <div className={classes.container}>
          {/* <div className={classes.content_bar_container}>
            <FlexRowDiv className={classes.contentbar_top}>
              <SmallFontLabel style={{ opacity: 0.5 }}>
                All Projects&nbsp;&nbsp;
              </SmallFontLabel>
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  height: '10px',
                  width: '7px',
                  color: '#F4511E',
                  opacity: 0.5,
                }}
              />
              <SmallFontLabel style={{ opacity: 0.5 }}>
                &nbsp;&nbsp; {projectName}&nbsp;&nbsp;
              </SmallFontLabel>
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  height: '10px',
                  width: '7px',
                  color: '#F4511E',
                  opacity: 0.5,
                }}
              />
              <SmallFontLabel style={{ opacity: 0.5 }}>
                &nbsp;&nbsp; {moduleName}&nbsp;&nbsp;
              </SmallFontLabel>
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{
                  height: '10px',
                  width: '7px',
                  color: '#F4511E',
                  opacity: 0.5,
                }}
              />
              <SmallFontLabel>
                &nbsp;&nbsp; {microSkillState.Challenge_Name}
              </SmallFontLabel>
            </FlexRowDiv>
            <FlexRowDiv className={classes.contentbar_Right}>
              <label
                style={{
                  font: 'normal normal normal 14px Open Sans',
                  color: '#707070',
                }}>{`Draft saved at: ${moment().format(
                'hh:mm A',
              )} (${moment().format('DD-MM-YYYY')})`}</label>
            </FlexRowDiv>
          </div> */}
          {currentPageTitle && (
            <div className={classes.heading_container}>
              <HeadingLabel className={classes.heading_title_style}>
                {currentPageTitle}
              </HeadingLabel>
              {stepProgressBar(skillSteps)}
            </div>
          )}
          {currentPage && currentPage.id == 1 && (
            <Page1_challange
              pages={pages}
              setCurrentPage={updateToNextPage}
              microSkillState={microSkillState}
              setMicroSkillState={setMicroSkillState}
              addChallengeAction={addChallengeAction}
            />
          )}
          {currentPage && currentPage.id == 2 && (
            <Page2_challange
              pages={pages}
              setCurrentPage={updateToNextPage}
              microSkillState={microSkillState}
              setMicroSkillState={setMicroSkillState}
              addChallengeAction={addChallengeAction}
            />
          )}
          {currentPage && currentPage.id == 3 && (
            <Page3_keywords
              pages={pages}
              setCurrentPage={updateToNextPage}
              microSkillState={microSkillState}
              setMicroSkillState={setMicroSkillState}
              addChallengeAction={addChallengeAction}
            />
          )}
          {currentPage && currentPage.id == 4 && (
            <Page4_CompletionCriteria
              pages={pages}
              setCurrentPage={updateToNextPage}
              microSkillState={microSkillState}
              setMicroSkillState={setMicroSkillState}
              fromEdit={fromEdit}
            />
          )}
        </div>
        {savingInProgress && (
          <div
            style={{
              width: '54px',
              position: 'absolute',
              backgroundColor: 'lightgrey',
              top: 'calc(50% - 27px)',
              left: 'calc(50% - 27px)',
              borderRadius: 4,
              height: 54,
            }}>
            <Loader fill="#4f0060" />
          </div>
        )}
      </div>
    );
  }


}

export default Spotlight;
