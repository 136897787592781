import React from 'react'

import constants from '../../constants'

const ComingSoon = () => {
    return (
        <div style={{
            height: `calc(100vh - ${constants.HEADER_HEIGHT})`,
            fontSize: '4rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: "500",
            display : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#38164D',
            color: '#f9f9f9'
        }}>
            Coming Soon...
        </div>
    )
}

export default ComingSoon
