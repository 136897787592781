import constants from "../constants"

const gbaValidations = constants.GBA_VALIDATIONS;

const validateContext = (designType, context, type) => {
    if (context.length > gbaValidations[designType].contextMaxLength) {
        return 'Characters exceeded!'
    } else if (!context.length) {
        if (type === "TF") return 'Valid'
        return 'Context required!'
    } else {
        return 'Valid'
    }
}

const getCorrectCount = (answers = []) => {
    let count = 0;
    let inCorrectIndexes = [];
    for (let i = 0; i < answers.length; i++) {
        if (answers[i].value) {
            count++
        } else {
            inCorrectIndexes.push(i);
        }
    }
    return { count, inCorrectIndexes };
}

const validateQuestion = (designType, question, type) => {
    // console.log('val = ', gbaValidations[designType].question);
    if (question.length > gbaValidations[designType].questionMaxLength) {
        return 'Characters exceeded!'
    } else if (!question.length) {
        if (type === 'MA') return 'Context required!'
        return 'Question required!'
    } else {
        return 'Valid'
    }
}

const validateResponses = (designType, responses, emptyResponses) => {
    // console.log('validateResponses = ',responses);
    let newResponses = [];
    let newEmptyAnswers = [...emptyResponses];
    responses.forEach((element, i) => {
        if (element.option.length > gbaValidations[designType].responseMaxLength) {
            newResponses.push('Characters exceeded!')
        } else if (!element.option.length) {
            newResponses.push('Responses missing!');
            newEmptyAnswers[i] = { isEmpty: true };
        } else {
            //HERE ITS VALID
            newEmptyAnswers[i] = { isEmpty: false };
        }
    });


    console.log('newEmptyAnswers = ', newEmptyAnswers);
    return { newResponses, newEmptyAnswers };
}

const validateUnChecked = (designType, responses, unCheckedOptions) => {
    let minCheckedInValidations = [];
    let newUnCheckedOptions = [...unCheckedOptions];
    const { count, inCorrectIndexes } = getCorrectCount(responses);
    console.log('count = ', count);
    console.log('inCorrectIndexes = ', inCorrectIndexes);
    if (count < constants.GBA_VALIDATIONS[designType].minCheckedRequired) {
        for (let i = 0; i < inCorrectIndexes.length; i++) {
            newUnCheckedOptions[inCorrectIndexes[i]] = { unChecked: true };
        }
        minCheckedInValidations.push('Responses missing!');
    }
    return { newUnCheckedOptions, minCheckedInValidations };
}

const validateListitem = (type, designType, listItem) => {
    console.log('validateListitem type = ', type);
    // VALIDATING MCQ
    if (type === "MCQ") {
        let newStatus = 'done'
        let isQuestionEmpty = false;
        let tempValidations = []
        //FIRST CHECK => IS QUESTION VALID
        let isQuestionValid = validateQuestion(designType, listItem.question);
        console.log('validateQuestion = ', type, " ", isQuestionValid);
        if (isQuestionValid !== 'Valid') {
            newStatus = 'error'
            tempValidations.push(isQuestionValid)
            console.log('Question is not valid ');
        }
        if (isQuestionValid === 'Question required!') {
            isQuestionEmpty = true
        }
        //SECOND CHECK => ARE RESPONSES VALID
        let validatedResponses = validateResponses(designType, listItem.answers, listItem.emptyAnswers);
        console.log('validatedResponses = ', validatedResponses);
        //IF ANY INVALIDATION IN RESPONSES
        if (validatedResponses['newResponses'].length) {
            newStatus = 'error'
        }
        let newValidations = tempValidations.concat(validatedResponses['newResponses']);

        //THIRD CHECK => IS SKILL TAG SELECTED
        if (!listItem.skillId.length) {
            newStatus = 'error'
            newValidations.push('Skill Tag Required.')
        }

        let { newUnCheckedOptions, minCheckedInValidations } = validateUnChecked(designType, listItem.answers, listItem.unCheckedOptions);
        if (minCheckedInValidations.length) {
            newStatus = 'error'
            newValidations.push(minCheckedInValidations[0])
        }

        let uniqueValidations = new Set(newValidations)
        console.log('newStatus = ', newStatus);
        console.log('uniqueValidations = ', uniqueValidations.size);
        if (!uniqueValidations.size && !listItem.skillId.length) {
            //HERE QUESTION AND RESPONSES ARE VALID BUT SKILL TAG IS MISSING.
            newStatus = 'inProgress'
        }
        let newListitem = {
            ...listItem,
            status: newStatus,
            validations: [...uniqueValidations],
            isQuestionEmpty: isQuestionEmpty,
            emptyAnswers: validatedResponses['newEmptyAnswers'],
            unCheckedOptions: newUnCheckedOptions
        }
        return newListitem;
    }
    if (type === "TF") {
        let newStatus = 'done'
        let tempValidations = []
        let isQuestionEmpty = false;
        let isContextEmpty = false;
        let isAudioEmpty = false;
        if (designType === "TF_SoundRight") {
            //FIRST CHECK => IS AUDIO FILE UPLOADED 
            if (!listItem.audioFile) {
                //HERE AUDIO FILE IS MISSING
                newStatus = 'error';
                isAudioEmpty = true;
                tempValidations.push('Audio File Required!');
                console.log('TF Context is not valid ');
            }

            //SECOND CHECK => IS QUESTION VALID
            let isQuestionValid = validateQuestion(designType, listItem.question);
            console.log('validateQuestion = ', type, " ", isQuestionValid);
            if (isQuestionValid !== 'Valid') {
                newStatus = 'error'
                tempValidations.push(isQuestionValid)
                console.log('TF Question is not valid ');
            }
            if (isQuestionValid === 'Question required!') {
                isQuestionEmpty = true
            }
            //THIRD CHECK => ARE RESPONSES VALID
            let validatedResponses = validateResponses(designType, listItem.answers, listItem.emptyAnswers);
            console.log('Sounds right validatedResponses = ', validatedResponses);
            //IF ANY INVALIDATION IN RESPONSES
            if (validatedResponses['newResponses'].length) {
                newStatus = 'error'
            }
            let newValidations = tempValidations.concat(validatedResponses['newResponses']);

            if (!listItem.skillId.length) {
                newStatus = 'error'
                newValidations.push('Skill Tag Required.')
            }

            let { newUnCheckedOptions, minCheckedInValidations } = validateUnChecked(designType, listItem.answers, listItem.unCheckedOptions);
            if (minCheckedInValidations.length) {
                newStatus = 'error'
                newValidations.push(minCheckedInValidations[0])
            }

            let uniqueValidations = new Set(newValidations);
            console.log('TF uniqueValidations = ', uniqueValidations);
            // if (!uniqueValidations.size && !listItem.skillId.length) {
            //     //HERE CONTEXT AND QUESTION ARE VALID BUT SKILL TAG IS MISSING.
            //     newStatus = 'inProgress'
            // }

            let newListitem = {
                ...listItem,
                status: newStatus,
                isAudioEmpty: isAudioEmpty,
                isQuestionEmpty: isQuestionEmpty,
                validations: [...uniqueValidations],
                emptyAnswers: validatedResponses['newEmptyAnswers'],
                unCheckedOptions: newUnCheckedOptions
            }
            return newListitem;
        } else {
            //CHECKS FOR REST OF TRUE-FALSE

            //FIRST CHECK => IS CONTEXT VALID 
            let isContextValid = validateContext(designType, listItem.contextObj.context, "TF");
            console.log('validateContext = ', type, " ", isContextValid);
            if (isContextValid !== 'Valid') {
                newStatus = 'error';
                tempValidations.push(isContextValid);
                console.log('TF Context is not valid ');
            }
            if (isContextValid === 'Context required!') {
                isContextEmpty = true;
            }

            //SECOND CHECK => IS QUESTION VALID
            let isQuestionValid = validateQuestion(designType, listItem.question);
            console.log('validateQuestion = ', type, " ", isQuestionValid);
            if (isQuestionValid !== 'Valid') {
                newStatus = 'error'
                tempValidations.push(isQuestionValid)
                console.log('TF Question is not valid ');
            }
            if (isQuestionValid === 'Question required!') {
                isQuestionEmpty = true
            }
            // let newValidations = tempValidations.concat(areResponsesValid);

            //THIRD CHECK => IS SKILL TAG SELECTED
            if (!listItem.skillId.length) {
                newStatus = 'error'
                tempValidations.push('Skill Tag Required.')
            }

            let { newUnCheckedOptions, minCheckedInValidations } = validateUnChecked(designType, listItem.answers, listItem.unCheckedOptions);
            if (minCheckedInValidations.length) {
                newStatus = 'error'
                tempValidations.push(minCheckedInValidations[0])
            }

            let uniqueValidations = new Set(tempValidations)
            console.log('TF uniqueValidations = ', uniqueValidations);
            // if (!uniqueValidations.size && !listItem.skillId.length) {
            //     //HERE CONTEXT AND QUESTION ARE VALID BUT SKILL TAG IS MISSING.
            //     newStatus = 'inProgress'
            // }

            // console.log('newStatus = ', newStatus);
            let newListitem = { 
                ...listItem, 
                status: newStatus, 
                isContextEmpty: isContextEmpty, 
                isQuestionEmpty: isQuestionEmpty, 
                validations: [...uniqueValidations],
                unCheckedOptions: newUnCheckedOptions
            }
            return newListitem;
        }



    }
    if (type === "MA") {
        let newStatus = 'done'
        let tempValidations = [];
        let isQuestionEmpty = false;
        let isImageEmpty = false;
        //FIRST CHECK => IS CONTEXT VALID
        //IN MA CONTEXT = QUESTION
        let isQuestionValid = validateQuestion(designType, listItem.question, type)
        console.log('validateContext = ', type, " ", isQuestionValid);
        if (isQuestionValid !== 'Valid') {
            newStatus = 'error'
            tempValidations.push(isQuestionValid)
            console.log('MA question is not valid ');
        }
        if (isQuestionValid === 'Context required!') {
            isQuestionEmpty = true
        }

        if (designType === "MA_RevealThePicture") {
            //CHECKING IF IMAGE IS SELECTED OR NOT
            if (!listItem.imageFile) {
                //HERE IMAGE FILE IS MISSING
                newStatus = 'error';
                isImageEmpty = true;
                tempValidations.push('Image Required!');
            }
        }
        //SECOND CHECK => ARE RESPONSES VALID
        let validatedResponses = validateResponses(designType, listItem.answers, listItem.emptyAnswers);
        if (validatedResponses['newResponses'].length) {
            newStatus = 'error'
        }
        let newValidations = tempValidations.concat(validatedResponses['newResponses']);
        if (!listItem.skillId.length) {
            newStatus = 'error'
            newValidations.push('Skill Tag Required.')
        }

        //THIRD CHECK => ARE MIN RESPONSES CHECKED
        let { newUnCheckedOptions, minCheckedInValidations } = validateUnChecked(designType, listItem.answers, listItem.unCheckedOptions);
        if (minCheckedInValidations.length) {
            newStatus = 'error'
            newValidations.push(minCheckedInValidations[0])
        }
        let uniqueValidations = new Set(newValidations)
        console.log('MA uniqueValidations = ', uniqueValidations);
        console.log('newUnCheckedOptions = ', newUnCheckedOptions);
        // if (!uniqueValidations.size && !listItem.skillId.length) {
        //     //HERE CONTEXT AND QUESTION ARE VALID BUT SKILL TAG IS MISSING.
        //     newStatus = 'inProgress'
        // }
        // console.log('newStatus = ', newStatus);
        let newListitem;
        if (designType === "MA_RevealThePicture") {
            newListitem = {
                ...listItem,
                status: newStatus,
                isImageEmpty: isImageEmpty,
                isQuestionEmpty: isQuestionEmpty,
                validations: [...uniqueValidations],
                emptyAnswers: validatedResponses['newEmptyAnswers'],
                unCheckedOptions: newUnCheckedOptions
            }
        } else {
            newListitem = {
                ...listItem,
                status: newStatus,
                isQuestionEmpty: isQuestionEmpty,
                validations: [...uniqueValidations],
                emptyAnswers: validatedResponses['newEmptyAnswers'],
                unCheckedOptions: newUnCheckedOptions
            }
        }
        return newListitem;
    }
    if (type === "MTRR") {
        let newStatus = 'done'
        let tempValidations = []
        let isContextEmpty = false;
        //FIRST CHECK => IS CONTEXT VALID
        let isContextValid = validateContext(designType, listItem.contextObj.context)
        console.log('validateContext = ', type, " ", isContextValid);
        if (isContextValid !== 'Valid') {
            newStatus = 'error'
            tempValidations.push(isContextValid)
            console.log('MTRR Context is not valid ');
        }
        if (isContextValid === 'Context required!') {
            isContextEmpty = true
        }
        //SECOND CHECK => IS QUESTION VALID
        let element = [...listItem.questionArr];
        // console.log('MTRR before element = ', element);
        for (let i = 0; i < element.length; i++) {
            let isQuestionValid = validateQuestion(designType, element[i].question);
            if (isQuestionValid !== 'Valid') {
                newStatus = 'error'
                tempValidations.push(isQuestionValid)
            }
            console.log('MTRR isQuestionValid = ', isQuestionValid);
            if (isQuestionValid === 'Question required!') {
                element[i].isQuestionEmpty = true;
            }
        }
        console.log('MTRR after element = ', element);
        let tempResponse = []
        element.forEach(item => {
            tempResponse.push(item.answers[0])
        })
        let emptyResponses = []
        element.forEach(item => {
            emptyResponses.push(item.emptyAnswers[0])
        })
        console.log('mtrr responses for validation = ', emptyResponses);


        let validatedResponses = validateResponses(designType, tempResponse, emptyResponses);
        if (validatedResponses['newResponses'].length) {
            newStatus = 'error'
        }
        let newValidations = tempValidations.concat(validatedResponses['newResponses']);
        if (!listItem.skillId.length) {
            newStatus = 'error'
            newValidations.push('Skill Tag Required.')
        }
        let uniqueValidations = new Set(newValidations)
        let newListitem = { ...listItem, status: newStatus, isContextEmpty: isContextEmpty, validations: [...uniqueValidations], questionArr: element }
        return newListitem;
    }
    return listItem
}

const isEachListitemValid = (list) => {
    for (let i = 0; i < list.length; i++) {
        if (list[i].status === 'error') {
            console.log('element with error = ', list[i]);
            return false
        }
    }
    return true
}

const validateContextList = (designType, list) => {
    if (list.length >= gbaValidations[designType].minQuestionsRequired) {
        return true
    }
    return false

}

export default { validateContextList, validateListitem, isEachListitemValid };
// id: 0
// question: ""
// skillId:0
// validations: []
// answers: (4) [{…}, {…}, {…}, {…}]