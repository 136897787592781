import React, { useEffect, useState, useRef, useContext } from 'react'
import html2canvas from "html2canvas";

//mui
import { makeStyles } from "@mui/styles";

//components
// import EditableBody from '../editable-body';
import CustomButton from '../../../custom-styled/button';
import SuggestedOrUpload from '../suggested-or-upload';
import EditableBody from '../rtf-body';

//utils
import commonUtil from '../../../../utils/commonUtil';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';

const Body = ({
  customAspectRatio = true,
  handleSaveCard,
  isEdit,
  editCardData,
  handleCancel,
  handleUpdateCard,
  titleBody,
  setTitleBody,
  handleBodyChange,
  laBgImgData,
  handleChangeLaBgImg,
  handleBodyClick,
  toolbar,
  setToolbar
}) => {
  const styles = commonStyles();
  const [globalStates] = useContext(GlobalStates);
  let capture = useRef();
  let bodyRef = useRef(null);

  useEffect(() => {
    setToolbar({ title: false, body: false })
    if (isEdit && editCardData !== null) {
      let card = editCardData.card;
      let body = commonUtil.getBodyFromContents(card.contents);
      // bodyRef.current.innerHTML = commonUtil.removeStyleAndHrefTagsFromString(body);
      // console.log('body = ', body);
      setTitleBody({ title: '', body: body })
    } else {
      setTitleBody({ title: '', body: '' })
    }
  }, [editCardData])

  // useEffect(() => {
  //   // console.log('bodyRef.current.innerHTML = ', bodyRef?.current?.innerHTML);
  //   commonUtil.removeInlineStyles(bodyRef?.current);
  // }, [bodyRef?.current?.innerHTML])


  const captureScreenshot = async () => {
    if (!titleBody.body.length) {
      globalStates.handleToast(true, 'Please enter the Body text', 'error');
      return
    }

    if (laBgImgData?.bgImg) {
      var backgroundColorDiv = document.createElement("div");
      // Set the background color on the additional div
      backgroundColorDiv.style.backgroundColor = 'rgba(0,0,0,0.5)';
      backgroundColorDiv.style.position = 'absolute';
      backgroundColorDiv.style.top = '0';
      backgroundColorDiv.style.left = '0';
      backgroundColorDiv.style.width = '100%';
      backgroundColorDiv.style.height = '100%';
      backgroundColorDiv.style.zIndex = -1;

      // Append the additional div to the container
      capture.current.appendChild(backgroundColorDiv);
    }

    // setCustomAspectRatio(!customAspectRatio)
    const canvas = await html2canvas(capture.current);
    if (laBgImgData?.bgImg) capture.current.removeChild(backgroundColorDiv);

    //CONVERTING CANVAS TO BASE64
    const imgData = canvas.toDataURL("image/png");
    // console.log("canvas to base64 = ", imgData);
    const myFile = commonUtil.base64toFile(imgData)

    let cardData = {
      id: 0,
      cardType: "CREATED",
      ratio: "4:3",
      body: titleBody.body,
      thumbImageFile: myFile,
      thumbFileSize: myFile.size,
    }

    console.log('B cardData = ', cardData);
    const type = 'B'


    if (isEdit) {
      console.log('THIS IS EDIT editCardData = ', editCardData);
      cardData.cardId = editCardData.id;
      handleUpdateCard(type, cardData)
      return
    }

    handleSaveCard(type, cardData);
    return
  }

  let textColor = laBgImgData?.bgImg ? '#fff' : 'rgb(0, 0, 0, 0.5)';
  let placeholderColor = laBgImgData?.bgImg ? 'rgb(255, 255, 255, 0.7)' : 'rgb(0, 0, 0, 0.2)';


  return (
    <>
      <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}
        style={{
          backgroundImage: `url(${laBgImgData.bgImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: laBgImgData?.bgImg && 'multiply',
          backgroundColor: laBgImgData?.bgImg && 'rgb(0,0,0,0.5)',
        }}
      >
        <EditableBody
          ref={bodyRef}
          minHeight={'33rem'}
          maxHeight={'33rem'}
          handleBodyChange={handleBodyChange}
          value={titleBody.body}
          handleBodyClick={handleBodyClick}
          showBodyToolbar={toolbar.body}
          textColor={textColor}
          placeholderColor={placeholderColor}
        />

        <div className={styles.suggestionBox}>
          <SuggestedOrUpload
            title={'Background Image:'}
            handleChange={handleChangeLaBgImg}
          />
        </div>
      </div>

      {true && <div className={styles.btnBox}>
        <CustomButton
          btnText={'SAVE'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={captureScreenshot.bind(this)}
        // handleClick={() => setCustomAspectRatio(!customAspectRatio)}
        />
        {isEdit && <CustomButton
          btnText={'CANCEL'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={handleCancel}
        />}
      </div>}
    </>
  )
}

export default Body;

