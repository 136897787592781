import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    textEditorBox: {
        // border: '1px solid',
        height: '3.8rem',
        width: '100%'
    },
    main: {
        // border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center',
        flexDirection : 'column',
        position: 'relative',
        // height: 'calc(100% - (3.8rem + 3.8rem))',
        height: 'calc(100% - 3.8rem)',
        width: '100%'
    },
    defaultCard: {
        // border: '1px solid',
        aspectRatio: 0.79,
        borderRadius: '10px',
        overflow: 'hidden',
        '-webkit-box-shadow': '0px 2px 6px 0px rgba(0,0,0,0.2)',
        position: 'relative',
        height: '560px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > img': {
            height: '100%',
            width: '100%'
        }
    },

    // card: {
    //     // border: '1px solid',
    //     position: 'relative',
    //     height: '560px',
    //     backgroundColor: '#fff',
    //     borderRadius: '10px',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     padding: '1rem',

    // },
    // aspect1: {
    //     aspectRatio: 3 / 4,
    // },
    // aspect2: {
    //     aspectRatio: 9 / 16,
    // },
    // btnBox: {
    //     border: '1px solid',
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     height: '3.8rem',
    //     width: '100%',
    //     gap: '6rem',
    //     '& > button': {
    //         height: '42px',
    //         width: '150px',
    //         borderRadius: '1px',
    //         '&:hover': {
    //             borderColor: '#f4511e',
    //             backgroundColor: '#f4511e',
    //             color: '#fff'
    //         }
    //     }

    // },
}));

export default useStyles;