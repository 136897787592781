import React from 'react'

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { useEffect } from 'react';


const AutocompleteSearch = (props) => {
    const { data, handleFilter, handleChange, setDefaultOption, focus = false } = props;

    useEffect(() => {
        if (focus) {
            let inputField = document.getElementById("#input-field");
            console.log('inputField = ', inputField);
            if (inputField) inputField.focus();
        }

    }, [focus])


    return (
        <div style={{ backgroundColor: "#fff", width: "50%", borderRadius: "4px", border: "transparent", margin: "1rem" }}>
            <Autocomplete
                id="#input-field"
                options={data}
                filterOptions={handleFilter}
                getOptionLabel={(option) => { return option.name }}
                onInputChange={(e, value) => {
                    setDefaultOption({ name: value, type: "new" })
                }}
                getOptionDisabled={(option) =>
                    option.type === "disabled"
                }
                freeSolo
                onChange={handleChange}
                renderInput={(params) => <TextField {...params} placeholder="Start typing a category..." />}
                renderOption={(props, option) => (
                    <li {...props} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>{option.name}</div>
                        {option.type === 'new' ? <Button
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            style={{ background: "#F4511E", color: "#FFF" }}
                        >Click Here to Add</Button> : null}
                    </li>
                )} />
        </div>
    )
}

export default AutocompleteSearch