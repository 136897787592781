import React, { forwardRef } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import Editor from '../../../custom-styled/editor';


const EditableBody = forwardRef((props, ref) => {
    const {
        value,
        handleBodyChange,
        fs = '1rem',
        fw = 600,
        align = 'start',
        textColor = '#707070',
        minHeight = '3rem',
        maxHeight = '162px',
        placeholderColor = 'rgb(112, 112, 112, 0.4)',
        handleBodyClick = () => {},
        showBodyToolbar
    } = props;
    const classes = useStyles({
        placeholderColor,
        fs,
        fw,
        align,
        textColor,
        minHeight,
        maxHeight,
        showBodyToolbar
    });
    return (
        <div className={classes.wrapper} onClick={handleBodyClick}>
            <Editor
                showToolbar={showBodyToolbar}
                quillRef={ref}
                handleChange={handleBodyChange}
                placeholder={"Enter Body Text Here"}
                value={value}
            />
        </div>

    );
});


export default EditableBody;


const useStyles = makeStyles(() => ({
    wrapper: {
        width: '100%',
        '&:hover': {
            border: '1px solid #E3E3E3',
        },
        '& > div': {
            border: 'none !important',
            '& > div:first-child': {
                display: ({ showBodyToolbar }) => (showBodyToolbar?'inherit': 'none'),
                border: 'none !important',
                position: 'absolute',
                top: '-3rem',
                left: '-1rem',
                width: '118%'
            },
            '& > pre': {
                border: 'none !important',
                // position: 'absolute',
                width: '100%',
                '& > div:first-child': {
                    fontSize: '1rem',
                    minHeight: ({ minHeight }) => (minHeight),
                    maxHeight: ({ maxHeight }) => (maxHeight),
                    overflow: 'hidden',
                    padding: '0 !important',
                    '&::before': {
                        left: '0 !important',
                        fontSize: '1.2rem',
                        color: ({ placeholderColor }) => (placeholderColor)
                    },
                },
                '&:focus-within': {
                    // outline: '1px solid #4F0060',
                },
            },
        },
    },
    // body: {
    //     width: '100%',
    //     height: '100%',
    //     maxHeight: ({ maxHeight }) => (maxHeight),
    //     padding: '0.2rem',
    //     overflow: 'hidden',
    //     fontSize: ({ fs }) => (`${fs}!important`),
    //     fontWeight: ({ fw }) => (fw),
    //     textAlign: ({ align }) => (align),
    //     color: ({ textColor }) => (textColor),
    //     // minHeight: ({ minHeight }) => (minHeight),

    //     '&:empty:before': {
    //         content: 'attr(data-placeholder)',
    //         color: 'rgb(112, 112, 112, 0.3)',

    //     },
    //     '&:hover': {
    //         border: '1px solid #E3E3E3',
    //     },
    //     '& *': {
    //         fontSize: ({ fs }) => (`${fs}!important`),
    //         fontWeight: ({ fw }) => (`${fw}!important`),
    //         color: ({ color }) => (`${color}!important`),
    //     }
    // }

}));