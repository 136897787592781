import React, { useState } from 'react';
// import MenuItem from '@material-ui/core/MenuItem';
// import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
// import Input from '@material-ui/core/TextField';
import AutoSuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TopBarWrapper from '../../components/tms-components/TopBarWrapper';
import MOTopBar from '../../components/tms-components/MOTopBar';
import TopBarHeading from '../../components/tms-components/TopBarHeading';
import FormControl from '../../components/tms-components/FormControl';
import Label from '../../components/tms-components/Label';
import SimpleFormSelect from '../../components/tms-components/SimpleFormSelect';
import MODateTimePicker from '../tms-components/MODateTimePicker';
import CoursePathwayElement from '../../components/tms-components/CoursePathwayElement';
import FlatPrimaryButton from '../../components/tms-components/FlatPrimaryButton';
import FlatClearButton from '../../components/tms-components/FlatClearButton';
// import { Dialog } from '@material-ui/core';
import styled from 'styled-components';
import PlusIcon from '../../components/tms-components/MDLIcon/icons/Plus';
import ClearButton from '../../components/tms-components/ClearButton';
import dayjs from 'dayjs';
import EditCourseEvent from './EditCourseEvent';
import utc from 'dayjs/plugin/utc';
import Button2 from '../../components/tms-components/Button2';
import Loader from '../../components/tms-components/Loader';
import EditIcon from '../../components/tms-components/MDLIcon/icons/EditIcon';
import TextInput from '../../components/tms-components/TextInput';

//new
import Paper from '@mui/material/Paper';
import { makeStyles } from "@mui/styles";
import MenuItem from '@mui/material/MenuItem';
import { TextField as Input } from '@mui/material';
import Dialog from '@mui/material/Dialog';

//utils
import useQuery from '../../utils/useQuery';

//services
import trainingServices from '../../services/training';
import courseServices from '../../services/course';

dayjs.extend(utc);

const Actions = styled.div`
  display: flex;
  align-items: center;
`;
const FormWrapper = styled.div`
  padding: 30px;
  text-align: left;
`;

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

const AddLearnersWrapper = styled.div`
  padding: 20px;
`;

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const ErrorText = styled.span`
  font-size: 0.7rem;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #f63b34;
  margin-top: 5px;
`;

let courseElementBGConstants = ['#1a56bd', '#f63b34', '#56dab8', '#ffdd00'];

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    fontSize: '0.9rem',
  },
  divider: {
    height: theme.spacing(2),
  },
  input: {
    fontSize: '0.7rem',
    border: '0.5px solid #dde0e2',
    width: 302,
    paddingLeft: 18,
    color: '#707070',
    fontWeight: 600,
    fontFamily: 'Montserrat',
  },
}));

function EditTrainingSession(props) {
  let currentDate = dayjs(new Date());
  const classes = useStyles();
  const query = useQuery();
  const companyId = query.get('c_id');
  let training_session_id = props.match.params.id;
  console.log('EditTrainingSession training_session_id = ', training_session_id);
  //   if (isNaN(training_session_id)) return;
  const permissions = [];
  const roles = localStorage.getItem('roles');
  console.log('roles = ', roles);
  ;
  console.log('EditTrainingSession [props] = ', props);

  // if (props.userInfo.permissions.hasOwnProperty('features')) {
  //   for (const permission of props.userInfo.permissions.features) {
  //     permissions.push(permission.key);
  //   }
  // }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [trainingSessions, setTrainingSessions] = React.useState([]);
  const [editCourseEventDialogOpen, setEditCourseEventDialogOpen] = useState(
    false,
  );
  let [open, setOpen] = useState(false);
  let [startDate, setStartDate] = useState(dayjs(new Date()));
  let [endDateDialogOpen, setEndDateDialogOpen] = useState(false);
  let [endDate, setEndDate] = useState(dayjs(new Date()).add(2, 'd'));
  const [editEventIndex, setEditEventIndex] = useState(0);
  const [editEventName, setEditEventName] = useState('');
  let [newEventName, setNewEventName] = useState(null);
  let [newEventStartDate, setNewEventStartDate] = useState(dayjs(new Date()));
  let [newEventEndDate, setNewEventEndDate] = useState(dayjs(new Date()));
  let [
    newCourseEventStartDateDialogOpen,
    setNewCourseEventStartDateDialogOpen,
  ] = useState(false);
  let [
    newCourseEventEndDateDialogOpen,
    setNewCourseEventEndDateDialogOpen,
  ] = useState(false);
  let [newEventTime, setNewEventTime] = useState(dayjs(new Date()));
  let [newEventTimeOpen, setNewEventTimeOpen] = useState(false);
  const [trainingLocation, setTrainingLocation] = useState('');
  const [
    trainingLocationSuggestions,
    setTrainingLocationSuggestions,
  ] = useState([]);
  const [coursePathwayElements, setCoursePathwayElements] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [editSessionBatches, setEditSessionBatches] = useState([]);
  const [addLearnersDialog, setAddLearnersDialog] = useState(false);
  const [progressDialog, setProgressDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [failureDialog, setFailureDialog] = useState(false);
  const [errorText, setErrorText] = useState("Sorry. Any ongoing training session cannot be modified.");
  const [courseDurationError, setCourseDurationError] = React.useState(null);
  const [eventDurationError, setEventDurationError] = React.useState(null);
  const [locationId, setLocationId] = React.useState(null);
  const [locationInputChanged, setLocationInputChanged] = useState(false);
  let [training_session] = trainingSessions.filter((t) => t.id === training_session_id);
  console.log('ts is ', trainingSessions);
  console.log('filtered ts is ', training_session);
  let selectedCourse = 0;
  let selectedCourseText = "";
  let selectedCourseObject = {};
  const [trainingName, setTrainingName] = useState(null);
  const [trainingNameErrorText, setTrainingNameErrorText] = useState(null);
  const trainingNameRef = React.useRef(null);

  if (typeof training_session !== 'undefined') {
    console.log('Got a hit');
    selectedCourse = training_session.id;
    selectedCourseText = training_session.name;
    // [selectedCourseObject] = props.courses.filter((c) => c.id === selectedCourse);
    // if (typeof selectedCourseObject !== "undefined") selectedCourseText = selectedCourseObject.name;
  }

  React.useEffect(() => {
    if (trainingName === '') {
      setTrainingNameErrorText('Training Name is required');
      trainingNameRef.current.focus();
    } else {
      setTrainingNameErrorText('');
    }
  }, [trainingName]);


  React.useEffect(() => {
    fetch_training_sessions(companyId, { id: training_session_id });
  }, [])

  React.useEffect(() => {
    if (locationId) {
      fetchSelectedLocation(companyId, null, locationId);
    }
  }, [locationId])


  const fetchSelectedLocation = async (companyId, search = null, id = null) => {
    const res = await trainingServices.fetchTrainingLocations(companyId, search, id);
    console.log('fetchSelectedLocations res = ', res);
    if (res.status === 200 || res.status === 201) {
      let result = res.data.data;
      console.log('location result = ', result);
      if (result.length) {
        setTrainingLocation(`[${result[0].code}] ${result[0].name}`);
      }
    }
  }

  React.useEffect(() => {
    console.log(`changed to ${props.match.params.id}`);
    // here is where you fetch the training session data and modify the local state

    if (typeof training_session !== 'undefined') {
      // setTrainingLocation(training_session.location);
      setStartDate(dayjs(training_session.startsOn));
      setEndDate(dayjs(training_session.endsOn));
      if (selectedCourse !== null) {
        // let course = props.courses.filter((c) => c.id === selectedCourse);

        // let text = course[0].name;

        let el = training_session.TrainingEvent.filter((e) => {
          return e.trainingId === selectedCourse;
        });

        console.log('el = ', el);

        let mapped_elements = el.map((e, index) => {
          // let event_length = training_session.training_events[index].event_days.length;
          return {
            start_date: dayjs(e.startsOn),
            end_date: dayjs(e.endsOn),
            event_time: dayjs(e.timings.eventTime),
            ...e,
          };
        });
        mapped_elements.sort((a, b) => {
          if (a.start_date > b.start_date) {
            return 1
          } else {
            return -1
          }
        })
        // let mapped_elements = el.map((e, index) => {
        //   let event_length = training_session.training_events[index].event_days.length;
        //   return {
        //     start_date: dayjs(training_session.training_events[index].event_days[0].event_date),
        //     end_date: dayjs(training_session.training_events[index].event_days[event_length - 1].event_date),
        //     event_time: dayjs(training_session.training_events[index].event_days[0].event_time),
        //     ...e,
        //   };
        // });

        setCoursePathwayElements(mapped_elements);
        // setSelectedBatches(training_session.training_batches);
        // setEditSessionBatches(training_session.training_batches);
        let trainingBatches = training_session.TrainingBatches[0]
        console.log('trainingBatches = ', trainingBatches);
        setSelectedBatches([trainingBatches.Batch.id]);
        setEditSessionBatches([trainingBatches.Batch.id]);

        // set the default date to be more relevant
        //setEndDate(dayjs(new Date()).add(mapped_elements.length - 1, 'day'));

      }

    }

  }, [props.match.params.id, trainingSessions, props.courses, props.batches])

  React.useEffect(() => {
    validate_course_duration();
  }, [startDate, endDate]);

  React.useEffect(() => {
    validate_course_event_duration();
  }, [newEventStartDate, newEventEndDate]);



  async function fetch_training_sessions(companyId, options = {}) {
    const res = await trainingServices.fetchTrainings(companyId, options);
    console.log('fetch_training_sessions response = ', res);
    if (res.status === 200 || res.status === 201) {
      let result = res.data.data;
      console.log('fetch_training_sessions result = ', result);
      // let [training_session] = res.data.data.filter((t) => t.id === training_session_id);
      if (result.length) {
        let name = "";
        if (result[0].name) {
          name = result[0].name;
        } else if (!result[0].name && result[0]?.Course?.name) {
          name = result[0].Course.name
        }
        setLocationId(result[0].locationId);
        setTrainingSessions(result);
        setTrainingName(name);
      }
    }

    //   const options = {
    //     token: props.jwtToken,
    //   };

    //   if (permissions.indexOf('view_all_training_sessions') > -1) {
    //     options.get_all = true;
    //   }

    //   const fetch_training_sessions = props.MOClient.get_training_sessions(
    //     options,
    //   );

    //   fetch_training_sessions.then((outcome) => {
    //     if (outcome.status === 'success') {
    //       setTrainingSessions(outcome.data.data);
    //     }
    //   });
  }



  // React.useEffect(()=> {
  //   fetch_training_location_suggestions();

  // }, [trainingLocation]);

  let courseOptions = props.courses.map((course) => {
    return {
      id: course.id,
      display_text: course.name,
    };
  });

  let batchOptions = props.batches.map((batch) => {
    let o = {
      id: batch.id,
      display_text: batch.code,
    };

    if (batch.name !== null) {
      o.display_text += ` ${batch.name}`;
    }

    return o;
  });
  console.log('selectedBatches = ', selectedBatches);
  console.log('props.batches = ', props.batches);

  let selectedBatchText = '';

  let selectedBatchObj = props.batches.filter(
    (o) => o.id === selectedBatches[0]
  );
  console.log('selectedBatchObj = ', selectedBatchObj);

  if (selectedBatchObj.length > 0) {
    selectedBatchText = selectedBatchObj[0].code;

    if (selectedBatchObj[0].name !== null) {
      selectedBatchText += ' ' + selectedBatchObj[0].name;
    }
  }

  let editBatchText = '';

  let editBatchObj = props.batches.filter(
    (o) => o.id === editSessionBatches[0],
  );

  if (editBatchObj.length > 0) {
    editBatchText = editBatchObj[0].code;

    if (editBatchObj[0].name !== null) {
      editBatchText += ' ' + editBatchObj[0].name;
    }
  }

  function validate_course_duration() {
    if (startDate.isAfter(endDate, 'day')) {
      setCourseDurationError('The Start date should be before end date!');
      return false;
    } else {
      setCourseDurationError(null);
    }
    return true;
  }

  function renderInputComponent(inputProps) {
    const { classes, inputRef = () => { }, ref, ...other } = inputProps;

    return (
      <div>
        <FormControl>
          <Label>Training Location: <span style={{ color: 'red' }}>*</span></Label>
          <Input
            disableUnderline={true}
            InputProps={{
              disableUnderline: true,
              inputRef: (node) => {
                ref(node);
                inputRef(node);
              },
              classes: {
                input: classes.input,
                root: classes.input_root,
              },
            }}
            {...other}
          />
        </FormControl>
      </div>
    );
  }

  function renderSuggestion(suggestion, { query, isHighlighted }) {
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          <span
            style={{
              fontSize: '0.85rem',
            }}>{`[${suggestion.code}] ${suggestion.name}`}</span>
        </div>
      </MenuItem>
    );
  }

  function getSuggestionValue(suggestion) {
    return `[${suggestion.code}] ${suggestion.name}`;
  }

  // function fetch_training_location_suggestions(obj) {
  //   if (typeof obj === 'undefined') return;

  //   if (typeof obj.value === 'undefined') return;

  //   //debugger;
  //   if (obj.value === '') return;

  //   if (obj.value.length < 4) return;

  //   const terms = props.MOClient.search_training_locations(
  //     props.jwtToken,
  //     obj.value,
  //   );

  //   terms.then((search_results) => {
  //     if (search_results && search_results.length > 0) { // handle the crash when there is no data
  //       setTrainingLocationSuggestions(search_results);
  //     }
  //   });
  // }

  const fetchTrainingLocations = async (companyId, searchParam) => {
    const res = await trainingServices.fetchTrainingLocations(companyId, searchParam);
    console.log('fetchTrainingLocations res = ', res);
    if (res.status === 200 || res.status === 201) {
      let search_results = res.data.data;
      setTrainingLocationSuggestions(search_results);
    }
  }

  function fetch_training_location_suggestions(obj) {
    console.log('fetch training locations obj = ', obj);
    console.log('fetch training locationInputChanged = ', locationInputChanged);

    if (typeof obj === 'undefined') return;

    if (typeof obj.value === 'undefined') return;

    //debugger;
    if (obj.value === '') return;

    if (obj.value.length < 2) return;

    if (locationInputChanged) return;
    setLocationInputChanged(true);

    setTimeout(() => {
      fetchTrainingLocations(companyId, obj.value);
      setLocationInputChanged(false);
    }, 1000);
    // console.log('timer = ', timer);

    // fetchTrainingLocations(companyId, obj.value);

    //OLD API CALL
    // const terms = props.MOClient.search_training_locations(
    //   props.jwtToken,
    //   obj.value,
    // );

    // terms.then((search_results) => {
    //   if (search_results && search_results.length > 0) { // handle the crash when there is no data
    //     setTrainingLocationSuggestions(search_results);
    //   }
    // });
  }

  function validate_course_event_duration() {

    if (newEventStartDate.isAfter(newEventEndDate, 'day')) {
      setEventDurationError('The Start date should be before end date!');
      return false;
    } else if (newEventStartDate.isAfter(endDate, 'day')) {
      setEventDurationError('Event dates must be within training dates!');
      return false;
    } else if (newEventEndDate.isAfter(endDate, 'day')) {
      setEventDurationError('Event dates must be within training dates!');
      return false;
    } else if (newEventStartDate.isBefore(startDate, 'day')) {
      setEventDurationError('Event dates must be within training dates!');
      return false;
    }
    else if (newEventEndDate.isBefore(startDate, 'day')) {
      setEventDurationError('Event dates must be within training dates!');
      return false;
    } else {
      setEventDurationError(null);
    }
    return true;
  }

  function validate_course_selected() {
    if (selectedCourse === null) {
      setErrorText('Please select a course');
      setFailureDialog(true);
      return false;
    }

    return true;
  }

  function validate_training_location() {
    if (trainingLocation === '') {
      setErrorText('Please select the Training Location');
      setFailureDialog(true);
      return false;
    }

    return true;
  }

  function validate_training_batches() {
    if (selectedBatches.length === 0) {
      setErrorText('Please add learners to this training');
      setFailureDialog(true);
      return false;
    }

    return true;
  }

  function validate_training_name() {
    if (trainingName === '' || trainingName === null) {
      setTrainingNameErrorText('Training Name is required');
      trainingNameRef.current.focus();
      return false;
    }

    return true;
  }

  function run_validation() {
    return (
      // validate_training_name() &&
      validate_course_selected() &&
      validate_training_location() &&
      validate_course_duration() &&
      validate_training_batches()
    );
  }

  async function submit() {
    console.log('training_session= ', training_session);
    console.log('onsubmit data = ', training_session_data);
    if (training_session.locked) {
      setFailureDialog(true);
      return
    }
    let valid = run_validation();
    if (!valid) return;

    setProgressDialog(true);

    const events = [];
    training_session_data.training_events.forEach(element => {
      let event = {
        id: element.event_id,
        startsOn: element.event_days[0].event_date,
        endsOn: element.event_days.at(-1).event_date,
        // endsOn: element.event_days.length > 1 ? element.event_days[1].event_date : element.event_days[0].event_date,
        timings: {
          eventTime: element.event_days[0].event_time
        }
      }
      events.push(event);
    })
    let payload = {
      id: training_session.id,
      companyId: companyId,
      name: trainingName,
      description: " Productive Team",
      startsOn: `${training_session_data.start_date}T00:01:00.467Z`,
      endsOn: `${training_session_data.end_date}T00:01:00.467Z`,
      courseId: training_session.courseId,
      locationId: null,
      batchIds: training_session_data.training_batches,
      events: events
    };

    if (trainingName === '' || trainingName === null) {
      delete payload.name;
    }
    // console.log('payload = ', payload);
    // return


    //CHECKING IF TRAINING LOCATION NEEDS TO BE CREATED OR NOT
    let currLocation = training_session_data.training_location;
    if (training_session_data.training_location[0] === '[') {
      //here location name includes code also
      currLocation = training_session_data.training_location.split(" ").slice(1).join(" ");
    }
    const res = await trainingServices.fetchTrainingLocations(companyId, currLocation);
    if (res.status === 200 || res.status === 201) {
      let searchResults = res.data.data;
      if (searchResults.length) {
        let filtered = searchResults.filter(ele => ele.name.toLowerCase() === currLocation.toLowerCase());
        //  console.log('filtered = ', filtered);
        if (filtered.length) {
          //location already exists
          payload.locationId = filtered[0].id;
        }

      } else {
        //HERE WE HAVE TO CREATE NEW LOCATION
        const createLocationPayload = {
          companyId: companyId,
          code: "100110",
          name: trainingLocation,
          description: "Sample Description"
        }
        const response = await trainingServices.createTrainingLocation(createLocationPayload);
        // console.log('createTrainingLocation response = ', response);
        if (response.status === 200 || response.status === 201) {
          payload.locationId = response.data.data.id;
        } else {
          // console.log('createTrainingLocation failed');
          return
        }
      }
    }
    console.log('payload = ', payload);
    // return
    // if(training_session_data.training_location)
    // console.log('Schedule training payload = ', payload);

    // return

    //cc
    const updateTrainingResponse = await trainingServices.updateTraining(payload);
    console.log('updateTrainingResponse = ', updateTrainingResponse);
    if (updateTrainingResponse.status === 200 || updateTrainingResponse.status === 201) {
      setProgressDialog(false);
      setSuccessDialog(true);
    } else if (updateTrainingResponse.status === 400) {
      //here training creation api failed
      setProgressDialog(false);
      if (updateTrainingResponse?.data?.errors[0]?.message) {
        setErrorText(updateTrainingResponse.data.errors[0].message);
        setFailureDialog(true);
      }

    } else {
      setProgressDialog(false);
      setErrorText('Internal Server Error.');
      setFailureDialog(true);

    }

    // let action = props.MOClient.edit_training_session({
    //   token: props.jwtToken,
    //   training_session_id: training_session_id,
    //   data: training_session_data,
    // });

    // action.then((outcome) => {
    //   setProgressDialog(false);
    //   if (outcome.status !== 'success') {
    //     setFailureDialog(true);
    //   } else {
    //     setSuccessDialog(true);
    //   }
    // });
  }

  React.useEffect(() => {
  }, [props.match.params.id]);

  function onEdit() {
    if (!validate_course_event_duration()) return;
    // save and set the course pathway elements
    let copy = [...coursePathwayElements];
    copy[editEventIndex].start_date = newEventStartDate;
    copy[editEventIndex].end_date = newEventEndDate;
    copy[editEventIndex].event_time = newEventTime;

    setCoursePathwayElements(copy);

    setEditCourseEventDialogOpen(false);
  }

  let training_events = coursePathwayElements.map((training_event) => {
    console.log('coursePathwayElements event = ', training_event);
    let o = {};
    o.event_id = training_event.eventId;
    o.event_days = [];
    let no_of_days = training_event.end_date.diff(training_event.start_date, 'day') + 1;

    for (let i = 0; i < no_of_days; i++) {
      o.event_days.push({
        event_date: dayjs(training_event.start_date)
          .add(i, 'day')
          .format('YYYY-MM-DD'),
        event_time: training_event.event_time.utc().format(),
      });
    }

    return o;
  });

  let training_session_data = {
    start_date: startDate.format('YYYY-MM-DD'),
    end_date: endDate.format('YYYY-MM-DD'),
    training_events: training_events,
    training_batches: selectedBatches,
    training_location: trainingLocation,
  };

  // process permissions and determine the min_date for training course


  let min_date = currentDate;

  if (roles.includes('BACKDATE_TRAINING')) {
    min_date = false;
  }


  return (
    <div style={{ backgroundColor: '#fff' }}>
      <TopBarWrapper>
        <MOTopBar>
          <TopBarHeading>Edit Training Session</TopBarHeading>
        </MOTopBar>
        <div>
          <FormWrapper>
            <FormControl>
              {/* <Label>
                Training Name:
              </Label>
              <TextInput value={selectedCourseText} disabled /> */}
              <Label>
                Training Name
                {/* <span style={{ color: 'red' }}>*</span> */}
              </Label>
              <TextInput
                ref={trainingNameRef}
                value={trainingName}
                onChangeText={(text) => {
                  setTrainingName(text);
                }}
                errorText={trainingNameErrorText}
              />
            </FormControl>

            <AutoSuggest
              renderInputComponent={renderInputComponent}
              suggestions={trainingLocationSuggestions.slice(0, 7)}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onSuggestionsFetchRequested={fetch_training_location_suggestions}
              onSuggestionsClearRequested={fetch_training_location_suggestions}
              inputProps={{
                classes,
                id: 'react-autosuggest-simple',
                placeholder: '',
                value: trainingLocation,
                onChange: (e, { newValue }) => {
                  setTrainingLocation(newValue);
                },
              }}
              theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
              }}
              renderSuggestionsContainer={(options) => (
                <Paper {...options.containerProps} square>
                  {options.children}
                </Paper>
              )}
            />

            <FormControl>
              <Label>Training Duration:</Label>
              <DurationWrapper>
                <MODateTimePicker
                  open={open}
                  text={startDate ? startDate.format('DD-MM-YYYY') : 'DD-MM-YYYY'}
                  value={startDate ? startDate : currentDate}
                  minDate={min_date}
                  onClick={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  handleDateChange={(newDate) => setStartDate(newDate)}
                />

                <CourseFromToLabel>TO</CourseFromToLabel>

                <MODateTimePicker
                  open={endDateDialogOpen}
                  text={endDate ? endDate.format('DD-MM-YYYY') : 'DD-MM-YYYY'}
                  value={endDate ? endDate : currentDate}
                  minDate={min_date}
                  onClick={() => setEndDateDialogOpen(true)}
                  onClose={() => setEndDateDialogOpen(false)}
                  handleDateChange={(newDate) => setEndDate(newDate)}
                />
              </DurationWrapper>
              <ErrorText>{courseDurationError}</ErrorText>
            </FormControl>

            <FormControl>
              <Label>Training Events:</Label>

              {coursePathwayElements.map((e, k) => {
                return (
                  <CoursePathwayElement

                    onEdit={() => {
                      setEditEventIndex(k);
                      setEditEventName(e.CourseEvent.name);
                      setNewEventStartDate(e.start_date);
                      setNewEventEndDate(e.end_date);
                      setNewEventTime(e.event_time);
                      setNewEventName(e.event_time);
                      setEditCourseEventDialogOpen(true);
                    }}
                    disableClone={true}
                    disableDelete={true}
                    key={k}
                    backgroundColor={courseElementBGConstants[k % 4]}
                    text={e.CourseEvent.name}
                    date={(() => {
                      let d = e.start_date.format('DD-MM-YYYY');
                      if (e.end_date.diff(e.start_date, 'day') >= 1) {
                        d += ' to ' + e.end_date.format('DD-MM-YYYY');
                      }

                      return d;
                    })()}
                  />
                );
              })}
            </FormControl>

            <FormControl>
              <Label>
                Training Learners: <span style={{ color: 'red' }}>*</span>
              </Label>

              <p style={{ margin: 0, fontSize: '0.9rem', color: '#707070' }}>
                {selectedBatchText}
              </p>

              {selectedBatches.length > 0 ? (
                <StyledGenericButton
                  onClick={() => {
                    setAddLearnersDialog(true);
                  }}>
                  <div style={{ marginRight: 5, display: 'flex' }}>
                    <EditIcon fill="#f4511e" />
                  </div>
                  Edit Learners
                </StyledGenericButton>
              ) : (
                <StyledGenericButton
                  onClick={() => {
                    setAddLearnersDialog(true);
                  }}>
                  <div style={{ marginRight: 5, display: 'flex' }}>
                    <StyledPlusIcon />
                  </div>
                  Add Learners
                </StyledGenericButton>
              )}
            </FormControl>

            <FormControl>
              <FlatPrimaryButton
                onClick={submit}>
                Update Training Session
              </FlatPrimaryButton>

            </FormControl>
          </FormWrapper>
        </div>

        {/* Edit Course Event Dialog */}
        <div id="create-course-event-dialog">
          <Dialog open={editCourseEventDialogOpen}>
            <EditCourseEvent
              minDate={startDate}
              maxDate={endDate}
              eventName={editEventName}
              startDateOpen={newCourseEventStartDateDialogOpen}
              startDate={newEventStartDate}
              endDate={newEventEndDate}
              endDateOpen={newCourseEventEndDateDialogOpen}
              onStartDateClick={() => {
                setNewCourseEventStartDateDialogOpen(true);
              }}
              durationErrorText={eventDurationError}
              onStartDateChange={(d) => {
                setNewEventStartDate(d);
              }}
              onStartDateClose={() => {
                setNewCourseEventStartDateDialogOpen(false);
              }}
              onEndDateClick={() => {
                setNewCourseEventEndDateDialogOpen(true);
              }}
              onEndDateChange={(d) => {
                setNewEventEndDate(d);
              }}
              onEndDateClose={() => {
                setNewCourseEventEndDateDialogOpen(false);
              }}
              onChangeEventName={(text) => {
                setNewEventName(text);
              }}
              timeOpen={newEventTimeOpen}
              eventTime={newEventTime}
              onTimeChange={(d) => {
                setNewEventTime(d);
              }}
              onTimeClick={() => {
                setNewEventTimeOpen(true);
              }}
              onTimeClose={() => {
                setNewEventTimeOpen(false);
              }}
              onAddEvent={() => {
                onEdit();
              }}
              onClose={() => {
                setEditCourseEventDialogOpen(false);
              }}
            />
          </Dialog>
        </div>

        {/* Add Learners Dialog */}
        <div>
          <Dialog
            open={addLearnersDialog}
            onClose={() => setAddLearnersDialog(false)}>
            <AddLearnersWrapper>
              <FormControl>
                <Label>Select Learner Batches:</Label>
                <SimpleFormSelect
                  autoClose={true}
                  options={batchOptions}
                  text={editBatchText}
                  onSelect={(id) => {
                    setEditSessionBatches([id]);
                  }}
                />
              </FormControl>

              <Button2
                onClick={() => {
                  setSelectedBatches(editSessionBatches);
                  setAddLearnersDialog(false);
                }}>
                Save and Close
              </Button2>
            </AddLearnersWrapper>
          </Dialog>
        </div>

        {/* Progress Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={progressDialog}
          onClose={() => {
            setProgressDialog(false);
          }}>
          <ProgressContainer>
            <Loader fill="#f4511e" />

            <ProgressText>Please wait...</ProgressText>
          </ProgressContainer>
        </Dialog>

        {/* Success Dialog */}
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={successDialog}
          onClose={() => {
            setSuccessDialog(false);
          }}>
          <ProgressContainer>
            <img
              src={require('../../assets/tick.png')}
              style={{ width: '50px' }}
            />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>
                Training Session updated successfully!
              </ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  props.history.push({
                    pathname: `/drive/training/training-log?c_id=${companyId}`,
                    state: {},
                  });
                }}>
                Go to Training Log
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>

        {/* Failure Dialog */}
        <Dialog
          open={failureDialog}
          onClose={() => {
            setFailureDialog(false);
          }}>
          <ProgressContainer>
            <img
              src={require('../../assets/cross.png')}
              style={{ width: '50px' }}
            />
            <div style={{ margin: '12px 0' }}>
              <ProgressText>{errorText}</ProgressText>
            </div>
            <div style={{ margin: '12px 0' }}>
              <Button2
                onClick={() => {
                  setFailureDialog(false);
                }}>
                Close
              </Button2>
            </div>
          </ProgressContainer>
        </Dialog>
      </TopBarWrapper>
    </div>
  );
}

export default EditTrainingSession;
