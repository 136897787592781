import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    Heading: {
        color: "#484848",
        fontFamily: "Montserrat",
        marginTop: "1rem",
        fontWeight: "bold"
    },
    AnswersContainer: {
        display: 'flex',
        rowGap: '1rem',
        flexDirection: "column",
        width: "100%",
        marginTop: "0.8rem"
    },
    Answers: {
        display: "flex",
        columnGap: "1rem",
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    AnswerInput: {
        width: "75%"
    },
    AddButtonsContainer: {
        display: "flex",
        columnGap: "2rem"
    },
    AddButtons: {
        color: "#F4511E",
        display: 'flex',
        alignItems: "center",
        border: "1px solid #F4511E",
        padding: "0.3rem 2rem",
        borderRadius: "2rem",
        textTransform: "none"
    },
    deleteAnswerIcon: {
        cursor: 'pointer',
        marginRight: '1rem'
    }
}))

export default useStyles;