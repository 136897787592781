import { authFetch } from "../utils/fetch";

const fetchCategories = async (companyId) => {
    console.log(companyId);
    try {
        const resData = await authFetch.get(`/category?startIndex=0&limit=1000&sort=createdOn&sortDirection=asc&companyIds=${companyId}`)
        // console.log("fetchCategories:", resData);
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const createCategories = async (companyId, name, type = "Major") => {
    console.log(companyId);
    try {
        const data = {
            "companyId": companyId,
            "name": name,
            "type": type
        }
        const resData = await authFetch.post(`/category`, data)
        // console.log("fetchCategories:", resData);
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const deleteCategory = async (data) => {
    try {
        const resData = await authFetch.delete(`/category`, { data })
        // // console.log("deleteChallenge:", resData);
        return resData
    } catch (err) {
        // console.log('deleteChallenge err:', err.response);
        return err.response
    }
}

const updateCategorySequence = async (payload) => {
    try {
        const resData = await authFetch.post(`/category/update-category-sequence`, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const updateCategory = async (payload) => {
    try {
        const resData = await authFetch.put(`/category`, payload)
        return resData
    } catch (err) {
        return err.response
    }
}


export default {
    fetchCategories,
    createCategories,
    deleteCategory,
    updateCategorySequence,
    updateCategory
}