import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(()=>({
   OverCropImg : {
         height : "100%",
         width : "100%",
         minWidth: "100%",
         minHeight: "100%"
   },
   ReactCropImg : {
      height : "100%",
      width : "100%",
      "& .ReactCrop" : {
         width : "100%",
      },
      '& .ReactCrop__image': {
         height : "23rem",
         width : "100%"
     },
     '& .ReactCrop__crop-selection' : {
         backgroundColor : "rgba(255, 88, 88, 0.3)",
         // backgroundColor : "#FF5858",
         // opacity : "0.1",
         border: "2px dashed rgba(255, 255, 255, 0.3)",
         '& .ReactCrop__selection-addon' : {
            height : "100%",
            width : "100%",
            // display : "none"
         },
         // '& .ReactCrop__drag-elements' : {
         //    "& .ReactCrop__drag-bar" : {
         //       "& .ord-n" : {
         //          display : "none"
         //       }
               
         //    }
         // }
         
         // "& .ReactCrop__drag-elements" : {
         //    "& .ord-n" : {
         //       display : "none"
         //    }
         // }
        
     },
     '& i': {
      color: 'rgba(255, 255, 255, 0.6)',
      fontSize: '1.5rem',
      border: '1px dashed rgba(255, 255, 255, 0.6)',
      borderRadius: '0.2rem',
      padding: '0.4rem',
      cursor: 'pointer',
      height : "fit-content"
      
     }
   },
   CroppedImg : {
      height : "40vh",
      width : "30vw"
   },
   CropRow1 : {
      // border: '1px dashed rgba(255, 255, 255, 0.6)',
      height : "55%",
      width : '100%',
      display: 'flex',
      alignItems : "flex-end",
      justifyContent : "center",

   },
   CropRow2 : {
      // border: '1px dashed rgba(255, 255, 255, 0.6)',
      height : "45%",
      width : '100%',
      display : "flex",
   },
   CropRow2_Column1 : {
      // border: '1px dashed rgba(255, 255, 255, 0.6)',
      width : "70%",
      height : "100%"
   },

   MicroskillName : {
      color : "#FFFFFF",
      fontFamily: "Montserrat",
      fontSize : "1.5rem",
      fontWeight: "600",
      // display: 'inline-block',
      paddingLeft : "10%",
      paddingTop : "10%",
      letterSpacing : "2px"
   },
   CropRow2_Column2 : {
      // border: '1px dashed rgba(255, 255, 255, 0.6)',
      width : "30%",
      height : "100%"
   },
   ViewsLikes : {
      paddingTop : "10%",
      paddingLeft : "10%",
      fontFamily: "Open Sans",
      fontWeight: "Bold",
      color : "#FFFFFF",
      fontSize : "0.7rem",
   },
   ViewsLikesSpan : {
      paddingRight : "1.2rem"
   },
   ViewsLikesIcons : {
      paddingRight : "0.2rem"
   },
   BrandIcon : {
      border: "dotted 2px #fff",
      borderRadius : "6px",
      marginLeft : "20%",
      marginTop: "25%",
      height: "3.5em",
      width: "3.5em"
   },
   IconPreviewText:{
      fontFamily: "Open Sans",
      fontWeight: "Bold",
      color : "#FFFFFF",
      fontSize : "0.5em",
      marginTop : "5%",
      marginLeft : "20%"  
  },

    
}))

export default useStyles

// color: #ffffff;
//     font-size: 1.5rem;
//     border: 1px dashed;
//     border-radius: 0.2rem;
//     padding: 0.4rem;
//     cursor: pointer;
