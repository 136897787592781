import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import ButtonBase from '@mui/material/ButtonBase';
import Menu from '@mui/material/Menu';

//components
import SearchBox from '../../../search-box';
import Loader from '../../../custom-styled/loader';

//utils
import commonUtil from '../../../../utils/commonUtil';

const RepositoryGroups = ({
    loading = false,
    editData,
    groups,
    selectedGroups,
    setSelectedGroups,
    chips,
    setRepositoryGroups,
    handleSelectGroup,
    handleRemoveGroup,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    if (loading) {
        return <Loader/>
    } else {
        return (
            <div className={classes.wrapper}>
                <>
                    <ButtonBase
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        className={`${classes.chip}`}
                        onClick={handleOpen}>
                        <h6> Add Group </h6>
                        <IconButton
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: '0.7rem',
                                color: '#ffffff',
                                padding: 0,
                                '& .MuiSvgIcon-root': {
                                    fontSize: '1rem'
                                }
                            }}
                        >
                            <AddIcon />
                        </IconButton>

                    </ButtonBase>
                    {open && <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        className={classes.menu}
                        sx={{
                            top: -32,
                            zIndex: 9999
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        <SearchBox
                            listData={groups}
                            selected={selectedGroups}
                            setSelected={setSelectedGroups}
                            handleSelect={handleSelectGroup}
                        />
                    </Menu>
                    }
                </>

                {
                    chips.map((ele, i) => (
                        <span className={classes.chip2} key={i}>
                            <h6>
                                {ele.name}
                            </h6>
                            <IconButton
                                aria-label="close"
                                onClick={() => handleRemoveGroup(ele)}
                                sx={{
                                    position: 'absolute',
                                    right: '0.7rem',
                                    // top: '0.2rem',
                                    color: '#f4511e',
                                    padding: 0,
                                    '& .MuiSvgIcon-root': {
                                        fontSize: '1rem'
                                    }
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </span>
                    ))
                }
            </div>
        )
    }


}

export default RepositoryGroups


const useStyles = makeStyles(() => ({
    wrapper: {
        position: 'relative',
        display: 'flex',
        gap: '0.9rem',
        flexWrap: 'wrap',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: 6, // Width of the scrollbar
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#f4511e', // Color of the scrollbar thumb
            borderRadius: '4px', // Rounded corners of the thumb
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#fff', // Color of the scrollbar track
            borderRadius: '4px', // Rounded corners of the track
        },
        // flexDirection: 'column'
    },
    chip: {
        position: 'relative',
        border: '1px solid #f4511e',
        minWidth: 'fit-content',
        // width: 'fit-content',
        // height: '2.6rem',
        padding: '0.5rem 1rem',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f4511e',
        cursor: 'pointer',
        '& > h6': {
            marginRight: '1.2rem !important',
            color: '#ffffff',
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: 700,

        }
    },
    chip2: {
        position: 'relative',
        border: '1px solid #f4511e',
        minWidth: 'fit-content',
        // width: 'fit-content',
        // height: '2.6rem',
        padding: '0.5rem 1rem',
        borderRadius: '2rem',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#fff',
        cursor: 'pointer',
        '& > h6': {
            marginRight: '1.2rem !important',
            color: '#707070',
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: 700,

        },
        '&:hover': {
            backgroundColor: 'rgb(235, 235, 242, 0.3)'
        }
    },
    menu: {
        '& .MuiList-root': {
            padding: 0
        }
    }
}));
