import React from "react";

// mui
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from "@mui/styles";

// theme
// import palette from "../../theme/palette";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 400,
    width: '90%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    border: 'none',
    p: 3,
    borderRadius: '5px',
    '& .MuiTypography-root':{
        fontFamily : 'montserrat'
    }
}

const DialogAlert = (props) => {
    const {
        isOpen = false,
        title = 'Title',
        content = 'Content',
        setIsDialogOpen
    } = props;
    const classes = useStyles();
    const handleClose = () => {
        setIsDialogOpen(false);
    }
    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
        >
            <Box sx={style}>
                <Typography variant="body1" component="div"> {title} </Typography>
                <Typography variant="body2" component="div"> {content} </Typography>
                <div className={classes.alertBtn}>
                    <Button onClick={handleClose} autoFocus>
                        <Typography variant="body2" component="div" sx={{ color: '#4F0060' }}> {'Okay'} </Typography>
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default DialogAlert;

const useStyles = makeStyles((theme) => ({
    alertBtn: {
        marginTop: '1.5rem',
        textAlign: 'right'
    },
}));