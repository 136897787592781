import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
const func = () => { }
const SuggestedOrUpload = ({ title = 'SUGGESTED IMAGES:', handleChange, suggestedImages = [], handleClick = func }) => {
  const classes = useStyles();
  return (
    <div className={classes.mainSuggestOrUpload}>
      <h3>{title}</h3>
      <div className={classes.cardListBox}>
        <Button variant="contained" component="label" className={classes.uploadCard} onChange={handleChange}>
          <img src='/images/learning-aid-assets/add.svg' alt='' />
          <h6>UPLOAD</h6>
          <input hidden accept="image/*" type="file" />
        </Button>
        {
          suggestedImages.map((ele, i) => (
            <div className={classes.suggestedImageCard} onClick={() => handleClick(ele.url)}>
              <img src={ele.url} alt='' />
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default SuggestedOrUpload


const useStyles = makeStyles(() => ({
  mainSuggestOrUpload: {
    overflow: 'hidden',
    backgroundColor: '#fff',
    height: '100%',
    width: '100%',
    // borderTopRightRadius: '8px',
    // borderBottomRightRadius: '8px',
    '& h3': {
      // border : '1px solid',
      color: '#0D034B',
      fontFamily: "Montserrat",
      fontSize: "0.66rem",
      fontWeight: "800",
      letterSpacing: '1px'
    }
  },
  cardListBox: {
    // border: '1px solid',
    margin: '0.6rem 0',
    width: '100%',
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    overflowX: 'hidden',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  uploadCard: {
    border: 'none',
    border: '1px solid #ccc',
    // boxShadow: '4px 4px 33px 1px rgba(0,0,0,0.58)',
    height: '5rem',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    backgroundColor: '#fff',
    '& img': {
      height: '2rem',
      width: '2rem',
      filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)'
    },
    '& h6': {
      color: '#f4511e',
      fontSize: '0.6rem',
      fontWeight: 800
    },
    '&:hover': {
      backgroundColor: '#fff',
    }
  },
  suggestedImageCard: {
    height: '5rem',
    width: '100%',
    borderRadius: '4px',
    cursor: 'pointer',
    '& img': {
      height: '100%',
      width: '100%',
      borderRadius: '4px',
    }
  }
}));
