import React, { useState, useEffect, useContext, useRef } from 'react'

//  components
import SuccessDialog from '../success-dialog';
import FailureDialog from '../failure-dialog';
import Step1 from './components/Step-1';
import Step2 from './components/Step-2';
import Step3 from './components/Step-3';
import Step4 from './components/Step-4';

//  mui
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';

//  utils
import commonUtil from '../../utils/commonUtil';
import useQuery from '../../utils/useQuery';
import isEmpty from '../../utils/isEmpty';
import useRouter from '../../utils/useRouter';

// services
import microskillServices from '../../services/microskill';
import formServices from '../../services/form';
import groupServices from '../../services/group';
import companiesServices from '../../services/companies';
import notificationServices from '../../services/push-notifications';

//constants
import constants from '../../constants';

//  configs
import { keys, urls } from '../../configs';
import { EventSourcePolyfill } from 'event-source-polyfill';

//global-states
import { GlobalStates } from '../../App';

//  styles
import useStyles from './index.styles';


const PushNotifications = () => {
    const styles = useStyles();
    const query = useQuery();
    const router = useRouter();
    const companyId = query.get('c_id');
    const userType = localStorage.getItem("user-type");
    const [globalStates, setGlobalStates] = useContext(GlobalStates);
    const [companies, setCompanies] = useState([]);
    const companiesObject = commonUtil.objectify(companies, 'id')
    const [selectedCompany, setSelectedCompany] = useState([]);

    const [microskills, setMicroskills] = useState([]);
    const microskillsObject = commonUtil.objectify(microskills, 'id')
    const [selectedMicroskills, setSelectedMicroskills] = useState([]);

    const [groups, setGroups] = useState([]);
    const groupsObject = commonUtil.objectify(groups, 'id')
    const [selectedGroups, setSelectedGroups] = useState([]);

    const [forms, setForms] = useState([]);
    const formsObject = commonUtil.objectify(forms, 'id')
    const [selectedForms, setSelectedForms] = useState([]);


    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [linkedContent, setLinkedContent] = useState([]);
    const [launchType, setLaunchType] = useState([]);
    const [fileUpload, setFileUpload] = useState(null);
    const [learnerOption, setLearnerOption] = useState('CSV');
    const [jobId, setjobId] = useState('');
    const [listening, setListening] = useState(false);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);


    const [failed, setFailed] = useState(false);
    const [success, setSuccess] = useState(false);

    const [failedText, setFailedText] = useState('');
    const [successText, setSuccessText] = useState('');


    const [launching, setLaunching] = useState(false);

    useEffect(() => {
        setGlobalStates({ ...globalStates, appBarTitle: 'Push Notifications' });
        return () => {
            setGlobalStates({ ...globalStates, appBarTitle: 'Master-O Studio' });
        }
    }, [])

    useEffect(() => {
        if (companyId !== 'null') {
            setSelectedCompany([companyId]);
        }

        if (userType === "superAdmin") {
            fetchCompanies();
        }
    }, [])

    useEffect(() => {
        if (userType === "superAdmin" && selectedCompany.length) {
            console.log('superAdmin selectedCompany = ', selectedCompany);
            router.history.replace(`/push-notifications?c_id=${selectedCompany[0]}`);
            if (linkedContent.includes("Microskills") && selectedCompany.length) {
                fetchMicroskills(selectedCompany[0]);
                // fetchGroups(selectedCompany[0]);
            }
            if (linkedContent.includes("Forms") && selectedCompany.length) {
                fetchForms(selectedCompany[0]);
            }
        } else {
            if (linkedContent.includes("Microskills") && companyId) {
                fetchMicroskills(companyId);
                // fetchGroups(companyId);
            }
            if (linkedContent.includes("Forms") && companyId) {
                fetchForms(companyId);
            }
        }

    }, [selectedCompany, linkedContent])

    const fetchCompanies = async () => {
        const response = await companiesServices.fetchCompanies();
        // console.log('fetchCompanies response = ', response);
        if (response.status === 200 || response.status === 201) {
            const data = response.data.data;
            data.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return -1;
                } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return 1;
                } else {
                    return 0;
                }
            })
            if (companyId === 'null') {
                setSelectedCompany([data[0].id]);
            }
            setCompanies(data);
        }
    }


    useEffect(() => {
        if (linkedContent.includes("Microskills") && selectedGroups.length && companyId !== 'null') {
            // fetchMicroskills(companyId, null, selectedGroups[0]);
        }


    }, [selectedGroups])

    useEffect(() => {
        if (fileUpload !== null) setStep4(true);
        else setStep4(false);


    }, [fileUpload])



    useEffect(() => {
        if (step2 || step3 || step4) {
            commonUtil.scrollToBottom();
        }

    }, [step2, step3, step4])

    useEffect(() => {
        if (listening) {
            // console.log('EventSourcePolyfill calling!');
            const eventBaseURL = `${urls.baseUrl}${urls.apiV1}`;
            const session = JSON.parse(localStorage.getItem('session'));
            const events = new EventSourcePolyfill(`${eventBaseURL}/notification/${launchType.includes("IMMEDIATE") ? "launch" : "disable"}/${jobId}/status`, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'x-api-key': keys.apiKey,
                    'x-access-token': session.accessToken,
                    'x-refresh-token': session.refreshToken
                }
            });

            events.onmessage = (res) => {
                // console.log('EventSourcePolyfill response data = ', res.data);
                const data = JSON.parse(res.data);
                console.log('EventSourcePolyfill parsed data = ', data);
                if (data.status === "completed") {
                    events.close();
                    setListening(false);
                    setjobId('');
                    setSuccess(true);
                    if (launchType.includes("IMMEDIATE")) {
                        setSuccessText("Notification Launched Successfully!")
                    }
                    else {
                        setSuccessText("Notification Disabled Successfully!")
                    }
                    handleClear();
                    return

                } else if (data.status === "completed" && !isEmpty(data.returnvalue) && data?.returnvalue[0]?.error.length) {
                    events.close();
                    setListening(false);
                    setjobId('');
                    setLaunching(false);
                    setFailed(true);
                    let message = 'Something went wrong, please try again.';
                    if (data?.returnvalue[0]?.error === 'No User found') {
                        message = 'Please enter the valid usernames and try again.'
                    }
                    setFailedText(message);
                    return
                }
                else {
                    setListening(true);
                    return
                }
            }
        }
    }, [listening]);


    const fetchMicroskills = async (companyId = null, search = null, groupIds = null) => {
        console.log('fetchMicroskills called with companyId = ', companyId);
        if (companyId === 'null') return
        const response = await microskillServices.fetchMicroskillsSearch(companyId, search, groupIds);
        // console.log('fetchMicroskills response = ', response);
        if (response.status === 200 || response.status === 201) {
            let activeMicroskills = response.data.data.filter(ele => ele.active === true);
            setMicroskills(activeMicroskills);
        }
    }

    const fetchGroups = async (companyId = null, search = null,) => {
        if (companyId === 'null') return
        const response = await groupServices.fetchGroupsSearch(companyId, search);
        // console.log('fetchGroups response = ', response);
        if (response.status === 200 || response.status === 201) {
            let activeGroups = response.data.data.filter(ele => ele.active === true);
            setGroups(activeGroups);
        }
    }
    const fetchForms = async (companyId = null, search = null,) => {
        if (companyId === 'null') return
        const response = await formServices.fetchFormSearch(companyId, search);
        // console.log('fetchForms response = ', response);
        if (response.status === 200 || response.status === 201) {
            let activeForms = response.data.data.filter(ele => ele.active === true);
            console.log(activeForms);
            setForms(activeForms);
        }
    }


    const _selectCompany = (item) => {
        setSelectedCompany([item.id]);
        setSelectedMicroskills([]);
        setSelectedForms([]);
        setSelectedGroups([]);
        return
    }

    const _selectGroup = (item) => {
        setStep2(false);
        setStep3(false);
        if (selectedGroups.includes(item.id)) {
            setSelectedGroups([]);
            setSelectedMicroskills([]);
            // let filtered = selectedGroups.filter(ele=>ele!==item.id);
            // setSelectedGroups(filtered);
            if (!isEmpty(companyId)) {
                fetchMicroskills(companyId);
            }
            return
        }
        setSelectedGroups([item.id]);
        setSelectedMicroskills([]);
        return
    }

    const _selectMicroskill = (item) => {
        if (selectedMicroskills.includes(item.id)) {
            // let filtered = selectedMicroskills.filter(ele => ele !== item.id);
            // console.log('handleClick filtered = ', filtered);
            setSelectedMicroskills([]);
        } else {
            setSelectedMicroskills([item.id]);
        }
        return
    }
    const _selectAllMicroskills = (list) => {
        let isSelectAll = list.length === selectedMicroskills.length;
        if (isSelectAll) {
            setSelectedMicroskills([]);
            setStep2(false);
            setStep3(false);
            setLaunchType([]);
        } else {
            let all = list.map(ele => ele.id)
            console.log('all = ', all);
            setSelectedMicroskills(all);
            setStep2(true);
        }
        return
    }
    const _selectForm = (item) => {
        if (selectedForms.includes(item.id)) {
            setSelectedForms([]);
            return
        }
        setSelectedForms([item.id]);
        return
    }

    const handleClear = () => {
        setTitle("");
        setMessage("");
        setSelectedGroups([]);
        setSelectedMicroskills([]);
        setSelectedForms([]);
        setLinkedContent([]);
        setLaunchType([]);
        setStep2(false);
        setStep3(false);
        setStep4(false);
        setFileUpload(null);
        setLaunching(false);
        return

    }


    const launchNotificationImmediate = async (notificationId) => {
        const data = {
            notificationLaunchFile: fileUpload,
            notificationIds: [notificationId],
            companyId: companyId,
            launchType: "IMMEDIATE"
        }
        const payload = await commonUtil.jsonToFormData(data);
        const response = await notificationServices.getNotificationJobId(payload);
        console.log('getNotificationJobId response = ', response);
        if (response.status === 200 || response.status === 201) {
            setjobId(response.data.data.jobId);
            setListening(true);
        }
    }

    const runValidation = () => {
        if (!title.length) {
            setFailed(true);
            setFailedText("Please enter the notification title");
            return false;
        }
        if (!message.length) {
            setFailed(true);
            setFailedText("Please enter the notification message");
            return false;
        }
        if (!launchType.length) {
            setFailed(true);
            setFailedText("Please select the launch type");
            return false;
        }
        if (fileUpload === null) {
            setFailed(true);
            setFailedText("Please upload the file");
            return false;
        }
        return true;
    }

    const createNotification = async () => {
        let payload = {
            companyId: companyId,
            title: title,
            message: message
        }
        if (linkedContent.length) {
            if (linkedContent.includes("Microskills") && selectedMicroskills.length) {
                payload.payload = {
                    linkedContent: {
                        type: "microskill",
                        microskillId: selectedMicroskills[0]
                    }
                }
            } else if (linkedContent.includes("Forms") && selectedForms.length) {
                payload.payload = {
                    linkedContent: {
                        type: "form",
                        formId: selectedForms[0]
                    }

                }
            }
        }
        console.log('createNotification payload = ', payload);
        const response = await notificationServices.createNotification(payload);
        return response
    }

    const handleLaunch = async () => {
        let isValid = runValidation();
        if (!isValid) return
        setLaunching(true);
        let notificationId = null;
        if (launchType.includes("IMMEDIATE")) {
            const response = await createNotification();
            console.log('createNotification response = ', response);
            if (response.status === 200 || response.status === 201) {
                notificationId = response.data.data.id;
                await launchNotificationImmediate(notificationId);
            } else {
                return
            }
        }

    }

    const handleFailClick = () => {
        setFailed(false);
    }
    const handleSuccessClick = () => {
        setSuccess(false);
    }

    return (
        <div style={{
            padding: "1rem",
            background: "#FFF",
            height: '100%',
            minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
            position: 'relative'
        }}>
            {/* STEP-1 */}
            <Step1
                userType={userType}
                title={title}
                setTitle={setTitle}
                message={message}
                setMessage={setMessage}
                setStep2={setStep2}
                setStep3={setStep3}
                setStep4={setStep4}
                launching={launching}
                globalStates={globalStates}
                setLaunchType={setLaunchType}
                setFileUpload={setFileUpload}
                companies={companies}
                companiesObject={companiesObject}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
                _selectCompany={_selectCompany}
            />


            {/* STEP-2 */}
            {
                step2 ?
                    <Step2
                        linkedContent={linkedContent}
                        launchType={launchType}
                        setLaunchType={setLaunchType}
                        setStep3={setStep3}
                    /> : null
            }


            {/* STEP-3 */}
            {
                (step2 && step3) ?
                    <Step3
                        learnerOption={learnerOption}
                        setLearnerOption={setLearnerOption}
                        fileUpload={fileUpload}
                        setFileUpload={setFileUpload}
                        setStep4={setStep4}
                    /> : null
            }

            {/* STEP-4 */}
            {
                (step2 && step3 && step4) ?
                    <Step4
                        linkedContent={linkedContent}
                        setLinkedContent={setLinkedContent}
                        setStep2={setStep2}
                        setStep3={setStep3}
                        setLaunchType={setLaunchType}
                        microskills={microskills}
                        microskillsObject={microskillsObject}
                        selectedMicroskills={selectedMicroskills}
                        setSelectedMicroskills={setSelectedMicroskills}
                        _selectMicroskill={_selectMicroskill}
                        forms={forms}
                        formsObject={formsObject}
                        selectedForms={selectedForms}
                        setSelectedForms={setSelectedForms}
                        _selectForm={_selectForm}
                    /> : null
            }
            <div style={{
                position: 'absolute',
                bottom: '26px',
                display: 'flex',
                gap: '4rem'
            }}>
                {
                    !(step2 && step3 && step4) ?
                        //instead of loading btn here we have to show disabled button until all validation are clear on context list
                        <Button variant="contained" disabled className={styles.btn}>
                            {
                                launchType.length ?
                                    launchType.includes("IMMEDIATE") ? "LAUNCH" : 'DISABLE'
                                    : "LAUNCH"
                            }
                        </Button> :
                        (
                            launching ?

                                <LoadingButton
                                    loading
                                    loadingPosition="start"
                                    startIcon={<SaveIcon />}
                                    variant="outlined"
                                    className={styles.btn}
                                    sx={{
                                        borderWidth: '2px !important'
                                    }}
                                >

                                    {

                                        launchType.includes("IMMEDIATE") ? "LAUNCHING..." : 'DISABLING...'

                                    }
                                </LoadingButton>
                                : <Button title='LAUNCH'
                                    variant="contained"
                                    className={styles.btn}
                                    onClick={handleLaunch}
                                    sx={{
                                        backgroundColor: "#f4511e",
                                        border: '2px solid #f4511e',
                                        color: '#ffffff',
                                        '&:hover': {
                                            backgroundColor: "#f4511e",
                                            border: '2px solid #f4511e',
                                            color: '#ffffff',
                                            boxShadow: '1px 2px 7px 0px rgba(0,0,0,0.75)'
                                        }
                                    }}>
                                    {
                                        launchType.length ?
                                            launchType.includes("IMMEDIATE") ? "LAUNCH" : 'DISABLE'
                                            : "LAUNCH"
                                    }
                                </Button>
                        )


                }


                <Button
                    title='CLEAR'
                    variant="outlined"
                    onClick={handleClear}
                    className={styles.btn}
                    sx={{
                        backgroundColor: "#FFFFFF",
                        border: '2px solid #f4511e',
                        color: '#f4511e',
                        '&:hover': {
                            backgroundColor: "#FFFFFF",
                            border: '2px solid #f4511e',
                            color: '#f4511e',
                            boxShadow: '1px 2px 7px 0px rgba(0,0,0,0.4)'
                        }
                    }}>
                    CLEAR & DELETE
                </Button>


                {/* <LoadingButton
                    loading={loading}
                    disabled={loading}
                    className={styles.LaunchBtn}
                // onClick={handleLaunch}
                >
                    LAUNCH
                </LoadingButton>
                {loading === false && (
                    <LoadingButton className={styles.ClearBtn}
                        onClick={handleClear}
                    >CLEAR & DELETE</LoadingButton>)} */}
            </div>

            {failed && (
                <FailureDialog
                    setErrorDialogOpen={setFailed}
                    errorDialogOpen={failed}
                    dialogText={failedText}
                    handleClick={handleFailClick}
                />
            )}

            {success && (
                <SuccessDialog
                    successDialogOpen={success}
                    setSuccessDialogOpen={setSuccess}
                    dialogText={successText}
                    handleClick={handleSuccessClick}
                />
            )}
        </div>
    )
}

export default PushNotifications;



