import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import commonReducer from "./common/common-reducer";
import authReducer from "./auth/auth-reducer";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

const rootReducer = combineReducers({
    common: commonReducer,
    auth: authReducer,
})


export default persistReducer(persistConfig, rootReducer);