import { authFetch } from "../utils/fetch";

const fetchProjects = async (companyId, search = '') => {
    let url = `/company/project?limit=300&sortDirection=asc&companyIds=${companyId}`;
    if (search && search.length) url += `&search=${search}`
    try {
        const resData = await authFetch.get(url) //GET ALL COMPANY PROJECTS
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const fetchProjectsPagination = async (companyId, startIndex = 0, search = '') => {
    let endPoint = `/company/project?startIndex=${startIndex}&limit=10&sort=createdOn&sortDirection=asc&companyIds=${companyId}`;
    if (search.length) endPoint += `&search=${search}`
    try {
        const resData = await authFetch.get(endPoint)
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const createProject = async (data) => {
    // console.log(data);
    try {
        const resData = await authFetch.post(`/company/project`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const updateProject = async (payload) => {

    try {
        const resData = await authFetch.put(`/company/project`, payload)
        return resData
    } catch (err) {
        return err.response
    }
}

const deleteProject = async (data) => {
    try {
        const resData = await authFetch.delete(`/company/project`, { data })
        return resData
    } catch (err) {
        return err.response
    }
}


export default { fetchProjects, createProject, updateProject, deleteProject, fetchProjectsPagination }