import { authFetch, authFetchFormData } from "../utils/fetch";

const createManager = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/managerdashboard/create-manager`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const mapUsersToManagers = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/managerdashboard/users-manager-mapping`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const validateManagers = async (data) => {
    try {
        const resData = await authFetchFormData.post(`/managerdashboard/verifyManagerUploadData`, data)
        return resData
    } catch (err) {
        return err.response
    }
}

const getLastUploadedManagerMapping = async (companyId = null) => {
    try {
        const resData = await authFetch.post(`/managerdashboard/getManagerUploadUrl?companyId=${companyId}`)
        return resData
    } catch (err) {
        return err.response
    }
}

export default {
    createManager,
    validateManagers,
    getLastUploadedManagerMapping,
    mapUsersToManagers
}