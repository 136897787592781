import constants from "../constants";
import { authFetch } from "../utils/fetch";

const fetchLoginReports = async (payload, { startIndex = 0, limit = 10 }) => {
    let url = `/reports/login?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const fetchCompletionReports = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/completion`;
    if (payload.companyId === constants.IFL_ID) {
        if (payload.flag === 'excel' || payload.flag === 'email') {
            if ("reportFetchType" in payload) delete payload["reportFetchType"];
            if ("largeReport" in payload) delete payload["largeReport"];
            endPoint = `/reports/flat-completion`;
        }
    }
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const getDemographics = async (companyId) => {
    let url = `/user/getcompWiseDemographics/${companyId}`;
    try {
        const resData = await authFetch.get(url);
        return resData
    } catch (err) {
        return err.response
    }
}

const getFilteredDemographics = async (payload) => {
    let url = `/user/demographicsFilter`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchLearnerFeedbackReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/course-feedback`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchTrainerFeedbackReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/trainer-feedback`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchSkillGapReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/skill-gap`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const fetchTqaReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/tqa`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}


const fetchLeaderboardReports = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/leader-board`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const downloadAssessmentReport = async (payload, { startIndex = 0, limit = 10000 }) => {
    let endPoint = `/reports/forms-report`
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchAttemptLevelReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/microskill-attempt-level-data`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const fetchMicroskillFeedbackReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/microskill-feedback-reports`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}
const fetchActiveInactiveUserReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/login`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchCompletionWithTimespentReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/completion`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchUserActivityReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/user-activity-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchFirstTimeCompletionReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/first-time-completion-microskill`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchLicenceReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/licence`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchSpotlightAttemptLevelReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/spotlight-attempt-level-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchDisabledMicroskillReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/disabled-microskill-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const fetchLearningJourneyCompletionReport = async (payload, { startIndex = 0, limit = 10 }) => {
    let endPoint = `/reports/learning-journey-completion-report`;
    let url = `${endPoint}?startIndex=${startIndex}&limit=${limit}&sortDirection=asc`;
    try {
        const resData = await authFetch.post(url, payload);
        return resData
    } catch (err) {
        return err.response
    }
};




export default {
    fetchLoginReports,
    fetchCompletionReports,
    fetchLearnerFeedbackReports,
    fetchTrainerFeedbackReports,
    downloadAssessmentReport,
    getDemographics,
    getFilteredDemographics,
    fetchSkillGapReports,
    fetchTqaReports,
    fetchLeaderboardReports,
    fetchAttemptLevelReport,
    fetchMicroskillFeedbackReport,
    fetchActiveInactiveUserReport,
    fetchCompletionWithTimespentReport,
    fetchUserActivityReport,
    fetchFirstTimeCompletionReport,
    fetchLicenceReport,
    fetchSpotlightAttemptLevelReport,
    fetchDisabledMicroskillReport,
    fetchLearningJourneyCompletionReport
}