import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainContainer: {
        height: 'calc(100% - 3.8rem)',
        padding: '1rem'
    },
    row1: {
        // border: '1px solid',
        height: '40%',
    },
    uploadProgressBox: {
        display: 'flex',
        alignItems: 'center',
        '& .fa-solid': {
            width: '6%',
            fontSize: '1.3rem',
            marginRight: '3%',
            color: '#444444',
        },
        '& > div': {
            width: '91%',
            '& > h6': {
                fontSize: '0.6rem',
                color: '#f3c50e',
                fontFamily: 'Montserrat',
                fontWeight: 600,
            }
        }
    },
    csvUploadBoxContainer: {
        border: '2px dashed #CCCCCC',
        borderRadius: '10px',
        marginTop: '1rem',
        height: '8rem'
    },
    csvUploadBox: {
        padding: '1rem 0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '8rem',
        '& h4,h5,a': {
            marginBottom: '0.2rem',
            fontSize: '0.6rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: "700",
        },
        '& h5': {
            margin: '0.3rem 0',
            fontWeight: "500",
        },
        '& > a': {
            margin: '0.3rem 0',
            color: '#F4511E',
            fontWeight: "600",
            '&:hover': {
                fontWeight: "700",
            }
        },

    },
    uploadIcon: {
        '& .fa-solid': {
            fontSize: '1.5rem',
            color: '#F4511E',
            cursor: 'pointer',
            marginBottom: '0.3rem',
            transition: '0.5s',
            '&:hover': {
                scale: 1.1
            }
        },

    },
    row2: {
        // border: '1px solid',
        height: '60%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    labelBox: {
        margin: '1rem 0'
    },
    warningNote: {
        // border: '1px solid red',
        fontFamily: "Montserrat",
        fontWeight: 600,
        fontSize: "0.8rem",
        color: '#e30000',
        marginBottom: '1rem',
    },
    btnBox: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        gap: '2rem',
        '& .MuiButtonBase-root': {
            // marginRight: '2rem',
            width: '45%',
            fontFamily: "Montserrat",
            fontWeight: 800,
            lineHeight: 0,
            fontSize: "0.6rem",
            borderRadius: "0.2rem",
        }
    },
    btn: {
        width: '45%',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#F4511E",
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        borderRadius: "0.2rem",
        cursor: "pointer",
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
        }
    },
    saveBtn: {
        backgroundColor: "#F4511E",
        // marginRight: "2rem",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F4511E",
        }
    },
    clearBtn: {
        backgroundColor: "#FFFFFF",
        color: "#F4511E",
        "&:hover": {
            border: "2px solid #F4511E",
            backgroundColor: "#FFFFFF",
        }
    },



}));

export default useStyles;