import React from 'react'
import ManageUser from '../../components/manage-user'

const ManageUserPage = () => {
  return (
    <>
    <ManageUser/>
    </>
  )
}

export default ManageUserPage