import React, { useEffect } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import Title from '../../../title'
import MicroskillAttemptLevelReport from '../ms-attempt-level-report';
import MicroskillFeedbackReport from '../ms-feedback-report';
import ActiveInactiveUserReport from '../active-inactive-user-report';
import CompletionWithTimespentReport from '../completion-with-timespent';
import UserActivityReport from '../user-activity-report';
import PrePostCompletionReport from '../pre-post-completion';
import FirstTimeCompletionScore from '../first-time-completion-score';
import LicenceReport from '../licence-report';
import SpotlightAttemptLevel from '../spotlight-attempt-level';
import DisabledMicroskillReport from '../disabled-microskill-report'
import LearningJourneyCompletionReport from '../learning-journey-completion-report';

//constants
import constants from '../../../../constants';

const OtherReports = ({
    setBackdrop,
    mapping,
    demographicFilter,
    setDemographicFilter,
    _applyDemographicFilter,
    _selectAllSubmenuDF,
    _handleSubmenuItemDF,
    _resetDemographicFilter,
    handleDemographicSearch,
    contentFilter,
    setContentFilter,
    _selectAllSubmenuCF,
    _handleSubmenuItemCF,
    _applyContentFilter,
    _resetContentFilter,
    _handleContentSearch,
    _resetDateRange,
    selectedTab,
    handleSelectTab
}) => {
    let roles = localStorage.getItem('roles') || [];
    const classes = useStyles();

    useEffect(() => {
        scrollToItem(selectedTab);

    }, [])

    const scrollToItem = (id, block = 'center', blockOffset = null) => {
        let item = document.getElementById(`#${id}-mis-reports`);
        if (item) {
            let scrollOptions = { behavior: 'smooth', block: block };
            if (blockOffset) scrollOptions.blockOffset = blockOffset;
            item.scrollIntoView(scrollOptions);
        }
        return
    }

    const allRestricted = () => {
        let restricted = true;
        const otherReportRoles = constants.OTHER_REPORTS_ROLES;
        for (let i = 0; i < otherReportRoles.length; i++) {
            if (roles.includes(otherReportRoles[i])) {
                restricted = false;
                break;
            }
        }
        return restricted

    }

    return (
        <div className={classes.main}>
            <Title titleText={'Additional Reports (MIS)'} fs='1.8rem' fw={800} />

            {
                allRestricted() ?
                    <div className={classes.restricted}>
                        <i className="fa-solid fa-circle-exclamation"></i>
                        <p>You do not have access to any of the additional MIS reports</p>
                    </div> :
                    <div className={classes.cardsContainer}>
                        {
                            roles.includes('MS_ATTEMPT_LEVEL') ?
                                <MicroskillAttemptLevelReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.MS_ATTEMPT_LEVEL}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.MS_ATTEMPT_LEVEL}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }


                        {
                            roles.includes('OTHER_REPORTS') ?
                                <MicroskillFeedbackReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.MS_FEEDBACK}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.MS_FEEDBACK}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }



                        {
                            roles.includes('ACTIVE_INACTIVE_USER') ?
                                <ActiveInactiveUserReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.ACTIVE_INACTIVE_USER}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.ACTIVE_INACTIVE_USER}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }



                        {
                            roles.includes('COMPLETION_WITH_TIMESPENT') ?
                                <CompletionWithTimespentReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.COMPLETION_WITH_TIMESPENT}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.COMPLETION_WITH_TIMESPENT}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }

                        {
                            roles.includes('USER_ACTIVITY') ?
                                <UserActivityReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.USER_ACTIVITY}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.USER_ACTIVITY}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }


                        {
                            roles.includes('PRE_POST_COMPLETION_ATTEMPTS') ?
                                <PrePostCompletionReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.PRE_POST_COMPLETION_ATTEMPTS}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.PRE_POST_COMPLETION_ATTEMPTS}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }


                        {
                            roles.includes('FIRST_TIME_COMPLETION') ?
                                <FirstTimeCompletionScore
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.FIRST_TIME_COMPLETION}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.FIRST_TIME_COMPLETION}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }
                        {
                            roles.includes('LICENCE_REPORT') ?
                                <LicenceReport
                                    setBackdrop={setBackdrop}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.LICENCE_REPORT}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.LICENCE_REPORT}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }

                        {
                            roles.includes('SPOTLIGHT_ATTEMPT_REPORT') ?
                                <SpotlightAttemptLevel
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.SPOTLIGHT_ATTEMPT_REPORT}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.SPOTLIGHT_ATTEMPT_REPORT}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }
                        {
                            roles.includes('DISABLED_MICROSKILL_REPORT') ?
                                <DisabledMicroskillReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.DISABLED_MICROSKILL_REPORT}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.DISABLED_MICROSKILL_REPORT}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }

                        {
                            roles.includes('OTHER_REPORTS') ?
                                <LearningJourneyCompletionReport
                                    setBackdrop={setBackdrop}
                                    mapping={mapping}
                                    demographicFilter={demographicFilter}
                                    setDemographicFilter={setDemographicFilter}
                                    _applyDemographicFilter={_applyDemographicFilter}
                                    _selectAllSubmenuDF={_selectAllSubmenuDF}
                                    _handleSubmenuItemDF={_handleSubmenuItemDF}
                                    _resetDemographicFilter={_resetDemographicFilter}
                                    handleDemographicSearch={handleDemographicSearch}
                                    contentFilter={contentFilter}
                                    setContentFilter={setContentFilter}
                                    _selectAllSubmenuCF={_selectAllSubmenuCF}
                                    _handleSubmenuItemCF={_handleSubmenuItemCF}
                                    _applyContentFilter={_applyContentFilter}
                                    _resetContentFilter={_resetContentFilter}
                                    _handleContentSearch={_handleContentSearch}
                                    _resetDateRange={_resetDateRange}
                                    active={selectedTab === constants.OTHER_REPORTS_TABS_OBJECT.LEARNING_JOURNEY_COMPLETION_REPORT}
                                    tabIdx={constants.OTHER_REPORTS_TABS_OBJECT.LEARNING_JOURNEY_COMPLETION_REPORT}
                                    handleSelectTab={handleSelectTab}
                                /> : null
                        }


                    </div>

            }
        </div>
    )
}

export default OtherReports

const useStyles = makeStyles(() => ({
    main: {
        padding: '0.5rem 1rem',
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem'
    },
    cardsContainer: {
        display: 'flex',
        gap: '4rem',
        padding: '1rem',
        flexWrap: 'wrap'
    },
    restricted: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 'calc(100vh - 14rem)',
        gap: '2rem',
        '& > i': {
            fontSize: '4rem',
            color: '#EB3917',
            animation: '$heartBeat 1s infinite linear reverse'
        },
        '& > p': {
            color: '#EB3917',
            fontSize: '2rem',
            fontWeight: 600,
            fontFamily: 'Montserrat',
        }

    },
    '@keyframes heartBeat': {
        '0%': {
            transform: 'scale(1)'
        },
        '50%': {
            transform: 'scale(1.1)'
        },
        '100%': {
            transform: 'scale(1)'
        },
    }

}));