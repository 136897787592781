import React, { useState, useEffect, useContext } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';


//components
import Title from '../../../title'
import Table from '../table';
import SearchBox from '../../../search-box';
import Label from '../../../custom-styled/label';
import DatePicker from '../date-picker';

//utils
import useQuery from '../../../../utils/useQuery';
import commonUtil from '../../../../utils/commonUtil';

//services
import reportsServices from '../../../../services/reports';

//global-states
import { GlobalStates } from '../../../../App';

let defaultPagination = {
    limit: 0,
    pageCount: 0,
    startIndex: -1,
    totalCount: 0
}



const defaultHeadings = [
    { title: "Learner Name", key: "Learner Name" },
    { title: "Email ID", key: "Email" },
    { title: "Username", key: "Username" },
    { title: "First Name", key: "First Name" },
    { title: "Last Name", key: "Last Name" },
    { title: "Mobile", key: "Mobile" },
    { title: "Designation", key: "Designation" },
    { title: "Zone", key: "Zone" },
    { title: "Supervisor", key: "Supervisor" },
    { title: "Business Group", key: "Business Group" },
    // { title: "Password", key: "Password" },
    { title: "LoginStatus", key: "LoginStatus" },
    { title: "First Login", key: "firstLogin", isDate: true },
    { title: "Latest Login", key: "latestLogin", isDate: true },
];


const Feedback = ({ setBackdrop, _resetDateRange }) => {
    const classes = useStyles();
    const query = useQuery();
    const companyId = query.get("c_id");
    const [globalStates] = useContext(GlobalStates);
    const [headings, setHeadings] = useState(defaultHeadings);
    const [tableData, setTableData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [tableLoader, setTableLoader] = useState(false);
    const [pagination, setPagination] = useState(defaultPagination);
    const today = dayjs();
    const [payload, setPayload] = useState({ companyId, flag: "filter" });
    const [dateRange, setDateRange] = React.useState([null, null]);
    const [limit, setLimit] = React.useState(10);
    const [selectedShortcut, setSelectedShortcut] = useState(null);
    let minDate = dayjs(new Date('2022-01-01'));



    useEffect(() => {
        // fetchLoginReports(payload, { startIndex, limit });
    }, [payload, startIndex, limit])



    const handleChangeLimit = (event) => {
        setStartIndex(0)
        setPageNo(1);
        setLimit(event.target.value);
    };

    const handleBackClick = () => {
        if (startIndex >= limit) {
            setStartIndex(startIndex - limit)
            setPageNo(pageNo - 1);
        }
    }

    const handleForwardClick = () => {
        if (startIndex + limit < totalCount) {
            setStartIndex(startIndex + limit);
            setPageNo(pageNo + 1);
        }
    }


    // const fetchLoginReports = async (payload, options = {}) => {
    //     setTableLoader(true);
    //     // return
    //     const response = await reportsServices.fetchLoginReports(payload, options);
    //     console.log('fetchLoginReports response = ', response);
    //     if (response && response.status === 200) {
    //         let resData = response.data.data;
    //         const headings = commonUtil.getHeadings(resData.data, 'dateOnly');
    //         console.log('Login headings = ', headings);
    //         if (headings.length) setHeadings(headings);
    //         setTableData(resData.data);
    //         setPagination(resData.paginationOptions);
    //         setPageCount(resData.paginationOptions.pageCount);
    //         setTotalCount(resData.paginationOptions.totalCount);
    //         setTableLoader(false);
    //     }
    // }



    const handleDateChange = (newValue) => {
        if (newValue[1] !== null) {
            let newPayload = {
                ...payload,
                startDate: dayjs(newValue[0]).format('YYYY-MM-DD HH:mm:ss.SSS'),
                endDate: dayjs(newValue[1]).format('YYYY-MM-DD HH:mm:ss.SSS')
            }
            console.log('newPayload = ', newPayload);

            setPayload(newPayload);
        }

        setDateRange(newValue);

    }

    const downloadLearnerFeedbackReport = async () => {
        console.log('downloadLearnerFeedbackReport');
        setBackdrop(true);
        let downloadAsExcelPayload = {
            ...payload,
            companyId: companyId,
            flag: "excel",
        };
        // if (!downloadAsExcelPayload.startDate) downloadAsExcelPayload.startDate = dayjs(today.subtract(7, 'day')).format('YYYY-MM-DD HH:mm:ss.SSS')
        // if (!downloadAsExcelPayload.endDate) downloadAsExcelPayload.endDate = dayjs(today).format('YYYY-MM-DD HH:mm:ss.SSS')
        const response = await reportsServices.fetchLearnerFeedbackReports(downloadAsExcelPayload, { startIndex: 0 });
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Learner feedback reports'; // Set the desired filename for the downloaded file
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }

    const downloadTrainerFeedbackReport = async () => {
        setBackdrop(true);
        let downloadAsExcelPayload = {
            ...payload,
            companyId: companyId,
            flag: "excel",
        };
        // if (!downloadAsExcelPayload.startDate) downloadAsExcelPayload.startDate = dayjs(today.subtract(7, 'day')).format('YYYY-MM-DD HH:mm:ss.SSS')
        // if (!downloadAsExcelPayload.endDate) downloadAsExcelPayload.endDate = dayjs(today).format('YYYY-MM-DD HH:mm:ss.SSS')
        const response = await reportsServices.fetchTrainerFeedbackReports(downloadAsExcelPayload, { startIndex: 0 });
        if (response.status === 200) {
            let fileUrl = response.data.data.file.Location;
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.download = 'Trainer feedback reports'; // Set the desired filename for the downloaded file
                link.click();
                setBackdrop(false);
            }

        } else {
            globalStates.handleToast(true, "Internal server error", "error");
            setBackdrop(false);
        }
    }
    const handleResetDateRange = () => {
        let { newPayload } = _resetDateRange(payload);
        setPayload(newPayload);
        setDateRange([null, null]);
    }



    return (
        <div className={classes.main}>
            <div className={classes.header}>
                <Title titleText={"Learner Feedback Report"} />
                <div className={classes.actionIcons}>
                    <img src='/images/analytics/download-icon.svg' alt='' title='Download Learner Feedback Report' onClick={downloadLearnerFeedbackReport} />
                </div>
            </div>
            <div className={classes.tableBox}>
                <div className={classes.filtersContainer}>
                    <div className={classes.demographicsFilterWrapper}>


                    </div>
                    <div className={classes.dateRangeWrapper}>
                        {/* <Label labelText={"Date Range"} /> */}
                        <DatePicker
                            value={dateRange}
                            maxDate={today}
                            minDate={minDate}
                            handleDateChange={handleDateChange}
                            handleReset={handleResetDateRange}
                            selectedShortcut={selectedShortcut}
                            setSelectedShortcut={setSelectedShortcut}
                        />
                    </div>
                </div>
                {/* <Table
                    loader={tableLoader}
                    tableHeadings={headings}
                    tableData={tableData}
                    handleForwardClick={handleForwardClick}
                    handleBackClick={handleBackClick}
                    pageCount={pageCount}
                    pageNo={pageNo}
                    pagination={pagination}
                    rows={limit}
                    handleChangeNumberOfRows={handleChangeLimit}
                /> */}
            </div>

            <div className={classes.header}>
                <Title titleText={"Trainer Feedback Report"} />
                <div className={classes.actionIcons}>
                    <img src='/images/analytics/download-icon.svg' alt='' title='Download Trainer Feedback Report' onClick={downloadTrainerFeedbackReport} />
                </div>

            </div>
            <div className={classes.tableBox}>
                <div className={classes.filtersContainer}>
                    <div className={classes.demographicsFilterWrapper}>


                    </div>
                    <div className={classes.dateRangeWrapper}>
                        {/* <Label labelText={"Date Range"} /> */}
                        <DatePicker
                            value={dateRange}
                            maxDate={today}
                            minDate={minDate}
                            handleDateChange={handleDateChange}
                            handleReset={handleResetDateRange}
                            selectedShortcut={selectedShortcut}
                            setSelectedShortcut={setSelectedShortcut}
                        />
                    </div>
                </div>
                {/* <Table
                    loader={tableLoader}
                    tableHeadings={headings}
                    tableData={tableData}
                    handleForwardClick={handleForwardClick}
                    handleBackClick={handleBackClick}
                    pageCount={pageCount}
                    pageNo={pageNo}
                    pagination={pagination}
                    rows={limit}
                    handleChangeNumberOfRows={handleChangeLimit}
                /> */}
            </div>
        </div>
    )
}


export default Feedback;



const useStyles = makeStyles(() => ({
    main: {
        // border: '1px solid',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: '#f9f9f9',
        padding: '0.2rem 1rem',
        margin: '1.5rem 0',
        height: '3rem'
    },
    actionIcons: {
        position: 'absolute',
        right: '1rem',
        display: 'flex',
        alignItems: 'center',
        '& > img': {
            height: '1.9rem',
            transition: '.4s',
            '&:hover': {
                height: '2rem',
                cursor: 'pointer'
            }
        },

    },
    tableBox: {
        margin: '1rem',
        marginBottom: '2rem',
        boxShadow: "0px 0px 8px -4px rgba(0,0,0,0.5)",
        borderRadius: '6px'
    },
    filtersContainer: {
        // border: '1px solid red',
        position: 'relative',
        height: '3.8rem',
        display: 'flex',
    },
    demographicsFilterWrapper: {
        // border: '1px solid',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '14rem',
        '& > h1': {
            border: '1px solid rgb(112,112,112, 0.3)',
            borderRadius: '3px',
            fontSize: '0.8rem',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontWeight: 600,
            padding: '0.4rem 0.5rem',
            display: 'flex',
            alignItems: 'center',
            '& > i': {
                marginLeft: '0.8rem',
                fontSize: '1rem',
                color: '#f4511e'
            },
            '&:hover': {
                cursor: 'pointer',
                boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.1)'
            }
        }
    },
    selectBoxWrapper: {
        position: 'absolute',
        top: '1rem',
        left: '1rem',
        backgroundColor: '#fff',
        borderRadius: '4px',
    },
    dateRangeWrapper: {
        // border: '1px solid',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        gap: '1rem',
        right: '1rem',
        height: '100%',
    },
    datePickerBox: {
        // border: '1px solid red',
        '& > div': {
            overflow: 'hidden !important',
            paddingTop: '0.4rem !important',
            height: '2.5rem !important',
            '& .MuiInputBase-root': {
                height: '2rem !important',
                width: '8rem !important',
                color: '#707070'

            },
            '& .MuiFormLabel-root': {
                fontFamily: 'Montserrat',
                fontSize: '0.9rem',
                fontWeight: 600
            }
        }
    },
    datePicker: {
        // border: '1px solid red'
    }
}));

