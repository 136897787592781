import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    addCategoryContainer: {
        position: 'relative',
        backgroundImage: "url(/images/add-category/add-category.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // height: "94.3vh",
        color: "#fff",
        backgroundBlendMode: "multiply",
        backgroundColor: "rgb(0, 0, 0, 0.3)"
    },
    heading: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "2rem",
        padding: '1rem',
        '& > h1':{
            marginBottom : '3.3rem',
            fontFamily : 'Montserrat',
            fontSize : '4rem'
        }
    },
    closeBtn: {
        position: 'absolute',
        top: 46,
        left: 65,
        height: '22px',
        filter: 'invert(98%) sepia(2%) saturate(0%) hue-rotate(142deg) brightness(160%) contrast(107%)',
        '&:hover':{
            cursor: 'pointer',
            transition: 'height 0.5s',
            height: '24px',
        }
    }
}))

export default useStyles;