import React, { useState, useRef, createRef, useEffect, useContext } from 'react'
import html2canvas from "html2canvas";

//mui
import { makeStyles } from "@mui/styles";

//components
import CustomButton from '../../../buttons';
// import EditableTitle from '../editable-title';
import Label from '../../../custom-styled/label';
import SuggestedOrUpload from '../suggested-or-upload';
import EditableTitle from '../rtf-title';

//utils
import commonUtil from '../../../../utils/commonUtil';

//constants
import constants from '../../../../constants';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';



const Cover = ({
  suggestedImages,
  handleSaveCard,
  customAspectRatio = true,
  isEdit,
  editCardData,
  handleCancel,
  handleUpdateCard,
  titleBody,
  setTitleBody,
  handeleTitleChange,
  handleTitleClick,
  toolbar,
  setToolbar
}) => {
  const classes = useStyles();
  const styles = commonStyles();
  const [globalStates] = useContext(GlobalStates)
  // const [logo, setLogo] = useState(constants.SUGGESTED_IMAGES[0].url);
  // const [image, setImage] = useState(constants.SUGGESTED_IMAGES[0].url);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isSuggested, setIsSuggested] = useState(false);
  const [prevImageUrl, setPrevImageUrl] = useState(null);
  // const [title, setTitle] = useState('')
  const [logo, setLogo] = useState(null)
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [isNext, setIsNext] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  let capture = useRef();
  let imgRef = useRef();
  let titleRef = useRef(null);

  useEffect(() => {
    // let logoUrl = "https://masterov4-media-files.s3.ap-south-1.amazonaws.com/clewehsn2000tqnvrh52gda46/LAMediaFiles-1678343988728.png"
    // let logoUrl = constants.MO_LOGO_PATH;
    // commonUtil.imageUrlToBase64(logoUrl, (base64) => {
    //   // console.log('logo = ', base64);
    //   setLogo(base64);
    // })

  }, [])

  useEffect(() => {
    setToolbar({ title: false, body: false })
    if (isEdit && editCardData !== null) {
      let card = editCardData.card;
      let imgUrl = commonUtil.getImageUrlFromContents(card.contents);
      setPrevImageUrl(imgUrl);
      commonUtil.imageUrlToBase64(imgUrl, (base64) => {
        setImage(base64);
      })
      setIsSuggested(false);
      let title = commonUtil.getTitleFromContents(card.contents);
      setTitleBody({ title: title, body: '' });
      setShowDetails(false);

    } else {
      setTitleBody({ title: '', body: '' })
    }
  }, [editCardData])

  useEffect(() => {
    if (isNext) {
      captureScreenshot()
    }
  }, [isNext])


  const captureScreenshot = async () => {


    const backgroundColorDiv = document.createElement("div");
    // Set the background color on the additional div
    backgroundColorDiv.style.backgroundColor = 'rgba(0,0,0,0.5)';
    backgroundColorDiv.style.position = 'absolute';
    backgroundColorDiv.style.top = '0';
    backgroundColorDiv.style.left = '0';
    backgroundColorDiv.style.width = '100%';
    backgroundColorDiv.style.height = '100%';
    backgroundColorDiv.style.zIndex = -1;

    // Append the additional div to the container
    capture.current.appendChild(backgroundColorDiv);

    // setCustomAspectRatio(!customAspectRatio)
    const canvas = await html2canvas(capture.current);
    capture.current.removeChild(backgroundColorDiv);

    //CONVERTING CANVAS TO BASE64
    const thumbImgData = canvas.toDataURL("image/png");
    const myFile = commonUtil.base64toFile(thumbImgData, 'cover')
    const cardData = {
      id: 0,
      cardType: "COVER",
      ratio: "4:3",
      title: titleBody.title,
      logoUrl: constants.MO_LOGO_PATH,
      thumbImageFile: myFile,
      thumbFileSize: myFile.size,
      imageFile: imageFile,
      suggestedImgUrl: image
    }

    const type = 'COVER'
    const withImage = true;
    if (isEdit) {
      cardData.cardId = editCardData.id;
      cardData.prevImageUrl = prevImageUrl;
      await handleUpdateCard(type, cardData, withImage, isSuggested)
      return
    }
    await handleSaveCard(type, cardData, withImage, isSuggested);
  }


  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0])
      let img = URL.createObjectURL(event.target.files[0]);
      // // console.log(img);
      setIsSuggested(false)
      setImage(img)
    }
  };

  const handleSelectSuggested = (imageUrl) => {
    setIsSuggested(true)
    setImage(imageUrl)
  }


  const handleNext = () => {
    if (!titleBody.title.length) {
      globalStates.handleToast(true, 'Please enter the Title', 'error')
      // console.log('Title is Empty');
      return
    }
    if (!image) {
      globalStates.handleToast(true, 'Please upload the image', 'error')
      return
    }
    setShowDetails(false);
    setIsNext(true);
  }


  return (


    <>
      <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: 'multiply',
          backgroundColor: 'rgb(0,0,0,0.5)',
        }}>

        {/* <div className={classes.titleContainer}>
          <EditableTitle
            ref={titleRef}
            handeleTitleChange={handeleTitleChange}
            fs={'2.6rem'}
            fw={800}
            align={'center'}
            textColor={'#fff'}
            placeholderColor={'rgb(255, 255, 255, 0.5)'}
          />
        </div> */}
        <EditableTitle
          ref={titleRef}
          textColor={'#fff'}
          minHeight={'3rem'}
          maxHeight={'25rem'}
          handeleTitleChange={handeleTitleChange}
          value={titleBody.title}
          handleTitleClick={handleTitleClick}
          showTitleToolbar={toolbar.title}
          placeholderColor={'rgb(255, 255, 255, 0.5)'}
        />
        {showDetails ? <div className={classes.cardDetails}>
          <div>
            <img src='/images/learning-aid-assets/number-of-cards-icon.svg' /> <Label labelText={'0 CARDS'} fs={'0.76rem'} color={'#fff'} />
          </div>
          <div>
            <img src='/images/learning-aid-assets/total-duration-la-icon.svg' /> <Label labelText={'0 MINUTES'} fs={'0.76rem'} color={'#fff'} />
          </div>
        </div> : null}
        {showSuggestions ?
          <div className={classes.suggestionBox}>
            <SuggestedOrUpload
              title={'Background Image:'}
              handleChange={onImageChange}
              suggestedImages={suggestedImages}
              handleClick={handleSelectSuggested}
            />
          </div> : null}

      </div>

      {true && <div className={styles.btnBox}>
        <CustomButton
          btnText={isEdit ? 'SAVE' : 'NEXT'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={handleNext}
        // handleClick={() => setCustomAspectRatio(!customAspectRatio)}
        />

        {isEdit && <CustomButton
          btnText={'CANCEL'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={handleCancel}
        />}
      </div>}
    </>

  )

}

export default Cover;

const useStyles = makeStyles(() => ({
  row: {
    height: '100%',
    width: '100%',
    display: 'flex'
  },
  colum1: {
    // border: '1px solid red',
    width: '90%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // position: 'relative'
  },
  colum2: {
    // border: '1px solid red',
    width: '10%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  aspect1: {
    aspectRatio: 3 / 4,
  },
  aspect2: {
    aspectRatio: 9 / 16,
  },
  cardBox: {
    // border: '1px solid',
    // position: 'relative',
    height: '88%',
    display: 'flex',
    justifyContent: 'center',

  },
  card: {
    // width: '43%',
    position: 'relative',
    height: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '1rem 1.5rem',

    // margin: '0 auto'
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    display: 'none',//hiding logo in phase 1 launch
    // border: '1px solid black',
    height: '2.5rem',
    width: '40%',
    position: 'absolute',
    top: '3%'
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '100%'
  },
  titleContainer: {
    width: '92%',
    maxHeight: '21%',
    margin: '0.5rem 0 !important',
    overflow: 'hidden',
    position: 'absolute',
    top: '12%',
    '& > div': {
      margin: '0 !important',
      marginTop: '0 !important',
      lineHeight: '1.2 !important',
      maxHeight: '100%',
      height: '100%',
      '&:focus': {
        outline: 'none'
      },
      '&:hover': {
        border: 'none',
      }
    },
    '&:hover': {
      border: '1px dashed rgb(204, 204, 204, 0.5)',
    }
  },
  btnBox: {
    height: '12%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiButtonBase-root': {
      borderRadius: '3px',
      height: '2.8rem',
      width: '11% !important',
      '&:hover': {
        borderColor: '#f4511e',
        backgroundColor: '#fff',
        color: '#f4511e'
      }
    }
  },
  imgBox: {
    borderRadius: '4px',
    height: '52%',
    width: '92%',
    position: 'absolute',
    top: '35%',
    '& > img': {
      borderRadius: '4px',
      width: '100%',
      aspectRatio: 4 / 3
    },
    '&:hover': {
      border: '1.6px dashed rgb(204, 204, 204, 0.5)',
    }
  },

  cardDetails: {
    // border: '1px solid',
    position: 'absolute',
    top: '88%',
    width: '92%',
    height: '8%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      '& label': {
        fontWeight: 900,
      },
    },
    '& img': {
      height: '2rem',
      width: '2rem'
    },

  },

  colum3Row1: {
    height: '92%'
  },
  colum3Row2: {
    height: '8%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    '& .MuiButtonBase-root': {
      width: '50%',
      '&:hover': {
        borderColor: '#f4511e',
        backgroundColor: '#fff',
        color: '#f4511e'
      }
    }
  },
  aspectRatioButtons: {
    // border: '1px solid',
    height: '10%'
  },
  suggestionBox: {
    backgroundColor: '#fff',
    // border: '1px solid',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    // width : '10rem',
    position: 'absolute',
    top: '11.9rem',
    right: '-10.2rem',
    // height: '17rem', //changing while hiding suggested images
    height: '7.8rem',
    width: '10.2rem',
    padding: '0.5rem',
    paddingLeft: '0.3rem',
    paddingRight: '1.5rem'
  }

}));

