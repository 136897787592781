import { makeStyles } from "@mui/styles";


const useStyles = makeStyles(() => ({
    mainContainer:{
        display : "flex",
        height : "100%",
        width : "100%",
        backgroundColor : "#FFFFFF",
    },
    Left_div : {
        // border: "1px solid red",
        height : "100%",
        width : "50%",
        padding : "1rem"
    },
    Title : {
        display: "flex",
        alignItems:"center",
        fontFamily: "Montserrat",
        color : "#2B2B2B",
        fontSize : "14px",
        fontWeight: "Bolder",
        characterSpacing : "0",
        lineSpacing : "155",
        padding : '0.5rem 0'
    },
    Help_Icon:{
        color: "#F4511E",
        fontSize: "1rem",
        marginLeft: "0.7rem"
    },
    Label : {
        display : "block",
        fontFamily: "Montserrat",
        color : "#484848",
        fontSize : "12px",
        fontWeight: "Bold",
        characterSpacing : "0",
        padding: "0.6rem 0"
    },
    datePickerBox : {
        '& input': {
            position: 'relative'
        },
        '& input[type="date"]':{
            '&::-webkit-calendar-picker-indicator':{
                background: 'transparent',
                bottom: '0',
                color: 'transparent',
                cursor: 'pointer',
                height: 'auto',
                left: '0',
                position: 'absolute',
                right: '0',
                top: '0',
                width: 'auto'
            }
        }
    },
    Input_Box:{
        fontFamily: "Open Sans",
        fontSize : "11px",
        fontWeight: "400",
        display : "block",
        width : "50%",
        padding : "0.5rem",
        margin: "0.5rem 0",
        border : "none",
        // boxShadow: "0 0 6px #DDE0E2",
        border: "1.4px solid #70707050",
        color : "#707070",
        fontSize : "0.8rem"
    },
    Date_Input : {
        background: 'url("/images/icons/calendar-solid.svg") no-repeat right',
        backgroundPosition: 'bottom 50% right 8px',
        backgroundSize: '12px',
        fontFamily: "Open Sans",
        fontSize : "11px",
        fontWeight: "400",
        display : "block",
        width : "50%",
        padding : "0.5rem",
        margin: "0.5rem 0",
        border : "none",
        // boxShadow: "0 0 6px #DDE0E2",
        border: "1.4px solid #70707050",
        color : "#707070",
        fontSize : "0.8rem"
    },
    CheckBoxContainer:{
        height: '2rem',
        margin : "0.8rem 0",
        display : "flex",
        alignItems : "center",
        justifyContent : "flex-start",
        "& .MuiSvgIcon-root": { fontSize: "1rem", color: "#F4511E" },
        '& > .MuiFormGroup-root':{
            '& > .MuiFormControlLabel-root':{
                marginRight: "0 !important",
                marginLeft : "-0.5rem !important",
                '& > .MuiButtonBase-root':{
                    marginRight: '0.5rem'
                }
            }
        }
    },
    CheckBox:{
        // border: '1px solid black',
        '& .MuiTypography-root':{
            color : "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize : "0.7rem",
            fontWeight: 600,
        }
    },
    RememberMeText : {
        fontFamily: "Open Sans",
        fontWeight: "400",
        fontSize : "0.7rem",
        color : "#707070",
        characterSpacing : "0",
        lineSpacing : "15"

    },
    Save_Btn : {
        backgroundColor : "#F4511E",
        color : "#FFFFFF",
        fontFamily: "Montserrat",
        fontSize : "12px",
        fontWeight: "Bold",
        characterSpacing : "0",
        lineSpacing : "82",
        padding : "0.6rem 2.5rem",
        border : "none",
        borderRadius : "2px",
        cursor : "pointer",
        "&:hover" :{
            opacity : "0.9"
        }
    },
    Right_div : {
        minHeight : "100%",
        width : "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: '0 3rem'
    },
    Inside_Right_div: {
        // padding: "0 1rem",
        // paddingRight : "5rem",
        display:"flex",
        flexDirection: "column",
        flexWrap : "wrap"

    },
    Error_Icon:{
        color: "#F4511E",
        fontSize: "1rem",
        marginRight : "0.2rem"
    },
    Right_div_title : {
        display : "flex",
        alignItems : "center",
        fontFamily: "Montserrat",
        color : "#4F0060",
        fontSize : "0.9rem",
        fontWeight: "Bold",
    },
    Right_div_textbox:{
        fontFamily: "Open Sans",
        color : "#707070",
        fontSize : "0.9rem",
        fontWeight: 400,
        letterSpacing: 0,
        lineHeight : "3"
    }
}))

export default useStyles