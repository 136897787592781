import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

//  mui
import { createFilterOptions } from '@mui/material/Autocomplete';
import { Autocomplete, TextField } from '@mui/material';

//  components
import AutocompleteSearch from '../autocomplete-search';

//constants
import constants from '../../constants';

//  services
import categoryServices from "../../services/categories";

// styles
import useStyles from './index.styles'
import { useRef } from 'react';

const AddCategory = (props) => {
    const { categories, showAddCategoryPage, setShowAddCategoryPage, createCategory, showBackBtnCategory, setShowBackBtnCategory } = props
    const { companyId } = useParams();
    console.log('company id = ', companyId);
    // useEffect(() => {
    //     console.log('useeffect called!');
    //     fetchCategory(companyId);
    // }, [])
    const _filterOption = createFilterOptions();
    const [defaultOption, setDefaultOption] = useState({ name: "No category available.", type: "disabled" });
    const [selectCategory, setSelectCatgeory] = useState();

    

    // useEffect(() => {
    //     let inputField = document.getElementById("#input-field");
    //     console.log('inputField = ', inputField);
    //     if (inputField) inputField.focus();
    //   }, [showAddCategoryPage])

    // const fetchCategory = async (companyId) => {
    //     const response = await categoryServices.fetchCategories(companyId);
    //     console.log("response:", response);
    //     if (response.status === 200 || response.status === 201) {
    //         let activeCategories = response.data.data.filter(element => element.active === true);
    //         setCategory(activeCategories);
    //     }
    // }

    // const createCategory = async (element) => {
    //     const response = await categoryServices.createCategories(companyId, element.name);
    //     if (response.status === 200 || response.status === 201) {

    //         fetchCategory(companyId);
    //         return;
    //     }
    // }



    // useEffect(() => {
    //     if (selectCategory) {
    //         selectCategory.forEach(element => {
    //             if (element.type === "new") {
    //                 createCategory(element)
    //                 fetchCategory(companyId)
    //             }
    //         });
    //     }
    // }, [selectCategory])

    const handleFilter = (option, value) => {
        const results = _filterOption(option, value);
        if (results.length == 0) {
            results.push(defaultOption)
        }
        return results;
    }

    const handleChange = (e, values) => {
        console.log('handleChange = ', values);
        if (values.type === "new") {
            createCategory(values)
        } else {
            localStorage.setItem('scrollToItem', values.id);
            setShowAddCategoryPage(false);
        }
        // setSelectCatgeory(values);

    }

    const handleBack = () => {
        setShowAddCategoryPage(false);
        // setShowBackBtnCategory(false);
    }

    const styles = useStyles();
    return (
        <div style={{ height: `calc(100vh - ${constants.HEADER_HEIGHT})` }} className={styles.addCategoryContainer}>
            {
                showBackBtnCategory ?
                    <img onClick={handleBack} className={styles.closeBtn} src='/images/gba-assets/left-arrow.svg' alt='' /> : null
            }
            <div className={styles.heading}>
                <h1>Add or select a category</h1>
                <AutocompleteSearch
                    focus={true}
                    data={categories}
                    handleFilter={handleFilter}
                    handleChange={handleChange}
                    setDefaultOption={setDefaultOption}
                />
            </div>
        </div>
    )
}

export default AddCategory