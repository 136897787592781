import React, { useEffect } from 'react'

// mui
import { FormControl, FormControlLabel, RadioGroup, Typography, Radio } from '@mui/material'
import DragDropFiles from '../../../drag-drop-files-uploader/index';
import UploadedFiles from '../../../drag-drop-files-uploader/uploaded-files';

//  constants
import constants from '../../../../constants';

//styles
import useStyles from '../../index.styles';

const Files = (props) => {
    const classes = useStyles();
    const fileLink = constants.USER_LAUNCH_FILE_FORMAT;
    const { setLearnerOption, learnerOption, fileUpload, setFileUpload } = props;

    const handleChange = (e, value) => {
        setLearnerOption(value);
    }

    const handleFileUpload = (files) => {
        setFileUpload(files[0])
    }

    return (
        <>
            <FormControl>
                <RadioGroup onChange={handleChange}>
                    <FormControlLabel value="CSV" control={<Radio checked={learnerOption === "CSV"} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>Via  Excel File</Typography>} />
                </RadioGroup>
            </FormControl>
            {learnerOption === "CSV" && (<div style={{ padding: "1rem 0rem" }}>
                <a style={{
                    textDecoration: "none",
                    color: "#F4511E",
                    fontWeight: "bolder",
                    fontSize: "1rem",
                    fontFamily: 'Montserrat',
                }} href={fileLink} className={classes.anchor} title='Download File Format'>Download File Format</a>
            </div>)}
            {learnerOption === "CSV" && (
                <div style={{ width: "40%", height: "15rem", marginBottom: "5rem" }}>
                    {fileUpload === null ?
                        <DragDropFiles title={'Drag & Drop your file here to import'} handleChange={handleFileUpload} typesAccepted={["XLS", "XLSX"]} />
                        :
                        <UploadedFiles fileName={fileUpload.name} setFileUpload={setFileUpload} />
                    }
                </div>
            )}

        </>
    )
}

export default Files