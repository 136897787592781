import React, { useState } from 'react'
import { useEffect } from 'react';

//mui
import { makeStyles } from "@mui/styles";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

//components
import LaDragableList from '../la-dragable-list'
import CustomButton from '../../../buttons';

//constants
import constants from '../../../../constants';

const Content = ({
  setShowCoverAndObjective,
  setShowImportBtn,
  coverAndObjective,
  setPreview,
  isActive,
  setIsActive,
  list,
  setList,
  selectedCard,
  setSelectedCard,
  selectedColumns,
  setSelectedColumns,
  setSelectedInteraction,
  handleSaveChallenge,
  isSavingChallenge,
  setIlaCardId,
  setSelectedQuestion,
  handleDeleteCard,
  setIsEdit,
  setEditCardData,
  setShowFirstColumn,
  setIsEditInteraction, 
  setLink,
  laAudioData,
  setLaAudioData,
  laBgImgData,
  setLaBgImgData
 }) => {
  const classes = useStyles();


  const handleAddCard = () => {
    //here we have to hide cover and objective cards from select card type and show import content btn
    setIsEdit(false);
    setEditCardData(null);
    setIsActive(null);
    setShowCoverAndObjective(false);
    setShowImportBtn(true);
    setPreview(constants.LA_DEFAULT_IMAGE);
    setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' });
    setSelectedColumns({ ...selectedColumns, firstColumn: 'createOwn' });
  }


  const handleAddInteraction = (e,listItem) => {
    e.stopPropagation();
    setIsActive(listItem.id);
    setIlaCardId(listItem.id);
    setPreview(listItem.thumbImage);
    setSelectedInteraction({ gameType: '', interactionType: '', previewUrl: ''});
    setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' });
    setSelectedColumns({ firstColumn: 'manageInteraction', secondColumn: 'editContentSection' });
  }

  const handleManageInteraction = (e,listItem) => {
    e.stopPropagation();
    let games = listItem.games;
    let preview =  constants.ILA_PREVIEWS[games.questions[0].designType];
    setIsEditInteraction(true);
    setIsActive(listItem.id);
    setIlaCardId(listItem.id);
    setPreview(listItem.thumbImage);
    setSelectedQuestion(0);
    setSelectedCard({ rowIndex: null, columnIndex: null, cardType: 'default' });
    setSelectedColumns({ firstColumn: 'manageInteraction', secondColumn: 'editContentSection' });
    setSelectedInteraction({ gameType : games.gameType, interactionType: games.questions[0].designType, previewUrl: preview });
  }

  return (

    <>
      <LaDragableList
        // coverCard={coverCard}
        coverAndObjective={coverAndObjective}
        list={list}
        setList={setList}
        isActive={isActive}
        setIsActive={setIsActive}
        setPreview={setPreview}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        handleAddInteraction={handleAddInteraction}
        handleManageInteraction={handleManageInteraction}
        handleDeleteCard={handleDeleteCard}
        setIsEdit={setIsEdit}
        setEditCardData={setEditCardData}
        setShowFirstColumn={setShowFirstColumn}
        setLink={setLink} 
        laAudioData={laAudioData}
        setLaAudioData={setLaAudioData}
        laBgImgData={laBgImgData}
        setLaBgImgData={setLaBgImgData}
        />
      <div className={classes.btnBox}>
        {isSavingChallenge ?
          <LoadingButton
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined"
          >
            Saving...
          </LoadingButton> :
          <CustomButton handleClick={handleSaveChallenge} btnText={'SAVE CHALLENGE'} textColor={'#fff'} bgcolor={'#f4511e'} fw={700} ls={1} />

        }
        <CustomButton handleClick={handleAddCard} btnText={'ADD NEW CARD +'} fw={800} ls={1} />
      </div>
    </>
  )
}

export default Content

const useStyles = makeStyles(() => ({
  btnBox: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '4rem',
    padding: '0.5rem 1rem',
    gap: '1.5rem',
    '& .MuiLoadingButton-loading': {
      borderRadius: '0px',
      fontFamily: "Montserrat",
      fontWeight: "bold",
      fontSize: "0.55rem",
      letterSpacing: 1,
      height: '2.5rem'
    },
    '& .MuiButtonBase-root': {
      width: '200px',
      // width: '170px',
      fontSize: '0.55rem',
      fontWeight: 800,
      '&:hover': {
        color: '#f4511e',
        borderColor: '#f4511e',
        fontWeight: 800
      }

    }

  }
}));