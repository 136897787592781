import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
    mainContainer: {
        height: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        width: '100%',
        position: 'relative',
        backgroundColor: '#FFFFFF',
        '& nav': {
            height: '3.8rem',
            borderBottom: "1px solid rgb(221, 224, 226, 0.5)",
        }
    },
    header: {
        // border: '1px solid red',
        backgroundColor: '#F9F9F9',
        height: '3.4rem',
        display: 'flex',
        width: '50%',
        alignItems: 'center',
        paddingLeft: '1rem',
        gap: '1rem',
        position: 'absolute',
        top: '-3.4rem',
        left: 0,
        zIndex: 9999,
        '& > img': {
            cursor: 'pointer',
            transition: 'width 2s',
            '&:hover': {
                width: '1.2rem',
                marginRight: '-0.11rem'
            }
        },
        '& h1': {
            color: "rgb(0,0,0,0.9)",
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "700",
            '& img': {
                height: '0.8rem',
                width: '0.8rem',
                marginLeft: '2rem',
                '&:hover': {
                    cursor: 'pointer',
                    filter: 'invert(42%) sepia(69%) saturate(4247%) hue-rotate(352deg) brightness(100%) contrast(92%)'
                }
            }
        },
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            '& > input': {
                border: 'none',
                backgroundColor: '#F9F9F9',
                padding: '0.2rem 0.5rem',
                color: "rgb(0,0,0,0.9)",
                fontFamily: "Montserrat",
                fontSize: "1rem",
                fontWeight: "700",
                borderBottom: '1px solid #f4511e',
                '&:focus': {
                    outline: 'none',
                    borderBottom: '2px solid #f4511e',
                }
            },
            '& .MuiButtonBase-root': {
                marginLeft: '2rem',
                height: '1.5rem',
                width: '2rem',
                borderRadius: '4px',
                backgroundColor: '#f4511e',
                color: '#fff',
                border: '2px solid #f4511e',
                fontWeight: 700,
                letterSpacing: '1px',
                '&:hover': {
                    backgroundColor: '#fff',
                    color: '#f4511e',
                }
            }
        }


    },
    list: {
        // width: '25%',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        // justifyContent : 'center',
        // borderRight: "1px solid rgb(221, 224, 226, 0.5)",
        '& li': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            listStyle: 'none',
            // padding: '0 0.4rem',
            width: '26%',
            minWidth: '6rem',
            textAlign: 'center',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: "500",
            // paddingBottom: "0.4rem",
            cursor: "pointer",
            '& i': {
                paddingRight: "0.4rem",
                fontSize: "0.9rem",
            },
            '& .fa-circle-check': {
                color: '#15B725'
            },
            '& .fa-circle-dot': {
                color: '#ffa000'
            }
            // '&:hover': {
            //     // borderBottom : "3px solid #F4511E",
            //     color: "rgb(0, 0, 0, 0.8)",
            //     fontWeight: "700",
            // }
        },
        '& span': {
            width: '11%',
            borderTop: "2px solid rgb(221, 224, 226, 0.5)",

        }
    },
    // className={`${selectedMenuItem.index === i ? classes.liActive : null}`} //for active menu item - old
    liActive: {
        color: "rgb(0, 0, 0, 0.8) !important",
        padding: '0 0.2rem !important',
        fontWeight: "600 !important",
    },
    //
    row: {
        display: 'flex',
        // height: 'calc(100% - 3.8rem)'
        height: '100%'
    },
    column1: {
        width: '25%',
        height: '100%',
        borderRight: "1px solid rgb(221, 224, 226, 0.5)",
        // padding : '1.2rem',
        overflowY: 'auto',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '3px',
            // backgroundColor: '#F2F2F2'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px',
        },
        // '&:hover': {
        //     '&::-webkit-scrollbar-thumb': {
        //         background: '#f4511e',
        //         borderRadius: '12px',
        //     }
        // }
    },
    column2: {
        display: 'flex',
        // overflowY: 'scroll',
        justifyContent: 'center',
        backgroundColor: '#F9F9F9',
        // padding: '6rem 2rem',
        width: '50%',
        height: '100%',
        // borderRight: "1px solid rgb(221, 224, 226, 0.5)",
        '& img': {
            // height: '80%',
            // width: '70%'
        }
    },
    column3: {
        backgroundColor: '#F9F9F9',
        width: '25%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1rem 2rem',
        // borderRight: "1px solid rgb(221, 224, 226, 0.5)",
        '& div': {
            '& img': {
                objectFit: 'scale-down',
                height: '100%',
                width: '100%',
                aspectRatio: '9/17'
            }
        }

    },

    gridContainer: {
        // aspectRatio: '48 / 9 !important',
        minHeight: '100%',
    },

    dialogBoxContainer: {
        display: 'grid',
        gridTemplateRows: '20% 50% 20%',
        height: '50%',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        padding: '1rem',
        placeItems: 'center',
    },

    dialogTitle: {
        fontSize: 'large',
        fontWeight: 700,
        marginBottom: '1rem'
    },


    dismissBtn: {
        border: '1px solid #F4511E',
        backgroundColor: '#F4511E',
        color: '#FFF',

    },

    btn: {
        width: '190px',
        borderRadius: '2px',
        height: '42px',
        padding: '0 4px',
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: '#F4511E',
            color: '#FFF',
            boxShadow: "0px 1px 10px -3px rgba(0,0,0,0.5)"
        }
    },
card:{
    '&:hover': {
        boxShadow: '0px 5px 10px 0px rgba(0,0,0,0.8)'
    }
},


    cardMedia: {
        width: '100%',
        aspectRatio: '16 / 9',
        cursor:'pointer',
       

    }
}));

export default useStyles;