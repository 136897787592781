import React from 'react'

//styles
import { makeStyles } from "@mui/styles";


const CreateMicroskillCard = ({ handleCreate, createText }) => {
  const styles = useStyles();
  return (
    <>
      <div className={styles.card}  onClick={handleCreate}>
        <div className={styles.insideCard}>
          <img className={styles.addIcon} src='/images/icons/add.svg' alt='' />
          <h5>{createText}</h5>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(() => ({

  card: {
    // border: '2px dashed #F4511E',
    cursor: 'pointer',
    borderRadius: '5px',
    //  height: '25rem',
    width: '23%',
    aspectRatio: 0.72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // margin: '0.5rem',
    backgroundImage: 'repeating-linear-gradient(180deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(270deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(0deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(90deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px)',
    backgroundSize: '2px calc(100% + 30px), calc(100% + 30px) 2px, 2px calc(100% + 30px) , calc(100% + 30px) 2px',
    backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
    backgroundRepeat: 'no-repeat',
    animation: '$borderAnimation 4s infinite linear reverse'
  },
  '@keyframes borderAnimation': {
    from: { backgroundPosition: '0 0, -30px 0, 100% -30px, 0 100%' },
    to: { backgroundPosition: '0 -30px, 0 0, 100% 0, -30px 100%' }
  },
  insideCard: {
    display: 'flex',
    flexDirection: 'column',
    '& img': {
      height: '3.5rem',
      width: '3.5rem',
      marginBottom: '1.5rem',
      alignSelf: 'center',
    },
    '& h5': {
      color: '#F4511E',
      fontSize: '0.8rem',
      fontWeight: 'Bold',
      fontFamily: 'Open Sans'
    }
  },
}))

export default CreateMicroskillCard;
