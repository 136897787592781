import React from 'react';

export default function TrashIcon(props) {
  let fill = '#f4511e';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="mastero_icon_delete"
      width="27"
      height="27"
      viewBox="0 0 15 15"
      className={props.className}>
      <defs>
        <style>
          {`.class-trash-icon-1,.class-trash-icon-2{fill:${fill}}.class-trash-icon-1{opacity:0}`}
        </style>
      </defs>
      <path
        id="Rectangle_928"
        d="M0 0h15v15H0z"
        className="class-trash-icon-1"
        data-name="Rectangle 928"
      />
      <g id="delete-photo" transform="translate(3.75 2.25)">
        <path
          id="Path_1090"
          d="M176.415 239.822a.226.226 0 0 0 .226.226h5.747a.226.226 0 0 0 .226-.226v-7.335h-6.2v7.335zm4.373-6.087h.849v4.936h-.849zm-1.7 0h.849v4.936h-.849zm-1.7 0h.849v4.936h-.849z"
          className="class-trash-icon-2"
          data-name="Path 1090"
          transform="translate(-175.798 -229.855)"
        />
        <path
          id="Path_1091"
          d="M127.5 1.04V.226a.226.226 0 0 0-.227-.226h-3.364a.226.226 0 0 0-.226.226v1.1H122.1a.226.226 0 0 0-.226.226v.572a.226.226 0 0 0 .226.226h6.981a.226.226 0 0 0 .226-.226V1.55a.226.226 0 0 0-.226-.226H127.5V1.04zm-.849.236h-2.118V.849h2.118v.428z"
          className="class-trash-icon-2"
          data-name="Path 1091"
          transform="translate(-121.874)"
        />
      </g>
    </svg>
  );
}
