import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    // mainContainer: {
    //     "& select": {
    //         outline: 'none',
    //         margin: '1.2rem',
    //         marginTop: '2rem',
    //         marginBottom: '0.5rem',
    //         '-webkit-text-fill-color': 'rgb(0, 0, 0, 0.7)',
    //     },
    //     "& option": {
    //         color: "#8D8D8D",
    //         fontSize: "0.8rem",
    //     },
    // },
    // selectBox: {
    //     width: '35%',
    //     padding: '0.6rem',
    //     border: "2px solid rgb(221, 224, 226, 0.7)",
    //     borderRadius: '3px',
    //     color: "#F4511E",
    //     fontFamily: "Montserrat",
    //     fontSize: "0.7rem",
    //     fontWeight: "700",
    //     cursor: "pointer",
    // },
    mainContainerQA : {
        borderBottom : '1px solid rgb(184, 184, 184, 0.4)',
        padding : '1rem',
        marginTop : '0.3rem',
        marginBottom : '1.8rem'
      },
    questionBox: {
        margin: '1.2rem',
        marginTop: '0'
    },
    questionLabelBox: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    label: {
        padding: '1rem 0rem',
        color: "rgb(0, 0, 0, 0.9)",
        fontFamily: "Montserrat",
        fontSize: "0.7rem",
        fontWeight: "700",
    },
    maxLimitText: {
        fontWeight: '400',
        fontSize: '0.6rem',
        color: 'red',
    },
    questionInput: {
        resize: 'none',
        width: '100%',
        height: '3rem',
        '&::-webkit-scrollbar': {
            display: 'none'
        },
    },
    inputField: {
        padding: '0.6rem',
        color: "rgb(112, 112, 112, 0.8)",
        fontFamily: "Montserrat",
        fontSize: "0.66rem",
        fontWeight: "500",
        border: '1.6px solid rgb(221, 224, 226, 0.7)'
    },
    questionTag: {
        margin: '0.8rem 0rem'
    },
    m_1: {
        margin: '1rem'
    },
    borderTop: {
        borderTop: '1px solid rgb(221, 224, 226, 0.8)',
    },
    btnBox: {
        marginTop: '0.5rem',
        "& button": {
            margin: '0.8rem',
            marginLeft: '0',
            padding: '0.5rem 1.3rem',
            backgroundColor: 'white',
            border: '1px solid #f4511e',
            borderRadius: '2rem',
            color: "rgb(68, 68, 68, 0.9)",
            fontFamily: "Montserrat",
            fontSize: "0.6rem",
            fontWeight: "600",
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#f4511e',
                color: "#FFFFFF",
            }
        }
    },
    input: {
        margin: '1rem 0',
        padding: '0.4rem',
        color: "rgb(112, 112, 112, 0.8)",
        fontFamily: "Montserrat",
        fontSize: "0.77rem",
        fontWeight: "500",
        width: '100%',
        marginBottom: '0.5rem',
        border: '1.6px solid rgb(221, 224, 226, 0.7)'
    },
    formControlLabel: {
        '& .MuiFormControlLabel-label': {
            color: "rgb(68, 68, 68, 0.9)",
            fontSize: '0.6rem',
            fontFamily: "Montserrat",
            fontWeight: "600",
        }
    },
    active: {
        backgroundColor: '#f4511e !important',
        color: "#FFFFFF !important",
    }

}));

export default useStyles;