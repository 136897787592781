import React, { useEffect, useState } from 'react'

import companiesServices from '../../../services/companies';

import DragDropFiles from "../../drag-drop-files-uploader/index"
import UploadedFiles from '../uploadedFiles';

//styles
import useStyles from '../index.styles';

import { TABS } from '../index';

const Learners = (props) => {
    const classes = useStyles();

    const { setFile, file, validationData, activeTab, managerTabFormats } = props;
    const [fileLink, setFileLink] = useState();

    const company = props.company || localStorage.getItem("companyId");

    const handleFileUpload = (file) => {
        setFile(file);
    }

    const fetchCompanyFormat = async () => {
        const response = await companiesServices.getCompanyMapping(company);
        if (response.status === 200 || response.status === 201) {
            setFileLink(response.data.data.template);
        }
    }

    useEffect(() => {
        if (activeTab === TABS.USERS && company) {
            fetchCompanyFormat();
        }
    }, [company, localStorage.getItem("companyId"), activeTab])

    useEffect(() => {
        if (activeTab === TABS.MANAGERS && managerTabFormats?.user) {
            //here we have to set file format as manager user upload sheet
            // let format = '/files/manager-mapping/manager-mapping.xlsx'
            setFileLink(managerTabFormats.user)
        }
    }, [activeTab, managerTabFormats])

    const icons = {
        error: "fa-solid fa-circle-exclamation",
        inProgress: "fa-regular fa-circle-dot",
        valid: "fa-solid fa-circle-check"
    }

    const userValidations = [
        {
            name: 'valid-users',
            title: validationData?.validUsersCount > 1 ? 'User IDs are valid' : 'User ID is valid',
            count: validationData?.validUsersCount,
            url: validationData?.validUsersUrl,
            icon: icons.valid
        },
        {
            name: 'min-character-error',
            title: 'User IDs have less than 3 characters',
            count: validationData?.invalidShortUsersCount,
            url: validationData?.invalidShortUsersUrl,
            icon: icons.error
        },
        {
            name: 'max-character-error',
            title: 'User IDs have more than 120 characters',
            count: validationData?.invalidLongUsersCount,
            url: validationData?.invalidLongUsersUrl,
            icon: icons.error
        },
        {
            name: 'special-character-error',
            title: 'User IDs have invalid special characters (Only . - _ @ are valid)',
            count: validationData?.invalidSpecialCharUsersCount,
            url: validationData?.invalidSpecialCharUsersUrl,
            icon: icons.error
        },
        {
            name: 'id-with-space-error',
            title: 'User IDs have spaces',
            count: validationData?.invalidSpaceUsersCount,
            url: validationData?.invalidSpaceUsersUrl,
            icon: icons.error
        },

        {
            name: "Demo Users doesn't have password",
            title: "Demo Users doesn't have password",
            count: validationData?.invalidDemoUsersPasswordCount,
            url: validationData?.invalidDemoUsersPasswordUrl,
            icon: icons.error
        }
    ]

    const managerValidations = [
        {
            name: 'valid-managers',
            title: validationData?.validUsersCount > 1 ? 'IDs are valid' : 'ID is valid',
            count: validationData?.validUsersCount,
            url: validationData?.validUsersUrl,
            icon: icons.valid
        },
        {
            name: 'min-character-error',
            title: 'IDs have less than 3 characters',
            count: validationData?.invalidShortUsersCount,
            url: validationData?.invalidShortUsersUrl,
            icon: icons.error
        },
        {
            name: 'max-character-error',
            title: 'IDs have more than 120 characters',
            count: validationData?.invalidLongUsersCount,
            url: validationData?.invalidLongUsersUrl,
            icon: icons.error
        },
        {
            name: 'special-character-error',
            title: 'IDs have invalid special characters (Only . - _ @ are valid)',
            count: validationData?.invalidSpecialCharUsersCount,
            url: validationData?.invalidSpecialCharUsersUrl,
            icon: icons.error
        },
        {
            name: 'id-with-space-error',
            title: 'User IDs have spaces',
            count: validationData?.invalidSpaceUsersCount,
            url: validationData?.invalidSpaceUsersUrl,
            icon: icons.error
        },
        {
            name: 'username-error',
            title: 'Invalid username',
            count: validationData?.invalidUsernamesCount,
            url: validationData?.invalidUsernamesUrl,
            icon: icons.error
        },
        {
            name: 'users-format-error',
            title: 'Invalid user format',
            count: validationData?.invalidUserFormatCount,
            url: validationData?.invalidUserFormatUrl,
            icon: icons.error
        },
    ]

    const validations = activeTab === TABS.USERS ? userValidations : managerValidations;

    const handleDownloadValidationFile = (ele) => {
        if (ele.url) {
            const link = document.createElement('a');
            link.href = ele.url;
            link.download = 'Validation file'; // Set the desired filename for the downloaded file
            link.click();
        }
    }


    return (
        <div style={{ padding: "1rem", border: "0 0 1px 0 #707070" }}>
            <h1 style={{
                fontSize: '1.2rem',
                color: "#707070",
                padding: '1rem 0rem',
                fontFamily: "Montserrat",
                fontWeight: 700,
            }}>
                {activeTab === TABS.MANAGERS ? 'Step 2: Upload User Details' : 'Step 2: Learners'}
            </h1>
            <div style={{
                marginBottom: '1rem'
            }}>
                {company || localStorage.getItem("companyId") ?     // For downloading file format
                    <a style={{
                        textDecoration: "underline",
                        color: "#F4511E",
                        fontWeight: 700,
                        fontSize: "1rem",
                        fontFamily: 'Montserrat'
                    }}
                        download
                        className={classes.anchor}
                        href={fileLink}>Download File Format</a> :
                    null}
            </div>
            <div style={{ height: "15rem", display: 'flex', gap: '2rem' }}>
                {
                    file === null || file === undefined ?
                        (<DragDropFiles
                            title={'Drag & Drop your file here to import'}    // The Drag Zone
                            handleChange={handleFileUpload}
                            typesAccepted={["XLS", "XLSX"]}
                            multiple={false}
                            width='35rem'
                        />)
                        :
                        (<UploadedFiles fileName={file.name} setFileUpload={setFile} width='35rem' />)
                }
                <div className={classes.validationWrapper}>
                    {
                        validations.map((ele, i) => {
                            if (ele.count) return <div key={i} onClick={() => handleDownloadValidationFile(ele)}><i className={ele.icon}></i><span>{ele.count}</span><p>{ele.title}</p> </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Learners
