import React, { useState, useRef, useEffect, useContext } from 'react'
import html2canvas from "html2canvas";

//mui
import { makeStyles } from "@mui/styles";

//components
import CustomButton from '../../../buttons';
// import EditableTitle from '../editable-title';
// import EditableBody from '../editable-body';
import SuggestedOrUpload from '../suggested-or-upload';
import UploadContainer from './upload-container'

//constants
// import constants from '../../../../constants';

//utils
import commonUtil from '../../../../utils/commonUtil';

//global-states
import { GlobalStates } from '../../../../App';

//common-styles
import commonStyles from './index.styles';

const ImageOnly = ({
  suggestedImages,
  customAspectRatio = true,
  handleSaveCard,
  isEdit,
  editCardData,
  handleCancel,
  handleUpdateCard,
  laBgImgData,
  handleChangeLaBgImg
}) => {
  const classes = useStyles();
  const styles = commonStyles();
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [globalStates] = useContext(GlobalStates)
  // const [customAspectRatio, setCustomAspectRatio] = useState(true);
  const [prevImageUrl, setPrevImageUrl] = useState(null);
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  // const [canvas, setCanvas] = useState(null);
  const [isSuggested, setIsSuggested] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  let capture = useRef();

  useEffect(() => {
    console.log('isEdit = ', isEdit);
    console.log('editCardData = ', editCardData);
    console.log('laBgImgData = ', laBgImgData);
    if (isEdit && editCardData !== null) {
      let card = editCardData.card;
      let imgUrl = commonUtil.getImageUrlFromContents(card.contents);
      console.log('url = ', imgUrl);
      setPrevImageUrl(imgUrl);
      if (editCardData.cardType === "IMPORTED") {
        setImage(imgUrl)
      } else {
        globalStates.handleProgressDialog(true, 'Loading...');
        commonUtil.imageUrlToBase64(imgUrl, (base64) => {
          // console.log('image to base64 = ', base64);
          setImage(base64);
          globalStates.handleProgressDialog(false);
        })
      }

      setIsSuggested(false);

    }
  }, [editCardData])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFile(event.target.files[0]);
      let img = URL.createObjectURL(event.target.files[0])
      console.log(img)
      setImage(img);
      setIsSuggested(false);
    }
  }

  const handleSelectSuggested = (imageUrl) => {
    setImage(imageUrl);
    setIsSuggested(true);
  }

  const captureScreenshot = async () => {
    // alert("Now.. Preparing Screenshot");
    // console.log('clicked', customAspectRatio);
    // setCustomAspectRatio(!customAspectRatio)
    //console.log(this.capture.current);
    console.log('editCardData = ', editCardData);
    if (!image) {
      //HERE I HAVE TO OPEN TOAST TO SHOW THIS MESSAGE
      globalStates.handleToast(true, 'Please upload the image', 'error')
      return
    }
    if (isEdit && editCardData.cardType === "IMPORTED") {
      console.log('Its edit imported card');
      const importedCardData = {
        cardType: "IMPORTED",
        ratio: "4:3",
        thumbFileSize: editCardData.size,
        imageFile: imageFile,
        isSuggested: false,
        sequence: editCardData.sequence
      }


      const type = 'IMPORTED'
      const withImage = true;

      if (imageFile !== null) importedCardData.thumbFileSize = imageFile.size
      importedCardData.cardId = editCardData.id;
      importedCardData.prevImageUrl = prevImageUrl;
      console.log('Imported card Data = ', importedCardData);
      handleUpdateCard(type, importedCardData, withImage);



      return
    }
    if (laBgImgData?.bgImg) {
      var backgroundColorDiv = document.createElement("div");
      // Set the background color on the additional div
      backgroundColorDiv.style.backgroundColor = 'rgba(0,0,0,0.5)';
      backgroundColorDiv.style.position = 'absolute';
      backgroundColorDiv.style.top = '0';
      backgroundColorDiv.style.left = '0';
      backgroundColorDiv.style.width = '100%';
      backgroundColorDiv.style.height = '100%';
      backgroundColorDiv.style.zIndex = -1;

      // Append the additional div to the container
      capture.current.appendChild(backgroundColorDiv);
    }

    // setCustomAspectRatio(!customAspectRatio)
    const canvas = await html2canvas(capture.current);
    if (laBgImgData?.bgImg) capture.current.removeChild(backgroundColorDiv);

    //CONVERTING CANVAS TO BASE64
    const imgData = canvas.toDataURL("image/png");
    console.log("canvas to base64 = ", imgData);
    // setCanvas(imgData)
    const myFile = commonUtil.base64toFile(imgData, 'temp')

    const cardData = {
      id: 0,
      cardType: "CREATED",
      ratio: "4:3",
      thumbImageFile: myFile,
      thumbFileSize: myFile.size,
      imageFile: imageFile,
      suggestedImgUrl: image,
      isSuggested: isSuggested
    }

    console.log('Image only cardData = ', cardData);

    const type = 'I'
    const withImage = true;

    console.log('THIS IS EDIT isEdit = ', isEdit);
    if (isEdit) {
      console.log('THIS IS EDIT editCardData = ', editCardData);
      cardData.cardId = editCardData.id;
      cardData.prevImageUrl = prevImageUrl;
      handleUpdateCard(type, cardData, withImage, isSuggested)
      return
    }

    handleSaveCard(type, cardData, withImage, isSuggested);

  }

  const handleMouseOver = () => {
    setShowDeleteIcon(true);
  }
  const handleMouseLeave = () => {
    if (image) setShowDeleteIcon(false);
  }

  const handleRemoveImage = () => {
    setImageFile(null);
    setImage(null)
    setShowDeleteIcon(false);
  }

  let textColor = laBgImgData?.bgImg ? '#fff' : 'rgb(112, 112, 112, 0.9)';


  return (
    <>
      <div className={`${styles.card} ${customAspectRatio ? styles.aspect1 : styles.aspect2}`} ref={capture}
        style={{
          backgroundImage: `url(${laBgImgData.bgImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundBlendMode: laBgImgData?.bgImg && 'multiply',
          backgroundColor: laBgImgData?.bgImg && 'rgb(0,0,0,0.5)',
        }}
        onMouseLeave={handleMouseLeave}
      >
        <div className={classes.imgBox} >
          {showDeleteIcon ?
            <span className={classes.deleteIcon}>
              <img src="/images/icons/delete-photo.svg" alt="Delete Button" title="Remove Image" onClick={handleRemoveImage} />
            </span>
            : null}
          {
            image ?
              <img src={image} alt='' onMouseOver={handleMouseOver} /> :
              <UploadContainer handleChange={onImageChange} textColor={textColor} iconColor={laBgImgData?.bgImg ? '#fff' : '#f4511e'} />
          }
        </div>
        {showSuggestions ?
          <div className={styles.suggestionBox}>
            <SuggestedOrUpload
              title={'Background Image:'}
              handleChange={handleChangeLaBgImg}
            />
          </div> : null}
      </div>

      {true && <div className={styles.btnBox}>
        <CustomButton
          btnText={'SAVE'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={captureScreenshot.bind(this)}
        // handleClick={() => setCustomAspectRatio(!customAspectRatio)}
        />
        {isEdit && <CustomButton
          btnText={'CANCEL'}
          bgcolor={'#F4511E'}
          textColor={'#FFF'}
          fw={800}
          ls={1}
          handleClick={handleCancel}
        />}
      </div>}
    </>
  )
}

export default ImageOnly;




const useStyles = makeStyles(() => ({
  imgBox: {
    position: 'relative',
    borderRadius: '4px',
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > img': {
      borderRadius: '4px',
      maxWidth: '100%',
      maxHeight: '100%',
      height: 'fit-content',
      width: 'fit-content',
    },
    '&:hover': {
      border: '1.6px dashed rgb(204, 204, 204, 0.5)',
    }
  },
  deleteIcon: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0.5rem',
    right: '0.5rem',
    height: '2.2rem',
    width: '2.2rem',
    // padding: '0.2rem',
    borderRadius: '50%',
    backgroundColor: 'rgb(112,112,112,0.5)',
    '& > img': {
      transition: '0.7s',
      height: '1rem !important',
      width: '1rem !important',
      filter: 'invert(100%) sepia(95%) saturate(21%) hue-rotate(288deg) brightness(104%) contrast(106%)',
    },
    '&:hover': {
      cursor: 'pointer !important',
      '& > img': {
        scale: 1.2,
        filter: 'invert(40%) sepia(95%) saturate(3595%) hue-rotate(328deg) brightness(99%) contrast(93%)'

      }
    }
  }
}));