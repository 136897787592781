import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    mainContainer: {
        height: 'calc(100% - 7.8rem)',
        padding: '0rem 1rem',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px',
            // backgroundColor: '#F2F2F2'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px',
        },
    },
    row1: {
        // border: '1px solid',
        maxHeight: '90%',
        overflow: 'auto'
    },
    options: {
        // border : '1px solid black'
        margin: '1rem 0'
    },
    inputBox: {
        marginTop: '0.8rem',
        '& input': {
            height: '33px',
        }
    },
    row2: {
        // border: '1px solid',
        height: '10%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    labelBox: {
        margin: '1rem 0'
    },
    btnBox: {
        padding: '0.5rem 1rem',
        height: '3rem',
        display: 'flex',
        justifyContent: 'center',
        gap: '2rem',
        '& .MuiButtonBase-root': {
            width: '45%',
            // padding: "0.63rem 1.5rem",
            fontFamily: "Montserrat",
            fontWeight: "bold",
            fontSize: "0.6rem",
            letterSpacing: "75",
            borderRadius: "0.2rem",
        }
    },
    btn: {
        width: '45%',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#F4511E",
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        borderRadius: "0.2rem",
        cursor: "pointer",
        boxShadow: 'none',
        // transition: "font-size 1s",
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
            // fontSize: "0.62rem",
        }
    },
    saveBtn: {
        backgroundColor: "#F4511E",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F4511E",
        }
    },
    clearBtn: {
        backgroundColor: "#FFFFFF",
        color: "#F4511E",
        "&:hover": {
            border: "2px solid #F4511E",
            backgroundColor: "#FFFFFF",
        }
    },

}));

export default useStyles;