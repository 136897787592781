import React from 'react'

//mui
import { makeStyles } from "@mui/styles";
// import { Dialog } from '@material-ui/core';
import Loader from '../tms-components/Loader';

//new
import Dialog from '@mui/material/Dialog';


import styled from 'styled-components';

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const useStyles = makeStyles(() => ({
    main: {
        zIndex: '999999999 !important',
        '& .MuiBackdrop-root':{
            backgroundColor: 'rgb(0 0 0 / 75%)',
            backdropFilter: 'blur(4px)'
        }
        
    },
    btnBox: {
        '& .MuiButtonBase-root': {
            borderRadius: '4px',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e'
            }
        }
    }
}));

const LoadingDialog = ({
    progressDialogOpen,
    setProgressDialogOpen,
    dialogText = '',
    handleClick,
    buttonText = 'Okay' }) => {
    const classes = useStyles();
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={progressDialogOpen}
            className={classes.main}
            onClose={() => {
                return
                setProgressDialogOpen(false);
            }}>
            <ProgressContainer style={{ minWidth: '310px', maxWidth: '450px' }} >
                <Loader fill="$f4511e" />
                <div style={{ margin: '12px 0' }}>
                    <ProgressText>{dialogText}</ProgressText>
                </div>
            </ProgressContainer>
        </Dialog>
    )
}

export default LoadingDialog
