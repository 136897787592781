export const EDITOR_MODULES = {
    toolbar: [
        [{ font: [] }],
        // [{ size: [] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        // [{ indent: '-1' }, { indent: '+1' }, { indent: '0' }],
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    },
};

export const EDITOR_FORMATS = [
    'font',
    // 'size',
    'color',
    'background',
    'align',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    // 'header'
];