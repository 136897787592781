import { authFetch } from "../utils/fetch";

const fetchGroupsSearch = async (companyId = null, searchValue = null, limit = 1000) => {
    let url = `/group?startIndex=0&limit=${limit}&sort=createdOn&sortDirection=desc`
    if (companyId !== null) {
        url += `&companyIds=${companyId}`;
    }
    if (searchValue !== null) {
        url += `&search=${searchValue}`
    }
    try {
        const resData = await authFetch.get(url);
        return resData;
    } catch (err) {
        return err.response;
    }
}

const fetchGroups = async (companyId, options = { startIndex: 0, limit: 1000 }) => {

    let url = `/group?startIndex=${options.startIndex}&limit=${options.limit}&sort=createdOn&sortDirection=asc&companyIds=${companyId}`;
    try {
        const resData = await authFetch.get(url) //GET ALL COMPANY PROJECTS
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const createGroup = async (data) => {
    try {
        const resData = await authFetch.post(`/group`, data);
        return resData
    } catch (err) {
        return err.response
    }
}

const updateGroup = async (payload) => {
    try {
        const resData = await authFetch.put(`/group`, payload);
        return resData
    } catch (err) {
        return err.response
    }
}

const deleteGroup = async (data) => {
    try {
        const resData = await authFetch.delete(`/group`, { data });
        return resData
    } catch (err) {
        return err.response
    }
}

export default {
    fetchGroupsSearch,
    fetchGroups,
    createGroup,
    updateGroup,
    deleteGroup
}