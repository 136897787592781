import React from 'react'

import PushNotifications from '../../components/push-notifications'

const PushNotificationsPage = () => {
    return (
        <PushNotifications />
    )
}

export default PushNotificationsPage
