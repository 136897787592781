import React from 'react'

//components
import Spotlight from '../../../components/spotlight/index'

const SpotlightPage = () => {
  return (
    <div>
        <Spotlight/>
        {/* Spotlight */}
    </div>
  )
}

export default SpotlightPage
