import { authFetch } from "../utils/fetch";

const fetchCompanies = async (companyId = null) => {
    let url = `/company?limit=300&sortDirection=asc`
    if (companyId !== null) {
        url = `${url}&ids=${companyId}`
    }
    try {
        const resData = await authFetch.get(`${url}`)
        // console.log("fetchCompanies:", resData);
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const fetchCompaniesPagination = async (startIndex = 0, search = '') => {
    let url = `/company?startIndex=${startIndex}&limit=10&sort=createdOn&sortDirection=asc`
    if (search.length) url += `&search=${search}`
    try {
        const resData = await authFetch.get(url)
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const fetchCompaniesSearch = async (search) => {

    try {
        let url = `/company?limit=300&sortDirection=asc`;
        if(search) url += `&search=${search}`
        const resData = await authFetch.get(url)
        // console.log("fetchCompanies:", resData);
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}

const createCompany = async (data) => {
    // console.log(data);
    try {
        const resData = await authFetch.post(`/company`, data)
        console.log("createCompany:", resData);
        return resData
    } catch (err) {
        console.log("createCompany:", err.response);
        return err.response
    }
}

const updateCompany = async (payload) => {

    try {
        const resData = await authFetch.put(`/company`, payload)
        // console.log("updateCompany:", resData);
        return resData
    } catch (err) {
        //console.log(err.response);
        return err.response
    }
}
const deleteCompany = async (data) => {
    try {
        const resData = await authFetch.delete(`/company`, { data })
        // console.log("deleteCompany:", resData);
        return resData
    } catch (err) {
        // console.log('deleteCompany err:', err.response);
        return err.response
    }
}
const getCompanyMapping = async (companyId) => {
    try {
        const resData = await authFetch.get(`/company/${companyId}/mapping`)
        // console.log("deleteCompany:", resData);
        return resData
    } catch (err) {
        // console.log('deleteCompany err:', err.response);
        return err.response
    }
}


export default { fetchCompanies, createCompany, updateCompany, deleteCompany, getCompanyMapping, fetchCompaniesPagination, fetchCompaniesSearch }