import React from 'react';
import styled from 'styled-components';
import EditIcon from "./MDLIcon/icons/EditIcon";
import DuplicateIcon from "./MDLIcon/icons/Duplicate";
// import ButtonBase from "@material-ui/core/ButtonBase";
import TrashIcon from "./MDLIcon/icons/Trash";
import CoursePathwayElementLabel from './CoursePathwayElementLabel';

//new
import ButtonBase from '@mui/material/ButtonBase';

const ComponentWrapper = styled.div`
  margin-bottom: 20px;
`;

const DateText = styled.h5`
  opacity: 0.7;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  text-align: left;
  color: #919191;
  margin: 0;
`;

const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const CurvedButtonBase = styled(ButtonBase)`
  border-radius: 20px;
  margin: 3px;
  padding: 5px;
  
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;


const StyledEditIcon = styled(EditIcon)`
  > #pencil-edit-button .class-edit-icon-2 {
    fill: #4f0060;
  }
`;

const StyledDuplicateIcon = styled(DuplicateIcon)`
  > #duplicate .class-duplicate-icon-2 {
    fill: #4f0060;
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  .class-trash-icon-2 {
    fill: #4f0060;
  }
`;


export default function CoursePathwayElement(props) {
  return (
    <ComponentWrapper className={props.className}>
      <DateText>{props.date}</DateText>
      <ElementWrapper>
        <CoursePathwayElementLabel backgroundColor={props.backgroundColor} text={props.text} />
        {
          props.disableEdit && props.disableClone && props.disableDelete ? null : (
            <ActionWrapper>
              {props.disableEdit === true ? null : <CurvedButtonBase onClick={props.onEdit}><StyledEditIcon /></CurvedButtonBase>}
              {props.disableClone === true ? null : <CurvedButtonBase onClick={props.onCopy}><StyledDuplicateIcon /></CurvedButtonBase>}
              {props.disableDelete === true ? null : <CurvedButtonBase onClick={props.onDelete}><StyledTrashIcon/></CurvedButtonBase>}
            </ActionWrapper>
          )
        }

      </ElementWrapper>
    </ComponentWrapper>
  )
}