import * as React from 'react';

// mui
import Drawer from '@mui/material/Drawer';

// components
import SideMenu from '../side-menu/index'


const SideDrawerContainer = ({
    sideMenu,
    setSideMenu
}) => {
    console.log("Drawer open ", sideMenu.isOpen)

    const drawerStyle = {
        display: {
            xs: 'none',
            sm: 'none',
            md: 'block'
        },
        width: sideMenu.isOpen ? '215px' : '65px',
        overflowX: 'hidden',
        position : 'relative',
        '& .MuiDrawer-paper': {
            transition: sideMenu.isOpen ? 'width 1s' : '0.3s cubic-bezier(0.09, 0.58, 0.93, 0.94)',
            width: sideMenu.isOpen ? '215px' : '65px',
            boxSizing: 'border-box',
            // paddingTop: constants.HEADER_HEIGHT,
            // color: '#FFF',
            overflowX: 'hidden',
            border: 0,
            backgroundColor: '#F9F9F9',
            '& ::-webkit-scrollbar': {
                display: 'none',
            },
        }
    }
    const logoContainerStyle = {
        width: '100%',
        display: 'flex',
        position : 'absolute',
        bottom : 0,
        alignItems: 'flex-end',
        justifyContent: sideMenu.isOpen ? 'flex-start' : 'center',
        padding: '0.5rem',
        paddingLeft: sideMenu.isOpen ? '1.5rem' : '0.5rem',
        backgroundColor: '#f9f9f9'
    }

    return (
        <Drawer
            variant="permanent"
            sx={drawerStyle}
            open={sideMenu.isOpen}>
            <SideMenu
                sideMenu={sideMenu}
                setSideMenu={setSideMenu}
            />
            <div style={logoContainerStyle}>
                <img height='35px' width='35px' src='/images/side-menu-icons/logo.svg' alt='Master-o Logo' />
                {sideMenu.isOpen ? <img height='35px' width='130px' style={{ marginLeft: '-30px' }} src='/images/side-menu-icons/logo-text.svg' alt='Master-o Logo' /> : null}
            </div>
        </Drawer>
    );
}

export default SideDrawerContainer;