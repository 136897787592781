import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

//constants
import constants from '../../../../constants';

//components
import Title from '../../../title';
import CustomTextArea from '../../../custom-styled/custom-text-area';
import CustomInput from '../../../custom-styled/custom-input'
import ControlledSwitches from '../../../custom-styled/switch-button';


//utils
import commonUtil from '../../../../utils/commonUtil';
import isEmpty from '../../../../utils/isEmpty';


const FillInTheBlankV2 = ({
  type,
  activeQuestion = {},
  handleQuestion,
  handleResponse,
  handleRadio
}) => {
  const classes = useStyles();
  const [word, setWord] = useState('');
  const questionValidation = constants.ILA_VALIDATIONS[type]['question'];
  const responseValidation = constants.ILA_VALIDATIONS[type]['response'];
  const [questionLimit, setQuestionLimit] = useState({ exceeded: false, limitText: `${questionValidation} CHARACTERS` })
  const data = new Array(1).fill({ exceeded: false, limitText: `${responseValidation} CHARACTERS` })
  const [responseLimit, setResponseLimit] = useState(data);
  let allowed = ['ILA_FITB_2'];
  if (!allowed.includes(activeQuestion.designType)) {
    activeQuestion = {}
  }

  useEffect(() => {
    if (isEmpty(activeQuestion)) {
      return
    } else {
      setWord(activeQuestion.answers[0].answer)
    }
  }, [activeQuestion])

  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let questionTempLimit = {};
      let value = activeQuestion.question;
      if (value.length > questionValidation) {
        //HERE LENGTH OF INPUT IS GREATER THAN questionCharlimit CHARACTERS.
        questionTempLimit = { exceeded: true, limitText: `${value.length - questionValidation} CHARACTERS EXCEEDED!` };
        setQuestionLimit(questionTempLimit);
      } else {
        //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
        if (activeQuestion.isQuestionEmpty) {
          questionTempLimit = { exceeded: false, limitText: `*Required` };
        } else {
          questionTempLimit = { exceeded: false, limitText: `${questionValidation - value.length} CHARACTERS` };
        }
        setQuestionLimit(questionTempLimit);
      }
    }
  }, [activeQuestion])

  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let temp = [...responseLimit];
      activeQuestion.answers.forEach((element, id) => {
          let value = element.answer;
          if (value.length > responseValidation) {
              //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
              temp[id] = { exceeded: true, limitText: `${value.length - responseValidation} CHARACTERS EXCEEDED!` };
              // setResponsesValidations(temp);
          } else {
              //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
              if(activeQuestion.emptyAnswers[id].isEmpty){
                  temp[id] = { exceeded: false, limitText: `*Required` };
              }else{
                  temp[id] = { exceeded: false, limitText: `${responseValidation - value.length} CHARACTERS` };
              }
          }
      });
      console.log('McqResponse temp = ', temp);
      setResponseLimit(temp);
    }

   
}, [activeQuestion])


  const handleQuestionChange = (e) => {
    let value = e.target.value;
    handleQuestion(value);
    console.log(value.length);
    if (value.length > questionValidation) {
      setQuestionLimit({ exceeded: true, limitText: `${value.length - questionValidation} CHARACTERS EXCEEDED!` })
    } else {
      setQuestionLimit({ exceeded: false, limitText: `${questionValidation - value.length} CHARACTERS` })
    }
  }

  const handleResponseChange = (e, index) => {
    console.log('handleResponseChange index = ', index);
    let value = e.target.value.trim().toUpperCase();
    handleResponse(value, index);
    // let shuffled = commonUtil.shuffleArray()
    setWord(value.toUpperCase())
    console.log(value.length);
    let temp = [...responseLimit];
    if (value.length > responseValidation) {
      temp[index] = { exceeded: true, limitText: `${value.length - responseValidation} CHARACTERS EXCEEDED!` }
      setResponseLimit(temp)
    } else {
      temp[index] = { exceeded: false, limitText: `${responseValidation - value.length} CHARACTERS` }
      setResponseLimit(temp)
    }
  }
  // let word = 'Conforminty'

  return (
    <div className={classes.main}>
      <div className={classes.row2}>
        <div className={classes.card}>
          {/* <Title titleText={'Fill In The Blank'} /> */}
          <div className={classes.contextBox}>
            <CustomTextArea
              value={isEmpty(activeQuestion) ? '' : activeQuestion.question}
              handleChange={handleQuestionChange}
              isExceeded={questionLimit.exceeded}
              exceedText={questionLimit.limitText}
              isEmpty={isEmpty(activeQuestion) ? false : activeQuestion.isQuestionEmpty}
            />
          </div>
          <div className={classes.responsesBox}>
            {
              responseLimit.map((ele, i) => (
                <div className={classes.response} key={i}>
                  <CustomInput
                    value={isEmpty(activeQuestion) ? '' : activeQuestion.answers[i].answer}
                    handleChange={(e) => handleResponseChange(e, i)}
                    exceedText={responseLimit[i].limitText}
                    isExceeded={responseLimit[i].exceeded}
                    isEmpty={isEmpty(activeQuestion) ? false : activeQuestion.emptyAnswers[i].isEmpty}
                  />
                </div>
              ))
            }
            <div className={classes.charactersBox}>
              {
                commonUtil.shuffleArray(word.split("")).map((ele, i) => (
                  <div key={i}>{ele}</div>
                ))
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default FillInTheBlankV2;

const useStyles = makeStyles(() => ({
  main: {
    // backgroundColor : 'red',
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  row1: {
    // border : '1px solid black',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '6%',
    width: '100%',
  },
  checked: {
    '& span': {
      '& .Mui-checked ': {
        color: '#f4511e',

      },
      '& .MuiSwitch-track ': {
        backgroundColor: 'rgb(244, 81, 30) !important'
      }
    }
  },
  scoreBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '27%',
    '& input': {
      width: '26%',
      border: 'none',
      '&:focus': {
        outline: 'none'
      }
    },
  },
  mandatoryBox: {
    // border : '1px solid black',
    display: 'flex',
    alignItems: 'center',
    width: '25%'
  },
  row2: {
    // border : '1px solid black',
    height: '94%',
    width: '100%',
  },
  card: {
    aspectRatio: 0.87,
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '2rem',
    '& > h2': {
      marginTop: '1rem',
      fontSize: '1.6rem',
      color: 'rgb(0,0,0, 0.8)'
    }
  },
  contextBox: {
    // border : '1px solid black',
    width: '100%',
    height: '33%',
    marginTop: '4rem',
  },
  responsesBox: {
    // border: '1px solid',
    height: '44%',
    marginTop: '1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem'
  },
  response: {
    display: 'flex',
    height: '18%'
  },
  charactersBox: {
    rowGap: '6%',
    columnGap: '5%',
    height: '68%',
    display: 'flex',
    padding: '0.1rem',
    overflow: 'hidden',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& div': {
      border: '1px solid #ccc',
      color: 'rgb(112, 112, 112, 0.7)',
      width: '10%',
      aspectRatio: 1 / 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  characterList: {
    '& li': {
      listStyle: 'none'
    }
  },
  column1: {
    width: '82%',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  },
  column2: {
    width: '18%',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center'
  },
  radioGroup: {
    '& .MuiFormControlLabel-root': {
      margin: '0',

      '& .MuiTypography-root': {
        color: 'rgb(112, 112, 112, 0.8)',
        // fontFamily: "Montserrat",
        fontSize: "0.66rem",
        fontWeight: "600",
      },
      '& .MuiButtonBase-root': {
        padding: '0.4rem',
        margin: '0 0.3rem'
      }
    }
  }
}));