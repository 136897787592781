import { makeStyles } from "@mui/styles";
// import palette from "../../theme/palette";

// const COLORS = constants.COLORS;
// MuiButtonBase-root
const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    sideBarItemText: { //for text or links
        '& span': {
            color: '#FFF !important',
            paddingLeft: '1rem'
        }
    },
    list: {
        display: 'flex',
        flexDirection: 'column',
        padding: "0px",
        overflowY: 'scroll',
        '& .MuiListItem-root': {
            textAlign: 'center',
            // border : '1px solid',
        },
        '& .MuiListItem-gutters': {
            padding: '0px !important'
        }
    },
    iconBtn: {
        '&:hover': {
            backgroundColor: 'rgba(204, 204, 204, 0.3)',
            '& img': {
                filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
            },
            '& .MuiSvgIcon-root': {
                filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
            }
        }
    },
    listItemDefault: {
        // border: '1px solid',
        display: 'flex',
        flexDirection: 'column',
        '& > .MuiButtonBase-root': {
            padding: '0px !important',
            margin: '13px 10px !important',
            '&:hover': {
                borderRadius: "35px",
                backgroundColor: 'rgba(204, 204, 204, 0.3)',
                '& .MuiTypography-root': {
                    fontWeight: 'bold',
                },
                '& img': {
                    filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
                }
            },
        },
        '& .MuiCollapse-root': {
            // border : '1px solid red'
        }
    },
    listItem: {

    },
    active: {
        '& > .MuiButtonBase-root': {
            backgroundColor: 'rgba(204, 204, 204, 0.5)',
            borderRadius: '20px',
            '& .MuiTypography-root': {
                fontWeight: 'bold',
            },
            '& img': {
                filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
            }
        },
    },
    listBtnDefault: {
        margin: '13px 10px !important',
        borderRadius: '50% !important',
        padding: '0',
        '& > .MuiListItemIcon-root': {
            margin: '0px !important'
        },
        //old
        // margin: '13px auto !important',
        // borderRadius: '50% !important',
        // padding: '0',
        // width: '50%',
        '&:hover': {
            backgroundColor: 'rgba(204, 204, 204, 0.3)'
        },
        // '& .MuiListItemIcon-root': { //for default
        //     '&:hover': {
        //         borderRadius: "50%",
        //         backgroundColor: 'rgba(255, 255, 255, 0.05)'
        //     },
        // },
    },
    listBtn: {
        margin: '5px 0px !important',
    },
    listText: {
        '& .MuiTypography-root': {
            fontFamily: 'Montserrat',
            fontSize: '0.74rem',
            fontWeight: '400',
            color: '#484848 !important',
            minWidth: '150px'
        }

    },
    hamburgerBox: {
        marginTop: '0rem !important',
        marginBottom: '0.5rem !important'
    },
    hamburgerIcon: {
        padding: '10px !important',
    },
    menuIcon: {
        height: '2.5rem',
        width: '2.2rem',
        filter: `invert(22%) sepia(9%) saturate(0%) hue-rotate(181deg) brightness(0%) contrast(82%)`, //new side-menu
        '&:hover': {
            filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
        },
    },
    closeIcon: {
        height: '1.8rem',
        width: '1.8rem',
        filter: `invert(22%) sepia(9%) saturate(0%) hue-rotate(181deg) brightness(0%) contrast(82%)`, //new side-menu
        '&:hover': {
            filter: 'invert(44%) sepia(200%) saturate(450%) hue-rotate(330deg) brightness(80%) contrast(290%)',
        },
    },
    collapseListBox: {
        // backgroundColor: 'pink',
        marginTop: '-12px',
        width: '90%',
        '& .MuiCollapse-wrapper': {
            display: 'flex',
            justifyContent: 'flex-end'
        },
        '& .MuiCollapse-wrapperInner': {
            width : '78%'
        },
    },
    collapseList: {
        // marginTop : 'px',
        borderLeft: '1px solid gray',
        // height: '7rem',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '0 !important',
        '& .MuiTypography-root': {
            fontFamily: 'Montserrat',
            fontSize: '0.7rem',
            letterSpacing: '0.2px',
            color: '#484848 !important',
            '&:hover': {
                fontWeight: "bold !important"
            },
        },
        '& > .MuiButtonBase-root': {
            paddingLeft: '0.5rem',
            minHeight: '2.3rem !important',
            '& > .MuiListItemText-root': {
                marginBottom: '0px !important'
            },
            '&:hover': {
                borderLeft: '3px solid #F4511F',
                '& .MuiListItemText-root > .MuiTypography-root': {
                    fontWeight: "bold !important"
                }
            }
        },
    }
}))

export default useStyles;