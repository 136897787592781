import React, { useEffect, useState } from 'react'

//mui
import { makeStyles } from "@mui/styles";

//components
import Title from '../../../title';
import CustomTextArea from '../../../custom-styled/custom-text-area';
import CustomInput from '../../../custom-styled/custom-input'
import ControlledSwitches from '../../../custom-styled/switch-button';

//constants
import constants from '../../../../constants';
import isEmpty from '../../../../utils/isEmpty';


const MTRR = ({
  type = 'ILA_MTRR_1',
  activeQuestion = {},
  ilaCardId,
  selectedQuestion,
  list,
  setList }) => {
  console.log('activeQuestion = ', activeQuestion);
  let allowed = ['ILA_MTRR_1', 'ILA_MTRR_2'];
  if (!allowed.includes(activeQuestion.designType)) {
    activeQuestion = {}
  }
  const classes = useStyles();
  const contextValidation = constants.ILA_VALIDATIONS[type]['context'];
  const questionValidation = constants.ILA_VALIDATIONS[type]['question'];
  const responseValidation = constants.ILA_VALIDATIONS[type]['response'];
  const [contextLimit, setContextLimit] = useState({ exceeded: false, limitText: `${contextValidation} CHARACTERS` })
  const questionData = new Array(4).fill({ exceeded: false, limitText: `${questionValidation} CHARACTERS` })
  const [questionLimit, setQuestionLimit] = useState(questionData);
  const responseData = new Array(4).fill({ exceeded: false, limitText: `${responseValidation} CHARACTERS` })
  const [responseLimit, setResponseLimit] = useState(responseData);


  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let length = activeQuestion.questionArr.length;
      console.log('length = ', length);
      let questions = new Array(length).fill({ exceeded: false, limitText: `${questionValidation} CHARACTERS` })
      let responses = new Array(length).fill({ exceeded: false, limitText: `${responseValidation} CHARACTERS` })

      for (let i = 0; i < length; i++) {
        let questionLen = activeQuestion.questionArr[i].question.length;
        let answerLen = activeQuestion.questionArr[i].answers[0].answer.length;
        if (questionLen > questionValidation) {
          questions[i] = { exceeded: true, limitText: `${questionLen - questionValidation} CHARACTERS EXCEEDED!` };
        } else {
          //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
          if (activeQuestion.questionArr[i].isQuestionEmpty) {
            questions[i] = { exceeded: false, limitText: `*Required` };
          } else {
            questions[i] = { exceeded: false, limitText: `${questionValidation - questionLen} CHARACTERS` };
          }
        }

        if (answerLen > responseValidation) {
          responses[i] = { exceeded: true, limitText: `${answerLen - responseValidation} CHARACTERS EXCEEDED!` };
        } else {
          //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
          if (activeQuestion.questionArr[i].emptyAnswers[0].isEmpty) {
            responses[i] = { exceeded: false, limitText: `*Required` };
          } else {
            responses[i] = { exceeded: false, limitText: `${responseValidation - answerLen} CHARACTERS` };
          }
        }

      }

      setQuestionLimit(questions);
      setResponseLimit(responses);



    }

  }, [activeQuestion])


  useEffect(() => {
    if (!isEmpty(activeQuestion)) {
      let contextLen = activeQuestion.contextObj.context.length;
      let contextTempLimit = {};
      if (contextLen > contextValidation) {
        contextTempLimit = { exceeded: true, limitText: `${contextLen - contextValidation} CHARACTERS EXCEEDED!` };
        setContextLimit(contextTempLimit);
      } else {
        //HERE LENGTH OF INPUT IS LESS THAN questionCharlimit CHARACTERS.
        if (activeQuestion.isContextEmpty) {
          contextTempLimit = { exceeded: false, limitText: `*Required` };
        } else {
          contextTempLimit = { exceeded: false, limitText: `${contextValidation - contextLen} CHARACTERS` };
        }
        setContextLimit(contextTempLimit);
      }
    }
  }, [activeQuestion])



  const handleContextChange = (e) => {
    let value = e.target.value;
    console.log(value.length);
    if (value.length > contextValidation) {
      setContextLimit({ exceeded: true, limitText: `${value.length - contextValidation} CHARACTERS EXCEEDED!` })
    } else {
      setContextLimit({ exceeded: false, limitText: `${contextValidation - value.length} CHARACTERS` })
    }
    let temp = [...list];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i].id === ilaCardId) {
        temp[i].games.questions[selectedQuestion].contextObj.context = value;
        temp[i].games.questions[selectedQuestion].isContextEmpty = false;
        temp[i].games.questions[selectedQuestion].status = 'inProgress';
        console.log('temp[i] = ', temp[i]);
        break;
      }
    }
    setList(temp);
  }

  const handleQuestionChange = (e, index) => {
    console.log('handleQuestionChange index = ', index);
    let value = e.target.value;
    console.log(value.length);
    let temp = [...questionLimit];
    if (value.length > questionValidation) {
      temp[index] = { exceeded: true, limitText: `${value.length - questionValidation} CHARACTERS EXCEEDED!` }
      console.log('handleQuestionChange temp =', temp);
      setQuestionLimit(temp)
    } else {
      temp[index] = { exceeded: false, limitText: `${questionValidation - value.length} CHARACTERS` }
      setQuestionLimit(temp)
    }
    let tempList = [...list];
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].id === ilaCardId) {
        tempList[i].games.questions[selectedQuestion].questionArr[index].question = value;
        tempList[i].games.questions[selectedQuestion].questionArr[index].isQuestionEmpty = false;
        tempList[i].games.questions[selectedQuestion].status = 'inProgress';
        console.log('tempList[i] = ', tempList[i]);
        break;
      }
    }
    setList(tempList);
  }

  const handleResponseChange = (e, index) => {
    console.log('handleResponseChange index = ', index);
    let value = e.target.value;
    console.log(value.length);
    let temp = [...responseLimit];
    if (value.length > responseValidation) {
      temp[index] = { exceeded: true, limitText: `${value.length - responseValidation} CHARACTERS EXCEEDED!` }
      setResponseLimit(temp)
    } else {
      temp[index] = { exceeded: false, limitText: `${responseValidation - value.length} CHARACTERS` }
      setResponseLimit(temp)
    }
    let tempList = [...list];
    for (let i = 0; i < tempList.length; i++) {
      if (tempList[i].id === ilaCardId) {
        tempList[i].games.questions[selectedQuestion].questionArr[index].answers[0].answer = value;
        tempList[i].games.questions[selectedQuestion].questionArr[index].emptyAnswers[0].isEmpty = false;
        tempList[i].games.questions[selectedQuestion].status = 'inProgress';
        console.log('tempList[i] = ', tempList[i]);
        break;
      }
    }
    setList(tempList);
  }

  return (
    <div className={classes.main}>
      <div className={classes.row}>
        <div className={classes.card}>
          {/* <Title titleText={'Match The Right Response'} /> */}
          <div className={classes.contextBox}>
            <CustomTextArea
              handleChange={handleContextChange}
              value={isEmpty(activeQuestion) ? '' : activeQuestion.contextObj.context}
              exceedText={contextLimit.limitText}
              isExceeded={contextLimit.exceeded}
              isEmpty={isEmpty(activeQuestion) ? false : activeQuestion.isContextEmpty}
            />
          </div>
          <div className={classes.titles}>
            <span>
              Questions
            </span>
            <span>
              Answers
            </span>
          </div>
          <div className={classes.responsesBox}>

            {
              responseLimit.map((ele, i) => (
                <div className={classes.questionResponseBox} key={i}>
                  <div className={classes.column1}>
                    <CustomTextArea
                      handleChange={(e) => handleQuestionChange(e, i)}
                      value={(isEmpty(activeQuestion) || i >= activeQuestion.questionArr.length) ? '' : activeQuestion.questionArr[i].question}
                      exceedText={questionLimit[i].limitText}
                      isExceeded={questionLimit[i].exceeded}
                      isEmpty={(isEmpty(activeQuestion) || i >= activeQuestion.questionArr.length) ? false : activeQuestion.questionArr[i].isQuestionEmpty}
                      fs='0.7rem'
                      p='0.4rem'
                      warningTextFs="0.47rem"
                      pb='0.8rem'
                    />
                  </div>
                  <div className={classes.column2}>
                    <CustomTextArea
                      handleChange={(e) => handleResponseChange(e, i)}
                      exceedText={responseLimit[i].limitText}
                      isExceeded={responseLimit[i].exceeded}
                      value={(isEmpty(activeQuestion) || i >= activeQuestion.questionArr.length) ? '' : activeQuestion.questionArr[i].answers[0].answer}
                      isEmpty={(isEmpty(activeQuestion) || i >= activeQuestion.questionArr.length) ? false : activeQuestion.questionArr[i].emptyAnswers[0].isEmpty}
                      fs='0.7rem'
                      p='0.4rem'
                      warningTextFs="0.47rem"
                      pb='0.8rem' />
                  </div>
                </div>
              ))
            }


          </div>
        </div>
      </div>
    </div>
  )
}

export default MTRR;

const useStyles = makeStyles(() => ({
  main: {
    // backgroundColor : 'red',
    height: '94%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    // border : '1px solid black',
    height: '100%',
    width: '100%',
  },
  card: {
    aspectRatio: 0.87,
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '1.8rem 1rem',
    '& > h2': {
      marginTop: '1rem',
      fontSize: '1.6rem',
      color: 'rgb(0,0,0, 0.8)'
    }
  },
  contextBox: {
    // border : '1px solid black',
    width: '100%',
    height: '33%',
    padding: '0 0.5rem',
    // marginTop: '3rem',
  },
  titles: {
    display: 'flex',
    gap: '2rem',
    marginTop: '1rem',
    padding: '0 0.5rem',
    '& > span': {
      width: '50%',
      fontSize: '1.2rem',
      fontWeight: 800,
      fontFamily: 'montserrat',
      color: 'rgb(0,0,0,0.8)'
    }
  },
  responsesBox: {
    // border: '1px solid',
    height: '56%',
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '0.5rem',
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#f4511e',
      borderRadius: '12px',
    }
  },
  questionResponseBox: {
    display: 'flex',
    height: '20%',
    minHeight: '3.6rem',
    gap: '2rem'
  },
  column1: {
    width: '50%',
  },
  column2: {
    width: '50%',
  },
}));