import React from 'react'
import { makeStyles } from "@mui/styles";
import DeleteIcon from '@mui/icons-material/Delete';

const UploadedFiles = ({ fileName, setFileUpload, width = '100%', }) => {
    const classes = useStyles();

    const handleDeleteFile = () => {
        setFileUpload(null);
    }

    return (
        <div className={classes.main} style={{ width }}>
            <div className={classes.customDragDrop}>
                <DeleteIcon style={{ color: "#F4511E" }} onClick={handleDeleteFile} />
                <h3>{fileName}</h3>
            </div>
        </div>
    );
}

export default UploadedFiles;



//  className={classes.mainContainer}

const useStyles = makeStyles(() => ({
    main: {
        height: '100%',
        // width: '100%',
        minHeight: "10rem",
        position: 'relative',
        '& label': {
            height: '100%',
            // position : 'relative',
            zIndex: '1',
            width: '100%',
            opacity: '0'
        },
        '&:hover': {
            backgroundColor: 'rgb(112, 112, 112, 0.01)'
        }
    },
    customDragDrop: {
        cursor: 'pointer',
        position: 'absolute',
        top: '0',
        // zIndex : '0',
        border: '2px dashed rgb(112, 112, 112, 0.3)',
        borderRadius: '7px',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding : '1rem 0.1rem',
        // paddingTop : '10%',
        '& img': {
            height: '1.3rem',
            width: '2rem',
        },
        '& h3,h6,h4,h5': {
            fontFamily: 'Montserrat',
            paddingTop: '0.7rem'
        },
        '& h3': {
            fontSize: '0.6rem',
            color: 'rgb(112, 112, 112, 0.9)'
        },
        '& h6': {
            fontSize: '0.57rem',
            color: 'rgb(112, 112, 112, 0.7)'
        },
        '& h4': {
            fontSize: '0.6rem',
            color: '#f4511e',
            textDecoration: 'underline'
        },
        '& h5': {
            fontSize: '0.6rem',
            color: 'rgb(112, 112, 112, 0.9)'
        },


    },
    externalLinkInput: {
        border: '1.4px solid #ccc',
        borderRadius: '4px',
        position: 'relative',
        zIndex: 99999,
        color: "rgb(112, 112, 112, 0.6)",
        // fontFamily: "Montserrat",
        fontSize: "0.7rem",
        fontWeight: "500",
        padding: '0.4rem',
        marginTop: '0.9rem',
        width: '82%'
    },
    captureExternalLinkInput: {
        border: '1px solid #f4511e',

    }
}));

// export default useStyles;
