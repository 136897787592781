import React from 'react';
import Button2 from './components/Button2';
import FormControl from './components/FormControl';
// import { Dialog } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

//new
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';

export default function MSDialog(props) {
  const { open, setOpen } = props;
  const { message } = props;
  const { leftButtonAction, rightButtonAction } = props;
  const { leftButtonTitle, rightButtonTitle } = props;
  const classes = custom_styles();
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}>
      <ProgressContainer>
        <ProgressText>{message}</ProgressText>
        <ProgressText style={{ justifyContent: 'flex-end', width: '100%' }}>
          <FormControl>
            <Actions>
              <Button2
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  leftButtonAction();
                }}>
                {leftButtonTitle}
              </Button2>
              <div className={classes.bottomButtonStyle}>
                <Button2
                  onClick={rightButtonAction}
                  transparent={true}
                  style={{ marginLeft: '5px'}}
                  >
                  {rightButtonTitle}
                </Button2>
              </div>
            </Actions>
          </FormControl>
        </ProgressText>
      </ProgressContainer>
    </Dialog>
  );
}

const custom_styles = makeStyles({
  bottomButtonStyle: {
    ['@media (max-width:780px)']: {
      marginTop: 10,
    },
  },
});

const ProgressContainer = styled.div`
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProgressText = styled.h5`
  color: #707070;
  font-size: 1rem;
  text-align: center;
  margin: 10px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
