import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import useStyles from './index.styles'

//  components
import Title from '../title';

//  mui
import { FormGroup } from 'react-bootstrap';
import { FormControlLabel } from '@mui/material';
import { Checkbox } from "@mui/material"

//  services
import formServices from '../../services/form';

//  utils
import useRouter from '../../utils/useRouter';
import FailureDialog from '../failure-dialog';

const CreateNewForm = ({
    title,
    lables,
    placeholder = '',
    btnText,
    companyId
}) => {
    const styles = useStyles();
    const router = useRouter();

    const [isAssessment, setAssessment] = useState(false);
    const [isTime, setTime] = useState(false);
    const [showScore, setShowScore] = useState(false);
    const [formDescription, setFormDescription] = useState('');
    const [timeValue, setTimeValue] = useState(60);
    const [formId, setFormId] = useState('');

    const [failure, setFailure] = useState(false);

    useEffect(() => {
        if (formId !== '') {
            router.history.push(`/forms?c_id=${companyId}&formId=${formId}`);
        }
    }, [formId])

    const handleAssessment = () => {
        setAssessment(!isAssessment);
    }

    const handleAddTime = () => {
        setTime(!isTime);
    }

    const handleShowScore = () => {
        setShowScore(!showScore);
    }

    const handleFormDescription = (e) => {
        setFormDescription(e.target.value);
    }

    const handleTimeValue = (e) => {
        setTimeValue(e.target.value)
    }

    const createForm = async (data) => {
        const resp = await formServices.createForm(data);
        if (resp.status === 200 || resp.status === 201) {
            let respData = resp.data.data;
            setFormId(respData.id);
        }
    }

    const submitData = (e) => {
        e.preventDefault();
        if (companyId === '') {
            setFailure(true);
            return;
        }

        let target = e.target.elements;
        let data = {}
        data["companyId"] = companyId;
        data["name"] = target.FormName.value;

        if (formDescription !== '') {
            data["description"] = formDescription;
        }

        if (isAssessment) {
            data["assessment"] = isAssessment;
        }

        if (isTime && isAssessment) {
            data["timeLimit"] = parseInt(timeValue);
        }
        else if (isAssessment) {
            data["timeLimit"] = parseInt(60);
        }


        if (showScore) {
            data["showScore"] = showScore
        }

        data["questions"] = [];
        createForm(data);
    }


    return (
        <div className={styles.CreateCompany_Container}>
            <div className={styles.Left_div}>
                <div className={styles.Inside_Left_div}>
                    <Title titleText={title} infoIcon={true} />

                    <form onSubmit={submitData}>
                        <div>
                            <label htmlFor='FormName' className={styles.Label}>
                                Form Name
                            </label>
                            <input
                                className={styles.Input_Box}
                                id="FormName"
                                name="FormName"
                                type="text"
                                minLength="2"
                                maxLength="60"
                                placeholder={placeholder}
                                required
                            />
                        </div>

                        <div>
                            <label htmlFor='FormDescription' className={styles.Label}>
                                Form Description
                            </label>
                            <textarea
                                className={styles.Input_Box}
                                name="FormDescription"
                                id="FormDescription"
                                style={{ minHeight: "10rem" }}
                                maxLength={200}
                                onChange={handleFormDescription} />
                        </div>

                        <div className={styles.CheckBoxContainer}>
                            <FormGroup>
                                <FormControlLabel
                                    className={styles.CheckBox}
                                    control={
                                        <Checkbox
                                            style={{ color: "#F4511E" }}
                                            onChange={handleAssessment}
                                            checked={isAssessment}
                                        />
                                    }
                                    label="Assessment"
                                />
                            </FormGroup>
                        </div>

                        {isAssessment && (
                            <div style={{ display: "flex", flexDirection: "column", margin: "0.8rem 0rem", justifyContent: "flex-start" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        className={styles.CheckBox}
                                        control={
                                            <Checkbox
                                                style={{ color: "#F4511E" }}
                                                onChange={handleShowScore}
                                                checked={showScore}
                                            />
                                        }
                                        label="Show Score to Learner"
                                    />

                                    <div style={{ display: "flex" }}>
                                        <FormControlLabel
                                            className={styles.CheckBox}
                                            control={
                                                <Checkbox
                                                    style={{ color: "#F4511E" }}
                                                    onChange={handleAddTime}
                                                    checked={isTime}
                                                />
                                            }
                                            label="Time Limit(Seconds)"
                                        />
                                        <input
                                            className={styles.Input_Box}
                                            type="number"
                                            defaultValue="60"
                                            disabled={!isTime}
                                            onChange={handleTimeValue}
                                        />
                                    </div>
                                </FormGroup>
                            </div>
                        )}
                        <button className={styles.Save_Btn} type="Submit">
                            Create Form
                        </button>
                    </form>

                    {companyId === '' && (
                        <FailureDialog
                            setErrorDialogOpen={setFailure}
                            errorDialogOpen={failure}
                            dialogText='Please Select Company'
                            handleClick={() => setFailure(false)}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CreateNewForm