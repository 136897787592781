import React from "react";
import QRCode from "react-qr-code";

const QrCodeGenerator = ({ value }) => {

    return (
        <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={value}
            viewBox={`0 0 256 256`}
        />
    );
}

export default QrCodeGenerator;

// import React from "react";
// import "./styles.css";
// import QRCode from "react-qr-code";
// import useSWR from "swr";

// const QrCodeGenerator = () => {
//     const { data, error } = useSWR("/api/user", fetch);

//     if (error) return <div>failed to load</div>;
//     if (!data) return <div>loading...</div>;

//     return (
//         <div className="App">
//             <h1>Hotspot app</h1>
//             <h2>Visitors, scan the following qr code</h2>
//             <QRCode value="www.youtube.com" size="250" />
//         </div>
//     );
// }

// export default QrCodeGenerator;