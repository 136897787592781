import React from 'react'

const Disclaimer = (props) => {
const {
    w = '100%',
    h = 'auto',
    p = '1rem',
    fs = '0.6rem',
    fw = 500,
    lh = 1.5,
    ff = 'Montserrat',
    mt=0,
    bgColor='#FFFFFF'
} = props;
    const style = {
        width: w,
        height: h,
        padding: p,
        fontSize: fs,
        fontFamily: ff,
        color: '#000000',
        fontWeight: fw,
        lineHeight: lh,
        backgroundColor: bgColor,
        marginTop:mt,
        ...props
    }

    const clientName = localStorage.getItem('company-name') || 'Master-O';


    return (
        <div style={style}><b>Disclaimer:</b> {clientName} & it’s employees are solely responsible for the content published in the platform. InspireOne Technologies Pvt Ltd (referred henceforth as “Master-O”) shall not be held responsible or liable for the quality and / or quantity of the content uploaded, included but not limited to any errors, omissions, harmful or abusive content or any resulting loss or damages incurred by the {clientName} on behalf of any content that is published on the Master-O platform.</div>
    )
}

export default Disclaimer