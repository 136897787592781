import { authActionTypes } from "./auth.types";

const INITIAL_STATE = {
    currentUser: null,
    isLoading: false,
    error: null,
    analyticsToken: null,
}
const authReducer = (state = INITIAL_STATE, action) => {
    // console.log('authReducer state = ',state );
    // console.log('authReducer action = ',action );
    switch (action.type) {

        case authActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        case authActionTypes.SET_ANALYTICS_TOKEN:
            return {
                ...state,
                analyticsToken: action.payload
            }
        case authActionTypes.LOGOUT_USER:
            return {
                ...state,
                currentUser: action.payload
            }
        default:
            return state;
    }
}

export default authReducer