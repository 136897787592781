import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    label: {
        // padding: '1rem 0rem',
        color: "rgb(0, 0, 0, 0.9)",
        fontFamily: "Montserrat",
        // fontSize: "0.8rem",
        fontWeight: "700",
    },
}));

const Label = ({labelText, fs='0.7rem'}) => {
    const classes = useStyles();
  return (
    <label className={classes.label} style={{fontSize: fs,}}>{labelText}</label>
  )
}

export default Label;


