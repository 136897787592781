import React from 'react'

//styles-common
import commonStyles from './index.styles';

const Graphs = () => {
  const styles = commonStyles();
  return (
    <div className={styles.unavailable}>
       CURRENTLY UNAVAILABLE!
    </div>
  )
}

export default Graphs
