import * as React from 'react';

//mui
import { makeStyles } from "@mui/styles";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteForever from '@mui/icons-material/DeleteForever';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';


export default function ConfirmDialog({
    open,
    setOpen,
    isDelete = true,
    title = 'Confirmation',
    description,
    leftBtnText = 'Cancel',
    leftBtnClick,
    rightBtnText = 'Delete',
    rightBtnClick,
    isSaving = false
}) {
    const boxStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30%',
        minHeight: '25%',
        bgcolor: 'background.paper',
        padding: '1.5rem 1.4rem',
        border: '1px solid rgb(206 206 206 / 50%)',
        boxShadow: 24,
        // border: 'none',
        borderRadius: '10px',
        outline: 'none',

    }
    return (
        <React.Fragment>
            {/* <Button
        variant="outlined"
        color="danger"
        endDecorator={<DeleteForever />}
        onClick={() => setOpen(true)}
      >
        Discard
      </Button> */}
            <Modal open={open} onClose={() => {
                return
                setOpen(false)
            }} sx={{
                '& > .MuiBackdrop-root ': {
                    backgroundColor: 'rgb(255 255 255 / 70%)',
                    backdropFilter: 'blur(8px)'
                }
            }}>
                <Box sx={boxStyle}>
                    <Typography
                        id="alert-dialog-modal-title"
                        component="h2"
                        sx={{
                            // border: '1px solid',
                            fontSize: '1.25em',
                            display: 'flex',
                            alignItems: 'center',
                            fontFamily: 'Montserrat',
                            fontWeight: 700,
                            gap: '0.5rem',
                            marginBottom: '0.5rem'
                        }}
                    >
                        <WarningRoundedIcon /> {title}
                    </Typography>
                    <Divider />
                    <Typography
                        id="alert-dialog-modal-description"
                        textcolor="text.tertiary"
                        sx={{
                            fontSize: '1em',
                            color: 'rgb(0,0,0,0.7)',
                            fontFamily: 'Montserrat',
                            fontWeight: 500,
                            margin: '0.6rem 0',
                            lineHeight: 1.5,
                            textAlign: 'start'
                        }}>
                        {description}
                    </Typography>
                    <Box sx={{
                        display: 'flex', gap: 1, justifyContent: 'flex-end', pt: 2,
                        '& .MuiButtonBase-root': {
                            fontSize: '0.8em',
                            fontFamily: 'Montserrat',
                            fontWeight: 600,
                        }
                    }}>
                        <Button variant="plain" color="neutral" onClick={leftBtnClick} >
                            {leftBtnText}
                        </Button>
                        {isSaving ?
                            <LoadingButton
                                loading
                                loadingPosition="center"
                                startIcon={<SaveIcon />}
                                variant="filled"
                                sx={{
                                    backgroundColor: '#e6e6e6',
                                    minWidth: '90px'
                                }}
                            >
                                
                            </LoadingButton>
                            : <Button variant="contained" color='error' onClick={rightBtnClick} sx={{minWidth: '80px'}}>
                                {rightBtnText}  
                                {isDelete ? <DeleteForever sx={{
                                    paddingLeft: '0.2rem'
                                }} /> : null}
                            </Button>}
                    </Box>
                </Box>
            </Modal>
        </React.Fragment>
    );
}
