import React, { useState, useRef } from 'react';
import styled from 'styled-components';

export const Box = styled.div`
  width: ${(props) => (props.width ? props.width : '320px')};
  max-width: 100%;
  min-height: 90.3px;
  border: ${(props) => (props.border ? props.border : 'solid 0.5px #dde0e2')};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
`;

export const StyledTextArea = styled.textarea`
  border: none;
  width: 100%;
  background-color: transparent;
  display: block;
  height: 100%;
  margin: 0;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
  flex: 1;
  box-sizing: border-box;
  resize: ${(props) => (props.resize ? props.resize : 'auto')};

  &:focus {
    border: none;
    outline: 0 !important;
  }

  %:active {
    border: none;
  }
`;

export default function TextArea(props) {
  let inputRef = useRef(null);

  let text = '';

  if (props.hasOwnProperty('text')) {
    text = props.text;
  }

  let resize = 'auto';

  if (props.hasOwnProperty('resize')) {
    resize = props.resize;
  }

  function handleInput() {
    if (inputRef.hasOwnProperty('current') && inputRef.current !== null) {
      if (props.hasOwnProperty('onChangeText')) {
        props.onChangeText(inputRef.current.value);
      }
    }
  }
  return (
    <Box width={props.width} border={props.border}>
      <StyledTextArea
        resize={props.resize}
        ref={inputRef}
        onChange={handleInput}>
        {text}
      </StyledTextArea>
    </Box>
  );
}
