import { authFetch } from "../utils/fetch";

const fetchSkills = async (companyId, value = '') => {
    let url = `/skill?startIndex=0&limit=100&sort=createdOn&sortDirection=asc&companyIds=${companyId}`
    if (value !== '') {
        url += `&search=${value}`
    }
    try {
        const resData = await authFetch.get(url);
        return resData
    } catch (err) {
        return err.response
    }
}

const createSkill = async (payload = {}) => {
    try {
        const respData = await authFetch.post('/skill', payload);
        return respData;
    }
    catch (err) {
        return err.response;
    }
}

const removeMicroskillSkill = async (payload) => {
    try {
        const respData = await authFetch.post('/microskill/remove-skill', payload);
        return respData;
    }
    catch (err) {
        return err.response;
    }
}

const addMicroskillSkill = async (payload) => {
    try {
        const respData = await authFetch.post('/microskill/add-skill', payload);
        return respData;
    } catch (err) {
        return err.response;
    }
}

const getMicroskillSkill = async (id) => {
    try {
        const respData = await authFetch.get(`/microskill/get-skills/${id}`);
        return respData;
    } catch (err) {
        return err.response;
    }
}


export default { fetchSkills, createSkill, removeMicroskillSkill, addMicroskillSkill, getMicroskillSkill }