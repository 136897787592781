import { makeStyles } from "@mui/styles";
import constants from "../../constants";

const useStyles = makeStyles(() => ({
  main: {
    // borderTop: '1px solid #70707050',
    // borderLeft: '1px solid #70707050',
    backgroundColor: '#fff',
    minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
    padding: '2rem 5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',

  },
  row1: {
    height: '20%',
    '& > form': {
      boxShadow: '0px 1px 10px 0px rgba(0,0,0,0.2)',
      '-webkit-box-shadow': '0px 1px 10px 0px rgba(0,0,0,0.2)',
      '-moz-box-shadow': '0px 1px 10px 0px rgba(0,0,0,0.2)',
      height: '2.6rem'
    }
  },
  row2: {
    // border: '1px solid',
    // height: '80%'
  },
  activeUser: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    zIndex: '999999999999999999999',
    top: '0.8rem',
    right: '20rem',
  },
  totalUser: {
    fontSize: '1.2rem',
    fontWeight: '900',
    marginRight: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Montserrat',
  },
  userProgress: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '0.3rem',
  },
  learnerText: {
    color: '#000000CC',
    fontSize: '0.8rem',
    fontFamily: 'Montserrat',
    fontWeight: '500',
  },
}));


export default useStyles;
