import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    mainContainerDraglist: {
        height: 'calc(100% - (3.8rem + 4rem))',
        overflowX: 'hidden',
        overflowY: 'auto',
        backgroundColor: '#fff',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px'
        },
    },
    coverAndObjectiveLi: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.6rem',
        borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
        // border : '1px solid',
        '& > div': {
            backgroundColor: '#fff',
            // borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
            height: '6rem',
            padding: '0rem 0.4rem',
            '& img': {
                height: '4.5rem',
                aspectRatio: 1 / 1
            }
        },
    },
    listItemLa: {
        // backgroundColor : '#fff',
        marginBottom: '-10px',
        borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        padding: '0.7rem 0.6rem',
        '& > i': {
            width: '3%',
            cursor: 'pointer',
            fontSize: '0.9rem',
            color: '#f4511e'
        },
        '&:hover': {
            backgroundColor: 'rgba(204, 204, 204, 0.3) !important'
        }

    },
    column2: {
        width: '93%',
        display: 'flex',
        gap: '0.7rem',
        cursor: 'pointer',
        '& > img': {
            border: '1px solid rgb(112, 112, 112, 0.1) ',/* Gray border */
            borderRadius: '4px ', /* Rounded border */
            // padding: '5px', /* Some padding */
            height: '4.5rem',
            width: '4.5rem', /* Set a small width */
            '&:first-child':{
                objectFit: 'cover'
                
            }
        }
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.7rem',
        width: '40%',
        '& h4': {
            fontSize: '0.7rem',
            fontFamily: 'Montserrat',
            color: '#484848'
        },
        '& h5': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.64rem',
            fontStyle: 'italic',
            color: 'rgb(72, 72, 72, 0.5)',
            fontWeight: '600',
            '& i': {
                fontSize: '0.7rem',
                marginLeft: '0.5rem',
                // paddingTop : '0.5rem',
                color: '#f4511e'
            },
        },
        '& h6': {
            fontSize: '0.6rem',
            color: 'rgb(72, 72, 72, 0.7)'
        },
    },
    buttonsBox: {
        width: '33%',


    },
    editIcon: {
        position: 'absolute',
        right: '2.2rem',
        border: 'none !important',
        top: '0.7rem',
        height: '0.8rem !important',
        width: '0.8rem !important',
        transition: '0.5s scale',
        '&:hover': {
            scale: '1.2',
            filter: 'invert(44%) sepia(28%) saturate(6975%) hue-rotate(349deg) brightness(97%) contrast(97%)'
        }
    },
    deleteIcon: {
        position: 'absolute',
        border: 'none !important',
        right: '0.6rem',
        top: '0.7rem',
        height: '0.8rem !important',
        width: '0.8rem !important',
        transition: '0.5s scale',
        '&:hover': {
            scale: '1.2',
            filter: 'invert(44%) sepia(28%) saturate(6975%) hue-rotate(349deg) brightness(97%) contrast(97%)'
        }
    },
    duplicateDelete: {
        height: '63%',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '1rem',
        '& img': {
            height: '0.8rem',
            width: '0.8rem',
            '&:hover': {
                filter: 'invert(44%) sepia(28%) saturate(6975%) hue-rotate(349deg) brightness(97%) contrast(97%)'
            }
        },

    },
    addInteractionBtn: {
        // width: '120px',
        // height: '28px',
        // aspectRatio: 16 / 9,
        textAlign: 'end',
        position: 'absolute',
        right: '0.6rem',
        bottom: '0.7rem',
        '& .MuiButtonBase-root': {
            borderRadius: '3px',
            fontSize: '0.5rem',
            height: '100%',
            fontWeight: 800,
            padding: 0,
            width: '120px',
            height: '28px',
        },
        '& .MuiButton-filled': {
            '&:hover': {
                backgroundColor: '#fff',
                color: '#f4511e',
                borderColor: '#f4511e'
            }
        }
    },


    //LA LIST ITEM STYLES

    listItemLaLI: {
        // backgroundColor : '#fff',
        marginBottom: '-10px',
        borderBottom: '1px solid rgb(112, 112, 112, 0.2)',
        display: 'flex',
        alignItems: 'center',
        gap: '0.8rem',
        padding: '0.7rem 0.6rem',
        backgroundColor: 'rgb(149, 152, 154, 0.1)',
        // '& > i': {
        //     width: '3%',
        //     cursor: 'pointer',
        //     fontSize: '0.9rem',
        //     color: '#f4511e'
        // },
        '&:hover': {
            backgroundColor: 'rgba(204, 204, 204, 0.3) !important'
        }

    },
    columnLI: {
        // border: '1px solid',
        position: 'relative',
        width: '93%',
        display: 'flex',
        gap: '0.7rem',
        cursor: 'pointer',
        marginLeft: '7%',
        '& > img': {
            border: '1px solid rgb(112, 112, 112, 0.1) ',/* Gray border */
            borderRadius: '4px ', /* Rounded border */
            // padding: '5px', /* Some padding */
            height: '4.5rem',
            width: '4.5rem', /* Set a small width */
            '&:first-child':{
                objectFit: 'cover'
            }
        }
    },
    cardDetailsLI: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '0.7rem',
        width: '100%',
        '& h4': {
            fontSize: '0.7rem',
            fontFamily: 'Montserrat',
            color: '#484848'
        },
        '& h5': {
            display: 'flex',
            alignItems: 'center',
            fontSize: '0.64rem',
            fontStyle: 'italic',
            color: 'rgb(72, 72, 72, 0.5)',
            fontWeight: '600',
            '& i': {
                fontSize: '0.7rem',
                marginLeft: '0.5rem',
                // paddingTop : '0.5rem',
                color: '#f4511e'
            },
        },
        '& h6': {
            fontSize: '0.6rem',
            color: 'rgb(72, 72, 72, 0.7)'
        },
    },
    buttonsBoxLI: {
        // border: '1px solid blue',
        width: '33%',
        marginRight: '0.2rem'

    },
    duplicateDeleteLI: {
        // border: '1px solid',
        position: 'absolute',
        right: 0,
        top: '0.7rem',
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '0.77rem',
        '& > img': {
            height: '0.8rem !important',
            width: '0.8rem !important',
            transition: '0.5s scale',
            '&:hover': {
                scale: '1.1',
                filter: 'invert(44%) sepia(28%) saturate(6975%) hue-rotate(349deg) brightness(97%) contrast(97%)'
            }
        },

    },
}));

export default useStyles;