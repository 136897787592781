import React from 'react'

//components
import CustomButton from '../../../buttons';

//utils
import commonUtil from '../../../../utils/commonUtil';

//styles
import useStyles from './index.styles';

const Listitem = (props) => {
    const { item, itemSelected, dragHandleProps, commonProps } = props;
    // console.log('item = ', item);
    const classes = useStyles();
    //HERE IN commonProps WE CAN USE PROPS PASSED THROUGH DraggableList COMPONENT!
    const {
        list,
        setPreview,
        isDragging,
        setIsDragging,
        selectedCard,
        setSelectedCard,
        handleAddInteraction,
        handleManageInteraction,
        handleDeleteCard,
        isActive,
        setIsActive,
        coverAndObjective,
        handleEdit,
        handleThumbImgClick } = commonProps;
    const { onMouseDown, onTouchStart } = dragHandleProps;



    const getCardNumber = () => {
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === item.id) {
                return (i + coverAndObjective.length + 1)
            }
        }
    }



    //RETURNS LIST ITEM
    return (
        <>
            <div key={item.id} id={`#${item.id.toString()}`} style={{ backgroundColor: (isDragging === item.id || isActive === item.id) ? "rgb(204, 204, 204, 0.3)" : "#fff" }} className={`${classes.listItemLa}`}>
                <i className="fa-solid fa-grip-vertical"
                    onMouseDown={(e) => {//IS CALLED WHEN DRAG STARTS
                        setIsDragging(item.id)
                        // document.body.style.overflow = "hidden";
                        onMouseDown(e);
                    }}
                    onMouseUp={() => { //IS CALLED WHEN DRAG ENDS
                        // console.log("drag end");
                        // setIsDragging(false)
                        document.body.style.overflow = "visible";
                    }}
                    onTouchStart={(e) => {
                        e.preventDefault();
                        document.body.style.overflow = "hidden";
                        onTouchStart(e);
                    }}
                    onTouchEnd={(e) => {
                        document.body.style.overflow = "visible";
                    }}>
                </i>
                <div className={classes.column2} onClick={(e) => handleThumbImgClick(e,item)}>
                    <img src={item.thumbImage} alt='' />
                    <div className={classes.cardDetails} >
                        <h4>Card {getCardNumber()}</h4>
                        <h5>{item.cardType}</h5>
                        <h6>{commonUtil.formatBytes(item.size)}</h6>
                    </div>

                    <img className={classes.editIcon} src='/images/icons/pencil-edit-button.svg' alt='' onClick={(e) => handleEdit(e, item)} />
                    <img className={classes.deleteIcon} src='/images/icons/delete-photo.svg' alt='' onClick={(e) => handleDeleteCard(e, item)} />

                    <div className={classes.addInteractionBtn}>
                        {
                            item.games.questions.length ?
                                <CustomButton
                                    btnText={'Manage Interaction'}
                                    handleClick={(e) => handleManageInteraction(e, item)}
                                    variant='filled'
                                    textColor='#fff'
                                    borderColor='#f4511e'
                                    bgcolor='#f4511e'

                                />
                                : <CustomButton btnText={'Add Interaction'} handleClick={(e) => handleAddInteraction(e, item)} />
                        }

                    </div>


                </div>
            </div>
        </>
    );
};
export default Listitem;