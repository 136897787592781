import React, { useCallback } from 'react'

//mui
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

//components
import Label from '../../../label';
import CustomInput from '../custom-input';

//styles
import { makeStyles } from "@mui/styles";
import { useEffect } from 'react';


import { CHECKBOX_TYPES } from './index';


const McqResponse = ({
    listitem,
    isUnChecked,
    isDisableAll,
    mcqResponses,
    responsesCharLimit,
    numberOfResponses = 4,
    handleResponseChange,
    handleRadioChange
}) => {
    const classes = useStyles();
    const data = Array(numberOfResponses).fill(null).map((item, index) => ({ exceeded: false, limitText: `${responsesCharLimit} Characters` }));
    // console.log('McqResponse data = ', data);
    const [responsesValidations, setResponsesValidations] = React.useState(data);
    useEffect(() => {
        let temp = [...responsesValidations];
        mcqResponses.forEach((element, id) => {
            let value = element.option;
            if (value.length > responsesCharLimit) {
                //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
                temp[id] = { exceeded: true, limitText: `${value.length - responsesCharLimit} Characters Exceeded` };
            } else {
                //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
                if (listitem.emptyAnswers[id].isEmpty) {
                    temp[id] = { exceeded: false, limitText: `*Required` };
                } else {
                    temp[id] = { exceeded: false, limitText: `${responsesCharLimit - value.length} Characters` };
                }
            }
        });
        console.log('McqResponse temp = ', temp);
        setResponsesValidations(temp);
    }, [mcqResponses, listitem])


    const handleInputChange = (e) => {

        let value = e.target.value;
        let id = parseInt(e.target.id);
        handleResponseChange(id, value)

        //SET WARNING IF LIMIT EXCEEDS
        let temp = [...responsesValidations];
        if (value.length > responsesCharLimit) {
            //HERE LENGTH OF INPUT IS GREATER THAN responsesCharLimit CHARACTERS.
            temp[id] = { exceeded: true, limitText: `${value.length - responsesCharLimit} Characters Exceeded` };
            setResponsesValidations(temp);
        } else {
            //HERE LENGTH OF INPUT IS LESS THAN responsesCharLimit CHARACTERS.
            temp[id] = { exceeded: false, limitText: `${responsesCharLimit - value.length} Characters` };
            setResponsesValidations(temp);
        }
    }
    const checkIfDisabled = (index) => {
        if (isDisableAll) return true
        let disable = isDisableAll;
        if (index === 2) {
            disable = mcqResponses[index].option === CHECKBOX_TYPES.NONE_OF_THE_ABOVE
        }
        if (index === 3) {
            disable = mcqResponses[index].option === CHECKBOX_TYPES.ALL_OF_THE_ABOVE
        }
        return disable
    }

    return (
        <>
            <Label labelText={'Responses:'} fs={'0.7rem'} />
            {data.map((ele, index) => (
                <div key={index} className={classes.main}>
                    <div className={classes.column1}>
                        <CustomInput
                            id={index.toString()}
                            isDisabled={checkIfDisabled(index)}
                            defaultValue={mcqResponses[index].option}
                            handleChange={(e) => handleInputChange(e)}
                            limitText={responsesValidations[index].limitText}
                            isExceeded={responsesValidations[index].exceeded}
                            isEmpty={listitem.emptyAnswers[index].isEmpty}
                        />
                    </div>
                    <div className={classes.column2}>
                        <FormControl>
                            <RadioGroup
                                className={classes.radioGroup}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                            >
                                <FormControlLabel value="Incorrect"
                                    className={`${isUnChecked(index) ? classes.leftUnchecked : ""}`}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                            color: '#f4511e'
                                        },
                                    }}
                                    control={<Radio
                                        checked={mcqResponses[index].value}
                                        onChange={handleRadioChange}
                                        value={index.toString()}
                                        name="radio-buttons"
                                    // inputProps={{ 'aria-label': 'A' }}
                                    />} label={mcqResponses[index].value ? "Correct" : "Incorrect"} />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            ))}

        </>
    )
}

export default McqResponse;



const useStyles = makeStyles(() => ({
    main: {
        display: 'flex',
        paddingTop: '0.4rem'
    },
    column1: {
        width: '87%',

    },
    column2: {
        width: '13%',
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'center'
    },
    radioGroup: {
        '& .MuiFormControlLabel-root': {
            margin: '0',

            '& .MuiTypography-root': {
                color: 'rgb(112, 112, 112, 0.8)',
                // fontFamily: "Montserrat",
                fontSize: "0.66rem",
                fontWeight: "600",
            },
            '& .MuiButtonBase-root': {
                padding: '0.4rem',
                margin: '0 0.3rem'
            }
        }
    },
    leftUnchecked: {
        '& .MuiSvgIcon-root': {
            color: 'red !important'
        },
        '& .MuiTypography-root': {
            fontWeight: '700 !important',
            color: 'red !important'
        }
    }


}));