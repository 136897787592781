import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    MainContainer: {
        display: "flex",
    },
    FormQuestionContainer: {
        padding: "1rem 0.5rem",
        width: "50%",
        height: "90vh",
        overflow: "auto",
        overflowX: "auto",
        '&::-webkit-scrollbar': {
            width: '6px'
            // display: 'none'
        },
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                background: '#f5411e',
                borderRadius: '6px'
            }
        }
    },
    FormQuestionHeading: {
        background: "rgb(221, 224, 226, 0.5)",
        padding: "0.6rem",
        marginBottom: "0.5rem",
        color: "#707070",
        fontWeight: "bold",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "0.8rem",
        cursor: 'pointer',
    },

    dropDownIcon: {
        color: '#f4511e',
        fontSize: '0.8rem',
        marginRight: '1rem'
    },

    validatedQuestionIcon: {
        color: "#15B725 !important",
        fontSize: '0.8rem',
        marginRight: '0.4rem'
    },

    unValidatedQuestionIcon: {
        color: '#f4511e',
        fontSize: '0.8rem',
        marginRight: '0.4rem'
    },

    SecondConatiner: {
        height: '90vh',
        width: '20%',
        padding: "1rem 0.5rem",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    AddButtons: {
        width: "25%",
        color: "#FFF",
        background: "#F4511E",
        display: 'flex',
        alignItems: "center",
        border: "1px solid #F4511E",
        padding: "0.8rem 2rem",
        textTransform: "none",
        '&:hover': {
            color: "#FFF",
            background: "#F4511E",
        }
    },
    Input_Box: {
        fontFamily: "Open Sans",
        fontSize: "11px",
        fontWeight: "400",
        display: "block",
        width: "90%",
        padding: "0.5rem",
        margin: "0.5rem 0",
        border: "none",
        // boxShadow: "0 0 6px #DDE0E2",
        border: "1.4px solid #70707050",
        color: "#707070",
        fontSize: "0.8rem",
        marginBottom: '3rem'
    },
    Label: {
        display: "block",
        fontFamily: "Montserrat",
        color: "#484848",
        fontSize: "1rem",
        fontWeight: "Bold",
        characterSpacing: "0",
        padding: "0.6rem 0"
    },
    formOptionContainer: {
        width: '80%',
        justifyContent: 'space-between',
        color: '#484848',
        fontWeight: '700',
        flexWrap: 'wrap',
        rowGap: '1rem'
    },
    timeLimitContainer: {
        justifyContent: 'flex-start'
    },
    timeLimitArrowContainer: {
        color: "#F4511E",
        width: '30%',
        border: '1.4px solid #70707050',
        borderLeft: 'transparent',
        columnGap: '0.5rem'
    },
    timeLimitInput: {
        width: '60%',
        borderRight: 'transparent',
        padding: '0.76rem',
        marginBottom: '0.5rem'
    },
    timeLimitArrows: {
        flexDirection: 'column',
        color: "#F4511E",
        cursor: 'pointer'
    },
    btnBox: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '1rem',
        gap: '2rem',
        width: '80%',
        '& .MuiButtonBase-root': {
            // marginRight: '2rem',
            width: '45%',
            fontFamily: "Montserrat",
            fontWeight: 800,
            lineHeight: 0,
            fontSize: "0.6rem",
            borderRadius: "0.2rem",
        }
    },
    btn: {
        width: '45%',
        height: '2.5rem',
        border: "2px solid #F4511E",
        backgroundColor: "#F4511E",
        fontFamily: "Montserrat",
        fontWeight: 800,
        fontSize: "0.6rem",
        letterSpacing: 1,
        borderRadius: "0.2rem",
        cursor: "pointer",
        "&:hover": {
            '-webkit-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            '-moz-box-shadow': '3px 2px 9px 0px rgba(0,0,0,0.4)',
            boxShadow: '3px 2px 9px 0px rgba(0,0,0,0.4)',
        }
    },
    saveBtn: {
        backgroundColor: "#F4511E",
        // marginRight: "2rem",
        color: "#FFFFFF",
        "&:hover": {
            backgroundColor: "#F4511E",
        }
    },
    clearBtn: {
        backgroundColor: "#FFFFFF",
        color: "#F4511E",
        "&:hover": {
            border: "2px solid #F4511E",
            backgroundColor: "#FFFFFF",
        }
    },

    errorContainer: {
        width: '35%',
        columnGap: '0.5rem',
        justifyContent: 'flex-end'
    },

    errorText: {
        minWidth: '50%',
        border: '1px solid red',
        color: '#F4511E',
        textAlign: 'center',
        color: '#F4511E',
        borderRadius: '5px',
        fontWeight: '600',
        padding: '0.2rem'
    }
}));

export default useStyles;