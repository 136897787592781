import React from 'react'

import { makeStyles } from "@mui/styles";   

const StepperMenu = ({menuData, handleMenuClick, menuStatus, selectedColumns}) => {
    const classes = useStyles();
    const stepperMenuStatus = (ele) => {
        let temp = ele.split(" ").join("").toLowerCase();
        const status = {
            pending: "fa-regular fa-circle",
            inProgress: "fa-regular fa-circle-dot",
            done: "fa-solid fa-circle-check"
        }
    
        return status[menuStatus[temp]];
    }
    const getActiveClass = (ele) => {
        // console.log('getActiveClass = ', ele);
        let temp = ele.split(" ").join("").toLowerCase();
        // console.log('getActiveClass temp = ', temp);
        if (menuStatus[temp] !== 'pending') {
            return classes.liActive;
        }
        return ""
    }
    return (
        <nav>
            <ul className={classes.list} style={{backgroundColor:((selectedColumns.firstColumn === 'createOwn') ||( selectedColumns.firstColumn === 'addInteraction'))?'rgb(149, 152, 154, 0)':'#fff'}}>
                {
                    menuData.map((ele, i) => (
                        (i % 2 === 0) ? // 0=GameType, 2=Content, 4=Settings
                            <li key={i} className={getActiveClass(ele)} onClick={() => handleMenuClick(i, ele)}>
                                <i className={stepperMenuStatus(ele)}></i>
                                {ele}
                            </li> :
                            <span key={i}></span>
                    ))
                }
            </ul>
        </nav>
    )
}

export default StepperMenu;


const useStyles = makeStyles(() => ({
    list: {
        // width: '25%',
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            listStyle: 'none',
            width: '26%',
            minWidth: '6rem',
            textAlign: 'center',
            color: "rgb(112, 112, 112)",
            fontFamily: "Montserrat",
            fontSize: "0.7rem",
            fontWeight: "500",
            cursor: "pointer",
            '& i': {
                paddingRight: "0.4rem",
                fontSize: "0.9rem",
            },
            '& .fa-circle-check': {
                color: '#15B725'
            },
            '& .fa-circle-dot': {
                color: '#ffa000'
            }
        },
        '& span': {
            width: '11%',
            borderTop: "2px solid rgb(221, 224, 226, 0.5)",
    
        }
    },
    liActive: {
        color: "rgb(0, 0, 0, 0.8) !important",
        // padding: '0 0.2rem !important',
        fontWeight: "600 !important",
    },
}));


