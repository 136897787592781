import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
    importBtnBox: {
        // border: '1px solid ',
        // padding: '1.5rem 2.5rem',
        // paddingTop: '0.5rem'

        /* padding: 1.5rem 2.5rem; */
        /* padding-top: 0.5rem; */
        height: 'calc(20% - 3.8rem)',
        display: 'flex',
        /* width: 80%; */
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiButtonBase-root': {
            width: '320px !important',
            height: '42px !important',
            color: '#f4511e',
            border: `2px solid #f4511e`,
            borderRadius: 0,
            backgroundColor: '#fff',
            fontSize: '0.66rem',
            fontWeight: 700,
            letterSpacing: 1,
            boxShadow: 'none',
            '&:hover':{
                backgroundColor: '#fff',
                color: '#f4511e',
                boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
            }
        }
    },
    cardsContainer: {
        // border : '1px solid',
        padding: '0.5rem 2.5rem',
        // height: '80%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '2px',

        },
        '&::-webkit-scrollbar-thumb': {
            background: '#f4511e',
            borderRadius: '12px'
        }
    },
    btnBox: {
        // border : '1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height : '4rem',
        gap: '1rem',
        '& > button':{
            width: '320px !important',
            height: '42px !important'
        },
        '& .MuiButton-contained:hover': {
            backgroundColor: 'rgb(244, 81, 30, 0.9)',
            borderColor: 'rgb(244, 81, 30, 0.9)',
            color: '#fff',
        }
    },
    cardsBox: {
        display: 'flex',
        gap: '1.7rem',
        margin: '0.5rem 0rem'
    },
    createdAndDefaultChecked: {
        // border: '1px solid',
        width: '45%',
        aspectRatio: 16 / 9,
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
        '& img': {
            height: '93%',
            width: '80%'
        },
        '& .fa-solid': {
            fontSize: '0.8rem',
            color: '#15B725',
            position: 'absolute',
            top: '0.2rem',
            right: '0.2rem',
            zIndex: 1
        }
    },
    lottieBox: {
        width: '45%',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        '& .fa-solid': {
            fontSize: '0.8rem',
            color: '#15B725',
            position: 'absolute',
            top: '0.2rem',
            right: '0.2rem',
            zIndex: 1
        }
    },
    imageBox:{
        width: '45%',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        '& img':{
            height: '100%',
            width: '100%'
        },
        '& .fa-solid': {
            fontSize: '0.8rem',
            color: '#15B725',
            position: 'absolute',
            top: '0.2rem',
            right: '0.2rem',
            zIndex: 1
        }
    },
    sectionBreakBox: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1.5rem',
        margin: '0.5rem 0rem'
    },
    sectionBreakBtn: {
        width: '45%',
        '& .MuiButtonBase-root': {
            padding: 0,
            lineHeight: 0,
            minWidth: 0,
            '&:hover': {
                backgroundColor: '#fff'
            }
        },

    }
}));

export default useStyles;