import React, { useState, useEffect } from 'react';
// import { HashRouter as Router, Route, Link } from 'react-router-dom';
import { Route } from 'react-router';
import styled from 'styled-components';
import FormControl from '../../components/tms-components/FormControl';
import Label from '../../components/tms-components/Label';
import dayjs from 'dayjs';
import SimpleFormSelect from '../../components/tms-components/SimpleFormSelect';
import ClearButton from '../../components/tms-components/ClearButton';
import PlusIcon from '../../components/tms-components/MDLIcon/icons/Plus';
import FlatPrimaryButton from '../../components/tms-components/FlatPrimaryButton';
import FlatClearButton from '../../components/tms-components/FlatClearButton';
import CoursePathwayElement from '../../components/tms-components/CoursePathwayElement';
// import { Dialog } from '@material-ui/core';
import AddCourseEvent from './AddCourseEvent';
import AttendanceTool from '../../components/tms-components/AttendanceTool';
import TopBarWrapper from '../../components/tms-components/TopBarWrapper';
import MOTopBar from '../../components/tms-components/MOTopBar';
import TopBarHeading from '../../components/tms-components/TopBarHeading';

//new
import Dialog from '@mui/material/Dialog';

//nested-views
import CourseLog from './CourseLog';
import NewCourse from './NewCourse';
import EditCourse from './EditCourse';
// import CourseFeedback from './CourseFeedback';
import TrainingLog from './TrainingLog';
import EditTrainingSession from './EditTrainingSession';
import NewTrainingSession from './NewTrainingSession';

//utils
import useQuery from '../../utils/useQuery';

//services
import courseServices from '../../services/course';
import audienceServices from '../../services/audience';


const unload = (recaptchaSiteKey) => {
  const nodeBadge = document.querySelector('.grecaptcha-badge');
  if (nodeBadge) {
    document.body.removeChild(nodeBadge.parentNode);
  }

  const scriptSelector =
    "script[src='https://www.google.com/recaptcha/api.js?render=" +
    recaptchaSiteKey +
    "']";
  const script = document.querySelector(scriptSelector);
  if (script) {
    script.remove();
  }
};

const FormWrapper = styled.div`
  padding: 30px;
  text-align: left;
`;

const DurationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CourseFromToLabel = styled.h4`
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  color: #484848;
  padding-left: 20px;
  padding-right: 20px;
`;

const course_types = [
  {
    id: 'standalone',
    display_text: 'Standalone Journey',
  },
  {
    id: 'blended',
    display_text: 'Blended Learning Journey',
  },
  {
    id: 'webinar',
    display_text: 'Webinar',
  },
  {
    id: 'internal',
    display_text: 'Internal',
  },
  {
    id: 'inhouse',
    display_text: 'In-house',
  },
  {
    id: 'external',
    display_text: 'External',
  }
];

const StyledPlusIcon = styled(PlusIcon)`
  > #mastero_icon_add .cls-plus-icon {
    fill: #f4511e !important;
  }
`;

const StyledGenericButton = styled(ClearButton)`
  margin-top: 15px;
  color: #4f0060;
  padding: 0;
  font-size: 10px;
`;

function CourseTab(props) {
  // console.log('CourseTab props = ', props);
  const query = useQuery();
  const companyId = query.get('c_id');
  let permissions = [];
  const roles = localStorage.getItem('roles');

  if (props.userInfo.permissions.hasOwnProperty('features')) {
    for (let permission of props.userInfo.permissions.features) {
      permissions.push(permission.key);
    }
  }

  let user_info = props.userInfo;
  let jwt_token = props.jwtToken;
  let [courses, setCourses] = useState([]);
  let [courseEvents, setCourseEvents] = useState([]);
  const [batches, setBatches] = React.useState([]);

  async function fetch_courses(companyId) {
    // console.log('fetch_courses payload = ', props.activeProject, props.jwtToken);

    const res = await courseServices.fetchCourses(companyId);
    console.log('fetch_courses response = ', res);
    if (res.status === 200 || res.status === 201) {
      // let activeCourses = res.data.data.filter(element => element.active === true);
      // console.log('fetch_courses activeCourses = ', activeCourses);
      setCourses(res.data.data);
    }
    if (res.status === 401) {
      if (res.data.errors[0].message === 'Admin not registered or not active') {
        props.history.push("/login");
        localStorage.clear();
        return
      }
    }

    //OLD
    // let response = await MOClient.get_training_courses({
    //   project_id: props.activeProject,
    //   token: props.jwtToken,
    // });
    // let data = await response.fetch_request.json();
    // console.log('get_training_courses = ', data);
    // if (data.status === 'success') {
    //   setCourses(data.courses);
    //   setCourseEvents(data.course_events);
    // } else {
    //   // process error on fetching training courses
    //   return
    // }
  }

  React.useEffect(() => {


    //OLD REQUEST TO FETCH BATCHES
    // let options = {
    //   token: props.jwtToken,
    // };

    // if (permissions.indexOf('view_all_training_batches') > -1) {
    //   options.get_all = true;
    // }

    // let action = MOClient.get_training_batches(options);

    // action.then((result) => {
    //   if (result.status === 'success') {
    //     let fetch_request = result.fetch_request;

    //     let extract_data = fetch_request.json();

    //     extract_data.then((json_data) => {
    //       if (json_data.status === 'success') {
    //         setBatches(json_data.data);
    //       }
    //     });
    //   }
    // });
  }, []);

  const fetchBatches = async (companyId, options = {}) => {
    const res = await audienceServices.fetchBatches(companyId, options);
    console.log('fetchBatches response = ', res);
    if (res.status === 200 || res.status === 201) {
      const activeBatches = res.data.data.filter(ele => ele.active === true);
      console.log('fetchBatches activeBatches = ', activeBatches);
      setBatches(activeBatches);
    }
  }


  const fetchCourseEvents = async () => {
    const res = await courseServices.fetchCourseEvents();
    console.log('fetchCourseEvents res = ', res);
    // if (res.status === 200 || res.status === 201) {
    //   let mappedEvents = res.data.data.map((e) => {
    //     let o = {
    //       event_name: e.name,
    //       ...e,
    //     };

    //     o.modules = [];
    //     if (!Array.isArray(o.modules)) {
    //     }
    //     return o;
    //   });
    //   console.log('mappedEvents = ', mappedEvents);
    //   setCoursePathwayElements(mappedEvents);
    // }
  }

  useEffect(() => {
    fetch_courses(companyId);

    if (roles.includes("VIEW_TRAINING_BATCHES")) {
      console.log('has all batches access');
      fetchBatches(companyId);
    } else {
      console.log('has only his/her batch access');
      const admin = JSON.parse(localStorage.getItem("admin"));
      const trainerIds = [admin.id];
      fetchBatches(companyId, { trainerIds });
    }
    // fetchCourseEvents()
  }, []);



  function onCourseAdded() {
    fetch_courses(companyId);
  }

  let meta = {
    demographics: [],
  };

  if (props.companyInfo.hasOwnProperty('meta')) {
    let user_level_demographics = [];

    for (let i = 0; i < props.companyInfo.meta.demographics.length; i++) {
      let elem = props.companyInfo.meta.demographics[i];
      let demographic = elem['name'].toLowerCase();
      if (
        user_info.hasOwnProperty('permissions') &&
        user_info.permissions.hasOwnProperty('demographics') &&
        user_info.permissions.demographics.hasOwnProperty(demographic)
      ) {
        user_level_demographics.push(elem);
      }
    }

    meta.demographics = user_level_demographics;
  }

  // unload('6LeMwbMUAAAAAIf56AAL27kmmU7ZalWZPkM81ka-');

  return (
    <div>

      {/*== Create new Course === */}
      <Route
        exact
        path="/drive/courses-and-training/create-new-course"
        render={(routeProps) => (
          <NewCourse
            {...routeProps}
            {...props}
            onCourseAdded={onCourseAdded}
          />
        )}
      />

      {/*== Course Log === */}
      <Route
        exact
        path="/drive/courses-and-training/course-log"
        render={(routeProps) => (
          <CourseLog
            {...props}
            {...routeProps}
            courses={courses}
            courseEvents={courseEvents}
            onDelete={() => {
              fetch_courses(companyId);
            }}
          />
        )}
      />



      {/*== Edit Course === */}
      <Route
        exact
        path="/drive/courses-and-training/courses/edit/:id"
        render={(routeProps) => (
          <EditCourse
            {...props}
            {...routeProps}
            courses={courses}
            // courseEvents={courseEvents}
            batches={batches}
            onSave={() => {
              fetch_courses(companyId);
            }}
          />
        )}
      />

      {/*== Course feedback === */}
      {/* <Route
          path="/courses-and-training/course-feedback"
          render={(routeProps) => (
            <CourseFeedback
              {...props}
              {...routeProps}
              courses={courses}
              courseEvents={courseEvents}
            />
          )}
        /> */}

      {/*== Training Log === */}
      <Route
        exact
        path="/drive/training/training-log"
        render={(routeProps) => (
          <TrainingLog
            {...props}
            {...routeProps}
            courses={courses}
            courseEvents={courseEvents}
            batches={batches}
          />
        )}
      />

      {/*== Create new Training Session === */}
      <Route
        exact
        path="/drive/training/create-new-training-session"
        render={(routeProps) => (
          <NewTrainingSession
            {...props}
            {...routeProps}
            courses={courses}
            // courseEvents={courseEvents}
            batches={batches}
          />
        )}
      />

      {/*== Edit new Training Session === */}
      <Route
        exact
        path="/drive/training/training-sessions/edit/:id"
        render={(routeProps) => (
          <EditTrainingSession
            {...props}
            {...routeProps}
            courses={courses}
            courseEvents={courseEvents}
            batches={batches}
          />
        )}
      />





      {/*== Attendance Tool === */}
      <Route
        exact
        path="/drive/training/training-tool"
        render={(routeProps) => (
          <AttendanceTool
            {...props}
            {...routeProps}
            courses={courses}
            courseEvents={courseEvents}
            batches={batches}
          />
        )}
      />
    </div>
  );
}

export default CourseTab;
