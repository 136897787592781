import { makeStyles } from "@mui/styles";
// import palette from "../../theme/palette";

import constants from "../../constants";

// const COLORS = constants.COLORS;

const useStyles = makeStyles((theme) => ({
    centerFlex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    container: {
        minHeight: `calc(100vh - ${constants.HEADER_HEIGHT})`,
        height: '100%',
        backgroundColor: '#fff',
    },
    row1: {
        // border : '1px solid green',
        height: '50%'
    },
    row2: {
        // border : '1px solid red',
        height: '50%'
    },
    CompanyContainer: {
        padding: "1rem"
    }
}))

export default useStyles