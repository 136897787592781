import React from 'react'

//components
import Files from '../Files';

const Step3 = ({
    learnerOption,
    setLearnerOption,
    fileUpload,
    setFileUpload
}) => {
    return (
        <div style={{
            marginBottom: '3rem'
        }}>
            <h2 style={{
                fontSize: '1.2rem',
                fontFamily: "Montserrat",
                color: "#707070",
                padding: '1rem 0rem',
                paddingTop: '1.5rem',
                borderTop: "1px solid rgb(112, 112, 112, 0.3)",
                marginTop: '1.5rem'
            }}>Step 3: Learners</h2>
            <Files
                learnerOption={learnerOption}
                setLearnerOption={setLearnerOption}
                fileUpload={fileUpload}
                setFileUpload={setFileUpload}
            />
        </div>
    )
}

export default Step3;
