import React from 'react'

//components
import SearchBox from '../../../search-box';
import CustomSelect from '../../../custom-styled/custom-select';

//utils
import commonUtil from '../../../../utils/commonUtil';

//styles
import useStyles from '../../index.styles';

const Step2 = ({
    launchType,
    setLaunchType,
    setStep3

}) => {
    const classes = useStyles();

    let launchTypeData = [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }, { id: 'DISABLE', name: 'DISABLE' }];
    launchTypeData = [{ id: 'IMMEDIATE', name: 'IMMEDIATE' }];
    const launchTypeObject = commonUtil.objectify(launchTypeData, 'id');

    const _selectLaunchType = (item) => {
        console.log('_selectLaunchType item = ', item);
        if (launchType.includes(item.id)) {
            return
        }
        // setLinkedContent([...linkedContent, item.id]); //for selecting multiple content
        setLaunchType([item.id]);
        setStep3(true);
    }

    

    return (
        <div style={{
            marginBottom: '3rem'
        }}>
            <h2 style={{
                fontSize: '1.2rem',
                fontFamily: "Montserrat",
                color: "#707070",
                padding: '1rem 0rem',
                paddingTop: '1.5rem',
                borderTop: "1px solid rgb(112, 112, 112, 0.3)",
                marginTop: '1.5rem'
            }}>Step 2: Launch Schedule</h2>

            <div className={classes.row}>
                <CustomSelect
                    defaultText={commonUtil.getSelectLabel({ type: 'single', defaultText: 'Select Launch Type', selected: launchType, dataObject: launchTypeObject })}
                    autoClose={true}
                    withSearch={false}
                    withSelectAllOption={false}
                    listData={launchTypeData}
                    selected={launchType}
                    setSelected={setLaunchType}
                    handleSelect={_selectLaunchType}
                />
                {/* <div className={classes.selectWrapper}>
                    <div
                        className={`${classes.select} ${active === 'launch-type' ? classes.active : ''}`}
                        onClick={() => handleSetActive('launch-type')}
                    >
                        {
                            launchType.length ?
                                launchTypeObject[launchType[0]]?.name
                                : 'Select Launch Type'
                        }
                        <i className={`fa-solid ${active === 'launch-type' ? 'fa-angle-up' : 'fa-angle-down'}`}></i>
                    </div>
                    {
                        active === 'launch-type' &&
                        <div className={classes.selectBox}>
                            <SearchBox
                                withSearch={false}
                                withSelectAllOption={false}
                                listData={launchTypeData}
                                selected={launchType}
                                setSelected={setLaunchType}
                                handleSelect={_selectLaunchType} />
                        </div>
                    }
                </div> */}
            </div>
        </div>
    )
}

export default Step2;
