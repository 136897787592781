import { authFetch, authFetchFormData } from "../utils/fetch";

const fetchDocuments = async (companyId, parentId = null, sortDirection = 'desc', groupIds = null) => {
    let url = `/repository?startIndex=0&limit=50&sort=createdOn&sortDirection=${sortDirection}&companyIds=${companyId}`
    if (parentId) url += `&ids=${parentId}`
    if (groupIds) url += `&groupIds=${groupIds}`
    try {
        const resData = await authFetch.get(`${url}`);
        // console.log("fetchCategories:", resData);
        return resData;
    } catch (err) {
        //console.log(err.response);
        return err.response;
    }
}

const createFolder = async (payload) => {
    try {
        const resData = await authFetchFormData.post(`/repository`, payload);
        // console.log("fetchCategories:", resData);
        return resData;
    } catch (err) {
        //console.log(err.response);
        return err.response;
    }
}

const updateDocument = async (payload) => {

    try {
        const resData = await authFetchFormData.put(`/repository`, payload);
        // console.log("fetchCategories:", resData);
        return resData;
    } catch (err) {
        //console.log(err.response);
        return err.response;
    }
}

const deleteFolder = async (data) => {
    try {
        const resData = await authFetch.delete(`/repository`, { data })
        // // console.log("deleteChallenge:", resData);
        return resData
    } catch (err) {
        // console.log('deleteChallenge err:', err.response);
        return err.response
    }
}

const createFile = async (payload) => {
    try {
        const resData = await authFetchFormData.post(`/repository/file`, payload);
        // console.log("fetchCategories:", resData);
        return resData;
    } catch (err) {
        //console.log(err.response);
        return err.response;
    }
}

const deleteFile = async (data) => {
    try {
        const resData = await authFetch.delete(`/repository/file`, { data })
        // // console.log("deleteChallenge:", resData);
        return resData
    } catch (err) {
        // console.log('deleteChallenge err:', err.response);
        return err.response
    }
}

const launchDocument = async (data, type = "launch") => {
    try {
        const resData = await authFetchFormData.post(`/repository/${type}`, data);
        return resData;
    }
    catch (err) {
        return err.response;
    }
}

const fetchRepositoryGroups = async (repositoryId = null) => {
    let url = `/repository/get-groups/${repositoryId}`
    try {
        const resData = await authFetch.get(`${url}`)
        return resData
    } catch (err) {
        return err.response
    }

}

const addRepositoryToGroup = async (payload) => {
    let url = `/repository/add-group`
    try {
        const resData = await authFetch.post(`${url}`, payload)
        return resData
    } catch (err) {
        return err.response
    }

}

const removeRepositoryFromGroup = async (payload) => {
    let url = `/repository/remove-group`
    try {
        const resData = await authFetch.post(`${url}`, payload)
        return resData
    } catch (err) {
        return err.response
    }

}

export default {
    fetchDocuments,
    createFolder,
    updateDocument,
    createFile,
    deleteFolder,
    deleteFile,
    launchDocument,
    fetchRepositoryGroups,
    addRepositoryToGroup,
    removeRepositoryFromGroup
}