import React, { useState, useContext, useRef } from 'react'
import html2canvas from "html2canvas";

//mui
import { ButtonBase } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//components
import SuccessDialog from '../success-dialog';
import FailureDialog from '../failure-dialog';
import CustomInput from '../custom-styled/custom-input';
import Title from '../title';
import Label from '../custom-styled/label';
import QrCodeGenerator from '../qr-code-generator';

//utils
import useQuery from '../../utils/useQuery';
import useRouter from '../../utils/useRouter';
import commonUtil from '../../utils/commonUtil';

//styles
import useStyles from './index.styles'

// global-states
import { GlobalStates } from '../../App';

const QrBasedChallenge = () => {
    let query = useQuery();
    const classes = useStyles();
    const router = useRouter();
    const qrCodeRef = useRef();
    const defaultName = query.get('challengeName') || '';
    const microskillId = query.get('ms_id');
    const companyId = query.get('c_id');
    const sequence = query.get('seq');
    const [backdrop, setBackdrop] = useState(false);
    const [link, setLink] = useState('');
    const [challengeName, setChallengeName] = useState(defaultName);
    const [globalStates] = useContext(GlobalStates);
    const [dialogText, setDialogText] = useState('Uploading, Please Wait...');
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);

    const captureScreenshot = async () => {
        const canvas = await html2canvas(qrCodeRef.current);
        //CONVERTING CANVAS TO BASE64
        const base64String = canvas.toDataURL("image/png");
        // console.log("canvas to base64 = ", imgData);
        const imgFile = commonUtil.base64toFile(base64String);

        return { base64String, imgFile }
    }

    const handleDownloadPng = async () => {
        if (!link.length) {
            globalStates.handleToast(true, 'Please enter the link to generate QR code', 'error');
            return
        }
        setBackdrop(true);
        const { base64String } = await captureScreenshot();
        const anchorTag = document.createElement('a');
        anchorTag.href = base64String;
        anchorTag.download = 'qrcode.png';
        anchorTag.click();
        setBackdrop(false);
    }

    return (
        <div className={classes.main}>
            <div className={classes.column1}>
                <Title titleText={"Form Details"} fs='16px' />
                <div className={classes.inputWrapper}>
                    <Label labelText={"Link"} fs='14px' color="rgb(0, 0, 0, 0.7)" />
                    <CustomInput
                        id={"link"}
                        withLimitWarning={false}
                        value={link}
                        placeholder={'www.example.com'}
                        handleChange={(e) => setLink(e.target.value)}
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <Label labelText={"QR Code"} fs='14px' color="rgb(0, 0, 0, 0.7)" />
                    <div ref={qrCodeRef} style={{ height: "auto", maxWidth: '15rem', padding: '1rem', width: "100%" }}>
                        <QrCodeGenerator value={link} />
                    </div>
                    <ButtonBase className={`${classes.btn} ${classes.btnOutlined} ${classes.downloadBtn}`} onClick={handleDownloadPng}>DOWNLOAD PNG</ButtonBase>
                </div>
                <div className={classes.btnBox}>
                    <ButtonBase className={`${classes.btn} ${classes.btnFilled} `} onClick={() => { }} disabled={false}>Save</ButtonBase>

                    <ButtonBase className={`${classes.btn} ${classes.btnOutlined}`} >Cancel</ButtonBase>
                </div>
            </div>

            {/* <div className={classes.column2}>
                <div className={classes.staticPreview}>
                    Content Preview
                </div>
            </div> */}
            <Backdrop
                sx={{ backgroundColor: 'rgb(0 0 0 / 70%)', color: '#fff', zIndex: 99999 }}
                open={backdrop}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                <SuccessDialog
                    successDialogOpen={success}
                    setSuccessDialogOpen={setSuccess}
                    dialogText={dialogText}
                    handleClick={() => setSuccess(false)}
                />
            }
            {
                <FailureDialog
                    errorDialogOpen={failure}
                    setErrorDialogOpen={setFailure}
                    dialogText={dialogText}
                    handleClick={() => setFailure(false)}
                />
            }
        </div>
    )
}

export default QrBasedChallenge;
