import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
// import ButtonBase from '@material-ui/core/ButtonBase';
import { faFolderClosed } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//new
import { ButtonBase } from '@mui/material';

const FileName = styled.h4`
  padding-left: 10px;
  font-family: OpenSans;
  font-size: 12px;
  text-align: left;
  color: #707070;
`;

export const Box = styled.div`
  width: 100%;
  max-width: 100%;
  height: 27.3px;
  border: solid 0.5px #dde0e2;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BrowseText = styled.p`
  font-family: 'Open Sans';
  font-size: 10px;
  padding-right: 10px;
  font-weight: 600;
  color: #f4511e;
  cursor: pointer;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

function FileSelect(props, ref) {
  let inputRef = useRef(null);
  let [filename, setFileName] = useState(props.filename ? props.filename : '');
  let formRef = useRef(null);

  function handleClick() {
    inputRef.current.click();
  }

  function handleFileInput() {
    if (inputRef.current.files && inputRef.current.files.length > 0) {
      console.log(inputRef.current.files[0].name);
      setFileName(inputRef.current.files[0].name);
      props.onFileSelect(inputRef.current.files);
    }
  }
  useImperativeHandle(
    ref,
    () => ({
      resetForm() {
        setFileName('');
        formRef.current.reset();
      },
    }),
    [],
  );
  return (
    <ButtonBase
      onClick={handleClick}
      style={{ width: props.width ? props.width : '320px' }}>
      <Box>
        <div>
          <FileName>{filename}</FileName>
          <form ref={formRef}>
            <input
              ref={inputRef}
              type="file"
              name="incentiveMapping"
              style={{ display: 'none' }}
              onChange={handleFileInput}
              accept={props.accept}
              disabled={props.disabled}
            />
          </form>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            background: '#EAEAEA',
            paddingLeft: '10px',
            height : '100%'
          }}>
          <FontAwesomeIcon
            icon={faFolderClosed}
            style={{
              color: '#F4511E',
              fontSize: '0.8rem',
              marginRight: '10px',
            }}
          />
          <BrowseText style={{ fontSize: '9px' }}>Browse</BrowseText>
        </div>
      </Box>
    </ButtonBase>
  );
}

export default forwardRef(FileSelect);
