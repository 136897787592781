import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

const AddCard = ({ icon = '/images/documents/add-folder.svg', text = 'Add Folder', handleClick }) => {
    const classes = useStyles();
    return (
        <div className={classes.addCard} onClick={handleClick}>
            <img src={icon} alt='' />
            <h6>{text}</h6>
        </div>
    )
}

export default AddCard


const useStyles = makeStyles(() => ({
    addCard: {
        display: 'flex',
        border : '2px dashed #f4511e',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        width: '15%',
        aspectRatio: 2 / 1.5,
        containerType: 'inline-size',
        cursor: 'pointer',
        backgroundColor: '#F9F9F9',
        '& img': {
            height: '40cqi',
            width: '40cqi'
        },
        '& h6': {
            marginTop : '5cqi',
            color: "#f4511e",
            fontFamily: "Montserrat",
            fontSize: "6cqi",
            fontWeight: 700,
        }
    }
}));

