import React from 'react';
import ReactQuill from 'react-quill';
import { EDITOR_MODULES, EDITOR_FORMATS } from './index.modules'
import { useEffect } from 'react';
import { useState } from 'react';
import { useLayoutEffect } from 'react';
// import '../../../../node_modules/react-quill/dist/quill.snow.css';

const Editor = ({
    quillRef,
    theme,
    handleChange,
    onBlur,
    value,
    showModules = true,
    style,
    className,
    placeholder,
    readOnly = false,
    preserveWhitespace = true,
    showToolbar = true,
    modules = EDITOR_MODULES,
    formats = EDITOR_FORMATS

}) => {

    return (
        <ReactQuill
            ref={quillRef}
            theme={theme}
            onChange={handleChange}
            onBlur={onBlur}
            value={value}
            // modules={showModules ? Editor.modules : {}}
            // formats={showModules ? Editor.formats : {}}
            modules={modules}
            formats={formats}
            style={style}
            className={className}
            placeholder={placeholder}
            readOnly={readOnly}
            preserveWhitespace={preserveWhitespace}
        />
    );
};

export default Editor;

// Editor.modules = {
//     toolbar: [
//         [{ font: [] }],
//         [{ size: [] }],
//         ['bold', 'italic', 'underline'],
//         [{ indent: '-1' }, { indent: '+1' }, { indent: '0' }],
//     ],
//     clipboard: {
//         // toggle to add extra line breaks when pasting HTML:
//         matchVisual: false,
//     },
// };
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
// Editor.formats = ['font', 'size', 'bold', 'italic', 'underline', 'indent'];
