import React from 'react'

//mui
import { makeStyles } from "@mui/styles";

const CreationCard = ({handleClick, text = '', icon='/images/documents/add-folder.svg' }) => {
    const classes = useStyles();
    return (
        <div className={classes.creationCard} onClick={handleClick}>
            <img src={icon} />
            <h5>{text}</h5>
        </div>
    )
}

export default CreationCard;


const useStyles = makeStyles(() => ({
    creationCard: {
        // border: '2px dashed #f4511e',
        borderRadius: '5px',
        width: '24%',
        aspectRatio: 0.80,
        // aspectRatio: 3 / 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        cursor : 'pointer',
        backgroundColor : '#F9F9F9',
        backgroundImage: 'repeating-linear-gradient(180deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(270deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(0deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px), repeating-linear-gradient(90deg, #f4511e, #f4511e 20px, transparent 20px, transparent 30px, #f4511e 30px)',
        backgroundSize: '2px calc(100% + 30px), calc(100% + 30px) 2px, 2px calc(100% + 30px) , calc(100% + 30px) 2px',
        backgroundPosition: '0 0, 0 0, 100% 0, 0 100%',
        backgroundRepeat: 'no-repeat',
        animation: '$borderAnimation 6s infinite linear reverse',
        '& img' : {
            height: '7rem',
            width: '7rem',
            marginBottom : '1rem',
            cursor : 'pointer'
         },
        '& h5': {
            color: '#f4511e',
            fontFamily: "Montserrat",
            fontSize: "1rem",
            fontWeight: "700",
        }
    },
    '@keyframes borderAnimation': {
        from: { backgroundPosition: '0 0, -30px 0, 100% -30px, 0 100%' },
        to: { backgroundPosition: '0 -30px, 0 0, 100% 0, -30px 100%' }
    },
}));


