import React from "react";

import { FormControl, FormControlLabel, RadioGroup, Typography, Radio } from '@mui/material';

import ViaCsvFile from '../via-csv-file';
import ViaDemographic from '../via-demographic';

import { LEARNER_OPTIONS } from '../../index';
import useStyles from "./index.styles";


const AddLearnerInLJ = (props) => {
    const { journeyName, selectedMicroskillsSequence, launchType, unlockCondition, learnerOption, setLearnerOption, fileUpload, setFileUpload, setShowDwModal, selectedUsers = [], setSelectedUsers, userData, setUserData, selectedMicroskills = [], setHeadings, setLoadingUserData, withDemograhicwiseOption = false } = props;

    const classes = useStyles();

    const handleChange = (e, value) => {
        setSelectedUsers([]);
        setFileUpload(null);
        setLearnerOption(value);
    }

    return (
        <div className={classes.container}>
            <h2 className={classes.pageTitle}>Learning Journey Details</h2>
            <div>
                <h4 className={classes.sectionTitle}>Journey Name: {journeyName}</h4>

                <h4 className={`${classes.sectionTitle} ${classes.listTitle}`}>Microskills: </h4>
                <ol className={classes.list}>
                    {selectedMicroskillsSequence.map((item, index) => {
                        return <li key={index} className={classes.listItem}>{item.name}</li>
                    })}
                </ol>
                <h4 className={classes.sectionTitle}>Launch Type: {launchType ? "Grouped" : "Ungrouped"}</h4>
                <h4 className={classes.sectionTitle}>Unlock Condition: {unlockCondition}</h4>
            </div>

            <hr style={{ margin: '1.5rem 0px 2.5rem' }} />
            <div>
                <h2 className={classes.pageTitle} >Add Learners</h2>
                <FormControl>
                    <RadioGroup onChange={handleChange} row sx={{ gap: '1rem' }}>
                        <FormControlLabel sx={{ margin: '0px !important' }} value={LEARNER_OPTIONS.CSV} control={<Radio checked={learnerOption === LEARNER_OPTIONS.CSV} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>Via CSV File</Typography>} />
                        {withDemograhicwiseOption ? <FormControlLabel sx={{ margin: '0px !important' }} value={LEARNER_OPTIONS.DEMOGRAPHIC} control={<Radio checked={learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC} style={{ color: "#F4511E" }} />} label={<Typography style={{ fontWeight: "bold", color: "#484848", fontSize: '0.8rem', fontFamily: "Montserrat" }}>Demographic Wise</Typography>} /> : null}
                    </RadioGroup>
                </FormControl>
                {learnerOption === LEARNER_OPTIONS.CSV && <ViaCsvFile
                    fileUpload={fileUpload}
                    setFileUpload={setFileUpload}
                />}
                {withDemograhicwiseOption && learnerOption === LEARNER_OPTIONS.DEMOGRAPHIC && <ViaDemographic
                    setShowDwModal={setShowDwModal}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    userData={userData}
                    setUserData={setUserData}
                    selectedMicroskills={selectedMicroskills}
                    setHeadings={setHeadings}
                    setLoadingUserData={setLoadingUserData}
                />}
            </div>
        </div>
    )
};

export default AddLearnerInLJ;