import React, { useContext, useState, useEffect } from 'react'

//mui
import { ButtonBase } from '@mui/material';

//styles
import useStyles from './index.styles'

//components
import Title from '../title';
import Label from '../custom-styled/label';
import CustomInput from '../custom-styled/custom-input';
import FileuploadInput from '../game-based-assessment/components/question-answer-component/components/fileupload-input';
import Disclaimer from '../disclaimer';
import SuccessDialog from '../success-dialog';
import FailureDialog from '../failure-dialog';

// services
import scormServices from '../../services/scorm';
import challengesService from '../../services/challenges';

//utils
import useQuery from '../../utils/useQuery';
import commonUtil from '../../utils/commonUtil';
import useRouter from '../../utils/useRouter';

// global-states
import { GlobalStates } from '../../App';


const Scorm = () => {
    let query = useQuery();
    const classes = useStyles();
    const router = useRouter();
    const defaultName = query.get('challengeName') || '';
    const [globalStates, setGlobalStates] = useContext(GlobalStates);
    const [challengeName, setChallengeName] = useState(defaultName);
    const [fileName, setFileName] = useState('No file chosen');
    const [zipFile, setZipFile] = useState(null);
    const [uploaded, setUploaded] = useState(false);
    const [dialogText, setDialogText] = useState('Uploading, Please Wait...');
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [saveBtnDisable, setSaveBtnDisable] = useState(false);
    const [uploadedFilePath, setUploadedFilePath] = useState('');

    const microskillId = query.get('ms_id');
    const companyId = query.get('c_id');
    const sequence = query.get('seq');
    const isEdit = query.get('edit');

    useEffect(() => {
        setGlobalStates({ ...globalStates, showMsName: true });
        return () => {
            setGlobalStates({ ...globalStates, showMsName: false });
        }
    }, [])


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setZipFile(file);
        setFileName(file.name);
    }

    const uploadScorm = async () => {
        if (challengeName === '') {
            setDialogText('Please enter challenge name');
            setFailure(true);
            return;
        }
        if (isEdit) {
            //update-api needs to be created
            return
        }
        globalStates.handleProgressDialog(true, 'Uploading, please Wait...');

        let json = {
            companyId: companyId,
            microskillId: microskillId,
            ScormFile: zipFile
        }

        let payload = await commonUtil.jsonToFormData(json);
        let res = await scormServices.uploadScorm(payload);
        globalStates.handleProgressDialog(false, 'Uploading, Please Wait...');
        if (res.status === 201 || res.status === 200) {
            globalStates.handleToast(true, 'Scorm uploaded successfully', 'success');
            setUploadedFilePath(res.data.data.scormResources[0].filePath);
            setUploaded(true);
        }
        else {
            setDialogText('Failed to upload scorm package');
            setFailure(true);
        }
    }

    const createChallenge = async () => {
        let payload = {
            companyId: companyId,
            microskillId: microskillId,
            name: challengeName,
            type: "SCORM",
            gameType: "SCORM",
            designType: "SCORM_V12",
            sequence: Number(sequence),
            maxScore: 0,
            setting: {
                orgFilename: fileName,
                folderName: `${uploadedFilePath}`
            }
        }

        setSaveBtnDisable(true);

        const responseData = await challengesService.createChallenge(payload);
        if (responseData.status === 200 || responseData.status === 201) {
            router.history.push(`/microskill-studio?edit=true&&c_id=${companyId}&&p_id=xx&&ms_id=${microskillId}`);
            localStorage.setItem('isChallengeCreated', true);
        } else {
            setSaveBtnDisable(false);
            if (responseData.status === 500) {
                globalStates.handleToast(true, 'Internal server error', 'error');
            }
        }
    }

    const handleSuccessCloseButton = () => {
        setSuccess(false);
    }

    const handleFaliureCloseButton = () => {
        setFailure(false);
    }



    return (
        <div className={classes.main}>
            <div className={classes.column1}>
                <Title titleText={"SCORM Details"} fs='16px' />
                <div className={classes.inputWrapper}>
                    <Label labelText={"Challenge Name"} fs='14px' color="rgb(0, 0, 0, 0.7)" />
                    <CustomInput
                        id={"challenge-name"}
                        withLimitWarning={false}
                        value={challengeName}
                        placeholder={'Enter challenge name'}
                        handleChange={(e) => setChallengeName(e.target.value)}
                    // exceedText = '',
                    // isExceeded = false
                    // isEmpty = false
                    />
                </div>
                <div className={classes.inputWrapper}>
                    <Label labelText={"SCORM Package"} fs='14px' color="rgb(0, 0, 0, 0.7)" />
                    <FileuploadInput
                        fileName={fileName}
                        accept='.zip'
                        //  withWarning = false
                        //  warningText = imgWarningText
                        handleChange={handleFileChange}
                    />
                    <h6 className={classes.info}>
                        <span>SCORM Version 1.2</span>
                        <span>Supported files: Zip</span>
                    </h6>
                </div>
                <Disclaimer p='1rem 0' lh={2.5} />
                <div className={classes.btnBox}>
                    {!uploaded ? <ButtonBase onClick={uploadScorm} className={`${classes.btn} ${classes.btnFilled} `}>Upload</ButtonBase> :
                        <ButtonBase className={`${classes.btn} ${classes.btnFilled} `} onClick={createChallenge} disabled={saveBtnDisable}>Save</ButtonBase>}

                    <ButtonBase className={`${classes.btn} ${classes.btnOutlined}`} >Cancel</ButtonBase>
                </div>
            </div>
            <div className={classes.column2}>
                <div className={classes.staticPreview}>
                    Content Preview
                </div>
            </div>


            {
                success && <SuccessDialog
                    successDialogOpen={success}
                    setSuccessDialogOpen={setSuccess}
                    dialogText={dialogText}
                    handleClick={handleSuccessCloseButton}
                />
            }
            {
                failure && <FailureDialog
                    errorDialogOpen={failure}
                    setErrorDialogOpen={setFailure}
                    handleClick={handleFaliureCloseButton}
                    dialogText={dialogText}
                />
            }
        </div>
    )
}

export default Scorm;
