import React, { useEffect, useState, useContext } from 'react'

//mui
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

//components
import Table from './components/table'

//services
import userServices from '../../services/user'

//styles
import useStyles from './index.styles';
import MicroskillTableModal from './components/modal';

//global-states
import { GlobalStates } from '../../App';
import LinearProgress from '../tms-components/LinearProgress';
import { withStyles } from '@mui/material';
import LinearProgressWithLabel from '../custom-styled/progress-bar';

let defaultPagination = {
    limit: 0,
    pageCount: 0,
    startIndex: 0,
    totalCount: 0
}

const UserDetails = () => {
    const classes = useStyles();
    const [globalStates, setGlobalStates] = useContext(GlobalStates);
    const [searched, setSearched] = useState("");
    const [tableData, setTableData] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [pagination, setPagination] = useState(defaultPagination);
    const [openMsDetails, setOpenMsDetails] = useState(false);
    const [msModalData, setMsModalData] = useState(null);
    const [tableLoader, setTableLoader] = useState(false);
    const [activeUser, setActiveUser] = useState(0);

    useEffect(() => {

        setGlobalStates({ ...globalStates, appBarTitle: 'User Details' });

        return () => {
            setGlobalStates({ ...globalStates, appBarTitle: 'Master-O Studio' });
        }
    }, [])

    const handleChange = (e) => {
        setSearched(e.target.value);
    }
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            setPageNo(1);
            setStartIndex(0);
            fetchUserDetails(0, searched);
        }
    }

    useEffect(() => {
        if (searched.length) {
            fetchUserDetails(startIndex, searched);
        } else {
            fetchUserDetails(startIndex);
        }
    }, [startIndex])

    const fetchUserDetails = async (startIndex, searchValue = null) => {

        setTableLoader(true)
        const response = await userServices.fetchUserDetails(startIndex, searchValue);

        if (response.status === 200 || response.status === 201) {
            response.data.data.users.forEach(element => {
                element.companyName = element.Company.name ? element.Company.name : ''
            });

            setActiveUser(response?.data?.data?.totalLoggedInUser);
            setTableData(response?.data?.data?.users)
            setPagination(response?.data?.pagination);
            setPageCount(response?.data?.pagination?.pageCount);
            setTotalCount(response?.data?.pagination?.totalCount);

            if (!(totalUser > 0)) {
                setTotalUser(response?.data?.pagination?.totalCount);
            }

            setTableLoader(false)
        }
    }

    const handleClear = () => {
        setPageNo(1);
        setStartIndex(0);
        fetchUserDetails(0);
        setSearched("");
    }

    const handleBackClick = () => {
        if (startIndex >= 50) {
            setStartIndex(startIndex - 50)
            setPageNo(pageNo - 1);
        }
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    const handleForwardClick = () => {
        if (startIndex + 50 < totalCount) {
            setStartIndex(startIndex + 50);
            setPageNo(pageNo + 1);
        }
        // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }

    const handleRowClick = (element) => {
        setMsModalData(element);
        setOpenMsDetails(true);
    }

    const HEADINGS = [
        { title: "First name", key: "firstName" },
        { title: "Last name", key: "lastName" },
        { title: "Username", key: "username" },
        { title: "Type", key: "type" },
        { title: "Mobile", key: "mobile" },
        { title: "Email", key: "email" },
        { title: "Company", key: "companyName" },
        // { title: "Department", key: "quickActions" },
        { title: "Status", key: "active" },
        { title: "Deleted On", key: "deletedOn" },
        { title: "Created On", key: "createdOn" }
    ];



    return (
        <div className={classes.main}>
            <div className={classes.activeUser}>
                <span className={classes.totalUser}> {activeUser.toLocaleString()} / {totalUser.toLocaleString()} </span>
                <span className={classes.userProgress}>
                    <span className={classes.learnerText}>Learners in Company</span>
                    <LinearProgressWithLabel value={Math.floor((activeUser / totalUser) * 100)} width='9rem' height='5px' />
                </span>
            </div>

            <div className={classes.row1}>
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}
                >

                    <IconButton sx={{ p: '5px', color: '#f4511e' }} aria-label="menu">
                        <SearchIcon />
                    </IconButton>

                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        value={searched}
                        placeholder="Search"
                        inputProps={{ 'aria-label': 'search' }}
                        onChange={handleChange}
                        onKeyDown={handleSearch}
                    />

                    {searched.length ?
                        <IconButton sx={{ p: '5px', ':hover': { color: '#f4511e' } }} aria-label="menu" onClick={handleClear}>
                            <CloseIcon />
                        </IconButton> : null
                    }
                </Paper>
            </div>

            <div className={classes.row2}>
                <Table
                    handleRowClick={handleRowClick}
                    tableHeadings={HEADINGS}
                    tableData={tableData}
                    quickActions={false}
                    handleForwardClick={handleForwardClick}
                    handleBackClick={handleBackClick}
                    pageCount={pageCount}
                    pageNo={pageNo}
                    pagination={pagination}
                    loader={tableLoader}
                />
            </div>
            {
                openMsDetails ? <MicroskillTableModal
                    isOpen={openMsDetails}
                    setIsOpen={setOpenMsDetails}
                    data={msModalData}
                /> : null
            }
        </div >
    )
}

export default UserDetails
