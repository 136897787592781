import React from 'react'

import FormsQuestion from '../../components/form-question'

const FormsQuestionsPage = () => {
    return (
        <FormsQuestion />
    )
}

export default FormsQuestionsPage