import { makeStyles } from "@mui/styles";
import { fontWeight } from "@mui/system";
import { ThemeConsumer } from "styled-components";
import constants from "../../../../constants";

const useStyles = makeStyles(() => ({
    centerFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tableRoot: {
        backgroundColor: '#f9f9f9',
        maxWidth: `calc(50dvw - ${constants.DRAWER_WIDTH})`,
        overflow: 'auto',
    },
    table: {
        borderCollapse: 'collapse',
        // display: 'grid',
        rowGap: '0.5rem',
        tableLayout: 'auto',
        borderCollapse: 'separate',
        borderSpacing: '0 0.5rem',
        width: '100%',
    },
    tableRow: {
        borderRadius: '0.5rem',
    },
    tableCell: {
        padding: '10px',
        fontSize: '0.9cqi',
        backgroundColor: '#fff',
        // border: '1px solid #ddd',
        minWidth: '2rem',
        whiteSpace: 'nowrap',
    },
    tableHeadCell: {
        fontWeight: 'bold',
        fontSize: '1cqi',
    },
    loader: {
        padding: '1rem 0',
        color: '#F4511E',
    },
    tableFooter: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '2rem',
        padding: '1rem 0',
    },
    inputField: {
        border: '1px solid rgba(112, 112, 112, 0.3)',
        borderRadius: '4px',
        padding: '0.5rem',
        width: '100px',
        fontFamily: 'Open Sans',
        fontSize: '0.8rem',
        color: '#707070',
        '& :focus': {
            borderColor: '#f4511e',
        }
    },
    
}));
export default useStyles;