import React from 'react'

//components
import Documents from '../../components/documents'

const DocumentsPage = () => {
  return (
    <Documents/>
  )
}

export default DocumentsPage
