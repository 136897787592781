import React from 'react'

// mui
import DragDropFiles from '../../../drag-drop-files-uploader/index';
import UploadedFiles from '../../../drag-drop-files-uploader/uploaded-files';

//  constants
import constants from '../../../../constants';

//styles
import useStyles from '../../index.styles';

const ViaCsvFile = (props) => {
    const classes = useStyles();
    const fileLink = constants.USER_LAUNCH_FILE_FORMAT;
    const { fileUpload, setFileUpload } = props;

    const handleFileUpload = (file) => {
        setFileUpload(file)
    }

    return (
        <>

            <div style={{ padding: "1rem 0rem" }}>
                <a style={{
                    textDecoration: "underline",
                    color: "#F4511E",
                    fontWeight: "bolder",
                    fontSize: "1rem",
                    fontFamily: 'Montserrat',

                }} href={fileLink} className={classes.anchor} title='Download File Format'>Download File Format</a>
            </div>

            <div style={{ width: "30rem", height: "15rem", marginBottom: "1rem" }}>
                {fileUpload === null ?
                    <DragDropFiles title={'Drag & Drop your file here to import'} handleChange={handleFileUpload} typesAccepted={["XLS", "XLSX"]} multiple={false}/>
                    :
                    <UploadedFiles fileName={fileUpload?.name || 'Unknown'} setFileUpload={setFileUpload} />
                }
            </div>


        </>
    )
}

export default ViaCsvFile