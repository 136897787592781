import React from 'react'

//components
import UserDetails from '../../components/user-details'

const UserDetailsPage = () => {
  return (
    <UserDetails />
  )
}

export default UserDetailsPage
