import React from "react";

//mui
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

//components
import Loader from "../../../custom-styled/loader";

//utils
import isEmpty from "../../../../utils/isEmpty";

//styles
import useStyles from "./index.styles";



const Table = ({
    tableHeadings,
    tableData,
    quickActions = true,
    deleteKey = true,
    copyKey = true,
    withPagination = true,
    handleDelete,
    handleEdit,
    handleRowClick = () => { return },
    handleDuplicate,
    handleBackClick,
    handleForwardClick,
    pageNo = 1,
    pageCount = 1,
    pagination = {
        limit: 0,
        pageCount: 0,
        startIndex: -1,
        totalCount: 0
    },
    loader = false

}) => {
    const styles = useStyles();

    const getPaginationText = () => {
        if (!tableData.length || loader) return `${0} - ${0} out of ${0} Entries`
        return `${pagination.startIndex + 1} - ${(pagination.startIndex + 1 * pagination.limit) > pagination.totalCount ? pagination.totalCount : pagination.startIndex + pagination.limit} out of ${pagination.totalCount} Entries`

    }


    return (
        <div className={styles.MasteroTable_Container}>
            <table className={styles.Table}>
                <thead>
                    <tr className={styles.Table_Heading}>
                        {tableHeadings.map((element, i) => (
                            (element.key === "quickActions" && !quickActions) ? null : (
                                <th key={i}>{element.title}</th>
                            )
                        ))}
                    </tr>
                </thead>

                {!loader ? <tbody>
                    {tableData.map((element, key) => (
                        <tr className={styles.Table_Data} key={key}>

                            {
                                tableHeadings.map((data, i) => {
                                    switch (data['key']) {
                                        case "quickActions":
                                            if (quickActions) {
                                                return (
                                                    <td key={i} className={styles.iconBox}>
                                                        <img
                                                            className={styles.Icons}
                                                            onClick={() => handleEdit(element, key)}
                                                            src="/images/icons/pencil-edit-button.svg"
                                                            alt="Edit Button"
                                                            title="Edit"
                                                        />
                                                        {copyKey ?
                                                            <img
                                                                className={styles.Icons}
                                                                onClick={() => handleDuplicate(element, key)}
                                                                src="/images/icons/duplicate.svg"
                                                                alt="Duplicate Button"
                                                                title="Duplicate"
                                                            /> : null}
                                                        {
                                                            deleteKey ?
                                                                <img
                                                                    className={styles.Icons}
                                                                    onClick={() => handleDelete(key)}
                                                                    src="/images/icons/delete-photo.svg"
                                                                    alt="Delete Button"
                                                                    title="Delete"
                                                                />
                                                                : null
                                                        }
                                                    </td>
                                                )
                                            } else {
                                                return null
                                            }
                                        case "active":
                                            return (
                                                <td key={i}>
                                                    <span className={`${styles.btn} ${element[data.key] ? styles.successBtn : styles.errorBtn}`}> {element[data.key] ? 'Enabled' : 'Deleted'}</span>
                                                </td>
                                            )
                                        case "completion":
                                            return (
                                                <td key={i}>
                                                    <span className={`${styles.btn} ${element[data.key] ? styles.successBtn : styles.errorBtn}`}>{element[data.key] ? 'Completed' : 'Incomplete'}</span>
                                                </td>
                                            )
                                        case "createdOn":
                                        case "completedOn":
                                        case "updatedOn":
                                        case "launchedOn":
                                            return (
                                                <td key={i}>
                                                    {element[data.key] ? new Date(element[data.key]).toLocaleString() : "-"}
                                                </td>
                                            )
                                        case "deletedOn":
                                            return (
                                                <td key={i}>
                                                    {element[data.key] ?
                                                        element["active"] ? "-" : new Date(element[data.key]).toLocaleString()
                                                        : "-"}
                                                </td>
                                            )
                                        case "username":
                                            return (<td key={i} style={{textDecoration: "underline", cursor: "pointer", color:"#f4511e", fontWeight:700}} onClick={() => handleRowClick(element)}>{isEmpty(element[data.key]) ? "-" : element[data.key]}</td>)
                                        default:
                                            return (<td key={i}>{isEmpty(element[data.key]) ? "-" : element[data.key]}</td>)
                                    }
                                }
                                )
                            }
                        </tr>
                    ))}
                </tbody> : null}
            </table>

            {
                loader ?
                    <div className={styles.loaderBox}>
                        <Loader />
                    </div> : null
            }

            {!loader && !tableData.length ? <div className={styles.emptyBox}>No Data Found</div> : null}
            {withPagination && tableData.length && !loader ? <div className={styles.paginationBox}>
                <h5>{getPaginationText()}</h5>
                {/* <h5>Showing {pagination.startIndex + 1} to {pageNo === pageCount ? pagination.totalCount : pagination.startIndex + pagination.limit} out of {pagination.totalCount} Entries</h5> */}
                <ChevronLeftIcon className={styles.leftIcon} onClick={handleBackClick} />
                <ChevronRightIcon className={styles.leftIcon} onClick={handleForwardClick} />
            </div> : null}
        </div>
    );
};

export default Table;
